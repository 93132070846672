import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class NanoValidators {
  static ROOM_NAME_LENGTH: number = 200;

  static stringEquals(matchString: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value !== matchString;
      return forbidden ? { stringNotEquals: { value: control.value } } : null;
    };
  }

  static controlEquals(matchControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value !== matchControl.value;
      return forbidden ? { controlEquals: { value: control.value } } : null;
    };
  }

  static stringNotEquals(matchString: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value === matchString;
      return forbidden ? { stringNotEquals: { value: control.value } } : null;
    };
  }

  static controlNotEquals(matchControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value === matchControl.value;
      return forbidden ? { controlNotEquals: { value: control.value } } : null;
    };
  }

  static isWholeNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const numRegex = new RegExp('^[0-9]*$');
      const valid = numRegex.test(control.value);
      return valid ? null : { isNumber: { value: control.value } };
    };
  }

  static passwordStrengthLow(getStrength: () => number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = getStrength() < 1;

      return forbidden ? { passwordStrengthLow: { value: control.value } } : null;
    };
  }

  static noSlashes(control: AbstractControl): { [key: string]: boolean } {
    const nameRegexp: RegExp = /[\/\\]/;
    if (control.value && nameRegexp.test(control.value)) {
      return { noSlashes: true };
    }
  }
}

// export function StringEqualsValidator(val1: string, val2: string): ValidatorFn{
//   return (control: AbstractControl): ValidationErrors | null => {
//     const forbidden = val1 !== val2;
//     return forbidden ? {stringEquals: {value: control.value}} : null;
//   };
// }

// export function StringNotEqualsValidator(val1: string, val2: string): ValidatorFn{
//   return (control: AbstractControl): ValidationErrors | null => {
//     const forbidden = val1 === val2;
//     return forbidden ? {stringNotEquals: {value: control.value}} : null;
//   };
// }
