import { Ed25519DSWorker } from '../../worker_base/ds/ec';
import { workerHandler } from 'src/app/shared/worker-handler/worker-handler';
import { WorkerOperation } from '../../../../shared/worker-handler/worker-common';

/**
 * Asymmetric signature with the Ed25519 algorithm.
 */
export class Ed25519DS extends Ed25519DSWorker {
  /**
   *
   * @returns signature
   */
  public static sign(plain: Uint8Array, secret_key: Uint8Array): Promise<Uint8Array> {
    return workerHandler.run(WorkerOperation.Ed25519DSSign, plain, secret_key);
  }

  /**
   *
   * @returns if OK return with the plain text without signature, throw error otherwise
   */
  public static verify(
    signature: Uint8Array,
    public_key: Uint8Array,
    plain: Uint8Array
  ): Promise<Uint8Array> {
    return workerHandler.run(WorkerOperation.Ed25519DSVerify, signature, public_key, plain);
  }
}
