import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { RouterHandler, RouterResponse } from 'src/app/shared/services/router-handler.service';
import { showWorkspaceTabObserver, sideMenuActiveObserver } from 'src/app/shared/shared-observers';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { AccountAvatarRecord } from 'src/app/shared/server-services/query-records/account-records';
import {
  PageTabService,
  PageTypes,
  SubPageTypes,
  activePageInfoChanged,
} from 'src/app/shared/services/page-tab.service';
import { AccountService } from '../../shared/server-services/account.service';
import { AuthService } from '../../shared/server-services/auth.service';
import { DialogueService } from '../../shared/server-services/dialogue.service';
import { ID } from '../../shared/server-services/query-records/common-records';
import { RoomWithData } from '../../shared/server-services/query-records/room-records';
import {
  ResourceType,
  SidebarResource,
} from '../../shared/server-services/query-records/sidebar-records';
import { RoomService } from '../../shared/server-services/room.service';
import { SidebarService } from '../../shared/server-services/sidebar.service';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from '../../shared/server-services/subscription-event';
import { SubscriptionService } from '../../shared/server-services/subscription.service';

export let newsObserver = new Subject<{
  count: number;
  newsCount: number;
  grantedRoomsCount: number;
  newsList: ExtendedSidebarResource[];
}>();

export interface ExtendedSidebarResource extends SidebarResource {
  room?: RoomWithData;
  avatar?: AccountAvatarRecord;
  hasNewMessages?: Boolean;
  loaded?: Boolean;
}

const customToolTipOptions: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 0,
  // touchGestures: 'auto',
  // position: 'before',
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customToolTipOptions }],
})
export class NewsListComponent implements OnInit, OnDestroy, AfterViewInit {
  public newsList: ExtendedSidebarResource[] = [];
  public grantedRooms: ID[] = [];
  public rejectedRooms: ID[] = [];
  public ResourceType = ResourceType;
  public isMobile: boolean = false;

  public hasNews: boolean = false;
  public isNewsListOnStart: boolean = false;

  @ViewChild('newsContainer') newsContainer: ElementRef;
  private destroyEvent = new Subject<void>();
  public activeResourceId: ID = null;

  private activePageInfoChangeSubscription: Subscription;

  constructor(
    private sidebarService: SidebarService,
    private subscriptionService: SubscriptionService,
    private authService: AuthService,
    private roomService: RoomService,
    private accountService: AccountService,
    private dialogueService: DialogueService,
    private routerHandler: RouterHandler,
    private breakpointObserver: BreakpointObserver,
    private pageTabService: PageTabService,
    @Inject(DOCUMENT) private document
  ) {
    this.subscriptionService.subscribeWorkspaceLoaded(this.onWorkspaceLoaded);
    this.routerHandler.subscribe('main-menu', this.initScroll);

    this.activePageInfoChangeSubscription = activePageInfoChanged.subscribe(
      this.setActiveResourceIdFromPageInfoChange
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshNewsList
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshNewsList
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshNewsAndGrantedRooms
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.refreshNewsAndGrantedRooms
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshNewsAndGrantedRooms
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.refreshNewsAndGrantedRooms
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.refreshNewsList
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.ALL,
      this.refreshNewsList
    );
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.refreshNewsList
    );

    this.routerHandler.subscribeAll(this.setActiveResourceId);
    this.setActiveResourceId(this.routerHandler.getRoute());
  }

  setActiveResourceId = (route: RouterResponse) => {
    this.activeResourceId = route.params.id || null;
  };

  setActiveResourceIdFromPageInfoChange = (ev) => {
    const pageInfo = this.pageTabService.getActivePageInfo();
    if (pageInfo?.id) {
      this.activeResourceId = pageInfo.id;
    }
  };

  ngAfterViewInit(): void {
    // this feature breaks the touch pad scrolling
    /*fromEvent(this.newsContainer.nativeElement, 'wheel')
      .pipe(throttleTime(25), takeUntil(this.destroyEvent)) //throttle mouse wheels scrolls that are -too- fast
      .subscribe((event: WheelEvent) => {
        this.newsContainer.nativeElement.scrollBy({
          left: event.deltaY < 0 ? -56 : 56,
        });
      });*/
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 761px)']) //TODO this burnt-in variable is not optimal. Better would be to use global options. Best would be -> See issue #298
      .subscribe((state: BreakpointState) => {
        this.isMobile = state.matches;
      });
  }

  ngOnDestroy(): void {
    this.routerHandler.unsubscribeAll(this.setActiveResourceId);

    this.routerHandler.unsubscribe('main-menu', this.initScroll);

    this.subscriptionService.unsubscribeWorkspaceLoaded(this.onWorkspaceLoaded);

    this.activePageInfoChangeSubscription.unsubscribe();

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshNewsList
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshNewsList
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshNewsAndGrantedRooms
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.refreshNewsAndGrantedRooms
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshNewsAndGrantedRooms
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.refreshNewsAndGrantedRooms
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.refreshNewsList
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.ALL,
      this.refreshNewsList
    );
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.refreshNewsList
    );

    this.document.removeEventListener('resume', this.initScroll, true);

    this.destroyEvent.next();
  }

  private initScroll = () => {
    this.scrollToStart(null, false);
  };

  public onMouseWheel(event: WheelEvent): void {
    if (event.deltaY === 0)
      //touchscroll
      return;

    let parent = <Element>event.target;
    while (parent) {
      if (parent.classList.contains('news-container')) {
        if (event.deltaY > 0) parent.scrollLeft += 25;
        else parent.scrollLeft -= 25;
        break;
      } else {
        parent = parent.parentElement;
      }
    }
    event.preventDefault();
  }

  private onWorkspaceLoaded = (res) => {
    if (!this.authService.isAnonym()) {
      this.refreshNewsAndGrantedRooms();
    }
    this.document.addEventListener('resume', this.initScroll, true);
  };

  // make a callback which can subscribe/unsubscribe to events
  private refreshNewsAndGrantedRooms = () => {
    return this.refreshGrantedRooms().then(() => {
      this.refreshNewsList();
    });
  };

  private refreshGrantedRooms = () => {
    return Promise.all([
      this.sidebarService.getGrantedRooms().then((rooms) => {
        this.grantedRooms = rooms;
      }),

      this.sidebarService.getRejectedRooms().then((rooms) => {
        this.rejectedRooms = rooms;
      }),
    ]);
  };

  /**
   * Refreshes state of newsList
   */
  private refreshNewsList = () => {
    this.sidebarService.getSidebarNews().then((news) => {
      // this.hasNews = news.news > 0;
      let hasNewsTemp = false;

      const promises: Promise<void>[] = [];

      for (const [index, resource] of (<ExtendedSidebarResource[]>news.resources).entries()) {
        /** Only the recent 3 chats triggers the hasNews */
        if (index < 3 && resource.hasNewMessages) {
          hasNewsTemp = true;
        }

        const isOnPage = resource.id === this.activeResourceId;

        if (isOnPage) {
          resource.hasNewMessages = false;
        } else {
          if (resource.type === ResourceType.DIALOGUE) {
            const promise = this.dialogueService.getData(resource.id).then((msg) => {
              resource.hasNewMessages =
                (msg.seenMessageId == null && msg.topMessageId !== null) ||
                msg.seenMessageId < msg.topMessageId;
            });
            promises.push(promise);
          }
          if (resource.type === ResourceType.ROOM) {
            const promise = this.roomService.getRoomRecord(resource.id).then((msg) => {
              if (msg.seenMessageId || msg.topMessageId) {
                resource.hasNewMessages =
                  (msg.seenMessageId == null && msg.topMessageId !== null) ||
                  msg.seenMessageId < msg.topMessageId;
              }
            });
            promises.push(promise);
          }
        }
      }
      this.newsList = news.resources;

      this.hasNews = hasNewsTemp;

      Promise.all(promises).then(() => {
        this.sidebarService.getGrantedRooms().then((rooms) => {
          const hasGrantedRooms = rooms.length > 0 ? 1 : 0;
          newsObserver.next({
            count: news.news + hasGrantedRooms,
            newsCount: news.news,
            newsList: this.newsList,
            grantedRoomsCount: rooms.length,
          });
        });
      });
    });
  };

  // event on
  public visibilityChange = (event, resource) => {
    if (resource === 'placeholder') {
      if (!event) {
        this.isNewsListOnStart = false;
      } else {
        this.isNewsListOnStart = true;
      }
      return;
    }

    if (event) {
      this.loadData(resource);
    }
  };

  // singular resource (avatar) loader
  private loadData = (resource) => {
    const editableResource = this.newsList.find((singleNew) => singleNew.id === resource.id);
    if (editableResource?.loaded) {
      // skip
    } else {
      if (resource.type === ResourceType.ROOM) {
        this.roomService
          .getRoom(resource.id)
          .then((room) => {
            editableResource.room = room;
            // @todo
            // resource.posterId = room.posterId
          })
          .catch((err) => {
            console.warn('can not find room', err);
          });
      }
      if (resource.type === ResourceType.DIALOGUE) {
        this.accountService.getAccount(resource.id).then((avatar) => {
          editableResource.avatar = avatar;
        });
      }
      editableResource.loaded = true;
    }
  };

  public navigateTo = (resource) => {
    const routeFragmentsArray = [
      'room',
      resource?.id,
      'chat',
      resource?.type === 'room' ? 'room' : 'private',
    ];
    const routeUrl = routeFragmentsArray.join('/');
    console.log('Trying to navigate to: ', routeUrl);
    this.routerHandler.navigate([routeUrl]);
    // closing sidebar if needed
    sideMenuActiveObserver.next(false);
    showWorkspaceTabObserver.next();
  };

  public scrollToStart = (event: MouseEvent, animated: boolean = true) => {
    if (this.newsContainer) {
      this.newsContainer.nativeElement.scrollTo({
        left: 0,
        top: 0,
        behavior: animated ? 'smooth' : 'auto',
      });
    }
    if (event) {
      event.preventDefault();
    }
  };

  selected(e, resource: ExtendedSidebarResource) {
    // room/{{ sidebarResourceData.id }}/chat/room

    if (e.pointerType == 'mouse' && e.button == 1) {
      e.preventDefault();

      this.pageTabService.openInNewTab({
        page: resource.type == ResourceType.DIALOGUE ? PageTypes.PRIVATE_CHAT : PageTypes.ROOM,
        subpage: SubPageTypes.CHAT,
        id: resource.id,
        fragment: {},
      });
    }
  }
}
