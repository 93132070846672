import { Injectable } from '@angular/core';
import { Observable, PartialObserver, Subject, Subscription } from 'rxjs';
import { DriveFile } from 'src/app/components/resource-page/drive-window/drive-layout/drive-file';
import { ID } from '../server-services/query-records/common-records';

export type MediaObserverType = {
  roomId: ID;
  path: string;
  file: DriveFile;
};

@Injectable({
  providedIn: 'root',
})
export class MediaViewerService {
  private imageObserver: Subject<MediaObserverType> = new Subject<MediaObserverType>();

  private pdfObserver: Subject<MediaObserverType> = new Subject<MediaObserverType>();

  constructor() {}

  public showImage(roomId: ID, path: string, file: DriveFile) {
    //console.log('img', roomId, path, file);
    this.imageObserver.next({
      roomId,
      path,
      file,
    });
  }

  /*
  public showPdf(roomId: ID, path: string, file: DriveFile){
    this.pdfObserver.next({
      roomId,
      path,
      file,
    });
  }
  */

  public subscribeImageShow(observer: PartialObserver<MediaObserverType>): Subscription {
    return this.imageObserver.subscribe(observer);
  }

  public subscribePdfShow(observer: PartialObserver<MediaObserverType>): Subscription {
    return this.pdfObserver.subscribe(observer);
  }

  public showVideo() {
    console.warn('showVideo not implemented');
  }
}
