import { AbstractSelfAccountKeyring } from '../../keyring/account_base';
import { AbstractPrivateKeyring } from '../../keyring/private';
import { AbstractConfigDumpArgs, AbstractConfigLoadArgs } from '../base';

export class PrivateKeyConfigLoadArgs extends AbstractConfigLoadArgs {
  public private_kr;
  public key_version;

  constructor(cipher: Uint8Array, private_kr: AbstractPrivateKeyring, key_version: string) {
    super(cipher);
    this.private_kr = private_kr;
    this.key_version = key_version;
  }
}

export class PrivateKeyConfigDumpArgs extends AbstractConfigDumpArgs {
  public self_kr;
  public private_kr;
  public key_version;

  constructor(
    self_kr: AbstractSelfAccountKeyring,
    private_kr: AbstractPrivateKeyring,
    key_version: string
  ) {
    super();
    this.self_kr = self_kr;
    this.private_kr = private_kr;
    this.key_version = key_version;
  }
}
