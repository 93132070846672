import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, HostListener, OnDestroy, Renderer2 } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AppStorage } from '../app-storage';
import { AuthService } from '../server-services/auth.service';
import { RoomWithData } from '../server-services/query-records/room-records';
import { RoomService } from '../server-services/room.service';
import { RouterHandler } from '../services/router-handler.service';

export type BookmarksSubject = {
  open?: boolean;
  new?: BookmarkElement;
};

export type BookmarkElement = {
  roomId: string;
  path: string;
};

export let bookmarksObserver = new Subject<BookmarksSubject>();

export let BOOKMARK_OPEN_KEY = 'bookmarks_open';
export let BOOKMARK_LIST = 'bookmark_list';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss'],
})
export class BookmarksComponent implements OnDestroy {
  public showBookmarks: boolean = AppStorage.getItem(BOOKMARK_OPEN_KEY) == '1';
  public bookmarks: BookmarkElement[] = [];
  public roomData: { [roomId: string]: RoomWithData } = {};
  public editMode: boolean = false;

  constructor(
    private roomService: RoomService,
    private routerHandler: RouterHandler,
    private renderer: Renderer2,
    private authService: AuthService
  ) {
    // load bookmarks
    let savedInfo = AppStorage.getItem(BOOKMARK_LIST);
    if (savedInfo) {
      try {
        let bookmarkArray = JSON.parse(savedInfo);
        if (Array.isArray(bookmarkArray)) {
          (<any[]>bookmarkArray).forEach((element) => {
            if (element['roomId'] !== undefined && element['path'] !== undefined) {
              this.bookmarks.push({
                roomId: element['roomId'],
                path: element['path'],
              });
              try {
                this.roomService.getRoom(element['roomId']).then((data) => {
                  if (data) {
                    this.roomData[element['roomId']] = data;
                  }
                });
              } catch (e) {
                console.warn('could not load room data for bookmark', element);
              }
            } else {
              console.warn('bookmark element syntax error', element);
            }
          });
        } else {
          console.error('bookmarks syntax error');
        }
      } catch (e) {
        console.error('could not parse bookmarks', e);
      }
    }

    // subscribe to changes from project
    this.bookmarksSubscription = bookmarksObserver.subscribe((msg) => {
      if (msg.new) {
        if (
          !this.bookmarks.find((b) => {
            return b.roomId == msg.new.roomId && b.path == msg.new.path;
          })
        ) {
          this.bookmarks.push(msg.new);
          this.roomService.getRoom(msg.new.roomId).then((data) => {
            if (data) {
              this.roomData[msg.new.roomId] = data;
            }
          });
          this.saveBookmarks();
        }
      }

      if (msg.open !== undefined) {
        this.showBookmarks = msg.open;
        AppStorage.setItem(BOOKMARK_OPEN_KEY, msg.open ? '1' : '0');
      }
    });

    this.authService.onLogin(this.onLogin);
  }

  private onLogin = () => {
    this.bookmarks = [];
    this.saveBookmarks();
  };

  private bookmarksSubscription: Subscription;

  ngOnDestroy(): void {
    if (this.bookmarksSubscription) {
      this.bookmarksSubscription.unsubscribe();
    }

    this.authService.unsubscribeFromOnLogin(this.onLogin);
  }

  public openBookmark(b: BookmarkElement) {
    this.routerHandler.navigate(['room', b.roomId, 'drive'], {
      fragment: this.routerHandler.fragmentToRaw({
        path: b.path,
      }),
    });
  }

  public removeBookmark(b: BookmarkElement) {
    let index = this.bookmarks.indexOf(b);
    if (index > -1) {
      this.bookmarks.splice(index, 1);
      this.saveBookmarks();
    }
  }

  private saveBookmarks() {
    AppStorage.setItem(BOOKMARK_LIST, JSON.stringify(this.bookmarks));
  }

  /**
   * Returned unlistener from renderer2.listen
   */
  private mouseMovelistenerDestructor: Function;
  private dragStarted: boolean = false;
  public containerWidth: number = 300;

  public draggerPointerDown() {
    if (!this.mouseMovelistenerDestructor) {
      this.mouseMovelistenerDestructor = this.renderer.listen('document', 'mousemove', (e) => {
        if (this.dragStarted) {
          this.containerWidth = window.innerWidth - e.clientX;
        }
      });
    }

    this.dragStarted = true;
  }

  @HostListener('document:pointerup', ['$event']) pointerUp(e: MouseEvent) {
    if (this.dragStarted) {
      this.dragStarted = false;
      //AppStorage.setItem('bookmarksWidth', e.clientX.toString());
      this.mouseMovelistenerDestructor();
      this.mouseMovelistenerDestructor = null;
    }
  }

  roomDropped(event: CdkDragDrop<BookmarkElement[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.saveBookmarks();
  }
}
