<h2 mat-dialog-title translate>License tokens</h2>
<div class="dialog-outside-scroll">
  <p translate>The list includes all of your available license tokens within the selected bundle.</p>
  <p translate>
    Already claimed tokens in the bundle: <span>{{ licenseBundle.claimed }}</span>
  </p>
  <div class="paging-container" *ngIf="pageCount > 1">
    <button mat-icon-button class="small-icon-button" (click)="nextPage(-1)" [disabled]="currentPage === 0"><mat-icon>chevron_left</mat-icon></button>
    <mat-divider [vertical]="true"></mat-divider>
    <button mat-icon-button class="small-icon-button" (click)="nextPage(1)" [disabled]="currentPage === pageCount - 1"><mat-icon>chevron_right</mat-icon></button>
    <span class="paging-indicators">{{ currentPage + 1 }} / {{ pageCount }}</span>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <mat-list>
    <mat-list-item *ngFor="let token of tokens; let i = index">
      <div class="flex-center">
        <button mat-icon-button (click)="copy(token)"><mat-icon>content_copy</mat-icon></button> {{ i + 1 + currentPage * PAGE_SIZE }}. {{ token }}
      </div>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Close</span></button>
</mat-dialog-actions>
