import { Component, Input, OnInit } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import hljs from 'node_modules/highlight.js/lib/common';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import {
  HLJSCodeBlockFlattenedTokens,
  HLJSToken,
  flattenHLJSCodeblockTokens,
  separateTokensLineByLine,
} from '../../../../chat-message-editor/slate-editor/with-markdown';

@Component({
  selector: 'app-code-block-viewer',
  templateUrl: './code-block-viewer.component.html',
  styleUrls: ['./code-block-viewer.component.scss'],
})
export class CodeBlockViewerComponent implements OnInit {
  @Input() text: string;
  public tokens: HLJSCodeBlockFlattenedTokens[][];
  public language: string;
  public rawCode: string;
  public showLineNumber: boolean = true;

  constructor(
    private clipboardService: ClipboardService,
    private snackbarService: SnackBarService
  ) {}

  ngOnInit(): void {
    if (this.text) {
      let lines = this.text.split('\n');
      this.language = lines.splice(0, 1)[0];
      this.rawCode = lines.join('\n').slice(0, -1);

      let hljsResult;
      try {
        hljsResult = hljs.highlight(this.rawCode, {
          language: this.language,
          ignoreIllegals: true,
        });
      } catch (e) {
        hljsResult = hljs.highlight(this.rawCode, { language: 'ini' });
      }

      // hljs store selector nodes in object(kind, children), and directly as a string the texts nodes
      let hljsTokens: HLJSToken[] = hljsResult._emitter.rootNode.children;
      let tokens = flattenHLJSCodeblockTokens(hljsTokens); // merge the same siblings nodes
      this.tokens = separateTokensLineByLine(tokens); // split by \n like in the slate tree
    }
  }

  public copy() {
    this.clipboardService
      .copy(this.rawCode)
      .then(() => {
        this.snackbarService.showSnackbar(marker('Code copied!'));
      })
      .catch((err) => {
        console.error('clipboard err', err);
        this.snackbarService.showSnackbar(marker('Copy Error!'));
      });
  }
}
