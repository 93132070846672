import { AbstractSelfAccountKeyring } from '../keyring/account_base';
import { AccountSignatureConfigDumpArgs } from '../context/account_signature/args';
import { ACCOUNT_SIGNATURE_CRYPTO_CONTEXT } from '../context/account_signature/__init__';

/**
 * Sign the request-challenge payload for account deletion.
 */
export function create_account_delete_request(
  self_kr: AbstractSelfAccountKeyring,
  delete_token: Uint8Array
): Promise<Object> {
  return ACCOUNT_SIGNATURE_CRYPTO_CONTEXT.dump(
    new AccountSignatureConfigDumpArgs(delete_token, self_kr)
  ).then((signature) => {
    return {
      deleteToken: delete_token,
      signature,
    };
  });
}
