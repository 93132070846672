import { Component, Inject, INJECTOR, Injector, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DriveFile } from 'src/app/components/resource-page/drive-window/drive-layout/drive-file';
import { environment } from 'src/environments/environment';
import { ClipboardService } from '../../services/clipboard.service';
import { DownloadManagerService } from '../../services/download-manager-service';
import { RouterHandler } from '../../services/router-handler.service';
import { SnackBarService } from '../../services/snackbar.service';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

export interface DialogData {
  file: DriveFile;
}

@Component({
  selector: 'app-file-properties-dialog',
  templateUrl: './file-properties-dialog.component.html',
  styleUrls: ['./file-properties-dialog.component.scss'],
})
export class FilePropertiesDialogComponent
  extends NanoDialogBaseComponent<FilePropertiesDialogComponent>
  implements OnInit, OnDestroy
{
  // public lastModified: Date = null;
  public isDir: boolean = false;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,

    private routerHandler: RouterHandler,
    private downloadManagerService: DownloadManagerService,
    private clipboardService: ClipboardService,
    private snackbarService: SnackBarService
  ) {
    super(injector);
  }

  onCancel = () => {
    this.dialogRef.close();
  };

  ngOnDestroy(): void {
    this.routerHandler.unsubscribeAll(this.onCancel);
  }

  ngOnInit(): void {
    this.isDir = this.data.file.ext === 'dir';
    // this is the same as the parsed file.createdAt
    // this.lastModified = new Date(this.data.file?.mtime * 1000);
  }

  download() {
    this.downloadManagerService.download(this.data.file.resourceId, this.data.file);
  }

  goToDrive() {
    const resourceId = this.data.file.resourceId;
    const path = this.data.file.path;
    const fileName = this.data.file.fullName;

    this.routerHandler.navigate(['room', resourceId, 'drive'], {
      fragment: this.routerHandler.fragmentToRaw({
        path: path,
        file: fileName,
      }),
    });
    this.onCancel();
  }

  copyLink() {
    const resourceId = this.data.file.resourceId;
    const path = this.data.file.path;
    const fileName = this.data.file.fullName;

    const fragmentsObject = {
      path: path,
      file: fileName,
    };

    this.clipboardService
      .copy(
        environment.site_base +
          '/room/' +
          resourceId +
          '/drive#' +
          this.routerHandler.fragmentToRaw(fragmentsObject)
      )
      .then(() => {
        this.snackbarService.showSnackbar(
          marker('File URL copied!') + ' (' + this.data.file.fullName + ')'
        );
      });
  }
}
