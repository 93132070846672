import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateRoomMessageEventSubscriptionHandler } from './ws-sub-room-message-event-create-handler';
import { onDeleteRoomMessageEventSubscriptionHandler } from './ws-sub-room-message-event-delete-handler';
import { onModifyRoomMessageEventSubscriptionHandler } from './ws-sub-room-message-event-modify-handler';

export let workspaceSubscriptionRoomMessageEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateRoomMessageEventSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyRoomMessageEventSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteRoomMessageEventSubscriptionHandler,
};
