import { CacheLogicResponseManipulatorType } from '../cache-logic-interface';
import { customMutationManipulator } from './mutation-manipulator/custom-mutation-manipulator';
import { customQueryManipulator } from './query-manipulator/custom-query-manipulator';
import { customSubscriptionManipulator } from './subscription-manipulator/custom-subscription-manipulator';

export let customCacheResponseManipulator: CacheLogicResponseManipulatorType = {
  query: customQueryManipulator,
  mutation: customMutationManipulator,
  subscription: customSubscriptionManipulator,
};
