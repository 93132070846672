import msgpack from 'msgpack-lite';
import { blobService } from '../services/blob.service';

export class AuthenticationObject {
  private password: string;
  private sessionToken: string | null;
  private sessionTokenTimeoutSeconds: number;
  private timeoutId: any;
  private isSessionSupported: boolean = true;

  /**
   *
   * @param password
   * @param isSessionSupported Older nanos (<1.3) does not have auth session object feature,
   * we have to remember and use password for authentication
   */
  constructor(password: string, isSessionSupported: boolean = true) {
    this.password = password;
    this.isSessionSupported = isSessionSupported;
  }

  public setSession(session: { token: string; ttl: number }): void {
    //console.log('Creating client session with data', session);
    this.sessionToken = session.token;
    this.sessionTokenTimeoutSeconds = session.ttl;
    this.resetTokenTimeout();
  }

  public resetTokenTimeout(): void {
    //console.log(`Resetting token timeout (${this.sessionTokenTimeoutSeconds}s)`);
    if (this.timeoutId !== null) clearTimeout(this.timeoutId);
    this.timeoutId = this.createTokenTimeout();
  }

  public getAuthentication(): { admin_password: string } | { admin_session: string } {
    if (this.isSessionSupported) {
      const authentication =
        this.sessionToken !== undefined
          ? { admin_session: this.sessionToken }
          : { admin_password: this.password };
      return authentication;
    } else {
      return { admin_password: this.password };
    }
  }

  private createTokenTimeout(): any {
    return setTimeout(() => {
      console.log('Nano Client login token expired and purged:', this.sessionToken);
      this.sessionToken = null;
      this.timeoutId = null;
    }, this.sessionTokenTimeoutSeconds * 1000);
  }
}

/**
 * For mutations requests we need a token.
 * 1 user can use max 32 tokens per nano
 * if you start a request with a token, which is under process with an earlier request. It can
 * ruin the earlier call. So lock that token in this case.
 */
export type MutationToken = {
  token: Uint8Array;
  locked: boolean;
  counter: number;
  expired: boolean;
};

export abstract class NanoRequestBase {
  protected request;
  protected payloads = [];

  /**
   * Create the valid request message. Defined by the server
   * @returns
   */
  public getRequest() {
    return this.request;
  }

  /**
   * incoming chunk
   * @param payload
   */
  public push(payload): Promise<void> {
    this.payloads.push(payload);
    return Promise.resolve();
  }

  /**
   * The message coming as parts from the server. The parts's structure are depend on the request type (getDir, getFile, etc)
   * Override this merge function to fit the actual request
   * @param payloads an array, what contains all of the msg part
   * @returns the message from the server
   */
  public abstract mergePayloads();

  public reset(): Promise<void> {
    this.payloads = [];
    return Promise.resolve();
  }

  /**
   * clean up after request done or paused request delete
   * Note: On mobile device if we put the app in the background, we can keep it alive if
   * it is still some download/upload running. After request delete we need to call a close
   * to the local webserver so it can clean up its memory. Otherwise some process will stuck
   * in it's onprogress list after pause+delete.
   */
  public close(): Promise<void> {
    return Promise.resolve();
  }

  public setMutationToken(mutationToken: MutationToken) {
    this.request['mut_key'] = mutationToken.token;
    this.request['mut_seq'] = mutationToken.counter;
  }

  public setAuth(authObject: AuthenticationObject) {
    Object.assign(this.request, authObject.getAuthentication());
  }
}

export class MutationTokenRequest extends NanoRequestBase {
  public constructor(roomId) {
    super();

    this.request = {
      type: 'MUT-CRT',
      room_id: roomId,
    };
  }

  public mergePayloads() {
    return Promise.resolve(this.payloads[0].token);
  }
}

export class MutationTokenAdminRequest extends NanoRequestBase {
  public constructor(nanoId) {
    super();

    this.request = {
      type: 'MUT-CRT',
      nano_id: nanoId,
    };
  }

  public mergePayloads() {
    return Promise.resolve(this.payloads[0].token);
  }
}

export class CreateAdminSessionRequest extends NanoRequestBase {
  public constructor(adminPassword: string, nanoId: number) {
    super();
    this.request = {
      type: 'A-NEW-SESS',
      admin_password: adminPassword,
      nano_id: nanoId,
    };
  }

  public mergePayloads() {
    return Promise.resolve(this.payloads[0]);
  }
}

export class NanoFileSystemDeleteRequest extends NanoRequestBase {
  public constructor(resourceId: string, path: string) {
    super();

    this.request = {
      type: 'FS-DEL',
      room_id: resourceId,
      path: path,
    };
  }

  public mergePayloads() {
    return Promise.resolve(true);
  }
}

export class NanoFilesystemMkdirRequest extends NanoRequestBase {
  public constructor(resourceId: string, path: string) {
    super();

    this.request = {
      type: 'FS-MKD',
      room_id: resourceId,
      path: path,
    };
  }

  public mergePayloads() {
    let result = this.payloads[0]['norm_name'] || true;
    // Nano 0.7.8+ returns "norm_name" key in response for mkdir, put and move. Put response will only have it for offset==0 requests.
    return Promise.resolve(result);
  }
}

export class NanoFilesystemMoveRequestRequest extends NanoRequestBase {
  public constructor(resourceId: string, srcPath: string, newPath: string) {
    super();

    this.request = {
      type: 'FS-MOV',
      room_id: resourceId,
      src_path: srcPath,
      new_path: newPath,
    };
  }

  public mergePayloads() {
    let result = this.payloads[0]['norm_name'] || true;
    // Nano 0.7.8+ returns "norm_name" key in response for mkdir, put and move. Put response will only have it for offset==0 requests.
    return Promise.resolve(result);
  }
}

export class NanoFilesystemPutRequest extends NanoRequestBase {
  public constructor(
    resourceId: string,
    path: string, // final target path of the upload
    size, // final size of the file in bytes
    mtime, // unix timestamp when the file was last modified; in milliseconds
    offset, // number of bytes to offset the writing of the data chunk
    data, // data chunk, optimally ~1MB
    smac, // sum-hash of all parts in order
    overwrite: boolean, // to allow overwriting the target path
    autoName: string // automatically select an upload location and filename based on this name
  ) {
    super();

    this.request = {
      type: 'FS-PUT',
      room_id: resourceId,
      path,
      size,
      offset,
      data,
      mtime: Math.round(mtime),
      smac,
      overwrite,
      auto_name: autoName,
    };
  }

  public mergePayloads() {
    // Nano 0.7.8+ returns "norm_name" key in response for mkdir, put and move. Put response will only have it for offset==0 requests.
    return Promise.resolve({
      normName: this.payloads[0]['norm_name'],
      normPath: this.payloads[0]['norm_path'],
    });
  }
}

export abstract class NanoFilesystemGetRequestForFile extends NanoRequestBase {
  public constructor(resourceId: string, path: string) {
    super();
    this.request = {
      type: 'FS-GET',
      room_id: resourceId,
      path: path,
    };
  }
}

// drive api v5+
export class NanoFilesystemListRequest extends NanoRequestBase {
  public constructor(resourceId: string, path: string) {
    super();
    this.request = {
      type: 'FS-LS',
      room_id: resourceId,
      path: path,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      let list = [];
      this.payloads.forEach((payload) => {
        if (payload.ls) {
          list = list.concat(payload.ls);
        }
      });

      resolve(list);
    });
  }
}

export type NanoThumbnailResponse = {
  name: string;
  mtime: number;
  data: Uint8Array;
};

export class NanoFilesystemGetRequestForThumbnail extends NanoRequestBase {
  public mergePayloads() {
    let thmbList = [];
    this.payloads.forEach((chunk) => {
      if (chunk.ts) {
        let images = msgpack.decode(chunk.ts);
        images.forEach(([name, data, mtime]) => {
          let res: NanoThumbnailResponse = { name, data, mtime };
          thmbList.push(res);
        });
      }
    });
    return Promise.resolve(thmbList);
  }

  public constructor(resourceId: string, path: string, bucket: number = 1) {
    super();
    this.request = {
      type: 'FS-THMB',
      room_id: resourceId,
      path: path,
      // images stored in bucket batches, FS-LS can return with multiple backets
      // while FS-GET only use 1 bucker by default
      // we can decide which and how many bucket we want to load
      b: bucket,
    };
  }
}

export class NanoFilesystemGetRequestForFileAsBlob extends NanoFilesystemGetRequestForFile {
  /**
   * fs - filesize is in the init payload
   * fc - file chunk in every part
   * @param payloads
   */
  public mergePayloads() {
    return new Promise((resolve, reject) => {
      try {
        resolve(blobService.new(this.payloads)); //  { type: "application/octet-stream" }
      } catch (e) {
        reject(e);
      }
      /*console.log("file payload", this.payloads);

      try{
        let arr = [];
        for (let i = 0; i < this.payloads.length; i++) {
          arr.push(this.payloads[i].buffer);
        }
  
        resolve(concatArrayBuffer(arr));
      }catch(e){
        reject(e);
      }*/
    });
  }

  public close(): Promise<void> {
    this.payloads = [];
    return Promise.resolve();
  }
}

export interface FileSystemWritableFileStreamCommonInterface {
  write(payload: any): Promise<void>;
  close();
  truncate(size: number): Promise<void>;
}

export interface FileSystemWritableFileStreamMobile
  extends FileSystemWritableFileStreamCommonInterface {
  getId(): string;
  getPath(): string;
}

export class NanoFilesystemGetRequestForFileAsFileStream extends NanoFilesystemGetRequestForFile {
  private fileSystemWritableFileStream: any;

  public constructor(
    resourceId: string,
    path: string,
    fileSystemWritableFileStream: FileSystemWritableFileStreamCommonInterface = null
  ) {
    super(resourceId, path);
    this.fileSystemWritableFileStream = fileSystemWritableFileStream;
  }

  public push(payload) {
    return this.fileSystemWritableFileStream.write(payload);
  }

  public mergePayloads() {
    return Promise.resolve();
  }

  public reset() {
    return this.fileSystemWritableFileStream.truncate(0);
  }

  public close(): Promise<void> {
    // known Chrome rejections:
    // DOMException: An operation that depends on state cached in an interface object was made but the state had changed since it was read from disk.
    // DOMException: Blocked by Safe Browsing.
    // The writableStream object stuck in a locked error state
    // only way to resolve this, to restart the download with a new stream
    return this.fileSystemWritableFileStream.close();
  }
}

export class NanoFilesystemGetRequestForDirectory extends NanoRequestBase {
  public constructor(resourceId: string, path: string) {
    super();
    this.request = {
      type: 'FS-GET',
      room_id: resourceId,
      path: path,
    };
  }

  /**
   * There is an "ls" attribute, what contains the file list in an array
   * @param payloads
   */
  public mergePayloads() {
    return new Promise((resolve, reject) => {
      let list = [];
      this.payloads.forEach((payload) => {
        list.splice(this.findOverlapStartIndex(list, payload.ls));
        list = list.concat(payload.ls);
      });

      resolve(list);
    });
  }

  private findOverlapStartIndex(firstArray, nextArray) {
    let overlapMaxDistanceCheck = 50;

    if (nextArray.length == 0) return firstArray.length;

    for (let i = firstArray.length - 1; i > firstArray.length - overlapMaxDistanceCheck; i--) {
      if (firstArray[i]?.name == nextArray[0]?.name) {
        return i;
      }
    }
    return firstArray.length;
  }
}

export enum PeekOptionFlag {
  NO_IMAGE = 0,
  THUMBNAIL = 1,
  PREVIEW = 2,
  PREVIEW_SIZE_INFO = 4,
}

export type PeekResult = {
  img0?: Uint8Array;
  mtime: number;
  name: string;
  preview?: Blob;
  size: number;
  img?: 'stale'; // DRIVE_API_V5 if exists: thumbnail and preview making is under process
  ps?: number;
};

export class NanoFilesystemPeekRequest extends NanoRequestBase {
  public constructor(
    resourceId: string,
    path: string,
    optionFlags: number = PeekOptionFlag.THUMBNAIL |
      PeekOptionFlag.PREVIEW |
      PeekOptionFlag.PREVIEW_SIZE_INFO
  ) {
    super();
    this.request = {
      type: 'FS-PEEK',
      room_id: resourceId,
      path: path,
      o: optionFlags,
    };
  }

  /**
   * There is an "ls" attribute, what contains the file list in an array
   * @param payloads
   */
  public mergePayloads() {
    let infos: PeekResult = this.payloads[0].pk;

    let previewBlob = [];
    for (let i = 1; i < this.payloads.length; i++) {
      previewBlob.push(this.payloads[i].pc);
    }

    if (previewBlob.length > 0) {
      infos['preview'] = new Blob(previewBlob);
    }

    return Promise.resolve(infos);
  }
}

export class NanoSearchRequest extends NanoRequestBase {
  public constructor(resourceId: string, query: string, lang: string) {
    super();

    this.request = {
      type: 'SEARCH',
      room_id: resourceId,
      query: query,
      language: lang,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      let res = [];
      this.payloads.forEach((msg) => {
        res = res.concat(msg.docs);
      });

      resolve(res);
    });
  }
}

export class NanoAdminGetInfoRequest extends NanoRequestBase {
  public constructor(nanoId: number) {
    super();

    this.request = {
      type: 'A-INFO',
      id: nanoId,
    };
  }

  public mergePayloads() {
    console.log('info', this.request, this.payloads);
    return new Promise((resolve, reject) => {
      resolve({
        name: this.payloads[0].name,
        version: this.payloads[0].version,
      });
    });
  }
}

export class NanoAdminGetRemoteConfigRequest extends NanoRequestBase {
  public constructor(nanoId: number) {
    super();

    this.request = {
      type: 'A-REM-CFG',
      id: nanoId,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve(this.payloads[0]['config']);
    });
  }
}

export class NanoAdminChangePasswordRequest extends NanoRequestBase {
  public constructor(nanoId: number, newPassword: string) {
    super();

    this.request = {
      type: 'A-CH-PWD',
      id: nanoId,
      new_password: newPassword,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve(true); // there is no payloads for this
    });
  }
}

export class NanoAdminGetListingRequest extends NanoRequestBase {
  public constructor(nanoId: number, path: string) {
    super();

    this.request = {
      type: 'A-LISTING',
      id: nanoId,
      path,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve(this.payloads[0].ls);
    });
  }
}

export class NanoAdminMkdirRequest extends NanoRequestBase {
  public constructor(nanoId: number, path: string, name: string) {
    super();

    this.request = {
      type: 'A-MKD',
      id: nanoId,
      path,
      name,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve({ createdName: this.request.name });
    });
  }
}

export class NanoAdminCreateDriveRequest extends NanoRequestBase {
  public constructor(nanoId: number, path: string) {
    super();

    this.request = {
      type: 'A-D-CREATE',
      id: nanoId,
      path,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}

export class NanoAdminDeleteDriveRequest extends NanoRequestBase {
  public constructor(nanoId: number, path: string) {
    super();

    this.request = {
      type: 'A-D-DELETE',
      id: nanoId,
      path,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}

export class NanoAdminAttachDriveRequest extends NanoRequestBase {
  public constructor(nanoId: number, path: string, roomId: string, roomBlocks: string[]) {
    super();

    this.request = {
      type: 'A-D-ATTACH',
      id: nanoId,
      path,
      target_room_id: roomId,
      room_blocks: roomBlocks,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}

export class NanoAdminDetachDriveRequest extends NanoRequestBase {
  public constructor(nanoId: number, path: string, roomId: string) {
    super();

    this.request = {
      type: 'A-D-DETACH',
      id: nanoId,
      path,
      target_room_id: roomId,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}

export class NanoAdminEditNameRequest extends NanoRequestBase {
  public constructor(nanoId: number, name: string) {
    super();

    this.request = {
      type: 'A-EDIT-N',
      id: nanoId,
      name,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}

export class NanoAdminEditDenyAnonymousRequest extends NanoRequestBase {
  public constructor(nanoId: number, value: boolean) {
    super();

    this.request = {
      type: 'A-EDIT-DENY-ANON',
      id: nanoId,
      value,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}

export class NanoAdminEditRequireExplicitPeerTrustRequest extends NanoRequestBase {
  public constructor(nanoId: number, value: boolean) {
    super();

    this.request = {
      type: 'A-EDIT-REQ-EXPLICIT-PEER-TRUST',
      id: nanoId,
      value,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}

export class NanoCreateRoomConfigBlockRequest extends NanoRequestBase {
  public constructor(resourceId: number, memberFlags: Object, groupFlags: number) {
    super();

    this.request = {
      type: 'ROOM-CFG',
      room_id: resourceId,
      member_flags: memberFlags,
      group_flags: groupFlags,
    };
  }

  public mergePayloads() {
    return new Promise((resolve, reject) => {
      resolve(this.payloads[0]['block_id']);
    });
  }
}

export type PrepareDocumentEditorInfo = {
  wvh: string; // WSD version hash
  wfi: string; // WOPI file-id
  wat: string; // WOPI access-token
};

// Prepare a selected file in drive to open in document-editor.
export class NanoPrepareDocumentEditor extends NanoRequestBase {
  public constructor(resourceId: string, path: string) {
    super();

    this.request = {
      room_id: resourceId,
      type: 'DOC-PRE',
      path,
    };
  }

  public mergePayloads() {
    return Promise.resolve(<NanoPrepareDocumentEditor>this.payloads[0]);
  }
}

// Open a document viewing/editing WSD session. This will be called by the iframe socket-proxy when it starts up.
export class NanoOpenDocumentEditor extends NanoRequestBase {
  public constructor(resourceId: string, wsdWebsocketUrl: string) {
    super();

    this.request = {
      room_id: resourceId,
      type: 'DOC-OPN',
      wwu: wsdWebsocketUrl,
    };
  }

  public mergePayloads() {
    return Promise.resolve(this.payloads[0].session);
  }
}

export type DocumentEditorMessagesType = [number, number, number, Uint8Array][];

// Send stream-index mapped messages to a WSD session. This will be called by the iframe socket-proxy when the iframe wants to send something to WSD.
// NOTE: This is a unique mutating request that has a strict payload processing on its own, so it does not need the mutation-session protection.

export class NanoSendDocumentEditorMessage extends NanoRequestBase {
  public constructor(
    resourceId: string,
    documentEditorSessionToken: string,
    messages: DocumentEditorMessagesType
  ) {
    super();

    this.request = {
      room_id: resourceId,
      type: 'DOC-TX',
      s: documentEditorSessionToken,
      msgs: messages,
    };
  }

  public mergePayloads() {
    return Promise.resolve();
  }
}

export type DocumentEditorMessageData = {
  salt: Uint8Array;
  messages: DocumentEditorMessagesType;
};

// Read stream-index mapped messages from a WSD session. This will be called by the iframe socket-proxy when the WSD session opens. It will be open as long as the document/session is alive.

export class NanoReadDocumentEditorMessage extends NanoRequestBase {
  public constructor(resourceId: string, documentEditorSessionToken: string) {
    super();

    this.request = {
      room_id: resourceId,
      type: 'DOC-RX',
      s: documentEditorSessionToken,
    };
  }

  public mergePayloads() {
    return Promise.resolve();
  }

  public push(payload) {
    return Promise.resolve();
  }
}

// Close a WSD session. This will be called by the iframe socket-proxy when the iframe closes the document or the iframe itself is deleted.
export class NanoCloseDocumentEditor extends NanoRequestBase {
  public constructor(resourceId: string, documentEditorSessionToken: string) {
    super();

    this.request = {
      room_id: resourceId,
      type: 'DOC-CLS',
      s: documentEditorSessionToken,
    };
  }

  public mergePayloads() {
    return Promise.resolve();
  }
}

export class NanoAdminLogStream extends NanoRequestBase {
  /**
   *
   * @param name access or service
   * @param openNew if true the stream will be reset (and not continue a previous one)
   */
  public constructor(nanoId: number, name: string, openNew: boolean = false) {
    super();

    this.request = {
      name,
      type: 'A-LOG-S',
      new: openNew,
      id: nanoId,
    };
  }

  public mergePayloads() {
    return Promise.resolve(this.payloads);
  }
}

export class NanoAdminLogReadBackward extends NanoRequestBase {
  /**
   *
   * @param name access or service
   */
  public constructor(nanoId: number, name: string) {
    super();

    this.request = {
      name,
      type: 'A-LOG-R',
      id: nanoId,
    };
  }

  public push(payload) {
    let part: AdminLog = payload['bck'];

    if (part.compression) {
      return decompressUint8Array(part.logs, part.compression).then((log) => {
        return log.arrayBuffer().then((buff) => {
          this.payloads.push({
            end: part.end || false,
            log: new TextDecoder('utf-8').decode(new Uint8Array(buff).reverse()),
          });
          return;
        });
      });
    } else {
      this.payloads.push({
        end: part.end || false,
        log: new TextDecoder('utf-8').decode(part.logs.reverse()),
      });
      return;
    }
  }

  public mergePayloads() {
    return Promise.resolve(this.payloads);
  }
}

export function decompressUint8Array(compressedData: Uint8Array, compressAlgorithm: string) {
  const ds = new window['DecompressionStream'](<any>compressAlgorithm);
  const decompressedStream = new Blob([compressedData]).stream().pipeThrough(ds);
  return new Response(decompressedStream).blob();
}

export type AdminLog = {
  logs: Uint8Array;
  compression?: string;
  new?: boolean;
  end?: boolean;
};
