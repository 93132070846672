<div id="emoji-shordcode-container">
  <app-emoji-shortcode-picker [style.display]="shortcodePrefix.length >= 2 ? 'block' : 'none'" (selectEvent)="handleEmojiAutocomplete($event)" [prefix]="shortcodePrefix"></app-emoji-shortcode-picker>
</div>
<div id="style-menu" #styleMenu *ngIf="toggleStyleMenu">
  <div id="style-menu-inner">
    <mat-icon (click)="setFormat(TOKEN_TYPE.BOLD)">format_bold</mat-icon>
    <mat-icon (click)="setFormat(TOKEN_TYPE.ITALICS)">format_italics</mat-icon>
    <mat-icon (click)="setFormat(TOKEN_TYPE.UNDERLINE)">format_underline</mat-icon>
    <mat-icon (click)="setFormat(TOKEN_TYPE.STRIKETHROUGH)">format_strikethrough</mat-icon>
    <mat-icon (click)="setFormat(TOKEN_TYPE.QUOTES)">format_quotes</mat-icon>
    <mat-icon (click)="setFormat(TOKEN_TYPE.INLINE_CODE)">code</mat-icon>
  </div>
</div>
<slate-editable
  #slateEditorElement
  [editor]="editor"
  [(ngModel)]="value"
  [renderElement]="renderElement"
  [renderLeaf]="renderLeaf"
  [keydown]="keydown"
  [placeholder]="placeholder"
  [autoCorrect]="true"
  (ngModelChange)="valueChange($event)"
>
  <ng-template #paragraph let-context="context" let-viewContext="viewContext"> <p slateElement [context]="context" [viewContext]="viewContext"></p> </ng-template>
</slate-editable>
