import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Subject } from 'rxjs';
import { ImageCropperDialogComponent } from 'src/app/shared/dialogs/image-cropper-dialog/image-cropper-dialog.component';
import { NanoManagerDialogComponent } from 'src/app/shared/dialogs/nano-manager-dialog/nano-manager-dialog.component';
import { AccountService } from 'src/app/shared/server-services/account.service';
import { AuthService } from 'src/app/shared/server-services/auth.service';
import { MeRecord, UserRole } from 'src/app/shared/server-services/query-records/account-records';
import { TimedOfferRecord } from 'src/app/shared/server-services/query-records/timed-offer-records';
import { getTimedOffersQuery } from 'src/app/shared/server-services/querys';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from 'src/app/shared/server-services/subscription-event';
import { SubscriptionService } from 'src/app/shared/server-services/subscription.service';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ServerRestApiService } from 'src/app/shared/services/server-rest-api.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnDestroy {
  public me: MeRecord;

  public isDistributor: boolean = false;
  public offersAvailable: boolean = false;
  public isAnonym: boolean = false;
  private destroyEvent = new Subject<void>();
  public webDocLink: string = marker('https://www.clarabot.info/nano/en/');

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private serverRestApi: ServerRestApiService,
    private subscriptionService: SubscriptionService,
    private clipboardService: ClipboardService,
    private snackbarService: SnackBarService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
    this.subscriptionService.subscribeWorkspaceLoaded(() => {
      this.isAnonym = this.authService.isAnonym();
      if (!this.isAnonym) {
        this.serverRestApi
          .query({ query: getTimedOffersQuery })
          .then((offers: TimedOfferRecord[]) => {
            this.offersAvailable = offers && offers.length > 0;
          });
      }
    });
  }

  onAccountChange = () => {
    this.accountService.getMe().then((data) => {
      this.me = Object.assign({}, data);
    });
  };

  ngOnInit(): void {
    this.subscriptionService.subscribeWorkspaceLoaded(() => {
      this.accountService
        .getMe()
        .then((data: MeRecord) => {
          this.me = data;
          this.isDistributor = this.accountService.isCurrentUserInRole(
            UserRole.DISTRIBUTOR,
            this.me
          );
        })
        .catch((err) => console.error(err));
    });

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ACCOUNT_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onAccountChange
    );
  }

  ngOnDestroy() {
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ACCOUNT_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onAccountChange
    );

    this.destroyEvent.next();
  }

  copyContactId() {
    this.clipboardService
      .copy(this.me.id)
      .then(() => {
        this.snackbarService.showSnackbar(marker('Contact ID copied!'));
      })
      .catch((err) => {
        console.error('clipboard err', err);
        this.dialogService.openAlertDialog(
          marker('Copy Error'),
          marker('Could not copy to the clipboard')
        );
      });
  }

  openNanoManagerDialog(): void {
    this.dialog.open(NanoManagerDialogComponent, { autoFocus: false });
  }

  openEditAvatarDialog(): void {
    var ref = this.dialog.open(ImageCropperDialogComponent, { autoFocus: false });
    ref.afterClosed().subscribe((avatarImageBlob: Blob) => {
      if (!avatarImageBlob) return;

      avatarImageBlob.arrayBuffer().then((res: ArrayBuffer) => {
        this.accountService
          .setAvatar(res)
          .then((res) => {
            this.me.avatarImageKey = res;
          })
          .catch((err) => console.error('set avatar', err))
          .then(() => {
            this.accountService.getAvatarImage(this.me, true);
          });
      });
    });
  }
}
