<tr
  class="desktop-view"
  [ngClass]="{ 'selected-file': selected, 'focused-file': focus, unresolved: file.unresolved, 'error-happened': errorHappened }"
  [ngStyle]="{ 'background-size': progress + '%' }"
>
  <td class="file-image" *ngIf="!file.thumbnail">
    <span class="mdi mdi-{{ file.icon }} file-{{ file.icon }}"></span> <img class="nano-icon" src="/assets/img/ui/icon_nano.svg" *ngIf="file.icon === 'folder' && file.name === 'Nano'" />
  </td>
  <td class="file-image" *ngIf="file.thumbnail">
    <app-blob-viewer [blob]="file.thumbnail" type="image"></app-blob-viewer>
  </td>
  <td class="file-name mat-small">
    <span class="notranslate">{{ file.name }}</span>
  </td>
  <td class="file-ext mat-small" class="notranslate" *ngIf="!file.unresolved">{{ file.ext }}</td>
  <td class="file-ext mat-small center" *ngIf="file.unresolved">{{ file.symlink ? 'symlink' : '-' }}</td>

  <td class="file-size mat-small" *ngIf="!file.unresolved">{{ file.size | formatBytes }}</td>
  <td class="file-size mat-small center" *ngIf="file.unresolved">-</td>

  <td class="file-date mat-small" *ngIf="!file.unresolved">{{ file.createdAt | date : 'short' }}</td>
  <td class="file-date mat-small center" *ngIf="file.unresolved">-</td>
</tr>

<tr class="mobile-view" [ngClass]="{ 'selected-file': selected, 'focused-file': focus, unresolved: file.unresolved, errorHappened: errorHappened }" [ngStyle]="{ 'background-size': progress + '%' }">
  <td class="file-image" *ngIf="!file.thumbnail">
    <span class="mdi mdi-{{ file.icon }} file-{{ file.icon }}"></span>
  </td>
  <td class="file-image" *ngIf="file.thumbnail">
    <app-blob-viewer [blob]="file.thumbnail" type="image"></app-blob-viewer>
  </td>
  <td class="file-name" [attr.colspan]="!isSelectingMode && file.ext !== 'dir' ? '2' : '1'">
    <div class="file-name-content">
      <div>
        <span class="notranslate">{{ file.name }}</span>
      </div>
      <div *ngIf="file.ext !== 'dir'" class="file-size-date">
        <span class="mat-small" *ngIf="!file.unresolved">{{ file.createdAt | date : 'short' }} - {{ file.size | formatBytes }}</span>
        <span class="mat-small" *ngIf="file.unresolved"> - </span>
      </div>
    </div>
  </td>
  <td class="dir-arrow" *ngIf="!isSelectingMode && file.ext === 'dir'" (click)="onNavigate()">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </td>
  <td class="checkbox-container" *ngIf="isSelectingMode">
    <mat-checkbox color="primary" [checked]="selected"></mat-checkbox>
  </td>
</tr>
