import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { DriveFile } from 'src/app/components/resource-page/drive-window/drive-layout/drive-file';
import { isNanoFeatureSupported, NanoFeature } from '../../drive-version';
import { NanoThumbnailResponse } from '../../nano/nano-requests';
import { NanoService } from '../../nano/nano.service';
import { ObjectSorter } from '../../object-sorter';
import { ID } from '../../server-services/query-records/common-records';
import { RoomService } from '../../server-services/room.service';
import { BlobService } from '../../services/blob.service';
import { DialogService } from '../../services/dialog.service';
import { NanoActionQueueService } from '../../services/nano-action-queue.service';
import { NanoDialogBaseComponent } from '../nano-base-dialog';
import { NewFolderDialogComponent } from '../new-folder-dialog/new-folder-dialog.component';

export type FilePickerDialogData = {
  roomId: ID;
  startPath?: string;
  onlyDirectory: boolean;
  multiSelection: boolean;
  title?: string;
};

export type FileState = {
  file: DriveFile;
  selected: boolean;
  dir: boolean;
};

export type FilePickerDialogResult = {
  path: string;
  files: DriveFile[];
};

export let FILE_PICKER_DIALOG_ROOT = null;

@Component({
  selector: 'app-file-picker-dialog',
  templateUrl: './file-picker-dialog.component.html',
  styleUrls: ['./file-picker-dialog.component.scss'],
})
export class FilePickerDialogComponent extends NanoDialogBaseComponent<NewFolderDialogComponent> {
  public path: DriveFile[] = [];
  public selectedFiles: FileState[] = [];
  public loadError: boolean = false;
  public loadInprogress: boolean = false;
  public fileStates: FileState[] = [];

  constructor(
    public dialogRef: MatDialogRef<NewFolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilePickerDialogData,
    private nanoActionQueueService: NanoActionQueueService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private dialogService: DialogService,
    @Inject(INJECTOR) injector: Injector,
    private roomService: RoomService,
    private nanoService: NanoService,
    private blobService: BlobService
  ) {
    super(injector);

    if (this.data.startPath) {
      this.path = this.createDriveFilePathFromStringPath(this.data.startPath);
    }

    this.listDirectory();
  }

  private createDriveFilePathFromStringPath(path: string): DriveFile[] {
    if (path.length == 0) return [];

    let result: DriveFile[] = [];

    let slicedPath = path.split('/');
    let concatedPath = '';
    slicedPath.forEach((dirName) => {
      concatedPath += dirName;
      result.push(
        new DriveFile({
          name: dirName,
          path: concatedPath,
          size: undefined, // dir
          mtime: 0,
          resourceId: this.data.roomId,
        })
      );
      concatedPath += '/';
    });

    return result;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSubmit() {
    this.dialogRef.close(this.path);
  }

  private getPathAsString(): string {
    let result = '';
    this.path.forEach((dir) => {
      if (result.length > 0) {
        result += '/';
      }
      result += dir.fullName;
    });
    return result;
  }

  public goUp() {
    this.path.pop();
    this.listDirectory();
  }

  public goNext(state: FileState, event?: MouseEvent) {
    event && event.stopPropagation();
    this.path.push(state.file);
    this.listDirectory();
  }

  public select(state: FileState) {
    if (this.data.multiSelection) {
      if (state.selected) {
        this.selectedFiles.splice(this.selectedFiles.indexOf(state), 1);
        state.selected = false;
      } else {
        this.selectedFiles.push(state);
        state.selected = true;
      }
    } else {
      if (state.selected) {
        this.selectedFiles = [];
        state.selected = false;
      } else {
        this.selectedFiles.forEach((s) => {
          s.selected = false;
        });
        this.selectedFiles = [state];
        state.selected = true;
      }
    }
  }

  public doubleClick(state: FileState) {
    if (state.dir) {
      this.goNext(state);
    } else {
      this.select(state);
    }
  }

  public listDirectory() {
    if (this.loadInprogress) return;

    this.loadInprogress = true;
    this.loadError = false;

    let pathString = this.getPathAsString();

    let errorHandler = (err) => {
      console.error('list error', err);
      this.dialogService.openAlertDialog(marker('Error'), marker('Listing error'), err);
      this.fileStates = [];
      this.selectedFiles = [];
      this.loadInprogress = false;
      this.loadError = true;
    };

    this.roomService
      .getNanoSession(this.data.roomId)
      .then((nanoSession) => {
        this.nanoActionQueueService.listDir(
          this.data.roomId,
          pathString,
          nanoSession.version,
          (list) => {
            let files = [];
            list.forEach((file) => {
              if (
                !this.data.onlyDirectory ||
                file.size === undefined // dir has undefined size
              ) {
                file.path = pathString.length > 0 ? pathString + '/' + file.name : file.name;
                file.resourceId = this.data.roomId;
                files.push(new DriveFile(file));
              }
            });

            let orderedFiles = this.reorderBy(files, 'name');

            this.fileStates = [];
            orderedFiles.forEach((f) => {
              this.fileStates.push({ file: f, selected: false, dir: f.ext == 'dir' });
            });
            this.selectedFiles = [];

            this.loadThumbnails();

            this.loadInprogress = false;
          },
          errorHandler
        );
      })
      .catch(errorHandler);
  }

  private loadThumbnails(): void {
    this.roomService.getNanoSession(this.data.roomId).then((nanoSession) => {
      if (
        nanoSession &&
        isNanoFeatureSupported(nanoSession.version, NanoFeature.THUMBNAIL) &&
        this.fileStates.length != 0
      ) {
        let pathString = this.getPathAsString();
        let files = [];
        this.fileStates.forEach((state) => {
          files.push(state.file);
        });
        this.nanoService.getFileThumbnail(
          this.data.roomId,
          pathString,
          files,
          1,
          (thumbnails: NanoThumbnailResponse[]) => {
            thumbnails.forEach((thmb) => {
              let file = files.find((f) => f.fullName === thmb.name);
              if (file) {
                file.thumbnail = this.blobService.new([thmb.data.buffer]); // {type: "image/jpeg"}
              }
            });
          },
          (err) => {
            console.error('Thumbnail load error', err);
          },
          () => {}
        );
      }
    });
  }

  public openCreateFolderDialog() {
    if (this.loadInprogress) return;

    this.loadInprogress = true;

    const dialogRef = this.dialog.open(NewFolderDialogComponent, {
      data: { name: this.translate.instant(marker('Folder')) },
    });

    dialogRef.afterClosed().subscribe((name) => {
      if (name && name.length > 0) {
        this.createFolder(name);
      } else {
        this.loadInprogress = false;
      }
    });
  }

  private createFolder(name) {
    this.loadInprogress = true;

    let fullPath = name;
    if (this.path.length > 0) {
      fullPath = this.getPathAsString() + '/' + name;
    }

    this.nanoActionQueueService.makeDir(
      this.data.roomId,
      fullPath,
      () => {
        this.loadInprogress = false;
        this.path.push(
          new DriveFile({
            name: name,
            path: fullPath,
            size: undefined, // dir
            mtime: 0,
            resourceId: this.data.roomId,
          })
        );
        this.listDirectory();
      },
      (err) => {
        console.error('error', err);
        let msg: any = marker('Error happened during the operation');
        if (err.error == 4218) {
          msg = marker('Folder is already exists');
        }
        if (err.error == 4216) {
          msg = marker('Permission is required');
        }

        this.loadInprogress = false;
        this.dialogService.openAlertDialog(marker('Error'), msg, err);
      }
    );
  }

  public reorderBy(files: DriveFile[], attr: string, direction: number = 1) {
    return files.sort((a, b) => {
      if (a.ext == 'dir' && b.ext != 'dir') return -1;
      if (a.ext != 'dir' && b.ext == 'dir') return 1;
      if (a.ext == 'dir' && b.ext == 'dir' && attr == 'size') {
        return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1;
      }

      return ObjectSorter.getCollator().compare(a[attr], b[attr]) * direction;
    });
  }

  public selectAll() {
    if (this.selectedFiles.length >= this.fileStates.length) {
      this.selectedFiles.forEach((f) => {
        f.selected = false;
      });
      this.selectedFiles = [];
    } else {
      this.fileStates.forEach((f) => {
        if (!f.selected) {
          f.selected = true;
          this.selectedFiles.push(f);
        }
      });
    }
  }

  public choose() {
    let resultFiles = [];
    this.selectedFiles.forEach((f) => {
      resultFiles.push(f.file);
    });
    this.dialogRef.close({
      path: this.getPathAsString(),
      files: resultFiles,
    });
  }

  public chooseCurrentFolder() {
    let selectedFile = this.path.pop() || FILE_PICKER_DIALOG_ROOT;
    this.dialogRef.close({
      path: this.getPathAsString(),
      files: [selectedFile],
    });
  }
}
