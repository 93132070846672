import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AppStorage } from 'src/app/shared/app-storage';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ServerRestApiService } from 'src/app/shared/services/server-rest-api.service';
import { styleChangedObserver } from 'src/app/shared/shared-observers';

@Component({
  selector: 'app-account-recovery',
  templateUrl: './account-recovery.component.html',
  styleUrls: ['./account-recovery.component.scss'],
})
export class AccountRecoveryComponent {
  public isPending: boolean = false;
  public isSendSuccess: boolean = false;
  public isDarkTheme: boolean = true;

  emailForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private restApiService: ServerRestApiService,
    private dialogService: DialogService,
    private fb: FormBuilder
  ) {
    this.isDarkTheme = AppStorage.getItem('style_mode') == 'dark';
    styleChangedObserver.subscribe((style) => {
      this.isDarkTheme = style == 'dark';
    });
  }

  public start() {
    if (this.isPending) {
      console.warn('operation is already in progress');
      return;
    }

    this.isPending = true;
    this.restApiService
      .accountRecoveryPrepare(this.emailForm.get('email').value)
      .then(() => {
        this.isSendSuccess = true;
      })
      .catch((err) => {
        console.log('account rec error', err);
        this.dialogService.openAlertDialog(
          marker('Error'),
          marker('Error happened during the operation')
        );
      })
      .finally(() => {
        this.isPending = false;
      });
  }
}
