declare const Capacitor;
import type {
  BlobSaverPlugin,
} from '../../../../blobsaver/src/definitions';

export class BlobSaverAdapter {
  private blobSaverAuthToken?: string;

  public getPort = () => {
    return Capacitor.Plugins.BlobSaver.getPort().then((res) => {
      return res.port;
    });
  };

  public getAuthToken = () => {
    if (this.blobSaverAuthToken) return Promise.resolve(this.blobSaverAuthToken);

    return Capacitor.Plugins.BlobSaver.getAuthToken().then((res) => {
      this.blobSaverAuthToken = res.token;
      return res.token;
    });
  };

  public getCookie = (options: {
    url: string,
    key: string
  })=>{
    return (<BlobSaverPlugin>Capacitor.Plugins.BlobSaver).getCookie(options).then((res)=>{
      return res[options.key];
    })
  }

  setCookie(options: {
    url: string,
    key: string,
    value: string,
    expires: Date
  }){
    return (<BlobSaverPlugin>Capacitor.Plugins.BlobSaver).setCookie(options);
  }

  removeCookie(options: {
    url: string,
    key: string
  }){
    return (<BlobSaverPlugin>Capacitor.Plugins.BlobSaver).removeCookie(options);
  }

  /**
   *
   * @param {id || name, close?, truncate?, overwrite: String("{base}, {ext}, {x}")} options -
   * @param {Blob} blob - File data
   * @returns {id?, name?} resp
   */
  uploadFile = (
    options: {
      id?: any;
      name?: string;
      close?: boolean;
      truncate?: boolean;
      overwrite?: string;
    },
    blob: Blob = new Blob([])
  ) => {
    return this.getPort().then((port) => {
      return this.getAuthToken().then((auth) => {

        const queryParams = new URLSearchParams(options as any).toString();
        return fetch('http://localhost:' + port + '?' + queryParams, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: auth,
          },
          body: blob,
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }).then((resp) => {
          return resp.json().then((result) => {
            if (result.error) {
              // fetch does not throw error on normally responded server error
              throw new Error(result.message);
            } else {
              return result;
            }
          });
        });
      });
    });
  };

  createFile = (name: string, blob: Blob, overwrite?: string, close?: boolean) => {
    return this.uploadFile({ name, close, overwrite }, blob);
  };

  appendFile = (id: any, blob: Blob, close?: boolean) => {
    return this.uploadFile({ id, close }, blob);
  };

  truncateFile = (id: any) => {
    return this.uploadFile({ id, truncate: true });
  };

  closeFile = (id: any) => {
    return this.uploadFile({ id, close: true });
  };

  getFileWriter = (
    fileName: string,
    overwrite?: string,
    convertPayload?: (blob: Blob) => Promise<Blob>
  ) => {
    return this.createFile(fileName, new Blob([]), overwrite).then((result) => {
      return {
        write: (blob: Blob) => {
          if (convertPayload) {
            return convertPayload(blob).then((payload) => {
              return this.appendFile(result.id, payload);
            });
          } else {
            return this.appendFile(result.id, blob);
          }
        },
        close: () => {
          return this.closeFile(result.id);
        },
        truncate: (size: number) => {
          return this.truncateFile(result.id);
        },
        getName: () => {
          return result.name;
        },
        getId: () => {
          return result.id;
        },
        getPath: () => {
          return result.path;
        },
      };
    });
  };
}
