import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

// Predefined range: 42xx
export enum ServerError {
  /**
   * Generic error, when the unexpected happens.
   */
  NANO_REQUEST_ERROR = 4200,
  /**
   * The conflict error that means request would process, but the client's cache or transfer key is stale.
   */
  NANO_REQUEST_ERROR_CONFLICT = 4201,
  /**
   * Pre-parsing errors mean the request is not even valid syntactically. This could mean a Nano is unable to
   * understand the server because it is too old or the client actually created a not parsable request.
   */
  NANO_REQUEST_ERROR_PREPARSE = 4202,
  /**
   * E2E payload parsing failed or decryption was not correct. Unless the Nano is too old to understand the
   * crypto-context's used config, this is an error on the client side likely.
   */
  NANO_REQUEST_ERROR_PAYLOAD_PARSE = 4203,
  /**
   * E2E payload embedded an invalid or forbidden handler type. This is an error on the client side.
   */
  NANO_REQUEST_ERROR_PAYLOAD_TYPE_INVALID = 4204,
  /**
   * E2E payload embedded an invalid room_id. This is an error on the client side.
   */
  NANO_REQUEST_ERROR_PAYLOAD_ROOM_INVALID = 4205,
  /**
   * Request processing is denied for user. Could be by their identity, by policy of room or local policy.
   */
  NANO_REQUEST_ERROR_DENIED = 4206,
  /**
   * Request processing encountered temporary internal error. Maybe all workers are dead, backend is not ready, etc.
   */
  NANO_REQUEST_ERROR_TEMPORARY_INTERNAL_FAILURE = 4207,
  /**
   * Request processing encountered internal error.
   */
  NANO_REQUEST_ERROR_INTERNAL_FAILURE = 4208,
  /**
   * Request processing timed-out.
   */
  NANO_REQUEST_ERROR_TIMEOUT = 4209,
  /**
   * Request type did not map to a handler.
   */
  NANO_REQUEST_ERROR_UNKNOWN_TYPE = 4210,
  /**
   * Requested part is out of range.
   */
  NANO_REQUEST_ERROR_PART_RANGE = 4211,
  /**
   * Request requires a valid admin_password.
   */
  NANO_REQUEST_ERROR_ADMIN_PASSWORD = 4212,
  /**
   * Request processing encountered an error by the handler's config. No request retry or modification will circumvent it.
   */
  NANO_REQUEST_ERROR_HANDLER_FAILURE = 4213,
  /**
   * Request processing encountered an error by the handler's config. This error is local and retry could solve it.
   */
  NANO_REQUEST_ERROR_HANDLER_TEMPORARY_FAILURE = 4214,
  /**
   * Request processing encountered an error in the handler. Request may be invalid for handler or execution failed.
   */
  NANO_REQUEST_ERROR_HANDLER_BAD_REQUEST = 4215,
  /**
   * Request processing is denied for user by the handler, most likely because of authorization policy.
   */
  NANO_REQUEST_ERROR_HANDLER_DENIED = 4216,
  /**
   * Request processing was rejected by some conflict. The process the client was attempting shall be reset for retry.
   */
  NANO_REQUEST_ERROR_HANDLER_RESET_REQUEST = 4217,
  /**
   * Request processing was rejected. The target state already exists or is in conflict with the requested one.
   */
  NANO_REQUEST_ERROR_HANDLER_TARGET_EXISTS = 4218,
  /**
   * Request was not provided or had an invalid mutation-session id and/or sequence.
   */
  NANO_REQUEST_ERROR_MUTATION_ID = 4219,
  /**
   * Peer's identity is broken by trusted fingerprint or TOFU database. The owner must verify their identity for
   * communications to be allowed again.
   */
  NANO_REQUEST_ERROR_PEER_NOT_TRUSTED = 4220,
  /**
   * Request requires a valid admin_password, but none has been set up. Revealing that the admin password is not setup
   * is not a security risk, because this information will only ever be send to a request of the owner.
   */
  NANO_REQUEST_ERROR_ADMIN_PASSWORD_MISSING = 4221,
  /**
   * Request was valid, but the system could not comply with finishing the operation this time. This most likely
   * indicates the issue when a temporary policy/file locking by some security software is preventing an operation.
   */
  NANO_REQUEST_ERROR_SHARE_LOCKED_OPERATION = 4222,
  /**
   * Request processing encountered an error. The cause of the error is likely some OS policy, resource limitation or
   * resource unavailability. It is not known if retrying the request is beneficial or not.
   */
  NANO_REQUEST_ERROR_HANDLER_UNSPECIFIED_FAILURE = 4223,
  /**
   * Request processing was rejected. The target was not found, may be inaccessible if valid at all.
   */
  NANO_REQUEST_ERROR_HANDLER_TARGET_NOT_FOUND = 4224,
  /**
   * Request was declined because the chosen file or folder name is not valid for the current operating system.
   */
  NANO_REQUEST_ERROR_HANDLER_INVALID_NAME = 4225,
  /**
   * The Nano Client demands the use of session, instead of password.
   */
  NANO_REQUEST_ERROR_ADMIN_SESSION = 4226,
  /**
   * Throttling error code is made to be akin to the canonical HTTP throttling status code (429). When this is returned
   * either the Nano rate-limited the client or the server rate-limited the Nano (and the client by extension).
   */
  NANO_REQUEST_ERROR_TOO_MANY_REQUESTS = 4290,
  /**
   * Too many auth failures, client must wait for retry.
   */
  NANO_REQUEST_ERROR_TOO_MANY_AUTH_FAILS = 4291,
  /**
   * Reserved for debugging.
   */
  NANO_REQUEST_ERROR_DEBUG = 4299,
}

export class ServerErrorDetail {
  public errorEnum: ServerError;
  private errorDescription: string;

  constructor(errorEnum: ServerError) {
    this.errorEnum = errorEnum;
    this.errorDescription = this.errorCodeToMessage(this.errorEnum);
  }

  public getFullErrorDescription(translateService: TranslateService): string {
    return `[${this.errorEnum}]: ${translateService.instant(this.errorDescription)}`;
  }

  private errorCodeToMessage(errorCode: ServerError): string {
    let errorDescription: string;

    switch (errorCode) {
      case ServerError.NANO_REQUEST_ERROR_ADMIN_PASSWORD:
        errorDescription = marker(
          'The Nano Client password is missing or is incorrect, please log into the Nano Client again.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_ADMIN_PASSWORD_MISSING:
        errorDescription = marker(
          'The Nano Client has no admin password set-up, please set-up a Nano Client admin password.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_ADMIN_SESSION:
        errorDescription = marker(
          'The Nano Client demands the use of session, instead of password.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_CONFLICT:
        errorDescription = marker(
          'Nano Client cache mismatch, please log into the Nano Client again to refresh cache synchronization.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_HANDLER_BAD_REQUEST:
        errorDescription = marker('Request processing encountered an error.');
        break;
      case ServerError.NANO_REQUEST_ERROR_HANDLER_DENIED: // same as auth error
      case ServerError.NANO_REQUEST_ERROR_DENIED:
        errorDescription = marker('Authentication error, please log out and log in.');
        break;
      case ServerError.NANO_REQUEST_ERROR_HANDLER_FAILURE:
        errorDescription = marker(
          'Request could not be fulfille because of a Nano Client configuration setting.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_HANDLER_INVALID_NAME:
        errorDescription = marker(
          'Request was declined because the chosen file or folder name is not valid for the current operating system.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_HANDLER_TEMPORARY_FAILURE: // handler errors, retry could help
      case ServerError.NANO_REQUEST_ERROR_HANDLER_UNSPECIFIED_FAILURE:
      case ServerError.NANO_REQUEST_ERROR_HANDLER_RESET_REQUEST:
        errorDescription = marker(
          'Request process was rejected because of a conflict. Please try again.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_HANDLER_TARGET_EXISTS:
        errorDescription = marker(
          'Request process was rejected because the target already exists in some form.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_HANDLER_TARGET_NOT_FOUND:
        errorDescription = marker(
          'Request process was rejected because the target could not be found or is inaccessible.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_INTERNAL_FAILURE:
        errorDescription = marker(
          'Nano Client encountered an internal error. Please check the access logs if you are the owner of the Nano Client.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_MUTATION_ID:
        errorDescription = marker(
          'The request towards the Nano Client was malformed. (Missing or invalid mutationId, sessionId or sequence)'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_PART_RANGE:
        errorDescription = marker('Request part out of range.');
        break;
      case ServerError.NANO_REQUEST_ERROR_PAYLOAD_PARSE:
        errorDescription = marker('Payload parsing or decryption error.');
        break;
      case ServerError.NANO_REQUEST_ERROR_PAYLOAD_ROOM_INVALID:
        errorDescription = marker('Invalid room ID.');
        break;
      case ServerError.NANO_REQUEST_ERROR_PAYLOAD_TYPE_INVALID:
        errorDescription = marker('Invalid handler type for payload.');
        break;
      case ServerError.NANO_REQUEST_ERROR_PEER_NOT_TRUSTED:
        errorDescription = marker(
          "Peer's identity is broken by trusted fingerprint or TOFU database. The owner must verify their identity for communications to be allowed again."
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_PREPARSE:
        errorDescription = marker('Malformed request was made towards the Nano Client.');
        break;
      case ServerError.NANO_REQUEST_ERROR_SHARE_LOCKED_OPERATION:
        errorDescription = marker(
          'Request was valid, but the system could not comply with finishing the operation this time. This most likely indicates the issue when a temporary policy/file locking by some security software is preventing an operation.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_TEMPORARY_INTERNAL_FAILURE:
        errorDescription = marker('Servers overloaded. Please try again in a few minutes.');
        break;
      case ServerError.NANO_REQUEST_ERROR_TIMEOUT:
        errorDescription = marker('Request timed out.');
        break;
      case ServerError.NANO_REQUEST_ERROR_TOO_MANY_AUTH_FAILS:
        errorDescription = marker(
          'Too many failed authentication requests. Please wait a few minutes before trying again.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_TOO_MANY_REQUESTS:
        errorDescription = marker(
          'Too many requests were made within a short timespan. Please wait a few minutes before trying again.'
        );
        break;
      case ServerError.NANO_REQUEST_ERROR_UNKNOWN_TYPE:
        errorDescription = marker('Request type is not recognized by the Nano Client.');
        break;
      case ServerError.NANO_REQUEST_ERROR_DEBUG: // same as default
      case ServerError.NANO_REQUEST_ERROR: // same as default
      default:
        errorDescription = marker(
          'Something unexpected happened with the request, please try again.'
        );
    }

    return errorDescription;
  }
}
