import { CurrencyPipe, KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from 'src/app/shared/server-services/payment.service';
import {
  PaymentShopData,
  PaymentShopDataCurrencyType,
  PaymentShopDataRecord,
} from 'src/app/shared/server-services/query-records/payment-records';
import { TitleService } from 'src/app/shared/services/title.service';
import { AppStorage } from 'src/app/shared/app-storage';
import { sideMenuActiveObserver } from 'src/app/shared/shared-observers';

@Component({
  selector: 'app-nano-license-view',
  templateUrl: './nano-license-view.component.html',
  styleUrls: ['./nano-license-view.component.scss'],
})
export class NanoLicenseViewComponent implements OnInit {
  shopData: PaymentShopDataRecord;
  isHun: boolean = AppStorage.getItem('lang') == 'hu';
  accountLicenseSlotLimit: number;

  constructor(
    private paymentService: PaymentService,
    private currencyPipe: CurrencyPipe,
    private translate: TranslateService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    sideMenuActiveObserver.next(false);
    this.paymentService.getPaymentShopData().then((shopData: PaymentShopData) => {
      this.shopData = shopData.response;
      this.accountLicenseSlotLimit = shopData.accountLicenseSlotLimit;
    });

    this.titleService.setCurrentTabTitle(marker('Available Licenses'));
  }

  public onCompare(_left: KeyValue<any, any>, _right: KeyValue<any, any>): number {
    return _left.value.months - _right.value.months;
  }

  public getPrice(
    price: { [key in PaymentShopDataCurrencyType]: number },
    months: number,
    free_months: number
  ): string {
    var selectedCurrency = this.getCurrencyFromLang();
    var fullMonths = free_months ? months - free_months : months;
    var fullPrice = Math.round(price[selectedCurrency] * fullMonths);
    var pricePolicy = this.isHunCurrency ? '1.0-2' : '';
    var formattedPrice = this.currencyPipe.transform(
      fullPrice,
      selectedCurrency,
      'symbol',
      pricePolicy
    );
    return `${formattedPrice}` + this.translate.instant(marker(' + VAT'));
  }

  public getGrossPrice(
    price: { [key in PaymentShopDataCurrencyType]: number },
    months: number,
    free_months: number
  ): string {
    var selectedCurrency = this.getCurrencyFromLang();
    var fullMonths = free_months ? months - free_months : months;
    var pricePolicy = this.isHunCurrency ? '1.0-2' : '';
    var formattedPrice = this.currencyPipe.transform(
      Math.round(price[selectedCurrency] * fullMonths * 1.27),
      selectedCurrency,
      'symbol',
      pricePolicy
    );
    return `${formattedPrice} bruttó`;
  }

  private getCurrencyFromLang(): string {
    switch (AppStorage.getItem('lang')) {
      case 'hu':
        return 'HUF';
      case 'en':
        return 'EUR';
      default:
        return 'EUR';
    }
  }

  public get isHunCurrency(): boolean {
    var selectedCurrency = this.getCurrencyFromLang();
    return selectedCurrency == PaymentShopDataCurrencyType.HUF;
  }
}
