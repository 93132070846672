<div *ngIf="topReactionCategories" class="title-bar" mat-dialog-title translate>
  <div class="header-category" (click)="selectedCategory = null" [ngClass]="{ active: selectedCategory === null }">All ({{ reactionCount }})</div>
  <div class="header-category" (click)="selectedCategory = cat.emoji" [ngClass]="{ active: selectedCategory === cat.emoji }" *ngFor="let cat of topReactionCategories; let i = index">
    <img [src]="getEmojiByIndex(cat.emoji)" />
    <span>{{ cat.count }}</span>
  </div>
  <div *ngIf="reactionCategories.length > 3" class="header-category header-more" [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></div>
</div>
<mat-dialog-content class="content-body mat-typography">
  <div *ngIf="!isLoading">
    <ng-container *ngFor="let reaction of reactionObjects">
      <ng-container *ngIf="reaction.accountAvatar">
        <div *ngIf="reaction.emojiCode === selectedCategory || selectedCategory === null" class="row" appShowUserInfo [accountAvatarRecord]="reaction.accountAvatar">
          <div>
            <app-avatar [accountAvatarRecord]="reaction.accountAvatar"></app-avatar>
          </div>
          <div class="name notranslate">{{ reaction.accountAvatar.avatarName }}</div>
          <img [src]="reaction.emojiIcon" />
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="loading-container" *ngIf="isLoading">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
  <mat-menu #menu="matMenu" xPosition="after" yPosition="above">
    <div class="more-menu-category" (click)="selectedCategory = cat.emoji" [ngClass]="{ active: selectedCategory === cat.emoji }" *ngFor="let cat of reactionCategories; let i = index">
      <img class="reaction-dialog-more-menu-emoji" [src]="getEmojiByIndex(cat.emoji)" />
      <span>{{ cat.count }}</span>
    </div>
  </mat-menu>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="primary"><span translate>Close</span></button>
</mat-dialog-actions>
