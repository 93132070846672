import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppStorage } from 'src/app/shared/app-storage';
import { AuthService } from 'src/app/shared/server-services/auth.service';
import { BlobService } from 'src/app/shared/services/blob.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { FileDownloaderService } from 'src/app/shared/services/file-downloader.service';
import { NativeAppService } from 'src/app/shared/services/native-app.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { styleChangedObserver } from 'src/app/shared/shared-observers';
import { EnvironmentService } from 'src/environments/environment.service';

@Component({
  selector: 'app-home',
  templateUrl: './home-base.component.html',
  styleUrls: ['./home-base.component.scss'],
})
export class HomeBaseComponent {
  public colorTheme;
  public siteBase: string;

  public showRecoveryCode: boolean = false;
  public showSafariSupportBlock: boolean = false;
  public showChromeRecommendBlock: boolean = false;
  public isOnMobile: boolean = false;

  constructor(
    private titleService: TitleService,
    private environmentService: EnvironmentService,
    private authService: AuthService,
    private dialog: MatDialog,
    private nativeAppService: NativeAppService,
    private snackbarService: SnackBarService,
    private dialogService: DialogService,
    private blobService: BlobService,
    private deviceDetector: DeviceDetectorService,
    private fileDownloaderService: FileDownloaderService
  ) {
    this.colorTheme = AppStorage.getItem('style_mode');
    styleChangedObserver.subscribe((style) => {
      this.colorTheme = style;
    });

    this.siteBase = this.environmentService.site_base;

    this.isOnMobile = this.nativeAppService.isOnApp();

    // this.titleService.setTabTitle(marker('Welcome'));
    this.titleService.setCurrentTabTitle(marker('Welcome'));

    if (!this.authService.isAnonym()) {
      this.showRecoveryKeyReminder();
    }

    if (this.deviceDetector.browser == 'Safari') {
      if (!AppStorage.getItem('safari-notice-read')) {
        this.showSafariSupportBlock = true;
      }
    } else if (
      this.deviceDetector.browser != 'Chrome' &&
      this.deviceDetector.browser != 'Opera' &&
      this.deviceDetector.browser != 'MS-Edge' &&
      this.deviceDetector.browser != 'MS-Edge-Chromium' &&
      !this.isOnMobile
    ) {
      if (!AppStorage.getItem('chrome-notice-read')) {
        this.showChromeRecommendBlock = true;
      }
    }
  }

  public closeShowSafariSupportBlock() {
    AppStorage.setItem('safari-notice-read', '1');
    this.showSafariSupportBlock = false;
  }

  public closeShowChromeRecommendBlock() {
    AppStorage.setItem('chrome-notice-read', '1');
    this.showChromeRecommendBlock = false;
  }

  private showRecoveryKeyReminder: () => void = () => {
    var nextReminderDateString = AppStorage.getItem('recoveryKeyDownloadReminderDate');
    if (!nextReminderDateString) {
      //this.dialog.open(DownloadRecoveryCodeDialogComponent, { autoFocus: false });
      this.showRecoveryCode = true;
      return;
    }

    var nextReminderDate = new Date(Date.parse(nextReminderDateString));
    var currentDate = new Date();

    if (nextReminderDate < currentDate) {
      //this.dialog.open(DownloadRecoveryCodeDialogComponent, { autoFocus: false });
      this.showRecoveryCode = true;
    }
  };

  downloadRecoveryKey(): void {
    let name = 'CBNANO-SECRET-RECOVERY-KEY.TXT';
    if (this.nativeAppService.isOnApp()) {
      this.nativeAppService
        .getFileWriter(name)
        .then((writer) => {
          return writer.write(this.authService.createRecoveryKeyFile()).then(() => {
            writer.close();
            this.snackbarService.showSnackbar(marker('Downloaded:') + ': ' + name);
          });
        })
        .catch((e) => {
          console.error('Could not create the file', e);
          this.dialogService.openAlertDialog(
            marker('Download error'),
            marker('Could not create the file')
          );
        });
    } else {
      this.fileDownloaderService.download(this.authService.createRecoveryKeyFile(), name);

      this.snackbarService.showSnackbar(marker('Downloaded') + ': ' + name);
    }
  }

  remindLaterRecoveryKey(): void {
    this.remindAfterMonthsRecoveryKey(1);
    this.showRecoveryCode = false;
  }

  remindAfterMonthsRecoveryKey(months: number): void {
    var currentDate = new Date();
    var reminderDate = new Date(currentDate.setMonth(currentDate.getMonth() + months));
    AppStorage.setItem('recoveryKeyDownloadReminderDate', reminderDate.toString());
  }

  closeRecoveryKey() {
    this.remindAfterMonthsRecoveryKey(6);
    this.showRecoveryCode = false;
  }
}
