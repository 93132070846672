export interface AccountAvatarRecord {
  id;
  avatarImageKey: string;
  avatarName: string;
  deleted: boolean;
  color?: number;
}

export enum UserRole {
  DISTRIBUTOR = 'dist',
  ADMIN = 'admin',
  DEV = 'dev',
}

export enum DialoguePolicy {
  OPEN = 0,
  SHAREDROOM = 10,
  OWNEDROOM = 20,
  CONTACTONLY = 30,
}

export interface MeRecord extends AccountAvatarRecord {
  email: string;
  parentAccountId: string | null;
  scopes: UserRole[];
  selectedStatus: any;
  dialoguePolicy: DialoguePolicy;
  walletAddress: string;
}

export interface AccountKeysRecord {
  encryptingPublicEc: Uint8Array;
  encryptingPublicEcSignature: Uint8Array;
  signingPublicEc: Uint8Array;
  trust: Uint8Array | null;
}

export interface JoinLiveRecord {
  serviceUrl: string;
  hostsDomain: string;
  hostsMuc: string;
  room: string;
  token: string;
  ttl: number;
}
