import { ResponseManipulatorHandler } from '../../../cache-logic-interface';
import { SeenCacheRecord } from '../../../custom-cache-reset-logic';

export let getSeenIdsByMessageQueryManipulator: ResponseManipulatorHandler = (
  cache,
  cacheKey,
  request
) => {
  let seenRecord: SeenCacheRecord = cache.getCacheData('seen')?.[request.variables.resourceId];
  if (!seenRecord) return [];
  return seenRecord.messages[request.variables.messageId] || [];
};
