/**
 * Encryption of registration challenge payload.
 */

import { CryptoContext } from '../base';
import { RegisterChallengeConfigDumpArgs, RegisterChallengeConfigLoadArgs } from './args';
import { RegisterChallengeConfigV1 } from './v1';

export let REGISTER_CHALLENGE_CRYPTO_CONTEXT = new CryptoContext<
  RegisterChallengeConfigLoadArgs,
  RegisterChallengeConfigDumpArgs
>([new RegisterChallengeConfigV1()], [new Uint8Array([1])]);
