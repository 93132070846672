<h2 mat-dialog-title translate *ngIf="!changeUsernameData.firstSetup">Change username</h2>
<h2 mat-dialog-title translate *ngIf="changeUsernameData.firstSetup">Choose a username</h2>
<form class="change-username-form" [formGroup]="usernameForm">
  <mat-dialog-content class="mat-typography">
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label><span translate>New username</span></mat-label>
        <input matInput formControlName="userName" name="user-name" />
        <mat-error *ngIf="usernameForm.get('userName').errors?.['required']"><span translate>Please enter your desired username.</span></mat-error>
        <mat-error *ngIf="usernameForm.get('userName').errors?.['stringNotEquals']"><span translate>This is your current username.</span></mat-error>
        <mat-error *ngIf="usernameForm.get('userName').errors?.['maxlength']"><span translate>Username can't be longer than 30 characters.</span></mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-button color="primary" [disabled]="!usernameForm.valid" (click)="changeUsername()" #submitButton><span translate>Change</span></button>
  </mat-dialog-actions>
</form>
