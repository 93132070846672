<div>
  <div class="sub-page">
    <h1 translate>Nano Metics</h1>
    <small>
      <p translate>Here you can see your Nano client's data usage metrics for each active month.</p>
      <p translate><span class="primary" translate>Downloads</span> show how much data your Nano client had to download. (For example when someone uploads a file to your drive.)</p>
      <p translate><span class="primary" translate>Uploads</span> show how much data was requested from your Nano client. (For example when someone downloads a file from your drive.)</p>
      <p translate>
        <span class="primary" translate>From cache</span> shows how much data was requested from your Nano client, but the Clarabot server cache was able to fulfill the request, saving your Nano
        client the trouble of sending the requested files again.
      </p>
      <p translate>Your Nano client always validates the request before allowing the Clarabot servers to respond from their cache.</p>
    </small>

    <ng-template *ngIf="loadingData; else content">
      <mat-spinner diameter="20"></mat-spinner>
    </ng-template>
    <ng-template #content>
      <section *ngIf="isAdmin" class="admin-button-container">
        <button *ngIf="!showAggregateStats" mat-raised-button color="primary" (click)="displayAggregateStats(true)"><span translate>Show aggregate statistics</span></button>
        <button *ngIf="showAggregateStats" mat-raised-button color="primary" (click)="displayAggregateStats(false)"><span translate>Show personal statistics</span></button>
      </section>
      <section class="stats-card-container" *ngIf="!showAggregateStats">
        <mat-card appearance="outlined" class="stats-card" *ngFor="let stat of stats">
          <mat-card-title> {{ stat.year }}. {{ stat.month }}. </mat-card-title>
          <mat-card-content>
            <div class="stats-container">
              <div class="stats-column" [style.height]="stat.requestColumnHeight + '%'"></div>
              <div class="stats-column" [style.height]="stat.sumColumnHeight + '%'">
                <div class="stats-column-inner" [style.height]="stat.cacheColumnHeight + '%'"></div>
              </div>

              <div class="stats-column-label">{{ stat.requestBytes | formatBytes }}</div>
              <div class="stats-column-label">
                {{ stat.cacheBytes + stat.responseBytes | formatBytes }} <br />
                ({{ stat.cacheBytes | formatBytes }})
              </div>

              <div class="stats-column-title" translate>Downloads</div>
              <div class="stats-column-title" translate>
                Uploads <br />
                (from cache)
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </section>

      <section *ngIf="showAggregateStats">
        <mat-card appearance="outlined">
          <mat-card-title><span translate>Aggregate metrics</span></mat-card-title>
          <mat-card-content>
            <div class="metrics-info-col">
              <label translate>Accounts</label>
              <span>{{ adminStats.accounts }}</span>
            </div>
            <div class="metrics-info-col">
              <label translate>Accounts updated</label>
              <span>{{ adminStats.accountsUpdated * 1000 | date: 'medium' }}</span>
            </div>
            <div class="metrics-info-col">
              <label translate>Nano metrics updated</label>
              <span>{{ adminStats.nanoMetricsUpdated * 1000 | date: 'medium' }}</span>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="stats-card-container">
          <mat-card appearance="outlined" class="stats-card" *ngFor="let stat of adminStats.nanoMetrics">
            <mat-card-title> {{ stat.year }}. {{ stat.month }}. </mat-card-title>
            <mat-card-content>
              <div class="stats-container">
                <div class="stats-column" [style.height]="stat.requestColumnHeight + '%'"></div>
                <div class="stats-column" [style.height]="stat.sumColumnHeight + '%'">
                  <div class="stats-column-inner" [style.height]="stat.cacheColumnHeight + '%'"></div>
                </div>

                <div class="stats-column-label">{{ stat.requestBytes | formatBytes }}</div>
                <div class="stats-column-label">
                  {{ stat.cacheBytes + stat.responseBytes | formatBytes }}<br />
                  / {{ stat.cacheBytes | formatBytes }}
                </div>

                <div class="stats-column-title" translate>Downloads</div>
                <div class="stats-column-title" translate>Uploads <br />(from cache)</div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </section>
    </ng-template>
  </div>
</div>
