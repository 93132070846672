<div class="input-container">
  <div>
    <img id="logo" [src]="isDarkTheme ? '/assets/img/ui/logo_nano-dark.svg' : '/assets/img/ui/logo_nano.svg'" />

    <form class="form-container" [formGroup]="registerForm" *ngIf="!isRegisterSuccess" (submit)="startRegister()">
      <mat-form-field>
        <mat-label><span translate>Email Address</span></mat-label>
        <input matInput formControlName="email" type="email" name="nano-webapp-email" autocomplete="email" />
        <mat-error *ngIf="registerForm.get('email').hasError('required')"><span translate>Please enter your email address.</span></mat-error>
        <mat-error *ngIf="registerForm.get('email').hasError('email')"><span translate>Please enter a valid email address.</span></mat-error>
      </mat-form-field>

      <div class="strength-input">
        <div #scoreContentMarker></div>
        <mat-form-field>
          <mat-label><span translate>Password</span></mat-label>
          <input
            matInput
            appZxcvbn
            [scoreContentMarker]="scoreContentMarker"
            (score)="passwordStrength($event)"
            formControlName="password"
            type="password"
            name="nano-webapp-password"
            autocomplete="current-password"
          />
          <mat-error *ngIf="registerForm.get('password').hasError('required')"><span translate>Please enter a new password.</span></mat-error>
          <mat-error *ngIf="registerForm.get('password').hasError('passwordStrengthLow') && !registerForm.get('password').hasError('required')"
            ><span translate>Please enter a stronger password.</span></mat-error
          >
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label><span translate>Confirm password</span></mat-label>
        <input matInput formControlName="passwordAgain" type="text" name="nano-webapp-password-again" autocomplete="current-password" type="password" />
        <mat-error *ngIf="registerForm.get('passwordAgain').hasError('controlEquals')"><span translate>Passwords must match.</span></mat-error>
      </mat-form-field>

      <div id="term-cont">
        <span>
          <mat-checkbox color="primary" formControlName="term" name="policy-accept">
            <span id="policy-cont" translate
              >Accept our <a href="{{ legalURL }}" target="_blank" translate>Terms of Service</a> and <a href="{{ legalURL }}" target="_blank" translate>Privacy Policy</a></span
            >
          </mat-checkbox>
          <mat-error *ngIf="registerForm.get('term').hasError('required') && registerForm.get('term').touched"><span translate>Please accept the terms and conditions.</span></mat-error>
        </span>
      </div>

      <button class="action-button" type="submit" [disabled]="isPending || !registerForm.valid" mat-raised-button color="primary">
        <span *ngIf="!isPending && !isRegisterSuccess" translate>Register</span>
        <mat-icon *ngIf="isPending"><mat-spinner diameter="20"></mat-spinner></mat-icon>
      </button>

      <div>
        <app-etalon-info></app-etalon-info>
      </div>
    </form>

    <div id="login-form-cont" *ngIf="isRegisterSuccess">
      <h1 class="mat-caption" translate>Successful Registration!</h1>
      <div class="success-text">
        <mat-icon>email</mat-icon>
        <div translate>We sent an email to your address, please follow the instructions to finalize your account.</div>
      </div>
    </div>

    <div class="nav-buttons">
      <a routerLink="/account-recovery"
        ><button mat-button color="primary"><span translate>Forgot password</span></button></a
      >
      <a routerLink="/login"
        ><button mat-button color="primary"><span translate>Sign in</span></button></a
      >
    </div>
  </div>
</div>
