/**
 * Raised when the request type and version of a key is not available. It may not exist at all or it may just
 * not be loaded. The using logic must manage this state.
 */
export class MissingKey extends Error {}

/**
 * Raised when the keyring is being constructed or updated, but a supplied key is unknown or invalid.
 */
export class InvalidKey extends Error {}

/**
 * The cryptographic keys that an entity has are the basis for all secure message exchanges. These must
 * be versioned like the message encryption contexts so upgrading and using them can be seamless.
 */
export abstract class AbstractKeyring {
  // public static abstract load: Function;

  public static check_key_lengths(keys: Object, valid_map: Object) {
    for (const [key_version, key] of Object.entries(keys)) {
      const length = valid_map[key_version];
      if (length === undefined) {
        throw new InvalidKey(`Unknown key version ${key_version}`);
      }
      if (key.length !== length) {
        throw new InvalidKey(`Incorrect key length ${key.length} for ${key_version}`);
      }
    }
  }
}
