import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastPartOfPath',
})
export class LastPartOfPathPipe implements PipeTransform {
  transform(value: string): string {
    const splittedPath = value.split('/');
    return splittedPath[splittedPath.length - 1];
  }
}
