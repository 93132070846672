import { Component, Inject, INJECTOR, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';
import { NanoValidators } from 'src/app/shared/nano-validators';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent
  extends NanoDialogBaseComponent<ChangePasswordDialogComponent>
  implements OnInit
{
  strength: number = 0;

  passwordForm = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required]),
    passwordAgain: new UntypedFormControl('', [Validators.required]),
  });

  constructor(@Inject(INJECTOR) injector: Injector, private dialogService: DialogService) {
    super(injector);
  }

  ngOnInit(): void {
    this.passwordForm
      .get('passwordAgain')
      .addValidators([NanoValidators.controlEquals(this.passwordForm.get('password'))]);
    this.passwordForm
      .get('password')
      .addValidators([NanoValidators.passwordStrengthLow(() => this.strength)]);

    this.passwordForm.get('password').valueChanges.subscribe((e) => {
      if (this.passwordForm.get('passwordAgain').touched) {
        this.passwordForm.get('passwordAgain').updateValueAndValidity();
      }
    });
  }

  public passwordStrength(score) {
    this.strength = score;
    this.passwordForm.get('password').updateValueAndValidity();
  }

  changePassword() {
    this.dialogRef.close(this.passwordForm.get('password').value);
  }
}
