<h1 mat-dialog-title translate>Rename file</h1>
<form>
  <div mat-dialog-content>
    <div class="container">
      <mat-form-field appearance="outline">
        <mat-label translate>Name</mat-label>
        <input cdkFocusInitial matInput [formControl]="fileNameControl" />
        <span *ngIf="!editExtensionControl.value" matTextSuffix>{{ extension }}</span>
        <mat-error *ngIf="fileNameControl.hasError('required')"><span translate>Please enter a file name.</span></mat-error>
        <mat-error *ngIf="fileNameControl.hasError('maxlength')" translate> Name is too long </mat-error>
        <mat-error *ngIf="fileNameControl.hasError('noSlashes')" translate> Slashes are not allowed </mat-error>
      </mat-form-field>
      <mat-checkbox *ngIf="!isFolder" color="primary" [formControl]="editExtensionControl"><span translate>Edit extension</span></mat-checkbox>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null"><span translate>Cancel</span></button>
    <button mat-button color="primary" (click)="close()" [disabled]="!fileNameControl.valid"><span translate>Rename</span></button>
  </div>
</form>
