import {
  RoomData,
  RoomRecord,
  RoomWithData,
} from '../../../../../../server-services/query-records/room-records';
import { getRoomQuery } from '../../../../../../server-services/querys';
import { ResponseMergerHandler } from '../../../cache-logic-interface';

export let customRoomDatasQueryMerger: ResponseMergerHandler = function (
  cache,
  request,
  response: { [key: string]: RoomData }
) {
  for (var id in response) {
    let room: RoomRecord | RoomWithData = cache.getCacheDataByRequest({
      query: getRoomQuery,
      variables: { id },
    });
    if (room) {
      (<RoomWithData>room).data = <RoomData>response[id];
    } else {
      // It should be in the cache. The record was used for decryption
      // impossible to not find it now
      console.warn('Can not find the Record for the requested Data in the cache', room);
    }
  }
};
