import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from '../../services/clipboard.service';
import { SnackBarService } from '../../services/snackbar.service';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

@Component({
  selector: 'app-general-alert',
  templateUrl: './general-alert-dialog.component.html',
  styleUrls: ['./general-alert-dialog.component.scss'],
})
export class GeneralAlertDialogComponent extends NanoDialogBaseComponent<GeneralAlertDialogComponent> {
  alertData: GeneralAlertData;
  showError: boolean = false;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    public generalAlertData: GeneralAlertData,
    public translate: TranslateService,
    private clipboardService: ClipboardService,
    private snackbarService: SnackBarService
  ) {
    super(injector);
    this.alertData = generalAlertData;
  }

  copyError(): void {
    this.clipboardService
      .copy(this.alertData.errorMessage)
      .then(() => {
        this.snackbarService.showSnackbar(marker('Error message copied!'));
      })
      .catch((err) => {
        console.error('clipboard err', err);
        this.snackbarService.showSnackbar(marker('Could not copy to the clipboard'));
      });
  }
}

interface GeneralAlertData {
  title: string;
  text: string;
  errorMessage: string;
}
