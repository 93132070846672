import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

@Component({
  selector: 'app-outbound-link-dialog',
  templateUrl: './outbound-link-dialog.component.html',
  styleUrls: ['./outbound-link-dialog.component.scss'],
})
export class OutboundLinkDialogComponent extends NanoDialogBaseComponent<OutboundLinkDialogComponent> {
  public linkHref: string;
  public linkDomain: string;

  constructor(@Inject(INJECTOR) injector: Injector, @Inject(MAT_DIALOG_DATA) public location: any) {
    super(injector);

    this.linkHref = location.href;
    this.linkDomain = location.host;
  }

  navigationAccepted(accepted: boolean): void {
    this.dialogRef.close(accepted);
  }
}
