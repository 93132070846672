import {
  TimedOfferData,
  TimedOfferRecord,
} from '../../../../../../../../server-services/query-records/timed-offer-records';
import { WorkspaceSubscriptionAccountOfferEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import { getTimedOffersQuery } from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onCreateAccountOfferSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionAccountOfferEventRecord
) => {
  let allAccountOffer: TimedOfferRecord[] = cache.getCacheDataByRequest({
    query: getTimedOffersQuery,
    variables: {},
  });
  allAccountOffer.push({
    id: event.id,
    data: <TimedOfferData>event.data,
    expiration: event.expiration,
  });
};
