import { Component, Inject, Injector, INJECTOR, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import msgpack from 'msgpack-lite';
import { base64 } from 'rfc4648';
import { Observable, Subject } from 'rxjs';
import { GroupConfigBlockFlags } from 'src/app/shared/crypto/group_config/block';
import { RoomPermissions } from 'src/app/shared/crypto/group_config/chain-flag-parser';
import { ImageCropperDialogComponent } from 'src/app/shared/dialogs/image-cropper-dialog/image-cropper-dialog.component';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';
import { NanoValidators } from 'src/app/shared/nano-validators';
import { NanoService } from 'src/app/shared/nano/nano.service';
import { ObjectSorter } from 'src/app/shared/object-sorter';
import { SafeRadix32 } from 'src/app/shared/safe-radix32';
import { AccountService } from 'src/app/shared/server-services/account.service';
import { AuthService } from 'src/app/shared/server-services/auth.service';
import { PermissionService } from 'src/app/shared/server-services/permission.service';
import { AccountAvatarRecord } from 'src/app/shared/server-services/query-records/account-records';
import {
  PermissionRole,
  RoomData,
  RoomMember,
  RoomPermission,
} from 'src/app/shared/server-services/query-records/room-records';
import { WorkspaceSubscriptionRoomPermissionEventRecord } from 'src/app/shared/server-services/query-records/workspace-records';
import { RoomPermissionAttributes, RoomService } from 'src/app/shared/server-services/room.service';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from 'src/app/shared/server-services/subscription-event';
import { SubscriptionService } from 'src/app/shared/server-services/subscription.service';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { WeightedSearch } from 'src/app/shared/weighted-search';
import { MemberDetailDialogComponent } from './member-detail-dialog/member-detail-dialog.component';

export interface RoomDetailsDialogData {
  resourceId: string;
  activeTab: number;
}

@Component({
  selector: 'app-room-details-dialog',
  templateUrl: './room-details-dialog.component.html',
  styleUrls: ['./room-details-dialog.component.scss'],
})
export class RoomDetailsDialogComponent
  extends NanoDialogBaseComponent<RoomDetailsDialogComponent>
  implements OnInit
{
  public loading: boolean = true;
  public activeTab: number = 0;
  public activeAdvancedSettingsTab: number = 0;
  public isNanoConnected: boolean = false;
  public myRoomPermission: RoomPermission;
  public avatar: ArrayBuffer;
  public publicUrl: string = '';
  public referenceString: string = '';
  public activeInviteList: Array<AccountAvatarRecord> = [];
  public activeRoomMembersList: Array<RoomMember> = [];
  public roomMembers: Array<RoomMember> = [];
  public meRoomMember: RoomMember;
  public Object = Object;
  public modifiedMemberPermissionDict: { [userId: string]: RoomPermission } = {};
  public maxMembers: number = 0;
  public PermissionRole = PermissionRole;

  public roomNameControl = new FormControl<string | null>(null, [
    Validators.required,
    Validators.maxLength(NanoValidators.ROOM_NAME_LENGTH),
  ]);
  public allowAnonymControl = new FormControl<boolean>(false, { nonNullable: true });
  public isMessageBoardControl = new FormControl<boolean>(false, { nonNullable: true });
  public isEmoteAllowedControl = new FormControl<boolean>(false, { nonNullable: true });
  public inviteSearchControl = new FormControl<string>(null);
  public memberSearchControl = new FormControl<string>(null);

  public resourceId: string;
  private inviteList: Array<AccountAvatarRecord> = [];
  private waitingForModify: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: RoomDetailsDialogData,
    @Inject(INJECTOR) injector: Injector,
    protected roomService: RoomService,
    protected nanoService: NanoService,
    protected snackbarService: SnackBarService,
    protected accountService: AccountService,
    protected dialogService: DialogService,
    protected routerHandler: RouterHandler,
    protected clipboardService: ClipboardService,
    protected authService: AuthService,
    protected permissionService: PermissionService,
    protected subscriptionService: SubscriptionService
  ) {
    super(injector);

    this.resourceId = data.resourceId;
    this.activeTab = data.activeTab;
  }

  ngOnInit(): void {
    this.initData();
    this.bindEvents();
  }

  private initData(): void {
    Promise.all([
      this.roomService.getPublicShareUrl(this.resourceId),
      this.accountService.getAllAccount(),
      this.permissionService.getRoomMembers(this.resourceId),
      this.accountService.getMe(),
      this.initRoomData(),
      this.initMyPermissions(),
    ])
      .then((res) => {
        this.publicUrl = res[0];
        this.inviteList = res[1];
        this.roomMembers = res[2];
        this.activeInviteList = ObjectSorter.sort(this.inviteList, 'avatarName');
        this.activeRoomMembersList = ObjectSorter.sort(this.roomMembers, 'highestRolePermission');
        let me = res[3];
        this.meRoomMember = this.roomMembers.find((rm) => rm.user.id === me.id);
        this.loading = false;
      })
      .catch((err) => {
        console.error('Error during room detail init', err);

        this.dialogService
          .openAlertDialog(
            marker('Room permission error'),
            marker('Could not load all room permission data.')
          )
          .subscribe(() => {
            this.dialogRef.close();
          });
      });
  }

  private bindEvents(): void {
    this.inviteSearchControl.valueChanges.subscribe((value: string) =>
      this.onInviteSearchInputChange(value)
    );
    this.memberSearchControl.valueChanges.subscribe((value: string) => {
      this.onMemberSearchInputChange(value);
    });

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_PERMISSION_EVENT,
      SubscriptionServiceEventType.ALL,
      this.onRoomPermissionChange
    );
  }

  private initRoomData(): Promise<any> {
    return this.roomService.getRoom(this.resourceId).then((roomData) => {
      if (!!roomData!.inaccessible) return;

      this.isNanoConnected = Object.keys(roomData['nanoSessions']).length == 1;
      this.roomNameControl.setValue((<RoomData>roomData.data)?.name);
      this.avatar = (<RoomData>roomData.data)?.avatar;
    });
  }

  private initMyPermissions(): Promise<any> {
    return this.permissionService
      .getMyRoomPermissions(this.resourceId)
      .then(async (myRoomPermission: RoomPermission) => {
        this.myRoomPermission = myRoomPermission;
        this.allowAnonymControl = new FormControl<boolean>(
          myRoomPermission.roomAllowAnonymEnabled,
          {
            nonNullable: true,
          }
        );
        this.isMessageBoardControl = new FormControl<boolean>(
          myRoomPermission.roomIsMessageBoardEnabled,
          {
            nonNullable: true,
          }
        );
        this.isEmoteAllowedControl = new FormControl<boolean>(
          myRoomPermission.roomReactionsEnabled,
          {
            nonNullable: true,
          }
        );

        if (!myRoomPermission.isOwner && !myRoomPermission.isAdmin) {
          this.roomNameControl.disable();
        }
        if (myRoomPermission.isAdmin || myRoomPermission.isModerator || myRoomPermission.isOwner) {
          let roomExtraPermData = await this.permissionService.getRoomPermissions(this.resourceId);
          this.maxMembers = roomExtraPermData.maxMembers;
        }
        if (myRoomPermission.isOwner) {
          let chain = await this.permissionService.getRoomBlockChain(
            this.resourceId,
            this.authService.getSelfAccountKeyring()
          );
          let encodedBlockChainHead = base64.stringify(
            msgpack.encode({
              id: this.resourceId,
              block_names: chain.head_names,
            })
          );

          this.referenceString = encodedBlockChainHead;
        }
      });
  }

  public openContent(index: number): void {
    this.activeTab = index;
  }

  public openAdvancedSettingsContent(index: number): void {
    this.activeAdvancedSettingsTab = index;
  }

  // public openDrive(): void {
  //   this.routerHandler.navigate(['/room/' + this.resourceId + '/drive'], {
  //     fragment: this.routerHandler.getRoute().rawFragment,
  //   });
  //   this.dialogRef.close();
  // }

  public openEditAvatarDialog(): void {
    if (!this.meRoomMember.permissions.isOwner && !this.meRoomMember.permissions.isAdmin) return;
    let ref = this.dialog.open(ImageCropperDialogComponent, { autoFocus: false });
    ref.afterClosed().subscribe((avatarImageBlob: Blob) => {
      if (!avatarImageBlob) return;

      this.loading = true;
      this.waitingForModify = true;

      avatarImageBlob.arrayBuffer().then((res: ArrayBuffer) => {
        this.roomService
          .editData(this.resourceId, this.roomNameControl.value, res)
          .then(() => {
            avatarImageBlob.arrayBuffer().then((buffer) => {
              this.avatar = buffer;
            });
            this.snackbarService.showSnackbar(marker('Room avatar has changed.'));
          })
          .catch((err) => {
            console.error('Could not change room avatar', err);
          })
          .finally(() => (this.loading = false));
      });
    });
  }

  public openDetailDialog(userId: string): void {
    let roomMember = this.roomMembers.find((rm) => rm.user.id === userId);
    let accountAvatar: AccountAvatarRecord;
    let permission: RoomPermission;
    let isInviting: boolean = !roomMember;
    if (roomMember) {
      accountAvatar = roomMember.user;
      permission = Object.assign({}, roomMember.permissions);
    } else {
      accountAvatar = this.activeInviteList.find((cl) => cl.id === userId);
      permission = this.getUserPermissions(userId);
    }

    let modifiedPermission = this.modifiedMemberPermissionDict[accountAvatar.id];
    if (modifiedPermission) {
      permission = modifiedPermission;
    }

    let ref = this.dialog.open(MemberDetailDialogComponent, {
      data: { accountAvatar: accountAvatar, permission: permission, isInviting: isInviting },
      autoFocus: false,
    });

    ref.afterClosed().subscribe((ret: RoomPermission) => {
      if (ret) {
        this.modifiedMemberPermissionDict[accountAvatar.id] = ret;
      } else {
        delete this.modifiedMemberPermissionDict[accountAvatar.id];
      }
    });
  }

  public saveRoomName(): void {
    this.loading = true;
    this.waitingForModify = true;
    this.roomService
      .editData(this.resourceId, this.roomNameControl.value, this.avatar)
      .then(() => {
        this.roomNameControl.markAsPristine();
        this.snackbarService.showSnackbar(marker('Room name has changed.'));
      })
      .catch((err) => {
        console.error('Could not change the room name', err);
      })
      .finally(() => (this.loading = false));
  }

  public closeRoomDetailDialog(): void {
    if (this.roomNameControl.dirty) {
      this.showConfirmPrompt().subscribe((confirm) => {
        if (confirm) this.dialogRef.close();
      });
    } else {
      this.dialogRef.close();
    }
  }

  public cancelFormControlEditing(control: FormControl): void {
    const cancelConfirmed$ = new Subject<void>();
    cancelConfirmed$.subscribe(() => {
      control.reset();
      this.openContent(0);
    });

    if (control.dirty) {
      this.showConfirmPrompt().subscribe((confirm) => {
        if (confirm) cancelConfirmed$.next();
      });
    } else {
      cancelConfirmed$.next();
    }
  }

  public cancelMemberPermissionEdit(): void {
    const cancelConfirmedPromise = new Promise<boolean>((resolve) => {
      if (this.Object.keys(this.modifiedMemberPermissionDict).length > 0) {
        this.showConfirmPrompt().subscribe((confirm) => {
          resolve(confirm);
        });
      } else {
        resolve(true);
      }
    });

    cancelConfirmedPromise.then((canCancel) => {
      if (canCancel) {
        this.activeRoomMembersList = this.roomMembers;
        this.activeInviteList = this.inviteList;
        this.modifiedMemberPermissionDict = {};
        this.openContent(0);
      }
    });
  }

  public saveRoomType(): void {
    this.loading = true;
    this.waitingForModify = true;
    if (this.isNanoConnected) {
      let roomFlag = this.allowAnonymControl.value
        ? GroupConfigBlockFlags.ALLOW_ANONYMOUS
        : GroupConfigBlockFlags.ALLOW_ANONYMOUS_NEGATE;
      this.nanoService.nanoCreateRoomConfigBlock(
        this.resourceId,
        {},
        roomFlag,
        () => {
          this.allowAnonymControl = new FormControl<boolean>(this.allowAnonymControl.value, {
            nonNullable: true,
          });
          this.openContent(0);
          this.snackbarService.showSnackbar(marker('Room type changed.'));
          this.loading = false;
        },
        (err) => {
          console.log('can not set the permissions for the room via nano', err);
          this.loading = false;
        }
      );
    } else {
      let roomPerm: RoomPermissions = {};
      roomPerm.allowAnonym = this.allowAnonymControl.value;
      this.permissionService
        .saveConfig(this.resourceId, [], roomPerm)
        .then(() => {
          this.allowAnonymControl = new FormControl<boolean>(this.allowAnonymControl.value, {
            nonNullable: true,
          });
          this.openContent(0);
          this.snackbarService.showSnackbar(marker('Room type changed.'));
          this.loading = false;
        })
        .catch((err) => {
          console.error('can not save the permissions for the room via rest', err);
        })
        .finally(() => (this.loading = false));
    }
  }

  public saveChatAccess(): void {
    this.loading = true;
    this.waitingForModify = true;
    this.roomService
      .editPermission(
        this.resourceId,
        RoomPermissionAttributes.MESSAGE_BOARD_READ_ONLY,
        this.isMessageBoardControl.value
      )
      .then(() => {
        this.isMessageBoardControl = new FormControl<boolean>(this.isMessageBoardControl.value, {
          nonNullable: true,
        });
        this.openContent(0);
        this.snackbarService.showSnackbar(marker('Room chat access changed.'));
      })
      .catch(() => {
        console.error('could not change the msg board read only flag for the room');
      })
      .finally(() => (this.loading = false));
  }

  public saveEmoteReaction(): void {
    this.loading = true;
    this.waitingForModify = true;
    this.roomService
      .editPermission(
        this.resourceId,
        RoomPermissionAttributes.NO_REACTIONS,
        !this.isEmoteAllowedControl.value
      )
      .then(() => {
        this.isEmoteAllowedControl = new FormControl<boolean>(this.isEmoteAllowedControl.value, {
          nonNullable: true,
        });
        this.openContent(0);
        this.snackbarService.showSnackbar(marker('Emote reaction changed.'));
      })
      .catch(() => {
        console.error('could not change the msg board read only flag for the room');
      })
      .finally(() => (this.loading = false));
  }

  public leaveRoom(): void {
    this.dialogService
      .openConfirmDialog(marker('Leave room'), marker('Are you sure you want to leave this room?'))
      .subscribe((confirm) => {
        if (!confirm) return;
        this.loading = true;
        this.roomService
          .leaveRoom(this.resourceId)
          .then(() => {
            this.dialogRef.close();
            this.snackbarService.showSnackbar(marker('You left the room.'));
            this.routerHandler.navigate(['/']);
          })
          .finally(() => (this.loading = false));
      });
  }
  public deleteRoom(): void {
    this.dialogService
      .openConfirmDialog(
        marker('Delete room'),
        marker('Are you sure you want to delete this room?')
      )
      .subscribe((confirm) => {
        if (!confirm) return;
        this.loading = true;
        this.waitingForModify = true;
        this.roomService
          .deleteRoom(this.resourceId)
          .then(() => {
            this.dialogRef.close();
            this.snackbarService.showSnackbar(marker('Room has been deleted.'));
            this.routerHandler.navigate(['/']);
          })
          .finally(() => (this.loading = false));
      });
  }
  public copy(content: string): void {
    this.clipboardService
      .copy(content)
      .then(() => {
        this.snackbarService.showSnackbar(marker('Copied to clipboard!'));
      })
      .catch((err) => {
        this.dialogService.openAlertDialog(
          marker('Copy Error'),
          marker('Could not copy to the clipboard')
        );
      });
  }

  public savePermissions(): void {
    this.loading = true;
    this.waitingForModify = true;

    // Note:
    // must wait for the event async after savePermission request.
    // it clears the roomconfig, and the init data requires the new version of it
    let handleDone = false;
    let handleSuccessfulSaveRoomPermissionEvent = (event) => {
      if (event.id == this.resourceId) {
        handleDone = true;
        this.subscriptionService.unsubscribe(
          SubscriptionServiceEvent.ROOM_PERMISSION_EVENT,
          SubscriptionServiceEventType.MODIFY,
          handleSuccessfulSaveRoomPermissionEvent
        );

        console.log('save permission done');
        this.openContent(0);
        this.modifiedMemberPermissionDict = {};
        this.initData();
      }
    };

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_PERMISSION_EVENT,
      SubscriptionServiceEventType.MODIFY,
      handleSuccessfulSaveRoomPermissionEvent
    );

    this.permissionService
      .saveRoomPermissions(
        this.resourceId,
        this.modifiedMemberPermissionDict,
        this.isNanoConnected,
        this.roomMembers
      )
      .then(() => {
        this.snackbarService.showSnackbar(marker('Room data changed.'));
        setTimeout(() => {
          if (!handleDone) {
            this.subscriptionService.unsubscribe(
              SubscriptionServiceEvent.ROOM_PERMISSION_EVENT,
              SubscriptionServiceEventType.MODIFY,
              handleSuccessfulSaveRoomPermissionEvent
            );
            this.openContent(0);
            this.modifiedMemberPermissionDict = {};
            this.initData();
            console.warn(
              'could not refresh the state, still waiting for event callback room permission'
            );
          }
        }, 3000);
      })
      .catch((err) => {
        this.snackbarService.showSnackbar(marker('Could not save member permissions.'));
        console.error('Member permissions error during save', err);
        this.loading = false;
        this.subscriptionService.unsubscribe(
          SubscriptionServiceEvent.ROOM_PERMISSION_EVENT,
          SubscriptionServiceEventType.MODIFY,
          handleSuccessfulSaveRoomPermissionEvent
        );
      });
  }

  public kickMember(member: RoomMember): void {
    let onClose = this.dialogService.openConfirmDialog(
      marker('Kick user'),
      marker('Are you sure you want to kick this user?'),
      marker('Kick')
    );
    onClose.subscribe((confirm) => {
      if (!confirm) return;
      let kickedPermission = Object.assign({}, member.permissions);
      kickedPermission.isMember = false;
      kickedPermission.isAdmin = false;
      kickedPermission.isModerator = false;
      this.modifiedMemberPermissionDict[member.user.id] = kickedPermission;
    });
  }

  public get anonymAccessAllowed(): string {
    return this.allowAnonymControl.value ? marker('Public') : marker('Private');
  }

  public get isMessageBoard(): string {
    return this.isMessageBoardControl.value ? marker('Moderators') : marker('All members');
  }

  public get isEmoteAllowed(): string {
    return this.isEmoteAllowedControl.value ? marker('Allowed') : marker('Not allowed');
  }

  public isMember(contact: AccountAvatarRecord): boolean {
    return this.roomMembers.some((m) => m.user.id === contact.id);
  }

  public isBeingModified(contact: AccountAvatarRecord): boolean {
    return this.modifiedMemberPermissionDict[contact.id] !== undefined;
  }

  public isBeingKicked(member: RoomMember): boolean {
    let modifiedPermission = this.modifiedMemberPermissionDict[member.user.id];
    return modifiedPermission ? !modifiedPermission.isMember : false;
  }

  public revertKick(member: RoomMember): void {
    delete this.modifiedMemberPermissionDict[member.user.id];
  }

  public canKick(member: RoomMember): boolean {
    return (
      (this.meRoomMember.permissions.isOwner || this.meRoomMember.permissions.isAdmin) &&
      !member.permissions.isOwner
    );
  }

  public lastOfRole(member: RoomMember): boolean {
    let lastIndex = this.lastIndexOf(member);
    let index = this.activeRoomMembersList.indexOf(member);
    return lastIndex === index;
  }

  private lastIndexOf(member: RoomMember): number {
    let i = this.activeRoomMembersList.length;
    while (i--) {
      if (member.highestRolePermission === this.activeRoomMembersList[i].highestRolePermission)
        return i;
    }
    return -1;
  }

  public getMemberTitle(member: RoomMember): string {
    if (member.permissions.isOwner) return marker('Owner');

    let modifiedMemberPerm = this.modifiedMemberPermissionDict[member.user.id];
    if (modifiedMemberPerm) {
      if (modifiedMemberPerm.isAdmin) return marker('Admin');
      else if (modifiedMemberPerm.isModerator) return marker('Moderator');
      else return marker('Member');
    }

    if (member.permissions.isAdmin) return marker('Admin');
    else if (member.permissions.isModerator) return marker('Moderator');
    else return marker('Member');
  }

  public getMemberPermTitle(member: RoomMember): string {
    if (
      !this.meRoomMember.permissions.isOwner &&
      !this.meRoomMember.permissions.isAdmin &&
      this.meRoomMember.user.id !== member.user.id
    )
      return marker('Unknown');

    let modifiedMemberPerm = this.modifiedMemberPermissionDict[member.user.id];
    if (modifiedMemberPerm) {
      if (modifiedMemberPerm.driveCanWrite) return marker('Full access');
      else if (modifiedMemberPerm.driveCanUpload) return marker('Upload only');
      else return marker('Read only');
    }

    if (member.permissions.driveCanWrite) return marker('Full access');
    else if (member.permissions.driveCanUpload) return marker('Upload only');
    else return marker('Read only');
  }

  private getUserPermissions(userId: string): RoomPermission {
    let existingPermission = this.modifiedMemberPermissionDict[userId];
    if (existingPermission) {
      return Object.assign({}, existingPermission);
    } else {
      return this.permissionService.getAnonymRoomPermissions();
    }
  }

  private async onInviteSearchInputChange(value: string) {
    if (!value) {
      this.activeInviteList = this.inviteList;
    } else if (SafeRadix32.isSafeRadix(value)) {
      let acc = await this.accountService.getAccount(value);
      if (!this.inviteList.includes(acc)) {
        this.inviteList.push(acc);
      }
      this.activeInviteList = WeightedSearch.search(value, ['id'], this.inviteList);
    } else {
      this.activeInviteList = WeightedSearch.search(value, ['avatarName', 'id'], this.inviteList);
    }
  }

  private async onMemberSearchInputChange(value: string) {
    if (!value) {
      this.activeRoomMembersList = this.roomMembers;
    } else {
      this.activeRoomMembersList = WeightedSearch.search(
        value,
        ['user.avatarName', 'id'],
        this.roomMembers
      );
    }
  }

  private onRoomPermissionChange = (event: WorkspaceSubscriptionRoomPermissionEventRecord) => {
    if (this.resourceId !== event.id) return;

    if (this.waitingForModify) {
      this.waitingForModify = false;
    } else {
      this.snackbarService.showSnackbar(
        marker('Room data has been changed, please reopen this dialog.'),
        marker('OK')
      );
    }
  };

  private showConfirmPrompt(): Observable<boolean> {
    return this.dialogService.openConfirmDialog(
      marker('Unsaved changes'),
      marker('There are unsaved changes on this tab, are you sure you want to leave?')
    );
  }
}
