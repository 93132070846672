import { AbstractSelfAccountKeyring } from '../keyring/account_base';
import { AbstractPrivateKeyring } from '../keyring/private';
import { account_register_prepare } from './register';
import { AccountSignatureConfigDumpArgs } from '../context/account_signature/args';
import { ACCOUNT_SIGNATURE_CRYPTO_CONTEXT } from '../context/account_signature/__init__';
import { concatByteArray } from '../utility';

export function account_password_change(
  private_kr_cls: typeof AbstractPrivateKeyring,
  self_kr: AbstractSelfAccountKeyring,
  password: string,
  enforce_normalized_password?: boolean
) {
  return account_register_prepare(
    private_kr_cls,
    password,
    self_kr.get_secret_key(AbstractSelfAccountKeyring.MASTER),
    undefined,
    enforce_normalized_password
  );
}

/**
 * This is the base payload to be submitted to the server for starting the password-change process.
    TODO: 2FA/TOTP follow-up

    The "editPassword" rest-like endpoint may be submitted with these as variables.
 */
export function create_account_password_change_request(
  self_kr: AbstractSelfAccountKeyring,
  client_salt_input: Uint8Array,
  encrypted_master_key: Uint8Array,
  hashed_authentication_key: Uint8Array
): Promise<Object> {
  return ACCOUNT_SIGNATURE_CRYPTO_CONTEXT.dump(
    new AccountSignatureConfigDumpArgs(
      concatByteArray(client_salt_input, encrypted_master_key, hashed_authentication_key),
      self_kr
    )
  ).then((signature) => {
    return {
      salt: client_salt_input,
      masterKey: encrypted_master_key,
      authKey: hashed_authentication_key,
      signature,
    };
  });
}
