import { Component, Input, OnInit } from '@angular/core';
import { BlobService } from 'src/app/shared/services/blob.service';
import { SearchResult } from '../../../shared/server-services/search-service';
import { RouterHandler } from '../../../shared/services/router-handler.service';

@Component({
  selector: 'app-search-view-result',
  templateUrl: './search-view-result.component.html',
  styleUrls: ['./search-view-result.component.scss'],
})
export class SearchViewResultComponent implements OnInit {
  @Input() result: SearchResult;
  // thumbnail
  img0: Blob;

  constructor(private routerHandler: RouterHandler, private blobService: BlobService) {}

  ngOnInit(): void {
    if (this.result.img0) this.img0 = this.blobService.new([this.result.img0.buffer]); // {type: "image/jpeg"}
  }

  public openPath() {
    let fragment = this.routerHandler.getRoute().fragment;
    fragment['path'] = this.result.dir;
    fragment['file'] = this.result.file.fullName;

    if (fragment?.['from']) {
      delete fragment['from'];
    }

    this.routerHandler.navigate(['/room/', this.result.resourceId, 'drive'], {
      fragment: this.routerHandler.fragmentToRaw(fragment),
    });
  }

  public openRoom() {
    this.routerHandler.navigate(['/room/', this.result.resourceId, 'chat', 'room']);
  }
}
