<div>
  <div class="sub-page">
    <small><a routerLink="/account" translate>Back to account settings</a></small>
    <h1 translate>Session Handler</h1>
    <section>
      <p translate>You can delete browser, application and Nano sessions.</p>
      <p translate>Deleting a Nano session will permanently lock that Nano out, it will need to be "reset".</p>
    </section>
    <section>
      <div id="delete-all">
        <button mat-raised-button (click)="kickAllOtherUser()"><span translate>Delete all other user session</span><mat-icon>block</mat-icon></button>
      </div>
    </section>
    <section>
      <div id="header">
        <button mat-button (click)="loadData()" [matTooltip]="'Refresh list' | translate"><mat-icon color="primary">refresh</mat-icon></button>
      </div>
      <mat-divider></mat-divider>
      <mat-spinner *ngIf="dataLoading" diameter="40"></mat-spinner>
      <div *ngIf="!dataLoading">
        <div *ngFor="let s of sessions">
          <div class="one-session" [ngClass]="{ primary: s.self }">
            <div class="info" *ngIf="s.scopes.includes(LoginSessionScope.USER)">
              <mat-icon class="no-button">person</mat-icon>
              <span translate *ngIf="!s.self">User session</span>
              <span translate *ngIf="s.self">Your session</span>
            </div>
            <div class="info" *ngIf="s.scopes.includes(LoginSessionScope.NANO)">
              <mat-icon>computer</mat-icon>
              <span translate>Nano session</span>
            </div>
            <button mat-button (click)="kick(s)" [matTooltip]="'Delete Session' | translate">
              <mat-icon>block</mat-icon>
            </button>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </section>
  </div>
</div>
