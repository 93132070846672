<h2 mat-dialog-title translate>MetaMask not found</h2>
<mat-dialog-content class="mat-typography">
  <div translate>MetaMask was not found on this browser. The MetaMask plugin must be installed before trying to connect.</div>
  <ng-container *ngIf="isFirefox">
    <div translate>MetaMask may not work on Firefox due to a bug, as a workaround we recommend using Chrome.</div>
    <a target="_blank" href="https://github.com/MetaMask/metamask-extension/issues/3133" translate>About the bug.</a>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>OK</span></button>
</mat-dialog-actions>
