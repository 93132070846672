import { SidebarGroup } from '../../../../../../../../server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionResourceGroupEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import { getSidebarGroupQuery } from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onModifyResourceGroupEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionResourceGroupEventRecord) => {
    let group: SidebarGroup = cache.getCacheDataByRequest({
      query: getSidebarGroupQuery,
      variables: {
        id: event.id,
      },
    });

    if (event.closed !== undefined) {
      group.closed = event.closed;
    }

    if (event.ordering) {
      group.ordering = event.ordering;
    }

    if (event.name) {
      group.name = event.name;
    }

    if (event.decryptionError) {
      group.decryptionError = event.decryptionError;
      group.decryptionErrorMessage = event.decryptionErrorMessage;
    }
  };
