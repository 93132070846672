<!-- put mat menu here -->
<div #userInfoMenuTrigger [matMenuTriggerFor]="userInfoMenu"></div>
<mat-menu #userInfoMenu="matMenu" [hasBackdrop]="false">
  <div class="user-menu">
    <div class="menu-row">
      <app-avatar verify id="avatar-image" [accountAvatarRecord]="avatar"></app-avatar>
    </div>
    <div class="menu-row name-row">
      <span class="name-field notranslate" *ngIf="!avatar?.deleted">{{ avatar?.avatarName }}</span
      ><span class="data-error" *ngIf="avatar?.deleted" translate>Deleted user</span>
    </div>
    <button mat-menu-item (click)="copyContactId(); $event.stopPropagation()">
      <mat-icon>content_copy</mat-icon>
      <span translate>Copy ID</span>
    </button>
    <button mat-menu-item (click)="copyContactUrl(); $event.stopPropagation()" *ngIf="!avatar?.deleted">
      <mat-icon>link</mat-icon>
      <span translate>Copy contact URL</span>
    </button>
    <button mat-menu-item (click)="addContact()" *ngIf="!isAnonymous && me?.id !== avatar?.id && !avatarInContacts && !avatar?.deleted">
      <mat-icon>person_add</mat-icon>
      <span translate>Add contact</span>
    </button>
    <button mat-menu-item (click)="goToDialog()" (pointerdown)="selected($event)" *ngIf="!isAnonymous && me?.id !== avatar?.id && !inCurrentRoom">
      <mat-icon>chat</mat-icon>
      <span translate>Enter chat</span>
    </button>
    <button mat-menu-item (click)="verifyIdentity()" *ngIf="isIdentitySettingsOn && !isAnonymous && me?.id !== avatar?.id">
      <mat-icon>verified_user</mat-icon>
      <span translate>Verify Identity</span>
    </button>
    <button mat-menu-item (click)="removeContact()" *ngIf="!isAnonymous && me?.id !== avatar?.id && avatarInContacts && !avatar?.deleted">
      <mat-icon>person_remove</mat-icon>
      <span class="warn" translate>Remove contact</span>
    </button>
  </div>
</mat-menu>
