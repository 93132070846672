<div class="input-container">
  <div>
    <img id="logo" [src]="isDarkTheme ? '/assets/img/ui/logo_nano-dark.svg' : '/assets/img/ui/logo_nano.svg'" />

    <div class="flex-column" *ngIf="currStep === stepRecovery">
      <h1 class="mat-caption" translate>Account recovery</h1>
      <p translate>You are here, because you followed a recovery account confirmation link. To change your password please upload your recovery file.</p>
      <input type="file" class="hidden-input" #hiddenFileInput (change)="uploadKey($event)" />
      <button class="action-button" [disabled]="isPending || isOperationSuccess" mat-raised-button color="primary" translate (click)="hiddenFileInput.value = ''; hiddenFileInput.click()">
        Upload Recovery Key
      </button>
      <p class="margin-top" translate>You can also copy and paste the recovery text here.</p>
      <form class="form-container" [formGroup]="recoveryKeyGroup" (submit)="parseRecoveryKey()">
        <mat-form-field>
          <mat-label><span translate>Recovery key</span></mat-label>
          <input #recoveryKeyInput matInput formControlName="recoveryKey" type="text" name="nano-webapp-recovery-key" />
          <mat-error *ngIf="recoveryKeyGroup.get('recoveryKey').errors?.['required']"><span translate>Please enter or upload recovery key.</span></mat-error>
        </mat-form-field>
        <button class="action-button" type="submit" [disabled]="isPending || isOperationSuccess || !recoveryKeyGroup.valid" mat-raised-button color="primary">
          <span *ngIf="!isPending && !isOperationSuccess" translate>Confirm</span>
          <mat-icon *ngIf="isPending"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          <mat-icon *ngIf="isOperationSuccess">done</mat-icon>
        </button>
      </form>
    </div>

    <div class="flex-column" *ngIf="currStep === stepPassword">
      <span class="back-navigation">
        <a class="primary flex-center" translate (click)="backToRecoverytStep()"><mat-icon>chevron_left</mat-icon><span translate>Back to recovery key</span> </a>
      </span>

      <form class="form-container" [formGroup]="passwordGroup" (submit)="submit()">
        <div class="strength-container">
          <div #scoreContentMarker></div>
        </div>

        <mat-form-field appearance="outline">
          <mat-label><span translate>Password</span></mat-label>
          <input appZxcvbn [scoreContentMarker]="scoreContentMarker" (score)="passwordStrength($event)" matInput formControlName="password" type="password" name="nano-webapp-password" />
          <mat-error *ngIf="passwordGroup.get('password').errors?.['required']"><span translate>Please enter a new password.</span></mat-error>
          <mat-error *ngIf="passwordGroup.get('password').errors?.['passwordStrengthLow'] && !passwordGroup.get('password').errors?.['required']"
            ><span translate>Please enter a stronger password.</span></mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label><span translate>Confirm password</span></mat-label>
          <input matInput formControlName="password2" type="password" name="nano-webapp-password-again" />
          <mat-error *ngIf="passwordGroup.get('password2').errors?.['required']"><span translate>Please enter a new password.</span></mat-error>
          <mat-error *ngIf="passwordGroup.get('password2').errors?.['controlEquals']"><span translate>Passwords must match.</span></mat-error>
        </mat-form-field>

        <button class="action-button" type="submit" [disabled]="isPending || isOperationSuccess || !passwordGroup.valid" mat-raised-button color="primary">
          <span *ngIf="!isPending && !isOperationSuccess" translate>Submit</span>
          <mat-icon *ngIf="isPending"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          <mat-icon *ngIf="isOperationSuccess">done</mat-icon>
        </button>
      </form>
    </div>

    <div class="nav-buttons">
      <a routerLink="/login"
        ><button mat-button color="primary"><span translate>Back to login</span></button></a
      >
    </div>
  </div>
</div>
