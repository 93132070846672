export class GlobalCrypto {
  /**
   * crypto.subtle.digest SHA256
   * @param data Uint8Array binary data
   * @returns Promise
   */
  public static SHA256(data: Uint8Array | ArrayBuffer) {
    return crypto.subtle.digest('SHA-256', data);
  }

  /**
   * crypto.subtle.digest SHA512
   * @param data Uint8Array binary data
   * @returns Promise
   */
  public static SHA512(data: Uint8Array) {
    return crypto.subtle.digest('SHA-512', data);
  }
}
