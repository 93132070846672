import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

export interface DialogData {
  code: string;
}

@Component({
  selector: 'app-two-factor-dialog',
  templateUrl: './two-factor-dialog.component.html',
  styleUrls: ['./two-factor-dialog.component.scss'],
})
export class TwoFactorDialogComponent extends NanoDialogBaseComponent<TwoFactorDialogComponent> {
  public data: DialogData = {
    code: '',
  };

  constructor(@Inject(INJECTOR) injector: Injector) {
    super(injector);
  }

  onSubmit() {
    this.data.code = this.data.code.trim();
    // this.data.code = this.data.code + ''; // the number input convert it into number instead of string
    if (this.data.code.length > 0) {
      this.dialogRef.close(this.data);
    }
  }

  onCancel() {
    this.dialogRef.close({ code: undefined });
  }
}
