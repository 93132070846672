import { RoomRecord } from 'src/app/shared/server-services/query-records/room-records';
import { SidebarNews } from 'src/app/shared/server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionRoomEventRecord } from 'src/app/shared/server-services/query-records/workspace-records';
import { getRoomQuery, getSidebarNewsQuery } from 'src/app/shared/server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../../../workspace-change-subscription-merger-handlers/types';
import { pushNewsElementForward } from '../push-element-forward';

export let onNewsHandlerRoomModify: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionRoomEventRecord
) => {
  let room: RoomRecord = cache.getCacheDataByRequest({ query: getRoomQuery, variables: event });
  let news: SidebarNews = cache.getCacheDataByRequest({
    query: getSidebarNewsQuery,
    variables: {},
  });

  if (event.seenMessageId) {
    if (
      ((!room.seenMessageId && room.topMessageId) || room.seenMessageId < room.topMessageId) && // there is unseen message
      event.seenMessageId >= room.topMessageId // incoming seen message for top
    ) {
      news.news--;
    }
  }
  if (event.topMessageId) {
    pushNewsElementForward(cache, event.id);

    if (
      (!room.seenMessageId && !room.topMessageId) || // first message of the room
      (room.seenMessageId == room.topMessageId && // seen the last message but got a new one
        event.topMessageId >= room.topMessageId)
    ) {
      news.news++;
    }
  }
};
