import { Component, Inject, INJECTOR, Injector, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

@Component({
  selector: 'app-metamask-alert-dialog',
  templateUrl: './metamask-alert-dialog.component.html',
  styleUrls: ['./metamask-alert-dialog.component.scss'],
})
export class MetamaskAlertDialogComponent
  extends NanoDialogBaseComponent<MetamaskAlertDialogComponent>
  implements OnInit
{
  public isFirefox;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    public translate: TranslateService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    super(injector);
    this.isFirefox = this.deviceDetectorService.browser == 'Firefox';
  }

  ngOnInit(): void {}
}
