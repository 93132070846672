import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AppStorage } from 'src/app/shared/app-storage';
import { AuthService } from 'src/app/shared/server-services/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { styleChangedObserver } from 'src/app/shared/shared-observers';

@Component({
  selector: 'app-register-confirm',
  templateUrl: './register-confirm.component.html',
  styleUrls: ['./register-confirm.component.scss'],
})
export class RegisterConfirmComponent implements OnInit {
  private token;
  private salt;
  public isPending: boolean = false;
  public isRegisterSuccess: boolean = false;
  public isDarkTheme: boolean = true;

  registerConfirmForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private routerHandler: RouterHandler,
    private authService: AuthService,
    private dialogService: DialogService,
    private titleService: TitleService
  ) {
    this.titleService.setCurrentTabTitle(marker('Confirm registration'));
    this.isDarkTheme = AppStorage.getItem('style_mode') == 'dark';
    styleChangedObserver.subscribe((style) => {
      this.isDarkTheme = style == 'dark';
    });
  }

  public startRegisterConfirm() {
    if (this.isPending) {
      console.warn('operation is already in progress');
      return;
    }

    var email = this.registerConfirmForm.get('email').value;
    var password = this.registerConfirmForm.get('password').value;

    this.isPending = true;
    this.authService
      .registerConfirm(this.token, email, password)
      .then(() => {
        this.isPending = false;
        this.isRegisterSuccess = true;
        // confimation success force the user to login
        this.dialogService.openAlertDialog(
          marker('Successful confirmation'),
          marker('Your account is confirmed. Please try to login')
        );
        this.routerHandler.navigate(['/login']);
      })
      .catch((err) => {
        console.error('wrong input data', err);

        this.isPending = false;

        if (err.status == 400 && err.error !== 'Bad Request') {
          // just to mark them for translations
          const markedErrorTexts = [
            marker('Email is already taken!'),
            marker('Email is already taken by a deleted account!'),
          ];
          this.dialogService.openAlertDialog(marker('Email taken'), err.error);
        } else {
          this.dialogService.openAlertDialog(
            marker('Confirmation Error'),
            marker('Something went wrong, please try again.'),
            err
          );
        }
        this.registerConfirmForm.get('password').setValue('');
      });
  }

  ngOnInit(): void {
    let fragment = this.routerHandler.getRoute().fragment['register-confirm'];
    if (fragment) {
      this.token = fragment;
      console.log('ok, start to confirm register', this.token);
    }
  }
}
