import { SafeRadix32 } from '../../safe-radix32';

export function getTimeFromId(id: string): number {
  let ticks = SafeRadix32.decodeSafeRadix32(id.slice(0, -5)); // get the ticks from the encoded number
  // 0.02 sec for every tick
  // add 2020.01.01 01:00 to it (this is the start)
  return (ticks + 78891840000) / 50;
}

/**
 * Use with crypto-config versions to facilitate key derivation by time windows.
 */
export class TimestampWindowCalculator {
  public _timestamp_window;
  public _low_overlap;
  public _high_overlap;

  constructor(timestamp_window: number, allowed_overlap: number) {
    if (allowed_overlap < Math.floor(timestamp_window / 2)) {
      this._timestamp_window = timestamp_window;
      this._low_overlap = allowed_overlap;
      this._high_overlap = timestamp_window - allowed_overlap;
    } else {
      throw 'TimestampWindowCalculator init error';
    }
  }

  public window(timestamp: number): Uint8Array {
    return new TextEncoder().encode(timestamp - (timestamp % this._timestamp_window) + '');
  }

  public windows(timestamp: number): Uint8Array[] {
    let encoder = new TextEncoder();

    let m = timestamp % this._timestamp_window;
    let d = timestamp - m;
    let ws = [encoder.encode(d + '')];
    if (m < this._low_overlap) {
      ws.push(encoder.encode(d - this._timestamp_window + ''));
    } else if (m > this._high_overlap) {
      ws.push(encoder.encode(d + this._timestamp_window + ''));
    }
    return ws;
  }
}
