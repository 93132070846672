import { Component, OnInit } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Subject, merge } from 'rxjs';
import { AuthService } from 'src/app/shared/server-services/auth.service';
import {
  CollectionManagerType,
  DownloadManagerService,
  FileManagerType,
  downloadCollectionErrorObserver,
  downloadCollectionFinishedObserver,
  downloadCollectionProgressObserver,
  downloadCollectionStartedObserver,
  downloadErrorObserver,
  downloadFinishedObserver,
  downloadProgressObserver,
  downloadStartedObserver,
  fileAddedObserver,
  fileRemovedObserver,
  repeatingTaskObserver,
  uploadErrorObserver,
  uploadFinishedObserver,
  uploadProgressObserver,
  uploadStartedObserver,
} from 'src/app/shared/services/download-manager-service';
import {
  NativeAppService,
  NotificationCommands,
  NotificationParam,
  ProgressNotificationParam,
} from 'src/app/shared/services/native-app.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';

export let downloadOverallProgressChanged = new Subject<number>();

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  public fileProgressList: (FileManagerType | CollectionManagerType)[] = [];
  public overallProgress: number;
  public showLoadingBar: boolean = false;
  public errorHappened: boolean = false;

  private uploadOrDownloadEvents = merge(
    downloadStartedObserver,
    downloadProgressObserver,
    downloadFinishedObserver,
    downloadCollectionStartedObserver,
    downloadCollectionProgressObserver,
    downloadCollectionFinishedObserver,
    uploadStartedObserver,
    uploadProgressObserver,
    uploadFinishedObserver
  );

  private finishedEvents = merge(
    downloadFinishedObserver,
    downloadCollectionFinishedObserver,
    uploadFinishedObserver
  );

  private progressUpdatedEvent = new Subject<void>();

  private errorEvents = merge(
    downloadErrorObserver,
    downloadCollectionErrorObserver,
    uploadErrorObserver
  );

  constructor(
    private nativeAppService: NativeAppService,
    private downloadManagerService: DownloadManagerService,
    private authService: AuthService,
    private snackbarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.createFileEventHandlers();
    this.createEventHandlers();
    if (this.nativeAppService.isOnApp()) this.createMobileEventHandlers();
  }

  private createFileEventHandlers(): void {
    merge(fileAddedObserver, downloadCollectionStartedObserver).subscribe((file) => {
      this.fileProgressList.push(file);
    });
  }

  private createEventHandlers(): void {
    this.errorEvents.subscribe((fileManagerType: FileManagerType) => {
      this.errorHappened = true;
    });

    this.uploadOrDownloadEvents.subscribe(() => {
      this.updateLoadingBarVisibility();
      this.updateOverallProgress();
    });

    fileRemovedObserver.subscribe((removedFile: FileManagerType) => {
      this.fileProgressList = this.fileProgressList.filter((file) => file != removedFile);
      this.errorHappened = this.fileProgressList.some((f) => f.isError());

      this.updateOverallProgress();
      this.updateLoadingBarVisibility();
    });

    this.authService.onLogout(() => {
      this.updateLoadingBarVisibility();
    });

    repeatingTaskObserver.subscribe(() => {
      this.snackbarService.showSnackbar(marker('All active downloads finished.'));
    });
  }

  private createMobileEventHandlers(): void {
    this.errorEvents.subscribe((fileManagerType: FileManagerType) => {
      this.sendErrorNotification();
    });

    this.progressUpdatedEvent.subscribe(() => {
      this.sendProgressNotification();
    });

    fileRemovedObserver.subscribe(() => {
      if (this.fileProgressList.length === 0) this.nativeAppService.clearNotification(0); //CordovaService.PROGRESS_NOTIFICATION_ID);
    });

    this.finishedEvents.subscribe(() => {
      if (this.overallProgress === 100) this.nativeAppService.playNotificationSound();
      if (this.errorHappened) {
        this.errorHappened = this.fileProgressList.some((f) => f.isError());
      }
    });
  }

  private updateOverallProgress(): void {
    if (this.fileProgressList.length === 0) return;

    if (this.fileProgressList.every((file) => file.isDone())) {
      this.overallProgress = 100;
      downloadOverallProgressChanged.next(this.overallProgress);
      this.progressUpdatedEvent.next();
      this.fileProgressList = [];
      return;
    }

    let activeFiles = this.fileProgressList;
    let progressCount: number = 0;
    let max: number = activeFiles.length * 100;
    activeFiles.map((file) => file.progress).forEach((prog) => (progressCount += prog));

    this.overallProgress = Math.floor((progressCount / max) * 100);
    downloadOverallProgressChanged.next(this.overallProgress);

    this.progressUpdatedEvent.next();
    this.errorHappened = this.fileProgressList.some((f) => f.isError());
  }

  private updateLoadingBarVisibility(): void {
    this.showLoadingBar =
      this.downloadManagerService.isDownloadInProgress() ||
      this.downloadManagerService.isUploadInProgress();
  }

  sendProgressNotification(): void {
    var activeFiles = this.fileProgressList;
    var message;
    var title;

    if (this.overallProgress < 100) {
      title = activeFiles.length == 1 ? marker('File progress') : marker('Overall progress');
      message =
        activeFiles.length == 1
          ? marker('File: ') + activeFiles[0]?.file?.fullName ?? activeFiles[0]?.path
          : marker('Multiple files');
    } else {
      title = activeFiles.length == 1 ? marker('File ready') : marker('Files ready');
      message = activeFiles.length == 1 ? marker('File: ') + activeFiles[0]?.file?.fullName : null;
    }

    var progressNotification: ProgressNotificationParam = {
      id: 0, //CordovaService.PROGRESS_NOTIFICATION_ID, //all progress tracking runs on a single notification
      text: message,
      title: title,
      data: {
        command: NotificationCommands.NAVIGATE,
        to: '/download',
      },
      progressBar:
        this.overallProgress < 100
          ? { value: this.overallProgress, enabled: true }
          : { enabled: false },
    };

    this.nativeAppService.callProgressNotification(progressNotification);
  }

  sendErrorNotification(): void {
    var errorFiles = this.fileProgressList.filter((f) => f.isError());
    var message;
    var title;

    if (errorFiles.length === 1) {
      title = marker('File error');
      message = marker('File: ') + errorFiles[0]?.file?.fullName;
    } else {
      title = marker('Several file error');
      message = marker('Error happened with several files.');
    }

    var progressErrorNotification: NotificationParam = {
      id: 0, //CordovaService.PROGRESS_ERROR_NOTIFICATION_ID,
      text: message,
      title: title,
      data: {
        command: NotificationCommands.NAVIGATE,
        to: '/download',
      },
      group: 'progress',
    };

    this.nativeAppService.callProgressErrorNotification(progressErrorNotification);

    //if no other files are in progress, then remove the progress tracker because error happened
    if (this.fileProgressList.filter((f) => f.isError() && f.progress < 100).length == 0)
      this.nativeAppService.clearNotification(0); //CordovaService.PROGRESS_NOTIFICATION_ID);
  }
}
