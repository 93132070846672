import { MeRecord } from '../../../../../../../../server-services/query-records/account-records';
import {
  RoomMyPermissionRecord,
  RoomRecord,
  RoomWithData,
} from '../../../../../../../../server-services/query-records/room-records';
import { WorkspaceSubscriptionRoomAccountPermissionEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getMeQuery,
  getRoomQuery,
  roomMyPermissionsQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onModifyRoomAccountPermissionEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionRoomAccountPermissionEventRecord) => {
    if (
      (<MeRecord>cache.getCacheDataByRequest({ query: getMeQuery, variables: {} })).id ==
      event.accountId
    ) {
      let room: RoomRecord | RoomWithData = cache.getCacheDataByRequest({
        query: getRoomQuery,
        variables: {
          id: event.id,
        },
      });
      if (room) {
        let perm: RoomMyPermissionRecord = cache.getCacheDataByRequest({
          query: roomMyPermissionsQuery,
          variables: {
            id: event.id,
          },
        });

        if (perm) {
          Object.assign(perm.account, <Object>event);
        } // else just wait for the first query
      }
    }
  };
