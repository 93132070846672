import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, Subscription, mergeMap, of, retry, throwError } from 'rxjs';
import { showUserInfoObserver } from 'src/app/shared/components/user-info-menu/user-info-menu.component';
import { CreateRoomDialogComponent } from 'src/app/shared/dialogs/create-room-dialog/create-room-dialog.component';
import { NewGroupDialogComponent } from 'src/app/shared/dialogs/new-group-dialog/new-group-dialog.component';
import { AppDragDropService } from 'src/app/shared/drag/app-drag-drop.service';
import { AuthService } from 'src/app/shared/server-services/auth.service';
import { DialogueService } from 'src/app/shared/server-services/dialogue.service';
import { PermissionService } from 'src/app/shared/server-services/permission.service';
import { ID } from 'src/app/shared/server-services/query-records/common-records';
import { RoomPermission } from 'src/app/shared/server-services/query-records/room-records';
import {
  SidebarGroup,
  SidebarResource,
  UNGROUPPED_GROUP_ID,
} from 'src/app/shared/server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionResourceGroupEventRecord } from 'src/app/shared/server-services/query-records/workspace-records';
import { RoomService } from 'src/app/shared/server-services/room.service';
import { SidebarService } from 'src/app/shared/server-services/sidebar.service';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from 'src/app/shared/server-services/subscription-event';
import { SubscriptionService } from 'src/app/shared/server-services/subscription.service';
import { CacheService } from 'src/app/shared/services/cache/cache.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NativeAppService } from 'src/app/shared/services/native-app.service';
import { PageTabService, activePageInfoChanged } from 'src/app/shared/services/page-tab.service';
import { RouterHandler, RouterResponse } from 'src/app/shared/services/router-handler.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { showWorkspaceTabObserver } from 'src/app/shared/shared-observers';
import { RoomDetailsDialogComponent } from '../resource-page/room-details-dialog/room-details-dialog.component';
import { OrderedList } from './ordered-list';
import { BottomSheetGroupSelectorComponent } from './sidebar-group/bottom-sheet-group-selector/bottom-sheet-group-selector.component';

export let refreshSidebarObserver = new Subject<void>();
export let enableGroupReorderObserver = new Subject<boolean>();
export let enableRoomReorderObserver = new Subject<boolean>();
export let moveIntoOtherGroupObserver = new Subject<SidebarResource>();
export let openSidebarRoomContextMenu = new Subject<{
  roomData: { id: string; isMyRoom: boolean; isNanoConnected: boolean; isError: boolean };
  pos: { x: number; y: number };
}>();

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnDestroy {
  public ungroupedResources: SidebarResource[];
  public groups: SidebarGroup[];

  // group data structure
  public orderedGroups: OrderedList<SidebarGroup>;
  // reference of the showed list
  public showGroups: SidebarGroup[] = [];

  public workspaceLoaded: boolean = false;

  // public isGroupDragStarted: boolean = false;
  public isGroupReordering: boolean = false;
  public isRoomReordering: boolean = false;

  @ViewChild('expPanelContainer') expPanelNode: ElementRef;
  @ViewChild('expScrollWindow') expScrollWindow: ElementRef;
  @ViewChild('roomContextMenuTrigger') roomContextMenuTrigger: MatMenuTrigger;
  public contextMenuPosition = { x: '0px', y: '0px' };
  public roomContextMenuData: {
    id: string;
    isMyRoom: boolean;
    isNanoConnected: boolean;
    isError: boolean;
  };

  private activeResourceId: ID = null;
  private activePageInfoChangeSubscription: Subscription;

  constructor(
    protected subscriptionService: SubscriptionService,
    public dialog: MatDialog,
    protected roomService: RoomService,
    protected dialogService: DialogService,
    protected sidebarService: SidebarService,
    private dialogueService: DialogueService,
    private pageTabService: PageTabService,
    protected appDragDropService: AppDragDropService,
    protected routerHandler: RouterHandler,
    protected changeDetectionRef: ChangeDetectorRef,
    protected authService: AuthService,
    protected snackbarService: SnackBarService,
    protected cacheService: CacheService,
    protected deviceDetectorService: DeviceDetectorService,
    protected nativeAppService: NativeAppService,
    private bottomSheet: MatBottomSheet,
    private permissionService: PermissionService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.subscriptionService.subscribeWorkspaceLoaded(this.onWorkspaceLoaded);

    this.activePageInfoChangeSubscription = activePageInfoChanged.subscribe(
      this.setActiveResourceIdFromPageInfoChange
    );

    // subscriptions --------
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.RESOURCE_GROUP_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.onCreateResourceGroup
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.RESOURCE_GROUP_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onEditResourceGroup
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.RESOURCE_GROUP_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.onDeleteResourceGroup
    );

    enableGroupReorderObserver.subscribe((reordering: boolean) => {
      this.enableGroupReorder(reordering);
    });

    enableRoomReorderObserver.subscribe((reordering: boolean) => {
      this.enableRoomReorder(reordering);
    });

    moveIntoOtherGroupObserver.subscribe((resource: SidebarResource) => {
      this.moveIntoOtherGroup(resource);
    });

    openSidebarRoomContextMenu.subscribe(
      (data: {
        roomData: { id: string; isMyRoom: boolean; isNanoConnected: boolean; isError: boolean };
        pos: { x: number; y: number };
      }) => {
        this.roomContextMenuData = data.roomData;
        this.beginRoomContextMenuOpen(data.pos);
      }
    );

    showUserInfoObserver.subscribe(() => {
      if (this.roomContextMenuTrigger.menuOpen) this.roomContextMenuTrigger.closeMenu();
    });

    // init, after query done
    // this.dragDropService = this.appDragDropService;
    // this.dragDropService.subscribeDragMove(this.scrollMouseMoveHandler);
    this.routerHandler.subscribeAll(this.setActiveResourceId);
    this.setActiveResourceId(this.routerHandler.getRoute());
  }

  setActiveResourceId = (route: RouterResponse) => {
    this.activeResourceId = route.params.id || null;
  };

  private onWorkspaceLoaded = (res) => {
    this.workspaceLoaded = false;

    if (!this.authService.isAnonym()) {
      this.sidebarService.getSidebarUngroupped().then((res) => {
        this.ungroupedResources = res;
      });

      this.sidebarService.getAllSidebarGroup().then((res) => {
        this.groups = res;

        this.orderedGroups = new OrderedList<SidebarGroup>(this.groups);
        this.showGroups = this.orderedGroups.getInorderedDataRef();
      });
    }

    this.workspaceLoaded = true;
  };

  setActiveResourceIdFromPageInfoChange = (ev) => {
    const pageInfo = this.pageTabService.getActivePageInfo();
    if (pageInfo?.id) {
      this.activeResourceId = pageInfo.id;
    }
  };

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeWorkspaceLoaded(this.onWorkspaceLoaded);

    this.routerHandler.unsubscribeAll(this.setActiveResourceId);
    this.activePageInfoChangeSubscription.unsubscribe();

    // subscriptions --------
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.RESOURCE_GROUP_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.onCreateResourceGroup
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.RESOURCE_GROUP_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onEditResourceGroup
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.RESOURCE_GROUP_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.onDeleteResourceGroup
    );

    // init, after query done
    // this.dragDropService.unsubscribeDragMove(this.scrollMouseMoveHandler);
  }

  groupDropped(event: CdkDragDrop<SidebarGroup[]>) {
    const group = event.container.data[event.previousIndex];
    const ordering = this.orderedGroups.calcOrderingByPosition(group, event.currentIndex);
    this.sidebarService.editGroupOrdering(group.id, ordering);

    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  enableGroupReorder(enabled: boolean): void {
    this.isGroupReordering = enabled;
  }

  enableRoomReorder(enabled: boolean): void {
    this.isRoomReordering = enabled;
  }

  disableReorder(): void {
    if (this.isGroupReordering) enableGroupReorderObserver.next(false);
    if (this.isRoomReordering) enableRoomReorderObserver.next(false);
  }

  // dialogs and menu states ------------------------------
  openNewGroupDialog(): void {
    const dialogRef = this.dialog.open(NewGroupDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //console.log('client>server, create group: name', name);
        this.sidebarService.createGroup(result).then((res) => {
          showWorkspaceTabObserver.next();
        });
      }
    });
  }

  openNewRoomDialog(): void {
    const dialogRef = this.dialog.open(CreateRoomDialogComponent);

    dialogRef
      .afterClosed()
      .subscribe(
        (result: {
          roomName: string;
          roomAvatarBuffer: ArrayBuffer;
          inviteAvatarIdList: Array<string>;
        }) => {
          if (!result) return;
          console.log('room create', result);
          this.roomService
            .newRoom(result.roomName, result.roomAvatarBuffer)
            .then((roomId: string) => {
              this.snackbarService.showSnackbar(marker('Room created successfully!'));
              showWorkspaceTabObserver.next();

              this.scrollToRoom(roomId);

              setTimeout(() => this.routerHandler.navigate([`room/${roomId}/chat/room`]), 500);

              return roomId;
            })
            .then((newRoomId: string) => {
              // add users if there are any given
              if (result.inviteAvatarIdList.length === 0) return;

              let modifiedMemberPermissionDict: { [userId: string]: RoomPermission } = {};

              result.inviteAvatarIdList.forEach((userId) => {
                modifiedMemberPermissionDict[userId] =
                  this.permissionService.getDefaultRoomPermissions();
              });

              this.permissionService.saveRoomPermissions(
                newRoomId,
                modifiedMemberPermissionDict,
                false, // no nano is connected to new room
                [] // there are no members in new room, and we can not add ourselves
              );
            })
            .catch((error) => {
              if (error.message === 'Own room limit is reached (200)') {
                this.dialogService.openAlertDialog(
                  marker('Room limit'),
                  marker('Own room limit is reached.')
                );
              } else {
                this.dialogService.openAlertDialog(
                  marker('Create room'),
                  marker('Could not create room.')
                );
              }
              console.error('Room creation error', error);
            });
        }
      );
  }

  scrollToRoom(roomId: string): void {
    // we can not be sure that the searched room HTML element exists yet, so we retry 5 times with .5 second delays
    // After that we stop trying to scroll to the room

    var counter = 0;
    of('')
      .pipe(
        mergeMap(() => {
          let roomElement = this.document.getElementById('room-' + roomId);
          counter++;
          return roomElement === null && counter < 5
            ? throwError(() => 'Room element not found!')
            : of(roomElement);
        }),
        retry({ count: 5, delay: 500 })
      )
      .subscribe((roomElement: HTMLElement) => {
        if (roomElement) {
          roomElement.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
          // (<HTMLElement>this.expScrollWindow.nativeElement).scrollTop = roomElement.offsetTop - 100;
        }
      });
  }

  // group events
  private onCreateResourceGroup = (event: WorkspaceSubscriptionResourceGroupEventRecord) => {
    this.sidebarService.getGroup(event.id).then((group) => {
      this.orderedGroups.pushByOrdering(group);
    });
  };

  private onEditResourceGroup = (event: WorkspaceSubscriptionResourceGroupEventRecord) => {
    if (event.ordering != null || event.ordering != undefined) {
      // the cache layer changed the ordering values of the references
      this.orderedGroups.reorder();

      if (OrderedList.isPositionNeedToOptimize(event.ordering)) {
        let optimize = this.orderedGroups.getOptimizedOrdering();

        console.log('optimize groups', optimize);
        optimize.forEach((el) => {
          this.sidebarService.editGroupOrdering(el.element.id, el.ordering);
        });
      }
    }
    // this.triggerIOSGUIRefresh();
  };

  private onDeleteResourceGroup = (event: WorkspaceSubscriptionResourceGroupEventRecord) => {
    this.orderedGroups.remove(event.id);
  };

  private moveIntoOtherGroup(resource: SidebarResource) {
    let group = this.groups.find((g) => g.id === resource.groupId);
    const selectedGroup = group ? group : UNGROUPPED_GROUP_ID;
    var sheetRef = this.bottomSheet.open(BottomSheetGroupSelectorComponent, {
      data: {
        resource,
        group: selectedGroup,
      },
    });

    sheetRef.afterDismissed().subscribe((result: { group: SidebarGroup }) => {
      if (!result) return;

      this.sidebarService.editPlacement(resource.id, result.group?.id, 0, resource.type);
    });
  }

  private beginRoomContextMenuOpen(pos: { x: number; y: number }): void {
    if (this.roomContextMenuTrigger.menuOpen) {
      this.roomContextMenuTrigger.closeMenu();
      setTimeout(() => {
        this.openContextMenu(pos);
      }, 150);
    } else {
      this.openContextMenu(pos);
    }
  }

  private openContextMenu(pos: { x: number; y: number }): void {
    this.contextMenuPosition.x = pos.x + 'px';
    this.contextMenuPosition.y = pos.y + 'px';
    this.roomContextMenuTrigger.openMenu();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    if (this.roomContextMenuTrigger.menuOpen) this.roomContextMenuTrigger.closeMenu();
  }

  public openRoomDetails(): void {
    this.dialog.open(RoomDetailsDialogComponent, {
      data: { resourceId: this.roomContextMenuData.id, activeTab: 0 },
      autoFocus: false,
    });
  }

  public openRoomDrive(): void {
    this.routerHandler.navigate(['/room/' + this.roomContextMenuData.id + '/drive'], {
      fragment: this.routerHandler.getRoute().rawFragment,
    });
  }

  public leaveRoom(): void {
    this.dialogService
      .openConfirmDialog(marker('Leave room'), marker('Are you sure you want to leave this room?'))
      .subscribe((confirm) => {
        if (!confirm) return;
        this.roomService
          .leaveRoom(this.roomContextMenuData.id)
          .then(() => {
            this.snackbarService.showSnackbar(marker('You left the room.'));
            this.routerHandler.navigate(['/']);
          })
          .finally(() => this.roomContextMenuTrigger.closeMenu());
      });
  }
  public deleteRoom(): void {
    this.dialogService
      .openConfirmDialog(
        marker('Delete room'),
        marker('Are you sure you want to delete this room?')
      )
      .subscribe((confirm) => {
        if (!confirm) return;
        this.roomService
          .deleteRoom(this.roomContextMenuData.id)
          .then(() => {
            this.snackbarService.showSnackbar(marker('Room has been deleted.'));
            this.routerHandler.navigate(['/']);
          })
          .finally(() => this.roomContextMenuTrigger.closeMenu());
      });
  }

  // drag n drop handlers ------------------------------------

  // public dragStarted(event) {
  //   let container = this.expPanelNode.nativeElement;
  //   this.expPanelHeight = container.getBoundingClientRect().height + 'px';

  //   // calc the children DOM node before the dragged element
  //   let childNumberBefore = 0;
  //   let el: any = event.srcComponent.getDOMNode().previousSibling;
  //   while ((el = el.previousSibling) != null) {
  //     childNumberBefore++;
  //   }

  //   this.startPlaceFillerHeight =
  //     event.srcComponent.getDOMNode().offsetTop -
  //     childNumberBefore * SidebarComponent.GROUP_BOX_HEIGHT;
  //   //console.log("startplacefiller", this.startPlaceFillerHeight);

  //   this.isGroupDragStarted = true;
  // }

  // /**
  //  *
  //  * @param ev contains the new position of the group (from 0 to array length-1): ev.pos
  //  * @param group the current dragged group
  //  */
  // public groupDragEnded(ev: AppDragMoveEvent, group: SidebarGroup) {
  //   this.isGroupDragStarted = false;

  //   this.startPlaceFillerHeight = 0;

  //   let ordering = this.orderedGroups.calcOrderingByPosition(group, ev.pos);
  //   this.sidebarService.editGroupOrdering(group.id, ordering);

  //   // TODO
  //   /*if (this.isPositionNeedToOptimize(ordering)) {
  //     this.optimizeGroupOrdering();
  //   }*/

  //   window.setTimeout(() => {
  //     this.expPanelHeight = 'auto';
  //   }, SidebarComponent.ANGULAR_EXPANSION_PANEL_ANIMATION_SPEED);
  // }

  // // this event subscribed to the dragMove
  // /**
  //  * A handler connected to the dragMove event in AppDragDropService
  //  */
  // private scrollMouseMoveHandler = (pos, comp) => {
  //   // this even can be triggered by mousemove, and window.settimeout, and we don't want to run it twice in the same time, so first of all, clear the timer
  //   clearTimeout(this.isCursorOnScrollPositionTimer);
  //   if (!this.dragDropService.isDragOn()) return;

  //   let cont = this.expScrollWindow.nativeElement;
  //   let bond = cont.getBoundingClientRect();

  //   let x = pos.x;
  //   let y = pos.y;

  //   if (x > bond.left && x < bond.left + bond.width) {
  //     if (y > bond.top && y < bond.top + SidebarComponent.SCROLL_DISTANCE_SENSITIVITY) {
  //       // check the top empty range, we dont want to scroll over the emptiness
  //       // you can overflow by 30 pixel, to see you reached the last element
  //       // only when you dragged a group
  //       if (
  //         comp.getDropZone() == 'sidebar-groups' &&
  //         cont.scrollTop <=
  //           this.startPlaceFillerHeight +
  //             (SidebarComponent.SCROLL_DISTANCE_SENSITIVITY - SidebarComponent.SCROLL_OVERFLOW)
  //       ) {
  //         cont.scrollTo({
  //           top: this.startPlaceFillerHeight - SidebarComponent.SCROLL_OVERFLOW,
  //           left: 0,
  //           behavior: 'smooth',
  //         });
  //       } else {
  //         // top edge
  //         cont.scrollBy({
  //           top: -1 * SidebarComponent.SCROLL_SPEED,
  //           left: 0,
  //           behavior: 'smooth',
  //         });
  //       }

  //       this.isCursorOnScrollPositionTimer = setTimeout(() => {
  //         this.scrollMouseMoveHandler(pos, comp);
  //       }, 100);
  //     } else if (
  //       y < bond.top + bond.height &&
  //       y > bond.top + bond.height - SidebarComponent.SCROLL_DISTANCE_SENSITIVITY
  //     ) {
  //       /**
  //        * Watch out! You have to reach the height of the parent container to start scrolling. So when you reached the scrollTop 100px, you are on the containerHeight+100px
  //        */
  //       if (
  //         comp.getDropZone() == 'sidebar-groups' &&
  //         cont.scrollTop >=
  //           this.startPlaceFillerHeight +
  //             (this.expPanelNode.nativeElement.childElementCount - 1) *
  //               SidebarComponent.GROUP_BOX_HEIGHT -
  //             bond.height -
  //             (SidebarComponent.SCROLL_DISTANCE_SENSITIVITY - SidebarComponent.SCROLL_OVERFLOW)
  //       ) {
  //         cont.scrollTo({
  //           top:
  //             this.startPlaceFillerHeight +
  //             (this.expPanelNode.nativeElement.childElementCount - 1) *
  //               SidebarComponent.GROUP_BOX_HEIGHT -
  //             bond.height +
  //             SidebarComponent.SCROLL_OVERFLOW,
  //           left: 0,
  //           behavior: 'smooth',
  //         });
  //       } else {
  //         // bottom edge
  //         cont.scrollBy({
  //           top: SidebarComponent.SCROLL_SPEED,
  //           left: 0,
  //           behavior: 'smooth',
  //         });
  //       }

  //       this.isCursorOnScrollPositionTimer = setTimeout(() => {
  //         this.scrollMouseMoveHandler(pos, comp);
  //       }, 100);
  //     }
  //   }
  // };

  public onRouterChangeSearch = (res) => {
    // this.showSearch();
  };

  // private triggerIOSGUIRefresh() {
  //   setTimeout(() => {
  //     /**
  //      * In some position (rel+abs) the reverse flexbox ui renderer in safari is buggy
  //      * we have to trigger a view redraw with some viewport matrix or layout change (css hacks would also work)
  //      */
  //     if (
  //       this.deviceDetectorService.browser == 'Safari' ||
  //       (this.nativeAppService.isOnApp() && this.nativeAppService.isOnIOS())
  //     ) {
  //       this.expScrollWindow.nativeElement.scrollBy(0, -1);
  //       this.expScrollWindow.nativeElement.scrollBy(0, 1);
  //     }
  //   }, 125 + 10); // wait for the group open/close animation
  // }
}
