import { SidebarNews } from 'src/app/shared/server-services/query-records/sidebar-records';
import { getSidebarNewsQuery } from 'src/app/shared/server-services/querys';
import { CacheInterface } from '../../../../../cache-logic-interface';

export let pushNewsElementForward = (cache: CacheInterface, resourceId) => {
  let news: SidebarNews = cache.getCacheDataByRequest({
    query: getSidebarNewsQuery,
    variables: {},
  });

  // push forward the resource in the ordered list (by news)
  // do the same in the dialogue
  let index = news.resources.findIndex((res) => {
    return res.id == resourceId;
  });
  if (index > -1) {
    // push forward
    let [currNode] = news.resources.splice(index, 1);
    news.resources.unshift(currNode);
  }
};
