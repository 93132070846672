<div id="unsent-cont" *ngIf="showMessage">
  <div class="one-message own-message" appResized (resized)="onResized($event)">
    <div class="one-message-content">
      <div class="messages">
        {{ data.content }}
      </div>
      <div class="attachments">
        <ng-container *ngIf="allAttachment > 0"
          ><div>
            <span translate>Attachments: </span><span>{{ uploadedAttachment }}/{{ allAttachment }}</span>
          </div>
          <div>
            <span>{{ data.sumUploadProgress }}%</span> <mat-icon>upload_file</mat-icon>
          </div></ng-container
        >
      </div>
      <mat-progress-bar [mode]="data.sumUploadProgress === 0 ? 'indeterminate' : 'determinate'" [value]="data.sumUploadProgress"></mat-progress-bar>
    </div>
    <div class="cancel-menu">
      <mat-icon (click)="cancel()">delete</mat-icon>
      <mat-icon *ngIf="sendError" (click)="edit()">edit</mat-icon>
      <mat-icon *ngIf="uploadError" class="warn" [matTooltip]="'Error occured during the upload process' | translate">error</mat-icon>
      <mat-icon *ngIf="sendError" class="warn" [matTooltip]="'Error occured during message send' | translate">error</mat-icon>
    </div>
  </div>
</div>
