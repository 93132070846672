import { RoomRecord } from 'src/app/shared/server-services/query-records/room-records';
import { SidebarNews } from 'src/app/shared/server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionRoomEventRecord } from 'src/app/shared/server-services/query-records/workspace-records';
import { getRoomQuery, getSidebarNewsQuery } from 'src/app/shared/server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../../../workspace-change-subscription-merger-handlers/types';

export let onNewsHandlerRoomDelete: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionRoomEventRecord
) => {
  let news: SidebarNews = cache.getCacheDataByRequest({
    query: getSidebarNewsQuery,
    variables: {},
  });
  let room: RoomRecord = cache.getCacheDataByRequest({ query: getRoomQuery, variables: event });

  // remove from news
  let roomIndex = news.resources.findIndex((el) => el.id == event.id);
  if (roomIndex > -1) {
    news.resources.splice(roomIndex, 1);

    if (room && room.topMessageId && room.topMessageId < room.seenMessageId) {
      news.news--;
      if (news.news < 0) {
        news.news = 0;
        console.warn('News number is negative', news);
      }
    }
  }
};
