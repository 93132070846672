import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { DriveFile } from 'src/app/components/resource-page/drive-window/drive-layout/drive-file';
import { RoomService } from '../../server-services/room.service';
import { NanoActionQueueService } from '../../services/nano-action-queue.service';
import { NanoDialogBaseComponent } from '../nano-base-dialog';
import { NewFolderDialogComponent } from '../new-folder-dialog/new-folder-dialog.component';

@Component({
  selector: 'app-move-file-dialog',
  templateUrl: './move-file-dialog.component.html',
  styleUrls: ['./move-file-dialog.component.scss'],
})
export class MoveFileDialogComponent extends NanoDialogBaseComponent<MoveFileDialogComponent> {
  public currentPath: string = '';
  public selectedFiles: DriveFile[] = [];
  public folders: DriveFile[] = [];
  public isRootFolder: boolean = true;
  public selectedFilesCount: number = 0;
  public selectedFoldersCount: number = 0;

  private path: string[] = [];
  private loading: boolean = false;

  constructor(
    private actionQueue: NanoActionQueueService,
    private translate: TranslateService,
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: MoveFileDialogData,
    private roomService: RoomService
  ) {
    super(injector);

    this.currentPath = data.path;
    this.selectedFiles = data.files;
    this.selectedFilesCount = data.files.filter((f) => !f.isDir()).length;
    this.selectedFoldersCount = data.files.filter((f) => f.isDir()).length;
    this.initPath(this.currentPath);
    this.getFolders();
  }

  private getFolders() {
    this.roomService
      .getNanoSession(this.data.resourceId)
      .then((nanoSession) => {
        this.actionQueue.listDir(
          this.data.resourceId,
          this.path.join('/'),
          nanoSession.version,
          (data) => this.filesLoadedHandler(data),
          (err) => this.filesLoadedErrorHandler(err)
        );
      })
      .catch((err) => this.filesLoadedErrorHandler(err));
  }

  private filesLoadedHandler(data) {
    this.folders = [];
    data
      .filter((d) => (d.unresolved ? !d.unresolved : true) && d['size'] === undefined)
      .forEach((folder) => {
        let driveFolder = new DriveFile(folder);
        this.folders.push(driveFolder);
      });

    this.isRootFolder = this.path.length == 0;
    this.loading = false;
  }

  private filesLoadedErrorHandler(err) {
    console.error(err);
    this.loading = false;
  }

  private initPath(pathString: string) {
    var pathParts = pathString.split('/');
    if (pathParts.length > 1 || pathParts[0] !== '') {
      this.path = pathParts;
    }
  }

  public back() {
    if (this.isRootFolder) return;
    if (this.loading) return;
    this.loading = true;
    this.path.pop();
    this.currentPath = this.path.join('/');
    this.getFolders();
  }

  public openFolder(folderName: string) {
    if (this.loading) return;
    this.loading = true;
    this.path.push(folderName);
    this.currentPath = this.path.join('/');
    this.getFolders();
  }

  public newFolderDialog() {
    var dialogRef = this.dialog.open(NewFolderDialogComponent, {
      data: { name: this.translate.instant(marker('New Folder')) },
    });

    dialogRef.afterClosed().subscribe((folderName) => {
      if (!folderName || folderName.length == 0) return;
      let fullPath = folderName;
      if (this.path.length > 0) {
        fullPath = this.path.join('/') + '/' + folderName;
      }
      this.actionQueue.makeDir(
        this.data.resourceId,
        fullPath,
        () => this.getFolders(),
        (err) => console.error(err)
      );
    });
  }
}

export interface MoveFileDialogData {
  resourceId: string;
  path: string;
  files: DriveFile[];
}
