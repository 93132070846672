<div>
  <div class="sub-page">
    <h1 translate>Licenses and Bundles</h1>
    <small translate>Check out your active Nano licenses and active recurring licenses here. If you have unclaimed bundle licenses, you can claim them here or copy them for distribution.</small>
    <p *ngIf="isOnApp" translate>Buying license only available in browser environment. On mobile application we will redirect you to our website.</p>

    <ng-template #loadingContent>
      <mat-icon><mat-spinner diameter="20"></mat-spinner></mat-icon>
    </ng-template>

    <ng-container *ngIf="licenses && bundles; else loadingContent">
      <div class="button-row">
        <button *ngIf="!isOnApp" mat-raised-button color="primary" routerLink="/license-buy"><mat-icon>shopping_cart</mat-icon><span translate>Buy license</span></button>
        <button *ngIf="isOnApp" mat-raised-button color="primary" (click)="openBuyLicenseInBrowser()"><mat-icon>shopping_cart</mat-icon><span translate>Buy license</span></button>

        <button mat-raised-button color="primary" (click)="showRedeemDialog()"><mat-icon>how_to_vote</mat-icon><span translate>Redeem license token</span></button>
      </div>
      <div id="nano-manager-link" translate>Open <a (click)="openNanoManagerDialog()" translate>Nano Manager</a></div>

      <ng-container *ngIf="licenses.length > 0">
        <h2 translate>Your Nano licenses</h2>
        <table id="license-table" #licenseTable mat-table [dataSource]="licenses" class="mat-elevation-z8 desktop-table">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef translate>Key</th>
            <td mat-cell *matCellDef="let license" [ngClass]="{ expired: isExpired(license), revoked: license.revoked }">
              {{ license.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef translate>Nano slots</th>
            <td mat-cell *matCellDef="let license" class="center" [ngClass]="{ expired: isExpired(license), revoked: license.revoked }">
              {{ license.number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="recurrence">
            <th mat-header-cell *matHeaderCellDef translate>Recurring until</th>
            <td mat-cell *matCellDef="let license" class="center">
              <span *ngIf="license.recurringOrder"
                ><a class="date-link" (click)="openRecurringOrderDetail(license.recurringOrder)">{{ license.recurringOrder.recurring_until * 1000 | date: 'medium' }}</a></span
              >
              <span *ngIf="!license.recurringOrder" translate> - </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="expiration">
            <th mat-header-cell *matHeaderCellDef translate>Expires</th>
            <td mat-cell *matCellDef="let license" [ngClass]="{ expired: isExpired(license), revoked: license.revoked }">
              {{ license.expiration * 1000 | date: 'medium' }} <span class="badge-holder" matBadge="!" matBadgeSize="small" [matBadgeHidden]="!isExpiring(license, 2)"></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="revoked">
            <th mat-header-cell *matHeaderCellDef translate>Valid</th>
            <td mat-cell *matCellDef="let license" class="center">
              <mat-icon color="primary" *ngIf="!license.revoked">check</mat-icon>
              <mat-icon color="primary" *ngIf="license.revoked">close</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="licenseColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: licenseColumns"></tr>
        </table>

        <div class="mobile-table">
          <mat-card appearance="outlined" *ngFor="let license of licenses">
            <mat-card-content [ngClass]="{ expired: isExpired(license), revoked: license.revoked }">
              <div class="title-cell" translate>Key</div>
              <div class="value-cell">{{ license.id }}</div>
              <div class="title-cell" translate>Nano slots</div>
              <div class="value-cell">{{ license.number }}</div>
              <div class="title-cell" translate>Expires</div>
              <div class="value-cell">{{ license.expiration * 1000 | date: 'medium' }}</div>
              <div class="title-cell" translate>Recurring until</div>
              <div class="value-cell">
                <span *ngIf="!license.recurringOrder" translate> - </span>
                <span *ngIf="license.recurringOrder">
                  <a class="date-link" (click)="openRecurringOrderDetail(license.recurringOrder)">{{ license.recurringOrder.recurring_until * 1000 | date: 'medium' }} </a></span
                >
              </div>
              <div class="title-cell" translate>Valid</div>
              <div class="value-cell"><mat-icon color="primary" *ngIf="!license.revoked">check</mat-icon><mat-icon color="primary" *ngIf="license.revoked">close</mat-icon></div>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>

      <ng-container *ngIf="bundles.length > 0">
        <h2 translate>Your Nano bundles</h2>
        <small class="h2-small">The bundles include all of your available licenses, even the ones that are already in use.</small>

        <table mat-table [dataSource]="bundles" class="mat-elevation-z8 desktop-table">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef translate>Key</th>
            <td mat-cell *matCellDef="let bundle" [ngClass]="{ expired: isExpired(bundle), revoked: bundle.revoked }">
              {{ bundle.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef translate>License count</th>
            <td mat-cell *matCellDef="let bundle" class="center" [ngClass]="{ expired: isExpired(bundle), revoked: bundle.revoked }">
              {{ bundle.number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="expiration">
            <th mat-header-cell *matHeaderCellDef translate>Expires</th>
            <td mat-cell *matCellDef="let bundle" [ngClass]="{ expired: isExpired(bundle), revoked: bundle.revoked }">
              {{ bundle.expiration * 1000 | date: 'medium' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="months">
            <th mat-header-cell *matHeaderCellDef translate>Length</th>
            <td mat-cell *matCellDef="let bundle" [ngClass]="{ expired: isExpired(bundle), revoked: bundle.revoked }">
              {{ bundle.months }} <span *ngIf="bundle.months === 1" translate>month</span><span *ngIf="bundle.months > 1" translate>months</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="revoked">
            <th mat-header-cell *matHeaderCellDef translate>Valid</th>
            <td mat-cell *matCellDef="let bundle" class="center">
              <mat-icon color="primary" *ngIf="!bundle.revoked">check</mat-icon>
              <mat-icon color="primary" *ngIf="bundle.revoked">close</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef translate>Show / Download</th>
            <td mat-cell *matCellDef="let bundle" class="center action-buttons">
              <button mat-icon-button (click)="showBundleLicenses(bundle)" [disabled]="bundle.revoked || isExpired(bundle)"><mat-icon>visibility</mat-icon></button>
              <button mat-icon-button (click)="downloadBundleLicenses(bundle)" [disabled]="bundle.revoked || isExpired(bundle)"><mat-icon>file_download</mat-icon></button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="bundleColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: bundleColumns"></tr>
        </table>

        <div class="mobile-table">
          <mat-card appearance="outlined" *ngFor="let bundle of bundles">
            <mat-card-content [ngClass]="{ expired: isExpired(bundle), revoked: bundle.revoked }">
              <div class="title-cell" translate>Key</div>
              <div class="value-cell">{{ bundle.id }}</div>
              <div class="title-cell" translate>License count</div>
              <div class="value-cell">{{ bundle.number }}</div>
              <div class="title-cell" translate>Expires</div>
              <div class="value-cell">{{ bundle.expiration * 1000 | date: 'medium' }}</div>
              <div class="title-cell" translate>Length</div>
              <div class="value-cell">{{ bundle.months }} <span *ngIf="bundle.months === 1" translate>month</span><span *ngIf="bundle.months > 1" translate>months</span></div>
              <div class="title-cell" translate>Valid</div>
              <div class="value-cell"><mat-icon color="primary" *ngIf="!bundle.revoked">check</mat-icon><mat-icon color="primary" *ngIf="bundle.revoked">close</mat-icon></div>
              <div class="title-cell" translate>Show / Download</div>
              <div class="value-cell">
                <button mat-icon-button (click)="showBundleLicenses(bundle)" [disabled]="bundle.revoked || isExpired(bundle)"><mat-icon>visibility</mat-icon></button>
                <button mat-icon-button (click)="downloadBundleLicenses(bundle)" [disabled]="bundle.revoked || isExpired(bundle)"><mat-icon>file_download</mat-icon></button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
