import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NanoDialogBaseComponent } from '../nano-base-dialog';
import { DialogData } from '../new-group-dialog/new-group-dialog.component';

@Component({
  selector: 'app-delete-group-dialog',
  templateUrl: './delete-group-dialog.component.html',
  styleUrls: ['./delete-group-dialog.component.scss'],
})
export class DeleteGroupDialogComponent extends NanoDialogBaseComponent<DeleteGroupDialogComponent> {
  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super(injector);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.data);
  }
}
