import { AbstractConfigDumpArgs, AbstractConfigLoadArgs } from '../base';

export class LocalStorageConfigLoadArgs extends AbstractConfigLoadArgs {
  public server_key;
  public client_key;
  public salt;

  constructor(
    cipher: Uint8Array,
    server_key: Uint8Array,
    client_key: Uint8Array,
    salt: Uint8Array = new Uint8Array([])
  ) {
    super(cipher);
    this.server_key = server_key;
    this.client_key = client_key;
    this.salt = salt;
  }
}

export class LocalStorageConfigDumpArgs extends AbstractConfigDumpArgs {
  public plain;
  public server_key;
  public client_key;
  public salt;

  constructor(
    plain: Uint8Array,
    server_key: Uint8Array,
    client_key: Uint8Array,
    salt: Uint8Array = new Uint8Array([])
  ) {
    super();
    this.plain = plain;
    this.server_key = server_key;
    this.client_key = client_key;
    this.salt = salt;
  }
}
