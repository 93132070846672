import { AbstractSelfAccountKeyring } from '../../keyring/account_base';
import { AbstractConfigLoadArgs, AbstractConfigDumpArgs } from '../base';

export class AccountConfigLoadArgs extends AbstractConfigLoadArgs {
  public self_kr;

  constructor(cipher: Uint8Array, self_kr: AbstractSelfAccountKeyring) {
    super(cipher);
    this.self_kr = self_kr;
  }
}

export class AccountConfigDumpArgs extends AbstractConfigDumpArgs {
  public plain;
  public self_kr;

  constructor(plain: Uint8Array, self_kr: AbstractSelfAccountKeyring) {
    super();
    this.plain = plain;
    this.self_kr = self_kr;
  }
}
