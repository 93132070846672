import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppStorage } from 'src/app/shared/app-storage';
import { EnvironmentService } from 'src/environments/environment.service';
import { AccountService, UserVerificationState } from '../../server-services/account.service';
import { AccountAvatarRecord } from '../../server-services/query-records/account-records';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from '../../server-services/subscription-event';
import { SubscriptionService } from '../../server-services/subscription.service';
import { BlobService } from '../../services/blob.service';
import { styleChangedObserver } from '../../shared-observers';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnChanges, OnDestroy, OnInit {
  @Input() accountAvatarRecord?: AccountAvatarRecord;
  @Input() blob?: Blob | ArrayBuffer;
  @Input() verify?: string;
  public src: SafeUrl = '';
  public objectUrl: string = '';
  public isTrustVerified: boolean = false;
  public isTrustDangerous: boolean = false;

  constructor(
    private environmentService: EnvironmentService,
    private blobService: BlobService,
    private accountService: AccountService,
    private subscriptionService: SubscriptionService,
    private sanitizer: DomSanitizer
  ) {
    styleChangedObserver.subscribe(() => this.onChanges());
  }

  ngOnInit(): void {
    if (this.verify !== undefined && this.accountAvatarRecord)
      this.subscriptionService.subscribe(
        SubscriptionServiceEvent.ACCOUNT_TRUST_EVENT,
        SubscriptionServiceEventType.ALL,
        this.verifyUser
      );
    this.verifyUser();
  }

  private verifyUser = () => {
    if (this.verify !== undefined && this.accountAvatarRecord) {
      this.accountService.getUserVerificationState(this.accountAvatarRecord.id).then((ver) => {
        this.isTrustVerified = ver == UserVerificationState.VERIFIED;
        this.isTrustDangerous = ver == UserVerificationState.DANGEROUS;
      });
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    this.verifyUser();
    this.onChanges();
  }

  private onChanges(): void {
    if (this.accountAvatarRecord?.avatarImageKey) {
      /*this.src =
        this.environmentService.url_api +
        '/r/avatar/' +
        this.accountAvatarRecord.id +
        '/' +
        this.accountAvatarRecord.avatarImageKey;*/
      this.accountService.getAvatarImage(this.accountAvatarRecord).then((blob) => {
        var urlCreator = window.URL || window.webkitURL;
        this.objectUrl = urlCreator.createObjectURL(this.blobService.new([blob]));
        this.src = this.sanitizer.bypassSecurityTrustUrl(this.objectUrl);
      });
    } else if (this.accountAvatarRecord?.deleted) {
      this.setDeletedImage();
    } else if (this.blob) {
      var urlCreator = window.URL || window.webkitURL;
      this.objectUrl = urlCreator.createObjectURL(this.blobService.new([this.blob]));
      this.src = this.sanitizer.bypassSecurityTrustUrl(this.objectUrl);
    } else {
      this.setDefaultImage();
    }
  }

  setDefaultImage() {
    var styleMode = AppStorage.getItem('style_mode');
    if (this.accountAvatarRecord)
      this.src = this.sanitizer.sanitize(
        SecurityContext.URL,
        `/assets/img/ui/user-${styleMode}.svg`
      );
    else
      this.src = this.sanitizer.sanitize(
        SecurityContext.URL,
        `/assets/img/ui/room-${styleMode}.svg`
      );
  }

  setDeletedImage() {
    var styleMode = AppStorage.getItem('style_mode');
    if (this.accountAvatarRecord)
      this.src = this.sanitizer.sanitize(
        SecurityContext.URL,
        `/assets/img/ui/user-${styleMode}-deleted.svg`
      );
    else
      this.src = this.sanitizer.sanitize(
        SecurityContext.URL,
        `/assets/img/ui/room-${styleMode}-deleted.svg`
      );
  }

  ngOnDestroy(): void {
    var urlCreator = window.URL || window.webkitURL;
    urlCreator.revokeObjectURL(this.objectUrl);

    if (this.verify !== undefined && this.accountAvatarRecord) {
      this.subscriptionService.unsubscribe(
        SubscriptionServiceEvent.ACCOUNT_TRUST_EVENT,
        SubscriptionServiceEventType.ALL,
        this.verifyUser
      );
    }
  }
}
