<div class="manager-collection-container">
  <div [ngClass]="{ 'manager-collection-preview': true, done: isUploading }" (click)="openPath()">
    <span class="mdi mdi-folder file-folder"></span>
  </div>
  <div class="manager-collection-status">
    <div class="manager-collection-info">
      <div [ngClass]="{ 'manager-collection-title': true, 'mat-small': true, done: !isUploading }">
        <span (click)="openPath()">{{ collection.path }}</span>
        <!-- <div class="manager-collection-part-name" *ngIf="collection.inProgressPartNames.length < 3">
          <div *ngFor="let onePartName of collection.inProgressPartNames">...{{ onePartName }}</div>
        </div> -->
        <div *ngIf="isOpen">
          <div class="manager-collection-part-name" (click)="closeDetails()" *ngIf="!collection.isDone()">
            <div>
              <span class="inactive-text" translate>hide details</span>
              <mat-icon class="inactive-text details-arrow">arrow_upward</mat-icon>
            </div>
            <!-- <div *ngFor="let onePart of collection.parts.filter(part=>part.status === 'pending')"> -->
            <div *ngFor="let onePart of collection.inProgressParts" (click)="openPath(onePart)">
              <div *ngIf="onePart?.status === 'inprogress'">
                <div class="inline-block manager-collection-part-progress-box">{{ onePart.progress ?? 0 }}%</div>
                <div class="inline-block manager-collection-part-path-box">...{{ onePart?.path }}</div>
                <!-- 
                  <div class="inline-block manager-collection-part-size-box">
                    ( {{ ((onePart?.progress ?? 0) * (onePart?.file?.file?.size ?? 0)) / 100 | formatBytes: 2 }} / {{ onePart.file.file.size | formatBytes: 2 }} )
                  </div>
                  <div class="inline-block manager-collection-part-time-box">{{ onePart.timeNeeded }}</div> 
                -->
              </div>
            </div>
          </div>
        </div>
        <!-- Error displaying -->
        <div *ngIf="collection.errorParts.length > 0">
          <div class="warn">
            <span translate>Error occurred during download: </span><span>({{ collection.errorParts.length }})</span>
          </div>
          <!--<button (click)="(resumeCollectionWithError)">Fix</button>-->
          <div *ngFor="let onePart of collection.errorParts" (click)="openPath(onePart)" class="collection-error-file warn">
            <div class="inline-block manager-collection-part-progress-box">{{ onePart.progress ?? 0 }}%</div>
            <div class="inline-block manager-collection-part-path-box">...{{ onePart?.path }}</div>
          </div>
        </div>
        <div (click)="openDetails()" *ngIf="!isOpen && !collection.isDone()">
          <span class="inactive-text" translate>open details</span>
          <mat-icon class="inactive-text details-arrow">arrow_downward</mat-icon>
        </div>
      </div>

      <div class="manager-collection-progress mat-small" *ngIf="!collection.isDone()">( {{ collection.currPart }} / {{ collection.countPart }} )</div>
      <div class="manager-collection-progress mat-small" *ngIf="!collection.isDone()">{{ collection.timeNeeded }} -</div>
      <div class="manager-collection-progress mat-small" *ngIf="!collection.isDone()">( {{ collection.currSize | formatBytes: 2 }} / {{ collection.maxSize | formatBytes: 2 }} )</div>
      <mat-icon class="manager-collection-direction" *ngIf="!collection.isDone() && collection.direction === 'down'">arrow_downward</mat-icon>
      <mat-icon class="manager-collection-direction" *ngIf="!collection.isDone() && collection.direction === 'up'">arrow_upward</mat-icon>
      <div class="manager-collection-progress mat-small" *ngIf="!collection.isDone()">{{ sumSpeed | formatBytes: 1 }}/s</div>
      <div class="manager-collection-progress mat-small" *ngIf="!collection.isDone()">{{ sumRawTimeNeeded }}</div>
    </div>
    <div class="manager-collection-info">
      <div [ngClass]="{ 'manager-collection-room': true, 'mat-small': true, done: !isUploading }" (click)="openPath()">
        <app-avatar *ngIf="roomData" [blob]="roomData['data'].avatar"></app-avatar><span *ngIf="roomData">{{ roomData['data'].name }}</span>
      </div>
      <span class="mat-small manager-collection-time" *ngIf="!collection.isDone()">{{ collection.speed }}</span>
    </div>
    <div class="manager-collection-bar" *ngIf="!collection.isDone()">
      <mat-progress-bar mode="determinate" [value]="collection.progress"></mat-progress-bar>
    </div>
  </div>
  <div class="manager-collection-progress mat-small" *ngIf="collection.isDone()">
    {{ collection.maxSize | formatBytes: 2 }}
  </div>
  <mat-icon class="manager-collection-direction" *ngIf="collection.isDone() && collection.direction === 'down'">arrow_downward</mat-icon>
  <mat-icon class="manager-collection-direction" *ngIf="collection.isDone() && collection.direction === 'up'">arrow_upward</mat-icon>
  <div class="manager-collection-action">
    <mat-icon
      color="warn"
      *ngIf="!collection.isDone() && !collection.errorFixStarted && !collection.isVerifying() && collection.isError()"
      (click)="resumeWithError()"
      [matTooltip]="'Error occured during the process' | translate"
      >error</mat-icon
    >

    <mat-icon *ngIf="collection.isDone() || collection.isPaused()" (click)="remove()" [matTooltip]="'Remove the element from the list' | translate">delete</mat-icon>

    <mat-icon *ngIf="!collection.isDone() && !collection.isPaused()" (click)="pause()" [matTooltip]="'Pause the operation' | translate">pause</mat-icon>
    <mat-icon *ngIf="!collection.isDone() && collection.isPaused()" (click)="resume()" [matTooltip]="'Resume the operation' | translate">play_arrow</mat-icon>
  </div>
</div>
