<div id="contact-container">
  <div>
    <app-avatar mat-card-avatar [accountAvatarRecord]="user"></app-avatar>
  </div>
  <div>
    <span class="center mat-headline-6 notranslate">{{ user.avatarName }}</span>
  </div>
  <mat-divider></mat-divider>
  <div class="dia-btn" (click)="addContact()" *ngIf="!addContactDone && !isThisMe">
    <mat-icon *ngIf="!addContactInProgress">person_add</mat-icon>
    <mat-spinner *ngIf="addContactInProgress" diameter="20"></mat-spinner>
    <span translate>Add contact</span>
  </div>
  <div class="dia-btn" *ngIf="addContactDone && !isThisMe">
    <mat-icon color="primary">task_alt</mat-icon>
    <span translate>Saved in your contact</span>
  </div>
  <div class="dia-btn" (click)="openChat()" *ngIf="!isThisMe">
    <mat-icon>chat</mat-icon>
    <span translate>Enter chat</span>
  </div>
  <mat-divider></mat-divider>
  <div class="dia-btn" (click)="close()">
    <span translate class="center">Close</span>
  </div>
</div>
