import { Injectable, Injector } from '@angular/core';
import { ROOM_CRYPTO_CONTEXT } from '../crypto/context/room/__init__';
import { RoomConfigDumpArgs, RoomConfigLoadArgs } from '../crypto/context/room/args';
import { getTimeFromId } from '../crypto/context/timestamp';
import { NanoService } from '../nano/nano.service';
import {
  chatChangesSubscription,
  createRoomMessageQuery,
  deleteRoomMessageQuery,
  editRoomMessageQuery,
  getSeenIdsByMessageQuery,
  pinRoomMessageQuery,
  reactRoomMessageQuery,
  roomMessagesByIdQuery,
  roomMessagesQuery,
  seenRoomMessageQuery,
  sendRoomTypingQuery,
  unpinRoomMessageQuery,
} from '../server-services/querys';
import { ID } from './query-records/common-records';
import {
  DialogueDetail,
  LoadMessageDirection,
  MessageRecord,
  ReactionEnum,
  RoomDetail,
  RoomPermission,
} from './query-records/room-records';
import {
  ParsedMessageRecord,
  ResourceBase,
  UnsentMessageAttachment,
  UnsentMessageAttachmentLocation,
} from './resource-base';

import { environment } from 'src/environments/environment';
import { RouterHandler } from '../services/router-handler.service';
import { encodeWH } from '../wh-coder';
import { roomChatUnsentMessageStorageKey } from './chat-service-types';
import { WorkspaceSubscriptionRoomMessageEventRecord } from './query-records/workspace-records';
import { RoomKeyringService } from './room-keyring.service';
import { RoomService } from './room.service';
import { SubscriptionServiceEvent } from './subscription-event';

/**
 * these event types will come from the server
 * CMD is coming from the MessageEvent, and we have an independent typingEvent
 * but we will hide these separation from the api-user layer
 */
export enum SubscriptionChatEventType {
  CREATE = 'C',
  MODIFY = 'M',
  DELETE = 'D',
  TYPING = 'T',
}

export interface ChatEventSubscriptionWrapperObject {
  // subscriptions for the specific method
  C: Function[];
  M: Function[];
  D: Function[];
  T: Function[];
  subscription: any; // observable
}

@Injectable({
  providedIn: 'root',
})
export class ChatService extends ResourceBase {
  /**
   * Map<resourceId, event handlers>
   * This collection is wrap all of the subscription handler for a specific resource (key)
   * We separate the CMD+Typing callbacks
   * You can use the subscription to unsubscribe an apollo subscription
   */
  private subscriptions: Map<string, ChatEventSubscriptionWrapperObject> = new Map<
    string,
    ChatEventSubscriptionWrapperObject
  >();

  constructor(
    protected nanoService: NanoService,
    protected routerHandler: RouterHandler,
    protected injector: Injector,
    protected roomService: RoomService,
    protected roomKeyringService: RoomKeyringService
  ) {
    super(injector);
  }

  public getKeyring(resourceId) {
    return this.roomKeyringService.getKeyring(resourceId);
  }

  // direct api-calls --------------------------------------

  /**
   * get Data for Room. As a side effect it will save the room_kr for future requestes
   * @param id
   * @returns
   */
  public getData(id): Promise<Object> {
    return this.roomService.getRoom(id);
  }

  /**
   * Note: Be sure, you have already called the getRoomData - need for creating the room_kr
   */
  public getLastMessages(resourceId): Promise<MessageRecord[]> {
    return this.serverRestApiService.query({
      query: roomMessagesQuery,
      variables: {
        roomId: resourceId,
        pivot: null,
        after: false,
        count: 30,
        prepareSub: true,
      },
      decrypt: this.decryptMessages.bind(this),
    });
  }

  public getMoreMessages(
    resourceId,
    cursorMessageId,
    direction = LoadMessageDirection.AFTER,
    count = 20
  ): Promise<MessageRecord[]> {
    return this.serverRestApiService.query({
      query: roomMessagesQuery,
      variables: {
        roomId: resourceId,
        pivot: cursorMessageId,
        after: direction !== LoadMessageDirection.AFTER, //on server, 'after' is in perspective of date of messages. On client, 'after' is in perspective of the message array
        count: count,
        prepareSub: false,
        direction,
      },
      decrypt: this.decryptMessages.bind(this),
    });
  }

  public sendTyping(resourceId: string): Promise<boolean> {
    return this.serverRestApiService.mutate({
      query: sendRoomTypingQuery,
      variables: {
        roomId: resourceId,
      },
    });
  }

  public deleteMessage(resourceId, messageId): Promise<any> {
    return this.serverRestApiService.mutate({
      query: deleteRoomMessageQuery,
      variables: {
        roomId: resourceId,
        messageId,
      },
    });
  }

  public editMessage(resourceId, messageId, content, replyTo): Promise<any> {
    return this.serverRestApiService.mutate({
      query: editRoomMessageQuery,
      variables: {
        roomId: resourceId,
        messageId,
      },
      extra: {
        message: content,
        replyTo,
      },
      encrypt: this.encryptMessage.bind(this),
    });
  }

  public seenMessage(resourceId, messageId): Promise<any> {
    return this.serverRestApiService.mutate({
      query: seenRoomMessageQuery,
      variables: {
        roomId: resourceId,
        seenId: messageId,
      },
    });
  }

  public getDetail(resourceId): Promise<RoomDetail | DialogueDetail> {
    return this.roomService.getDetail(resourceId);
  }

  pinMessage(resourceId, messageId): Promise<void> {
    return this.serverRestApiService.mutate({
      query: pinRoomMessageQuery,
      variables: {
        roomId: resourceId,
        messageId,
      },
    });
  }

  unpinMessage(resourceId, messageId): Promise<void> {
    return this.serverRestApiService.mutate({
      query: unpinRoomMessageQuery,
      variables: {
        roomId: resourceId,
        messageId,
      },
    });
  }

  public getSeenIdsByMessage(resourceId, messageId): Promise<ID[]> {
    return this.serverRestApiService.query({
      query: getSeenIdsByMessageQuery,
      variables: { resourceId, messageId },
    });
  }

  public reactMessage(
    resourceId: any,
    messageId: any,
    reaction: ReactionEnum | null
  ): Promise<void> {
    return this.serverRestApiService.mutate({
      query: reactRoomMessageQuery,
      variables: {
        roomId: resourceId,
        messageId,
        reaction,
      },
    });
  }

  protected queryMessagesById(resourceId: ID, messageIds: ID[]): Promise<MessageRecord[]> {
    return this.serverRestApiService.query({
      query: roomMessagesByIdQuery,
      variables: {
        roomId: resourceId,
        messageIds: messageIds,
      },
      decrypt: this.decryptMessages.bind(this),
    });
  }

  public getChatPermissions(resourceId: any): Promise<RoomPermission> {
    if (this.authService.isAnonym()) {
      return Promise.resolve(this.permissionService.getAnonymRoomPermissions());
    }

    return this.permissionService.getMyRoomPermissions(resourceId);
  }

  public decryptOneMessage(
    resourceId: ID,
    messageId: ID,
    encryptedMessage: Uint8Array
  ): Promise<ParsedMessageRecord> {
    return this.getKeyring(resourceId).then((room_kr) => {
      return ROOM_CRYPTO_CONTEXT.load(
        new RoomConfigLoadArgs(encryptedMessage, room_kr, getTimeFromId(messageId))
      ).then((loadedData) => {
        return this.convertMessageFromEncryptableObject(loadedData);
      });
    });
  }

  protected encryptMessage(variables, extra): Promise<Object> {
    let parsedMessage = this.convertMessageToEncrypedObject(extra);

    return this.getKeyring(variables['roomId']).then((room_kr) => {
      return ROOM_CRYPTO_CONTEXT.dump(
        new RoomConfigDumpArgs(
          parsedMessage,
          room_kr,
          variables.messageId
            ? getTimeFromId(variables.messageId)
            : this.serverRestApiService.makeTimestamp()
        )
      ).then((content) => {
        variables['content'] = content;
        return variables;
      });
    });
  }

  protected callSendMessageMutation(resourceId: ID, message: string, replyTo?: ID): Promise<any> {
    return this.serverRestApiService.mutate({
      query: createRoomMessageQuery,
      variables: {
        roomId: resourceId,
      },
      extra: {
        message,
        replyTo,
      },
      encrypt: this.encryptMessage.bind(this),
    });
  }

  protected createAttachmentLocation(
    resourceId,
    name: string,
    path: string,
    previewSize?: number
  ): UnsentMessageAttachmentLocation {
    // cut the file from the path
    let dirPath = path.substring(0, path.lastIndexOf('/') + 1);

    let fragment = {
      path: dirPath,
      file: name,
    };
    if (previewSize) {
      fragment['s'] = previewSize;
    }

    return {
      name,
      path,
      url:
        environment.site_base +
        '/room/' +
        resourceId +
        '/drive#' +
        this.routerHandler.fragmentToRaw(fragment),
    };
  }

  protected getUnsentMessageStorageKeyPrefix(): string {
    return roomChatUnsentMessageStorageKey;
  }

  // Note: basic chat subscription is moved to the workspace, but for anonym user we still need this feature
  // subscriptions handler ------------------------------------------

  /**
   * When you subscribe for a new chat changes, you have to prepare the inner dataset with a
   * new callback handler for all of the operation
   * @param resourceId
   * @returns
   */
  private generateNewChatSubscriptionWrapper(): ChatEventSubscriptionWrapperObject {
    return {
      C: [],
      M: [],
      D: [],
      T: [],
      subscription: null,
    };
  }

  /**
   * The subscribeChatChanges will connect this handler with the correct ChatEventSubscriptionWrapperObject
   * This function is handling the server data via subscription
   * and call the actual subscription operations like create/modify/delete/typing
   * @param subWrapper ChatEventSubscriptionWrapperObject
   * @param data query result from the server
   */
  private handleSubscriptionChanges(subWrapper: ChatEventSubscriptionWrapperObject, data: any) {
    let msg = data;

    if ('roomMessageEvent' in msg) {
      let result = msg['roomMessageEvent'];

      // call the current (C, M, D) array foreach
      subWrapper[result['cmd']].forEach((cb) => {
        cb(result);
      });
    }
    if ('typingEvent' in msg) {
      subWrapper.T.forEach((cb) => {
        cb(msg['typingEvent']);
      });
    }
  }

  public subscribeChatChanges(resourceId, event: SubscriptionChatEventType, handler: Function) {
    if (!this.subscriptions.has(resourceId)) {
      // check if subscription is already exist for resource

      // generate new subscription handler for this resource
      let subWrapper = this.generateNewChatSubscriptionWrapper();
      subWrapper[event].push(handler);
      this.subscriptions.set(resourceId, subWrapper); // register handler

      subWrapper.subscription = this.serverRestApiService
        .subscribe({
          // set subscription
          query: chatChangesSubscription,
          variables: {
            roomId: resourceId,
            prepared: true,
          },
          decrypt: this.decryptSubscribeMessage.bind(this),
        })
        .subscribe({
          next: (res) => {
            // pass the core handler, to divide the response into CMD+typing
            this.handleSubscriptionChanges(subWrapper, res);
          },
        });
    } else {
      this.subscriptions.get(resourceId)[event].push(handler); // just simply register the handler
    }
  }

  private decryptSubscribeMessage(data): Promise<Object> {
    let p: Promise<void>;

    if (data[SubscriptionServiceEvent.ROOM_MESSAGE_EVENT]) {
      let messageEvent = data[SubscriptionServiceEvent.ROOM_MESSAGE_EVENT];
      let eventCopy = {};
      for (let prop in messageEvent) {
        if (prop != 'content') {
          // copy everything, except content, which will be decrypted later
          eventCopy[prop] = messageEvent[prop];
        }
      }

      data[SubscriptionServiceEvent.ROOM_MESSAGE_EVENT] = <
        WorkspaceSubscriptionRoomMessageEventRecord
      >eventCopy;

      if (messageEvent.content) {
        data[SubscriptionServiceEvent.ROOM_MESSAGE_EVENT].decryptionError = false;
        // if already opened, you can use keyring, else just skip
        p = this.decryptOneMessage(messageEvent.id, messageEvent.messageId, messageEvent.content)
          .then((decryptedParsedMessageRecord) => {
            this.applyParsedMessageRecordOnMessageRef(
              data[SubscriptionServiceEvent.ROOM_MESSAGE_EVENT],
              decryptedParsedMessageRecord
            );
            return;
          })
          .catch((e) => {
            console.warn('room msg decryption error', e);
            data[SubscriptionServiceEvent.ROOM_MESSAGE_EVENT].decryptionError = true;
            data[SubscriptionServiceEvent.ROOM_MESSAGE_EVENT].decryptionErrorMessage = e;
            return;
          });
      }
    }

    if (p) {
      return p.then(() => {
        return data;
      });
    } else {
      return Promise.resolve(data);
    }
  }

  /**
   * as an anonym user, you dont have workspace, you can get the news with this
   * @param resourceId
   */
  public unsubscribeAllChatChanges(resourceId) {
    let chatChangeHandlerObject = this.subscriptions.get(resourceId);
    if (chatChangeHandlerObject) {
      // we shouldn't close the observer, because the cache can be invalid after modification/delete
      //chatChangeHandlerObject.subscription.unsubscribe(); // unsubscribe form the observer

      // reset all the handler
      chatChangeHandlerObject.C = [];
      chatChangeHandlerObject.M = [];
      chatChangeHandlerObject.D = [];
      chatChangeHandlerObject.T = [];
    }
  }

  protected peekUpdateAttachment(
    resourceId: ID,
    attachment: UnsentMessageAttachment
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.roomService
        .getNanoSession(resourceId)
        .then((nanoSession) => {
          let peekAndUpdateLocation = () => {
            this.nanoService.peekFile({
              resourceId,
              path: attachment.file.path,
              responseCallback: (res) => {
                if (res.size !== undefined) {
                  if (res.preview) {
                    attachment.location = this.createAttachmentLocation(
                      resourceId,
                      attachment.file.fullName,
                      attachment.file.path,
                      res.ps || encodeWH(400, 400)
                    );
                    resolve();
                  }
                } else {
                  // folder
                  resolve();
                }
              },
              errorCallback: (err) => {
                resolve(); // older nano does not have this api, just ignore it
              },
              nanoVersion: nanoSession.version,
            });
          };

          peekAndUpdateLocation();

          setTimeout(() => {
            resolve();
          }, 10000);
        })
        .catch(() => {
          resolve();
        });
    });
  }
}
