import {
  accountAvatarsQuery,
  aggregateStats,
  dialogueMessagesByIdQuery,
  dialogueMessagesQuery,
  getAllAccountIdQuery,
  getAllRoomQuery,
  getAllSidebarGroupQuery,
  getDialogueDataQuery,
  getLoginSessions,
  getNanoBundleLicensesQuery,
  getNanoLicensesQuery,
  getNanoSlotsQuery,
  getPaymentBillingsQuery,
  getRecurringOrdersQuery,
  getRoomDatasQuery,
  getRoomQuery,
  getRoomWithDataQuery,
  getSeenIdsByMessageQuery,
  getSidebarGrantedRoomsQuery,
  getSidebarGroupQuery,
  getSidebarNewsQuery,
  getSidebarRejectedRoomsQuery,
  getSidebarResourceQuery,
  getSidebarUngrouppedQuery,
  getTimedOffersQuery,
  getTwoFactorModeQuery,
  messageQuery,
  nanoMetricsQuery,
  nanoRequestQuery,
  paymentParseBackQuery,
  pingQuery,
  roomConfigQuery,
  roomMemberQuery,
  roomMessagesByIdQuery,
  roomMessagesQuery,
  roomPermissionsQuery,
} from '../../../../server-services/querys';
import { CacheLogicConditionForFetchType } from '../cache-logic-interface';
import { conditionFetchForDialogueMessageQuery } from './sub-conditions/dialogue-message-query-condition';
import { alwaysFetchCondition, neverFetchCondition } from './sub-conditions/primitive-conditions';
import { conditionFetchForRoomConfigQuery } from './sub-conditions/room-config-query-condition';
import { conditionFetchForRoomMessageQuery } from './sub-conditions/room-message-query-condition';
import { conditionFetchForRoomWithData } from './sub-conditions/room-with-data-query-condition';

export let customConditionForQueryFetch: CacheLogicConditionForFetchType = {
  /**
   * default condition rule
   */
  default: function (cache, cacheKey, request) {
    return cache.getCacheData(cacheKey) === undefined;
  },

  /**
   * true conditions, always fetch these query from the server
   */
  // it is normally called after cache erase, but to be sure
  [pingQuery.endpoint]: alwaysFetchCondition,
  [nanoRequestQuery.endpoint]: alwaysFetchCondition,
  [getTwoFactorModeQuery.endpoint]: alwaysFetchCondition,
  [getNanoSlotsQuery.endpoint]: alwaysFetchCondition,
  // we don't have live events for these, so have to refresh every time
  [roomPermissionsQuery.endpoint]: alwaysFetchCondition,
  [roomMemberQuery.endpoint]: alwaysFetchCondition,
  [roomConfigQuery.endpoint]: conditionFetchForRoomConfigQuery,
  [getNanoLicensesQuery.endpoint]: alwaysFetchCondition,
  [getPaymentBillingsQuery.endpoint]: alwaysFetchCondition,
  [paymentParseBackQuery.endpoint]: alwaysFetchCondition,
  [getRecurringOrdersQuery.endpoint]: alwaysFetchCondition,
  [getNanoBundleLicensesQuery.endpoint]: alwaysFetchCondition,
  [getLoginSessions.endpoint]: alwaysFetchCondition,
  [aggregateStats.endpoint]: alwaysFetchCondition,
  [nanoMetricsQuery.endpoint]: alwaysFetchCondition,
  [accountAvatarsQuery.endpoint]: alwaysFetchCondition,
  [getRoomDatasQuery.endpoint]: alwaysFetchCondition,
  // prechecked this with messageQuery in the chat.service/dialogue.service
  [roomMessagesByIdQuery.endpoint]: alwaysFetchCondition,
  [dialogueMessagesByIdQuery.endpoint]: alwaysFetchCondition,

  /**
   * false conditions, never fetch these query from the server, these should be resolved from cache
   */
  [getSidebarUngrouppedQuery.endpoint]: neverFetchCondition,
  [getAllAccountIdQuery.endpoint]: neverFetchCondition,
  [getSidebarGroupQuery.endpoint]: neverFetchCondition,
  [getSidebarResourceQuery.endpoint]: neverFetchCondition,
  [getSidebarGrantedRoomsQuery.endpoint]: neverFetchCondition,
  [getSidebarRejectedRoomsQuery.endpoint]: neverFetchCondition,
  [getSeenIdsByMessageQuery.endpoint]: neverFetchCondition,
  [messageQuery.endpoint]: neverFetchCondition,
  [getDialogueDataQuery.endpoint]: neverFetchCondition,
  [getTimedOffersQuery.endpoint]: neverFetchCondition,
  [getAllRoomQuery.endpoint]: neverFetchCondition,
  [getAllSidebarGroupQuery.endpoint]: neverFetchCondition,
  [getRoomQuery.endpoint]: neverFetchCondition,
  [getSidebarNewsQuery.endpoint]: neverFetchCondition,

  /**
   * custom conditions
   */
  [roomMessagesQuery.endpoint]: conditionFetchForRoomMessageQuery,
  [getRoomWithDataQuery.endpoint]: conditionFetchForRoomWithData,
  [dialogueMessagesQuery.endpoint]: conditionFetchForDialogueMessageQuery,
};
