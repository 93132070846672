import { AbstractSelfAccountKeyring } from '../../../keyring/account_base';
import { AbstractConfigDumpArgs, AbstractConfigLoadArgs } from '../../base';

export class AccountRootKeyConfigLoadArgs extends AbstractConfigLoadArgs {
  public self_kr;
  public key_version;

  constructor(cipher: Uint8Array, self_kr: AbstractSelfAccountKeyring, key_version: string) {
    super(cipher);
    this.self_kr = self_kr;
    this.key_version = key_version;
  }
}

export class AccountRootKeyConfigDumpArgs extends AbstractConfigDumpArgs {
  public self_kr;
  public key_version;

  constructor(self_kr: AbstractSelfAccountKeyring, key_version: string) {
    super();
    this.self_kr = self_kr;
    this.key_version = key_version;
  }
}
