import { Directive, ElementRef, Host, HostListener, Input } from '@angular/core';
import { showUserInfoObserver } from '../components/user-info-menu/user-info-menu.component';
import { AccountAvatarRecord } from '../server-services/query-records/account-records';

@Directive({
  selector: '[appShowUserInfo]',
})
export class ShowUserInfoDirective {
  @Input() accountAvatarRecord: AccountAvatarRecord;

  constructor(@Host() private elementRef: ElementRef) {}

  @HostListener('click')
  showUserInfo() {
    showUserInfoObserver.next({
      avatarInfo: this.accountAvatarRecord,
      originElementRef: this.elementRef,
    });
  }
}
