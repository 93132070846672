import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Subject, Subscription, mergeMap, of, retry, throwError } from 'rxjs';
import { CreateRoomDialogComponent } from 'src/app/shared/dialogs/create-room-dialog/create-room-dialog.component';
import { DeleteGroupDialogComponent } from 'src/app/shared/dialogs/delete-group-dialog/delete-group-dialog.component';
import { RenameGroupDialogComponent } from 'src/app/shared/dialogs/rename-group-dialog/rename-group-dialog.component';
import { PermissionService } from 'src/app/shared/server-services/permission.service';
import { ID } from 'src/app/shared/server-services/query-records/common-records';
import { RoomPermission } from 'src/app/shared/server-services/query-records/room-records';
import {
  ResourceType,
  SidebarGroup,
  SidebarResource,
} from 'src/app/shared/server-services/query-records/sidebar-records';
import {
  WorkspaceSubscriptionDialogueEventRecord,
  WorkspaceSubscriptionResourceGroupEventRecord,
  WorkspaceSubscriptionRoomEventRecord,
} from 'src/app/shared/server-services/query-records/workspace-records';
import { RoomService } from 'src/app/shared/server-services/room.service';
import { SidebarService } from 'src/app/shared/server-services/sidebar.service';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from 'src/app/shared/server-services/subscription-event';
import { SubscriptionService } from 'src/app/shared/server-services/subscription.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { OrderedList } from '../ordered-list';
import { enableGroupReorderObserver, enableRoomReorderObserver } from '../sidebar.component';

export type SidebarResourceDragDroppedParam = {
  resource: SidebarResource;
  groupId: ID;
  pos: number;
};
export let sidebarResourceDragDropped: Subject<SidebarResourceDragDroppedParam> =
  new Subject<SidebarResourceDragDroppedParam>();

@Component({
  selector: 'app-sidebar-group',
  templateUrl: './sidebar-group.component.html',
  styleUrls: ['./sidebar-group.component.scss'],
})
export class SidebarGroupComponent implements OnInit, OnDestroy, OnChanges {
  @Input() sidebarGroupData: SidebarGroup;

  public name: string;
  public error: boolean;
  public groupId: string;
  public resources: SidebarResource[];
  // resource data structure
  public orderedResources: OrderedList<SidebarResource>;
  // reference of the showed list
  public showResourceList: SidebarResource[] = [];

  private dropSubscriptionRef: Subscription;

  public isDraggedCursorOverThis = false;
  public currHeight = 0;

  public isGroupReordering: boolean = false;
  public isRoomReordering: boolean = false;

  @ViewChild('group', { read: ElementRef }) group: any;
  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;

  constructor(
    private subscriptionService: SubscriptionService,
    public dialog: MatDialog,
    private sidebarService: SidebarService,
    private dialogService: DialogService,
    private snackbarService: SnackBarService,
    private permissionService: PermissionService,
    private roomService: RoomService,
    private routerHandler: RouterHandler,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.RESOURCE_GROUP_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onEditResourceGroup
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.onCreateResource
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onEditResource
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.onDeleteResource
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.onCreateResource
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onEditResource
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onModifyDialogue
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.onDeleteResource
    );

    // this.dropSubscriptionRef = sidebarResourceDragDropped.subscribe((ev) => {
    //   if (ev.groupId == this.groupId) {
    //     let ordering = this.orderedResources.calcOrderingByPosition(ev.resource, ev.pos);
    //     this.sidebarService.editPlacement(ev.resource.id, this.groupId, ordering, ev.resource.type);
    //   }

    //   // TODO optimize ordering numbers
    // });

    enableGroupReorderObserver.subscribe((reordering: boolean) => {
      this.isGroupReordering = reordering;
      if (reordering) {
        if (this.expansionPanel.expanded) this.expansionPanel.expanded = false;
      } else {
        this.expansionPanel.expanded = !this.sidebarGroupData.closed;
      }
    });

    enableRoomReorderObserver.subscribe((reordering: boolean) => {
      this.isRoomReordering = reordering;
      if (reordering) {
        if (!this.expansionPanel.expanded) this.expansionPanel.expanded = true;
      } else {
        this.expansionPanel.expanded = !this.sidebarGroupData.closed;
      }
    });
  }

  public startGroupReorder(): void {
    enableGroupReorderObserver.next(true);
  }

  public startRoomReorder(): void {
    enableRoomReorderObserver.next(true);
  }

  roomDropped(event: CdkDragDrop<SidebarResource[]>) {
    const resource = event.previousContainer.data[event.previousIndex];
    const ordering = this.orderedResources.calcOrderingByPosition(resource, event.currentIndex);
    this.sidebarService.editPlacement(resource.id, this.groupId, ordering, resource.type);

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  /**
   * Note: This called on the original parent group, we have to pass the event to the
   * destionation group (or ungroupped group)
   */
  // public resourceDragEnded(ev: AppDragMoveEvent, resource: SidebarResource) {
  //   if (ev.containerId && ev.containerId.startsWith('group-')) {
  //     let destGroupId = ev.containerId.replace('group-', '');
  //     if (destGroupId == '') {
  //       destGroupId = UNGROUPPED_GROUP_ID;
  //     }

  //     sidebarResourceDragDropped.next({
  //       groupId: destGroupId,
  //       pos: ev.pos,
  //       resource,
  //     });
  //   } else {
  //     this.dialogService.openAlertDialog(
  //       marker('Error'),
  //       marker('Error during the operation'),
  //       "Error: container id should start with 'group-', found " + ev.containerId
  //     );
  //   }
  // }

  private refreshState(): Promise<void> {
    return this.sidebarService.getGroup(this.sidebarGroupData.id).then((group) => {
      this.groupId = group.id;
      this.resources = group.resources;

      if (group.decryptionError) {
        this.error = true;
        this.name = group.decryptionErrorMessage;
      } else {
        this.name = group.name;
      }
    });
  }

  ngOnInit(): void {
    this.refreshState().then(() => {
      this.orderedResources = new OrderedList<SidebarResource>(this.resources);
      this.showResourceList = this.orderedResources.getInorderedDataRef();

      // this.updateContainerHeight();
      // this.updateContainerHeight();

      // this.appDragDropService.subscribeDragMove(this.onDragAutoOpenMove);
      // this.appDragDropService.subscribeDragMove(this.onDragAutoOpenMove);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allClose !== undefined) {
      // this.updateContainerHeight();
      // this.updateContainerHeight();
    }
  }

  // public onDragAutoOpenMove = (pos, comp) => {
  //   // handler, trigger when an element is dragged
  //   // auto open the group, when the mouse moved over this component
  //   // close the submenu, you can drag the component and open the submenu in the same time on mobile, when you use longpress on the title
  //   // this.trigger.closeMenu();
  //   this.updateContainerHeight();

  //   if (!this.sidebarGroupData.closed) return; // we already opened

  //   if (this.checkCursorIsOverThis(pos)) {
  //     this.isDraggedCursorOverThis = true;

  //     // if we are still waiting over the element after x sec, lets open this
  //     setTimeout(() => {
  //       if (this.isDraggedCursorOverThis) {
  //         this.sidebarGroupData.closed = false;
  //         this.isDraggedCursorOverThis = false;
  //         this.sidebarService.editGroupClosed(this.groupId, this.sidebarGroupData.closed); // emit on server side
  //       }
  //     }, 500);
  //   } else {
  //     this.isDraggedCursorOverThis = false;
  //   }
  // };

  // private checkCursorIsOverThis(pos) {
  //   let bond = this.el.nativeElement.getBoundingClientRect();
  //   return (
  //     pos.x >= bond.left &&
  //     pos.y > bond.top &&
  //     pos.x <= bond.left + bond.width &&
  //     pos.y <= bond.top + bond.height
  //   );
  // }

  // private updateContainerHeight() {
  //   return;
  //   // setTimeout(() => {
  //   //   // call this async, so it can be called immediatly after data structure change
  //   //   if (!this.sidebarGroupData.closed && !this.allClose) {
  //   //     this.currHeight =
  //   //       48 +
  //   //       Math.max(
  //   //         24,
  //   //         this.group.nativeElement.querySelector('.group-body').getBoundingClientRect().height
  //   //       );
  //   //   } else {
  //   //     this.currHeight = 24;
  //   //   }

  //   //   this.group.nativeElement.style.height = this.currHeight + 'px';
  //   // }, 1);
  // }

  private onEditResourceGroup = (data: WorkspaceSubscriptionResourceGroupEventRecord) => {
    if (data.id == this.sidebarGroupData.id) {
      this.refreshState();
      // this.updateContainerHeight();
      // this.updateContainerHeight();
    }
  };

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.RESOURCE_GROUP_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onEditResourceGroup
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.onCreateResource
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onEditResource
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.onDeleteResource
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.onCreateResource
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onEditResource
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.onModifyDialogue
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.onDeleteResource
    );

    // this.dropSubscriptionRef.unsubscribe();
    // this.dropSubscriptionRef.unsubscribe();
  }

  public toggle() {
    if (this.isGroupReordering || this.isRoomReordering) return;
    this.sidebarGroupData.closed = !this.sidebarGroupData.closed;
    // this.updateContainerHeight();
    this.sidebarService.editGroupClosed(this.groupId, this.sidebarGroupData.closed);
  }

  public stopPropagation(event: any) {
    event.stopPropagation();
  }

  public editName() {
    const dialogRef = this.dialog.open(RenameGroupDialogComponent, {
      data: { name: this.name },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sidebarService
          .editGroupName(this.groupId, result)
          .then((res) => {
            // wait for async set
          })
          .catch((err) => {
            console.log('rename error', err);
            if (err.message === 'Message error: 33')
              this.snackbarService.showSnackbar(marker('Group name too long!'));
          });
      }
    });
  }

  public delete() {
    const dialogRef = this.dialog.open(DeleteGroupDialogComponent, {
      data: { name: this.name },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //console.log('client>server, delete group: id', id);
        return this.sidebarService.deleteGroup(this.sidebarGroupData.id).then((res) => {});
      }
    });
  }

  private onModifyDialogue = (event: WorkspaceSubscriptionDialogueEventRecord) => {
    if (event.hidden === true) {
      // remove it if it is in here
      this.orderedResources.remove(event.id);
      // this.updateContainerHeight();
      // this.updateContainerHeight();
    } else if (event.hidden === false) {
      this.sidebarService.getResource(event.id).then((res) => {
        if (res.groupId === this.groupId) {
          this.orderedResources.pushByOrdering(res);
          // this.updateContainerHeight();
          // this.updateContainerHeight();
        }
      });
    }
  };

  private onCreateResource = (
    event: WorkspaceSubscriptionRoomEventRecord | WorkspaceSubscriptionDialogueEventRecord
  ) => {
    if (event.groupId == this.groupId) {
      this.sidebarService.getResource(event.id).then((res) => {
        this.orderedResources.pushByOrdering(res);
        // this.updateContainerHeight();
        // this.updateContainerHeight();
      });
    }
  };

  private onEditResource = (
    event: WorkspaceSubscriptionRoomEventRecord | WorkspaceSubscriptionDialogueEventRecord
  ) => {
    if (event.ordering !== undefined) {
      // null means ungrouped
      this.sidebarService.getResource(event.id).then((res) => {
        let includes = this.orderedResources.includes(event.id);

        if (includes) {
          if (this.groupId == event.groupId) {
            // the references ordering attr changed via cache service
            // but it stays in the same group
            this.orderedResources.reorder();
            if (OrderedList.isPositionNeedToOptimize(event.ordering)) {
              this.optimizeOrdering();
            }
          } else {
            // group changed
            this.orderedResources.remove(event.id);
            // this.updateContainerHeight();
            // this.updateContainerHeight();
          }
        } else {
          if (this.groupId == event.groupId) {
            // got a new element
            this.orderedResources.pushByOrdering(res);
            if (OrderedList.isPositionNeedToOptimize(event.ordering)) {
              this.optimizeOrdering();
            }
            // this.updateContainerHeight();
            // this.updateContainerHeight();
          } else {
            // skip
          }
        }
      });
    }
  };

  private optimizeOrdering() {
    let optimize = this.orderedResources.getOptimizedOrdering();

    console.log('optimize resources', optimize);
    optimize.forEach((el) => {
      this.sidebarService.editPlacement(el.element.id, this.groupId, el.ordering, el.element.type);
    });
  }

  private onDeleteResource = (
    event: WorkspaceSubscriptionRoomEventRecord | WorkspaceSubscriptionDialogueEventRecord
  ) => {
    this.orderedResources.remove(event.id);
    // this.updateContainerHeight();
  };

  scrollToRoom(roomId: string): void {
    // we can not be sure that the searched room HTML element exists yet, so we retry 5 times with .5 second delays
    // After that we stop trying to scroll to the room

    var counter = 0;
    of('')
      .pipe(
        mergeMap(() => {
          let roomElement = this.document.getElementById('room-' + roomId);
          counter++;
          return roomElement === null && counter < 5
            ? throwError(() => 'Room element not found!')
            : of(roomElement);
        }),
        retry({ count: 5, delay: 500 })
      )
      .subscribe((roomElement: HTMLElement) => {
        if (roomElement) {
          roomElement.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
          // (<HTMLElement>this.expScrollWindow.nativeElement).scrollTop = roomElement.offsetTop - 100;
        }
      });
  }

  openNewRoomDialog(): void {
    const dialogRef = this.dialog.open(CreateRoomDialogComponent);

    dialogRef
      .afterClosed()
      .subscribe(
        (result: {
          roomName: string;
          roomAvatarBuffer: ArrayBuffer;
          inviteAvatarIdList: Array<string>;
        }) => {
          if (!result) return;

          this.roomService
            .newRoom(result.roomName, result.roomAvatarBuffer)
            .then((newRoomId) => {
              this.sidebarService.getSidebarUngroupped().then((newUngroupped) => {
                const foundRoom = newUngroupped.find((room) => room.id === newRoomId);

                if (foundRoom) {
                  let ordering = 0;
                  if (this.resources.length) {
                    ordering = this.orderedResources.calcOrderingByPosition(foundRoom, 0);
                  }
                  this.sidebarService
                    .editPlacement(foundRoom.id, this.groupId, ordering, ResourceType.ROOM)
                    .then((edited) => {
                      this.snackbarService.showSnackbar(marker('Room created successfully!'));
                      if (this.sidebarGroupData.closed) {
                        this.toggle();
                      }

                      setTimeout(() => {
                        this.routerHandler.navigate([`room/${newRoomId}/chat/room`]);
                        this.scrollToRoom(newRoomId);
                      }, 500);
                    });
                }
              });

              return newRoomId;
            })
            .then((newRoomId: string) => {
              // add users if there are any given
              if (result.inviteAvatarIdList.length === 0) return;

              let modifiedMemberPermissionDict: { [userId: string]: RoomPermission } = {};

              result.inviteAvatarIdList.forEach((userId) => {
                modifiedMemberPermissionDict[userId] =
                  this.permissionService.getDefaultRoomPermissions();
              });

              this.permissionService.saveRoomPermissions(
                newRoomId,
                modifiedMemberPermissionDict,
                false, // no nano is connected to new room
                [] // there are no members in new room, and we can not add ourselves
              );
            })
            .catch((error) => {
              if (error.message === 'Own room limit is reached (200)') {
                this.dialogService.openAlertDialog(
                  marker('Room limit'),
                  marker('Own room limit is reached.')
                );
              } else {
                this.dialogService.openAlertDialog(
                  marker('Create room'),
                  marker('Could not create room.')
                );
              }
              console.error('Room creation error', error);
            });
        }
      );
  }
}
