<!-- <div id="etalon-info-header" (click)="showBody = !showBody">
  <img src="assets/img/ui/Etalon_logo_small.png" />
  <span translate>Etalonnal ingyenesen prémium szolgáltatásokhoz juthat.</span>
  <mat-icon>info</mat-icon>
</div>
<div id="etalon-info-body" *ngIf="showBody">
  <div translate>Ha összeköti Crypto-pénztárcáját a Nano fiókjával, jogosultá válik egy le nem járó Nano <a routerLink="/license-view">licenszre</a>.</div>
  <div translate>Mit kell ehhez tennie?</div>
  <ul>
    <li translate>Készítsen egy Nano fiókot.</li>
    <li translate>Csatlakoztassa Crypto-pénztárcáját a fiókjához, mely tartalmaz legalább 1 <a href="https://etalon.cash/" target="_blank">Etalon</a> tokent.</li>
    <li translate>Élvezze a Nano prémium szolgáltatásait egy ingyenes Nano licensszel.</li>
  </ul>
  <div translate>Az így szerzett licensz mindaddig érvényes marad, míg Crypto-pénztárcája tartalmaz legalább 1 Etalon tokent.</div>
</div> -->
<div id="etalon-info-header" (click)="showBody = !showBody">
  <img src="assets/img/ui/Etalon_logo_small.png" />
  <span translate>Etalon gives you access to premium services for free.</span>
  <mat-icon>info</mat-icon>
</div>
<div id="etalon-info-body" *ngIf="showBody">
  <div translate>When you link your Crypto Wallet to your Nano account, you become eligible for a non-expiring Nano <a translate routerLink="/license-view">license</a>.</div>
  <div translate>What do you need to do?</div>
  <ul>
    <li translate>Create a Nano account.</li>
    <li translate>Connect your Crypto Wallet to your account that contains at least 1 <a href="https://etalon.cash/" target="_blank">Etalon</a> token.</li>
    <li translate>Not sure how to set up Etalon? See the official <a translate href="https://etalon.cash/guide">Etalon setup guide</a>.</li>
    <li translate>Enjoy Nano's premium services with a free Nano license.</li>
  </ul>
  <div translate>The acquired license remains valid as long as your Crypto Wallet contains at least 1 Etalon token.</div>
</div>
