import { MessageRecord } from 'src/app/shared/server-services/query-records/room-records';
import {
  getAllAccountIdQuery,
  getAllRoomQuery,
  getAllSidebarGroupQuery,
  getContactListIdsQuery,
  getSidebarUngrouppedQuery,
  getTimedOffersQuery,
} from '../../../server-services/querys';
import { CacheInterface, CacheResetHandler } from './cache-logic-interface';

export let customCacheResetLogic: CacheResetHandler = (cache: CacheInterface) => {
  return {
    [<string>cache.getCacheLogic().makeCacheDataPath({ query: getAllRoomQuery, variables: {} })]:
      [],
    [<string>(
      cache.getCacheLogic().makeCacheDataPath({ query: getAllAccountIdQuery, variables: {} })
    )]: [],
    [<string>(
      cache.getCacheLogic().makeCacheDataPath({ query: getContactListIdsQuery, variables: {} })
    )]: [],
    [<string>(
      cache.getCacheLogic().makeCacheDataPath({ query: getTimedOffersQuery, variables: {} })
    )]: [],
    [<string>(
      cache.getCacheLogic().makeCacheDataPath({ query: getAllSidebarGroupQuery, variables: {} })
    )]: [],
    [<string>(
      cache.getCacheLogic().makeCacheDataPath({ query: getSidebarUngrouppedQuery, variables: {} })
    )]: [],
    seen: {},
    messages: {},
  };
};

export type SeenCacheRecord = {
  messages: { [messageId: string]: string[] }; // messageId -> userId[]
  users: { [userId: string]: string }; // userId -> messageId
};

export type MessagesCacheRecord = { [messageId: string]: MessageRecord };
