import {
  chatChangesSubscription,
  workspaceSubscription,
} from '../../../../../server-services/querys';
import { ResponseMergerType } from '../../cache-logic-interface';
import { customWorkspaceSubscriptionMerger } from './sub-merger/workspace-subscription-merger';

export let customSubscriptionMerger: ResponseMergerType = {
  default: function (cache, request, response) {},
  [workspaceSubscription.endpoint]: customWorkspaceSubscriptionMerger,
  [chatChangesSubscription.endpoint]: customWorkspaceSubscriptionMerger, // for anonym and non-member user
};
