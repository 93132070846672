import { CacheLogic } from './cache-logic-interface';
import { customCacheKeyGeneratorLogic } from './cache-key-generator';
import { customConditionForQueryFetch } from './condition-for-query-fetch/custom-condition-for-query-fetch';
import { customResponseMerger } from './response-merger/custom-response-merge';
import { customCacheResponseManipulator } from './cache-response-manipulator/custom-cache-response-manipulator';
import { customCacheResetLogic } from './custom-cache-reset-logic';

export let cacheCustomLogic: CacheLogic = {
  makeCacheDataPath: customCacheKeyGeneratorLogic,
  conditionForFetch: customConditionForQueryFetch,
  requestResponseMerger: customResponseMerger,
  requestResponseManipulator: customCacheResponseManipulator,
  resetCache: customCacheResetLogic,
};
