import { DOCUMENT, formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  Subject,
  buffer,
  debounceTime,
  finalize,
  from,
  map,
  mergeMap,
  of,
  repeat,
  takeUntil,
} from 'rxjs';
import { AppStorage } from 'src/app/shared/app-storage';
import { ChatLayoutSheetAction } from 'src/app/shared/consts/enums';
import {
  EditorSupportedFileExtensions,
  NanoFeature,
  isNanoFeatureSupported,
} from 'src/app/shared/drive-version';
import { AccountService } from 'src/app/shared/server-services/account.service';
import {
  ChatService,
  SubscriptionChatEventType,
} from 'src/app/shared/server-services/chat.service';
import { DialogueService } from 'src/app/shared/server-services/dialogue.service';
import { PermissionService } from 'src/app/shared/server-services/permission.service';
import {
  AccountAvatarRecord,
  MeRecord,
} from 'src/app/shared/server-services/query-records/account-records';
import { ID } from 'src/app/shared/server-services/query-records/common-records';
import {
  LoadMessageDirection,
  MessageRecord,
  MessageRecordPlaceholder,
  MessageRecordType,
  ReactionEnum,
  RoomPermission,
  reactionIndexSrcMap,
  reactionList,
} from 'src/app/shared/server-services/query-records/room-records';
import { ResourceType } from 'src/app/shared/server-services/query-records/sidebar-records';
import {
  WorkspaceSubscriptionDialogueEventRecord,
  WorkspaceSubscriptionDialogueTypingEventRecord,
  WorkspaceSubscriptionRoomAccountPermissionEventRecord,
  WorkspaceSubscriptionRoomEventRecord,
  WorkspaceSubscriptionRoomMessageEventRecord,
  WorkspaceSubscriptionRoomTypingEventRecord,
} from 'src/app/shared/server-services/query-records/workspace-records';
import { ResourceBase, UnsentMessage } from 'src/app/shared/server-services/resource-base';
import { RoomService } from 'src/app/shared/server-services/room.service';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from 'src/app/shared/server-services/subscription-event';
import { SubscriptionService } from 'src/app/shared/server-services/subscription.service';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NativeAppService } from 'src/app/shared/services/native-app.service';
import {
  PageInfo,
  PageTabService,
  PageTypes,
  SubPageTypes,
} from 'src/app/shared/services/page-tab.service';
import { RouterHandler, RouterResponse } from 'src/app/shared/services/router-handler.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { CopyChatMessagesSnackbarComponent } from 'src/app/shared/snackbars/copy-chat-messages-snackbar/copy-chat-messages-snackbar.component';
import { forceMobileView } from 'src/main';
import { SafeRadix32 } from '../../../shared/safe-radix32';
import { ChatMessageGroupingPolicy } from '../../settings/settings.component';
import { ChatData, ChatMessagePermission } from './chat-data';
import { ChatLayoutBottomSheetMenuComponent } from './chat-layout-bottom-sheet-menu/chat-layout-bottom-sheet-menu.component';
import { NativeEditorComponent } from './chat-message-editor/native-editor/native-editor.component';
import { SlateEditorComponent } from './chat-message-editor/slate-editor/slate-editor.component';
import {
  ChatMessageComponent,
  ChatMessageContextMenuData,
} from './chat-message/chat-message.component';
import { AttachedFileUrl } from './chat-message/markdown-view/markdown-file-preview/markdown-file-preview.component';
import { focusChatInput } from './focus-chat-input-observer';
import { InputSubmitEvent } from './input-submit-event';

type ReplyObject = {
  messageId: ID; // id of the message
  replyId: ID; // id of the message we replied to
  msgRef?: ChatData; // this.chatMessage chatData object ref for easing the update
};

/** @todo make these configurable in settings */
/** Above this amount the chatMessages array will be sliced */
const MESSAGE_LIMIT_COUNT = 90;
/** Amount of messages will be left after slicing the chatMessages array */
const MESSAGE_SLICED_COUNT = 60;

enum ContextMenuAction {
  EDIT = 'edit',
  REPLY = 'reply',
  COPY = 'copy',
  SELECT = 'select',
  DELETE = 'delete',
  REACT = 'react',
  PIN = 'pin',
  COPY_FILE_URL = 'copy_file_url',
  OPEN_FILE_IN_EDITOR = 'open_file_in_editor',
}

@Component({
  selector: 'app-chat-layout',
  templateUrl: './chat-layout.component.html',
  styleUrls: ['./chat-layout.component.scss'],
})
export class ChatLayoutComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  public RESOURCE_TYPE_ROOM = ResourceType.ROOM;
  public MessageRecordType = MessageRecordType;

  @Input() data: PageInfo;
  @Input() active: boolean;
  @Output() pageInfoChange: EventEmitter<PageInfo> = new EventEmitter<PageInfo>();

  @ViewChild('resourceContainer', { read: ElementRef })
  resourceContainerRef: ElementRef;
  @ViewChild(MatMenuTrigger) context_trigger: MatMenuTrigger;
  public contextMenuPosition = { x: '0px', y: '0px' };
  public isExpanded = false;
  public reactions = reactionList;
  public topEmojies = [
    { index: 1, emoji: reactionIndexSrcMap[1] }, // ❤️
    { index: 40, emoji: reactionIndexSrcMap[40] }, // 😆
    { index: 42, emoji: reactionIndexSrcMap[42] }, // 😮
    { index: 18, emoji: reactionIndexSrcMap[18] }, // 😢
    { index: 41, emoji: reactionIndexSrcMap[41] }, // 😠
    { index: 6, emoji: reactionIndexSrcMap[6] }, // 👍
  ];
  public contextTargetMessage: ChatMessageContextMenuData = null;
  public contextTargetPermissions: ChatMessagePermission = null;
  public MAXIMUM_EMOJI_KIND_NEXT_TO_MESSAGE = 3;
  public MESSAGE_ID_PREFIX = 'message_';

  // at 0, is the newest
  public chatMessages: ChatData[] = [];
  public myPermissions: RoomPermission;

  public ContextMenuAction = ContextMenuAction;

  private isCompactDisplay = AppStorage.getItem('messageDisplay') === 'compact';
  private chatMessageComparePolicy = (d1, d2) => false;

  public resourceId;
  public goToMessage: ID;
  public isMessagesLoading = false;
  public isMessageSending: boolean = false;
  public isRoomError: boolean = false;
  public isDocumentEditorSupported: boolean = false;
  public type: ResourceType = null;
  private service: ResourceBase = null;
  private latestSeenMessage: MessageRecord;
  public chatEndReached: boolean = false;
  public isReadOnly: boolean = false;
  public resourceName: string = '';
  private isBottomVisible: boolean = true;
  private me: MeRecord;

  private isSelecting: boolean = false;
  private copySnackbarShowing: boolean = false;
  private selectedChatMessages: HTMLElement[] = [];
  private chatTag: string = 'APP-CHAT-MESSAGE';
  private copySnackBarRef: MatSnackBarRef<CopyChatMessagesSnackbarComponent>;
  private canEnterEditOnUpArrow: boolean = true;
  private isChatInputFocused: boolean = true;
  private isTypingTimer = null;
  private scrollTargetMessageId: string = null;
  private scrollFixMessageId: string = null;
  public isOnDesktop: boolean = false;

  public unsentMessages: UnsentMessage[] = [];

  public isOnApp: boolean = false;

  public editedMessage: null | ChatData = null;

  public repliedMessage: null | ChatData = null;

  // track the message, need to save it if the user step into edit mode
  // and load it after it goes back to the normal mode
  public lastUnsentMessage: string = '';

  public typingUsers: {
    timeoutId: NodeJS.Timeout;
    user: AccountAvatarRecord;
  }[] = [];

  private userSeenState: { [userId: string]: string } = {};
  private visibleMessages: ChatData[] = [];
  private unhandledVoids: MessageRecordPlaceholder[] = [];
  private isScrolling: boolean = false;
  private scrollDirection: number = 0;
  private isVoidLoading: boolean = false;
  public showStartToChatArrow: boolean = false;
  private scrollingEventUnlistenFunction: any;
  private scrollingEndedTimeout: any;

  private routeChanged$ = new Subject<void>();
  private seenMessageEvent$ = new Subject<ID>();

  @ViewChild('chatScroll', { read: ElementRef }) chatScroll: ElementRef;
  @ViewChildren('messageContainers') messageContainers: QueryList<ChatMessageComponent>;

  /**
   * If you are at the bottom of the scrollbar, the incoming messages will scroll you to the bottom.
   * But if you out of this scope, we wont scroll you, so you can search and read earlier messages.
   */
  private autoScrollLimit: number = 200;

  @ViewChild(SlateEditorComponent)
  slateEditorComponent: SlateEditorComponent;
  @ViewChild(NativeEditorComponent)
  nativeEditorComponent: NativeEditorComponent;
  @ViewChildren(ChatMessageComponent)
  chatMessagesRef: QueryList<ChatMessageComponent>;

  public runSeenAnimation: boolean = false;

  private popStateCallback = (event) => {
    if (this.editedMessage) {
      this.restoreChatMessage();
      return;
    }
    if (this.repliedMessage) {
      this.closeReplyBar();
      return;
    }
    //  else { // creates a weird history.back loop
    //   history.back();
    // }
  };

  public setEditedMessage(msg: ChatData) {
    if (!this.editedMessage) {
      window.history.pushState({ editor: true }, '');
    }
    this.chatService.saveMessageInStorage(
      this.resourceId,
      this.lastUnsentMessage,
      this.repliedMessage?.id
    );
    this.closeReplyBar();
    this.editedMessage = msg;
    focusChatInput.next();
  }

  public setRepliedMessage(msg: ChatData) {
    if (!this.repliedMessage) {
      window.history.pushState({ editor: true }, '');
    }
    this.repliedMessage = msg;
    this.chatService.saveMessageInStorage(
      this.resourceId,
      this.lastUnsentMessage,
      this.repliedMessage?.id
    );
    focusChatInput.next();
  }

  public restoreChatMessage() {
    this.chatService.loadMessageFromStorage(this.resourceId).then((res) => {
      this.lastUnsentMessage = res.message;
      this.editedMessage = null;

      if (res.replyTo) {
        this.service.getOneMessageById(this.resourceId, res.replyTo).then((msg) => {
          if (msg) {
            this.repliedMessage = this.convertServerRecordToMessage(msg);
          }
        });
      }
    });
  }

  public closeReplyBar() {
    if (this.repliedMessage) {
      this.repliedMessage = null;
    }
  }

  public editUnsentMessage(msg: UnsentMessage) {
    this.lastUnsentMessage = msg.content;
    let content = msg.content;
    msg.attachments.forEach((att) => {
      content += '\n' + att.location.url;
    });

    this.lastUnsentMessage = content;

    msg.remove();
  }

  onRouterChange = (route: RouterResponse) => {
    this.repliedMessage = null;
    this.editedMessage = null;

    this.routeChanged$.next();

    if (this.nativeAppService.isOnApp()) {
      this.data = null; // enforce that new data is created from the url
      this.initChat();
    }
  };

  public initChat = () => {
    if (!this.data) {
      let route = this.routerHandler.getRoute();
      this.data = {
        fragment: route.fragment,
        id: route.params.id,
        page: route.params.type == 'private' ? PageTypes.PRIVATE_CHAT : PageTypes.ROOM,
        subpage: SubPageTypes.CHAT,
      };
    }

    // will resolve the promise at the end
    let saveMessagePromise = () => Promise.resolve();
    if (
      this.myPermissions?.isMember &&
      (this.lastUnsentMessage?.length > 0 || this.lastUnsentMessage === '')
    ) {
      const params = {
        resourceId: this.resourceId,
        lastUnsentMessage: this.lastUnsentMessage,
        repliedMessageId: this.repliedMessage?.id,
      };
      saveMessagePromise = () =>
        this.chatService.saveMessageInStorage(
          params.resourceId,
          params.lastUnsentMessage,
          params.repliedMessageId
        );
    }

    this.chatService.unsubscribeAllChatChanges(this.resourceId);

    const prevId = this.resourceId;
    this.resourceId = this.data.id;

    this.goToMessage = this.data.fragment?.goto;

    if (prevId === this.data.id) {
      // saveMessage should happen before restoreChat
      saveMessagePromise().then(() => {
        this.restoreChatMessage();
      });
    } else {
      // restore chat can happen asynchronously
      saveMessagePromise();
      this.restoreChatMessage();
    }

    this.runSeenAnimation = false;

    this.accountService.getMe().then((me) => {
      this.me = me;
    });

    this.isMessagesLoading = true;
    this.chatEndReached = false;
    this.typingUsers = [];

    // bugfix on chat not scrolled to the bottom when switching rooms
    if (
      this.chatScroll?.nativeElement && // at the first load this is still undefined
      this.chatScroll.nativeElement.scrollTop < 0 // if its not on bottom
    ) {
      this.chatScroll.nativeElement.scrollTo({ top: 0, left: 0 });
    }

    this.copySnackBarRef?.dismiss();

    if (this.data.page == PageTypes.PRIVATE_CHAT) {
      this.type = ResourceType.DIALOGUE;
      this.service = this.dialogueService;
    } else {
      this.type = ResourceType.ROOM;
      this.service = this.chatService;
    }

    this.checkDriveStatus();

    this.chatMessages = [];

    this.unsentMessages = this.service.getUnsentMessages(this.resourceId);

    this.service
      .getChatPermissions(this.resourceId)
      .then((perm) => {
        this.myPermissions = perm;

        this.service.getData(this.resourceId).then((data) => {
          //|| (data['data'] && data['data']['decryptionError'])
          if (data['inaccessible']) {
            this.isRoomError = true;
            this.isMessagesLoading = false;
            return;
          }

          this.isRoomError = false;

          this.service.getDetail(this.resourceId).then((roomDetail) => {
            if (this.type == ResourceType.ROOM) this.resourceName = data['data'].name;
            else {
              this.accountService.getAccount(data['id']).then((acc) => {
                if (acc.deleted == true) {
                  this.resourceName = this.translateService.instant(marker('Deleted user'));
                } else {
                  this.resourceName = acc.avatarName;
                }
              });
            }

            // load these messages and subscribe the changes
            let cachedResourceId = this.resourceId;

            // jump to the last seen message. Note: message may have been deleted, lastSeenMessage may be null (first time load)

            this.loadRelevantInitMessages(data).then((messages) => {
              if (
                (<MessageRecordPlaceholder>(<unknown>messages[messages.length - 1])).type ===
                MessageRecordType.END
              )
                this.chatEndReached = true;

              // if you switch fast between chats you can get an old chat response on an other chat window
              if (cachedResourceId != this.resourceId) return;

              // let messagesAreLoading = false;
              this.accountService.getMe().then((me) => {
                if (messages.length > 0 && messages[0] !== me.id) {
                  // reset, so we wont duplicate newly incoming messages before called the lastMessages,
                  //which is contained by the messages array
                  this.chatMessages = [];
                  this.loadMessages(messages).then(() => {
                    this.isMessagesLoading = false;
                    setTimeout(() => {
                      // do not allow animation at the start of the chat for a little bit
                      if (this.resourceId === cachedResourceId) {
                        this.runSeenAnimation = true;
                      }
                    }, 100);
                  });
                }
              });

              // check if there is more message before the init messages
              // it can solve a scroller issue
              // we have to load both payload in one "loadMessages" otherwise
              // iphone safari renderer wont show the content of the reversed flexbox
              // floating container. It is a bug in the safari renderer core.
              // let olderMessagesPromise: Promise<MessageRecord[]> = Promise.resolve([]);
              // let realChatMessages = messages.filter((m) => m.id);
              // if (messages.length != 0) {
              //   olderMessagesPromise = this.service.getMoreMessages(
              //     this.resourceId,
              //     realChatMessages[realChatMessages.length - 1].id,
              //     LoadMessageDirection.AFTER,
              //     40
              //   );
              // }

              // olderMessagesPromise.then((olderMessages) => {
              //   // if you switch fast between chats you can get an old chat response on an other chat window
              //   if (cachedResourceId != this.resourceId) return;

              //   console.log('Load messages MORE');
              //   this.loadMessages(olderMessages).then(() => {
              //     this.isMessagesLoading = false;

              //     if (this.isBottomVisible) {
              //       this.sendSeen();
              //     }
              //   });
              // });
            });

            // we dont need the callback reference, it will be removed after ngOnDestroy
            if (!this.myPermissions.isMember && this.type == ResourceType.ROOM) {
              this.subscribeToAllChatServiceChanges();
            }
          });
        });
      })
      .catch((err) => {
        console.error('Error requesting permissions for room.', err);
        this.isMessagesLoading = false;
        if (err.myPermissionInvalidArgument === true) this.isRoomError = true;
      });
  };

  private loadRelevantInitMessages(data: Object): Promise<MessageRecord[]> {
    let seenMessageId = data['seenMessageId'];
    let topMessageId = data['topMessageId'];
    let loadRelevantMessagePromise: Promise<MessageRecord[]>;

    if (seenMessageId && topMessageId && seenMessageId !== topMessageId) {
      // load chat from the point of the last seen message
      loadRelevantMessagePromise = new Promise((resolve, reject) => {
        this.service.getOneMessageById(this.resourceId, seenMessageId).then((res) => {
          this.latestSeenMessage = res;
          this.service
            .getMoreMessages(this.resourceId, seenMessageId, LoadMessageDirection.AFTER)
            .then((afterMessages) => {
              this.service
                .getMoreMessages(this.resourceId, seenMessageId, LoadMessageDirection.BEFORE)
                .then((beforeMessage) => {
                  resolve(afterMessages.concat(beforeMessage));

                  if (undefined === this.latestSeenMessage)
                    //latestSeenMessage was deleted, set the latestSeen to the previous closest message
                    this.latestSeenMessage = afterMessages[0];

                  this.scrollTargetMessageId = this.latestSeenMessage.id;
                });
            });
        });
      });
    } else {
      // load chat from latest message
      loadRelevantMessagePromise = this.service
        .getLastMessages(this.resourceId)
        .then((messages) => {
          // if second object has a "type" property, then it is another "chat end" placeholder, which means this chat has no messages
          if (!('type' in messages[1])) {
            this.latestSeenMessage = messages[1];
            if (seenMessageId === null)
              // this is the first time the user has loaded in this chat
              this.seenMessageEvent$.next(this.latestSeenMessage.id);
          }

          return messages;
        });
    }

    return loadRelevantMessagePromise;
  }

  private subscribeToAllChatServiceChanges(): void {
    this.chatService.subscribeChatChanges(
      this.resourceId,
      SubscriptionChatEventType.CREATE,
      this.handleMessageCreate
    );
    this.chatService.subscribeChatChanges(
      this.resourceId,
      SubscriptionChatEventType.MODIFY,
      this.handleMessageModify
    );
    this.chatService.subscribeChatChanges(
      this.resourceId,
      SubscriptionChatEventType.DELETE,
      this.handleMessageDelete
    );
  }

  private handleRoomMessageCreate = (msg) => {
    this.handleMessageCreate(msg);
  };

  private handleDialogueMessageCreate = (msg) => {
    this.handleMessageCreate(msg);
  };

  private handleMessageCreate = (msg) => {
    this.unsentMessages = this.service.getUnsentMessages(this.resourceId);

    if (msg.id == this.resourceId) {
      this.service.getOneMessageById(this.resourceId, msg.messageId).then((data) => {
        this.loadMessage(data).then(() => {
          // scroll to end if we're close to it, so the whole new message will be visible
          if (this.chatScroll.nativeElement.scrollTop * -1 <= this.autoScrollLimit) {
            this.chatScroll.nativeElement.scrollTo({ top: 0, left: 0 });
          }
          // reverse-flexbox safari bug, its scroller does not work properly
          this.triggerIOSGUIRefresh();

          if (this.isBottomVisible) {
            // limit messages from being drawn to the page
            if (this.chatMessages.length > MESSAGE_LIMIT_COUNT) {
              this.chatMessages = this.chatMessages.slice(0, MESSAGE_SLICED_COUNT);
            }
          } else if (
            this.me.id === data.posterId &&
            this.chatScroll.nativeElement.scrollTop * -1 > this.autoScrollLimit
          ) {
            // scroll to the bottom, if needed
            setTimeout(() => {
              (<HTMLElement>this.chatScroll.nativeElement).scrollTop = 0;
            }, 10);
          }

          if (data.replyTo) {
            this.setRepliesByReplyList([{ messageId: data.id, replyId: data.replyTo }]);
          }

          let msgIndex = this.chatMessages.findIndex((cm) => cm.id === data.id);
          if (this.chatMessages[msgIndex + 1].id) {
            this.insertDateSeparatorsBetween(data.id, this.chatMessages[msgIndex + 1].id);
          }
        });
      });
    }

    if (this.me.id != msg.posterId) this.removeIsTyping(msg.posterId, true);
  };

  private triggerIOSGUIRefresh() {
    /**
     * In some position (rel+abs) the reverse flexbox ui renderer in safari is buggy
     * we have to trigger a view redraw with some viewport matrix or layout change (css hacks would also work)
     */
    if (
      this.deviceDetectorService.browser == 'Safari' ||
      (this.isOnApp && this.nativeAppService.isOnApp() && this.nativeAppService.isOnIOS())
    ) {
      this.chatScroll.nativeElement.scrollBy(0, -1);
      this.chatScroll.nativeElement.scrollBy(0, 1);
    }
  }

  public scrollToBottom() {
    this.chatScroll.nativeElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  private handleRoomMessageModify = (msg) => {
    this.handleMessageModify(msg);
  };

  private handleDialogueMessageModify = (msg) => {
    this.handleMessageModify(msg);
  };

  private handleMessageModify = (msg: WorkspaceSubscriptionRoomMessageEventRecord) => {
    if (msg.id == this.resourceId) {
      for (let i = 0; i < this.chatMessages.length; i++) {
        if (this.chatMessages[i].id == msg.messageId) {
          if (msg.content !== undefined) this.chatMessages[i].message = msg.content;
          if (msg.edited !== undefined) this.chatMessages[i].edited = msg.edited;
          if (msg.decryptionError !== undefined) this.chatMessages[i].error = msg.decryptionError;
          if (msg.reactionsAdded !== undefined || msg.reactionsRemoved !== undefined) {
            Object.assign(
              this.chatMessages[i],
              this.calcReactionInfo(this.chatMessages[i].messageRef.reactions)
            );
          }
        }
        if (this.chatMessages[i].replyToId === msg.messageId) {
          if (msg.content !== undefined) this.chatMessages[i].replyToMsg.message = msg.content;
          if (msg.edited !== undefined) this.chatMessages[i].replyToMsg.edited = msg.edited;
          if (msg.decryptionError !== undefined) {
            this.chatMessages[i].replyToMsg.error = msg.decryptionError;
            this.chatMessages[i].replyToMsg.errorMessage = msg.decryptionErrorMessage;
          }
        }
      }
    }
  };

  private handleRoomMessageDelete = (msg) => {
    if (this.type == ResourceType.ROOM) {
      this.handleMessageDelete(msg);
    }
  };

  private handleDialogueMessageDelete = (msg) => {
    if (this.type == ResourceType.DIALOGUE) {
      this.handleMessageDelete(msg);
    }
  };

  private handleMessageDelete = (msg) => {
    if (msg.id == this.resourceId) {
      for (let i = 0; i < this.chatMessages.length; i++) {
        if (this.chatMessages[i].id == msg.messageId) {
          this.chatMessages.splice(i, 1);

          // calculate isGrouping, hasLineBreak around the deleted message
          this.setGroupingProperties(i + 1);
          this.setGroupingProperties(i);
          this.setGroupingProperties(i - 1);
        }

        if (this.chatMessages[i].replyToId == msg.messageId) {
          this.chatMessages[i].replyToMsg = null;
          this.chatMessages[i].replyToMsgError = true;
        }
      }
    }
  };

  private handleRoomEventModify = (event: WorkspaceSubscriptionRoomEventRecord) => {
    if (event.id !== this.resourceId) {
      return;
    }

    if (
      'nanoSessionsAdded' in event ||
      'nanoSessionsRemoved' in event ||
      'nanoSessionsReset' in event
    ) {
      this.roomService.getNanoSession(this.data.id).then((nanoSession) => {
        this.isDocumentEditorSupported = isNanoFeatureSupported(
          nanoSession.version,
          NanoFeature.DOCUMENT
        );
      });
    }

    if (event.peerSeenIdsAdded) {
      // room seen event
      const peerSeenIdsAdded = event.peerSeenIdsAdded;
      // currently the `peerSeenIdsAdded` has only one entry
      // later we should optimize this
      // so we would not fetch the same message more than once

      for (const [userId, msgId] of Object.entries(peerSeenIdsAdded)) {
        const prevMessageId = this.userSeenState?.[userId];
        this.updateSeenMessage(prevMessageId);

        this.userSeenState[userId] = msgId;
        this.updateSeenMessage(msgId);
      }

      setTimeout(() => {
        // trigger gui refresh after the animation event
        this.triggerIOSGUIRefresh();
      }, 100);
    }

    if (event.nanoSessionsAdded || event.nanoSessionsRemoved || event.nanoSessionsReset) {
      this.checkDriveStatus();
    }
  };

  private handleDialogueEventModify = (event: WorkspaceSubscriptionDialogueEventRecord) => {
    // seen logic
    if (event.id !== this.resourceId) {
      return;
    }
    if (event.peerSeenId) {
      // room seen event
      const msgId = event.peerSeenId;
      const userId = event.id;

      const prevMessageId = this.userSeenState?.[userId];
      this.updateSeenMessage(prevMessageId);

      this.userSeenState[userId] = msgId;
      this.updateSeenMessage(msgId);

      setTimeout(() => {
        // trigger gui refresh after the animation event
        this.triggerIOSGUIRefresh();
      }, 100);
    }
  };

  private updateSeenMessage = (msgId) => {
    if (msgId) {
      const foundMessage = this.chatMessages.find((msg) => msg.id === msgId);
      if (foundMessage) {
        this.service.getSeenIdsByMessage(this.resourceId, foundMessage.id).then((seenBy) => {
          foundMessage.seenBy = seenBy.filter((userId) => userId !== this.me.id);
        });
      }
    }
  };

  private handleTypingEvent = (
    data:
      | WorkspaceSubscriptionDialogueTypingEventRecord
      | WorkspaceSubscriptionRoomTypingEventRecord
  ): void => {
    let currentlyTypingUserId = data['accountId'] ?? data['id'];

    if (!this.canShowTyping(data, currentlyTypingUserId)) return;

    let alreadyTypingUser = this.typingUsers.find(
      (typingUser) => typingUser.user.id == currentlyTypingUserId
    );
    if (alreadyTypingUser) {
      //if user is in the list when this event fires -> user is still typing, we have to reset and restart timer
      clearTimeout(alreadyTypingUser.timeoutId);
    }

    let timeOut = setTimeout(() => {
      this.removeIsTyping(currentlyTypingUserId);
    }, 6000);

    if (alreadyTypingUser) {
      alreadyTypingUser.timeoutId = timeOut;
    } else {
      this.accountService.getAccount(currentlyTypingUserId).then((account) => {
        if (!this.canShowTyping(data, currentlyTypingUserId)) return;
        this.typingUsers.push({ timeoutId: timeOut, user: account });
      });
    }
  };

  private canShowTyping(data, currentlyTypingUserId): boolean {
    //if i am typing in room, don't show myself as typing
    if (
      (this.type == ResourceType.ROOM && currentlyTypingUserId == this.me.id) ||
      this.resourceId != data['id']
    )
      return false;

    //if typing user is in other dialogue, don't show as typing
    if (this.type == ResourceType.DIALOGUE && currentlyTypingUserId != this.resourceId)
      return false;

    //if typing user is already displayed...
    if (this.typingUsers.some((typingUser) => typingUser.user.id === currentlyTypingUserId))
      return false;
    return true;
  }

  private removeIsTyping(accountId: string, shouldClearTimeout: boolean = false): void {
    if (shouldClearTimeout) {
      let user = this.typingUsers.find((typingUser) => typingUser.user.id == accountId);
      if (user) {
        clearTimeout(user.timeoutId);
      }
    }
    this.typingUsers = this.typingUsers.filter((typingUser) => typingUser.user.id != accountId);
  }

  public getTypingNames(): string {
    if (this.typingUsers.length > 2) {
      let firstTwo = this.typingUsers.slice(0, 2);
      let otherTypingUsersCount = this.typingUsers.length - 2;
      return (
        firstTwo.map((typingUser) => typingUser.user.avatarName).join(', ') +
        this.translate.instant(marker(' and ')) +
        otherTypingUsersCount +
        this.translate.instant(marker(' other people are typing...'))
      );
    }
    return (
      this.typingUsers.map((typingUser) => typingUser.user.avatarName).join(', ') +
      this.translate.instant(marker(' is typing...'))
    );
  }

  constructor(
    private chatService: ChatService,
    private dialogueService: DialogueService,
    private dialogService: DialogService,
    private snackbar: MatSnackBar,
    private accountService: AccountService,
    private subscriptionService: SubscriptionService,
    private roomService: RoomService,
    private clipboardService: ClipboardService,
    private deviceDetectorService: DeviceDetectorService,
    private translateService: TranslateService,
    private translate: TranslateService,
    private snackbarService: SnackBarService,
    private bottomSheet: MatBottomSheet,
    private routerHandler: RouterHandler,
    private nativeAppService: NativeAppService,
    private titleService: TitleService,
    private permissionService: PermissionService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private pageTabService: PageTabService
  ) {
    this.isOnDesktop;
    this.isOnApp = this.nativeAppService.isOnApp() || forceMobileView;

    this.titleService.setCurrentTabTitle(marker('Chat'));

    this.isMessagesLoading = true;

    this.subscriptionService.subscribeWorkspaceLoaded(this.onSubscribeWorkspaceLoaded);
    this.routerHandler.subscribeAll(this.onRouterChange);

    this.chatMessageComparePolicy = this.getSelectedChatMessagePolicy();

    window.addEventListener('popstate', this.popStateCallback);
    this.isOnDesktop = this.deviceDetectorService.isDesktop();

    this.document.addEventListener('pause', this.offscopeCallback, false);
  }
  ngAfterViewInit(): void {
    this.hookUpChatContainerChangeListener();
    this.hookUpScrollListener();
    this.hookUpEventListeners();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !changes.data.isFirstChange()) {
      this.initChat();
    }
  }

  // private firstLoading = true;

  private onSubscribeWorkspaceLoaded = () => {
    // if (this.firstLoading) {
    //   this.firstLoading = false;
    // } else {
    // reset now like this, later refactor
    this.resourceId = null;
    this.isMessagesLoading = true;
    /*let route = this.routerHandler.getRoute();

      if (route.params.type == 'private') {
        this.cacheService.deleteCacheDataByRequest({
          query: dialogueMessagesQuery,
          variables: { peerId: route.params.id },
        });
      } else {
        this.cacheService.deleteCacheDataByRequest({
          query: roomMessagesQuery,
          variables: { roomId: route.params.id },
        });
      }*/

    this.initChat();
    // }
  };

  private offscopeCallback = () => {
    if (
      this.myPermissions.isMember &&
      (this.lastUnsentMessage.length > 0 || this.lastUnsentMessage === '')
    ) {
      this.chatService.saveMessageInStorage(
        this.resourceId,
        this.lastUnsentMessage,
        this.repliedMessage?.id
      );
    }
  };

  ngOnInit(): void {
    // this.subscriptionService.subscribeWorkspaceLoaded(() => {
    //   this.initChat();
    // })
    this.subscribeAllWorkspaceChanges();

    this.setTitleFromData();
  }

  private setTitleFromData = () => {
    if (this.data.page === PageTypes.ROOM) {
      this.roomService.getRoom(this.data.id).then((res) => {
        if (res?.data?.name) {
          this.titleService.setCurrentTabTitle(res.data.name);
        }
      });
    }
    if (this.data.page === PageTypes.PRIVATE_CHAT) {
      this.accountService.getAccount(this.data.id).then((res) => {
        if (res.avatarName) {
          this.titleService.setCurrentTabTitle(res.avatarName);
        }
      });
    }
  };

  private subscribeAllWorkspaceChanges() {
    // seen events
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.handleRoomEventModify
    );
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.handleDialogueEventModify
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.handleRoomMessageCreate
    );
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_MESSAGE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.handleRoomMessageModify
    );
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_MESSAGE_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.handleRoomMessageDelete
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.handleDialogueMessageCreate
    );
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_MESSAGE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.handleDialogueMessageModify
    );
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_MESSAGE_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.handleDialogueMessageDelete
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.ALL,
      this.onRoomAccountPermissionChange
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_TYPING_EVENT,
      SubscriptionServiceEventType.ALL,
      this.handleTypingEvent
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_TYPING_EVENT,
      SubscriptionServiceEventType.ALL,
      this.handleTypingEvent
    );
  }

  private onRoomAccountPermissionChange = (
    event: WorkspaceSubscriptionRoomAccountPermissionEventRecord
  ) => {
    if (this.resourceId == event.id) {
      this.service.getChatPermissions(this.resourceId).then((perm) => {
        if (this.resourceId == event.id) {
          // if it is still that after response
          this.myPermissions = perm;
        }
      });

      //already subscribing in the WS, remove duplicate
      if (event.cmd == SubscriptionServiceEventType.CREATE) {
        this.chatService.unsubscribeAllChatChanges(this.resourceId);
        this.service.getDetail(this.resourceId);
      }

      if (event.cmd == SubscriptionServiceEventType.DELETE) this.subscribeToAllChatServiceChanges();
    }
  };

  private unsubscribeAllWorkspaceChanges() {
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.handleRoomEventModify
    );
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.handleDialogueEventModify
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.handleRoomMessageCreate
    );
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_MESSAGE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.handleRoomMessageModify
    );
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_MESSAGE_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.handleRoomMessageDelete
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.handleDialogueMessageCreate
    );
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_MESSAGE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.handleDialogueMessageModify
    );
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_MESSAGE_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.handleDialogueMessageDelete
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.ALL,
      this.onRoomAccountPermissionChange
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_TYPING_EVENT,
      SubscriptionServiceEventType.ALL,
      this.handleTypingEvent
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_TYPING_EVENT,
      SubscriptionServiceEventType.ALL,
      this.handleTypingEvent
    );
  }

  ngOnDestroy() {
    // mobile specific, when there is no tab-collection
    // this.routerHandler.unsubscribeAll(this.onRouterChange);

    if (
      this.myPermissions?.isMember &&
      ((this.lastUnsentMessage && this.lastUnsentMessage.length > 0) ||
        this.lastUnsentMessage === '')
    ) {
      if (!this.editedMessage) {
        this.chatService.saveMessageInStorage(
          this.resourceId,
          this.lastUnsentMessage,
          this.repliedMessage?.id
        );
      }
    }

    if (this.editedMessage) {
      this.editedMessage = null;
    }

    if (this.repliedMessage) {
      this.repliedMessage = null;
    }

    window.removeEventListener('popstate', this.popStateCallback);

    // we dont need all of the callback references, this feature simply close the subscription, and delete the handlers
    this.chatService.unsubscribeAllChatChanges(this.resourceId);

    this.routerHandler.unsubscribeAll(this.onRouterChange);
    this.unsubscribeAllWorkspaceChanges();

    this.copySnackBarRef?.dismiss();

    this.document.removeEventListener('pause', this.offscopeCallback);

    this.subscriptionService.unsubscribeWorkspaceLoaded(this.onSubscribeWorkspaceLoaded);
    this.scrollingEventUnlistenFunction();
  }

  public deleteRoom(): void {
    if (!this.myPermissions?.isOwner) {
      this.roomService.leaveRoom(this.resourceId).then(() => {
        this.snackbarService.showSnackbar(marker('You left the room.'));
        this.routerHandler.navigate(['/']);
      });
    } else {
      this.roomService.deleteRoom(this.resourceId).then(() => {
        this.snackbarService.showSnackbar(marker('Room has been deleted.'));
        this.routerHandler.navigate(['/']);
      });
    }
  }

  onSubmit(ev: InputSubmitEvent) {
    this.service
      .sendMessage(this.resourceId, ev.text, ev.attachments, ev.uploadPath, this.repliedMessage?.id)
      .then(() => {
        this.repliedMessage = null;
        this.unsentMessages = this.service.getUnsentMessages(this.resourceId);
        this.lastUnsentMessage = '';
      });
  }

  onEditSubmit(ev: InputSubmitEvent, editedMessage: ChatData) {
    if (this.isMessageSending || ev.text.trim().length == 0) return;

    this.isMessageSending = true;

    this.service
      .editMessage(this.resourceId, editedMessage.id, ev.text, editedMessage.replyToId)
      .then((msgId) => {
        this.editedMessage = null;
        this.snackbarService.showSnackbar(marker('Message edited.'));
      })
      .catch((err) => {
        console.error('Send message error', err);
        this.snackbarService.showSnackbar(marker('Could not send message.'), marker('OK'));
      })
      .finally(() => {
        this.isMessageSending = false;
      });
  }

  /**
   * Message id contains the timestamp in saferadix encoded
   * If you cut the last 5 char from the string, you'll get the encoded time
   * start at 2020.01.01 01:00 GMT+0100
   * every tick (calculated number by decodeSafeRadix) means 0.02 sec
   * @param id
   */
  private getTimeFromId(id: string): Date {
    let ticks = SafeRadix32.decodeSafeRadix32(id.slice(0, -5)); // get the ticks from the encoded number
    let timestamp = ticks * 20; // 0.02 sec for every tick
    return new Date(timestamp + 1577836800000); // add 2020.01.01 01:00 to it (this is the start)
  }

  /**
   * Convert raw server message into ChatData. Use loadMessage to install message into this system
   * @param data
   * @returns
   */
  private convertServerRecordToMessage(data: MessageRecord): ChatData {
    return {
      id: data.id,
      message: data.content,
      date: this.getTimeFromId(data.id),
      account: {
        id: data.posterId,
        avatarImageKey: null,
        avatarName: null,
        deleted: false,
      },
      edited: data.edited,
      seenBy: [],
      error: data.decryptionError,
      errorMessage: data.decryptionErrorMessage,
      messageRef: data,
      replyToId: data.replyTo, // data.replyTo ?? null,
      replyToMsg: null,
    };
  }

  /**
   * load, convert, and merge raw server response data into this chatData structure
   * connect user data to messages
   * @param data
   */
  private loadMessage(data: MessageRecord) {
    try {
      let msg = this.convertServerRecordToMessage(data);

      this.accountService.getAccount(msg.account.id).then((account) => {
        msg.account = account;
      });

      if (this.type == ResourceType.ROOM) {
        this.permissionService
          .getMyRoomPermissionsRecord(this.resourceId)
          .then((roomPermission) => {
            msg.isOwnerOfRoom = roomPermission.ownerAccountId === msg.account.id;
          })
          .catch((err) => {
            console.error('could not get room perm:4', this.resourceId);
          });
      }

      let p = this.service.getSeenIdsByMessage(this.resourceId, msg.id).then((seenUserIdList) => {
        const filteredSeenBy = seenUserIdList.filter((id) => id !== this.me.id);
        msg.seenBy = filteredSeenBy;

        for (const seenUserId of filteredSeenBy) {
          this.userSeenState[seenUserId] = msg.id;
        }
        return;
      });

      Object.assign(msg, this.calcReactionInfo(data.reactions));
      this.mergeMessage(msg);
      return p;
    } catch (e) {
      console.error('loadMessages error', e);
      return Promise.reject(e);
    }
  }

  private calcReactionInfo(reactions: { [userId: ID]: ReactionEnum }) {
    let allReaction = Object.values(reactions);
    let sumReaction = allReaction.length;
    let someReaction = [];
    for (let i = allReaction.length - 1; i >= 0; i--) {
      if (!someReaction.includes(allReaction[i])) {
        someReaction.push(allReaction[i]);
        if (someReaction.length >= this.MAXIMUM_EMOJI_KIND_NEXT_TO_MESSAGE) {
          break;
        }
      }
    }
    return {
      sumReaction,
      someReaction,
      myReaction: reactions[this.me.id] || null,
    };
  }

  private loadMessages(dataList: MessageRecord[]): Promise<void> {
    const promiseList: Promise<void>[] = [];
    const replyList: ReplyObject[] = [];
    const messageRecords: MessageRecord[] = dataList.filter((msg) => msg.id);
    const placeholderRecords: any[] = dataList.filter((msg) => !msg.id);

    for (let i = messageRecords.length - 1; i >= 0; i--) {
      promiseList.push(this.loadMessage(messageRecords[i]));
      // is it a reply
      if (messageRecords[i]?.replyTo) {
        replyList.push({
          messageId: messageRecords[i].id,
          replyId: messageRecords[i]?.replyTo,
        });
      }
    }

    const messagesLoadedPromise: Promise<void> = new Promise((resolve) => {
      Promise.all(promiseList).then(() => {
        this.insertEnds(dataList);
        if (messageRecords.length > 1) {
          this.insertDateSeparatorsBetween(
            messageRecords[0].id,
            messageRecords[messageRecords.length - 1].id
          );
        }
        this.setRepliesByReplyList(replyList);
        this.insertPlaceholders(placeholderRecords);

        resolve();
      });
    });

    return messagesLoadedPromise;
  }

  private insertEnds(dataList: MessageRecord[]): void {
    //first END
    if (
      dataList.length > 1 &&
      (<MessageRecordPlaceholder>(<unknown>dataList[0])).type === MessageRecordType.END
    ) {
      if (this.chatMessages[0]?.type !== MessageRecordType.END)
        this.chatMessages.splice(0, 0, <any>{ type: 0 });
    }
    if (
      //last END
      dataList.length > 1 &&
      (<MessageRecordPlaceholder>(<unknown>dataList[dataList.length - 1])).type ===
        MessageRecordType.END
    ) {
      if (this.chatMessages[this.chatMessages.length - 1]?.type !== MessageRecordType.END)
        this.chatMessages.push(<any>{ type: 0 });
    }

    if (
      dataList.length === 1 &&
      (<MessageRecordPlaceholder>(<unknown>dataList[0])).type === MessageRecordType.END
    ) {
      this.chatMessages.push(<any>{ type: 0 });
    }
  }

  private insertPlaceholders(placeholderRecords: MessageRecordPlaceholder[]) {
    for (let placeholder of placeholderRecords) {
      if (placeholder?.type === MessageRecordType.VOID) {
        if (!placeholder.afterMsgId && !placeholder.beforeMsgId) {
          console.warn('there is no before and after id set for void');
        }
        if (placeholder?.beforeMsgId) {
          const foundIndex = this.chatMessages.findIndex(
            (msg) => msg.id === placeholder.beforeMsgId
          );

          if (foundIndex !== -1) {
            const beforeMessage = this.chatMessages[foundIndex + 1];
            if (beforeMessage?.type === MessageRecordType.VOID) {
              //update existing placeholder
              (<MessageRecordPlaceholder>beforeMessage).beforeMsgId = placeholder.beforeMsgId;
            } else {
              //insert placeholder
              this.chatMessages.splice(foundIndex + 1, 0, <any>placeholder);
            }

            continue;
          }
        }
        if (placeholder?.afterMsgId) {
          const foundIndex = this.chatMessages.findIndex(
            (msg) => msg.id === placeholder.afterMsgId
          );

          if (foundIndex !== -1) {
            const afterMessage = this.chatMessages[foundIndex - 1];
            if (afterMessage?.type === MessageRecordType.VOID) {
              //update existing placeholder
              (<MessageRecordPlaceholder>afterMessage).afterMsgId = placeholder.afterMsgId;
            } else {
              //insert placeholder
              this.chatMessages.splice(foundIndex, 0, <any>placeholder);
            }

            continue;
          }
        }
      }
    }
  }

  private insertDateSeparatorsBetween(messageId1: string, messageId2: string): void {
    if (messageId1 == messageId2) {
      console.warn('Same ID', messageId1, messageId2);
      return;
    }

    let fromMessageId, toMessageId;
    if (messageId1 > messageId2) {
      // reversed direction because of reverse flexbox order
      fromMessageId = messageId1;
      toMessageId = messageId2;
    } else {
      fromMessageId = messageId2;
      toMessageId = messageId1;
    }

    let index = this.chatMessages.findIndex((el) => el.id == fromMessageId);
    let lastIndex = this.chatMessages.findIndex((el) => el.id == toMessageId);

    if (index - 1 >= 0) {
      index = index - 1;
    }

    if (lastIndex <= index) {
      console.warn('wrong interval', messageId1, messageId2);
      return;
    }

    while (index <= lastIndex) {
      const msg = this.chatMessages[index];
      const nextMsg = this.chatMessages[index + 1];

      if (!nextMsg) {
        return;
      }

      if (!msg) {
        console.warn(
          'out of messages array when inserting date separators',
          index,
          index + 1,
          this.chatMessages.length
        );
        return;
      }

      // do not compare VOID/END/DATE with message or with each others
      if (msg.id && nextMsg.id && !this.isSameDay(msg.date, nextMsg.date)) {
        this.chatMessages.splice(index + 1, 0, <any>{
          type: MessageRecordType.DATE,
          date: msg.date,
        });
        index++; //skip next, because next would be the currently inserted date and it breaks the current for-cycle
      }

      index++;
    }

    /*this.chatMessages = this.chatMessages.filter((cm) => cm.type !== MessageRecordType.DATE);
    for (let i = 0; i < this.chatMessages.length - 1; i++) {
      const msg = this.chatMessages[i];
      const nextMsg = this.chatMessages[i + 1];

      if (!nextMsg.id) {
        i++; //next message is a void or a date, skip it as well
        continue;
      }

      if (!msg.id) {
        continue;
      }

      if (!this.isSameDay(msg.date, nextMsg.date)) {
        this.chatMessages.splice(i + 1, 0, <any>{
          type: MessageRecordType.DATE,
          date: msg.date,
        });
        i++; //skip next, because next would be the currently inserted date
      }
    }

    if (this.chatMessages[this.chatMessages.length - 1].type === MessageRecordType.END) {
      const messages = this.chatMessages.filter((msg) => msg.id);
      if (messages.length === 0) return;

      const lastMsg = messages[messages.length - 1];

      this.chatMessages.splice(this.chatMessages.length - 1, 0, <any>{
        type: MessageRecordType.DATE,
        date: lastMsg.date,
      });
    }*/
  }

  private isSameDay(date1, date2): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  public voidVisible(isVisible: boolean, placeholder: ChatData): void {
    if (isVisible && this.unhandledVoids.indexOf(<MessageRecordPlaceholder>placeholder) === -1) {
      this.unhandledVoids.push(<MessageRecordPlaceholder>placeholder);
      if (!this.isScrolling && !this.isVoidLoading) {
        this.processVoids();
      }
    }
  }

  public messageVisible(isVisible: boolean, messageId: string): void {
    if (!this.myPermissions || !this.myPermissions.isMember) return;

    const msg = this.chatMessages.find((cm) => cm.id === messageId);
    if (isVisible) {
      this.visibleMessages.push(msg);

      if (
        !this.latestSeenMessage ||
        (this.myPermissions.isMember && msg.id > this.latestSeenMessage.id)
      )
        this.seenMessageEvent$.next(msg.id);
    } else {
      const msgIndex = this.visibleMessages.indexOf(msg);
      if (msgIndex > -1) this.visibleMessages.splice(msgIndex, 1);
    }
    this.visibleMessages.sort((m1, m2) => (m1.date > m2.date ? -1 : 1));
  }

  private topmostVisibleMessage: ChatData;
  public processVoids(): void {
    this.isVoidLoading = true;
    const cachedResourceId = this.resourceId;
    const processFinish$ = new Subject<void>();
    const loadedVoids: MessageRecordPlaceholder[] = [];

    const voidLoaderObservable = of(this.unhandledVoids).pipe(
      map((voids) => voids.shift()),
      mergeMap((placeholder) => {
        if (placeholder === undefined) {
          processFinish$.complete();
          return [];
        } else {
          return from(this.loadVoid(placeholder)).pipe(
            finalize(() => {
              loadedVoids.push(placeholder);
              setTimeout(() => processFinish$.next(), 1);
            })
          );
        }
      }),
      repeat({ delay: () => processFinish$ }),
      takeUntil(this.routeChanged$)
    );

    const allMessages = [];

    voidLoaderObservable.subscribe({
      next: (messages: MessageRecord[]) => {
        allMessages.push(...messages);
      },
      complete: () => {
        if (cachedResourceId === this.resourceId) {
          this.topmostVisibleMessage = this.visibleMessages[this.visibleMessages.length - 1];
          this.scrollFixMessageId = this.topmostVisibleMessage?.id;
          //remove voids from chat
          loadedVoids.forEach((placeholder: any) =>
            this.chatMessages.splice(this.chatMessages.indexOf(placeholder), 1)
          );
          this.isVoidLoading = false;

          if (allMessages.filter((am) => am.id).length === 0) this.scrollFixMessageId = null;

          this.handleReceivedOldMessages(cachedResourceId, allMessages);
        }
      },
    });
  }

  private loadVoid(placeholder: MessageRecordPlaceholder): Promise<MessageRecord[]> {
    //load messages before void
    let beforeLoadPromise: Promise<MessageRecord[]>;
    const beforeMsg = placeholder.beforeMsgId
      ? this.chatMessages.find((cm) => cm.id === placeholder.beforeMsgId)
      : null;
    if (beforeMsg) {
      beforeLoadPromise = this.service.getMoreMessages(
        this.resourceId,
        placeholder.beforeMsgId,
        LoadMessageDirection.AFTER
      );
    } else beforeLoadPromise = Promise.resolve([]);

    return beforeLoadPromise.then((beforeMessages: MessageRecord[]) => {
      //load messages after void
      let afterLoadPromise: Promise<MessageRecord[]>;
      const afterMsg = placeholder.afterMsgId
        ? this.chatMessages.find((cm) => cm.id === placeholder.afterMsgId)
        : null;
      if (afterMsg) {
        afterLoadPromise = this.service.getMoreMessages(
          this.resourceId,
          placeholder.afterMsgId,
          LoadMessageDirection.BEFORE
        );
      } else afterLoadPromise = Promise.resolve([]);

      return afterLoadPromise.then((afterMessages: MessageRecord[]) => {
        const oldMessages = [...beforeMessages, ...afterMessages];
        return oldMessages;
      });
    });
  }

  private setRepliesByReplyList = (replyList: ReplyObject[]): void => {
    if (replyList.length) {
      for (const replyObj of replyList) {
        replyObj.msgRef = this.chatMessages.find((msg) => msg.id === replyObj.messageId);
      }
      // unique id list
      const uniqueReplyIds = [...new Set(replyList.map((r) => r.replyId))];
      this.service.getMessagesById(this.resourceId, uniqueReplyIds).then((replies) => {
        for (let reply of replies) {
          if (!reply) {
            continue;
          }
          const replyMessage = this.convertServerRecordToMessage(reply);
          this.accountService.getAccount(replyMessage.account.id).then((account) => {
            replyMessage.account = account;
          });

          const relatedReplies = replyList.filter((r) => r.replyId === reply.id);
          relatedReplies.forEach((relatedReply) => {
            relatedReply.msgRef.replyToMsg = replyMessage;
          });
        }
        for (const replyObj of replyList) {
          if (!replyObj.msgRef.replyToMsg) {
            replyObj.msgRef.replyToMsgError = true;
          }
        }
      });
    }
  };

  public loadReplyMessage(messageId: string): void {
    //if the message is already loaded, then just scroll to it
    if (this.chatMessages.some((cm) => cm.id === messageId)) {
      this.scrollToLoadedMessage(messageId, true, true);
      return;
    }

    const pivot = messageId;
    const cachedResourceId = this.resourceId;
    this.service.getOneMessageById(this.resourceId, pivot).then((res) => {
      //load messages before pivot
      this.service
        .getMoreMessages(this.resourceId, pivot, LoadMessageDirection.BEFORE)
        .then((beforeMessages: MessageRecord[]) => {
          const messages = [];
          messages.push(...beforeMessages);

          //load messages after pivot
          this.service
            .getMoreMessages(this.resourceId, pivot, LoadMessageDirection.AFTER)
            .then((afterMessages: MessageRecord[]) => {
              messages.push(...afterMessages);
              if (this.resourceId === cachedResourceId) {
                this.scrollTargetMessageId = messageId;

                this.handleReceivedOldMessages(cachedResourceId, messages);
              }
            });
        });
    });
  }

  private hookUpChatContainerChangeListener(): void {
    //if the message container changes, and we have a scrollTargetSet, then scroll to it
    this.messageContainers.changes.subscribe((t) => {
      if (this.scrollTargetMessageId) {
        this.scrollToLoadedMessage(this.scrollTargetMessageId, true, true);
        this.scrollTargetMessageId = null;
      } else if (this.scrollFixMessageId) {
        this.scrollToLoadedMessage(this.scrollFixMessageId, false, true);
        this.scrollFixMessageId = null;
      }
    });
  }

  private prevScrollTop: number = 0;
  private hookUpScrollListener(): void {
    this.scrollingEventUnlistenFunction = this.renderer.listen(
      this.chatScroll.nativeElement,
      'scroll',
      (e) => {
        if (this.scrollingEndedTimeout) clearTimeout(this.scrollingEndedTimeout);

        const scrollTop = (<HTMLElement>this.chatScroll.nativeElement).scrollTop;

        this.showStartToChatArrow = scrollTop < -600;

        this.scrollDirection = this.prevScrollTop > scrollTop ? -1 : 1;
        this.prevScrollTop = scrollTop;
        this.isScrolling = true;
        this.scrollingEndedTimeout = setTimeout(() => {
          this.isScrolling = false;
          this.scrollingEndedTimeout = null;
          if (this.unhandledVoids.length > 0 && !this.isVoidLoading) {
            this.processVoids();
          }
        }, 100);
      }
    );
  }

  private hookUpEventListeners(): void {
    // This subscription handler can receive many seen message Id-s when the chat panel loads or when the user scrolls down fast.
    // 'buffer' is used to collect all the received messageIds into an array.
    // 'debounceTime' is used to tell the buffer when to release the collected Id-s.
    // When 500ms time elapses without a new 'seenMessageEvent$', 'debounceTime' emits an event, and the buffer sends the collected Id-s.
    // 'map' is used to transform the Id array into a single Id, which is the Id of the latest message.
    this.seenMessageEvent$
      .pipe(
        buffer(this.seenMessageEvent$.pipe(debounceTime(500))),
        map((ids) => ids.sort()[ids.length - 1])
      )
      .subscribe((latestSeenMessageId) => {
        this.service
          .seenMessage(this.resourceId, latestSeenMessageId)
          .then(() => {
            this.latestSeenMessage = this.chatMessages.find(
              (m) => m.id === latestSeenMessageId
            ).messageRef;
            // console.log('Sent seen message for message', this.latestSeenMessage);
          })
          .catch((err) => {
            console.error('seen error', err, latestSeenMessageId);
          });
      });
  }

  private scrollToLoadedMessage(
    messageId: string,
    showHighlight: boolean = false,
    instant: boolean = false
  ): void {
    const messageElement: HTMLElement = (<HTMLElement>this.chatScroll.nativeElement).querySelector(
      `#${this.MESSAGE_ID_PREFIX + messageId}`
    );

    if (instant) {
      (<HTMLElement>this.chatScroll.nativeElement).scrollTop = messageElement.offsetTop;
    } else {
      messageElement.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }

    if (showHighlight) {
      const chatMessageComponent = this.messageContainers.find((mc) => mc.data.id === messageId);
      chatMessageComponent.runHighlightAnimation();
    }
  }

  private handleReceivedOldMessages(
    cachedResourceId: string,
    oldMessages: MessageRecord[]
  ): Promise<void> {
    if (this.resourceId !== cachedResourceId) {
      return;
    }

    //remove voids if the oldMessages and the existingMessages meet/overlap
    this.sliceVoids(oldMessages);
    return this.loadMessages(oldMessages).then(() => {
      this.isMessagesLoading = false;

      this.chatEndReached =
        this.chatMessages[this.chatMessages.length - 1].type === MessageRecordType.END;

      return;
    });
  }

  private sliceVoids(oldMessages: MessageRecord[]): void {
    //foreach all voids, and if the beforeMessageId or afterMessageId exists in this.chatMessages then splice out the voids from oldMessages
    const newVoidElements = oldMessages.filter(
      (om) => (<MessageRecordPlaceholder>(<unknown>om))?.type === MessageRecordType.VOID
    ) as unknown as MessageRecordPlaceholder[]; //https://youtu.be/7IexUUE69HE
    for (const voidElement of newVoidElements) {
      if (!voidElement.afterMsgId && !voidElement.beforeMsgId) {
        console.warn('no before and after set for void');
        continue;
      }
      const voidElementIndex = oldMessages.indexOf(<MessageRecord>(<unknown>voidElement));
      for (let i = 0; i < this.chatMessages.length; i++) {
        let cm = this.chatMessages[i];
        if (cm.id && (cm.id === voidElement.beforeMsgId || cm.id === voidElement.afterMsgId)) {
          oldMessages.splice(voidElementIndex, 1);
          break;
        }
      }
    }

    //now check if the newly inserted messages meet/overlap the existing messages, and splice out the voids from this.chatMessages
    const existingVoidElements = this.chatMessages.filter(
      (om) => (<MessageRecordPlaceholder>(<unknown>om))?.type === MessageRecordType.VOID
    ) as unknown as MessageRecordPlaceholder[];
    for (const voidElement of existingVoidElements) {
      if (!voidElement.afterMsgId && !voidElement.beforeMsgId) {
        console.warn('no before and after set for void');
        continue;
      }
      const voidElementIndex = this.chatMessages.indexOf(<ChatData>(<unknown>voidElement));
      for (let i = 0; i < oldMessages.length; i++) {
        let om = oldMessages[i];
        if (om.id && (om.id === voidElement.beforeMsgId || om.id === voidElement.afterMsgId)) {
          this.chatMessages.splice(voidElementIndex, 1);
          break;
        }
      }
    }
  }

  /**
   * push message into the array
   * @param msg
   */
  private mergeMessage(msg: ChatData) {
    // two most common operation (push, unshift)
    let p = performance.now();

    // dont merge if message with id already exists
    if (this.chatMessages.find((chatMsg) => chatMsg.id === msg.id)) {
      //console.warn(`Message with id: ${msg.id} is already loaded`);
      return;
    }
    const oldestMessage = this.getOldestMessage();
    const newestMessage = this.getNewestMessage();

    if (this.chatMessages.filter((cm) => cm.id).length == 0) {
      this.chatMessages.splice(0, 0, msg);
      this.setGroupingProperties(this.chatMessages.indexOf(msg));
    } else if (msg.date.getTime() <= oldestMessage?.date?.getTime()) {
      // new message is older than the last message
      this.chatMessages.push(msg);
      this.setGroupingProperties(this.chatMessages.length - 1);
      if (this.chatMessages.length - 2 >= 0) {
        this.setGroupingProperties(this.chatMessages.length - 2);
      }
    } else if (msg.date.getTime() >= newestMessage?.date?.getTime()) {
      const newestMessageIndex = this.chatMessages.indexOf(newestMessage);
      // new message is newer than the "newest" message
      this.chatMessages.splice(newestMessageIndex, 0, msg);
      // calculate isInGroup, hasLineBreak around the new message
      this.setGroupingProperties(newestMessageIndex + 1);
      this.setGroupingProperties(newestMessageIndex);
    } else {
      // check for its place
      for (let i = 1; i < this.chatMessages.length; i++) {
        if (!this.chatMessages[i].id) continue;
        if (msg.date.getTime() >= this.chatMessages[i].date?.getTime()) {
          this.chatMessages.splice(i, 0, msg);
          // calculate isInGroup, hasLineBreak around the new message
          if (this.chatMessages.length > i) {
            this.setGroupingProperties(i + 1);
          }
          this.setGroupingProperties(i);
          this.setGroupingProperties(i - 1);
          break;
        }
      }
    }
  }

  private getOldestMessage(): ChatData {
    for (let i = this.chatMessages.length - 1; i >= 0; i--) {
      const msg = this.chatMessages[i];
      if (msg.id) return msg;
    }

    return null;
  }

  private getNewestMessage(): ChatData {
    for (let i = 0; i < this.chatMessages.length; i++) {
      const msg = this.chatMessages[i];
      if (msg.id) return msg;
    }

    return null;
  }

  public onMessageResized() {
    // when the messages are resized, the flex-container's size is not updated and the scroll area is not adjusted
    // automatically because of a bug. We need to manually force the container to render for it to get fixed.
    this.triggerIOSGUIRefresh();
  }

  public onMessageDelete(messageId) {
    this.dialogService
      .openConfirmDialog(
        marker('Delete message'),
        marker('Are you sure you want to delete this message?')
      )
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.service.deleteMessage(this.resourceId, messageId).then(() => {
            this.snackbarService.showSnackbar(marker('Message deleted.'));
          });
        }
      });
  }

  chatBoxBottomVisible(isVisible: boolean) {
    this.isBottomVisible = isVisible;
    if (isVisible && !this.isMessagesLoading && this.chatMessages.length > 0) {
      // this.sendSeen(); seen message is handled by actual message visibility change
    }
  }

  public onMouseDown(ev): void {
    this.isSelecting = true;
    this.clearPreviousSelection();
    //window.getSelection().removeAllRanges(); // it ruins the editor in edit mode; can not type character in the input (chrome)
    this.copySnackBarRef?.dismiss();
  }

  private selectChatMessagesBetweenElements(start: Node, end: Node): void {
    var chatParent = start.parentElement;
    var canSelect: boolean = false;

    for (let i = 0; i < chatParent.childNodes.length; i++) {
      const chatMessage = chatParent.childNodes.item(i);
      if (chatMessage.nodeType != 1 && (<HTMLElement>chatMessage).tagName != this.chatTag) continue;

      if (chatMessage == start || chatMessage == end) {
        canSelect = !canSelect;
        if (!canSelect) {
          //still need to add the last message
          (<HTMLElement>chatMessage).classList.add('selected');
          if (this.selectedChatMessages.every((scm) => scm != chatMessage))
            this.selectedChatMessages.push(<HTMLElement>chatMessage);
          continue;
        }
      }

      if (canSelect) {
        if (!this.selectedChatMessages.some((scm) => scm === chatMessage)) {
          (<HTMLElement>chatMessage).classList.add('selected');
          this.selectedChatMessages.push(<HTMLElement>chatMessage);
        }
      } else {
        (<HTMLElement>chatMessage).classList.remove('selected');
        this.selectedChatMessages = this.selectedChatMessages.filter((scm) => scm != chatMessage);
      }
    }
  }

  private selectFirstChatMessageDuringDeselect(start: Node) {
    var chatParent = start.parentElement;
    var canSelect: boolean = false;

    for (let i = 0; i < chatParent.childNodes.length; i++) {
      const chatMessage = chatParent.childNodes.item(i);
      if (chatMessage.nodeType != 1 && (<HTMLElement>chatMessage).tagName != this.chatTag) continue;

      canSelect = start == chatMessage;

      if (canSelect) {
        (<HTMLElement>chatMessage).classList.add('selected');
        if (this.selectedChatMessages.every((scm) => scm != chatMessage))
          this.selectedChatMessages.push(<HTMLElement>chatMessage);
      } else {
        (<HTMLElement>chatMessage).classList.remove('selected');
        this.selectedChatMessages = this.selectedChatMessages.filter((scm) => scm != chatMessage);
      }
    }
  }

  private getChatElementInParents(start: Node): Node {
    if (start.nodeType == 1 && (<HTMLElement>start).tagName === this.chatTag) {
      //node is HTMLElement and is chat element
      return start;
    }

    var el: HTMLElement = start.parentElement;
    while (el?.parentNode) {
      if (el.tagName === this.chatTag) return el;
      if (el.id === 'chat-scroll') return el.querySelector('app-chat-message:last-of-type') || null;
      if (el.id === 'is-typing-container')
        return el.parentElement.querySelector('app-chat-message:first-of-type') || null;

      el = el.parentElement;
    }

    return null;
  }

  private getOneMessageContentInParents(start: Node): Node {
    if (start.nodeType == 1 && (<HTMLElement>start).classList.contains('one-message-content')) {
      return start;
    }

    var el: HTMLElement = start.parentElement;
    while (el?.parentNode) {
      if (el.classList.contains('one-message-content')) {
        return el;
      }

      el = el.parentElement;
    }

    return null;
  }

  private clearPreviousSelection(): void {
    this.selectedChatMessages.forEach((cm) => cm.classList.remove('selected'));
    this.selectedChatMessages = [];
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    if (this.isOnDesktop) {
      if (
        this.context_trigger.menuOpen &&
        (event.target as Element).innerHTML !== 'expand_circle_down'
      ) {
        this.context_trigger.closeMenu();
      }
    }
  }

  public handleChatMessageContextMenu = (data: ChatMessageContextMenuData) => {
    let resetContextTarget = () => {
      this.isExpanded = false;
      this.contextTargetMessage = data;
      this.contextTargetPermissions = this.getMyPermissionOverChatData(data.data);
    };

    if (this.isOnDesktop) {
      if (this.context_trigger.menuOpen) {
        this.context_trigger.closeMenu();
        return;
      }
      resetContextTarget();
      this.openContextMenu(data.event);
    } else {
      // open bottomsheet
      resetContextTarget();
      this.openMenuSheet();
    }
  };

  public openMenuSheet(): void {
    var sheetRef = this.bottomSheet.open(ChatLayoutBottomSheetMenuComponent, {
      data: {
        contextTargetMessage: this.contextTargetMessage,
        contextTargetPermissions: this.contextTargetPermissions,
        myPermissions: this.myPermissions,
        // allowAnonym: this.react,
      },
    });
    sheetRef.afterDismissed().subscribe((ret: { action: ChatLayoutSheetAction; props?: any }) => {
      switch (ret?.action) {
        case ChatLayoutSheetAction.REACT: {
          this.addReaction(this.contextTargetMessage.data.id, ret.props); //props = emoji
          break;
        }
        case ChatLayoutSheetAction.COPY: {
          const copyText = this.parseCopiedMessages([this.contextTargetMessage.data]);
          this.clipboardService
            .copy(copyText.trim())
            .then(() => {
              this.snackbarService.showSnackbar(marker('Messages copied to clipboard!'));
            })
            .catch((err) => {
              console.error('clipboard err', err);
              this.dialogService.openAlertDialog(
                marker('Copy Error'),
                marker('Could not copy to the clipboard')
              );
            });
          break;
        }
        case ChatLayoutSheetAction.COPY_FILE_URL: {
          // copy
          this.clipboardService
            .copy((<AttachedFileUrl>this.contextTargetMessage.event['fileUrl']).raw)
            .then(() => {
              this.snackbarService.showSnackbar(marker('File url copied to clipboard!'));
            })
            .catch((err) => {
              console.error('clipboard err', err);
              this.dialogService.openAlertDialog(
                marker('Copy Error'),
                marker('Could not copy to the clipboard')
              );
            });
          break;
        }
        case ChatLayoutSheetAction.EDIT: {
          this.setEditedMessage(this.contextTargetMessage.data); // @todo
          break;
        }
        case ChatLayoutSheetAction.REPLY: {
          this.closeEditedMessage(null);
          // reply function
          this.setRepliedMessage(this.contextTargetMessage.data);
          break;
        }
        case ChatLayoutSheetAction.DELETE: {
          this.onMessageDelete(this.contextTargetMessage.data.id);
          break;
        }
        default:
          return;
      }
    });
  }

  private getMyPermissionOverChatData = (data: ChatData): ChatMessagePermission => {
    let modifyPermission = this.me.id == data?.account.id;

    return {
      modifyPermission,
      pinPermission: this.myPermissions.canPinMessages,
      reactionPermission: this.myPermissions.canAddReactions,
      deletePermission: modifyPermission || this.myPermissions.canDeleteAnyMessages,
    };
  };

  public contextMenuAction = (action: ContextMenuAction, props?) => {
    switch (action) {
      case ContextMenuAction.EDIT:
        this.setEditedMessage(this.contextTargetMessage.data); // @todo
        break;
      case ContextMenuAction.REPLY:
        this.closeEditedMessage(null);
        // reply function
        this.setRepliedMessage(this.contextTargetMessage.data);
        break;
      case ContextMenuAction.COPY:
        let selection = window.getSelection();
        let copyText = null;
        if (selection?.toString && selection.toString()) {
          copyText = selection.toString();
        } else {
          //copyText = this.parseCopiedMessages([this.contextTargetMessage]);
          copyText = this.contextTargetMessage.data.message; // only copy the message if you select only one
        }
        selection.removeAllRanges();

        // copy
        this.clipboardService
          .copy(copyText.trim())
          .then(() => {
            this.snackbarService.showSnackbar(marker('Messages copied to clipboard!'));
          })
          .catch((err) => {
            console.error('clipboard err', err);
            this.dialogService.openAlertDialog(
              marker('Copy Error'),
              marker('Could not copy to the clipboard')
            );
          });
        break;
      case ContextMenuAction.COPY_FILE_URL:
        // copy
        this.clipboardService
          .copy((<AttachedFileUrl>this.contextTargetMessage.event['fileUrl']).raw)
          .then(() => {
            this.snackbarService.showSnackbar(marker('File url copied to clipboard!'));
          })
          .catch((err) => {
            console.error('clipboard err', err);
            this.dialogService.openAlertDialog(
              marker('Copy Error'),
              marker('Could not copy to the clipboard')
            );
          });
        break;
      case ContextMenuAction.DELETE:
        this.onMessageDelete(this.contextTargetMessage.data.id);
        break;
      case ContextMenuAction.OPEN_FILE_IN_EDITOR:
        let fullPath = (<AttachedFileUrl>this.contextTargetMessage.event['fileUrl']).path;
        if (fullPath) {
          fullPath += '/';
        }
        fullPath += (<AttachedFileUrl>this.contextTargetMessage.event['fileUrl']).file;
        this.pageTabService.openInCurrentTab({
          page: PageTypes.ROOM,
          subpage: SubPageTypes.OFFICE,
          id: this.contextTargetMessage.event['fileUrl'].roomId,
          fragment: { file: fullPath },
        });
        break;
      case ContextMenuAction.SELECT:
        // @todo
        break;
      case ContextMenuAction.REACT:
        this.addReaction(this.contextTargetMessage.data.id, props); //props = emoji
        break;
      case ContextMenuAction.PIN:
        this.pinMessage(this.contextTargetMessage.data.id);
        break;
      default:
        console.warn('not supported ContextMenuAction', action);
    }
  };

  public expandEmojiPicker = (event: MouseEvent) => {
    event.stopPropagation();

    const EXPANDED_EMOJI_PANEL_HEIGHT = 240;

    // get generated css prop "transform-origin" from parent. It tells us in which way the mat menu opens (left/right top/bottom)
    const menuPosition =
      this.document.getElementsByClassName('chat-context-menu')?.[0].parentElement.parentElement
        .parentElement.style['transform-origin'];

    if (menuPosition && menuPosition?.split(' ')?.[1] === 'bottom') {
      // empty
    }

    if (menuPosition && menuPosition?.split(' ')?.[1] === 'top') {
      // there is not enough place for the expanded window: change Y coord to be placed higher
      let posY: number = +this.contextMenuPosition.y.split('px')[0];
      if (posY + EXPANDED_EMOJI_PANEL_HEIGHT > this.document.documentElement.scrollHeight) {
        this.contextMenuPosition.y =
          this.document.documentElement.scrollHeight - EXPANDED_EMOJI_PANEL_HEIGHT + 'px';
      }
    }
    this.isExpanded = true;
  };

  @HostListener('window:mouseup', ['$event'])
  mouseUp(e) {
    if (this.isSelecting) {
      this.isSelecting = false;
    }
  }

  @HostListener('document:selectionchange', ['$event'])
  selectionChange(e) {
    //TODO: re-implement when chat message grouping refactor is done. Until then it's pointless to always fix it when it breaks.
    return;

    if (!this.isSelecting) return;

    var selection = window.getSelection();

    if (selection.anchorNode == null || selection.focusNode == null) return;

    var startSelectionNode = this.getChatElementInParents(selection.anchorNode);
    var endSelectionNode = this.getChatElementInParents(selection.focusNode);

    if (startSelectionNode && endSelectionNode) {
      if (startSelectionNode != endSelectionNode)
        this.selectChatMessagesBetweenElements(startSelectionNode, endSelectionNode);
      else if (this.selectedChatMessages.length > 0)
        //only run when not starting selection process
        this.selectFirstChatMessageDuringDeselect(startSelectionNode);
    }

    if (this.selectedChatMessages.length > 0 && !this.copySnackbarShowing) {
      this.copySnackbarShowing = true;
      this.copySnackBarRef = this.snackbar.openFromComponent(CopyChatMessagesSnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['mat-toolbar', 'mat-primary', 'nano-snackbar'],
      });
      this.copySnackBarRef.onAction().subscribe((ret) => {
        this.copySelectedMessages();
      });
      this.copySnackBarRef.afterDismissed().subscribe(() => {
        this.copySnackbarShowing = false;
        this.clearPreviousSelection();
        window.getSelection().removeAllRanges();
      });
    }
  }

  @HostListener('document:copy', ['$event']) onCtrlC(event) {
    if (this.selectedChatMessages.length > 0) {
      event.preventDefault();
      this.copySelectedMessages();
      this.copySnackBarRef?.dismiss();
    }
  }

  @HostListener('document:keydown.arrowup', ['$event']) editFirstOwnMessage(event: KeyboardEvent) {
    var firstOwnMessage = this.chatMessages.find((cm) => cm.account == this.me);
    if (firstOwnMessage && this.canEnterEditOnUpArrow && this.isChatInputFocused) {
      this.setEditedMessage(firstOwnMessage);
    }
  }

  @HostListener('document:keydown.escape', ['$event']) closeEditedMessage(event: KeyboardEvent) {
    if (this.editedMessage) {
      this.restoreChatMessage();
    }
    if (this.repliedMessage) {
      this.closeReplyBar();
    }
  }

  private copySelectedMessages() {
    const foundMsgArray: ChatData[] = [];
    for (var scm of this.selectedChatMessages.reverse()) {
      const messageId = scm.getAttribute('id');
      if (messageId) {
        // var name = scm.querySelector('.message-header .name').innerHTML;
        // var date = scm.querySelector('.message-header .date').innerHTML;
        const foundMsg = this.chatMessages.find((msg) => msg.id === messageId);
        foundMsgArray.push(foundMsg);
      }
    }

    const copyText = this.parseCopiedMessages(foundMsgArray);

    this.clipboardService
      .copy(copyText.trim())
      .then(() => {
        this.snackbarService.showSnackbar(marker('Messages copied to clipboard!'));
      })
      .catch((err) => {
        console.error('clipboard err', err);
        this.dialogService.openAlertDialog(
          marker('Copy Error'),
          marker('Could not copy to the clipboard')
        );
      });
  }

  private parseCopiedMessages = (messageArray: ChatData[]) => {
    const msgArray = [];
    for (let message of messageArray) {
      const name = message.account.avatarName;
      const date = formatDate(message.date, 'short', AppStorage.getItem('lang'));

      if (message?.message?.length === 0) continue;

      msgArray.push({ text: `${name}, [${date}] \n${message.message}`, date: message.date });
    }
    const sortedMsgArray = msgArray.sort((msg1, msg2) => msg1.date - msg2.date);
    const copyText: string = sortedMsgArray.reduce((sum, msg) => (sum += '\n' + msg.text), '');

    return copyText;
  };

  onInputChange(message: InputSubmitEvent): void {
    if (!this.editedMessage) {
      this.lastUnsentMessage = message.text;
    }

    if (message && message.text.length > 0) {
      this.canEnterEditOnUpArrow = false;
    } else {
      this.canEnterEditOnUpArrow = true;
    }

    if (this.isTypingTimer || message['target'] || message.text.length == 0) {
      //sometimes we get a 'change' HTMLElement event, we must ignore that
      return;
    } else {
      this.isTypingTimer = setTimeout(() => (this.isTypingTimer = null), 5000);
      this.service.sendTyping(this.resourceId);
    }
  }

  onFocusChanged(isFocused: boolean): void {
    this.isChatInputFocused = isFocused;
    if (
      isFocused &&
      this.chatScroll &&
      this.chatScroll.nativeElement.scrollTop != 0 &&
      this.deviceDetectorService.isMobile()
    ) {
      setTimeout(() => {
        this.chatScroll.nativeElement.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 500); // wait for keyboard shows up
    }
  }

  /**
   * sets the grouping properties of the "index"-th chatMessage if possible
   *
   * @param index index of message in this.chatMessages
   */
  private setGroupingProperties = (index: number) => {
    // const p = performance.now();
    const msg = this.chatMessages?.[index];
    if (msg?.id) {
      msg.isInGroup = this.chatMessageIsInGroup(index);
      msg.hasLineBreak = !this.isCompactDisplay && !this.chatMessageIsInGroup(index - 1);
      msg.isOwnMessage = msg.account.id === this.me.id;
    }
  };

  getSelectedChatMessagePolicy = () => {
    //number: (d1: any, d2: any) => boolean
    const chatMessageComparePolicies = {
      [ChatMessageGroupingPolicy.sameYear]: (d1, d2) => d1.year === d2.year,
      [ChatMessageGroupingPolicy.sameMonth]: (d1, d2) =>
        d1.year === d2.year && d1.month === d2.month,
      [ChatMessageGroupingPolicy.sameDay]: (d1, d2) =>
        d1.year === d2.year && d1.month === d2.month && d1.day === d2.day,
      [ChatMessageGroupingPolicy.sameHour]: (d1, d2) =>
        d1.year === d2.year && d1.month === d2.month && d1.day === d2.day && d1.hour === d2.hour,
      [ChatMessageGroupingPolicy.sameMinute]: (d1, d2) =>
        d1.year === d2.year &&
        d1.month === d2.month &&
        d1.day === d2.day &&
        d1.hour === d2.hour &&
        d1.minute === d2.minute,
      [ChatMessageGroupingPolicy.in1Day]: (d1, d2) =>
        Math.abs(d1.time - d2.time) < 1000 * 60 * 60 * 24,
      [ChatMessageGroupingPolicy.in3Hours]: (d1, d2) =>
        Math.abs(d1.time - d2.time) < 1000 * 60 * 60 * 3,
      [ChatMessageGroupingPolicy.in2Hours]: (d1, d2) =>
        Math.abs(d1.time - d2.time) < 1000 * 60 * 60 * 2,
      [ChatMessageGroupingPolicy.in1Hour]: (d1, d2) =>
        Math.abs(d1.time - d2.time) < 1000 * 60 * 60 * 1,
      [ChatMessageGroupingPolicy.in30Minutes]: (d1, d2) =>
        Math.abs(d1.time - d2.time) < 1000 * 60 * 30,
      [ChatMessageGroupingPolicy.in10Minutes]: (d1, d2) =>
        Math.abs(d1.time - d2.time) < 1000 * 60 * 10,
      [ChatMessageGroupingPolicy.in5Minutes]: (d1, d2) =>
        Math.abs(d1.time - d2.time) < 1000 * 60 * 5,
    };

    const selectedChatMessageGroupingPolicy =
      AppStorage.getItem('chatGroupingPolicy') ?? ChatMessageGroupingPolicy.sameDay;

    const comparePolicy = chatMessageComparePolicies?.[selectedChatMessageGroupingPolicy];

    return comparePolicy;
  };

  chatMessageIsInGroup = (index) => {
    if (index > -1 && index <= this.chatMessages.length) {
      const msg = this.chatMessages[index];

      const prevMessage = this.chatMessages?.[index + 1];
      if (prevMessage && msg && prevMessage.account?.id === msg.account?.id) {
        const prevMessageTimes = {
          time: prevMessage.date.getTime(),
          year: prevMessage.date.getFullYear(),
          month: prevMessage.date.getMonth(),
          day: prevMessage.date.getDate(),
          hour: prevMessage.date.getHours(),
          minute: prevMessage.date.getMinutes(),
        };
        const msgTimes = {
          time: msg.date.getTime(),
          year: msg.date.getFullYear(),
          month: msg.date.getMonth(),
          day: msg.date.getDate(),
          hour: msg.date.getHours(),
          minute: msg.date.getMinutes(),
        };

        if (this.chatMessageComparePolicy) {
          return this.chatMessageComparePolicy(prevMessageTimes, msgTimes);
        } else {
          console.error(
            'Error while calculating comparePolicy for message',
            msg,
            prevMessage,
            msgTimes,
            prevMessageTimes
          );
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  public navigateToDrive = () => {
    this.routerHandler.navigate(['/room/' + this.resourceId + '/drive'], {
      fragment: this.routerHandler.getRoute().rawFragment,
    });
  };

  public addReaction(msgId, reaction: ReactionEnum | null) {
    const foundMsg = this.chatMessages.find((msg) => msg.id === msgId);
    if (foundMsg) {
      if (foundMsg.myReaction === reaction) {
        this.service.reactMessage(this.resourceId, msgId, null);
      } else {
        this.service.reactMessage(this.resourceId, msgId, reaction);
      }
    }
  }

  public pinMessage(msgId) {
    return this.service.pinMessage(this.resourceId, msgId).catch((err) => {
      console.error('pin error', err);
      this.dialogService.openAlertDialog(
        marker('Pin error'),
        marker('Error happened during the operation'),
        err
      );
    });
  }

  private openContextMenu(event: MouseEvent) {
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.context_trigger.openMenu();
  }

  public isDriveAvailable: boolean = false;
  private checkDriveStatus() {
    if (this.type == ResourceType.DIALOGUE) {
      this.isDriveAvailable = false;
      return;
    }

    this.roomService
      .getNanoSession(this.resourceId)
      .then((nanoSession) => {
        if (nanoSession) {
          this.isDriveAvailable = true;
        } else {
          this.isDriveAvailable = false;
        }

        this.isDocumentEditorSupported = isNanoFeatureSupported(
          nanoSession.version,
          NanoFeature.DOCUMENT
        );
      })
      .catch((err) => {
        this.isDriveAvailable = false;
      });
  }

  public canOpenInEditor(): boolean {
    var attachedFile: AttachedFileUrl = <AttachedFileUrl>this.contextTargetMessage.event['fileUrl'];
    return (
      this.isDocumentEditorSupported &&
      EditorSupportedFileExtensions.some((ext) => attachedFile.file.endsWith(ext))
    );
  }

  get topVisibleMessage(): ChatData {
    return this.visibleMessages[this.visibleMessages.length - 1];
  }
}
