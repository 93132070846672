import { Pipe, PipeTransform } from '@angular/core';
import { ByteHumanizer } from '../humanizers/byte-humanizer';

@Pipe({
  name: 'formatBytes',
})
export class FormatBytesPipe implements PipeTransform {
  transform(bytes: number, decimals: number = 2): string {
    return ByteHumanizer.humanize(bytes, decimals);
  }
}
