import msgpack from 'msgpack-lite';
import { ID } from '../../server-services/query-records/common-records';
import { RoomData } from '../../server-services/query-records/room-records';
import { RoomConfigDumpArgs, RoomConfigLoadArgs } from '../context/room/args';
import { ROOM_CRYPTO_CONTEXT } from '../context/room/__init__';
import { getTimeFromId } from '../context/timestamp';
import { RoomKeyring } from '../keyring/room';

export class RoomCrypto {
  public static encrypt(data: RoomData, roomId: ID, room_kr: RoomKeyring): Promise<Uint8Array> {
    let encodedMessage = msgpack.encode(data);

    return ROOM_CRYPTO_CONTEXT.dump(
      new RoomConfigDumpArgs(encodedMessage, room_kr, getTimeFromId(roomId))
    );
  }

  public static decrypt(data: Uint8Array, roomId: ID, room_kr: RoomKeyring): Promise<RoomData> {
    return ROOM_CRYPTO_CONTEXT.load(new RoomConfigLoadArgs(data, room_kr, getTimeFromId(roomId)))
      .then((loadedData) => {
        return msgpack.decode(loadedData);
      })
      .catch((err) => {
        return Promise.reject({
          message: 'Decryption Error',
          err,
        });
      });
  }
}
