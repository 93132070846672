import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

export interface RenameExistingFileDialogData {
  name: string;
}

export enum NamingConventionEnum {
  NEW_NAME,
  OVERWRITE,
  AUTO_NAME,
}

@Component({
  selector: 'app-rename-existing-file-dialog',
  templateUrl: './rename-existing-file-dialog.component.html',
  styleUrls: ['./rename-existing-file-dialog.component.scss'],
})
export class RenameExistingFileDialogComponent extends NanoDialogBaseComponent<RenameExistingFileDialogComponent> {
  // public fileNameControl = new FormControl<string>('', [Validators.required]);
  public namingConventionControl = new FormControl<NamingConventionEnum>(
    NamingConventionEnum.AUTO_NAME
  );
  public NamingConventionEnum = NamingConventionEnum;

  public extension: string = '';
  public originalName: string = '';

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: RenameExistingFileDialogData
  ) {
    super(injector);

    // const lastPeriod = data.name.lastIndexOf('.');
    // if (lastPeriod > 0) {
    //   this.fileNameControl.setValue(data.name.substring(0, lastPeriod));
    //   this.extension = data.name.substring(lastPeriod, this.data.name.length);
    // } else {
    //   this.fileNameControl.setValue(data.name);
    // }
    // this.originalName = this.fileNameControl.value;

    // this.fileNameControl.valueChanges.subscribe((val) => {
    //   if (this.originalName !== val) this.namingConventionControl.disable();
    //   else if (this.namingConventionControl.disabled) this.namingConventionControl.enable();
    // });
  }

  public close(): void {
    // const newName = this.fileNameControl.value + this.extension;
    // const namingConvention =
    //   this.fileNameControl.value === this.originalName
    //     ? this.namingConventionControl.value
    //     : NamingConventionEnum.NEW_NAME;

    this.dialogRef.close({ namingConvention: this.namingConventionControl.value });
  }
}
