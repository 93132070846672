import { Component, Input } from '@angular/core';
import { DriveFile } from '../../drive-file';

@Component({
  selector: 'app-file-info',
  templateUrl: './file-info.component.html',
  styleUrls: ['./file-info.component.scss'],
})
export class FileInfoComponent {
  @Input() file: DriveFile;

  constructor() {}
}
