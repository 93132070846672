import {
  AccountAvatarRecord,
  MeRecord,
} from '../../../../../../../../server-services/query-records/account-records';
import { WorkspaceSubscriptionAccountEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import { getAccountQuery, getMeQuery } from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onModifyAccountEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionAccountEventRecord
) => {
  let user: AccountAvatarRecord = cache.getCacheDataByRequest({
    query: getAccountQuery,
    variables: {
      id: event.id,
    },
  });

  let me: MeRecord = cache.getCacheDataByRequest({ query: getMeQuery, variables: {} });

  if (event.avatarImageKey) {
    user.avatarImageKey = event.avatarImageKey;
    if (me.id == user.id) {
      me.avatarImageKey = event.avatarImageKey;
    }
  }
  if (event.avatarName) {
    user.avatarName = event.avatarName;
    if (me.id == user.id) {
      me.avatarName = event.avatarName;
    }
  }
  if (event.walletAddress !== undefined) {
    me.walletAddress = event.walletAddress;
  }
};
