import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateResourceGroupEventSubscriptionHandler } from './ws-sub-resource-group-event-create-handler';
import { onDeleteResourceGroupEventSubscriptionHandler } from './ws-sub-resource-group-event-delete-handler';
import { onModifyResourceGroupEventSubscriptionHandler } from './ws-sub-resource-group-event-modify-handler';

export let workspaceSubscriptionResourceGroupEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateResourceGroupEventSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyResourceGroupEventSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteResourceGroupEventSubscriptionHandler,
};
