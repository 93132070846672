import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pathToArray',
})
export class PathToArrayPipe implements PipeTransform {
  public stack: string[];

  transform(value: string): string[] {
    if (!value) return [];
    this.stack = value.split('/');
    return this.stack;
  }
}
