import { Component, HostListener, Injector } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-nano-dialog-base',
  template: '',
})
export class NanoDialogBaseComponent<ChildDialogComponent> {

  protected dialogRef = this.injector.get(MatDialogRef<ChildDialogComponent>);
  protected dialog = this.injector.get(MatDialog);

  constructor(private injector: Injector) {}

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.closeTopDialog();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.closeTopDialog();
    event.stopPropagation();
  }

  private closeTopDialog(): void{
    let dialogCount = this.dialog.openDialogs.length
    if(dialogCount > 1){
      this.dialog.openDialogs[dialogCount - 1].close();
    } else{
      this.dialogRef.close();
    }
  }
}
