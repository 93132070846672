import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import {
  FILE_PICKER_DIALOG_ROOT,
  FilePickerDialogComponent,
  FilePickerDialogResult,
} from 'src/app/shared/dialogs/file-picker-dialog/file-picker-dialog.component';
import { NanoFeature, isNanoFeatureSupported } from 'src/app/shared/drive-version';
import { RoomService } from 'src/app/shared/server-services/room.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NativeAppService } from 'src/app/shared/services/native-app.service';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { DriveFile } from '../../drive-window/drive-layout/drive-file';
import { focusChatInput } from '../focus-chat-input-observer';
import { InputSubmitEvent } from '../input-submit-event';
import {
  MAX_CHAT_ATTACHMENT,
  MAX_CHAT_MESSAGE_LENGTH,
  MAX_SPLITTED_MESSAGE,
} from './message-consts';
import { MessageEditorBase } from './message-editor-base';
import { NativeEditorComponent } from './native-editor/native-editor.component';
import { SlateEditorComponent } from './slate-editor/slate-editor.component';

@Component({
  selector: 'app-chat-message-editor',
  templateUrl: './chat-message-editor.component.html',
  styleUrls: ['./chat-message-editor.component.scss'],
})
export class ChatMessageEditorComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() content?: string = ''; // initial content
  @Input() isMsgEdit?: boolean = false;
  @Input() autofocus?: boolean = false; // autofocus
  @Input() canUpload?: boolean = false;
  @Input() resourceId?: boolean = false; // required for the upload process
  @Input() isDriveAvailable?: boolean = false;

  @Output() readonly submitEvent = new EventEmitter<InputSubmitEvent>();
  @Output() readonly changeEvent = new EventEmitter<InputSubmitEvent>();
  @Output() readonly closeEvent = new EventEmitter<boolean>();
  @Output() readonly focusChangeEvent = new EventEmitter<boolean>();

  @ViewChild('emojiBtn', { read: ElementRef }) emojiBtn: ElementRef;

  @ViewChild(SlateEditorComponent) slateEditor: SlateEditorComponent;
  @ViewChild(NativeEditorComponent) nativeEditor: NativeEditorComponent;

  public toggledEmojiPopup: boolean = false;
  public messageLengthTooLong: boolean = false;
  public messageMaxLength: number = MAX_CHAT_MESSAGE_LENGTH * MAX_SPLITTED_MESSAGE;
  public attachments: DriveFile[] = [];
  public isOnDesktop: boolean = true; // on mobile the enter input should line break instead of form submit
  private focusChatInputSubsctiption: Subscription;
  public messageSending: boolean = false;
  public isOnApp: boolean;

  constructor(
    private ref: ElementRef,
    private deviceService: DeviceDetectorService,
    private routerHandler: RouterHandler,
    public dialog: MatDialog,
    private roomService: RoomService,
    private dialogService: DialogService,
    private nativeAppService: NativeAppService
  ) {
    this.isOnDesktop = this.deviceService.isDesktop();
    this.isOnApp = this.nativeAppService.isOnApp();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.isOnDesktop) {
      this.focusChatInputSubsctiption = focusChatInput.subscribe(() => {
        this.getEditor().focus();
      });
    }

    this.getEditor().onKeyDown(this.onKeyDown);
    this.getEditor().onFocusChange((active) => {
      this.focusChangeEvent.emit(active);
    });

    if (this.content && this.content.length > 0) {
      this.getEditor().setContent(this.content);
    }

    if (this.autofocus) {
      this.getEditor().focus();
    }

    this.getEditor().onChange(this.onInputChange);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['content'] && !changes['content'].isFirstChange()) {
      this.getEditor().setContent(changes['content'].currentValue || '');
    }
  }

  // routerChange = (res) => {
  //   if (this.autofocus && this.isOnDesktop) {
  //     this.getEditor().focus();
  //   }

  //   if (!this.isMsgEdit) {
  //     // on router change reset new message field, leave msg editor fields
  //     this.getEditor().emptyEditor();
  //     this.attachments = [];
  //   }
  // };

  public getEditor(): MessageEditorBase {
    return this.slateEditor || this.nativeEditor;
  }

  public addAttachment(event) {
    var files: FileList =
      event.target?.files || event.dataTransfer?.files || event.clipboardData?.files || [];

    for (var i = 0; i < files.length; i++) {
      if (this.attachments.length < MAX_CHAT_ATTACHMENT) {
        this.attachments.push(new DriveFile(files[i]));
      } else {
        this.dialogService.openAlertDialog(
          marker('Too many attachment'),
          marker('Maximum attachment reached: ') + MAX_CHAT_ATTACHMENT
        );
        return;
      }
    }
  }

  public removeAttachment(file: DriveFile) {
    let index = this.attachments.indexOf(file);
    if (index > -1) {
      this.attachments.splice(index, 1);
    }
  }

  public addDriveFiles() {
    const dialogRef = this.dialog.open(FilePickerDialogComponent, {
      data: {
        roomId: this.resourceId,
        multiSelection: true,
        title: marker('Select attachments'),
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: FilePickerDialogResult) => {
      if (!result) return;

      for (let i = 0; i < result.files.length; i++) {
        if (this.attachments.length < MAX_CHAT_ATTACHMENT) {
          if (result.files[i] !== FILE_PICKER_DIALOG_ROOT) {
            //result.files[i].path = path + result.files[i].name;
            this.attachments.push(result.files[i]);
          } else {
            this.dialogService.openAlertDialog(
              marker('Wrong attachment'),
              marker('Can not attach drive root')
            );
          }
        } else {
          this.dialogService.openAlertDialog(
            marker('Too many attachment'),
            marker('Maximum attachment reached: ') + MAX_CHAT_ATTACHMENT
          );
          return;
        }
      }
    });
    return;
  }

  public loadEmojiPicker: boolean = false;
  public onToggleEmojiPopup() {
    if (!this.loadEmojiPicker) {
      this.loadEmojiPicker = true;
    }
    this.toggledEmojiPopup = !this.toggledEmojiPopup;
    if (this.toggledEmojiPopup) {
      history.pushState({ emoji: true }, '');
    }
    this.getEditor().focus();
  }

  public handleEmojiInsert(ev) {
    this.getEditor().insertEmoji(ev);
    this.getEditor().focus();
  }

  public onKeyDown = (e: KeyboardEvent) => {
    if (e.key == 'Escape') {
      this.closeEvent.emit(true);
    } else if (e.key == 'Enter') {
      if (e.altKey || e.ctrlKey || e.shiftKey || !this.isOnDesktop) {
        // just insert enter normally
      } else {
        e.preventDefault(); // prevent inserting enter
        this.submit();
      }
    }
  };

  public onInputChange = (content: string) => {
    this.messageLengthTooLong = content.length > this.messageMaxLength;
    this.changeEvent.emit({ text: content, attachments: this.attachments, uploadPath: null });
  };

  public submit() {
    if (this.messageLengthTooLong) return;

    let isThereFileToUpload = this.attachments.find((element) => !element.resourceId);

    let push = (uploadPath) => {
      this.submitEvent.emit({
        text: this.getEditor().getContent(),
        attachments: this.attachments,
        uploadPath,
      });
      this.getEditor().emptyEditor();
      this.attachments = [];
    };

    if (!isThereFileToUpload) {
      // there is no file to upload
      push(null);
    } else {
      this.isDriveSupportAutoUploadLocation()
        .then((isAutoUploadLocationSupported) => {
          if (isAutoUploadLocationSupported) {
            // just send, it will be okay
            push(null);
          } else {
            // choose a destination
            const dialogRef = this.dialog.open(FilePickerDialogComponent, {
              data: {
                roomId: this.resourceId,
                onlyDirectory: true,
                title: marker('Select upload destination'),
              },
              autoFocus: false,
            });
            dialogRef.afterClosed().subscribe((result: FilePickerDialogResult) => {
              console.log('result', result);
              if (!result) {
                this.dialogService.openAlertDialog(
                  marker('Destination error'),
                  marker('Upload destination folder must be selected to upload files.')
                );
                return;
              }

              if (result.files.length == 0) {
                this.dialogService.openAlertDialog(
                  marker('Destination error'),
                  marker('There is no selected folder.')
                );
                return;
              }

              if (!result.files[0]) {
                // root
                push('');
              } else {
                // folder
                let path = result.files[0].fullName;
                if (result.path.length > 0) {
                  path = result.path + '/' + path;
                }
                push(path);
              }
            });
          }
        })
        .catch((e) => {
          this.dialogService.openAlertDialog(
            marker('Drive is not found'),
            marker('Drive is not found, can not start to upload attachments'),
            e.error
          );
        });
    }
  }

  private isDriveSupportAutoUploadLocation(): Promise<boolean> {
    return this.roomService.getNanoSession(this.resourceId).then((nanoSession) => {
      if (nanoSession) {
        return isNanoFeatureSupported(nanoSession.version, NanoFeature.AUTO_NAME);
      } else {
        throw 'Drive is not found';
      }
    });
  }

  ngOnDestroy(): void {
    this.focusChatInputSubsctiption && this.focusChatInputSubsctiption.unsubscribe();
  }

  @HostListener('drop', ['$event'])
  async onDrop(event) {
    this.addAttachment(event);
  }

  @HostListener('paste', ['$event'])
  async onPaste(event) {
    /**
     * add fromClipboard to the file from safari
     * more info: UploadSession.freezedMTime
     */
    var files: FileList =
      event.target?.files || event.dataTransfer?.files || event.clipboardData?.files || [];

    for (var i = 0; i < files.length; i++) {
      files[i]['fromClipboard'] = true;
    }
    this.addAttachment(event);
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    if (this.loadEmojiPicker) {
      var emojiPickerElement = this.ref.nativeElement.querySelector('app-emoji-picker');

      var emojiButtonElement = this.emojiBtn.nativeElement;

      if (
        !emojiPickerElement.contains(event.target) &&
        !emojiButtonElement.contains(event.target)
      ) {
        this.toggledEmojiPopup = false;
      }
    }
  }

  @HostListener('window:popstate', ['$event'])
  onWindowPopState($event) {
    this.toggledEmojiPopup = false;
  }

  public useCamera() {
    this.nativeAppService
      .useCamera()
      .then((webPath) => {
        let name = webPath.split('/').pop();
        return fetch(webPath)
          .then((r) => r.blob())
          .then((b) => new File([b], name))
          .then((f) => {
            this.addAttachment({ target: { files: [f] } });
          });
      })
      .catch((err) => {
        console.error('err', err);
      });
  }
}
