import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DriveFile } from 'src/app/components/resource-page/drive-window/drive-layout/drive-file';
import { FilePropertiesDialogComponent } from 'src/app/shared/dialogs/file-properties-dialog/file-properties-dialog.component';
import { NanoFeature, isNanoFeatureSupported } from 'src/app/shared/drive-version';
import { MediaViewerService } from 'src/app/shared/media-viewer/media-viewer.service';
import { NanoService, PeekFileParam } from 'src/app/shared/nano/nano.service';
import { RoomRecord } from 'src/app/shared/server-services/query-records/room-records';
import { RoomService } from 'src/app/shared/server-services/room.service';
import { ServerError } from 'src/app/shared/server-services/server-errors';
import { BlobService } from 'src/app/shared/services/blob.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DownloadManagerService } from 'src/app/shared/services/download-manager-service';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';

export type AttachedFileUrl = {
  file: string;
  path: string;
  roomId: string;
  width?: number;
  height?: number;
  raw: string;
};

@Component({
  selector: 'app-markdown-file-preview',
  templateUrl: './markdown-file-preview.component.html',
  styleUrls: ['./markdown-file-preview.component.scss'],
})
export class MarkdownFilePreviewComponent implements OnInit {
  @Input() fileUrl: AttachedFileUrl;
  public file: DriveFile;
  public roomData: RoomRecord;
  public isLoaded: boolean = false; // load the file info from nano
  public isFileValid: boolean;
  public fileCheckError: boolean = false;
  private isDownloadStarted: boolean = false;
  public downloadProgress: number = 0;
  public isDir: boolean = false;
  // thumbnail response from the server
  public img0: Blob;
  public preview: Blob;

  public dim: Object;
  public pathString: string;

  constructor(
    private roomService: RoomService,
    private nanoService: NanoService,
    private routerHandler: RouterHandler,
    private downloadManagerService: DownloadManagerService,
    private dialogService: DialogService,
    private mediaViewerService: MediaViewerService,
    private blobService: BlobService,
    private snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.file = new DriveFile({
      name: this.fileUrl.file,
      size: 0,
      mtime: 0,
      path: this.fileUrl.path,
      resourceId: this.fileUrl.roomId,
    });

    if (this.file.path.length > 0) {
      this.pathString = '(DriveRoot)/' + this.file.path + this.file.fullName;
    } else {
      this.pathString = '(DriveRoot)/' + this.file.fullName;
    }

    if (this.fileUrl.width && this.fileUrl.height) {
      this.dim = {
        'aspect-ratio': this.fileUrl.width / this.fileUrl.height,
        width: this.fileUrl.width + 'px',
        'max-width': 'min(100%, 160%*' + this.fileUrl.width / this.fileUrl.height + ')',
        'max-height': 'min(' + this.fileUrl.height + 'px,60vh)',
      };
    }
  }

  public openPathString() {
    this.routerHandler.navigate(['room', this.file.resourceId, 'drive'], {
      fragment: this.routerHandler.fragmentToRaw({
        path: this.file.path,
        file: this.file.fullName,
      }),
    });
  }

  private peekOptions: PeekFileParam;
  public peek() {
    this.roomService.getRoom(this.fileUrl.roomId).then((roomData) => {
      this.roomData = roomData;

      this.roomService.getNanoSession(roomData.id).then((nanoSession) => {
        if (nanoSession && isNanoFeatureSupported(nanoSession.version, NanoFeature.PEEK)) {
          this.peekOptions = {
            resourceId: this.fileUrl.roomId,
            path: (this.fileUrl.path ? this.fileUrl.path + '/' : '') + this.fileUrl.file,
            responseCallback: (file) => {
              this.file.size = file.size;
              this.file = new DriveFile(Object.assign(this.file, file));
              this.isLoaded = true;
              this.isFileValid = true;
              this.isDir = this.file.ext == 'dir';
              if (file.img0) this.img0 = this.blobService.new([file.img0.buffer]);
              if (file.preview) this.preview = this.blobService.new([file.preview]);
              //console.log("Peek file", file);
            },
            errorCallback: (err) => {
              if (err.error == ServerError.NANO_REQUEST_ERROR_HANDLER_TARGET_NOT_FOUND) {
                this.isLoaded = true;
                this.isFileValid = false;
              } else {
                this.fileCheckError = true;
                this.isLoaded = true;
              }
              console.error('err', err);
            },
            nanoVersion: nanoSession.version,
          };

          this.nanoService.peekFile(this.peekOptions);
        }
      });
    });
  }

  public openPath() {
    let fragment = this.routerHandler.getRoute().fragment;

    if (this.isDir) {
      fragment['path'] = this.fileUrl.path + '/' + this.file.name;
    } else {
      if (this.fileUrl.path) {
        fragment['path'] = this.fileUrl.path;
      }
      fragment['file'] = this.fileUrl.file;
    }

    this.routerHandler.navigate(['/room/', this.fileUrl.roomId, 'drive'], {
      fragment: this.routerHandler.fragmentToRaw(fragment),
    });
  }

  public openFile() {
    if (this.file.mime.startsWith('image')) {
      let path = this.file.fullName;
      if (this.fileUrl.path.length > 0) {
        path = this.fileUrl.path + '/' + path;
      }
      if (this.roomData) {
        this.mediaViewerService.showImage(this.roomData.id, path, this.file);
      }
    } else {
      this.openDetails();
    } /*else if (
      this.file.mime == "application/pdf"
    ) {
      this.mediaViewerService.showPdf(
        this.roomData.id,
        this.fileUrl.path + "/" + this.file.fullName,
        this.file
      );
    }*/
  }

  public download() {
    if (!this.isDownloadStarted) {
      this.isDownloadStarted = true;
      this.downloadManagerService
        .download(this.fileUrl.roomId, this.file)
        .then(() => {
          const fileRef = this.downloadManagerService
            .getFileList()
            .find((fileRef) => fileRef.file === this.file);
          fileRef.downloadObservable$.subscribe({
            next: () => (this.downloadProgress = fileRef.progress),
          });
        })
        .catch(() => {
          this.dialogService.openAlertDialog(
            marker('Error'),
            marker('Error happened during the file download')
          );
        })
        .finally(() => {
          this.isDownloadStarted = false;
        });
    } else {
      this.snackbarService.showSnackbar(marker('Download already started!'));
    }
  }

  public openDetails() {
    const dialogRef = this.dialog.open(FilePropertiesDialogComponent, {
      data: { file: this.file },
      hasBackdrop: true,
      disableClose: false,
      autoFocus: false,
    });
  }

  private peekStarted: boolean = false;
  public visibilityChange(visible) {
    if (visible && !this.peekStarted) {
      this.peekStarted = true;
      this.peek();
    } else {
      if (!this.isLoaded) {
        if (this.peekOptions) {
          this.nanoService.unsubscribeFromPeek(this.peekOptions);
        }
        this.peekStarted = false;
      }
    }
  }

  /**
   * attach fileUrl to the event so we can use this in the showed context menu
   */
  public onContextMenu(event) {
    event['fileUrl'] = this.fileUrl;
  }
}
