import { ConditionForFetchHandler } from '../../cache-logic-interface';

export let conditionFetchForRoomWithData: ConditionForFetchHandler = function (
  cache,
  cacheKey,
  request
) {
  let room = cache.getCacheData(cacheKey);
  return !room || room['__refetch'];
};
