import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppStorage } from 'src/app/shared/app-storage';
import { ReactionDialogComponent } from 'src/app/shared/dialogs/reaction-dialog/reaction-dialog.component';
import { ResizedEvent } from 'src/app/shared/directives/resized.directive';
import {
  ReactionEnum,
  reactionIndexSrcMap,
} from 'src/app/shared/server-services/query-records/room-records';
import { ChatData } from '../chat-data';

const customToolTipOptions: MatTooltipDefaultOptions = {
  showDelay: 200,
  hideDelay: 0,
  // touchGestures: 'auto',
  // position: 'before',
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};

export type ChatMessageContextMenuData = {
  event: MouseEvent;
  data: ChatData;
};

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customToolTipOptions }],
})
export class ChatMessageComponent implements OnInit {
  @Input() data: ChatData;
  @Input() runSeenAnimation: boolean = false;
  @Output() readonly addReactionEvent: EventEmitter<ReactionEnum> =
    new EventEmitter<ReactionEnum>();
  @Output() readonly resizedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly contextmenuevent: EventEmitter<ChatMessageContextMenuData> =
    new EventEmitter<ChatMessageContextMenuData>();
  @Output() readonly jumpToMessageEvent: EventEmitter<string> = new EventEmitter<string>();

  isCompactDisplay = AppStorage.getItem('messageDisplay') === 'compact';

  public isOnDesktop: boolean = false;
  public isTrustVerified: boolean = false;
  public isTrustDangerous: boolean = false;
  public reactionSrcMap = reactionIndexSrcMap;
  public highlighted: boolean = false;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {
    // calculate if the message is editable

    this.isCompactDisplay = AppStorage.getItem('messageDisplay') === 'compact';
    this.isOnDesktop = this.deviceDetectorService.isDesktop();
  }

  ngOnInit(): void {
    this.isCompactDisplay = AppStorage.getItem('messageDisplay') === 'compact';
  }

  public handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();

    this.contextmenuevent.emit({ event, data: this.data });
  };

  public handleLongPress = (event) => {
    this.contextmenuevent.emit({ event, data: this.data });
  };

  public onResized(event: ResizedEvent) {
    this.resizedEvent.emit();
  }

  public jumpToMessage(messageId: string): void {
    this.jumpToMessageEvent.emit(messageId);
  }

  // public addReaction(reaction: ReactionEnum | null) {
  //   this.addReactionEvent.emit(reaction);
  //   setTimeout(() => {
  //     this.hideReactionDialog();
  //   }, 100);
  // }

  public openReactionDialog = () => {
    if (!Object.keys(this.data.messageRef.reactions ?? {}).length) {
      return;
    }
    let dialogRef = this.dialog.open(ReactionDialogComponent, {
      data: {
        reactions: this.data.messageRef.reactions,
      },
      hasBackdrop: true,
      disableClose: false,

      width: '90%',
      minWidth: '320px',
      maxWidth: '400px',
      height: '80%',
      minHeight: '500px',
    });
  };

  public runHighlightAnimation(): void {
    this.highlighted = true;
    this.cdr.detectChanges();

    setTimeout(() => {
      this.highlighted = false;
      this.cdr.detectChanges();
    }, 1000);
  }

  // public expandReactionDialog(event) {
  //   this.isReactionBoxExpanded = event;
  // }

  // public hideReactionDialog() {
  //   this.showReactions = false;
  //   this.isReactionBoxExpanded = false;
  // }
}
