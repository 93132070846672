import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

@Component({
  selector: 'app-delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss'],
})
export class DeleteAccountDialogComponent extends NanoDialogBaseComponent<DeleteAccountDialogComponent> {
  public confirmed = false;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public translate: TranslateService
  ) {
    super(injector);
  }

  changeConfirmed(value) {
    this.confirmed = value;
  }

  deleteAccount() {
    this.closeWithOk();
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  closeWithOk() {
    this.dialogRef.close(true);
  }
}

interface DialogData {
  title: string;
  text: string;
  okButtonText?: string;
  cancelButtonText?: string;
}
