import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

@Component({
  selector: 'app-general-input-dialog',
  templateUrl: './general-input-dialog.component.html',
  styleUrls: ['./general-input-dialog.component.scss'],
})
export class GeneralInputDialogComponent extends NanoDialogBaseComponent<GeneralInputDialogComponent> {
  public inputValue: string;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public generalInputData: GeneralInputDialogData,
    public translate: TranslateService
  ) {
    super(injector);
    this.inputValue = this.generalInputData.input;
  }

  submit() {
    this.dialogRef.close(this.inputValue);
  }
}

export interface GeneralInputDialogData {
  title: string;
  text: string;
  input: string;
  canBeEmpty: boolean;
}
