import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { AppInfo } from '@capacitor/app';
import packageInfo from 'package.json';
import { NativeAppService } from 'src/app/shared/services/native-app.service';
import { EnvironmentService } from 'src/environments/environment.service';

export interface libType {
  licenseFile?: string;
  licenses?: string;
  publisher?: string;
  repository?: string;
  name?: string;
}

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  public data: libType[] = [];
  public version: string = packageInfo.version;
  public appInfo?: AppInfo;

  constructor(
    private httpClient: HttpClient,
    public environmentService: EnvironmentService,
    private nativeAppService: NativeAppService
  ) {
    if (this.nativeAppService.isOnApp()) {
      this.nativeAppService.getInfo().then((info) => {
        this.appInfo = info;
      });
    }
  }

  ngOnInit() {
    let extraLicenses = {
      /*name: {
        licenses: 'Apache-2.0',
        repository: 'https://github.com/apache/',
        publisher: 'apache',
      },*/
    };

    // license-checker --production --json --out license.json
    // do not forget to remove the clarabot from that list
    this.httpClient.get('assets/lib-licenses.json').subscribe((data) => {
      console.log('data', data);
      data = <{ [key: string]: libType }>data;

      for (let key in data) {
        let el = data[key];
        let arr = key.split('@');
        arr.pop();
        let name = arr.join('');
        el['name'] = name;
        this.data.push(el);
      }

      for (let key in extraLicenses) {
        let el = extraLicenses[key];
        el['name'] = key;
        this.data.push(el);
      }
    });
  }
}
