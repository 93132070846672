import { WorkspaceSubscriptionRecord } from '../../../../../../server-services/query-records/workspace-records';
import { ResponseMergerHandler } from '../../../cache-logic-interface';
import { onWorkspaceChangesSubscription } from './workspace-change-subscription-merger-handlers/workspace-change-subscription';
import { newsHandler } from './news-handler/news-handler';

export let customWorkspaceSubscriptionMerger: ResponseMergerHandler = function (
  cache,
  request,
  response: WorkspaceSubscriptionRecord
) {
  for (var key in response) {
    if (key in onWorkspaceChangesSubscription) {
      onWorkspaceChangesSubscription[key][response[key]['cmd']](cache, response[key]);
    } else {
      console.warn('There is no cache handler for this change event', response);
    }
    if (key in newsHandler) {
      newsHandler.handler[key][response[key]['cmd']](cache, response[key]);
    }
  }
};
