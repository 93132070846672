import { MeRecord } from '../../../../../../../../server-services/query-records/account-records';
import { ID } from '../../../../../../../../server-services/query-records/common-records';
import { RoomRecord } from '../../../../../../../../server-services/query-records/room-records';
import {
  SidebarGroup,
  SidebarResource,
  UNGROUPPED_GROUP_ID,
} from '../../../../../../../../server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionRoomEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getAllRoomQuery,
  getMeQuery,
  getRoomQuery,
  getSidebarGrantedRoomsQuery,
  getSidebarGroupQuery,
  getSidebarRejectedRoomsQuery,
  getSidebarResourceQuery,
  getSidebarUngrouppedQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onDeleteRoomEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionRoomEventRecord
) => {
  let sidebarRes: SidebarResource = cache.getCacheDataByRequest({
    query: getSidebarResourceQuery,
    variables: event,
  });

  let room: RoomRecord = cache.getCacheDataByRequest({ query: getRoomQuery, variables: event });

  let granted: ID[] = cache.getCacheDataByRequest({
    query: getSidebarGrantedRoomsQuery,
    variables: {},
  });
  let roomIndexInGranted = granted.findIndex((el) => el == event.id);

  // put in the rejected list
  if (sidebarRes || roomIndexInGranted > -1) {
    let rejected: ID[] = cache.getCacheDataByRequest({
      query: getSidebarRejectedRoomsQuery,
      variables: {},
    });
    rejected.push(event.id);
  }

  // if there is a sidebarRes, then it is in the ws otherwise in the
  // granted list
  if (sidebarRes) {
    // it is in the ws
    // remove from the container
    let container: SidebarResource[] | null = null;
    if (sidebarRes.groupId == UNGROUPPED_GROUP_ID) {
      container = cache.getCacheDataByRequest({ query: getSidebarUngrouppedQuery, variables: {} });
    } else {
      let group: SidebarGroup = cache.getCacheDataByRequest({
        query: getSidebarGroupQuery,
        variables: {
          id: sidebarRes.groupId,
        },
      });
      if (group) {
        container = group.resources;
      } else {
        console.warn('could not find the removable source in its group in the cache', event);
      }
    }

    if (container) {
      // it should be in the prev container
      let index = container.indexOf(sidebarRes);

      if (index > -1) {
        // remove the element from its old container
        container.splice(index, 1);
      } else {
        console.warn('could not find the removable element in its original container in the cache');
      }
    }

    cache.deleteCacheDataByRequest({ query: getSidebarResourceQuery, variables: event });
  } else {
    // it is in the granted list
    let grantedRooms: ID[] = cache.getCacheDataByRequest({
      query: getSidebarGrantedRoomsQuery,
      variables: {},
    });
    let roomIndex = grantedRooms.findIndex((roomId) => {
      return roomId == event.id;
    });
    if (roomIndex > -1) {
      // it should be there
      grantedRooms.splice(roomIndex, 1);
    } else {
      console.warn('room for delete-event should be in the granted list, but not found', event);
    }
  }

  if (room) {
    let me: MeRecord = cache.getCacheDataByRequest({ query: getMeQuery, variables: {} });
    // we dont need an owned deleted room anymore
    // we dont want to show it in other offline query
    if (me.id == room.ownerAccountId) {
      let rooms: RoomRecord[] = cache.getCacheDataByRequest({
        query: getAllRoomQuery,
        variables: {},
      });
      let roomIndex = rooms.findIndex((r) => r.id == event.id);
      // remove from all room
      rooms.splice(roomIndex, 1);
      // remove the cached record
      cache.deleteCacheDataByRequest({ query: getRoomQuery, variables: event });
    }
  }

  delete cache.getCacheData('messages')[event.id];
  delete cache.getCacheData('seen')[event.id];
};
