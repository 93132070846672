export interface WorkerRunMessage {
  operation: WorkerOperation;
  variables: any[];
  operationId: number;
}

export enum WorkerOperation {
  LOG, // for debug
  AESCipherSIVNonce96Encrypt,
  AESCipherSIVNonce96Decrypt,
  AESCipherSIVDeterministicEncrypt,
  AESCipherSIVDeterministicDecrypt,
  AESCipherGCMEncrypt,
  AESCipherGCMDecrypt,
  AESCipherCBCEncrypt,
  AESCipherCBCDecrypt,
  Ed25519DSSign,
  Ed25519DSVerify,
  /*Curve25519SharedSecretGeneratorNewKey,
  Ed25519KeyGeneratorNewKey,
  Curve25519KeyGeneratorNewKey,
  HKDFKeyGeneratorV1NewKey,
  HKDFKeyGeneratorV2NewKey,
  RandomKeyGenerator128NewKey,
  RandomKeyGenerator256NewKey,
  RandomKeyGenerator512NewKey*/
}

export interface WorkerRunResponse {
  operationId: number;
  response: any;
  error: boolean;
}
