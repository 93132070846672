<h2 mat-dialog-title>{{ generalAlertData.title | translate }}</h2>
<mat-dialog-content class="mat-typography">
  {{ generalAlertData.text | translate }}
  <ng-container *ngIf="generalAlertData.errorMessage">
    <div class="error-container">
      <small *ngIf="!showError && generalAlertData.errorMessage">
        <a translate (click)="showError = true">Show error message</a>
      </small>
      <div *ngIf="showError">
        <mat-card appearance="outlined" class="errorText">
          <mat-card-content>
            {{ generalAlertData.errorMessage }}
          </mat-card-content>
        </mat-card>
        <small class="copyButton"><a translate (click)="copyError()">Copy error message</a></small>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>OK</span></button>
</mat-dialog-actions>
