<div class="manager-file-container">
  <div [ngClass]="{ 'manager-file-preview': true, done: isUploading }" (click)="openPath()">
    <span class="mdi mdi-{{ file.file.icon }} file-{{ file.file.icon }}"></span>
  </div>
  <div class="manager-file-status">
    <div class="manager-file-info">
      <div [ngClass]="{ 'manager-file-title': true, 'mat-small': true, done: !isUploading }" class="notranslate" (click)="openPath()">
        {{ file.path | lastPartOfPath }}
      </div>
      <div class="manager-file-progress mat-small" *ngIf="!file.isDone()">{{ file.timeNeeded }} -</div>
      <div class="manager-file-progress mat-small" *ngIf="!file.isDone()">( {{ file.currPart * chunkSizeM | formatBytes : 1 }} / {{ file.file.size | formatBytes }} )</div>
      <mat-icon class="manager-file-direction" *ngIf="!file.isDone() && file.direction === 'down'">arrow_downward</mat-icon>
      <mat-icon class="manager-file-direction" *ngIf="!file.isDone() && file.direction === 'up'">arrow_upward</mat-icon>
    </div>
    <div class="manager-file-info">
      <div [ngClass]="{ 'manager-file-room': true, 'mat-small': true, done: !isUploading }" (click)="openPath()">
        <app-avatar *ngIf="roomData" [blob]="roomData['data'].avatar"></app-avatar><span *ngIf="roomData" class="notranslate">{{ roomData['data'].name }}</span>
      </div>
      <span class="mat-small manager-file-time" *ngIf="!file.isDone()">{{ file.speed }}</span>
    </div>
    <div class="manager-file-bar" *ngIf="!file.isDone()">
      <mat-progress-bar mode="determinate" [value]="file.progress"></mat-progress-bar>
    </div>
  </div>
  <div class="manager-file-progress mat-small" *ngIf="file.isDone()">
    {{ file.file.size | formatBytes }}
  </div>
  <mat-icon class="manager-file-direction" *ngIf="file.isDone() && file.direction === 'down'">arrow_downward</mat-icon>
  <mat-icon class="manager-file-direction" *ngIf="file.isDone() && file.direction === 'up'">arrow_upward</mat-icon>
  <div class="manager-file-action">
    <div *ngIf="file.isError()">
      <mat-icon color="warn" [matTooltip]="fullErrorDesc">error</mat-icon>
      <button mat-icon-button (click)="remove()" [matTooltip]="'Remove the element from the list' | translate">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button (click)="pause(); resume()" [matTooltip]="'Retry operation' | translate">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div *ngIf="!file.isError()">
      <button mat-icon-button *ngIf="file.isDone() || file.isPaused() || !file.requestId" (click)="remove()" [matTooltip]="'Remove the element from the list' | translate">
        <mat-icon>delete</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="canShowAsImage && ((file.isDone() && file.objectURL) || file.direction === 'up')"
        (click)="toggleViewer()"
        [matTooltip]="'Show/hide the preview of the image' | translate"
      >
        <mat-icon>image_search</mat-icon>
      </button>

      <button mat-icon-button *ngIf="file.requestId && !file.isDone() && !file.isPaused()" (click)="pause()" [matTooltip]="'Pause the operation' | translate"><mat-icon>pause</mat-icon></button>
      <div [matTooltipDisabled]="canResumeDownload" [matTooltip]="'Maximum concurrent downloads reached. Stop other downloads to resume.' | translate">
        <button mat-icon-button *ngIf="file.requestId && !file.isDone() && file.isPaused()" (click)="resume()" [disabled]="!canResumeDownload" [matTooltip]="'Resume the operation' | translate">
          <mat-icon>play_arrow</mat-icon>
        </button>
      </div>
      <button mat-icon-button *ngIf="file.requestId && file.isDone() && file.objectURL" (click)="redownload()" [matTooltip]="'Get the downloaded file again' | translate">
        <mat-icon>download</mat-icon>
      </button>
      <button mat-icon-button *ngIf="file.requestId && isOnApp && file.isDone() && file.direction === 'down'" (click)="open()" [matTooltip]="'Open file' | translate">
        <mat-icon>input</mat-icon>
      </button>
    </div>
  </div>
</div>
<div class="preview" *ngIf="showPreview && ((file.isDone() && file.objectURL) || file.direction === 'up') && canShowAsImage">
  <img [src]="safeBlobURL" />
</div>
