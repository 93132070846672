import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../server-services/auth.service';
import { DialogService } from '../services/dialog.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root',
})
export class RegisterConfirmGuard  {
  private confirmTitle: string = marker('Confirming registration');
  private confirmText: string = marker(
    'You will be logged out during the registration confirmation process. Do you want to continue?'
  );

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
      let onCallback = () => {
        this.authService.unsubscribeSelfAccountKeyringLoaded(onCallback);

        if (this.authService.isAnonym()) return resolve(true);

        //var logoutConfirmPromise: Promise<boolean | UrlTree>;
        if (this.authService.isAnonym() !== true) {
          //logoutConfirmPromise = new Promise((resolve) => {
          this.dialogService
            .openConfirmDialog(this.confirmTitle, this.confirmText)
            .subscribe((closedWithOk) => {
              if (closedWithOk) {
                this.authService
                  .logout()
                  .then(() => {
                    resolve(true);
                  })
                  .catch(() => resolve(false));
              } else {
                window.location.hash = '';
                resolve(this.router.parseUrl('/'));
              }
            });
          //});
        } else {
          //logoutConfirmPromise = Promise.resolve(true);
          resolve(true);
        }

        //return logoutConfirmPromise;
      };

      this.authService.onSelfAccountKeyringLoaded(onCallback);
    });
  }
}
