import * as emojiBaseShortcodes from 'emojibase-data/en/shortcodes/joypixels.json';
import { fromHexcodeToCodepoint, fromCodepointToUnicode } from 'emojibase';

export let emojiHexcodeToShortcode = emojiBaseShortcodes;
export let emojiShortcodeToUnicode = {};

//fetchShortcodes("en", "discord").then((res) => {
for (let key in emojiHexcodeToShortcode) {
  let arr: any;

  if (!Array.isArray(emojiHexcodeToShortcode[key])) {
    arr = [emojiHexcodeToShortcode[key]];
  } else {
    arr = emojiHexcodeToShortcode[key];
  }

  arr.forEach((el) => {
    let emoji = fromCodepointToUnicode(fromHexcodeToCodepoint(key));
    emojiShortcodeToUnicode[el] = emoji;
  });
}
//});
