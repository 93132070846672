import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ID } from '../server-services/query-records/common-records';
import { RouterHandler } from './router-handler.service';

export let openNewTab: Subject<PageInfo> = new Subject<PageInfo>();

export enum PageTypes {
  ROOM = 'room',
  PRIVATE_CHAT = 'private-chat',
  EMPTY = 'empty',
  LIVE = 'live',
}

export enum SubPageTypes {
  CHAT = 'chat',
  DRIVE = 'drive',
  OFFICE = 'office',
}

export type PageInfo = {
  page: PageTypes;
  subpage: SubPageTypes;
  id: ID;
  fragment: { [key: string]: string };
};

export interface TabComponent {
  openInNewTab(pageInfo?: PageInfo);
  openInCurrentTab(pageInfo?: PageInfo);
  getActivePageInfo(): PageInfo;
  pages: PageInfo[];
}

export let activePageInfoChanged: Subject<PageInfo> = new Subject<PageInfo>();

@Injectable({
  providedIn: 'root',
})
export class PageTabService {
  private activeTabComponent: TabComponent | null;

  public activePageInfo: PageInfo | null = null;

  constructor(private routerHandler: RouterHandler) {}

  public getObserverComponent(): TabComponent {
    return this.activeTabComponent;
  }

  public getActivePageInfo(): PageInfo | null {
    return this.activeTabComponent?.getActivePageInfo();
  }

  public makeUrlFromPageInfo(pageInfo: PageInfo): string {
    let url = '';
    if (pageInfo.page == PageTypes.ROOM) {
      if (pageInfo.subpage == SubPageTypes.CHAT) {
        url = `room/${pageInfo.id}/chat/room`;
      } else if (pageInfo.subpage == SubPageTypes.DRIVE) {
        url = `room/${pageInfo.id}/drive`;
      } else if (pageInfo.subpage == SubPageTypes.OFFICE) {
        url = `room/${pageInfo.id}/office`;
      }
    } else if (pageInfo.page == PageTypes.PRIVATE_CHAT) {
      url = `room/${pageInfo.id}/chat/private`;
    } else if (pageInfo.page == PageTypes.LIVE) {
      url = `live`;
    }

    if (Object.keys(pageInfo.fragment).length > 0) {
      url += '#' + this.routerHandler.fragmentToRaw(pageInfo.fragment);
    }
    return url;
  }

  public openInNewTab(pageInfo: PageInfo) {
    if (this.activeTabComponent) {
      this.activeTabComponent.openInNewTab(pageInfo);
    } else {
      this.routerHandler.navigate([this.makeUrlFromPageInfo(pageInfo)]);
    }
  }

  public openInCurrentTab(pageInfo: PageInfo) {
    if (this.activeTabComponent) {
      this.activeTabComponent.openInCurrentTab(pageInfo);
    } else {
      this.routerHandler.navigate([this.makeUrlFromPageInfo(pageInfo)]);
    }
  }

  public registerActiveObserverComponent(comp: TabComponent) {
    this.activeTabComponent = comp;
  }

  public removeRegisteredActiveObserverComponent() {
    this.activeTabComponent = null;
  }
}
