import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ChatData } from 'src/app/components/resource-page/chat-layout/chat-data';
import { AppStorage } from '../app-storage';
import { SafeRadix32 } from '../safe-radix32';
import { AccountService } from '../server-services/account.service';
import { ChatService } from '../server-services/chat.service';
import { DialogueService } from '../server-services/dialogue.service';
import { MeRecord } from '../server-services/query-records/account-records';
import { ID } from '../server-services/query-records/common-records';
import { MessageRecord } from '../server-services/query-records/room-records';
import { ResourceType } from '../server-services/query-records/sidebar-records';
import {
  WorkspaceSubscriptionDialogueMessageEventRecord,
  WorkspaceSubscriptionRoomMessageEventRecord,
} from '../server-services/query-records/workspace-records';
import {
  getSidebarGrantedRoomsQuery,
  getSidebarRejectedRoomsQuery,
} from '../server-services/querys';
import { ResourceBase } from '../server-services/resource-base';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from '../server-services/subscription-event';
import { SubscriptionService } from '../server-services/subscription.service';
import { RouterHandler } from './router-handler.service';
import { ServerRestApiService } from './server-rest-api.service';
import { NativeAppService } from './native-app.service';

export let newNotificationWidgetObserver = new Subject<MessageWidget | null>();

export type MessageWidget = {
  chatData: ChatData;
  roomData: {
    id: string;
    type: ResourceType;
  };
};

export let NotificationWidgetAppStorageKey = 'NOTIFICATION_WIDGET_ENABLED';

@Injectable({
  providedIn: 'root',
})
export class NotificationWidgetService implements OnDestroy {
  private me: MeRecord = null;

  // Last in first out queue for messages
  private messageQueue: MessageWidget[] = [];

  private isEnabled: boolean = true;

  constructor(
    private subscriptionService: SubscriptionService,
    private dialogueService: DialogueService,
    private chatService: ChatService,
    private accountService: AccountService,
    private routerHandler: RouterHandler,
    private serverRestApiService: ServerRestApiService,
    private nativeAppService: NativeAppService
  ) {
    this.subscriptionService.subscribeWorkspaceLoaded(this.onWorkspaceLoaded);

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.handleRoomMessageCreate
    );
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.handleDialogueMessageCreate
    );

    let appStorageValue = AppStorage.getItem(NotificationWidgetAppStorageKey);
    if (appStorageValue === null)
      //default true on App, false on Desktop
      this.isEnabled = this.nativeAppService.isOnApp();
    else this.isEnabled = JSON.parse(AppStorage.getItem(NotificationWidgetAppStorageKey));
  }

  ngOnDestroy() {
    this.subscriptionService.unsubscribeWorkspaceLoaded(this.onWorkspaceLoaded);

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.handleRoomMessageCreate
    );
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_MESSAGE_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.handleDialogueMessageCreate
    );
  }

  private onWorkspaceLoaded = () => {
    this.accountService.getMe().then((me) => {
      this.me = me;
    });
  };

  private handleRoomMessageCreate = (msg: WorkspaceSubscriptionRoomMessageEventRecord) => {
    this.handleMessageCreate(msg, ResourceType.ROOM);
  };

  private handleDialogueMessageCreate = (msg: WorkspaceSubscriptionDialogueMessageEventRecord) => {
    this.handleMessageCreate(msg, ResourceType.DIALOGUE);
  };

  private handleMessageCreate = (
    msg:
      | WorkspaceSubscriptionDialogueMessageEventRecord
      | WorkspaceSubscriptionRoomMessageEventRecord,
    resourceType: ResourceType
  ) => {
    if (!this.isEnabled) return;
    if (!msg) {
      return;
    }

    this.shouldBeIgnored(msg).then((ignore) => {
      if (ignore) return;

      // which service should be used
      let service: ResourceBase;
      if (resourceType == ResourceType.DIALOGUE) {
        service = this.dialogueService;
      } else if (resourceType == ResourceType.ROOM) {
        service = this.chatService;
      } else {
        console.warn('unsupported resource type', resourceType);
        return;
      }

      service.getOneMessageById(msg.id, msg.messageId).then((data) => {
        const messageWidget: MessageWidget = {
          chatData: this.convertServerRecordToMessage(data),
          roomData: {
            id: msg.id,
            type: resourceType,
          },
        };
        this.accountService.getAccount(data.posterId).then((account) => {
          messageWidget.chatData.account = account;
          this.pushMessage(messageWidget);
        });
      });
    });
  };

  /**
   * Convert raw server message into ChatData. Use loadMessage to install message into this system
   * @param data
   * @returns
   */
  private convertServerRecordToMessage(data: MessageRecord): ChatData {
    return {
      id: data.id,
      message: data.content,
      date: this.getTimeFromId(data.id),
      account: {
        id: data.posterId,
        avatarImageKey: null,
        avatarName: null,
        deleted: false,
      },
      edited: data.edited,
      seenBy: [],
      error: data.decryptionError,
      errorMessage: data.decryptionErrorMessage,
      messageRef: data,
    };
  }

  /**
   * Message id contains the timestamp in saferadix encoded
   * If you cut the last 5 char from the string, you'll get the encoded time
   * start at 2020.01.01 01:00 GMT+0100
   * every tick (calculated number by decodeSafeRadix) means 0.02 sec
   * @param id
   */
  private getTimeFromId(id: string): Date {
    let ticks = SafeRadix32.decodeSafeRadix32(id.slice(0, -5)); // get the ticks from the encoded number
    let timestamp = ticks * 20; // 0.02 sec for every tick
    return new Date(timestamp + 1577836800000); // add 2020.01.01 01:00 to it (this is the start)
  }

  private showNextMessage = () => {
    const message = this.messageQueue?.[0];

    if (message) {
      newNotificationWidgetObserver.next(message);
    } else {
      newNotificationWidgetObserver.next(null);
    }
  };

  private shouldBeIgnored: (
    message:
      | WorkspaceSubscriptionRoomMessageEventRecord
      | WorkspaceSubscriptionDialogueMessageEventRecord
  ) => Promise<boolean> = (message) => {
    const resourceId = this.routerHandler.getRoute().params?.id;
    // message comes from the page where we are on
    if (resourceId === message?.id) {
      return Promise.resolve(true);
    }

    if (this.me && message.posterId === this.me.id) {
      return Promise.resolve(true);
    }

    return this.getGrantedRooms().then((grantedRooms) => {
      if (grantedRooms && message?.id && grantedRooms.includes(message.id)) {
        return true;
      }

      return this.getRejectedRooms().then((rejectedRooms) => {
        if (rejectedRooms && message?.id && rejectedRooms.includes(message.id)) {
          return true;
        } else {
          return false;
        }
      });
    });
  };

  public pushMessage = (message: MessageWidget) => {
    if (!this.messageQueue.length) {
      // push new when queue is empty
      this.messageQueue.push(message);
      this.showNextMessage();
    } else {
      if (
        message.roomData.id === this.messageQueue[0].roomData.id // same room
      ) {
        // replace first in queue, when message is coming from the same room
        this.messageQueue[0] = message;
        this.showNextMessage();
      } else {
        // if there was a message queued to this room already then overwrite it
        const foundIndex = this.messageQueue.findIndex(
          (msg) => msg.roomData.id === message.roomData.id
        );
        if (foundIndex >= 0) {
          this.messageQueue[foundIndex] = message;
        } else {
          // else push new message to the end of the queue
          this.messageQueue.push(message);
        }
      }
    }
  };

  public enableNotificationWidget(enable: boolean = true): void {
    this.isEnabled = enable;
    AppStorage.setItem(NotificationWidgetAppStorageKey, enable.toString());
  }

  public isNotificationEnabled(): boolean {
    return this.isEnabled;
  }

  public skipOne = () => {
    this.messageQueue.shift();
    this.showNextMessage();
  };

  public clearQueue = () => {
    this.messageQueue = [];
    this.showNextMessage();
  };

  private getRejectedRooms(): Promise<ID[]> {
    return this.serverRestApiService.query({
      query: getSidebarRejectedRoomsQuery,
    });
  }

  private getGrantedRooms(): Promise<ID[]> {
    return this.serverRestApiService.query({
      query: getSidebarGrantedRoomsQuery,
    });
  }
}
