import { CacheLogicMergerType } from '../cache-logic-interface';
import { customMutationMerger } from './mutation-merger/custom-mutation-merger';
import { customQueryMerger } from './query-merger/custom-query-merger';
import { customSubscriptionMerger } from './subscription-merger/custom-subscription-merger';

export let customResponseMerger: CacheLogicMergerType = {
  query: customQueryMerger,
  mutation: customMutationMerger,
  subscription: customSubscriptionMerger,
};
