import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DriveFile } from 'src/app/components/resource-page/drive-window/drive-layout/drive-file';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

@Component({
  selector: 'app-delete-file-dialog',
  templateUrl: './delete-file-dialog.component.html',
  styleUrls: ['./delete-file-dialog.component.scss'],
})
export class DeleteFileDialogComponent extends NanoDialogBaseComponent<DeleteFileDialogComponent> {
  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DriveFile[]
  ) {
    super(injector);

    console.log('to-delete-data', data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.data);
  }
}
