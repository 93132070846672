/**
 * Featue to resolve width and height value to fit into one fragment parameter
 * so we can create shorter urls
 * 
 * 
 * Server side implementation:
 * 
 * 
 
 def encode_interleaved_int(a: int, b: int) -> int:
    assert a >= 0 <= b
    o = 0
    m = 1
    for i in range(max(a.bit_length(), b.bit_length())):
        o |= (((a & m) << 1) | (b & m)) << i
        m <<= 1
    return o


def decode_interleaved_int(x: int) -> Tuple[int, int]:
    assert x >= 0
    a = 0
    b = 0
    for i in range((x.bit_length() + 1) // 2):
        a |= ((x & 2) >> 1) << i
        b |= (x & 1) << i
        x >>= 2
    return a, b
 */

let bitLength = 16;

export function encodeWH(width: number, height: number): number {
  if (width < 0 || height < 0) throw 'wrong parameter';

  let o = 0;
  let m = 1;
  for (let i = 0; i < bitLength; i++) {
    o |= (((width & m) << 1) | (height & m)) << i;
    m = m << 1;
  }
  return o;
}

export function decodeWH(short: number): { width: number; height: number } {
  if (short < 0) throw 'wrong parameter';

  let width = 0;
  let height = 0;
  for (let i = 0; i < bitLength; i++) {
    width |= ((short & 2) >> 1) << i;
    height |= (short & 1) << i;
    short = short >> 2;
  }

  return { width, height };
}
