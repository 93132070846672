<div
  class="file-block-view-cont"
  [ngClass]="{ 'selected-file': selected, 'focused-file': focus, unresolved: file.unresolved }"
  [ngStyle]="{ 'background-size': '100% ' + progress + '%', 'background-image': errorHappened ? downloadErrorColor : downloadProgressColor }"
>
  <div class="checkbox-container" *ngIf="isSelectingMode">
    <mat-checkbox [checked]="selected" color="primary"></mat-checkbox>
  </div>
  <div class="file-preview">
    <ng-container *ngIf="file.unresolved">
      <span class="mdi mdi-file-hidden"></span>
    </ng-container>
    <ng-container *ngIf="!file.unresolved">
      <span *ngIf="!file.thumbnail" class="mdi mdi-{{ file.icon }} file-{{ file.icon }}"></span>
      <app-blob-viewer *ngIf="file.thumbnail" [blob]="file.thumbnail" type="image"></app-blob-viewer>
      <img class="nano-icon" src="/assets/img/ui/icon_nano.svg" *ngIf="file.icon === 'folder' && file.name === 'Nano'" />
    </ng-container>
  </div>
  <div class="file-name mat-small" *ngIf="!file.unresolved">
    <span class="notranslate">
      {{ file.fullName }}
    </span>
  </div>
  <div class="file-name mat-small" *ngIf="file.unresolved">
    <span class="notranslate">
      <i>{{ file.symlink ? file.name : 'Unresolved File' }}</i>
    </span>
  </div>
</div>
