<h2 mat-dialog-title>{{ generalInputData.title | translate }}</h2>
<mat-dialog-content class="mat-typography">
  <div class="container">
    <mat-form-field appearance="outline">
      <mat-label>{{ generalInputData.text | translate }}</mat-label>
      <input matInput required [(ngModel)]="inputValue" (keyup.enter)="submit()" />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Cancel</span></button>
  <button mat-button color="primary" (click)="submit()" [disabled]="!generalInputData.canBeEmpty && (!inputValue || inputValue.length === 0)">
    <span translate>Submit</span>
  </button>
</mat-dialog-actions>
