import { AbstractAccountKeyring, AbstractSelfAccountKeyring } from '../../keyring/account_base';
import { AbstractConfigDumpArgs, AbstractConfigLoadArgs } from '../base';

export class AccountTrustConfigLoadArgs extends AbstractConfigLoadArgs {
  public self_kr;
  public peer_kr;

  constructor(
    cipher: Uint8Array,
    self_kr: AbstractSelfAccountKeyring,
    peer_kr: AbstractAccountKeyring
  ) {
    super(cipher);
    this.self_kr = self_kr;
    this.peer_kr = peer_kr;
  }
}

export class AccountTrustConfigDumpArgs extends AbstractConfigDumpArgs {
  public self_kr;
  public peer_kr;
  public key_version;

  constructor(
    self_kr: AbstractSelfAccountKeyring,
    peer_kr: AbstractAccountKeyring,
    key_version: string
  ) {
    super();
    this.self_kr = self_kr;
    this.peer_kr = peer_kr;
    this.key_version = key_version;
  }
}
