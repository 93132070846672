<div class="cont">
  <div>
    <h2 translate>Verify user identity to trust them.</h2>
  </div>
  <div class="desc">
    <app-avatar id="avatar-image" [accountAvatarRecord]="avatar"></app-avatar><span class="notranslate">{{ avatar.avatarName }}</span>
  </div>
  <div class="desc"><mat-icon>warning</mat-icon><span translate>This feature is experimental and work in progress!</span></div>
  <div class="desc"><mat-icon>warning</mat-icon><span translate>Cryptographic trust signatures can not be undone!</span></div>
  <div class="desc"><span translate>Contact your partner using one or more secure channels outside of Nano and ask for their identity-signature.</span></div>
  <div class="inp-cont">
    <input class="nano-input" (input)="checkInput()" type="text" [(ngModel)]="signature" [placeholder]="'Partner\'s identity signature' | translate" />
  </div>
  <div *ngIf="timestamp">
    <span translate>Verified signature. Created at </span> <span>{{ timestamp | date: 'short' }}</span>
  </div>
  <div *ngIf="checkError" class="warn">
    <div class="desc"><mat-icon>warning</mat-icon><span translate>Invalid value. Please double check your copied value and your partner.</span></div>
  </div>
  <div class="close-cont">
    <button mat-raised-button (click)="save()" [disabled]="!timestamp">
      <span translate>Trust</span> <span class="notranslate"> {{ avatar.avatarName }} </span>
    </button>
    <button mat-dialog-close mat-raised-button><span translate>Close</span></button>
  </div>
</div>
