import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NanoValidators } from '../../nano-validators';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-new-folder-dialog',
  templateUrl: './new-folder-dialog.component.html',
  styleUrls: ['./new-folder-dialog.component.scss'],
})
export class NewFolderDialogComponent extends NanoDialogBaseComponent<NewFolderDialogComponent> {
  filteredFolderNameGroup = new FormGroup({
    folderName: new FormControl<string | null>('', [
      Validators.required,
      Validators.maxLength(255),
      NanoValidators.noSlashes,
    ]),
  });

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super(injector);
  }

  onSubmit() {
    this.dialogRef.close(this.filteredFolderNameGroup.get('folderName').value.trim());
  }
}
