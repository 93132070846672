/**
 * Encryption of login challenge payload.
 */

import { CryptoContext } from '../base';
import { LoginChallengeConfigDumpArgs, LoginChallengeConfigLoadArgs } from './args';
import { LoginChallengeConfigV1 } from './v1';

export let LOGIN_CHALLENGE_CRYPTO_CONTEXT = new CryptoContext<
  LoginChallengeConfigLoadArgs,
  LoginChallengeConfigDumpArgs
>([new LoginChallengeConfigV1()], [new Uint8Array([1])]);
