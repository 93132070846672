import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { IL10nsStrings, TimeagoIntl } from 'ngx-timeago';
import { strings as enStrings } from 'ngx-timeago/language-strings/en';
import { strings as huStrings } from 'ngx-timeago/language-strings/hu';
import { AppStorage } from 'src/app/shared/app-storage';
import { AudioService, Sounds } from 'src/app/shared/services/audio.service';
import { NativeAppService } from 'src/app/shared/services/native-app.service';
import { NotificationWidgetService } from 'src/app/shared/services/notification-widget.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { styleChangedObserver } from 'src/app/shared/shared-observers';

export enum ChatMessageGroupingPolicy {
  sameYear,
  sameMonth,
  sameDay,
  sameHour,
  sameMinute,
  in1Day,
  in3Hours,
  in2Hours,
  in1Hour,
  in30Minutes,
  in10Minutes,
  in5Minutes,
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public nightMode: boolean;
  public notificationWidgetEnabled: boolean;
  public volumeValue: number = 1;
  public supportedLanguages = {
    hu: 'Magyar',
    en: 'English',
  };
  public selectedLang;

  public chatGroupingPolicies = {
    [ChatMessageGroupingPolicy.sameYear]: marker('Same year'),
    [ChatMessageGroupingPolicy.sameMonth]: marker('Same month'),
    [ChatMessageGroupingPolicy.sameDay]: marker('Same day'),
    [ChatMessageGroupingPolicy.sameHour]: marker('Same hour'),
    [ChatMessageGroupingPolicy.sameMinute]: marker('Same minute'),
    [ChatMessageGroupingPolicy.in1Day]: marker('In 1 day'),
    [ChatMessageGroupingPolicy.in3Hours]: marker('In 3 hours'),
    [ChatMessageGroupingPolicy.in2Hours]: marker('In 2 hours'),
    [ChatMessageGroupingPolicy.in1Hour]: marker('In 1 hour'),
    [ChatMessageGroupingPolicy.in30Minutes]: marker('In 30 minutes'),
    [ChatMessageGroupingPolicy.in10Minutes]: marker('In 10 minutes'),
    [ChatMessageGroupingPolicy.in5Minutes]: marker('In 5 minutes'),
  };
  public selectedChatGroupingPolicy;
  public chatGroupingPoliciesSelectValues;

  public messageDisplays = [
    {
      value: 'cozy',
      displayName: marker('Cozy'),
    },
    {
      value: 'compact',
      displayName: marker('Compact'),
    },
  ];
  public selectedMessageDisplay;

  constructor(
    private translate: TranslateService,
    private titleService: TitleService,
    public dialog: MatDialog,
    private audioService: AudioService,
    private notificationWidgetService: NotificationWidgetService,
    private timeagoIntl: TimeagoIntl,
    private nativeAppService: NativeAppService
  ) {
    this.chatGroupingPoliciesSelectValues = Object.entries(this.chatGroupingPolicies).map(
      ([key]) => ({
        key: key.padStart(2, '0'),
        value: key,
        displayName: this.chatGroupingPolicies[key],
      })
    );

    this.selectedChatGroupingPolicy =
      AppStorage.getItem('chatGroupingPolicy') ?? ChatMessageGroupingPolicy.sameDay + '';

    this.bindValues();
    this.notificationWidgetEnabled = this.notificationWidgetService.isNotificationEnabled();
  }

  ngOnInit(): void {
    // sideMenuActiveObserver.next(false);
    //this.testCalls();
  }

  private bindValues(): void {
    this.nightMode = AppStorage.getItem('style_mode') == 'dark';

    styleChangedObserver.subscribe((style) => {
      this.nightMode = style == 'dark';
    });

    this.selectedLang = AppStorage.getItem('lang');
    this.selectedMessageDisplay = AppStorage.getItem('messageDisplay') || 'cozy';

    this.titleService.setCurrentTabTitle(marker('Settings'));

    var volume = AppStorage.getItem('volume');
    if (volume) this.volumeValue = parseFloat(volume);
  }

  public toggleTheme(): void {
    this.nightMode = !this.nightMode;
    styleChangedObserver.next(this.nightMode ? 'dark' : 'light');
  }

  public toggleNotificationWidget(): void {
    this.notificationWidgetEnabled = !this.notificationWidgetEnabled;
    this.notificationWidgetService.enableNotificationWidget(this.notificationWidgetEnabled);
  }

  public changeLanguage(lang): void {
    this.selectedLang = lang;
    this.translate.use(lang);
    AppStorage.setItem('lang', lang);
    let timeagoLangStrings: IL10nsStrings = enStrings;
    if (lang === 'hu') timeagoLangStrings = huStrings;

    this.timeagoIntl.strings = timeagoLangStrings;
    this.timeagoIntl.changes.next();
  }

  public changeChatGroupingPolicy(policy): void {
    this.selectedChatGroupingPolicy = policy;
    AppStorage.setItem('chatGroupingPolicy', policy);
  }

  public changeMessageDisplay(display): void {
    this.selectedMessageDisplay = display;
    AppStorage.setItem('messageDisplay', display);
  }

  public volumeChange(value: number): void {
    this.volumeValue = value;
    AppStorage.setItem('volume', this.volumeValue.toString());
  }

  public playTestSound(): void {
    if (this.nativeAppService.isOnIOS()) {
      this.nativeAppService.playNotificationSound();
    } else {
      this.audioService.playSound(Sounds.NOTIFICATION);
    }
  }
}
