<div class="sidebar-item-container" cdkDrag [cdkDragDisabled]="!isReordering" *ngIf="sidebarResourceData && dialogueRecord">
  <button
    mat-button
    matRipple
    routerLink="room/{{ sidebarResourceData.id }}/chat/private"
    routerLinkActive="active"
    [ngClass]="{ 'active-node': selectedMenu, 'data-error': error === true }"
    (pointerup)="selected($event)"
    (contextmenu)="showUserInfo($event)"
    [appEnterTheViewportNotifier]
    (visibilityChange)="visibilityChange($event)"
    id="sidebar-dialogue-cont"
    class="room-container"
    *ngIf="!isReordering"
  >
    <ng-container>
      <mat-icon class="notification-led" color="primary" *ngIf="sidebarResourceData?.hasNewMessages">circle</mat-icon>
      <app-avatar #sidebarAvatar [accountAvatarRecord]="avatar"></app-avatar>
      <div class="res-node-name">
        <span class="notranslate">{{ name }}</span>
        <div *ngIf="!avatar && isVisible" class="loading-bar"></div>
      </div>

      <mat-icon [matTooltip]="'Private chat' | translate" class="info-flag">person</mat-icon>
    </ng-container>
  </button>
  <div *ngIf="isReordering" id="sidebar-room-cont" class="room-container room-container-reordering" [appEnterTheViewportNotifier] (visibilityChange)="visibilityChange($event)">
    <app-avatar [accountAvatarRecord]="avatar"></app-avatar>
    <div class="res-node-name">
      <span class="notranslate">{{ name }}</span>
    </div>
    <button class="open-group-select-button" mat-icon-button (click)="moveIntoOtherGroup()"><mat-icon>north_east</mat-icon></button>
    <mat-icon class="room-grabber" cdkDragHandle>drag_handle</mat-icon>
  </div>
</div>
