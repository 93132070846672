import { AbstractSymmetricKeyGenerator } from '../interface';

export class RandomKeyGenerator128 extends AbstractSymmetricKeyGenerator {
  public static new_key(): Uint8Array {
    return crypto.getRandomValues(new Uint8Array(16));
  }

  public static describe(): string {
    return 'Random-128';
  }
}

export class RandomKeyGenerator256 extends AbstractSymmetricKeyGenerator {
  public static new_key(): Uint8Array {
    return crypto.getRandomValues(new Uint8Array(32));
  }

  public static describe(): string {
    return 'Random-256';
  }
}

export class RandomKeyGenerator512 extends AbstractSymmetricKeyGenerator {
  public static new_key(): Uint8Array {
    return crypto.getRandomValues(new Uint8Array(64));
  }

  public static describe(): string {
    return 'Random-512';
  }
}
