import { WorkspaceSubscriptionAccountTrustEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import { getAccountKeysQuery } from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onModifyAccountTrustEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionAccountTrustEventRecord) => {
    let cachedKeys = cache.getCacheDataByRequest({
      query: getAccountKeysQuery,
      variables: { id: event.peerId },
    });
    if (cachedKeys) {
      cachedKeys['trust'] = event.trust;
    }
  };
