import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../server-services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class IsLoggedInGuard  {
  private loggedInCallback = null;

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise((resolve, reject) => {
      this.loggedInCallback = () => {
        this.authService.unsubscribeSelfAccountKeyringLoaded(this.loggedInCallback);
        if (route.url.some((url) => url.path == 'login' || url.path == 'register')) {
          //on login or register page, we have to do the opposite
          if (this.authService.isAnonym()) resolve(true);
          else resolve(this.router.parseUrl(''));
        } //navigate to login if no user is logged in
        else {
          if (this.authService.isAnonym()) resolve(this.router.parseUrl('/login'));
          else resolve(true);
        }
      };
      this.authService.onSelfAccountKeyringLoaded(this.loggedInCallback);
    });
  }
}
