import { IEnvironment } from './environment-base';

export const environment: IEnvironment = {
  production: true,
  site_base: 'https://nano.clarabot.com',
  url_api: 'https://nano.clarabot.com/api',
  url_ws: 'wss://nano.clarabot.com/api/ws',
  url_sws: 'wss://nano.clarabot.com/api/sws',
  url_landing: 'https://clarabot.com',
  url_office_editor: 'https://doc.clarabot.com',
  beta: false,
  cookie_url: 'nano.clarabot.com',
  ws_timeout: 25000,

  live_muc_version_url: '/muc-client/version.json',
  live_jitsi_lib_fallback_url: '/muc-client/latest/lib-jitsi-meet.min.js',
  get_live_jitsi_lib_url: (version) => {
    return 'https://nano.clarabot.com/muc-client/' + version + '/lib-jitsi-meet.min.js';
  },
};
