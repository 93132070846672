<app-blocking-loader [loading]="loading"></app-blocking-loader>

<mat-tab-group mat-tab-group-headerless dynamicHeight [selectedIndex]="activeTab">
  <div>
    <!-- 0 Main Details Tab -->
    <mat-tab>
      <mat-dialog-content>
        <div class="tab-content" *ngIf="!loading">
          <div class="name-data-container">
            <div
              class="avatar-content"
              (click)="openEditAvatarDialog()"
              matRipple
              [matRippleCentered]="true"
              [matRippleUnbounded]="true"
              [matRippleDisabled]="!myRoomPermission.isOwner && !myRoomPermission.isAdmin"
            >
              <app-avatar [blob]="avatar"></app-avatar>
              <mat-icon *ngIf="myRoomPermission.isOwner || myRoomPermission.isAdmin" [matTooltip]="'Change room image' | translate">add_circle</mat-icon>
            </div>
            <mat-form-field appearance="fill">
              <mat-label translate>Room name</mat-label>
              <input type="email" matInput [formControl]="roomNameControl" />
              <mat-error *ngIf="roomNameControl.hasError('required')" translate> Please enter a room name</mat-error>
              <mat-error *ngIf="roomNameControl.hasError('maxLength')" translate> Room name can't be longer than 20 characters</mat-error>
            </mat-form-field>
          </div>
          <div class="line-container" *ngIf="allowAnonymControl.value">
            <div class="sub-input-container share-container">
              <mat-form-field appearance="outline">
                <mat-label translate>Share URL</mat-label>
                <input matInput placeholder="Share URL" [ngModel]="publicUrl" readonly />
              </mat-form-field>
              <button mat-icon-button color="primary" (click)="copy(publicUrl)">
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="line-container">
            <button mat-button (click)="openContent(1)" [disabled]="!myRoomPermission.canEditPermissions">
              <div class="line">
                <mat-icon>lock</mat-icon><span class="line-label" translate>Room type</span><span class="line-value">{{ anonymAccessAllowed | translate }}</span>
              </div>
            </button>
            <button mat-button (click)="openContent(2)" [disabled]="!myRoomPermission.canEditPermissions">
              <div class="line">
                <mat-icon>chat</mat-icon><span class="line-label" translate>Chat access</span><span class="line-value">{{ isMessageBoard | translate }}</span>
              </div>
            </button>
            <button mat-button (click)="openContent(7)" [disabled]="!myRoomPermission.isOwner">
              <div class="line">
                <mat-icon>computer</mat-icon><span class="line-label notranslate" translate>Drive</span><span class="line-value" translate>{{ isNanoConnected ? 'Connected' : 'Not connected' }}</span>
              </div>
            </button>
            <button mat-button (click)="openContent(3)" [disabled]="!myRoomPermission.canEditPermissions">
              <div class="line">
                <mat-icon>sentiment_very_satisfied</mat-icon><span class="line-label" translate>Reactions</span><span class="line-value">{{ isEmoteAllowed | translate }}</span>
              </div>
            </button>
          </div>
          <mat-divider></mat-divider>
          <div class="line-container">
            <button mat-button (click)="openContent(6)" [disabled]="!myRoomPermission.canEditPermissions">
              <div class="line"><mat-icon>person_add</mat-icon><span class="line-label" translate>Invite</span></div>
            </button>
            <button mat-button (click)="openContent(5)">
              <div class="line"><mat-icon>group</mat-icon><span class="line-label" translate>Members</span></div>
            </button>
          </div>
          <mat-divider></mat-divider>
          <div class="line-container">
            <button mat-button (click)="openContent(4)" [disabled]="!myRoomPermission.isOwner">
              <div class="line"><mat-icon>settings</mat-icon><span class="line-label" translate>Advanced settings</span></div>
            </button>
            <button mat-button *ngIf="!myRoomPermission.isOwner" (click)="leaveRoom()">
              <div class="line red"><mat-icon>logout</mat-icon><span class="line-label" translate>Leave room</span></div>
            </button>
            <button mat-button *ngIf="myRoomPermission.isOwner" (click)="deleteRoom()">
              <div class="line red"><mat-icon>delete</mat-icon><span class="line-label" translate>Delete room</span></div>
            </button>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button (click)="closeRoomDetailDialog()"><span translate>Cancel</span></button>
          <button mat-raised-button color="primary" [disabled]="!(roomNameControl.valid && roomNameControl.dirty)" (click)="saveRoomName()"><span translate>Save</span></button>
        </div>
      </mat-dialog-actions>
    </mat-tab>

    <!-- 1 Room Type Tab -->
    <mat-tab>
      <span mat-dialog-title translate>Change room type</span>
      <mat-dialog-content>
        <div class="tab-content sub-content">
          <div>
            <mat-radio-group [formControl]="allowAnonymControl" color="primary">
              <mat-radio-button [value]="false">
                <div translate>Private</div>
                <div translate class="sub-text">Only members can access this room.</div></mat-radio-button
              >
              <mat-radio-button [value]="true">
                <div translate>Public</div>
                <div translate class="sub-text">Anyone with the share URL can access this room.</div>
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            <div class="content-label" translate>Share URL</div>
            <div class="sub-input-container">
              <mat-form-field appearance="outline">
                <mat-label>Share URL</mat-label>
                <input matInput placeholder="Share URL" readonly [ngModel]="publicUrl" [disabled]="!allowAnonymControl.value" />
              </mat-form-field>
              <button mat-icon-button color="primary" (click)="copy(publicUrl)" [disabled]="!allowAnonymControl.value">
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button (click)="cancelFormControlEditing(allowAnonymControl)"><span translate>Cancel</span></button>
          <button mat-button (click)="saveRoomType()" color="primary" [disabled]="!allowAnonymControl.dirty"><span translate>Save</span></button>
        </div>
      </mat-dialog-actions>
    </mat-tab>

    <!-- 2 Chat Access Tab -->
    <mat-tab>
      <span mat-dialog-title translate>Change chat access</span>
      <mat-dialog-content>
        <div class="tab-content sub-content">
          <mat-radio-group [formControl]="isMessageBoardControl" color="primary">
            <mat-radio-button [value]="false">
              <div translate>All members</div>
              <div translate class="sub-text">Every room member can participate in the chat.</div>
            </mat-radio-button>
            <mat-radio-button [value]="true">
              <div translate>Moderators</div>
              <div translate class="sub-text">Only the moderators can participate in the chat.</div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button (click)="cancelFormControlEditing(isMessageBoardControl)"><span translate>Cancel</span></button>
          <button mat-button (click)="saveChatAccess()" color="primary" [disabled]="!isMessageBoardControl.dirty"><span translate>Save</span></button>
        </div>
      </mat-dialog-actions>
    </mat-tab>

    <!-- 3 Reactions Tab -->
    <mat-tab>
      <span mat-dialog-title translate>Reactions</span>
      <mat-dialog-content>
        <div class="tab-content sub-content">
          <mat-radio-group [formControl]="isEmoteAllowedControl" color="primary">
            <mat-radio-button [value]="true">
              <div translate>Allowed</div>
              <div translate class="sub-text">Allow emote reactions to chat messages.</div>
            </mat-radio-button>
            <mat-radio-button [value]="false">
              <div translate>Not allowed</div>
              <div translate class="sub-text">Disable emote reactions to chat messages.</div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button (click)="cancelFormControlEditing(isEmoteAllowedControl)"><span translate>Cancel</span></button>
          <button mat-button (click)="saveEmoteReaction()" color="primary" [disabled]="!isEmoteAllowedControl.dirty"><span translate>Save</span></button>
        </div>
      </mat-dialog-actions>
    </mat-tab>

    <!-- 4 Advanced Settings Tab -->
    <mat-tab>
      <div class="tab-content">
        <mat-tab-group mat-tab-group-headerless dynamicHeight [selectedIndex]="activeAdvancedSettingsTab">
          <!-- 0 Advanced Settings Main Tab -->
          <mat-tab>
            <span mat-dialog-title translate>Advanced settings</span>
            <mat-dialog-content>
              <div class="line-container">
                <button mat-button (click)="openAdvancedSettingsContent(1)">
                  <div class="line"><mat-icon>key</mat-icon><span class="line-label" translate>Room configuration state</span></div>
                </button>
              </div>
            </mat-dialog-content>
            <mat-dialog-actions align="end">
              <div class="action-container">
                <button mat-button (click)="openContent(0)">Back</button>
              </div>
            </mat-dialog-actions>
          </mat-tab>

          <!-- 1 Room Configuration State Tab -->
          <mat-tab>
            <span mat-dialog-title translate>Room configuration state</span>
            <mat-dialog-content>
              <div class="line-container sub-tab-line">
                <div class="content-label" translate>This is the reference to the room's current configuration state. Use this reference to connect a Drive to this room in the Nano Client.</div>
                <div class="sub-input-container">
                  <mat-form-field appearance="outline">
                    <mat-label>Reference string</mat-label>
                    <input matInput placeholder="Reference string" [ngModel]="referenceString" />
                  </mat-form-field>
                  <button mat-icon-button color="primary" (click)="copy(referenceString)">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                </div>
              </div>
            </mat-dialog-content>
            <mat-dialog-actions align="end">
              <div class="action-container">
                <button mat-button (click)="openAdvancedSettingsContent(0)"><span translate>Back</span></button>
              </div>
            </mat-dialog-actions>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-tab>

    <!-- 5 Room Members List -->
    <mat-tab>
      <span mat-dialog-title translate>Members</span>
      <mat-dialog-content>
        <div class="tab-content member-list-tab">
          <div class="content-label">
            <div class="member-list-input">
              <mat-form-field appearance="fill">
                <mat-label>Name or Contact ID</mat-label>
                <input matInput placeholder="Name or Contact ID" [formControl]="memberSearchControl" />
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="member-list-container">
            <ng-container *ngIf="roomMembers.length > 0; else loadingMembersContent">
              <div *ngFor="let member of activeRoomMembersList" class="member-list-item-container">
                <div class="member-list-item-content">
                  <app-avatar [accountAvatarRecord]="member.user" appShowUserInfo></app-avatar>
                  <button mat-button (click)="openDetailDialog(member.user.id)" [disabled]="member.permissions.isOwner || isBeingKicked(member) || !myRoomPermission.canEditPermissions">
                    <div class="member-list-item">
                      <div class="member-list-name">
                        <span class="member-name notranslate">{{ member.user.avatarName }}</span>
                        <span class="member-title">{{ getMemberTitle(member) | translate }}</span>
                      </div>
                      <div class="member-perm-title">
                        {{ getMemberPermTitle(member) | translate }}
                      </div>
                    </div>
                  </button>
                  <ng-container *ngIf="member.permissions.isOwner; else memberContent">
                    <button mat-icon-button [disabled]="true">
                      <mat-icon>stars</mat-icon>
                    </button>
                  </ng-container>
                  <ng-template #memberContent>
                    <ng-container *ngIf="!isBeingModified(member.user); else modifiedContent">
                      <button mat-icon-button *ngIf="canKick(member)" (click)="kickMember(member)" color="primary">
                        <mat-icon>person_remove</mat-icon>
                      </button>
                      <button mat-icon-button *ngIf="!canKick(member)" [disabled]="true">
                        <mat-icon>person</mat-icon>
                      </button>
                    </ng-container>
                    <ng-template #modifiedContent>
                      <ng-container *ngIf="!isBeingKicked(member); else beingKickedContent">
                        <button mat-icon-button [disabled]="true">
                          <mat-icon>edit_note</mat-icon>
                        </button>
                      </ng-container>
                      <ng-template #beingKickedContent>
                        <button mat-icon-button (click)="revertKick(member)" color="primary">
                          <mat-icon>replay</mat-icon>
                        </button>
                      </ng-template>
                    </ng-template>
                  </ng-template>
                </div>
                <mat-divider *ngIf="lastOfRole(member)"></mat-divider>
              </div>
            </ng-container>
            <ng-template #loadingMembersContent>
              <div class="align-center">
                <mat-spinner diameter="20"></mat-spinner>
              </div>
            </ng-template>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button (click)="cancelMemberPermissionEdit()"><span translate>Cancel</span></button>
          <button mat-button (click)="savePermissions()" color="primary" [disabled]="Object.keys(modifiedMemberPermissionDict).length === 0"><span translate>Save</span></button>
        </div>
      </mat-dialog-actions>
    </mat-tab>

    <!-- 6 Room Members Invite -->
    <mat-tab>
      <span mat-dialog-title
        ><span translate>Invite new member</span><span>({{ roomMembers.length }}/{{ maxMembers }})</span></span
      >
      <mat-dialog-content>
        <div class="tab-content invite-list-tab">
          <div class="content-label">
            <div class="invite-list-input">
              <mat-form-field appearance="fill">
                <mat-label>Name or Contact ID</mat-label>
                <input matInput placeholder="Name or Contact ID" [formControl]="inviteSearchControl" />
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
          </div>

          <mat-dialog-content *ngIf="roomMembers.length > 0; else loadingMembersContent">
            <div class="invite-list-container">
              <button mat-button *ngFor="let inviteUser of activeInviteList" [disabled]="isMember(inviteUser)" (click)="openDetailDialog(inviteUser.id)">
                <div class="invite-list-item">
                  <app-avatar [accountAvatarRecord]="inviteUser"></app-avatar>
                  <div class="invite-list-name notranslate">{{ inviteUser.avatarName }}</div>
                  <div>
                    <mat-icon *ngIf="isMember(inviteUser)">check</mat-icon>
                    <mat-icon *ngIf="isBeingModified(inviteUser)">edit_note</mat-icon>
                    <mat-icon *ngIf="!isBeingModified(inviteUser) && !isMember(inviteUser)" color="primary">person_add</mat-icon>
                  </div>
                </div>
              </button>
            </div>
          </mat-dialog-content>
          <ng-template #loadingMembersContent>
            <div class="align-center">
              <mat-spinner diameter="20"></mat-spinner>
            </div>
          </ng-template>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button (click)="cancelMemberPermissionEdit()"><span translate>Cancel</span></button>
          <button mat-button (click)="savePermissions()" color="primary" [disabled]="Object.keys(modifiedMemberPermissionDict).length === 0"><span translate>Save</span></button>
        </div>
      </mat-dialog-actions>
    </mat-tab>

    <!-- 7 Drive Overview -->
    <mat-tab>
      <app-drive-overview *ngIf="!loading && meRoomMember.permissions?.isOwner" [roomId]="resourceId" [roomMembers]="roomMembers" (backEvent)="openContent(0)"></app-drive-overview>
    </mat-tab>
  </div>
</mat-tab-group>
