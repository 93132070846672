import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DomSanitizer } from '@angular/platform-browser';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { RoomRecord } from 'src/app/shared/server-services/query-records/room-records';
import { RoomService } from 'src/app/shared/server-services/room.service';
import { BlobService } from 'src/app/shared/services/blob.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NativeAppService } from 'src/app/shared/services/native-app.service';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { NanoService } from '../../../shared/nano/nano.service';
import {
  DownloadManagerService,
  FileManagerDirection,
  FileManagerType,
} from '../../../shared/services/download-manager-service';
import { scrollToFileObserver } from '../../resource-page/drive-window/drive-layout/drive-layout.component';

@Component({
  selector: 'app-download-manager-file',
  templateUrl: './download-manager-file.component.html',
  styleUrls: ['./download-manager-file.component.scss'],
})
export class DownloadManagerFileComponent implements OnInit, OnDestroy {
  @Input() file: FileManagerType;
  @Input() canResumeDownload: boolean;
  public chunkSizeM;
  public roomData: RoomRecord;
  public canShowAsImage: boolean = false;
  public showPreview: boolean = false;
  public safeBlobURL;
  // for browser file from computer; do not forget to revoke
  // created only, when toggleView is triggered
  private fileObjectURL;
  public isOnApp: boolean = false;

  constructor(
    private nanoService: NanoService,
    private roomService: RoomService,
    private routerHandler: RouterHandler,
    private downloadManagerService: DownloadManagerService,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private blobService: BlobService,
    private nativeAppService: NativeAppService,
    private bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
    private renderer: Renderer2
  ) {
    this.chunkSizeM = NanoService.NETWORK_FILE_CHUNK_SIZE;
    this.isOnApp = nativeAppService.isOnApp();
  }

  ngOnInit(): void {
    this.roomService.getRoom(this.file.resourceId).then((data) => {
      this.roomData = data;
    });

    if (['bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png'].includes(this.file.file.ext)) {
      this.canShowAsImage = true;
    }
  }

  ngOnDestroy() {
    if (this.fileObjectURL) {
      this.blobService.revokeObjectURL(this.fileObjectURL);
    }
  }

  public toggleViewer() {
    this.showPreview = !this.showPreview;
    if (this.showPreview) {
      if (this.file.direction == FileManagerDirection.UP) {
        this.fileObjectURL = this.blobService.createObjectURL(<File>this.file.file.rawData);
        this.safeBlobURL = this.sanitizer.bypassSecurityTrustUrl(this.fileObjectURL);
      } else {
        this.safeBlobURL = this.sanitizer.bypassSecurityTrustUrl(this.file.objectURL);
      }
    }
  }

  public pause() {
    this.downloadManagerService.pauseFile(this.file);
  }

  public resume() {
    this.downloadManagerService.resumeFile(this.file);
  }

  /**
   * stored blob can be redownload for a short time, if
   * you accidentally denied or cancel the download popup
   */
  public redownload() {
    var a;
    a = this.renderer.createElement('a');
    this.renderer.setProperty(a, 'href', this.file.objectURL);
    this.renderer.setProperty(a, 'download', this.file.file.fullName);
    //document.body.appendChild(a);
    //a.style = "display: none";
    a.click();
    //a.remove();
  }

  public open() {
    this.nativeAppService.openFile(this.file.mobileLocalPath + '/' + this.file.file.fullName);
  }

  public remove() {
    if (this.file.isInProgress() && this.file.currPart > 100) {
      // this file is already downloaded more than 50 M, ask if the user is sure about to delete
      this.dialogService
        .openConfirmDialog(
          marker('Remove downloading file'),
          marker('Are you sure you want to stop the file progress?') +
            '( ' +
            this.file.file.fullName +
            ' )'
        )
        .subscribe((response) => {
          if (response) {
            this.downloadManagerService.removeFromFileList(this.file);
          }
        });
    } else {
      this.downloadManagerService.removeFromFileList(this.file);
    }

    //removing error file, must remove error notification too
    if (this.nativeAppService.isOnApp() && this.file.isError()) {
      this.nativeAppService.clearNotification(0); // CordovaService.PROGRESS_ERROR_NOTIFICATION_ID);
    }
  }

  public openPath() {
    if (this.isUploading) return;

    let fragment = this.routerHandler.getRoute().fragment;
    const splittedPath = this.file.path.split('/');
    fragment['path'] = splittedPath.slice(0, -1).join('/');
    fragment['file'] = splittedPath[splittedPath.length - 1];

    if (fragment?.['from']) {
      delete fragment['from'];
    }

    this.routerHandler.navigate(['/room/', this.file.resourceId, 'drive'], {
      fragment: this.routerHandler.fragmentToRaw(fragment),
    });

    if (fragment?.['file']) {
      scrollToFileObserver.next(fragment['file']);
    }

    this.bottomSheet.dismiss();
  }

  get isUploading(): boolean {
    return this.file.isInProgress() && this.file.direction == 'up';
  }

  get fullErrorDesc(): string {
    return this.file.errorDetail.getFullErrorDescription(this.translateService);
  }
}
