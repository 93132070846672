/**
 * Encryption of dialogue messages between accounts.
 */

import { CryptoContext } from '../base';
import { DialogueConfigDumpArgs, DialogueConfigLoadArgs } from './args';
import { DialogueConfigV1 } from './v1';

export let DIALOGUE_CRYPTO_CONTEXT = new CryptoContext<
  DialogueConfigLoadArgs,
  DialogueConfigDumpArgs
>([new DialogueConfigV1()]);
