import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';
import { LicenseBundleRecord } from 'src/app/shared/server-services/query-records/nano-records';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-bundled-licenses-dialog',
  templateUrl: './bundled-licenses-dialog.component.html',
  styleUrls: ['./bundled-licenses-dialog.component.scss'],
})
export class BundledLicensesDialogComponent extends NanoDialogBaseComponent<BundledLicensesDialogComponent> {
  protected tokens: string[];
  protected pageCount: number = 1;
  protected currentPage: number = 0;
  protected PAGE_SIZE: number = 100;
  private allTokens: string[];

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public licenseBundle: LicenseBundleRecord,
    private dialogService: DialogService,
    private clipboardService: ClipboardService,
    private snackbarService: SnackBarService
  ) {
    super(injector);
    this.allTokens = licenseBundle.tokens;

    this.setPage();
  }

  nextPage(dir: number): void {
    this.currentPage += dir;
    this.setPage();
  }

  setPage(): void {
    this.pageCount = Math.ceil(this.allTokens.length / this.PAGE_SIZE);

    const start = this.currentPage * this.PAGE_SIZE;
    const end = start + this.PAGE_SIZE;
    this.tokens = this.allTokens.slice(start, end);
  }

  copy(token: string): void {
    this.clipboardService
      .copy(token)
      .then(() => {
        this.snackbarService.showSnackbar(marker('License key copied!'));
      })
      .catch((err) => {
        console.error('clipboard err', err);
        this.dialogService.openAlertDialog(
          marker('Copy Error'),
          marker('Could not copy to the clipboard')
        );
      });
  }
}
