<div id="emoji-picker" *ngIf="allEmoji.length > 0">
  <div id="categories">
    <span *ngFor="let group of categories" class="emoji-category" [ngClass]="{ 'active-group': activeGroup === group.icon }">
      <a (click)="jumpToGroup(group.icon)">{{ group.icon }}</a>
    </span>
  </div>
  <div id="emoji-collection" #emojiCollection>
    <!--
            <div *ngFor="let group of categories" class="group" [attr.data-group]="group.icon">
                <div class="group-name"><a id="{{group.icon}}">{{group.name}}</a></div>
                <div class="emoji-list">
                    <span *ngFor="let emoji of group.emojies" (click)="selectEmoji(emoji)">
                        <img *ngIf="emoji.twitter" [defaultImage]="defaultImage" [lazyLoad]="'https://twemoji.maxcdn.com/v/13.0.1/72x72/'+emoji.twitter+'.png'"> 
                    </span>
                </div>
            </div>
        -->
  </div>
</div>
