<div id="viewer-cont" (pointerup)="pointerUp($event)" [ngClass]="{ opened: opened }" (click)="clickOutside($event)" (pointerdown)="startSwiping($event)">
  <div id="media-cont">
    <button class="nav-button" color="primary" mat-mini-fab (click)="showPreviousImage()"><mat-icon>arrow_back</mat-icon></button>
    <div id="image-cont">
      <button mat-mini-fab color="primary" class="close" (click)="close()"><mat-icon>close</mat-icon></button>
      <mat-progress-bar [mode]="currentChunk === 0 ? 'indeterminate' : 'determinate'" [value]="(currentChunk / maxChunk) * 100" *ngIf="!loaded"></mat-progress-bar>
      <div id="image-inside-cont">
        <img *ngIf="!loaded && safeUrl" id="preview-thumbnail" [src]="thumbnailSafeUrl" />
        <img *ngIf="safeUrl" id="image" [src]="safeUrl" />
        <div class="error-window" *ngIf="error" translate>Error happened during the preview load</div>
      </div>
      <div id="file-menu" *ngIf="file">
        <span>{{ file.fullName }}</span>
        <div id="actions">
          <button mat-icon-button (click)="download()">
            <mat-icon>download</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <button class="nav-button" color="primary" mat-mini-fab (click)="showNextImage()"><mat-icon>arrow_forward</mat-icon></button>
    <div class="mobile-button-container">
      <button mat-mini-fab color="primary" (click)="showPreviousImage()"><mat-icon>arrow_back</mat-icon></button>
      <button mat-mini-fab color="primary" (click)="showNextImage()"><mat-icon>arrow_forward</mat-icon></button>
    </div>
  </div>
</div>
