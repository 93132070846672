import { SubscriptionServiceEvent } from '../../../../../../../server-services/subscription-event';
import { workspaceSubscriptionAccountContactEventHandler } from './account-contact-event/ws-sub-account-contact-event-handler';
import { workspaceSubscriptionAccountEventHandler } from './account-event/ws-sub-account-event-handler';
import { workspaceSubscriptionAccountOfferEventHandler } from './account-offer-event/ws-sub-account-offer-event-handler';
import { workspaceSubscriptionAccountTrustEventHandler } from './account-trust-event/ws-sub-account-trust-event-handler';
import { workspaceSubscriptionDialogueEventHandler } from './dialogue-event/ws-sub-dialogue-event-handler';
import { workspaceSubscriptionDialogueMessageEventHandler } from './dialogue-message-event/ws-sub-dialogue-message-event-handler';
import { workspaceSubscriptionDialogueTypingEventHandler } from './dialogue-typing-event/ws-sub-dialogue-typing-event-handler';
import { workspaceSubscriptionNanoWaitingEventHandler } from './nano-waiting-event/ws-sub-nano-waiting-event-handler';
import { workspaceSubscriptionResourceGroupEventHandler } from './resource-group-event/ws-sub-resource-group-event-handler';
import { workspaceSubscriptionRoomAccountPermissionEventHandler } from './room-account-permission-event/ws-sub-rap-event-handler';
import { workspaceSubscriptionRoomEventHandler } from './room-event/ws-sub-room-event-handler';
import { workspaceSubscriptionRoomMessageEventHandler } from './room-message-event/ws-sub-room-message-event-handler';
import { workspaceSubscriptionRoomPermissionEventHandler } from './room-permission-event/ws-sub-r-perm-event-handler';
import { workspaceSubscriptionRoomTypingEventHandler } from './room-typing-event/ws-sub-room-typing-event';
import { WorkspaceChangeSubscriptionHandlerMap } from './types';

export let onWorkspaceChangesSubscription: WorkspaceChangeSubscriptionHandlerMap = {
  [SubscriptionServiceEvent.ACCOUNT_EVENT]: workspaceSubscriptionAccountEventHandler,
  [SubscriptionServiceEvent.ACCOUNT_CONTACT_EVENT]: workspaceSubscriptionAccountContactEventHandler,
  [SubscriptionServiceEvent.ACCOUNT_TRUST_EVENT]: workspaceSubscriptionAccountTrustEventHandler,
  [SubscriptionServiceEvent.DIALOGUE_EVENT]: workspaceSubscriptionDialogueEventHandler,
  [SubscriptionServiceEvent.DIALOGUE_MESSAGE_EVENT]:
    workspaceSubscriptionDialogueMessageEventHandler,
  [SubscriptionServiceEvent.RESOURCE_GROUP_EVENT]: workspaceSubscriptionResourceGroupEventHandler,
  [SubscriptionServiceEvent.ROOM_EVENT]: workspaceSubscriptionRoomEventHandler,
  [SubscriptionServiceEvent.ROOM_MESSAGE_EVENT]: workspaceSubscriptionRoomMessageEventHandler,
  [SubscriptionServiceEvent.ROOM_PERMISSION_EVENT]: workspaceSubscriptionRoomPermissionEventHandler,
  [SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT]:
    workspaceSubscriptionRoomAccountPermissionEventHandler,
  [SubscriptionServiceEvent.NANO_WAITING_EVENT]: workspaceSubscriptionNanoWaitingEventHandler,
  [SubscriptionServiceEvent.ACCOUNT_OFFER_EVENT]: workspaceSubscriptionAccountOfferEventHandler,
  [SubscriptionServiceEvent.DIALOGUE_TYPING_EVENT]: workspaceSubscriptionDialogueTypingEventHandler,
  [SubscriptionServiceEvent.ROOM_TYPING_EVENT]: workspaceSubscriptionRoomTypingEventHandler,
};
