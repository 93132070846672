import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { isNanoFeatureSupported, NanoFeature } from 'src/app/shared/drive-version';
import { NanoService } from 'src/app/shared/nano/nano.service';
import { RoomService } from 'src/app/shared/server-services/room.service';
import { BlobService } from 'src/app/shared/services/blob.service';
import { DriveFile } from '../../../drive-window/drive-layout/drive-file';

@Component({
  selector: 'app-input-attachment',
  templateUrl: './input-attachment.component.html',
  styleUrls: ['./input-attachment.component.scss'],
})
export class InputAttachmentComponent implements OnInit {
  @Input() file: DriveFile;
  @Output() removeEvent: EventEmitter<string> = new EventEmitter<string>();

  public showIcon: boolean = true;
  public thumbnailBlob;

  constructor(
    private nanoService: NanoService,
    private blobService: BlobService,
    private roomService: RoomService
  ) {}

  ngOnInit(): void {
    if (!this.file.resourceId) {
      this.showIcon = false;
      this.thumbnailBlob = this.file.rawData;
      return;
    }

    if (this.file.ext == 'dir') {
      this.showIcon = true;
      return;
    }

    this.roomService.getNanoSession(this.file.resourceId).then((nanoSession) => {
      this.nanoService.peekFile({
        resourceId: this.file.resourceId,
        path: this.file.path,
        responseCallback: (peek) => {
          this.showIcon = false;
          if (peek.img0) this.thumbnailBlob = this.blobService.new([peek.img0.buffer]);
          else this.showIcon = true;
        },
        errorCallback: (err) => {
          this.showIcon = true;
          console.error('attachment err', err);
        },
        nanoVersion: nanoSession.version,
      });
    });
  }

  onLoadError() {
    this.showIcon = true;
  }
}
