/**
 * Encryption of shared data of a room (group of accounts).
 */

import { CryptoContext } from '../base';
import { RoomConfigDumpArgs, RoomConfigLoadArgs } from './args';
import { RoomConfigV1 } from './v1';

export let ROOM_CRYPTO_CONTEXT = new CryptoContext<RoomConfigLoadArgs, RoomConfigDumpArgs>([
  new RoomConfigV1(),
]);
