import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NanoValidators } from '../../nano-validators';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

export interface RenameFileDialogData {
  name: string;
  isFolder: boolean;
}

@Component({
  selector: 'app-rename-file-dialog',
  templateUrl: './rename-file-dialog.component.html',
  styleUrls: ['./rename-file-dialog.component.scss'],
})
export class RenameFileDialogComponent extends NanoDialogBaseComponent<RenameFileDialogComponent> {
  public fileNameControl = new FormControl<string>('', [
    Validators.required,
    Validators.maxLength(255),
    NanoValidators.noSlashes,
  ]);
  public editExtensionControl = new FormControl<boolean>(false);
  public extension: String = '';
  public isFolder: boolean = false;

  private originalName: string = '';

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: RenameFileDialogData
  ) {
    super(injector);
    this.isFolder = data.isFolder;
    const lastPeriod = data.name.lastIndexOf('.');
    if (lastPeriod > 0 && !this.isFolder) {
      this.fileNameControl.setValue(data.name.substring(0, lastPeriod));
      this.extension = data.name.substring(lastPeriod, this.data.name.length);
    } else {
      this.fileNameControl.setValue(data.name);
    }

    this.originalName = this.fileNameControl.value;

    this.editExtensionControl.valueChanges.subscribe((enabled) => {
      const currentName = this.fileNameControl.value;
      if (enabled) {
        this.fileNameControl.setValue(currentName + this.extension);
      } else {
        this.fileNameControl.setValue(this.originalName);
      }
    });
  }

  public close(): void {
    let newName = this.fileNameControl.value;
    if (!this.editExtensionControl.value) newName += this.extension;
    this.dialogRef.close(newName);
  }
}
