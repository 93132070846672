<mat-card appearance="outlined">
  <div class="result-row">
    <div class="result-thumbnail" (click)="openPath()">
      <app-blob-viewer *ngIf="img0" [blob]="img0" type="image"></app-blob-viewer>
      <span *ngIf="!img0" class="mdi-thumbnail mdi mdi-{{ result.file.icon }} file-{{ result.file.icon }}"></span>
    </div>
    <div class="result-data">
      <div class="result-header">
        <span class="mdi mdi-{{ result.file.icon }} file-{{ result.file.icon }}"></span>
        <span class="file-name" (click)="openPath()">{{ result.file.fullName }}</span>
      </div>
      <div class="result-header-subdata">
        <span class="file-size mat-small">{{ result.file.size | formatBytes }}</span>
        <span class="room-info" (click)="openRoom()"
          ><app-avatar [blob]="result.resourceAvatar"></app-avatar><span class="resource-name">{{ result.resourceName }}</span></span
        >
        <span class="date mat-small">{{ result.file.createdAt | date: 'short' }}</span>
      </div>
      <div class="result-snippet" *ngIf="result.snippets.length > 0">
        <span class="mat-small" *ngFor="let snippet of result.snippets" [innerHTML]="snippet"></span>
      </div>
      <div class="result-footer mat-small drive-path">
        <mat-icon (click)="openPath()">computer</mat-icon>
        <ul (click)="openPath()">
          <li *ngFor="let pathSlice of result.path | pathToArray">
            <mat-icon>keyboard_arrow_right</mat-icon>
            <span class="mat-small">{{ pathSlice }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</mat-card>
