import { AbstractConfigDumpArgs, AbstractConfigLoadArgs } from '../base';

export class NanoDataConfigLoadArgs extends AbstractConfigLoadArgs {
  public key;

  constructor(cipher: Uint8Array, key: Uint8Array) {
    super(cipher), (this.key = key);
  }
}

export class NanoDataConfigDumpArgs extends AbstractConfigDumpArgs {
  public plain;
  public key;

  constructor(plain: Uint8Array, key: Uint8Array) {
    super();
    this.plain = plain;
    this.key = key;
  }
}
