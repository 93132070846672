<div>
  <div class="sub-page">
    <h1 translate>Settings</h1>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Sound volume</label>
        <span translate>Notification sound volume.</span>
      </div>
      <div class="setting-button-col slider-col">
        <mat-slider name="volumeSetting" color="primary" min="0" max="1" step="0.1" (mouseup)="playTestSound()"
          ><input (valueChange)="volumeChange($event)" matSliderThumb [(ngModel)]="volumeValue"
        /></mat-slider>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Night mode</label>
        <span translate>Night mode reduces the light emitted by device screens while maintaining the minimum colour contrast ratios required for readability.</span>
      </div>
      <div class="setting-button-col">
        <mat-slide-toggle color="primary" [checked]="nightMode" (change)="toggleTheme()"></mat-slide-toggle>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>In-app message notifications</label>
        <span></span>
      </div>
      <div class="setting-button-col">
        <mat-slide-toggle color="primary" [checked]="notificationWidgetEnabled" (change)="toggleNotificationWidget()"></mat-slide-toggle>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Message display</label>
        <!-- <span translate>Groups chat messages according to the selected policy.</span> -->
      </div>
      <div class="setting-button-col">
        <mat-form-field appearance="fill">
          <mat-select [value]="selectedMessageDisplay" (selectionChange)="changeMessageDisplay($event.value)">
            <mat-option *ngFor="let display of messageDisplays" [value]="display.value">{{ display.displayName | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Chat message grouping</label>
        <!-- <span translate>Groups chat messages according to the selected policy.</span> -->
      </div>
      <div class="setting-button-col">
        <mat-form-field appearance="fill">
          <!-- <mat-label translate>Policy</mat-label> -->
          <mat-select [value]="selectedChatGroupingPolicy" (selectionChange)="changeChatGroupingPolicy($event.value)">
            <mat-option *ngFor="let policy of chatGroupingPoliciesSelectValues" [value]="policy.value">{{ policy.displayName | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Language</label>
        <span></span>
      </div>
      <div class="setting-button-col">
        <mat-form-field appearance="fill">
          <mat-label translate>Language</mat-label>
          <mat-select [value]="selectedLang" (selectionChange)="changeLanguage($event.value)">
            <mat-option *ngFor="let lang of supportedLanguages | keyvalue" [value]="lang.key">{{ lang.value }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="setting-row panel">
      <div class="setting-info-col">
        <label translate>About Nano</label>
      </div>
      <div class="setting-button-col">
        <a routerLink="/about" translate>Show info</a>
      </div>
    </div>

    <div class="setting-row panel">
      <div class="setting-info-col">
        <label translate>Advanced Settings</label>
      </div>
      <div class="setting-button-col">
        <a routerLink="/advanced-settings" translate>Open</a>
      </div>
    </div>
  </div>
</div>
