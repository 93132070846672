import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateAccountTrustEventSubscriptionHandler } from './ws-sub-account-trust-event-create-handler';
import { onDeleteAccountTrustEventSubscriptionHandler } from './ws-sub-account-trust-event-delete-handler';
import { onModifyAccountTrustEventSubscriptionHandler } from './ws-sub-account-trust-event-modify-handler';

export let workspaceSubscriptionAccountTrustEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateAccountTrustEventSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyAccountTrustEventSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteAccountTrustEventSubscriptionHandler,
};
