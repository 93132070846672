import { Component, EventEmitter, Input, Output } from '@angular/core';
import { emojiShortcodeToUnicode } from 'src/app/shared/emoji-codes';
declare var twemoji;

@Component({
  selector: 'app-emoji-shortcode-picker',
  templateUrl: './emoji-shortcode-picker.component.html',
  styleUrls: ['./emoji-shortcode-picker.component.scss'],
})
export class EmojiShortcodePickerComponent {
  public static emojiUrl = 'assets/img/twemoji/72x72/';
  public static emojiExt = 'png';

  /**
   * event, it triggers, when the user select an emoji via component
   * @paranm $event: {
   *    shortcode: string
   *    emojiSrc: twemoji src of the actual emoji
   *    emoji: unicode emoji
   * }
   */
  @Output() readonly selectEvent = new EventEmitter<string>();
  hint = [];
  maxHint = 10;

  /**
   * Incoming prefix of the emoji without ":"
   */
  @Input() set prefix(prefix: string) {
    this.hint = [];
    if (prefix && prefix.length >= 2) {
      for (let key in emojiShortcodeToUnicode) {
        if (this.hint.length == this.maxHint) break;

        if (key.startsWith(prefix)) {
          let emojiUni = emojiShortcodeToUnicode[key];
          //let emojiCodePoint = twemoji.convert.toCodePoint(emojiUni);
          let emojiCodePoint;
          twemoji.parse(emojiUni, {
            callback: (icon, options) => {
              if (icon) {
                emojiCodePoint = icon;
              } else {
                console.warn('can not convert emoji', emojiUni);
              }
            },
          });
          let emojiSrc =
            EmojiShortcodePickerComponent.emojiUrl +
            emojiCodePoint +
            '.' +
            EmojiShortcodePickerComponent.emojiExt;
          this.hint.push({
            shortcode: key,
            emojiSrc: emojiSrc,
            emoji: emojiUni,
          });
        }
      }
    }
  }

  constructor() {}

  public selectEmoji(emoji) {
    this.selectEvent.emit(emoji);
  }
}
