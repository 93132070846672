import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { TwoFactorDialogComponent } from '../two-factor-dialog.component';

@Component({
  selector: 'app-two-factor-alert-dialog',
  templateUrl: './two-factor-alert-dialog.component.html',
  styleUrls: ['./two-factor-alert-dialog.component.scss'],
})
export class TwoFactorAlertDialogComponent extends TwoFactorDialogComponent {
  constructor(@Inject(INJECTOR) injector: Injector) {
    super(injector);
  }
}
