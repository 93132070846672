<h3 mat-dialog-title translate>Permissions</h3>
<mat-dialog-content class="content-body mat-typography">
  <div class="avatar-container">
    <app-avatar [accountAvatarRecord]="data.accountAvatar"></app-avatar>
    <span class="notranslate">{{ data.accountAvatar.avatarName }}</span>
  </div>
  <div>
    <div class="label-row">Room permission</div>
    <mat-radio-group [formControl]="roomPermissionControl" color="primary">
      <mat-radio-button [value]="RoomPermission.ADMIN">
        <div translate>Admin</div>
        <div translate class="sub-text">Administrators have full privileges.</div>
      </mat-radio-button>
      <mat-radio-button [value]="RoomPermission.MODERATOR">
        <div translate>Moderator</div>
        <div translate class="sub-text">Moderators can change room information, edit and delete messages, and write to the messageboard.</div>
      </mat-radio-button>
      <mat-radio-button [value]="RoomPermission.MEMBER">
        <div translate>Member</div>
        <div translate class="sub-text">Default member privileges.</div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <div class="label-row">Drive access</div>
    <mat-radio-group [formControl]="driveAccessControl" color="primary">
      <mat-radio-button [value]="DrivePermission.FULL_ACCESS">
        <div translate>Full access</div>
        <div translate class="sub-text">With full access the member has full control over the files and folders.</div>
      </mat-radio-button>
      <mat-radio-button [value]="DrivePermission.UPLOAD_ONLY">
        <div translate>Upload only</div>
        <div translate class="sub-text">Upload only members can download and upload files and folders.</div>
      </mat-radio-button>
      <mat-radio-button [value]="DrivePermission.READ_ONLY">
        <div translate>Read only</div>
        <div translate class="sub-text">Read only members can browse and download files and folders.</div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()"><span translate>Cancel</span></button>
  <button mat-raised-button (click)="save()" color="primary" [disabled]="!data.isInviting && !roomPermissionControl.dirty && !driveAccessControl.dirty"><span translate>Accept</span></button>
</mat-dialog-actions>
