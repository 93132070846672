import { SelfAccountKeyring } from './crypto/keyring/account_self';
import { base64 } from 'rfc4648';
import msgpack from 'msgpack-lite';
import { AppStorage } from 'src/app/shared/app-storage';

export class SessionCrypto {
  public static readonly SELF_KR = 'self_kr';
  public static readonly CLIENT_SESSION_KEY = 'client_session_key';
  public static readonly SELF_KR_STORAGE_KEY = 'self_keyring';
  public static readonly SALT_KEY = 'salt';

  public static saveLocalStorage(
    serverSessionKey: Uint8Array,
    self_kr: SelfAccountKeyring
  ): Promise<void> {
    let clientSessionKey = crypto.getRandomValues(new Uint8Array(32));
    let salt = crypto.getRandomValues(new Uint8Array(16));

    return self_kr.dump_local_storage(serverSessionKey, clientSessionKey, salt).then((dump) => {
      let store = msgpack.encode({
        [this.SELF_KR]: dump,
        [this.CLIENT_SESSION_KEY]: clientSessionKey,
        [this.SALT_KEY]: salt,
      });

      AppStorage.setItem(this.SELF_KR_STORAGE_KEY, base64.stringify(store));
      return;
    });
  }

  public static loadLocalStorage(serverSessionKey: Uint8Array): Promise<SelfAccountKeyring> {
    let store = AppStorage.getItem(this.SELF_KR_STORAGE_KEY);
    if (store) {
      store = msgpack.decode(base64.parse(store));
      return SelfAccountKeyring.load_local_storage(
        store[this.SELF_KR],
        serverSessionKey,
        store[this.CLIENT_SESSION_KEY],
        store[this.SALT_KEY] ? store[this.SALT_KEY] : new Uint8Array([])
      );
    }
    return Promise.reject('Can not find keyring in localstorage');
  }
}
