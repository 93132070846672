import { DialogueDetail } from 'src/app/shared/server-services/query-records/room-records';
import { WorkspaceSubscriptionDialogueMessageEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  dialogueMessagesQuery,
  getDialogueDetailQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onDeleteDialogueMessageEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionDialogueMessageEventRecord) => {
    let messages = cache.getCacheDataByRequest({
      query: dialogueMessagesQuery,
      variables: {
        peerId: event.id,
      },
    });

    if (!messages) {
      return;
    }

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id == event.messageId) {
        messages.splice(i, 1);
        break;
      }
    }

    if (cache.getCacheData('messages')[event.id]) {
      delete cache.getCacheData('messages')[event.id][event.messageId];
      console.log('delete msg');

      let dialogueDetails: DialogueDetail = cache.getCacheDataByRequest({
        query: getDialogueDetailQuery,
        variables: {
          id: event.id,
        },
      });

      if (dialogueDetails) {
        let index = dialogueDetails.pinnedMessageIds.indexOf(event.messageId);
        if (index > -1) {
          dialogueDetails.pinnedMessageIds.splice(index, 1);
          console.log(JSON.stringify(dialogueDetails.pinnedMessageIds));
        }
      }
    }
  };
