import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NanoDialogBaseComponent } from '../nano-base-dialog';
import { DialogData } from '../new-group-dialog/new-group-dialog.component';

@Component({
  selector: 'app-rename-group-dialog',
  templateUrl: './rename-group-dialog.component.html',
  styleUrls: ['./rename-group-dialog.component.scss'],
})
export class RenameGroupDialogComponent extends NanoDialogBaseComponent<RenameGroupDialogComponent> {
  groupNameControl = new UntypedFormControl('', [Validators.required]);

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super(injector);
    this.groupNameControl.setValue(data.name);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.groupNameControl.value);
  }
}
