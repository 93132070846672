import {
  AfterViewInit,
  Component,
  Inject,
  Injector,
  INJECTOR,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { debounceTime } from 'rxjs';
import { generateAvatar } from '../../avatar-generator';
import { NanoValidators } from '../../nano-validators';
import { ObjectSorter } from '../../object-sorter';
import { SafeRadix32 } from '../../safe-radix32';
import { AccountService } from '../../server-services/account.service';
import { AccountAvatarRecord, MeRecord } from '../../server-services/query-records/account-records';
import { WeightedSearch } from '../../weighted-search';
import { ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

@Component({
  selector: 'app-create-room-dialog',
  templateUrl: './create-room-dialog.component.html',
  styleUrls: ['./create-room-dialog.component.scss'],
})
export class CreateRoomDialogComponent
  extends NanoDialogBaseComponent<CreateRoomDialogComponent>
  implements OnInit, AfterViewInit
{
  roomNameControl = new FormControl<string>('', [
    Validators.required,
    Validators.maxLength(NanoValidators.ROOM_NAME_LENGTH),
  ]);
  inviteUserIdControl = new FormControl<string>('');

  @ViewChild('userSelectionList') userSelectionList: MatSelectionList;

  public avatarBlob: Blob = null;
  public filteredAccountList: Array<AccountAvatarRecord> = [];
  public accountList: Array<AccountAvatarRecord> = [];
  public selectedUsers: Array<AccountAvatarRecord> = [];
  private me: MeRecord;
  private avatarBuffer: ArrayBuffer = null;

  constructor(@Inject(INJECTOR) injector: Injector, private accountService: AccountService) {
    super(injector);

    generateAvatar(Math.random() + '').then((avatarBlob) => {
      this.avatarBlob = avatarBlob;
      avatarBlob.arrayBuffer().then((buffer) => {
        this.avatarBuffer = buffer;
      });
    });
  }
  ngAfterViewInit(): void {
    this.userSelectionList.selectionChange.subscribe((val) => {
      console.log('selected users changed', val);

      // new user selected
      if (val.options[0].selected) {
        this.selectedUsers.push(val.options[0].value);
      } else {
        // existing user deselected
        this.selectedUsers.splice(this.selectedUsers.indexOf(val.options[0].value), 1);
      }
    });
  }

  ngOnInit(): void {
    this.accountService.getAllAccount().then((res) => {
      this.accountList = res;
      this.filteredAccountList = this.accountList;
      this.sortList();
    });

    this.accountService.getMe().then((res) => {
      this.me = res;
    });

    this.inviteUserIdControl.valueChanges.pipe(debounceTime(500)).subscribe((val) => {
      this.searchForUser(val).then(() => {
        this.filterList(val);
        this.sortList();
      });
    });
  }

  public openEditAvatarDialog(): void {
    let ref = this.dialog.open(ImageCropperDialogComponent, { autoFocus: false });
    ref.afterClosed().subscribe((avatarImageBlob: Blob) => {
      if (!avatarImageBlob) return;

      this.avatarBlob = avatarImageBlob;
      avatarImageBlob.arrayBuffer().then((res: ArrayBuffer) => {
        this.avatarBuffer = res;
      });
    });
  }

  public shouldPreSelect(id: string): boolean {
    return this.selectedUsers.some((u) => u.id === id);
  }

  private searchForUser(searchTerm: string): Promise<void> {
    if (SafeRadix32.isSafeRadix(searchTerm)) {
      return this.accountService.getAccount(searchTerm).then((acc) => {
        if (acc && acc.id !== this.me.id) {
          if (this.accountList.indexOf(acc) === -1) {
            this.accountList.push(acc);
            // this.filteredAccountList.push(acc);
          }

          if (this.selectedUsers.indexOf(acc) === -1) {
            this.selectedUsers.push(acc);
          }
        }
      });
    } else {
      return Promise.resolve();
    }
  }

  private filterList(searchTerm: string): void {
    this.filteredAccountList = WeightedSearch.search<AccountAvatarRecord>(
      searchTerm,
      ['avatarName', 'id'],
      this.accountList,
      5
    );
  }

  private sortList(): void {
    ObjectSorter.sort(this.filteredAccountList, 'avatarName');
  }

  onSubmit() {
    this.dialogRef.close({
      roomName: this.roomNameControl.value,
      roomAvatarBuffer: this.avatarBuffer,
      inviteAvatarIdList: this.selectedUsers.map((su) => su.id),
    });
  }
}
