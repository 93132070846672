import { CryptoContext } from '../base';
import { AccountTrustConfigDumpArgs, AccountTrustConfigLoadArgs } from './args';
import {AccountTrustConfigV2} from "./v2";

export let ACCOUNT_TRUST_CRYPTO_CONTEXT = new CryptoContext<
  AccountTrustConfigLoadArgs,
  AccountTrustConfigDumpArgs
>([
  // NOTE: Version 1 was retired before it was utilized in production.
  // It had insufficient identity verification to protect from a colluding malicious server and a malicious peer.
  // See the docstring note of AbstractAccountTrustConfig._get_fingerprint for more info.
  // new AccountTrustConfigV1(true),
  new AccountTrustConfigV2(),
]);
