import { Injectable } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { NativeAppService } from './native-app.service';

@Injectable({
  providedIn: 'root',
})
export class AppCookieService {
  /**
   * As of iOS 14, you cannot use 3rd party cookies by default.
   * @todo extend plist by capacitor doc https://capacitorjs.com/docs/apis/cookies
   */
  private usePlugin: boolean = false;

  constructor(private cookieService: CookieService, private nativeAppService: NativeAppService) {
    // cookies are not working properly on ios, so we had to write plugin for that
    this.usePlugin = this.nativeAppService.isOnIOS();
  }

  public get(name: string): Promise<string> {
    if (this.usePlugin) {
      return this.nativeAppService.getCookie(name);
    }
    return Promise.resolve(this.cookieService.get(name));
  }

  public put(name: string, value: string, options: CookieOptions): Promise<void> {
    if (this.usePlugin) {
      return this.nativeAppService.setCookie(
        name,
        value,
        options.expires ? new Date(options.expires) : undefined
      );
    } else {
      return Promise.resolve(this.cookieService.put(name, value, options));
    }
  }

  public remove(name: string): Promise<void> {
    if (this.usePlugin) {
      return this.nativeAppService.removeCookie(name);
    } else {
      return Promise.resolve(this.cookieService.remove(name));
    }
  }
}
