import { Injectable } from '@angular/core';
import { GroupConfigBlockFlags, GroupConfigBlockMemberFlags } from '../crypto/group_config/block';
import { GroupConfigBlockChain } from '../crypto/group_config/chain';
import {
  FlagParser,
  MemberPermissions,
  RoomPermissions,
} from '../crypto/group_config/chain-flag-parser';
import { RoomKeyring } from '../crypto/keyring/room';
import { NanoService } from '../nano/nano.service';
import { CacheService } from '../services/cache/cache.service';
import { ServerRestApiService } from '../services/server-rest-api.service';
import { AccountService } from './account.service';
import { AuthService } from './auth.service';
import { AccountAvatarRecord } from './query-records/account-records';
import {
  PermissionRole,
  RoomMember,
  RoomMemberRecord,
  RoomMyPermissionRecord,
  RoomPermission,
  RoomPermissionRecord,
  roomConfigRecord,
} from './query-records/room-records';
import {
  createNewIdQuery,
  createRoomConfigBlockQuery,
  editRoomAccountPermissionQuery,
  roomConfigQuery,
  roomMemberQuery,
  roomMyPermissionsQuery,
  roomPermissionsQuery,
} from './querys';
import { RoomKeyringService } from './room-keyring.service';
import { SidebarService } from './sidebar.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private serverRestApiService: ServerRestApiService,
    private nanoService: NanoService,
    private cacheService: CacheService,
    private roomKeyringService: RoomKeyringService,
    private sidebarService: SidebarService
  ) {}

  public getMyRoomPermissionsRecord(resourceId): Promise<RoomMyPermissionRecord> {
    return this.serverRestApiService
      .query({
        query: roomMyPermissionsQuery,
        variables: { id: resourceId },
      })
      .catch((err) => {
        if (this.serverRestApiService.isPrimaryWebsocketOpen())
          err['myPermissionInvalidArgument'] = true;
        throw err;
      });
  }

  public getMyRoomPermissions(roomId: string): Promise<RoomPermission> {
    if (this.authService.isAnonym()) return Promise.resolve(this.getAnonymRoomPermissions());
    var allDataPromise = Promise.all([
      this.accountService.getMe(),
      this.cacheService.getRoomRecordFromCache(roomId),
      this.getMyRoomPermissionsRecord(roomId),
      this.sidebarService.getGrantedRooms(),
    ]);

    return allDataPromise.then((res) => {
      let me: AccountAvatarRecord = res[0];
      let roomData = res[1];
      let myPermRecord: RoomMyPermissionRecord = res[2];
      let grantedRooms: string[] = res[3];
      let isNanoConnected = Object.keys(roomData['nanoSessions']).length == 1;

      let isOwner = me.id == myPermRecord.ownerAccountId;
      let isAdmin = !!myPermRecord.account?.administrator;
      let isModerator = !!myPermRecord.account?.msgBrdModerate;
      let isMember = !!myPermRecord.account && grantedRooms.indexOf(roomId) === -1;
      let isMessageBoardEnabled = !!myPermRecord.msgBrdReadOnly;

      let roomPerm: RoomPermission = {
        isOwner: isOwner,
        isAdmin: isAdmin,
        isModerator: isModerator,
        isMember: isMember,
        canEditPermissions: (isAdmin && isNanoConnected) || isOwner,
        roomAllowAnonymEnabled: myPermRecord.allowAnonymous,
        roomIsMessageBoardEnabled: isMessageBoardEnabled,
        roomReactionsEnabled: !!!myPermRecord.noReactions,
        canSendMessages: isOwner || (isMember && (!isMessageBoardEnabled || isModerator)),
        canAddReactions: isMember,
        canUploadFiles:
          isNanoConnected &&
          (isOwner || myPermRecord.account?.driveUpload || myPermRecord.account?.driveWrite),
        canDeleteAnyMessages: isOwner || isAdmin || isModerator,
        canPinMessages: isOwner || isAdmin || isModerator,
        driveCanAdhoc: myPermRecord.account?.driveAdhoc,
        driveCanUpload: myPermRecord.account?.driveUpload,
        driveCanWrite: myPermRecord.account?.driveWrite,
      };

      return roomPerm;
    });
  }

  public getDefaultRoomPermissions(): RoomPermission {
    let roomPerm: RoomPermission = {
      isOwner: false,
      isAdmin: false,
      isModerator: false,
      isMember: true,
      canEditPermissions: false,
      roomAllowAnonymEnabled: false,
      roomIsMessageBoardEnabled: false,
      roomReactionsEnabled: false,
      canSendMessages: false,
      canAddReactions: false,
      canUploadFiles: false,
      canDeleteAnyMessages: false,
      canPinMessages: false,
      driveCanAdhoc: false,
      driveCanUpload: false,
      driveCanWrite: false,
    };
    return roomPerm;
  }

  public getAnonymRoomPermissions(): RoomPermission {
    let anonymPerm: RoomPermission = this.getDefaultRoomPermissions();
    anonymPerm.isMember = false;
    return anonymPerm;
  }

  public async saveRoomPermissions(
    resourceId: string,
    modifiedMemberPermissionDict: { [userId: string]: RoomPermission },
    isNanoConnected: boolean,
    roomMembers: RoomMember[]
  ): Promise<any> {
    let memberPermissionDict: { [userId: string]: RoomPermission } = {};
    let ownPermissionDict: { [userId: string]: RoomPermission } = {};
    let me = await this.accountService.getMe();
    for (let userId of Object.keys(modifiedMemberPermissionDict)) {
      if (userId === me.id) {
        ownPermissionDict[userId] = modifiedMemberPermissionDict[userId];
      } else {
        memberPermissionDict[userId] = modifiedMemberPermissionDict[userId];
      }
    }

    //promises that resolve when all the necessary permission operations finish
    let ownPermissionPromise: Promise<any>;
    let otherUserPermissionPromise: Promise<any>;

    //if we are changing our own permission then we must use a different order of operation
    if (Object.keys(ownPermissionDict).length > 0)
      ownPermissionPromise = this.saveOwnPermissions(
        resourceId,
        ownPermissionDict,
        isNanoConnected,
        roomMembers
      );
    if (Object.keys(memberPermissionDict).length > 0)
      otherUserPermissionPromise = this.saveOtherUserPermissions(
        resourceId,
        memberPermissionDict,
        isNanoConnected,
        roomMembers
      );

    return Promise.all([ownPermissionPromise, otherUserPermissionPromise]);
  }

  private saveOwnPermissions(
    resourceId: string,
    ownPermissionDict: { [userId: string]: RoomPermission },
    isNanoConnected: boolean,
    roomMembers: RoomMember[]
  ): Promise<void> {
    if (isNanoConnected) {
      return this.saveModeratorPermission(resourceId, ownPermissionDict, roomMembers).then(() => {
        return this.createRoomConfigBlock(resourceId, ownPermissionDict);
      });
    } else {
      let ownPermFlagsDict = this.convertPermissionToFlags(ownPermissionDict);
      return this.saveModeratorPermission(resourceId, ownPermissionDict, roomMembers).then(() => {
        return this.saveMemberConfig(resourceId, ownPermFlagsDict);
      });
    }
  }

  private saveOtherUserPermissions(
    resourceId: string,
    otherPermissionDict: { [userId: string]: RoomPermission },
    isNanoConnected: boolean,
    roomMembers: RoomMember[]
  ): Promise<void> {
    if (isNanoConnected) {
      return this.createRoomConfigBlock(resourceId, otherPermissionDict).then(() => {
        return this.saveModeratorPermission(resourceId, otherPermissionDict, roomMembers);
      });
    } else {
      let otherPermFlagsDict = this.convertPermissionToFlags(otherPermissionDict);
      return this.saveMemberConfig(resourceId, otherPermFlagsDict).then(() => {
        return this.saveModeratorPermission(resourceId, otherPermissionDict, roomMembers);
      });
    }
  }

  private createRoomConfigBlock(
    resourceId: string,
    permissionDict: { [userId: string]: RoomPermission }
  ): Promise<void> {
    let roomConfigBlockPromise: Promise<void>;
    let memberFlagsDict = this.convertPermissionToFlags(permissionDict);

    roomConfigBlockPromise = new Promise<void>((resolve, reject) => {
      this.nanoService.nanoCreateRoomConfigBlock(
        resourceId,
        memberFlagsDict,
        0,
        () => {
          resolve();
        },
        (err) => {
          console.error('Create room config block error', err);
          reject(err);
        }
      );
    });

    return roomConfigBlockPromise;
  }

  private saveModeratorPermission(
    resourceId: string,
    modifiedMemberPermissionDict: { [userId: string]: RoomPermission },
    roomMembers: RoomMember[]
  ): Promise<any> {
    let promises: Array<Promise<any>> = [];
    for (let userId of Object.keys(modifiedMemberPermissionDict)) {
      let newModeratorValue = modifiedMemberPermissionDict[userId].isModerator;
      let newIsMemberValue = modifiedMemberPermissionDict[userId].isMember;

      let roomMember = roomMembers.find((m) => m.user.id === userId);
      if (
        newIsMemberValue !== false &&
        ((roomMember === undefined && newModeratorValue) ||
          (roomMember !== undefined && roomMember.permissions.isModerator != newModeratorValue))
      ) {
        let promise = this.editAccountPermissionModerator(
          resourceId,
          userId,
          newModeratorValue
        ).catch((err) => {
          console.error('Edit account permission error', err);
        });

        promises.push(promise);
      }
    }

    return Promise.all(promises);
  }

  public editAccountPermissionModerator(resourceId, accountId, value: boolean) {
    return this.serverRestApiService.mutate({
      query: editRoomAccountPermissionQuery,
      variables: {
        roomId: resourceId,
        accountId,
        attribute: 'msgBrdModerate',
        value,
      },
    });
  }

  private convertPermissionToFlags(permissionDict: { [userId: string]: RoomPermission }): {
    [userId: string]: number;
  } {
    let flagsUserDict: { [userId: string]: number } = {};

    for (let userId of Object.keys(permissionDict)) {
      let permissionFlags: number = FlagParser.parseToFlagsV2(
        RoomMember.convertRoomPermissionToMemberPermission(permissionDict[userId])
      );

      flagsUserDict[userId] = permissionFlags;
    }

    return flagsUserDict;
  }

  public getRoomMembers(roomId: string): Promise<RoomMember[]> {
    let roomMembersPromise: Promise<RoomMember[]>;
    roomMembersPromise = this.getRoomMemberRecords(roomId)
      .then(async (res: RoomMemberRecord) => {
        let myPerm = await this.getMyRoomPermissions(roomId);
        let me = await this.accountService.getMe();
        let accountIds = res.accounts.map((r) => r.id);
        let accountDict = await this.accountService.getAccounts(accountIds);
        let membersPermissions: Map<string, MemberPermissions> = null;

        let flagParser;
        if (myPerm.isAdmin || myPerm.isOwner) {
          flagParser = await this.getRoomConfig(roomId);
          membersPermissions = flagParser.getMembersPermissions();
        }

        let roomMembers: RoomMember[] = [];

        for (let roomMemberRecord of res.accounts) {
          let permission: MemberPermissions;
          if (membersPermissions) {
            permission = membersPermissions.get(roomMemberRecord.id);
          } else {
            permission = {
              canAdhocDrive: false,
              canUploadDrive: false,
              canWriteDrive: false,
              isActive: true,
              isAdmin: roomMemberRecord.administrator,
              isModerator: roomMemberRecord.msgBrdModerate,
            };
          }

          if (!permission) {
            console.error(
              'something is wrong with the room permission request: [accounts, roomconfig]',
              res.accounts,
              flagParser
            );
            continue;
          }

          let roomMember = new RoomMember(accountDict[roomMemberRecord.id], permission);
          roomMembers.push(roomMember);

          //isModerator information comes from a different source
          roomMember.permissions.isModerator = !!roomMemberRecord.msgBrdModerate;
          if (roomMember.permissions.isModerator && !roomMember.permissions.isAdmin)
            roomMember.highestRolePermission = PermissionRole.MODERATOR;
          //isOwner is calculated here, it is not in the flagparser permissions
          if (res.ownerAccountId === roomMember.user.id) {
            roomMember.permissions.driveCanWrite = true;
            roomMember.permissions.isOwner = true;
            roomMember.highestRolePermission = PermissionRole.OWNER;
          }
          //own permissions are extended with more info
          if (roomMemberRecord.id === me.id) {
            roomMember.permissions = myPerm;
          }
        }

        return roomMembers;
      })
      .catch((err) => {
        console.error('Error during member list load', err);
        return [];
      });

    return roomMembersPromise;
  }

  public getRoomMemberRecords(roomId): Promise<RoomMemberRecord> {
    return this.serverRestApiService.query({
      query: roomMemberQuery,
      variables: {
        id: roomId,
      },
    });
  }

  public getRoomConfig(resourceId): Promise<FlagParser> {
    return this.cacheService.getRoomRecordFromCache(resourceId).then((roomData) => {
      return this.accountService.getPeerKeyring(roomData.ownerAccountId).then((peerKr) => {
        return this.getRoomBlockChain(resourceId, peerKr).then((chain) => {
          return new FlagParser(chain);
        });

        //chain.new_block()

        // new_block viszsaad: raw_block, signature, member_deltas, group_deltas
        // member_deltas ból kinyerem az id-ket. Csinálok objot:
        // {id: roomkeyring.dump(selfkeyring, peerKeyring(id)).pinnedKey}
        // createRoom(roomId, rawBlock, signature, generált obj)
      });
    });
  }

  public getRoomBlockChain(resourceId, ownerKr): Promise<GroupConfigBlockChain> {
    return this.serverRestApiService
      .query({
        query: roomConfigQuery,
        variables: { id: resourceId },
      })
      .then((res: roomConfigRecord[]) => {
        let blocks = [];

        res.forEach((element) => {
          blocks.push([element.block, element.signature]);
        });

        return GroupConfigBlockChain.load(resourceId, blocks, ownerKr).then((chain) => {
          return chain;
        });
      });
  }

  public saveConfig(
    resourceId,
    members: RoomMember[],
    roomPermissions: RoomPermissions
  ): Promise<any> {
    return this.serverRestApiService
      .mutate({
        query: createNewIdQuery,
      })
      .then((id) => {
        //console.log("newId", id);
        //chain.new_block()

        // new_block viszsaad: raw_block, signature, member_deltas, group_deltas
        // member_deltas ból kinyerem az id-ket. Csinálok objot:
        // {id: roomkeyring.dump(selfkeyring, peerKeyring(id)).pinnedKey}
        // createRoom(roomId, rawBlock, signature, generált obj)
        let permissions = {};

        // Notice: Member permissions are saved with saveMemberConfig
        // members.forEach((m) => {
        //   if (m.hasChanged) {
        //     permissions[m.user.id] = FlagParser.parseToFlags(m.permissions, m.newPermissions);
        //   }
        // });

        let roomFlag = 0;
        if (roomPermissions.allowAnonym === true) {
          roomFlag = GroupConfigBlockFlags.ALLOW_ANONYMOUS;
        } else if (roomPermissions.allowAnonym === false) {
          roomFlag = GroupConfigBlockFlags.ALLOW_ANONYMOUS_NEGATE;
        }

        if (Object.keys(permissions).length > 0 || Object.keys(roomPermissions).length > 0) {
          return this.getChain(resourceId).then((chain) => {
            return chain
              .new_block(this.authService.getSelfAccountKeyring(), id, permissions, roomFlag)
              .then(async ([newBlock, newBlockSignature, memberDeltas, groupDeltas]) => {
                let deltaKeys = {};

                let roomKr = await this.roomKeyringService.makeKeyring(
                  await this.cacheService.getRoomRecordFromCache(resourceId)
                );

                for (let key in memberDeltas) {
                  // only active users
                  if ((memberDeltas[key] & GroupConfigBlockMemberFlags.ACTIVE) == 1) {
                    let dump = await roomKr
                      .dump(
                        this.authService.getSelfAccountKeyring(),
                        await this.accountService.getPeerKeyring(key)
                      )
                      .catch((err) => {
                        console.error('can not dump key', err);
                        throw err;
                      });

                    deltaKeys[key] = dump[RoomKeyring.PINNED];
                  }
                }

                //console.log("deltas", Object.assign({}, deltaKeys));
                //return new Promise((resolve, reject)=>{
                //setTimeout(()=>{
                return this.serverRestApiService
                  .mutate({
                    query: createRoomConfigBlockQuery,
                    variables: {
                      roomId: resourceId,
                      newBlock,
                      newBlockSignature,
                      deltaKeys,
                    },
                  })
                  .then((res) => {
                    //console.log("res", res);
                    //resolve(true)
                    return true;
                  }); //.catch(reject)
                //}, 5000);
                //})
              });
          });
        } else {
          //console.log("nothing to change in config block");
          return true;
        }
      });
  }

  public saveMemberConfig(
    resourceId,
    memberPermissionDict: { [userId: string]: number }
  ): Promise<any> {
    return this.serverRestApiService
      .mutate({
        query: createNewIdQuery,
      })
      .then((id) => {
        let roomFlag = 0;
        return this.getChain(resourceId).then((chain) => {
          return chain
            .new_block(this.authService.getSelfAccountKeyring(), id, memberPermissionDict, roomFlag)
            .then(async ([newBlock, newBlockSignature, memberDeltas, groupDeltas]) => {
              let deltaKeys = {};

              let roomKr = await this.roomKeyringService.makeKeyring(
                await this.cacheService.getRoomRecordFromCache(resourceId)
              );

              for (let key in memberDeltas) {
                // only active users
                if ((memberDeltas[key] & GroupConfigBlockMemberFlags.ACTIVE) == 1) {
                  let dump = await roomKr
                    .dump(
                      this.authService.getSelfAccountKeyring(),
                      await this.accountService.getPeerKeyring(key)
                    )
                    .catch((err) => {
                      console.error('can not dump key', err);
                      throw err;
                    });

                  deltaKeys[key] = dump[RoomKeyring.PINNED];
                }
              }

              return this.serverRestApiService
                .mutate({
                  query: createRoomConfigBlockQuery,
                  variables: {
                    roomId: resourceId,
                    newBlock,
                    newBlockSignature,
                    deltaKeys,
                  },
                })
                .then((res) => {
                  return true;
                });
            });
        });
      });
  }

  public getRoomPermissions(resourceId): Promise<RoomPermissionRecord> {
    return this.serverRestApiService
      .query({
        query: roomPermissionsQuery,
        variables: { id: resourceId },
      })
      .then((res: RoomPermissionRecord) => {
        //console.log("res", res);
        return res;
      });
  }

  private getChain(resourceId): Promise<GroupConfigBlockChain> {
    return this.serverRestApiService
      .query({
        query: roomConfigQuery,
        variables: { id: resourceId },
      })
      .then((res: roomConfigRecord[]) => {
        let blocks = [];

        res.forEach((element) => {
          blocks.push([element.block, element.signature]);
        });

        return GroupConfigBlockChain.load(
          resourceId,
          blocks,
          this.authService.getSelfAccountKeyring()
        );
      });
  }
}
