import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-etalon-info',
  templateUrl: './etalon-info.component.html',
  styleUrls: ['./etalon-info.component.scss'],
})
export class EtalonInfoComponent implements OnInit {
  public showBody: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
