<ul class="file-row-list-box" [ngStyle]="{ 'background-color': data.status === 'error' || data.status === 'error-while-loading' ? 'darkred' : '' }">
  <li *ngIf="isContentLoaded" class="option-style">
    <div class="loading-box">
      <mat-spinner *ngIf="data?.status === 'loading'" diameter="15"></mat-spinner>
    </div>
    <div class="checkbox-box">
      <mat-checkbox
        color="primary"
        class="small"
        type="checkbox"
        [checked]="data.selected === 'selected'"
        [indeterminate]="data.selected === 'indeterminate'"
        (change)="changeFileSelection('auto', data)"
      ></mat-checkbox>
    </div>
    <!-- spaceholder elements for vertical lines -->
    <div class="spaceholder" *ngFor="let i of spaceholders"></div>
    <div class="content-box" (click)="toggleFolder()">
      <span *ngIf="data.type === 'file'" class="file-icon mdi mdi-{{ data.file.icon }} file-{{ data.file.icon }}"></span>
      <span class="file-text" *ngIf="data.type === 'file'">{{ data?.name }}</span>

      <span *ngIf="data.type === 'folder'" class="folder-icon mdi mdi-{{ data.file.icon }}{{ isOpen ? '-open' : '' }} file-{{ data.file.icon }}{{ isOpen ? '-open' : '' }}"></span>
      <span *ngIf="data.type === 'folder'">{{ data.name }}</span>
    </div>
    <div class="size-column">
      <span>{{ this.data?.size | formatBytes }}</span>
    </div>
  </li>
</ul>
<div *ngIf="isOpen">
  <div *ngFor="let singleFile of data.children">
    <app-file-row *ngIf="singleFile.type === 'folder'" [data]="singleFile" [depth]="depth + 1"></app-file-row>
  </div>
  <div *ngFor="let singleFile of data.children">
    <app-file-row *ngIf="singleFile.type === 'file'" [data]="singleFile" [depth]="depth + 1"></app-file-row>
  </div>
</div>
