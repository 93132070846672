import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import msgpack from 'msgpack-lite';
import { AppStorage } from 'src/app/shared/app-storage';
import { ACCOUNT_SIGNATURE_CRYPTO_CONTEXT } from '../crypto/context/account_signature/__init__';
import { AccountSignatureConfigLoadArgs } from '../crypto/context/account_signature/args';
import { NANO_CRYPTO_CONTEXT } from '../crypto/context/nano/__init__';
import { NanoConfigDumpArgs, NanoConfigLoadArgs } from '../crypto/context/nano/args';
import { NANO_DATA_CRYPTO_CONTEXT } from '../crypto/context/nano_data/__init__';
import { NanoDataConfigLoadArgs } from '../crypto/context/nano_data/args';
import { NANO_OWN_CRYPTO_CONTEXT } from '../crypto/context/nano_own/__init__';
import { NanoOwnConfigDumpArgs, NanoOwnConfigLoadArgs } from '../crypto/context/nano_own/args';
import { AbstractAccountKeyring } from '../crypto/keyring/account_base';
import { compareByteArray, concatArrayBuffer } from '../crypto/utility';
import { AccountService } from '../server-services/account.service';
import { AuthService } from '../server-services/auth.service';
import {
  getNanoSlotsQuery,
  nanoDirectRequestQuery,
  nanoRequestQuery,
  waitingNanosQuery,
} from '../server-services/querys';
import { RoomService } from '../server-services/room.service';
import { ServerRestApiService } from '../services/server-rest-api.service';

import {
  AuthenticationObject,
  CreateAdminSessionRequest,
  DocumentEditorMessageData,
  DocumentEditorMessagesType,
  FileSystemWritableFileStreamCommonInterface,
  MutationToken,
  MutationTokenAdminRequest,
  MutationTokenRequest,
  NanoAdminAttachDriveRequest,
  NanoAdminChangePasswordRequest,
  NanoAdminCreateDriveRequest,
  NanoAdminDeleteDriveRequest,
  NanoAdminDetachDriveRequest,
  NanoAdminEditDenyAnonymousRequest,
  NanoAdminEditNameRequest,
  NanoAdminEditRequireExplicitPeerTrustRequest,
  NanoAdminGetInfoRequest,
  NanoAdminGetListingRequest,
  NanoAdminGetRemoteConfigRequest,
  NanoAdminLogReadBackward,
  NanoAdminLogStream,
  NanoAdminMkdirRequest,
  NanoCloseDocumentEditor,
  NanoCreateRoomConfigBlockRequest,
  NanoFileSystemDeleteRequest,
  NanoFilesystemGetRequestForDirectory,
  NanoFilesystemGetRequestForFile,
  NanoFilesystemGetRequestForFileAsBlob,
  NanoFilesystemGetRequestForFileAsFileStream,
  NanoFilesystemGetRequestForThumbnail,
  NanoFilesystemListRequest,
  NanoFilesystemMkdirRequest,
  NanoFilesystemMoveRequestRequest,
  NanoFilesystemPeekRequest,
  NanoFilesystemPutRequest,
  NanoOpenDocumentEditor,
  NanoPrepareDocumentEditor,
  NanoReadDocumentEditorMessage,
  NanoRequestBase,
  NanoSearchRequest,
  NanoSendDocumentEditorMessage,
  NanoThumbnailResponse,
  PeekResult,
  PrepareDocumentEditorInfo,
} from './nano-requests';

import { MatDialog } from '@angular/material/dialog';
import { AdvancedSettingsEnum } from 'src/app/components/advanced-settings/advanced-settings.component';
import { DriveFile } from 'src/app/components/resource-page/drive-window/drive-layout/drive-file';
import { FilenameFilterDialogComponent } from '../dialogs/filename-filter-dialog/filename-filter-dialog.component';
import {
  AdminPasswordDialogComponent,
  AdminPasswordMode,
} from '../dialogs/nano-manager-dialog/admin-password-dialog/admin-password-dialog.component';
import {
  NANO_VERSION_FOR_NANO_MANAGER_ADMIN_SESSION,
  NanoFeature,
  isNanoFeatureSupported,
} from '../drive-version';
import { GlobalCrypto } from '../global-crypto';
import { ID } from '../server-services/query-records/common-records';
import { NanoSlotRecord } from '../server-services/query-records/nano-records';
import { RoomKeyringService } from '../server-services/room-keyring.service';
import { ServerError } from '../server-services/server-errors';
import { Query } from '../services/cache/cache-logic/cache-logic-interface';
import { DialogService } from '../services/dialog.service';
import { SnackBarService } from '../services/snackbar.service';
import { PartKeyHandler } from './part-key-handler';
import {
  FilePayloadContainer,
  LazyPayloadContainer,
  PayloadContainer,
  PayloadContainerSubscriptionResult,
  ResponsePayloadContainer,
} from './payload-container';
import { PeekCache } from './preview-cache';
import { ThumbnailCache } from './thumbnail-cache';

export type RequestSession = {
  // for request
  query: Query;
  variables: Object;
  request: NanoRequestBase;
  useSecondarySocket: boolean;
  // for rest
  encryption: Function;
  decryption: Function;
  // for resume
  initKey: Uint8Array;
  initKeyType: 'cacheKey' | 'transferKey';
  partCount: number;
  payloadParts: PayloadContainer;
  pause: Function;
  isPaused: boolean;
  done: boolean;
  nextChunk: number;
  doneCallback: Function;
  errorCallback: Function;
  progressCallback: (
    part: number,
    count: number,
    session: RequestSession,
    payloadPart?: any
  ) => void;
  restartIfConnectionIssueResolved: boolean;
};

export type UploadSession = {
  resourceId;
  file: File;
  path: string;
  name: string;
  doneCallback: (name, path) => void;
  errorCallback: Function;
  progressCallback;
  nextChunk: number;
  smac: ArrayBuffer[];
  requestId: number;
  isPaused: boolean;
  autoName: boolean;
  done: boolean;
  overwrite: boolean;
  retryDelay: number;
  errorState: boolean;
  /**
   * When Using Iphone clipboard to upload a message, the mtime will be always the current date
   * It will be inconsistent for every file chunk, so we need to freeze the time param in this case
   */
  freezedMTime?: number;
  /**
   * Number of successfully uploaded chunks.
   * Helps verifying uploaded chunks when last chunk is uploaded
   */
  successfulChunks?: number;
  failedChunks: number[];
  runningChunks: number;
  lastChunkStarted: boolean;
};

export type UploadChunkInfo = {
  offset: number;
  size: number;
  mtime: number;
  allChunk: number;
  autoName: string | null;
};

export type PeekFileParam = {
  resourceId: ID;
  path: string;
  errorCallback: (err: any) => void;

  // can be called multiple times if the preview is loaded later
  responseCallback: (res: PeekResult) => void;

  // older nano can not response with img stale, so we can't be sure
  // if the preview generation is under progress, or it is done but there is no
  // preivew for the file. In this case we should retry periodically and give up after a while
  // new nanos has img "stale" response which indicates, the peek will response with
  // a preview later
  // this param should be the same for every call
  nanoVersion: NanoFeature;
};

@Injectable({
  providedIn: 'root',
})
export class NanoService {
  private mutationTokens: Map<string, MutationToken[]> = new Map<string, MutationToken[]>();

  // TODO maybe we should calculate the chunk size dinamically
  public static NETWORK_FILE_CHUNK_SIZE =
    parseInt(AppStorage.getItem(AdvancedSettingsEnum.NETWORK_FILE_CHUNK_SIZE)) || 500000;
  public static FILE_STREAM_MIN_SIZE =
    parseInt(AppStorage.getItem(AdvancedSettingsEnum.FILE_STREAM_SIZE_LIMIT)) || 96 * 1000000; // 96M^ - file streaming instead of blob
  public static PARALLEL_UPLOAD_WINDOW =
    parseInt(AppStorage.getItem(AdvancedSettingsEnum.PARALLEL_UPLOAD_WINDOW)) || 3; // how many chunk used for upload in the same time before server resp

  private partKeyHandler = new PartKeyHandler();
  private requestSessions: Map<number, RequestSession> = new Map<number, RequestSession>();

  private lastRequestId = 0;
  private getNewRequestId() {
    return this.lastRequestId++;
  }

  constructor(
    private serverRestApiService: ServerRestApiService,
    private authService: AuthService,
    private roomService: RoomService,
    private roomKeyringService: RoomKeyringService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private snackbarService: SnackBarService,
    private dialogService: DialogService
  ) {}

  // Mutation token (basic, admin) handlers ------------------------------
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  //

  private getFreeRequestToken(roomId): Promise<MutationToken> {
    return this.getFreeToken({ roomId });
  }

  private getFreeAdminRequestToken(nanoId): Promise<MutationToken> {
    return this.getFreeToken({ nanoId });
  }

  /**
   * Generate mutation token for the room.
   * @param resourceId
   */
  private getFreeToken(param): Promise<MutationToken> {
    let id = param.roomId ? 'room' + param.roomId : 'nano' + param.nanoId;

    if (!this.mutationTokens.has(id)) {
      this.mutationTokens.set(id, []);
    }

    let tokens = this.mutationTokens.get(id);

    return new Promise((resolve, reject) => {
      for (let i = 0; i < tokens.length; i++) {
        if (tokens[i].expired) {
          tokens.splice(i, 1);
          i--;
          continue;
        }
        if (!tokens[i].locked) {
          tokens[i].locked = true;
          return resolve(tokens[i]);
        }
      }

      let request: Function;
      let id;
      let mutationRequest: NanoRequestBase;
      if (param.roomId) {
        request = this.commonRequest.bind(this);
        id = param.roomId;
        mutationRequest = new MutationTokenRequest(id);
      } else {
        request = this.adminRequest.bind(this);
        id = param.nanoId;
        mutationRequest = new MutationTokenAdminRequest(id);
      }

      // there is no token, we need to get one from the curr nano
      request(
        id,
        mutationRequest,
        (incToken) => {
          if (incToken) {
            const tk: MutationToken = {
              token: incToken,
              locked: true,
              counter: 1,
              expired: false,
            };

            tokens.push(tk);

            resolve(tk);
          } else {
            reject('Response error, can not get mutation token from nano');
          }
        },
        () => {
          reject('Request error, can not get token from nano: ');
        }
      );
    });
  }

  private releaseToken(token: MutationToken) {
    if (token) {
      token.counter++;
      token.locked = false;
    }
  }

  private removeToken(token: MutationToken) {
    token.expired = true;
  }

  private createGenericFileErrorHandler(
    filePath: string,
    errorCallback: Function,
    retryFunction: Function
  ): Function {
    return (err) => {
      if (err.error === ServerError.NANO_REQUEST_ERROR_HANDLER_INVALID_NAME) {
        // Filename not allowed by the OS
        let pathArray = filePath.split('/');
        var fileParts = pathArray.pop().split('.');
        let fileName = fileParts[0];
        let fileExt: string;
        if (fileParts.length > 1) {
          fileExt = fileParts[1];
        }
        let dialogRef = this.dialog.open(FilenameFilterDialogComponent, {
          data: { originalFileName: fileName }, // Take only the filename, not the extension
        });
        dialogRef.afterClosed().subscribe((newFileName: string) => {
          if (newFileName) {
            if (fileExt) {
              newFileName += '.' + fileExt; // Append file extension back to the new name
            }
            pathArray.push(newFileName);
            retryFunction(pathArray.join('/'));
          }
        });
      } else if (err.error === ServerError.NANO_REQUEST_ERROR_HANDLER_TARGET_EXISTS) {
        this.dialogService.openAlertDialog(
          marker('Resource exists error'),
          marker(
            'Action was not possible. Resource with the given name already exists on this path.'
          )
        );
      } else {
        errorCallback(err);
      }
    };
  }

  // Encryption - decryption for requestes -----------------------------
  //
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  //

  private encryptAdminRequest(data, extra) {
    data.request = msgpack.encode(data.request);

    return NANO_OWN_CRYPTO_CONTEXT.dump(
      new NanoOwnConfigDumpArgs(
        data.request,
        this.authService.getSelfAccountKeyring(),
        this.serverRestApiService.makeTimestamp()
      )
    ).then((dump) => {
      data.request = dump;
      return data;
    });
  }

  private encryptNanoRequest(data, extra) {
    if (this.authService.isAnonym())
      data.ephPubKey = this.authService
        .getSelfAccountKeyring()
        .get_public_key(AbstractAccountKeyring.CURVE25519);

    data.request = msgpack.encode(data.request);

    return this.roomService.getRoom(data.roomId).then((roomData) => {
      // if (!this.authService.isAnonym()){
      return this.accountService.getMe().then((me) => {
        if (me.id == roomData['ownerAccountId']) {
          // im the owner of this nano
          return NANO_OWN_CRYPTO_CONTEXT.dump(
            new NanoOwnConfigDumpArgs(
              data.request,
              this.authService.getSelfAccountKeyring(),
              this.serverRestApiService.makeTimestamp()
            )
          ).then((dump) => {
            data.request = dump;
            return data;
          });
        } else {
          // im not the owner
          return this.accountService.getPeerKeyring(roomData['ownerAccountId']).then((peer_kr) => {
            return this.roomKeyringService.getKeyring(data.roomId).then((room_kr) => {
              return NANO_CRYPTO_CONTEXT.dump(
                new NanoConfigDumpArgs(
                  data.request,
                  this.authService.getSelfAccountKeyring(),
                  peer_kr,
                  room_kr,
                  this.serverRestApiService.makeTimestamp()
                )
              ).then((dump) => {
                data.request = dump;
                return data;
              });
            });
          });
        }

        return data;
      });
      /* }
      else{
          // TODO anonym request: ephPubKey: anonym keyring public CURVE25519
          console.error('implement this TODO')
      } */
    });
  }

  private decryptAdminRequest(data, variables, extra) {
    if (data['error']) {
      return Promise.resolve(data);
    }

    data.request = variables.request;

    if (data.part > 0) {
      return this.partKeyHandler.getPartKey(extra.requestId).then((partKey) => {
        return NANO_DATA_CRYPTO_CONTEXT.load(
          new NanoDataConfigLoadArgs(data.response, partKey)
        ).then((load) => {
          data.response = msgpack.decode(load);
          return data;
        });
      });
    } else {
      return NANO_OWN_CRYPTO_CONTEXT.load(
        new NanoOwnConfigLoadArgs(
          data.response,
          this.authService.getSelfAccountKeyring(),
          this.serverRestApiService.makeTimestamp()
        )
      ).then((load) => {
        data.response = msgpack.decode(load);
        if ('PK' in data.response) {
          this.partKeyHandler.setPartKey(extra.requestId, data.response.PK);
        }
        return data;
      });
    }
  }

  private decryptNanoRequest(data, variables, extra) {
    if (!data.error) {
      if (data.part > 0) {
        return this.partKeyHandler.getPartKey(extra.requestId).then((partKey) => {
          return NANO_DATA_CRYPTO_CONTEXT.load(
            new NanoDataConfigLoadArgs(data.response, partKey)
          ).then((load) => {
            data.response = msgpack.decode(load);
            return data;
          });
        });
      } else {
        data.request = variables.request;

        return this.roomService.getRoom(variables.roomId).then((roomData) => {
          // if (!this.authService.isAnonym()){
          return this.accountService.getMe().then((me) => {
            if (me.id == roomData['ownerAccountId']) {
              // im the owner of this nano

              return NANO_OWN_CRYPTO_CONTEXT.load(
                new NanoOwnConfigLoadArgs(
                  data.response,
                  this.authService.getSelfAccountKeyring(),
                  this.serverRestApiService.makeTimestamp()
                )
              ).then((load) => {
                data.response = msgpack.decode(load);
                if ('PK' in data.response) {
                  this.partKeyHandler.setPartKey(extra.requestId, data.response.PK);
                }
                return data;
              });
            } else {
              return this.accountService
                .getPeerKeyring(roomData['ownerAccountId'])
                .then((peer_kr) => {
                  return this.roomKeyringService.getKeyring(variables.roomId).then((room_kr) => {
                    return NANO_CRYPTO_CONTEXT.load(
                      new NanoConfigLoadArgs(
                        data.response,
                        this.authService.getSelfAccountKeyring(),
                        peer_kr,
                        room_kr,
                        this.serverRestApiService.makeTimestamp()
                      )
                    ).then((load) => {
                      data.response = msgpack.decode(load);
                      if ('PK' in data.response) {
                        this.partKeyHandler.setPartKey(extra.requestId, data.response.PK);
                      }
                      return data;
                    });
                  });
                });
            }
          });
          /* }
          else{
              // TODO anonym request: ephPubKey: anonym keyring public CURVE25519
              console.error('implement this TODO')
          } */
        });
      }
    } else return Promise.resolve(data);
  }

  // Core for requestes -----------------------------------------
  //
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  //

  private commonRequest(
    roomId,
    request: NanoRequestBase,
    doneCallback: Function,
    errorCallback?: Function,
    progressCallback?: (
      part: number,
      count: number,
      session: RequestSession,
      payloadPart?: any
    ) => void,
    useSecondarySocket = false,
    createSession = false
  ): number | RequestSession {
    return this.nanoRequest(
      nanoRequestQuery,
      { roomId },
      request,
      doneCallback,
      errorCallback,
      progressCallback,
      useSecondarySocket,
      this.encryptNanoRequest,
      this.decryptNanoRequest,
      createSession
    );
  }

  private commonMutationRequest(
    resourceId,
    request: NanoRequestBase,
    doneCallback,
    errorCallback?,
    progressCallback?,
    useSecondarySocket?: boolean,
    createSession?: boolean
  ) {
    this.getFreeRequestToken(resourceId)
      .then((token) => {
        request.setMutationToken(token);
        this.commonRequest(
          resourceId,
          request,
          (response) => {
            this.releaseToken(token);
            doneCallback(response);
          },
          (err) => {
            if (err.error == ServerError.NANO_REQUEST_ERROR_MUTATION_ID) {
              this.removeToken(token);
              console.log('token expired, try again with a new one');
              this.commonMutationRequest(
                resourceId,
                request,
                doneCallback,
                errorCallback,
                progressCallback
              );
            } else {
              errorCallback && errorCallback(err);
            }
          },
          progressCallback,
          useSecondarySocket,
          createSession
        );
      })
      .catch(errorCallback);
  }

  private adminRequest(
    nanoId,
    request: NanoRequestBase,
    doneCallback: Function,
    errorCallback?: Function,
    progressCallback?: (
      part: number,
      count: number,
      session: RequestSession,
      payloadPart?: any
    ) => void,
    authObject?: AuthenticationObject,
    useSecondarySocket = false
  ) {
    let done: Function = doneCallback;
    if (authObject) {
      request.setAuth(authObject);
      done = (result) => {
        authObject.resetTokenTimeout();
        doneCallback(result);
      };
    }

    return this.nanoRequest(
      nanoDirectRequestQuery,
      { nanoId },
      request,
      done,
      errorCallback,
      progressCallback,
      useSecondarySocket,
      this.encryptAdminRequest,
      this.decryptAdminRequest
    );
  }

  private adminMutationRequest(
    nanoId,
    request: NanoRequestBase,
    authObject: AuthenticationObject | null,
    doneCallback,
    errorCallback?,
    progressCallback?: (
      part: number,
      count: number,
      session: RequestSession,
      payloadPart?: any
    ) => void
  ) {
    this.getFreeAdminRequestToken(nanoId)
      .then((token) => {
        request.setMutationToken(token);
        this.adminRequest(
          nanoId,
          request,
          (result) => {
            this.releaseToken(token);
            doneCallback(result);
          },
          (err) => {
            if (err.error == ServerError.NANO_REQUEST_ERROR_MUTATION_ID) {
              this.removeToken(token);
              console.log('token expired, try again with a new one');
              this.adminMutationRequest(
                nanoId,
                request,
                authObject,
                doneCallback,
                errorCallback,
                progressCallback
              );
            } else {
              errorCallback && errorCallback(err);
            }
          },
          progressCallback,
          authObject
        );
      })
      .catch(errorCallback);
  }

  public resumeRequest(requestId) {
    let session = this.requestSessions.get(requestId);
    session.restartIfConnectionIssueResolved = true;
    if (session) {
      if (session.isPaused) {
        session.nextChunk = session?.payloadParts?.getLastSuccessPart()
          ? session.payloadParts.getLastSuccessPart() + 1
          : 1;
        session.isPaused = false;

        this.nanoRequest(
          session.query,
          session.variables,
          session.request,
          session.doneCallback,
          session.errorCallback,
          session.progressCallback,
          session.useSecondarySocket,
          session.encryption,
          session.decryption,
          requestId
        );
      }
    } else {
      session.errorCallback && session.errorCallback('Can not find session');
    }
  }

  public pauseRequest(requestId): boolean {
    let session = this.requestSessions.get(requestId);
    session.restartIfConnectionIssueResolved = false;
    if (session && session.pause && !session.isPaused) {
      session.pause();
      return true;
    } else {
      throw 'Can not find session';
    }
  }

  public deleteRequestSession(requestId) {
    let session = this.requestSessions.get(requestId);

    if (session) {
      session.pause();
      session.payloadParts?.reset();
      session.request?.close();
      this.requestSessions.delete(requestId);
    }
  }

  /**
   * Start a request and merge it's payload
   * @param requestId
   * @param request nanoRequestBase
   * @param doneCallback cb(merged Message by passed requestHandler)
   * @param errorCallback cb('error msg as string')
   * @param progressCallback cb(current, max, speed)
   * @param useSecondarySocket secondary socket is for large file upload/download
   * @param useSession true/false: register a session for the event; requestId: continue the request
   * @return requestId
   */
  private nanoRequest(
    query: Query,
    variables: Object,
    request: NanoRequestBase,
    doneCallback: Function,
    errorCallback: Function,
    progressCallback: (
      part: number,
      count: number,
      session: RequestSession,
      payloadPart?: any
    ) => void,
    useSecondarySocket: boolean = false,
    encryption: Function,
    decryption: Function,
    useSession: boolean | number = false
  ): number | RequestSession {
    let sub; // subscription of the request
    let error = false; // stop, if error happened
    // bubble error up
    let throwError = (msg, err?) => {
      console.error('request error', msg, err);
      errorCallback && errorCallback(msg, err);

      error = true; // do not start to parse the async other messages
      sub && sub.unsubscribe(); // unsub the subscription, if it is still alive (client error)
    };
    let requestId;

    // we need the request as binary+encrypted form, the nano will open it
    variables['request'] = request.getRequest();

    // the whole request logic
    let session: RequestSession;

    // init or resume a download session request
    if (useSession !== false && useSession !== true) {
      // this is a continue request
      requestId = useSession;
      session = this.requestSessions.get(useSession);

      if (!session) {
        throwError('Request session not found');
      } else {
        // register the next pause event for this subscription scope
        session.pause = () => {
          sub && sub.unsubscribe();
          session.isPaused = true;
        };
        // set the start for the request
        if (session.initKeyType) {
          variables['part'] = session.nextChunk || 1;
          variables[session.initKeyType] = session.initKey; // transferKey or cacheKey = Uint8Array
        } // else init a new stream-line
      }
    } else {
      // this is a new request
      requestId = this.getNewRequestId();

      // we can init the keys with the first payload only
      session = {
        query,
        variables,
        request,
        useSecondarySocket,
        encryption,
        decryption,
        initKey: null,
        initKeyType: null,
        partCount: 1,
        doneCallback,
        errorCallback,
        progressCallback,
        payloadParts: null,
        nextChunk: 0,
        restartIfConnectionIssueResolved: true,
        pause: () => {
          sub && sub.unsubscribe();
          session.isPaused = true;
        },
        isPaused: false,
        done: false,
      };

      if (useSession === true) {
        this.requestSessions.set(requestId, session);
      }

      if (this.authService.isAnonym()) {
        // on anonym call we need the curve key
        variables['ephPubKey'] = this.authService
          .getSelfAccountKeyring()
          .get_public_key(AbstractAccountKeyring.CURVE25519);
      }
    }

    let onPayloadPush = (result: PayloadContainerSubscriptionResult) => {
      // bubble up the checked n ready chunks for blob concat or stream write
      let promises = [];
      result.chunks.forEach((c) => {
        promises.push(request.push(c));
      });

      Promise.all(promises)
        .then(() => {
          result.done();
        })
        .catch((err) => {
          throwError('could not push data into the file sink', err);
        });
    };

    let onPayloadCompleted = () => {
      // this is the last message
      session.done = true;
      request
        .mergePayloads()
        .then((result) => {
          return request.close().then(() => {
            // call async, so the callback wont block the queue
            setTimeout(() => {
              doneCallback(result);
            }, 1);
          });
        })
        .catch(errorCallback);
      sub && sub.unsubscribe(); // end the subscription
      //console.log('cleared request', session)
      //session.variables['request'] = null; // Release it from memory. It can hold upload payload
      //session.request = null;
      //console.log('cleared request', session)
    };

    let pushMessage = (msg, dataMAC?) => {
      session.payloadParts.push(msg.part, msg.response, dataMAC);
    };

    if (!session.isPaused) {
      // let's rock - start the request

      //console.log('--> will create req', query, variables['request']);
      sub = this.serverRestApiService
        .subscribe({
          query,
          variables,
          useSecondarySocket,
          extra: {
            requestId, // we need this to store the partKey under this session (nanoRequest only)
          },
          encrypt: encryption.bind(this),
          decrypt: decryption.bind(this),
        })
        .subscribe({
          next: async (msg: any) => {
            //console.log('<-- nano req', requestId, query, msg['response']);

            if (msg.error) {
              errorCallback(msg);
              sub.unsubscribe();
              return;
            }

            progressCallback && progressCallback(msg.part, session.partCount, session, msg);

            if (msg.error) error = true;

            if (!error) {
              // here comes a part of the msg, it can be init or x-th part
              const key = this.getKeyFromInitMessage(msg);

              if (key !== null) {
                // init, restart, or restarted continue (the first payload always the info about the file)
                if (!session.initKey) {
                  // this is an init or a restart message - direct requests has only 1 msg
                  progressCallback && progressCallback(0, msg.count, session);
                  session.initKeyType = key;
                  // the server re-sent the init payload, it can be a restart or a continue payload
                  session.initKey = msg[key];
                  session.partCount = msg.count;
                  session.nextChunk = 0;

                  if (
                    request instanceof NanoReadDocumentEditorMessage ||
                    request instanceof NanoAdminLogStream
                  ) {
                    session.payloadParts = new LazyPayloadContainer(msg.count);
                  } else {
                    session.payloadParts =
                      request instanceof NanoFilesystemGetRequestForFile
                        ? new FilePayloadContainer(msg.count)
                        : new ResponsePayloadContainer(msg.count);
                  }

                  session.payloadParts.onPayloadPush().subscribe({
                    next: onPayloadPush,
                    complete: onPayloadCompleted,
                    error: throwError,
                  });
                  await request.reset();
                }

                this.verifyInitMessage(session.initKey, msg)
                  .then(() => {
                    if (msg.response.C == session.partCount) {
                      pushMessage(msg);
                    } else {
                      throwError('part count mismatch in init message');
                    }
                  })
                  .catch((err) => {
                    console.error('init msg verify error', err);
                    throwError('init msg verify error', err);
                  });
              } else {
                // this is an x-th part message
                if (msg.response.P == msg.part) {
                  // check the correct part
                  if ('MAC' in msg.response) {
                    // at random positions we can get MAC during file download. Concat all the payloads after the prev MAC and test it

                    this.checkMAC(msg.response.MAC, msg.response.MACS, variables['roomId'])
                      .then(() => {
                        pushMessage(msg, msgpack.decode(msg.response.MAC));
                      })
                      .catch((err) => {
                        throwError('MAC/MACS check failed', err);
                      });
                  } else {
                    pushMessage(msg);
                  }
                } else {
                  throwError('part count mismatch in part message');
                }
              }
            } else {
              throwError(msg);
            }
          },
          error: (err) => {
            console.log('<- in error', err, Object.assign({}, session));
            // console.trace();
            if (useSession) {
              if (!session || !session.done) {
                // check connection status
                if (
                  (useSecondarySocket && this.serverRestApiService.isSecondaryWebsocketOpen()) ||
                  (!useSecondarySocket && this.serverRestApiService.isPrimaryWebsocketOpen())
                ) {
                  // status is open, but problem happened, we could not fix this, so throw error
                  if (errorCallback) errorCallback(err);
                } else {
                  // it is a connection problem, the websocket will solve it later, so just wait a little bit
                  if (useSecondarySocket) {
                    // we need to resume it manually otherwise the wsClient will resend the original request
                    // which is the first or the latest paused part
                    if (session) {
                      if (!session.isPaused) {
                        session.pause();
                        session.restartIfConnectionIssueResolved = true;
                        setTimeout(() => {
                          if (session.restartIfConnectionIssueResolved) {
                            this.resumeRequest(requestId);
                          }
                        }, 2000);
                      }
                    } else {
                      if (!session.isPaused) {
                        setTimeout(() => {
                          if (!session.isPaused) {
                            this.nanoRequest(
                              query,
                              variables,
                              request,
                              doneCallback,
                              errorCallback,
                              progressCallback,
                              useSecondarySocket,
                              encryption,
                              decryption,
                              requestId
                            );
                          }
                        }, 2000);
                      }
                    }
                  }
                }
              } else {
                console.error('<- error registered but download is already done', err);
              }
            } else {
              //session.variables['request'] = null; // it can hold the upload payload in the memory
              //session.request = null;
              console.log('nano session error', useSession); // maybe there is no nano connected
              if (errorCallback) errorCallback(err);
            }
          },
        });
    }

    if (useSession === true) {
      return requestId;
    } else {
      return session;
    }
  }

  // direct api calls ------------------------------------------------------------------------
  //
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  //

  public listDir(
    resourceId: string,
    path: string,
    nanoVersion: NanoFeature,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    if (nanoVersion >= NanoFeature.AUTO_NAME) {
      this.commonRequest(
        resourceId,
        new NanoFilesystemListRequest(resourceId, path),
        doneCallback,
        errorCallback,
        progressCallback
      );
    } else {
      this.commonRequest(
        resourceId,
        new NanoFilesystemGetRequestForDirectory(resourceId, path),
        doneCallback,
        errorCallback,
        progressCallback
      );
    }
  }

  private peekCache = new PeekCache();
  private activePeekRequests: {
    [resourceId: string]: {
      [path: string]: PeekFileParam[];
    };
  } = {};
  private peekRequestStarted: number = 0;
  private firstPeekRequestTimestamp: number = 0;
  private maxPeekRequestAmountWindow: number = 8;
  private maxPeekRequestTimeWindow: number = 1000;
  private waitingPeekRequests: PeekFileParam[] = [];

  public peekFile(options: PeekFileParam) {
    let cachedData = this.peekCache.getCachedPreview(options.resourceId, options.path);
    if (cachedData) {
      options.responseCallback(cachedData);
    }

    // there is still active request which is waiting for the preview
    if (this.activePeekRequests?.[options.resourceId]?.[options.path]) {
      this.activePeekRequests[options.resourceId][options.path].push(options);
      return;
    }

    // check how many peek request started in the limited time window
    let now = new Date().getTime();
    if (now - this.firstPeekRequestTimestamp > this.maxPeekRequestTimeWindow) {
      this.peekRequestStarted = 0;
      this.firstPeekRequestTimestamp = now;
    }

    this.peekRequestStarted++;

    // if too much request started, just wait for the next time window
    if (this.peekRequestStarted > this.maxPeekRequestAmountWindow) {
      // call the last first, user want to see the last visited placeholder in the chat
      this.waitingPeekRequests.unshift(options);
      setTimeout(() => {
        let requests = [...this.waitingPeekRequests];
        this.waitingPeekRequests = [];
        requests.forEach((opt) => {
          this.peekFile(opt);
        });
      }, this.maxPeekRequestAmountWindow);
      return;
    }

    // init request
    if (!this.activePeekRequests[options.resourceId]) {
      this.activePeekRequests[options.resourceId] = {
        [options.path]: [options],
      };
    } else {
      this.activePeekRequests[options.resourceId][options.path] = [options];
    }

    let recallTimeout = 3000;
    let maxTrial = 5;

    // start request
    let peek = (trial) => {
      // we can not detect if there is preview or not, if we can not get preview after some try
      // we can give up
      if (trial >= maxTrial && options.nanoVersion < NanoFeature.AUTO_NAME) {
        return;
      }

      this.commonRequest(
        options.resourceId,
        new NanoFilesystemPeekRequest(options.resourceId, options.path),
        (res: PeekResult) => {
          // cache and call response callbacks
          this.peekCache.setCachedPreview(options.resourceId, options.path, res);
          // callbacks can remove themself from the list, which would mess with the forEach index
          let callbacks = [...this.activePeekRequests[options.resourceId][options.path]];
          callbacks.forEach((resolver) => {
            resolver.responseCallback(res);
          });

          // this is the final request, we have got the preview, we can clean up the requests
          if (res.preview) {
            delete this.activePeekRequests[options.resourceId][options.path];
          } else {
            // if it is still in stale or we don't have info about preview we should retry
            if (res.img || options.nanoVersion < NanoFeature.AUTO_NAME) {
              setTimeout(() => {
                this.peekRequestStarted++;
                peek(trial + 1);
              }, recallTimeout);
            }
          }
        },
        options.errorCallback,
        () => {}
      );
    };

    peek(0);
  }

  unsubscribeFromPeek(optionsRef: PeekFileParam) {
    let index = this.waitingPeekRequests.indexOf(optionsRef);
    if (index > -1) {
      this.waitingPeekRequests.splice(index, 1);
    } else {
      console.log('could not find waiting peek subscription', optionsRef, this.waitingPeekRequests);
    }
    index =
      this.activePeekRequests?.[optionsRef.resourceId]?.[optionsRef.path]?.indexOf(optionsRef);
    if (index > -1) {
      this.activePeekRequests?.[optionsRef.resourceId]?.[optionsRef.path]?.splice(index, 1);
    } else {
      console.warn(
        'could not find peek subscription',
        optionsRef,
        this.activePeekRequests?.[optionsRef.resourceId]?.[optionsRef.path]
      );
    }
  }

  /**
   * Download a file, return with the requestId so you can pause/resume
   * @param resourceId
   * @param path
   * @param doneCallback
   * @param errorCallback
   * @param progressCallback
   * @returns requestId
   */
  public getFile(
    resourceId: string,
    path: string,
    fileSystemWritableFileStream: FileSystemWritableFileStreamCommonInterface | null,
    doneCallback,
    errorCallback?,
    progressCallback?
  ): number {
    return <number>(
      this.commonRequest(
        resourceId,
        fileSystemWritableFileStream
          ? new NanoFilesystemGetRequestForFileAsFileStream(
              resourceId,
              path,
              fileSystemWritableFileStream
            )
          : new NanoFilesystemGetRequestForFileAsBlob(resourceId, path),
        doneCallback,
        errorCallback,
        progressCallback,
        true,
        true
      )
    );
  }

  private thumbnailCache = new ThumbnailCache();

  public getFileThumbnail(
    roomId: string,
    path: string,
    files: DriveFile[],
    bucket: number,
    doneCallback,
    errorCallback,
    progressCallback?
  ) {
    let cachedThumbnails = this.thumbnailCache.getCachedThumbnails(roomId, path, files, bucket);
    if (cachedThumbnails !== null) {
      doneCallback(cachedThumbnails);
    } else {
      return this.commonRequest(
        roomId,
        new NanoFilesystemGetRequestForThumbnail(roomId, path, bucket),
        (thumbnails: NanoThumbnailResponse[]) => {
          this.thumbnailCache.setCachedThumbnails(roomId, path, files, bucket, thumbnails);

          doneCallback(thumbnails);
        },
        errorCallback,
        progressCallback
      );
    }
  }

  public search(
    resourceId: string,
    query: string,
    lang: string,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.commonRequest(
      resourceId,
      new NanoSearchRequest(resourceId, query, lang),
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public delete(resourceId: string, path: string, doneCallback, errorCallback?, progressCallback?) {
    this.commonMutationRequest(
      resourceId,
      new NanoFileSystemDeleteRequest(resourceId, path),
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public makeDir(
    resourceId: string,
    path: string,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    let extendedErrorCallback = this.createGenericFileErrorHandler(
      path,
      errorCallback,
      (fixedPath) =>
        this.makeDir(resourceId, fixedPath, doneCallback, errorCallback, progressCallback)
    );
    this.commonMutationRequest(
      resourceId,
      new NanoFilesystemMkdirRequest(resourceId, path),
      doneCallback,
      extendedErrorCallback,
      progressCallback
    );
  }

  public moveFile(
    resourceId: string,
    srcPath: string,
    newPath: string,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    let extendedErrorCallback = this.createGenericFileErrorHandler(
      newPath,
      errorCallback,
      (fixedPath) =>
        this.moveFile(resourceId, srcPath, fixedPath, doneCallback, errorCallback, progressCallback)
    );

    this.commonMutationRequest(
      resourceId,
      new NanoFilesystemMoveRequestRequest(resourceId, srcPath, newPath),
      doneCallback,
      extendedErrorCallback,
      progressCallback
    );
  }

  public nanoCreateRoomConfigBlock(
    resourceId,
    memberFlags: Object,
    groupFlags: number,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.commonMutationRequest(
      resourceId,
      new NanoCreateRoomConfigBlockRequest(resourceId, memberFlags, groupFlags),
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  /**
   * Keeps information (like name, version) for nanoslots on rooms
   */
  public nanoSlotDetails: { [key: string]: { detail: any } } = {};
  public adminGetInfo(
    nanoId: number,
    doneCallback,
    errorCallback?,
    progressCallback?,
    alwaysFetch = true
  ) {
    if (!alwaysFetch && nanoId in this.nanoSlotDetails) {
      doneCallback(this.nanoSlotDetails[nanoId]);
    } else {
      const mergedDoneCallback = (res) => {
        this.nanoSlotDetails[nanoId] = { detail: res };
        doneCallback(res);
      };
      this.adminRequest(
        nanoId,
        new NanoAdminGetInfoRequest(nanoId),
        mergedDoneCallback,
        errorCallback,
        progressCallback
      );
    }
  }

  public adminCreateSession(
    nanoId: number,
    password: string,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminMutationRequest(
      nanoId,
      new CreateAdminSessionRequest(password, nanoId),
      null,
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public adminGetRemoteConfig(
    nanoId: number,
    autObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminRequest(
      nanoId,
      new NanoAdminGetRemoteConfigRequest(nanoId),
      doneCallback,
      errorCallback,
      progressCallback,
      autObject
    );
  }

  public adminChangePasswordRequest(
    nanoId: number,
    newPassword: string,
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminMutationRequest(
      nanoId,
      new NanoAdminChangePasswordRequest(nanoId, newPassword),
      authObject,
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public adminGetListing(
    nanoId,
    path: string,
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminRequest(
      nanoId,
      new NanoAdminGetListingRequest(nanoId, path),
      doneCallback,
      errorCallback,
      progressCallback,
      authObject
    );
  }

  public adminMakeDir(
    nanoId,
    path: string,
    name: string,
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    let extendedErrorCallback = this.createGenericFileErrorHandler(
      path + name,
      errorCallback,
      (fixedPath) => {
        const fixedName = fixedPath.split('/').pop();
        this.adminMakeDir(
          nanoId,
          path,
          fixedName,
          authObject,
          doneCallback,
          errorCallback,
          progressCallback
        );
      }
    );

    this.adminMutationRequest(
      nanoId,
      new NanoAdminMkdirRequest(nanoId, path, name),
      authObject,
      doneCallback,
      extendedErrorCallback,
      progressCallback
    );
  }

  public adminCreateDrive(
    nanoId,
    path: string,
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminMutationRequest(
      nanoId,
      new NanoAdminCreateDriveRequest(nanoId, path),
      authObject,
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public adminDeleteDrive(
    nanoId,
    path: string,
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminMutationRequest(
      nanoId,
      new NanoAdminDeleteDriveRequest(nanoId, path),
      authObject,
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public adminAttachDrive(
    nanoId,
    path: string,
    roomId: string,
    roomBlocks: string[],
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminMutationRequest(
      nanoId,
      new NanoAdminAttachDriveRequest(nanoId, path, roomId, roomBlocks),
      authObject,
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public adminDetachDrive(
    nanoId,
    path: string,
    roomId: string,
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminMutationRequest(
      nanoId,
      new NanoAdminDetachDriveRequest(nanoId, path, roomId),
      authObject,
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public adminEditNameRequest(
    nanoId,
    name: string,
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminMutationRequest(
      nanoId,
      new NanoAdminEditNameRequest(nanoId, name),
      authObject,
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public adminEditDenyAnonymous(
    nanoId,
    value: boolean,
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminMutationRequest(
      nanoId,
      new NanoAdminEditDenyAnonymousRequest(nanoId, value),
      authObject,
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public adminEditRequireExplicitPeerTrust(
    nanoId,
    value: boolean,
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminMutationRequest(
      nanoId,
      new NanoAdminEditRequireExplicitPeerTrustRequest(nanoId, value),
      authObject,
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  /**
   *
   * @param name access / service
   * @param openNew if true the stream will be reset (and not continue a previous one)
   */
  public adminLogStream(
    nanoId,
    name: string,
    openNew: boolean,
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ): RequestSession {
    return <RequestSession>(
      this.adminRequest(
        nanoId,
        new NanoAdminLogStream(nanoId, name, openNew),
        doneCallback,
        errorCallback,
        progressCallback,
        authObject
      )
    );
  }

  /**
   *
   * @param name access / service
   */
  public adminReadLogBackward(
    nanoId,
    name: string,
    authObject: AuthenticationObject,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    this.adminMutationRequest(
      nanoId,
      new NanoAdminLogReadBackward(nanoId, name),
      authObject,
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public getWaitingNanos(): Promise<{ [key: string]: number }> {
    return this.serverRestApiService.query({
      query: waitingNanosQuery,
    });
  }

  public getNanoSlots(): Promise<NanoSlotRecord[]> {
    return this.serverRestApiService.query({ query: getNanoSlotsQuery });
  }

  // Upload ------------------------------------------------------
  //
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  //

  private uploadSession: { [key: number]: UploadSession } = {};

  pauseUploadSession(sessionId) {
    let session = this.uploadSession[sessionId];
    if (session && !session.done) {
      session.isPaused = true;
    }
  }

  resumeUploadSession(sessionId) {
    let session = this.uploadSession[sessionId];

    if (session && !session.done) {
      session.isPaused = false;
      if (session.runningChunks == 0) {
        this.startChunkUpload(session);
      }
    }
  }

  removeUploadSession(sessionId) {
    let session = this.uploadSession[sessionId];

    if (session) {
      session.isPaused = true;
      delete this.uploadSession[sessionId];
    }
  }

  private calcUploadInfo(session: UploadSession): UploadChunkInfo {
    let size = session.file.size;
    let allChunk = Math.ceil(size / NanoService.NETWORK_FILE_CHUNK_SIZE);

    let offset;
    if (session.failedChunks.length > 0) {
      offset = session.failedChunks.shift();
    } else {
      offset = session.nextChunk;
      session.nextChunk = Math.min(session.nextChunk + 1, allChunk - 1);
    }

    let autoName;
    // upload from chat or allow autoName from upload dialog
    if (session.autoName || session.path === null) {
      autoName = session.name || session.file.name;
    }

    return {
      offset,
      size,
      mtime: session.freezedMTime || session.file.lastModified,
      allChunk,
      autoName,
    };
  }

  private uploadErrorHandler(err, session: UploadSession, uploadInfo: UploadChunkInfo) {
    //console.log('error chunk', err, uploadInfo.offset);
    if (!session.done) {
      session.failedChunks.push(uploadInfo.offset);
      session.runningChunks--;

      if (uploadInfo.offset == uploadInfo.allChunk - 1) {
        session.lastChunkStarted = false;
      }

      // call error cb only for the first error, we do not want to call it for every paralell upload process
      if (!session.errorState && session.errorCallback) {
        session.errorState = true;
        session.errorCallback(err);
      }

      this.pauseUploadSession(session.requestId);
      if (err.error != 4216 && err.error != 4218) {
        // permission problem
        setTimeout(() => {
          session.retryDelay *= 2;
          if (session.retryDelay > 16000) {
            session.retryDelay = 16000;
          }
          this.resumeUploadSession(session.requestId);
        }, session.retryDelay);
      } else if (err.error == 4217) {
        // todo should reset the error with the first chunk
        console.error('upload require a restart', err);
        session.nextChunk = 0;
        session.failedChunks = [];
        session.smac = [];
      } else {
        console.error('permission problem during upload', err);
      }
    } else {
      console.error('<- error registered but last chunk already sent', err, session.file.name);
    }
  }

  public createEmptyFile(
    resourceId: string,
    path: string,
    doneCallback,
    errorCallback?,
    progressCallback?
  ) {
    let autoName = path;
    let lastSep = path.lastIndexOf('/');
    if (lastSep >= 0) {
      autoName = autoName.substring(lastSep + 1);
    }
    let data = new Uint8Array(0);
    let finalSMAC = new Uint8Array([
      38, 70, 3, 107, 178, 39, 129, 83, 107, 231, 16, 36, 92, 140, 187, 4,
    ]);
    this.commonMutationRequest(
      resourceId,
      new NanoFilesystemPutRequest(resourceId, path, 0, -1, 0, data, finalSMAC, false, autoName),
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  private uploadOneChunk(
    session: UploadSession,
    uploadInfo: UploadChunkInfo,
    chunk: ArrayBuffer,
    finalSMAC: any
  ) {
    return new Promise((resolve, reject) => {
      this.commonMutationRequest(
        session.resourceId,
        new NanoFilesystemPutRequest(
          session.resourceId,
          session.path,
          uploadInfo.size,
          uploadInfo.mtime,
          uploadInfo.offset * NanoService.NETWORK_FILE_CHUNK_SIZE,
          chunk,
          finalSMAC,
          session.overwrite,
          uploadInfo.autoName
        ),
        (res: { normName: string | undefined; normPath: string | undefined }) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
        () => {}
      );
    });
  }

  public async startChunkUpload(session: UploadSession) {
    if (session.isPaused || session.done) return;

    let uploadInfo = this.calcUploadInfo(session);

    //console.log('upload', session, uploadInfo);
    if (
      // all chunk done
      (uploadInfo.offset >= uploadInfo.allChunk && uploadInfo.allChunk !== 0) ||
      // last chunk and all other chunk is not done yet
      (uploadInfo.offset == uploadInfo.allChunk - 1 &&
        session.successfulChunks != uploadInfo.allChunk - 1) ||
      session.lastChunkStarted
    )
      return;

    if (uploadInfo.offset == uploadInfo.allChunk - 1) {
      session.lastChunkStarted = true;
    }

    session.runningChunks++;

    // get the chunk bytearray
    let chunk;
    try {
      chunk = await this.getFileChunkByOffset(session.file, uploadInfo.offset);
    } catch (err) {
      this.uploadErrorHandler(err, session, uploadInfo);
      return;
    }

    // calc smac
    session.smac[uploadInfo.offset] = await this.generateSMAC(chunk);

    // calc final smac for the last chunk
    let finalSMAC = null;
    if (uploadInfo.offset == uploadInfo.allChunk - 1 || uploadInfo.allChunk === 0) {
      finalSMAC = await this.generateSMAC(concatArrayBuffer(session.smac));
    }

    // start upload chunk
    //console.log('start chunk', uploadInfo.offset);
    this.uploadOneChunk(session, uploadInfo, chunk, finalSMAC)
      .then((res: { normName: string | undefined; normPath: string | undefined }) => {
        //console.log('done chunk', uploadInfo.offset);

        session.errorState = false;
        session.retryDelay = 1000;
        session.runningChunks--;
        session.successfulChunks++;

        if (res.normName) {
          session.name = res.normName;
        }
        // could be overwritten, when the path was null at init
        // the next chunk must be called via the server selected path
        if (res.normPath) {
          session.path = res.normPath;
        }

        session.progressCallback(session.successfulChunks, uploadInfo.allChunk);
        if (uploadInfo.offset == uploadInfo.allChunk - 1 || uploadInfo.allChunk === 0) {
          session.done = true;
          session.doneCallback(session.name, session.path); // name, path
        } else {
          if (!session.isPaused) {
            for (let i = session.runningChunks; i < NanoService.PARALLEL_UPLOAD_WINDOW; i++) {
              this.startChunkUpload(session);
            }
          }
        }
      })
      .catch((err) => {
        this.uploadErrorHandler(err, session, uploadInfo);
      });
  }

  /**
   *
   * @param resourceId
   * @param file
   * @param path
   * @param doneCallback
   * @param errorCallback
   * @param progressCallback
   * @returns requestId for pause/resume
   */
  public uploadFile(
    resourceId: string,
    file: File,
    path: string,
    overwrite: boolean,
    alias: string | null,
    autoName: boolean,
    doneCallback,
    errorCallback,
    progressCallback,
    freezedMTime?: number
  ): number {
    let requestId = this.getNewRequestId();
    let session: UploadSession = {
      requestId,
      resourceId,
      file,
      path,
      name: alias || file.name,
      doneCallback,
      errorCallback,
      errorState: false,
      progressCallback,
      nextChunk: 0,
      smac: [],
      isPaused: false,
      done: false,
      overwrite,
      autoName,
      retryDelay: 1000,
      freezedMTime,
      successfulChunks: 0,
      failedChunks: [],
      runningChunks: 0,
      lastChunkStarted: false,
    };
    this.uploadSession[requestId] = session;
    this.startChunkUpload(session);
    return requestId;
  }

  public prepareDocumentEditor(
    resourceId: string,
    path: string,
    nanoVersion: NanoFeature,
    doneCallback: (prepareInfo: PrepareDocumentEditorInfo) => void,
    errorCallback?,
    progressCallback?: (
      part: number,
      count: number,
      session: RequestSession,
      payloadPart?: any
    ) => void
  ) {
    if (!isNanoFeatureSupported(nanoVersion, NanoFeature.DOCUMENT)) {
      return errorCallback();
    }

    this.commonRequest(
      resourceId,
      new NanoPrepareDocumentEditor(resourceId, path),
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public openDocumentEditor(
    resourceId,
    wsdWebsocketUrl: string,
    nanoVersion: NanoFeature,
    doneCallback: (session) => void,
    errorCallback?,
    progressCallback?: (
      part: number,
      count: number,
      session: RequestSession,
      payloadPart?: any
    ) => void
  ) {
    if (!isNanoFeatureSupported(nanoVersion, NanoFeature.DOCUMENT)) {
      return errorCallback();
    }

    this.commonMutationRequest(
      resourceId,
      new NanoOpenDocumentEditor(resourceId, wsdWebsocketUrl),
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public sendDocumentEditorMessage(
    resourceId,
    documentEditorSession: string,
    messages: DocumentEditorMessagesType,
    nanoVersion: NanoFeature,
    doneCallback: () => void,
    errorCallback?,
    progressCallback?: (
      part: number,
      count: number,
      session: RequestSession,
      payloadPart?: any
    ) => void
  ) {
    if (!isNanoFeatureSupported(nanoVersion, NanoFeature.DOCUMENT)) {
      return errorCallback();
    }

    this.commonRequest(
      resourceId,
      new NanoSendDocumentEditorMessage(resourceId, documentEditorSession, messages),
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  public readDocumentEditorMessage(
    resourceId,
    documentEditorSession: string,
    nanoVersion: NanoFeature,
    doneCallback: (message: DocumentEditorMessageData) => void,
    errorCallback,
    progressCallback: (
      part: number,
      count: number,
      session: RequestSession,
      payloadPart?: any
    ) => void
  ): RequestSession {
    if (!isNanoFeatureSupported(nanoVersion, NanoFeature.DOCUMENT)) {
      return errorCallback();
    }

    return <RequestSession>(
      this.commonRequest(
        resourceId,
        new NanoReadDocumentEditorMessage(resourceId, documentEditorSession),
        doneCallback,
        errorCallback,
        progressCallback
      )
    );
  }

  public closeDocumentEditor(
    resourceId,
    documentEditorSession: string,
    nanoVersion: NanoFeature,
    doneCallback: () => void,
    errorCallback,
    progressCallback?: (
      part: number,
      count: number,
      session: RequestSession,
      payloadPart?: any
    ) => void
  ) {
    if (!isNanoFeatureSupported(nanoVersion, NanoFeature.DOCUMENT)) {
      return errorCallback();
    }

    this.commonMutationRequest(
      resourceId,
      new NanoCloseDocumentEditor(resourceId, documentEditorSession),
      doneCallback,
      errorCallback,
      progressCallback
    );
  }

  // Nano client authentication functions
  //
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  //

  //auth dict, key is the nanoSlotId
  private cachedAuthentication: { [key: number]: AuthenticationObject } = {};
  public getUserAuthObject(nanoSlotRecord: NanoSlotRecord): Promise<AuthenticationObject> {
    const authObj = this.cachedAuthentication[nanoSlotRecord.id];
    if (authObj) return Promise.resolve(this.cachedAuthentication[nanoSlotRecord.id]);
    else {
      return this.askForNanoPassword(
        nanoSlotRecord.id,
        nanoSlotRecord.detail.version >= NANO_VERSION_FOR_NANO_MANAGER_ADMIN_SESSION
      );
    }
  }

  public setAuthObject(nanoId: number, authObject: AuthenticationObject): void {
    this.cachedAuthentication[nanoId] = authObject;
  }

  public clearAuthObject(nanoId: number): void {
    delete this.cachedAuthentication[nanoId];
  }

  public clearAuthObjects(): void {
    this.cachedAuthentication = {};
  }

  public createSession(nanoId: number, password: string): Promise<{ token: string; ttl: number }> {
    return new Promise((resolve, reject) => {
      this.adminCreateSession(
        nanoId,
        password,
        (session: any) => {
          resolve({ token: session.token, ttl: session.ttl });
        },
        (err) => {
          console.error('Error during admin session creation', err);
          reject(err);
        }
      );
    });
  }

  public isAuthError(err: any): boolean {
    if (err.error) {
      const errorCode = err.error;
      return (
        errorCode === ServerError.NANO_REQUEST_ERROR_ADMIN_PASSWORD ||
        errorCode === ServerError.NANO_REQUEST_ERROR_ADMIN_PASSWORD_MISSING ||
        errorCode === ServerError.NANO_REQUEST_ERROR_ADMIN_SESSION
      );
    } else {
      return false;
    }
  }

  private getNanoClientAuthMode(
    nanoId: number,
    tryWithSessionEnabled: boolean = false
  ): Promise<AdminPasswordMode> {
    return new Promise((resolve, reject) => {
      const emptyAuth = new AuthenticationObject('');
      if (tryWithSessionEnabled) {
        emptyAuth.setSession({ token: 'dummy-token', ttl: 5 });
      }
      this.adminGetListing(
        nanoId,
        '',
        emptyAuth,
        () => {
          reject(
            'This should not happen. Empty string password for nano client (slot id): ' + nanoId
          );
        },
        (err) => {
          if (err.error) {
            const errorCode = err.error;
            if (errorCode == ServerError.NANO_REQUEST_ERROR_ADMIN_PASSWORD) {
              if (tryWithSessionEnabled) {
                resolve(AdminPasswordMode.PROVIDE_FOR_SESSION);
              } else {
                resolve(AdminPasswordMode.PROVIDE);
              }
            } else if (errorCode == ServerError.NANO_REQUEST_ERROR_ADMIN_PASSWORD_MISSING) {
              resolve(AdminPasswordMode.FIRST);
            } else if (errorCode == ServerError.NANO_REQUEST_ERROR_ADMIN_SESSION) {
              if (tryWithSessionEnabled) {
                //we are trying with dummy session, so we can return this value
                resolve(AdminPasswordMode.PROVIDE_FOR_SESSION);
              } else {
                //try with dummy session
                this.getNanoClientAuthMode(nanoId, true).then(resolve);
              }
            } else if (errorCode == ServerError.NANO_REQUEST_ERROR_TOO_MANY_AUTH_FAILS) {
              reject('too many requests');
            } else {
              reject({
                message: 'Unknown error code while prompting nano client (slot id): ' + nanoId,
                originError: err,
              });
            }
          } else {
            reject({
              message: 'Unknown error while prompting nano client (slot id): ' + nanoId,
              originError: err,
            });
          }
        }
      );
    });
  }

  private askForNanoPassword(
    nanoId: number,
    isSessionSupported: boolean = true
  ): Promise<AuthenticationObject> {
    return new Promise((resolve, reject) => {
      this.getNanoClientAuthMode(nanoId).then((adminPasswordMode) => {
        const dialogRef = this.dialog.open(AdminPasswordDialogComponent, {
          data: { adminPasswordMode: adminPasswordMode },
        });

        dialogRef
          .afterClosed()
          .subscribe((promptResponse: { password: string; passwordAgain: string }) => {
            if (!promptResponse) {
              reject('cancel');
              return;
            }

            const authObject = new AuthenticationObject(
              promptResponse.password,
              isSessionSupported
            );

            this.setAuthObject(nanoId, authObject);
            //old method, only simple password is needed
            if (adminPasswordMode === AdminPasswordMode.PROVIDE) {
              resolve(authObject);
            }
            //new method, set up session with given password
            else if (adminPasswordMode === AdminPasswordMode.PROVIDE_FOR_SESSION) {
              this.createSession(nanoId, promptResponse.password)
                .then((session) => {
                  authObject.setSession(session);
                  resolve(authObject);
                })
                .catch(reject);
            } else if (adminPasswordMode === AdminPasswordMode.FIRST) {
              this.adminChangePasswordRequest(
                nanoId,
                promptResponse.password,
                authObject,
                () => {
                  this.snackbarService.showSnackbar(marker('Change admin password successful!'));

                  resolve(authObject);
                },
                reject
              );
            }
          });
      });
    });
  }

  // Helper functions ------------------------------------------------------
  //
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  ////
  //
  //
  //
  //
  //

  /**
   *
   * @param initMessage initMessage inside the graphqlResponse['data']['nanoRequest']
   * @returns "cacheKey" or "transferKey" string if its an init message, null otherwise
   */
  private getKeyFromInitMessage(initMessage): 'cacheKey' | 'transferKey' {
    if ('cacheKey' in initMessage && initMessage.cacheKey !== null) {
      return 'cacheKey';
    } else if ('transferKey' in initMessage && initMessage.transferKey !== null) {
      return 'transferKey';
    } else return null;
  }

  private verifyInitMessage(initKey, msg): Promise<void> {
    return this.generateRAH(initKey, msg.request)
      .then((RAH) => {
        if (compareByteArray(RAH, msg.response.RAH)) {
          return true;
        } else {
          return Promise.reject('RAH mismatch');
        }
      })
      .catch((err) => {
        console.error('RAH error', err);
        return Promise.reject('Can not generate RAH from msg');
      });
  }

  /**
   * Recreate the RAH from the message: (CacheKey or TransferKey + request first 128 byte) -> sha256() -> first 16 byte
   * @param key CacheKey or TransferKey
   * @param request nanoRequestBase.getRequest, binary data for graphql nanoRequest subscription request param
   * @returns Promise<RAH>
   */
  private generateRAH(key: Uint8Array, request: Uint8Array): any {
    const arr = new Uint8Array(key.length + Math.min(128, request.length));
    arr.set(key, 0);
    arr.set(request.subarray(0, 128), key.length);

    return GlobalCrypto.SHA256(arr).then((result) => {
      return new Uint8Array(result.slice(0, 16));
    });
  }

  // future encode check
  // nano owner account peerkeyring signature crypto context
  // cipher: MACS, peer_kr: owner, plain: MAC
  private checkMAC(mac, macs, resourceId) {
    return this.roomService.getRoom(resourceId).then((roomData) => {
      return this.accountService.getPeerKeyring(roomData['ownerAccountId']).then((peer_kr) => {
        return ACCOUNT_SIGNATURE_CRYPTO_CONTEXT.load(
          new AccountSignatureConfigLoadArgs(macs, peer_kr, mac)
        );
      });
    });
  }

  /**
   * For upload file: all chunk need to be encoded, then concat them and encode again
   * @param data
   * @returns
   */
  private generateSMAC(data: ArrayBuffer) {
    return GlobalCrypto.SHA256(data).then((result) => {
      return result.slice(0, 16);
    });
  }

  /**
   * We want to check if a file has changed during the upload.
   * There is no supported option in browser yet. But there is a trick.
   * If you start reading a changed file via FileReader, it will throw an error.
   * However you should abort the file reading immediatly after it started, because
   * it can load the entire file into the memory, and we dont want to do that right?
   *
   * @param {File} file - the file which need to be checked
   * @param errorCallback we can register only on the errorCallback, the behaviour is depends
   * on the browser. Firefox throw an AbortError, but chrome doesnt. But on NotReadableError
   * you can register using a callback
   */
  private isFileOk(file: File, errorCallback) {
    const reader = new FileReader();
    reader.onprogress = function (res) {
      console.log(res);
      if (res.loaded > 0) reader.abort();
    };
    reader.onerror = function (err) {
      if (err.target.error?.name != 'AbortError') {
        // it is tipically "NotReadableError"
        errorCallback(err.target.error.message);
      }
    };

    reader.readAsArrayBuffer(file);
  }

  private getFileChunkByOffset(file: File, offset: number) {
    const start = offset * NanoService.NETWORK_FILE_CHUNK_SIZE;
    let end = start + NanoService.NETWORK_FILE_CHUNK_SIZE;
    if (end > file.size) {
      end = file.size;
    }

    return file.slice(start, end).arrayBuffer();
  }
}

let fileStreamSizeLimit = AppStorage.getItem(AdvancedSettingsEnum.FILE_STREAM_SIZE_LIMIT);
if (fileStreamSizeLimit !== undefined) {
  let limit = parseInt(fileStreamSizeLimit);
  if (limit >= 0) {
    NanoService.FILE_STREAM_MIN_SIZE = limit;
  }
}
