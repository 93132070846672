import { marker } from '@biesbjerg/ngx-translate-extract-marker';

// longer message will be split into separated messages
// v2 message with replied id and with 1 character message is 53 byte length
// so 1945 character will be 1999 bytes which is the maximum by the server now
export const MAX_CHAT_MESSAGE_LENGTH = 1000; //1945;

export const MAX_CHAT_ATTACHMENT = 20;

// too long message should be splitted into more messages
export const MAX_SPLITTED_MESSAGE = 5;

export const MESSAGE_INPUT_PLACEHOLDER = marker('Type message here...');
