<h2 mat-dialog-title translate>Create new room</h2>
<form>
  <mat-dialog-content class="mat-typography">
    <div class="name-data-container">
      <div class="avatar-content" (click)="openEditAvatarDialog()" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true">
        <app-avatar [blob]="avatarBlob"></app-avatar>
        <mat-icon [matTooltip]="'Change room image' | translate">add_circle</mat-icon>
      </div>
      <mat-form-field appearance="fill" class="input">
        <mat-label translate>Room name</mat-label>
        <input type="email" matInput [formControl]="roomNameControl" />
        <mat-error *ngIf="roomNameControl.hasError('required')" translate> Please enter a room name</mat-error>
        <mat-error *ngIf="roomNameControl.hasError('maxLength')" translate> Room name can't be longer than 20 characters</mat-error>
      </mat-form-field>
    </div>
    <div class="room-data-container">
      <mat-accordion>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title> Add members </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            <mat-form-field appearance="fill" class="input">
              <mat-label translate>User name or ID</mat-label>
              <input matInput [formControl]="inviteUserIdControl" />
            </mat-form-field>
          </p>
          <p>
            <mat-selection-list #userSelectionList color="primary" selectionChange="">
              <mat-list-option *ngFor="let inviteUser of filteredAccountList" [value]="inviteUser" [selected]="shouldPreSelect(inviteUser.id)">
                <div class="room-invite-item">
                  <app-avatar class="room-avatar" [accountAvatarRecord]="inviteUser"></app-avatar> <span class="notranslate">{{ inviteUser.avatarName }}</span>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </p>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="selected-users-list" *ngIf="selectedUsers.length > 0">
        <span translate>Selected users: </span><span>{{ selectedUsers.length }}</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-button (click)="onSubmit()" color="primary" [disabled]="!roomNameControl.valid"><span translate>Save</span></button>
  </mat-dialog-actions>
</form>
