<div class="manager-action-container">
  <div class="manager-action-preview">
    <span class="mdi mdi-{{ action.icon }} file-{{ action.icon }}"></span>
  </div>
  <div class="manager-action-status">
    <div class="manager-action-info">
      <div class="manager-action-title mat-small">{{ action.resourceId }}/{{ action.path }}</div>
      <div class="manager-action-progress mat-small"></div>
    </div>
    <div class="manager-action-bar" *ngIf="!action.done && !action.error">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
  <div class="manager-action-action">
    <mat-icon *ngIf="!action.done && !action.error">pause</mat-icon>
    <mat-icon *ngIf="action.done && !action.error">done</mat-icon>
    <mat-icon *ngIf="action.error">error</mat-icon>
  </div>
</div>
