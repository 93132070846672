<h2 mat-dialog-title translate>Change contact policy</h2>
<form id="policy-form" [formGroup]="policyForm">
  <mat-dialog-content class="mat-typography">
    <div class="form-row">
      <div>
        <ul class="policy-description-list">
          <li><label translate>Anyone: </label><span translate>Anyone can initiate a dialogue with you.</span></li>
          <li><label translate>Same room: </label><span translate>Only users in rooms you are a member of can initiate a dialogue with you, or users in your contact list.</span></li>
          <li><label translate>Owned room: </label><span translate>Only users in rooms you are an owner of can initiate a dialogue with you, or users in your contact list.</span></li>
          <li><label translate>Contact only: </label><span translate>Only users in your contact list can initiate a dialogue with you.</span></li>
        </ul>
      </div>
      <mat-form-field appearance="outline">
        <mat-label translate>Contact policy</mat-label>
        <mat-select formControlName="policy">
          <mat-option [value]="0"><span translate>Anyone</span></mat-option>
          <mat-option [value]="10"><span translate>Same room</span></mat-option>
          <mat-option [value]="20"><span translate>Owned room</span></mat-option>
          <mat-option [value]="30"><span translate>Contact only</span></mat-option>
        </mat-select>
        <mat-error *ngIf="policyForm.get('policy').errors?.['required']"><span translate>Please choose a contact policy.</span></mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-button color="primary" [disabled]="!policyForm.valid" (click)="changePolicy()"><span translate>Change</span></button>
  </mat-dialog-actions>
</form>
