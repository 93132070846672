<div id="chat-container">
  <mat-card *ngIf="isRoomError" class="error-room-container">
    <mat-card-content>
      <p translate>This room is no longer available. Please click the button below to remove this room from your workspace.</p>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button color="primary" (click)="deleteRoom()"><span translate>Leave room</span></button>
    </mat-card-actions>
  </mat-card>

  <div id="chat-body" #resourceContainer>
    <div id="chat-scroll" #chatScroll (pointerdown)="onMouseDown($event)">
      <div class="footer-buttons">
        <div>
          <div id="is-typing-container" [ngClass]="{ 'currently-typing': typingUsers.length > 0 }">
            <span>{{ getTypingNames() }}</span>
          </div>
        </div>
        <div *ngIf="isOnApp && type === RESOURCE_TYPE_ROOM" (click)="navigateToDrive()">
          <mat-icon>computer</mat-icon>
          <span> Browse drive</span>
        </div>
      </div>
      <div id="chat-box-bottom" *ngIf="!isMessagesLoading" (visibilityChange)="chatBoxBottomVisible($event)" appEnterTheViewportNotifier></div>
      <ng-container *ngFor="let msg of unsentMessages">
        <app-chat-unsent-message (resizedEvent)="onMessageResized()" (editEvent)="editUnsentMessage(msg)" [data]="msg"></app-chat-unsent-message>
      </ng-container>
      <ng-container *ngFor="let msg of chatMessages; let i = index">
        <ng-container *ngIf="msg.id">
          <app-chat-message
            #messageContainers
            [id]="MESSAGE_ID_PREFIX + msg.id"
            [data]="msg"
            (resizedEvent)="onMessageResized()"
            [runSeenAnimation]="runSeenAnimation"
            (contextmenuevent)="handleChatMessageContextMenu($event)"
            (jumpToMessageEvent)="loadReplyMessage($event)"
            appEnterTheViewportNotifier
            [viewportNotifierContainer]="chatScroll"
            (visibilityChange)="messageVisible($event, msg.id)"
          ></app-chat-message>
        </ng-container>
        <ng-container *ngIf="msg.type === MessageRecordType.VOID">
          <div
            class="void-row"
            (click)="processVoids()"
            appEnterTheViewportNotifier
            [viewportNotifierContainer]="chatScroll"
            [viewportNotifierMargin]="'80px 0px 80px 0px'"
            (visibilityChange)="voidVisible($event, msg)"
          >
            <mat-divider></mat-divider><mat-icon>hourglass_empty</mat-icon> <mat-divider></mat-divider>
          </div>
        </ng-container>
        <ng-container *ngIf="msg.type === MessageRecordType.DATE">
          <div class="separator-date-row">
            <mat-divider></mat-divider>
            <div class="separator-date">{{ msg.date | date : 'MMMM d' }}</div>
            <mat-divider></mat-divider>
          </div>
        </ng-container>
      </ng-container>
      <div class="loading" *ngIf="isMessagesLoading">
        <mat-spinner diameter="40"></mat-spinner>
      </div>

      <div *ngIf="chatEndReached" class="mat-small" translate>All messages are protected by strong encryption.</div>
      <div *ngIf="chatEndReached" class="mat-small begin-message-margin" translate>
        This is the beginning of <span class="notranslate">'{{ resourceName }}'</span>
      </div>
    </div>
    <div id="new-message-sign-container">
      <button mat-mini-fab color="primary" id="new-message-sign" (click)="scrollToBottom()" *ngIf="showStartToChatArrow"><mat-icon>arrow_downward</mat-icon></button>
    </div>
    <div id="message-date-container">
      <div id="message-date" *ngIf="showStartToChatArrow && topVisibleMessage">{{ topVisibleMessage.date | date : 'MMMM d' }}</div>
    </div>
  </div>
  <div id="chat-input" *ngIf="myPermissions && myPermissions.canSendMessages && !isRoomError">
    <div class="preview-edited-message" *ngIf="editedMessage">
      <div>
        <div><span translate class="edited-msg-title">Original message:</span></div>
        <div>
          <span class="edited-prev-msg">{{ editedMessage.message }}</span>
        </div>
      </div>
      <div>
        <mat-icon (click)="restoreChatMessage()">close</mat-icon>
      </div>
    </div>
    <div class="preview-edited-message" *ngIf="repliedMessage">
      <div>
        <div><span translate class="edited-msg-title">Reply to:</span></div>
        <div>
          <span class="edited-prev-msg">{{ repliedMessage.message }}</span>
        </div>
      </div>
      <div>
        <mat-icon (click)="closeReplyBar()">close</mat-icon>
      </div>
    </div>
    <app-chat-message-editor
      [content]="editedMessage ? (editedMessage.error ? '' : editedMessage.message) : lastUnsentMessage"
      [autofocus]="isOnDesktop"
      [canUpload]="myPermissions.canUploadFiles && !editedMessage"
      [resourceId]="resourceId"
      [isDriveAvailable]="isDriveAvailable"
      (submitEvent)="editedMessage ? onEditSubmit($event, editedMessage) : onSubmit($event)"
      (changeEvent)="onInputChange($event)"
      (focusChangeEvent)="onFocusChanged($event)"
    ></app-chat-message-editor>
  </div>

  <div [ngStyle]="{ position: 'fixed', top: contextMenuPosition?.y, left: contextMenuPosition?.x }" [matMenuTriggerFor]="context_menu"></div>
  <mat-menu #context_menu="matMenu" [overlapTrigger]="true" [backdropClass]="'click-through'">
    <ng-template matMenuContent>
      <div id="more-reactions" *ngIf="isExpanded === true">
        <span [ngClass]="{ selected: contextTargetMessage.data.myReaction === r.index }" (click)="contextMenuAction(ContextMenuAction.REACT, r.index)" *ngFor="let r of reactions"
          ><img [src]="r.src"
        /></span>
      </div>
      <div *ngIf="isExpanded === false" class="chat-context-menu">
        <ng-container *ngIf="contextTargetPermissions.reactionPermission">
          <div class="context-menu-emojibar">
            <span *ngFor="let emojiObj of topEmojies" [ngClass]="{ selected: contextTargetMessage.data.myReaction === emojiObj.index }">
              <img [src]="emojiObj.emoji" (click)="contextMenuAction(ContextMenuAction.REACT, emojiObj.index)" />
            </span>
            <span (click)="expandEmojiPicker($event)" class="expand-icon"><mat-icon>expand_circle_down</mat-icon></span>
          </div>
          <mat-divider></mat-divider>
        </ng-container>
        <button mat-menu-item *ngIf="myPermissions.canSendMessages" (click)="contextMenuAction(ContextMenuAction.REPLY)">
          <mat-icon>reply</mat-icon>
          <span translate>Reply</span>
        </button>
        <button *ngIf="contextTargetPermissions.modifyPermission" mat-menu-item (click)="contextMenuAction(ContextMenuAction.EDIT)">
          <mat-icon>edit</mat-icon>
          <span translate>Edit</span>
        </button>
        <button *ngIf="false && contextTargetPermissions.pinPermission" mat-menu-item (click)="contextMenuAction(ContextMenuAction.PIN)">
          <mat-icon>push_pin</mat-icon>
          <span translate>Pin</span>
        </button>
        <button mat-menu-item (click)="contextMenuAction(ContextMenuAction.COPY)">
          <mat-icon>content_copy</mat-icon>
          <span translate>Copy</span>
        </button>
        <button mat-menu-item (click)="contextMenuAction(ContextMenuAction.COPY_FILE_URL)" *ngIf="contextTargetMessage.event['fileUrl']">
          <mat-icon>file_copy</mat-icon>
          <span translate>Copy File URL</span>
        </button>
        <button
          mat-menu-item
          (click)="contextMenuAction(ContextMenuAction.OPEN_FILE_IN_EDITOR)"
          *ngIf="contextTargetMessage.event['fileUrl'] && isOnDesktop && canOpenInEditor(); isDocumentEditorSupported"
        >
          <mat-icon>design_services</mat-icon>
          <span translate>Open in editor</span>
        </button>
        <!-- <button mat-menu-item (click)="contextMenuAction('select')">
          <mat-icon>check_circle</mat-icon>
          <span translate>Select</span>
        </button> -->
        <button *ngIf="contextTargetPermissions.deletePermission" mat-menu-item (click)="contextMenuAction(ContextMenuAction.DELETE)">
          <mat-icon>delete</mat-icon>
          <span translate>Delete</span>
        </button>
      </div>
    </ng-template>
  </mat-menu>
</div>
