<mat-card appearance="outlined" (click)="openPath()" matRipple>
  <div class="result-row">
    <div class="result-data">
      <div class="file-name">
        <div class="file-name-first-part">{{ fileNameFirstPart }}</div>
        <div>{{ fileNameSecondPart }}{{ '.' + result.file.ext }}</div>
      </div>
      <div class="date">
        <span>{{ result.file.createdAt | date: 'shortDate' }}</span> -
        <span>{{ result.resourceName }}</span>
      </div>
    </div>
    <div class="result-thumbnail">
      <app-blob-viewer *ngIf="img0" [blob]="img0" type="image"></app-blob-viewer>
      <span *ngIf="!img0" class="mdi-thumbnail mdi mdi-{{ result.file.icon }} file-{{ result.file.icon }}"></span>

      <app-avatar [blob]="result.resourceAvatar"></app-avatar>
    </div>
  </div>
</mat-card>
