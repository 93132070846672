/**
 * For signing any payload by using the account keyring.
Signing the public keys of an account creates a trust-chain that other accounts can save with a simple
fingerprint to verify the trust.
Signing a group-encrypted message ensures the poster's identity.
 */

import { CryptoContext } from '../base';
import { AccountSignatureConfigDumpArgs, AccountSignatureConfigLoadArgs } from './args';
import { AccountSignatureConfigV1 } from './v1';

export let ACCOUNT_SIGNATURE_CRYPTO_CONTEXT = new CryptoContext<
  AccountSignatureConfigLoadArgs,
  AccountSignatureConfigDumpArgs
>([new AccountSignatureConfigV1()], [new Uint8Array([1])]);
