import { get } from 'lodash';

export class ObjectSorter {
  private static defaultCollator: Intl.Collator = new Intl.Collator('en', {
    numeric: true,
    caseFirst: 'upper',
  });

  private static collator: Intl.Collator;

  static sort<T>(
    objects: Array<T>,
    sortProp: string,
    direction: number = 1,
    collator: Intl.Collator = null
  ): Array<T> {
    if (!collator) collator = this.defaultCollator;

    objects.sort((a, b) => {
      let aVal = get(a, sortProp);
      let bVal = get(b, sortProp);

      if (aVal === undefined) return 1;
      else if (bVal === undefined) return -1;
      else return collator.compare(aVal, bVal) * direction;
    });

    return objects;
  }

  static getCollator(): Intl.Collator {
    return this.defaultCollator;
  }

  static sortMultiColumn<T>(
    objects: Array<T>,
    sortProps: string[],
    direction: number = 1,
    collator: Intl.Collator = null
  ): Array<T> {
    if (!collator) collator = this.defaultCollator;

    objects.sort((a, b) => {
      return sortProps.reduce((bool, sortProp) => {
        let aVal = get(a, sortProp);
        let bVal = get(b, sortProp);
        if (aVal === undefined) return 1;
        else if (bVal === undefined) return -1;
        else return bool || collator.compare(aVal, bVal);
      }, 0);
    });

    return objects;
  }
}
