<h2 mat-dialog-title translate>Create new category</h2>
<form>
  <mat-dialog-content class="mat-typography">
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label translate>Name</mat-label>
        <input matInput [formControl]="groupNameControl" />
        <mat-error *ngIf="groupNameControl.errors?.['required']"><span translate>Please enter a new category name.</span></mat-error>
        <mat-error *ngIf="groupNameControl.errors?.['maxlength']"><span translate>Category name can't be longer than 35 characters.</span></mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-button (click)="onSubmit()" color="primary" [disabled]="!groupNameControl.valid"><span translate>Save</span></button>
  </mat-dialog-actions>
</form>
