<div>
  <div class="sub-page">
    <h1 translate>Contacts</h1>

    <div id="contact-search">
      <div id="contact-search-form">
        <mat-card appearance="outlined">
          <mat-card-content>
            <p translate>Search for existing contact by user name, or search new contact by Contact ID</p>
            <span translate>User name or Contact ID</span>:<br />
            <mat-form-field>
              <input matInput type="text" [formControl]="searchTermControl" (input)="searchUser()" />
              <mat-error *ngIf="searchTermControl.errors?.['foundError']"><span translate>Can not find user with Contact ID</span></mat-error>
              <mat-error *ngIf="searchTermControl.errors?.['meError']"><span translate>This is your Contact ID</span></mat-error>
              <mat-error *ngIf="searchTermControl.errors?.['existsError']"><span translate>Account is already in your contact list</span></mat-error>
            </mat-form-field>

            <div *ngIf="foundAccount" class="one-contact">
              <mat-divider></mat-divider>

              <div class="contact-line">
                <div class="avatar-cont">
                  <app-avatar [accountAvatarRecord]="foundAccount"></app-avatar>
                  <span *ngIf="!foundAccount.deleted" class="notranslate">{{ foundAccount.avatarName }}</span
                  ><span class="data-error" *ngIf="foundAccount.deleted" translate>Deleted user</span>
                </div>
                <div class="avatar-buttons">
                  <button mat-icon-button aria-label="Start chat" (click)="startDialogue(foundAccount)" [matTooltip]="'Start chat' | translate" *ngIf="foundAccount !== me">
                    <mat-icon>chat</mat-icon>
                  </button>
                  <button *ngIf="canAdd" mat-icon-button aria-label="Add user to the contact list" (click)="createContact(foundAccount)" [matTooltip]="'Add to contacts' | translate">
                    <mat-icon color="primary">person_add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="mat-card contacts" *ngIf="filteredContactList?.length">
      <div class="button-container">
        <button mat-icon-button (click)="switchViewMode(viewModeEnum.TILE)" [ngClass]="{ primary: viewMode === viewModeEnum.TILE }" [matTooltip]="'Tile view' | translate">
          <mat-icon>view_module</mat-icon>
        </button>
        <button mat-icon-button (click)="switchViewMode(viewModeEnum.LIST)" [ngClass]="{ primary: viewMode === viewModeEnum.LIST }" [matTooltip]="'List view' | translate">
          <mat-icon>view_list</mat-icon>
        </button>
      </div>
      <div class="contact-container" [ngClass]="{ 'compact-view': viewMode === viewModeEnum.LIST }">
        <div *ngFor="let acc of filteredContactList">
          <div matRipple class="contact-item" appShowUserInfo [accountAvatarRecord]="acc">
            <div class="avatar-cont">
              <app-avatar [accountAvatarRecord]="acc"></app-avatar>
            </div>
            <div class="avatar-name-cont">
              <span *ngIf="!acc.deleted" class="overflow notranslate">{{ acc.avatarName }}</span>
              <span class="data-error" *ngIf="acc.deleted" translate> Deleted user </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
