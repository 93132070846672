<div class="input-container">
  <div>
    <img id="logo" [src]="isDarkTheme ? '/assets/img/ui/logo_nano-dark.svg' : '/assets/img/ui/logo_nano.svg'" />
    <div class="app-download-images" *ngIf="!isOnApp && (isMobile || isTablet)">
      <a [href]="'googlePlayStoreLink' | translate"><img class="playstore-download-button" [alt]="'Get it on Google Play ' | translate" [src]="'googlePlayStoreImagePath' | translate" /></a>
      <a [href]="'iosAppStoreLink' | translate"><img class="ios-download-button" [alt]="'Download on the: IOS App Store' | translate" [src]="'iosAppStoreImagePath' | translate" /></a>
    </div>
    <form class="form-container" [formGroup]="loginForm" (submit)="startLogin()">
      <mat-form-field>
        <mat-label translate>Email address</mat-label>
        <input type="email" matInput formControlName="email" />
        <mat-error *ngIf="loginForm.get('email').hasError('required')" translate> Please enter your email address </mat-error>
        <mat-error *ngIf="loginForm.get('email').hasError('email')" translate> Please enter a valid email address </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label translate>Password</mat-label>
        <input type="password" matInput formControlName="password" />
        <mat-error *ngIf="loginForm.get('email').hasError('required')" translate> Please enter your password </mat-error>
      </mat-form-field>
      <mat-checkbox *ngIf="!isOnApp" color="primary" formControlName="rememberMe"><span translate>Remember me</span></mat-checkbox>
      <app-etalon-info></app-etalon-info>
      <button class="action-button" type="submit" [disabled]="isPending || !loginForm.valid" mat-raised-button color="primary">
        <span *ngIf="!isPending && !isLoginSuccess" translate>Log in</span>
        <mat-icon *ngIf="isPending"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        <mat-icon *ngIf="isLoginSuccess">done</mat-icon>
      </button>
    </form>
    <div class="nav-buttons">
      <a routerLink="/account-recovery"
        ><button mat-button color="primary"><span translate>Forgot password</span></button></a
      >
      <a routerLink="/register"
        ><button mat-button color="primary"><span translate>Create an account</span></button></a
      >
    </div>
  </div>
</div>
