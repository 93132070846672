import { Injectable } from '@angular/core';
import { RoomRecord, RoomWithData } from '../../server-services/query-records/room-records';
import { getRoomQuery } from '../../server-services/querys';
import { Cache } from './cache-logic/cache';
import {
  CacheDataPath,
  CacheInterface,
  CacheLogic,
  Request,
  RequestCacheStateType,
} from './cache-logic/cache-logic-interface';
import { cacheCustomLogic } from './cache-logic/custom-cache-logic';

@Injectable({
  providedIn: 'root',
})
export class CacheService implements CacheInterface {
  private cache: Cache;

  constructor() {
    this.cache = new Cache(cacheCustomLogic);
    console.log('cache', this.cache);
  }

  getCacheLogic(): CacheLogic {
    return this.cache.getCacheLogic();
  }

  resetCache() {
    this.cache.resetCache();
  }

  getRequestCacheState(request: Request): RequestCacheStateType {
    return this.cache.getRequestCacheState(request);
  }

  public getStoredRequestResponse(cacheKey: CacheDataPath, request: Request, serverResponse?: any) {
    return this.cache.getStoredRequestResponse(cacheKey, request, serverResponse);
  }

  mergeRequestResult(request: Request, response: any): void {
    return this.cache.mergeRequestResult(request, response);
  }

  getCacheData(cacheKey: CacheDataPath) {
    return this.cache.getCacheData(cacheKey);
  }
  getCacheDataByRequest(request: Request) {
    return this.cache.getCacheDataByRequest(request);
  }
  setCacheData(cacheKey: CacheDataPath, data: any) {
    return this.cache.setCacheData(cacheKey, data);
  }
  setCacheDataByRequest(request: Request, data: any) {
    return this.cache.setCacheDataByRequest(request, data);
  }

  deleteCacheData(cacheKey: CacheDataPath) {
    return this.cache.deleteCacheData(cacheKey);
  }
  deleteCacheDataByRequest(request: Request) {
    this.cache.deleteCacheDataByRequest(request);
  }

  public getRoomRecordFromCache(roomId: string): Promise<RoomRecord | RoomWithData> {
    let room: RoomRecord | RoomWithData = this.getCacheDataByRequest({
      query: getRoomQuery,
      variables: {
        id: roomId,
      },
    });

    if (room) {
      return Promise.resolve(room);
    } else {
      return Promise.reject(`Could not find room in cache with id: ${roomId}`);
    }
  }
}
