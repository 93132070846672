import { DialogueRecord } from '../../../../../../server-services/query-records/room-records';
import {
  SidebarGroup,
  SidebarResource,
  UNGROUPPED_GROUP_ID,
} from '../../../../../../server-services/query-records/sidebar-records';
import { WorkspaceQueryDialogueRecord } from '../../../../../../server-services/query-records/workspace-records';
import {
  getDialogueDataQuery,
  getSidebarGroupQuery,
  getSidebarResourceQuery,
  getSidebarUngrouppedQuery,
} from '../../../../../../server-services/querys';
import { ResponseMergerHandler } from '../../../cache-logic-interface';

export let customCreateDialogueMutationMerger: ResponseMergerHandler = function (
  cache,
  request,
  response: WorkspaceQueryDialogueRecord
) {
  let dialogueData: DialogueRecord = cache.getCacheDataByRequest({
    query: getDialogueDataQuery,
    variables: response,
  });
  if (!dialogueData) {
    dialogueData = {
      hidden: response.hidden,
      id: response.id,
      ignore: response.ignore,
      seenMessageId: response.seenMessageId,
      topMessageId: response.topMessageId,
    };

    cache.setCacheDataByRequest(
      { query: getDialogueDataQuery, variables: dialogueData },
      dialogueData
    );
  }

  let sidebarRes: SidebarResource = cache.getCacheDataByRequest({
    query: getSidebarResourceQuery,
    variables: response,
  });
  if (!sidebarRes) {
    // it is not in the cache
    sidebarRes = {
      groupId: response.groupId,
      id: response.id,
      ordering: response.ordering,
      type: response.type,
    };

    cache.setCacheDataByRequest({ query: getSidebarResourceQuery, variables: response }, response);

    if (sidebarRes.groupId === UNGROUPPED_GROUP_ID) {
      // put on the top
      let ungroupped: SidebarResource[] = cache.getCacheDataByRequest({
        query: getSidebarUngrouppedQuery,
        variables: {},
      });
      if (!ungroupped.find((resource) => resource.id === response.id)) {
        ungroupped.unshift(sidebarRes);
      }
    } else {
      let group: SidebarGroup = cache.getCacheDataByRequest({
        query: getSidebarGroupQuery,
        variables: {
          id: sidebarRes.groupId,
        },
      });
      if (!group.resources.find((resource) => resource.id === response.id)) {
        // put on the top
        group.resources.unshift(sidebarRes);
      }
    }
  }
};
