import {
  SidebarGroup,
  SidebarResource,
} from '../../../../../../../../server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionResourceGroupEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getAllSidebarGroupQuery,
  getSidebarGroupQuery,
  getSidebarUngrouppedQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onDeleteResourceGroupEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionResourceGroupEventRecord) => {
    let group: SidebarGroup = cache.getCacheDataByRequest({
      query: getSidebarGroupQuery,
      variables: {
        id: event.id,
      },
    });

    let allGroup: SidebarGroup[] = cache.getCacheDataByRequest({
      query: getAllSidebarGroupQuery,
      variables: {},
    });
    let index = allGroup.indexOf(
      cache.getCacheDataByRequest({ query: getSidebarGroupQuery, variables: group })
    );
    cache.deleteCacheDataByRequest({ query: getSidebarGroupQuery, variables: event });

    if (index > -1) {
      allGroup.splice(index, 1);
    }

    let ungroupedResources: SidebarResource[] = cache.getCacheDataByRequest({
      query: getSidebarUngrouppedQuery,
      variables: {},
    });
    group.resources.forEach((res) => {
      ungroupedResources.push(res);
    });
  };
