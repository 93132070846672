<div>
  <div class="main-menu">
    <div class="avatar-bar">
      <div id="avatar-container" (click)="openEditAvatarDialog()">
        <app-avatar [accountAvatarRecord]="me"></app-avatar>
      </div>
      <div class="avatar-desc">
        <span class="notranslate">{{ me?.avatarName }}</span>
        <div *ngIf="!isAnonym">
          <button (click)="copyContactId()" mat-raised-button><span translate>Copy contact ID</span></button>
        </div>
      </div>
    </div>

    <div class="menus" *ngIf="!isAnonym">
      <div matRipple class="menu-row">
        <span
          ><a routerLink="/account"><span class="mdi mdi-24px mdi-account-box"></span> <span class="link-text" translate>Account</span></a></span
        >
      </div>
      <div matRipple class="menu-row">
        <span
          ><a routerLink="/contact-list"><span class="mdi mdi-24px mdi-contacts"></span><span class="link-text" translate>Contacts</span></a></span
        >
      </div>
      <div matRipple class="menu-row">
        <span
          ><a routerLink="/rejected-rooms"><span class="mdi mdi-24px mdi-close-octagon"></span><span class="link-text" translate>Rejected Rooms</span></a></span
        >
      </div>
      <mat-divider></mat-divider>
      <div matRipple class="menu-row">
        <span
          ><a routerLink="/licenses"><span class="mdi mdi-24px mdi-vote"></span><span class="link-text" translate>Nano Licenses</span></a></span
        >
      </div>
      <div matRipple class="menu-row">
        <span
          ><a routerLink="/license-buy"><span class="mdi mdi-24px mdi-cart primary"></span><span class="link-text primary" translate>Buy license</span></a></span
        >
      </div>
      <mat-divider></mat-divider>
      <div matRipple class="menu-row" *ngIf="isDistributor">
        <span
          ><a routerLink="/sale-code-manager"><span class="mdi mdi-24px mdi-receipt"></span><span class="link-text" translate>Sale Codes</span></a></span
        >
      </div>
      <div matRipple class="menu-row">
        <span
          ><a routerLink="/nano-metrics"><span class="mdi mdi-24px mdi-chart-line"></span><span class="link-text" translate>Nano Metrics</span></a></span
        >
      </div>
      <div matRipple class="menu-row">
        <span
          ><a (click)="openNanoManagerDialog()"><span class="mdi mdi-24px mdi-server"></span><span class="link-text" translate>Nano Manager</span></a></span
        >
      </div>
      <div matRipple class="menu-row">
        <span
          ><a target="_blank" href="https://www.clarabot.com/faq#q-19"
            ><span class="mdi mdi-24px mdi-book-open-page-variant primary"></span><span class="link-text primary" translate>About Nano</span></a
          ></span
        >
      </div>
      <ng-container *ngIf="offersAvailable">
        <mat-divider></mat-divider>
        <div matRipple class="menu-row">
          <span
            ><a routerLink="/offers"><span class="mdi mdi-24px mdi-sale"></span><span class="link-text" translate>Timed Offers</span></a></span
          >
        </div>
      </ng-container>
      <mat-divider></mat-divider>
      <div matRipple class="menu-row">
        <span
          ><a routerLink="/settings"><span class="mdi mdi-24px mdi-cog"></span><span class="link-text" translate>Settings</span></a></span
        >
      </div>
      <div matRipple class="menu-row">
        <a target="_blank" [href]="webDocLink | translate"> <span class="mdi mdi-24px mdi-book-open-page-variant"></span><span class="link-text" translate>Online Documentation</span> </a>
      </div>
      <div matRipple class="menu-row">
        <span
          ><a routerLink="/logout"><span class="mdi mdi-24px mdi-logout-variant"></span><span class="link-text" translate>Logout</span></a></span
        >
      </div>
    </div>

    <div *ngIf="isAnonym">
      <div matRipple class="menu-row">
        <span
          ><a routerLink="/settings"><span class="mdi mdi-24px mdi-cog"></span><span class="link-text" translate>Settings</span></a></span
        >
      </div>
      <div matRipple class="menu-row">
        <span
          ><a routerLink="/license-view"><span class="mdi mdi-24px mdi-cart primary"></span><span class="link-text primary" translate>View licenses</span></a></span
        >
      </div>
    </div>
  </div>
</div>
