<div id="code-block-cont">
  <div class="lang">
    <span>{{ language }}</span>
    <div>
      <mat-icon (click)="showLineNumber = !showLineNumber">format_list_numbered</mat-icon>
      <mat-icon (click)="copy()">content_copy</mat-icon>
    </div>
  </div>
  <div class="codeblock">
    <div *ngFor="let line of tokens; let i = index" class="line">
      <span class="code-line-number" *ngIf="showLineNumber">{{ i + 1 }}</span>
      <div class="code-line-cont">
        <span *ngFor="let t of line" class="slate-{{ t.kind }}">{{ t.text }}</span>
      </div>
    </div>
  </div>
</div>
