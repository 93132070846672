<tr class="desktop-view">
  <td class="file-image" *ngIf="!file.thumbnail"><span class="mdi mdi-{{ file.icon }} file-{{ file.icon }}"></span></td>
  <td class="file-image" *ngIf="file.thumbnail">
    <app-blob-viewer [blob]="file.thumbnail" type="image"></app-blob-viewer>
  </td>
  <td class="file-name mat-small">
    <span class="notranslate">{{ file.name }}</span>
  </td>
  <td class="file-ext mat-small" class="notranslate">{{ file.ext }}</td>
  <td class="file-size mat-small">{{ file.size | formatBytes }}</td>
  <td class="file-date mat-small">{{ file.createdAt | date: 'short' }}</td>
</tr>
<tr class="mobile-view">
  <td class="file-image" *ngIf="!file.thumbnail"><span class="mdi mdi-{{ file.icon }} file-{{ file.icon }}"></span></td>
  <td class="file-image" *ngIf="file.thumbnail">
    <app-blob-viewer [blob]="file.thumbnail" type="image"></app-blob-viewer>
  </td>
  <td class="file-name" [attr.colspan]="file.ext !== 'dir' ? '2' : '1'">
    <div class="file-name-content">
      <div>
        <span class="notranslate">{{ file.name }}</span>
      </div>
      <div *ngIf="file.ext !== 'dir'" class="file-size-date">
        <span class="mat-small">{{ file.createdAt | date: 'short' }} - {{ file.size | formatBytes }}</span>
      </div>
    </div>
  </td>
</tr>
