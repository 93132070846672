import { RoomRecord } from '../../../../../../server-services/query-records/room-records';
import { getAllRoomQuery, getRoomQuery } from '../../../../../../server-services/querys';
import { ResponseMergerHandler } from '../../../cache-logic-interface';

export let customRoomWithDataQueryMerger: ResponseMergerHandler = function (
  cache,
  request,
  response
) {
  cache.setCacheDataByRequest({ query: getRoomQuery, variables: request.variables }, response);
  /*if (response["pinnedKey"] || response["public"]) {
          // only the joined rooms
          cache.get(getAllRoomQuery).push(response);
        }*/
  let rooms: RoomRecord[] = cache.getCacheDataByRequest({ query: getAllRoomQuery, variables: {} });
  let roomIndex = rooms.findIndex((el) => el.id == response.id);
  if (roomIndex > -1) {
    // reappend, it can be an update for an unaviable room
    rooms[roomIndex] = response;
  } else {
    rooms.push(response);
  }
};
