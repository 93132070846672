import { Component, HostListener, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { DriveFile } from 'src/app/components/resource-page/drive-window/drive-layout/drive-file';
import { NanoFeature, isNanoFeatureSupported } from '../../drive-version';
import { NanoService, PeekFileParam, RequestSession } from '../../nano/nano.service';
import { RoomService } from '../../server-services/room.service';
import { BlobService } from '../../services/blob.service';
import { DownloadManagerService } from '../../services/download-manager-service';
import { MediaViewerService } from '../media-viewer.service';

export let getImageInDir = new Subject<{ dir: number; curFile: DriveFile }>();

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnDestroy {
  private sub: Subscription;
  public file: DriveFile;
  public path: string;
  public roomId: string;
  public currentChunk: number = 0;
  public maxChunk: number;
  public loaded: boolean = false;
  public data: Blob;
  public objectURL: string;
  public safeUrl: SafeUrl;
  public opened: boolean = false;
  public error: boolean = false;
  private session: RequestSession;
  public thumbnailSafeUrl: SafeUrl;
  private thumbnailBlobUrl;

  private swipeStarted = true;
  private swipeStartCoords = { x: 0, y: 0 };
  private swipeEndListenerDestructor = null;

  constructor(
    private mediaViewerService: MediaViewerService,
    private sanitizer: DomSanitizer,
    private nanoService: NanoService,
    private blobService: BlobService,
    private downloadManagerService: DownloadManagerService,
    private roomService: RoomService
  ) {
    this.sub = this.mediaViewerService.subscribeImageShow({
      next: (res) => {
        // init call
        this.reset(); // close the last session
        this.opened = true;

        // onPopState handling
        // removes last popstate eventlistener
        window.removeEventListener('popstate', this.popStateCallback);
        // history push
        history.pushState(null, null, window.location.href);
        // set new popstate eventlistener
        window.addEventListener('popstate', this.popStateCallback);

        this.file = res.file;
        this.path = res.path;
        this.roomId = res.roomId;

        this.thumbnailBlobUrl = this.blobService.createObjectURL(
          this.blobService.new([res.file.thumbnail]) /*res.file.mime*/
        );
        this.thumbnailSafeUrl = this.sanitizer.bypassSecurityTrustUrl(this.thumbnailBlobUrl);

        this.roomService.getNanoSession(res.roomId).then((session) => {
          if (!session) {
            this.error = true;
            return;
          }
          if (isNanoFeatureSupported(session.version, NanoFeature.TRUST)) {
            this.loadPreview(res.roomId, res.path, session.version);
          } else {
            this.loadFullImage(res.roomId, res.path);
          }
        });
      },
      error: (err) => {
        this.error = true;
        console.error('err', err);
      },
    });
  }

  protected showPreviousImage(): void {
    getImageInDir.next({ dir: -1, curFile: this.file });
  }

  protected showNextImage(): void {
    getImageInDir.next({ dir: 1, curFile: this.file });
  }

  private peekOption: PeekFileParam;
  private loadPreview(roomId, path, nanoVersion) {
    this.peekOption = {
      resourceId: roomId,
      path,
      responseCallback: (res) => {
        if (res.preview) {
          this.data = res.preview;
          if (this.objectURL) {
            this.blobService.revokeObjectURL(this.objectURL);
          }
          this.objectURL = this.blobService.createObjectURL(
            this.blobService.new([this.data]) /*res.file.mime*/
          );
          this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.objectURL);
          this.loaded = true;
          this.session = null;
        }
      },
      errorCallback: (err) => {
        this.error = true;
        console.error('err', err);
      },
      nanoVersion,
    };

    this.nanoService.peekFile(this.peekOption);
  }

  private loadFullImage(roomId, path) {
    this.nanoService.getFile(
      roomId,
      path,
      null, // force to use the BlobSaver
      (response) => {
        this.data = response;
        if (this.objectURL) {
          this.blobService.revokeObjectURL(this.objectURL);
        }
        this.objectURL = this.blobService.createObjectURL(
          this.blobService.new([this.data]) /*res.file.mime*/
        );
        this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.objectURL);
        this.loaded = true;
        this.session = null;
      },
      (err) => {
        this.error = true;
        console.error('err', err);
      },
      (current, max, session) => {
        this.loaded = false;
        this.currentChunk = current;
        this.maxChunk = max;
        this.session = session;

        try {
          // need some chunk for the half-done preview
          if (current > 2 && session && session.payloadParts) {
            let chunks = [];
            for (let key in session.payloadParts['payloads']) {
              chunks.push(session.payloadParts['payloads'][key].chunk.fc);
            }
            if (this.objectURL) {
              this.blobService.revokeObjectURL(this.objectURL);
            }
            this.objectURL = this.blobService.createObjectURL(
              this.blobService.new(chunks) /*res.file.mime*/
            );
            this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.objectURL);
          }
        } catch (e) {
          console.warn('err', e);
        }
      }
    );
  }

  // callback fired on popstate event - handles 'back' event
  private popStateCallback = () => {
    if (this.opened) {
      this.close(false); // false param indicates that it should not use history.back() in close()
    }
  };

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.swipeEndListenerDestructor) {
      this.swipeEndListenerDestructor();
      this.swipeEndListenerDestructor = null;
    }

    window.removeEventListener('popstate', this.popStateCallback);

    if (this.objectURL) {
      this.blobService.revokeObjectURL(this.objectURL);
      this.blobService.revokeObjectURL(this.thumbnailBlobUrl);
    }
  }

  private reset() {
    if (!this.loaded && this.peekOption) {
      this.nanoService.unsubscribeFromPeek(this.peekOption);
    }
    this.data = null;
    this.loaded = false;
    this.error = false;
    this.safeUrl = null;
    this.currentChunk = 0;
    this.maxChunk = 1;
    if (this.session) {
      this.session.pause();
      this.session = null;
    }
    if (this.objectURL) {
      this.blobService.revokeObjectURL(this.objectURL);
      this.blobService.revokeObjectURL(this.thumbnailBlobUrl);
      this.objectURL = null;
    }
    this.peekOption = null;
  }

  /**
   * closes image-viewer modal
   * @param goBack steps back with histor when given. Should call with 'false' when 'back' button is pressed (location.popState event)
   */
  public close(goBack: boolean = false) {
    if (this.swipeEndListenerDestructor) {
      this.swipeEndListenerDestructor();
      this.swipeEndListenerDestructor = null;
    }

    if (goBack) {
      history.back();
    }
    this.opened = false;
    this.reset();
  }

  public clickOutside(event) {
    // close when clicked on the background
    if (event.target && event.target.id == 'media-cont') {
      this.close();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.opened) {
      switch (event.code) {
        case 'Escape':
          this.close();
          break;
        case 'ArrowLeft':
          getImageInDir.next({ dir: -1, curFile: this.file });
          break;
        case 'ArrowRight':
          getImageInDir.next({ dir: 1, curFile: this.file });
          break;
      }
    }
  }

  public download() {
    this.downloadManagerService.download(this.roomId, this.file);
  }

  public startSwiping = (event) => {
    event.preventDefault();
    if (this.swipeEndListenerDestructor) {
      this.swipeEndListenerDestructor();
      this.swipeEndListenerDestructor = null;
    }
    if (!this.swipeStarted) {
      this.swipeStarted = true;
      this.swipeStartCoords = { x: event.pageX, y: event.pageY };
    }
  };

  pointerUp(e: MouseEvent) {
    if (this.opened && this.swipeStarted) {
      if (this.swipeStartCoords.y + 150 < e.pageY) {
        this.close();
      }
      this.swipeStarted = false;
    }
  }

  @HostListener('document:touchend', ['$event']) touchEnd(e: TouchEvent) {
    if (this.opened && this.swipeStarted) {
      if (this.swipeStartCoords.y + 150 < e?.changedTouches[0]?.pageY) {
        this.close();
      }
      this.swipeStarted = false;
    }
  }
}
