import { ID } from '../../../../../../../../server-services/query-records/common-records';
import {
  ResourceType,
  SidebarResource,
  UNGROUPPED_GROUP_ID,
} from '../../../../../../../../server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionRoomEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getSidebarGrantedRoomsQuery,
  getSidebarRejectedRoomsQuery,
  getSidebarResourceQuery,
  getSidebarUngrouppedQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onCreateRoomEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionRoomEventRecord
) => {
  let sidebarRes: SidebarResource = cache.getCacheDataByRequest({
    query: getSidebarResourceQuery,
    variables: {},
  });
  if (!sidebarRes) {
    // put the room in the system
    sidebarRes = {
      id: event.id,
      ordering: event.ordering || -1,
      type: ResourceType.ROOM,
      groupId: UNGROUPPED_GROUP_ID,
    };
    cache.setCacheDataByRequest(
      { query: getSidebarResourceQuery, variables: sidebarRes },
      sidebarRes
    );
    cache
      .getCacheDataByRequest({ query: getSidebarUngrouppedQuery, variables: {} })
      .push(sidebarRes);

    // Moreover if we just created the RoomRecord, it should be fetched via request,
    // we dont have any information about that, only the id
  } else {
    console.warn('room is already in the cache', event);
  }

  // remove the room from the granted/rejected if it is in there
  let granted: ID[] = cache.getCacheDataByRequest({
    query: getSidebarGrantedRoomsQuery,
    variables: {},
  });
  let roomIndex = granted.findIndex((roomId) => roomId == event.id);
  if (roomIndex > -1) {
    granted.splice(roomIndex, 1);
  }
  let rejected: ID[] = cache.getCacheDataByRequest({
    query: getSidebarRejectedRoomsQuery,
    variables: {},
  });
  roomIndex = rejected.findIndex((roomId) => {
    return roomId == event.id;
  });
  if (roomIndex > -1) {
    rejected.splice(roomIndex, 1);
  }
};
