<div id="tabs">
  <app-page-tab
    [data]="info"
    *ngFor="let info of pages; let i = index"
    (tabClose)="closeTab(i)"
    (click)="focusTab(i)"
    (pageInfoChange)="changedPageInfo(info)"
    [ngClass]="{ 'active-page-tab': selectedTabIndex === i && pages.length > 1, 'inactive-page-tab': selectedTabIndex !== i }"
    [active]="selectedTabIndex === i"
  ></app-page-tab>
  <div id="add-button" *ngIf="false">
    <button mat-icon-button (click)="openNewEmptyTab()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
