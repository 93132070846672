import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateRoomTypingEventSubscriptionHandler } from './ws-sub-room-typing-create-event';
import { onDeleteRoomTypingEventSubscriptionHandler } from './ws-sub-room-typing-delete-event';
import { onModifyRoomTypingEventSubscriptionHandler } from './ws-sub-room-typing-modify-event';

export let workspaceSubscriptionRoomTypingEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateRoomTypingEventSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyRoomTypingEventSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteRoomTypingEventSubscriptionHandler,
};
