import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AccountService } from 'src/app/shared/server-services/account.service';
import { WorkspaceSubscriptionRoomAccountPermissionEventRecord } from 'src/app/shared/server-services/query-records/workspace-records';
import { RoomService } from 'src/app/shared/server-services/room.service';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from 'src/app/shared/server-services/subscription-event';
import { SubscriptionService } from 'src/app/shared/server-services/subscription.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import {
  PageInfo,
  PageTabService,
  PageTypes,
  SubPageTypes,
  TabComponent,
  activePageInfoChanged,
} from 'src/app/shared/services/page-tab.service';
import { RouterHandler, RouterResponse } from 'src/app/shared/services/router-handler.service';
import { TitleService } from 'src/app/shared/services/title.service';

@Component({
  selector: 'app-page-tab-collection',
  templateUrl: './page-tab-collection.component.html',
  styleUrls: ['./page-tab-collection.component.scss'],
})
export class PageTabCollectionComponent implements OnDestroy, TabComponent {
  public pages: PageInfo[] = [];
  public selectedTabIndex: number;
  private lastLoadedPage: string;

  constructor(
    private routerHandler: RouterHandler,
    private location: Location,
    private pageTabService: PageTabService,
    private titleService: TitleService,
    private roomService: RoomService,
    private accountService: AccountService,
    private subscriptionService: SubscriptionService,
    private dialogService: DialogService
  ) {
    this.loadPageFromRoute(this.routerHandler.getRoute());

    this.routerHandler.subscribeAll(this.handlerRouterChange);

    this.pageTabService.registerActiveObserverComponent(this);

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.onRoomAccountPermissionDelete
    );
  }

  private onRoomAccountPermissionDelete = (
    event: WorkspaceSubscriptionRoomAccountPermissionEventRecord
  ): void => {
    const kickedOutTabIndex = this.pages.findIndex((p) => p.id === event.id);
    if (kickedOutTabIndex > -1) {
      const dialogTitle = marker('Permission change');
      const dialogText = marker(
        `Some of your permissions have been removed. Related tabs will be closed`
      );
      this.dialogService.openAlertDialog(dialogTitle, dialogText).subscribe((next) => {
        this.closeTab(kickedOutTabIndex);
      });
    }
  };

  private handlerRouterChange = (route: RouterResponse): void => {
    this.loadPageFromRoute(route, this.selectedTabIndex);
  };

  public getActivePageInfo(): PageInfo {
    if (this.pages && this.pages?.[this.selectedTabIndex]) {
      return this.pages[this.selectedTabIndex];
    }
    return null;
  }

  private loadPageFromRoute(route: RouterResponse, index?: number) {
    let newUrlWithFragment = route.url + '#' + this.routerHandler.fragmentToRaw(route.fragment);
    if (this.lastLoadedPage == newUrlWithFragment) {
      return;
    }

    this.lastLoadedPage = newUrlWithFragment;

    let detectedPage: PageInfo;
    // detect page
    if (this.routerHandler.isUrlOnPage(route.url, 'room/?/drive')) {
      detectedPage = {
        page: PageTypes.ROOM,
        subpage: SubPageTypes.DRIVE,
        id: route.params.id,
        fragment: route.fragment,
      };
    } else if (this.routerHandler.isUrlOnPage(route.url, 'room/?/chat/?')) {
      detectedPage = {
        page: route.params.type == 'private' ? PageTypes.PRIVATE_CHAT : PageTypes.ROOM,
        subpage: SubPageTypes.CHAT,
        id: route.params.id,
        fragment: route.fragment,
      };
    } else if (this.routerHandler.isUrlOnPage(route.url, 'live')) {
      detectedPage = {
        page: PageTypes.LIVE,
        fragment: route.fragment,
        subpage: null,
        id: null,
      };
    }

    if (detectedPage) {
      this.setPageTitleName(detectedPage);

      if (index !== undefined) {
        this.pages[index] = detectedPage;
      } else {
        this.pages.push(detectedPage);
        this.selectedTabIndex = this.pages.length - 1;
      }

      activePageInfoChanged.next(detectedPage);
    }
  }

  ngOnDestroy(): void {
    this.routerHandler.unsubscribeAll(this.handlerRouterChange);
    this.pageTabService.removeRegisteredActiveObserverComponent();
  }

  public focusTab(index: number) {
    if (index == this.selectedTabIndex) return;
    this.selectedTabIndex = index;
    this.changedPageInfo(this.pages[index]);
    activePageInfoChanged.next(this.pages[index]);
  }

  openInCurrentTab(pageInfo: PageInfo) {
    this.pages[this.selectedTabIndex] = pageInfo;
    this.setPageTitleName(pageInfo);
    activePageInfoChanged.next(pageInfo);
  }

  openInNewTab(pageInfo: PageInfo) {
    this.pages.push(pageInfo);
    this.setPageTitleName(pageInfo);
    this.selectedTabIndex = this.pages.length - 1;
    activePageInfoChanged.next(this.pages[this.selectedTabIndex]);
  }

  public closeTab(index: number) {
    this.pages.splice(index, 1);
    if (this.pages.length == 0) {
      this.routerHandler.navigate(['']);
      this.titleService.setCurrentTabTitle('Welcome');
      activePageInfoChanged.next(null);
    } else {
      this.selectedTabIndex = index - 1 > 0 ? index - 1 : 0;
      this.setPageTitleName(this.pages[this.selectedTabIndex]);
      activePageInfoChanged.next(this.pages[this.selectedTabIndex]);
    }
  }

  public openNewEmptyTab() {
    this.openInNewTab({
      page: PageTypes.EMPTY,
      fragment: {},
      id: null,
      subpage: null,
    });
  }

  public changedPageInfo(pageInfo: PageInfo) {
    this.lastLoadedPage = this.pageTabService.makeUrlFromPageInfo(pageInfo);
    pageInfo.subpage;

    this.setPageTitleName(pageInfo);

    this.location.go(this.lastLoadedPage);
  }

  private setPageTitleName(pageInfo: PageInfo): void {
    if (pageInfo) {
      // room
      if (pageInfo.page === PageTypes.ROOM) {
        this.roomService.getRoom(pageInfo.id).then((res) => {
          if (res?.data?.name) {
            this.titleService.setCurrentTabTitle(res.data.name);
          }
        });
      }
      // dialog
      if (pageInfo.page === PageTypes.PRIVATE_CHAT) {
        this.accountService.getAccount(pageInfo.id).then((res) => {
          if (res.avatarName) {
            this.titleService.setCurrentTabTitle(res.avatarName);
          }
        });
      }
    }
  }
}
