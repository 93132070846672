<div id="bookmarks-resizable-cont" [ngStyle]="{ width: containerWidth + 'px' }" *ngIf="showBookmarks">
  <div id="dragger" (pointerdown)="draggerPointerDown()"></div>
  <div id="bookmarks-cont">
    <div id="empty-message" *ngIf="bookmarks.length === 0">
      <div translate>Add folders to your bookmarks in any drive.</div>
      <div><mat-icon>bookmark</mat-icon></div>
    </div>
    <div id="bookmark-list" cdkDropList cdkDropListAutoScrollStep="15" [cdkDropListData]="bookmarks" (cdkDropListDropped)="roomDropped($event)">
      <div id="bookmark-title">
        <mat-icon>bookmark</mat-icon>
        <h2 translate>Bookmarks</h2>
        <mat-icon (click)="editMode = true" *ngIf="!editMode">edit</mat-icon>
        <mat-icon (click)="editMode = false" *ngIf="editMode" color="primary">done</mat-icon>
      </div>
      <div class="bookmark-element" cdkDragHandle cdkDrag *ngFor="let b of bookmarks" (click)="openBookmark(b)" [matTooltip]="'('+roomData?.[b.roomId]?.data?.name+')/'+b.path">
        <div class="icon"><mat-icon>folder</mat-icon> <app-avatar [blob]="roomData?.[b.roomId]?.data?.avatar"></app-avatar></div>
        <div class="path-group">
          <div class="path">{{ b.path }}</div>
          <div class="room-name">{{roomData?.[b.roomId]?.data?.name}}</div>
        </div>
        <mat-icon class="delete-icon" *ngIf="editMode" (click)="removeBookmark(b)">delete</mat-icon>
        <div *cdkDragPreview>
          <div class="bookmark-drag-preview">
            <mat-icon class="folder-icon">folder</mat-icon>
            <p>
              <span>{{ b.path }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
