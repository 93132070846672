import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

@Component({
  selector: 'app-general-confirm-dialog',
  templateUrl: './general-confirm-dialog.component.html',
  styleUrls: ['./general-confirm-dialog.component.scss'],
})
export class GeneralConfirmDialogComponent extends NanoDialogBaseComponent<GeneralConfirmDialogComponent> {
  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public generalConfirmData: GeneralConfirmData,
    public translate: TranslateService
  ) {
    super(injector);
  }

  closeWithOk() {
    this.dialogRef.close(true);
  }
}

interface GeneralConfirmData {
  title: string;
  text: string;
  okButtonText?: string;
  cancelButtonText?: string;
}
