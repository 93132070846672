<div>
  <div id="side-bar-main">
    <div
      id="sidebar-container"
      class="sidebar-results"
      cdkDropList
      cdkDropListGroup
      cdkDropListAutoScrollStep="15"
      cdkScrollable
      [cdkDropListData]="showGroups"
      (cdkDropListDropped)="groupDropped($event)"
    >
      <div class="loading" *ngIf="!workspaceLoaded">
        <mat-spinner diameter="40"></mat-spinner>
      </div>
      <div id="news-container" *ngIf="workspaceLoaded && grantedRooms.length > 0">
        <app-sidebar-granted-room [roomId]="resource" *ngFor="let resource of grantedRooms"></app-sidebar-granted-room>
      </div>
      <div *ngIf="workspaceLoaded && activePanel === 'inorder'" [ngClass]="{ hide: !workspaceLoaded }">
        <!-- <div class="start-place-filler" [style.height]="startPlaceFillerHeight + 'px'"></div> -->
        <mat-accordion #groupAccordion multi togglePosition="before">
          <app-sidebar-group *ngFor="let g of showGroups" [sidebarGroupData]="g"></app-sidebar-group>
          <app-sidebar-ungroupped [ungroupedResources]="ungroupedResources"></app-sidebar-ungroupped>
        </mat-accordion>
      </div>
    </div>
    <div id="res-tab-menu">
      <div id="add-resource-button">
        <ng-container *ngIf="isGroupReordering || isRoomReordering">
          <button color="primary" mat-mini-fab (click)="disableReorder()">
            <mat-icon>check</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!isGroupReordering && !isRoomReordering">
          <button color="primary" mat-mini-fab [matMenuTriggerFor]="menu">
            <mat-icon>add</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="after" yPosition="above" class="new-resource-menu">
            <!-- <button mat-menu-item routerLink="/contact-list">
              <mat-icon>person_add</mat-icon>
              <span translate>Add contact</span>
            </button>  -->
            <button mat-menu-item (click)="openNewGroupDialog()">
              <div class="menu-item-line">
                <mat-icon>category</mat-icon>
                <div>
                  <span translate>Create category</span>
                  <div class="sub-text" translate>Organize your workspace by creating custom categories.</div>
                </div>
              </div>
            </button>
            <button mat-menu-item (click)="openNewRoomDialog()">
              <div class="menu-item-line">
                <mat-icon>groups</mat-icon>
                <div>
                  <span translate>Create room</span>
                  <div class="sub-text" translate>Chat with your contacts or attach your Nano Drive.</div>
                </div>
              </div>
            </button>
          </mat-menu>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div #roomContextMenuTrigger="matMenuTrigger" [ngStyle]="{ position: 'fixed', top: contextMenuPosition.y, left: contextMenuPosition.x }" [matMenuTriggerFor]="roomContextMenu"></div>
<mat-menu #roomContextMenu="matMenu" [overlapTrigger]="true" [backdropClass]="'click-through'">
  <ng-template matMenuContent>
    <ng-container *ngIf="!roomContextMenuData.isError">
      <button mat-menu-item (click)="openRoomDetails()">
        <mat-icon>manage_accounts</mat-icon>
        <span translate>Open details</span>
      </button>
      <button mat-menu-item *ngIf="roomContextMenuData.isNanoConnected" (click)="openRoomDrive()">
        <mat-icon>computer</mat-icon>
        <span translate>Open drive</span>
      </button>
    </ng-container>

    <button mat-menu-item (click)="leaveRoom(); $event.stopPropagation()" *ngIf="!roomContextMenuData.isMyRoom">
      <mat-icon>logout</mat-icon>
      <span translate class="warn">Leave room</span>
    </button>
    <button mat-menu-item (click)="deleteRoom(); $event.stopPropagation()" *ngIf="roomContextMenuData.isMyRoom">
      <mat-icon>delete</mat-icon>
      <span translate class="warn">Delete room</span>
    </button>
  </ng-template>
</mat-menu>
