import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AppStorage } from 'src/app/shared/app-storage';
import { NanoValidators } from 'src/app/shared/nano-validators';
import { AuthService } from 'src/app/shared/server-services/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { styleChangedObserver } from 'src/app/shared/shared-observers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public isPending: boolean = false;
  public isRegisterSuccess: boolean = false;
  private passwordScore = 0;
  public isDarkTheme: boolean = true;

  registerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    passwordAgain: new FormControl('', [Validators.required]),
    term: new FormControl(false, [Validators.requiredTrue]),
  });

  public legalURL = environment.url_landing + '/legal';

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private titleService: TitleService
  ) {
    this.titleService.setCurrentTabTitle(marker('Register'));

    this.isDarkTheme = AppStorage.getItem('style_mode') == 'dark';
    styleChangedObserver.subscribe((style) => {
      this.isDarkTheme = style == 'dark';
    });
  }

  ngOnInit(): void {
    this.registerForm
      .get('passwordAgain')
      .addValidators([NanoValidators.controlEquals(this.registerForm.get('password'))]);
    this.registerForm
      .get('password')
      .addValidators([NanoValidators.passwordStrengthLow(() => this.passwordScore)]);

    this.registerForm.get('password').valueChanges.subscribe((e) => {
      if (this.registerForm.get('passwordAgain').touched) {
        this.registerForm.get('passwordAgain').updateValueAndValidity();
      }
    });
  }

  startRegister() {
    if (this.isPending) {
      console.warn('operation is already in progress');
      return;
    }

    this.isPending = true;
    this.authService
      .register(this.registerForm.get('email').value, this.registerForm.get('password').value)
      .then((res) => {
        this.isPending = false;
        this.isRegisterSuccess = true;
      })
      .catch((err) => {
        this.isPending = false;
        console.log('not ok', err);
        this.dialogService.openAlertDialog(
          'Registration rejected',
          'Something went wrong, please try again.'
        );
      });
  }

  public passwordStrength(score) {
    this.passwordScore = score;
    this.registerForm.get('password').updateValueAndValidity();
  }
}
