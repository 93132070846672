<div id="home-cont">
  <div id="welcome-main-container">
    <img src="{{ siteBase }}/assets/img/ui/logo-clara-bg-{{ colorTheme }}.svg" />
    <h2>Be safe</h2>
  </div>
  <div id="news">
    <div class="block" *ngIf="showRecoveryCode">
      <h2 mat-dialog-title translate>Download recovery key</h2>
      <h3 translate>You probably haven't downloaded your recovery key!</h3>
      <small translate>In case you did, do you still have it?</small>
      <p translate>The recovery key is the only way to recover your account in case of a lost password.</p>
      <p translate>Please download your recovery key. For the highest security possible store it in a non-digital manner.</p>
      <small translate>You can also download it from your account settings.</small>
      <div class="actions">
        <button mat-button (click)="remindLaterRecoveryKey()"><span translate>Remind me later</span></button>
        <button mat-button (click)="closeRecoveryKey()"><span translate>I already downloaded</span></button>
        <button mat-button color="primary" (click)="downloadRecoveryKey()"><span translate>Download key</span></button>
      </div>
    </div>
    <div class="block" *ngIf="showSafariSupportBlock">
      <h2 translate>Safari experimental support</h2>
      <p translate>Safari support is currently in experimental phase. If you experience any issues we recommend using Chrome or a chromium-based browser for the time being.</p>
      <div class="actions">
        <button mat-button (click)="closeShowSafariSupportBlock()"><span translate>Close</span></button>
      </div>
    </div>
    <div class="block" *ngIf="showChromeRecommendBlock">
      <h2 translate>Browser support notice</h2>
      <p translate>Because of the technologies used in our webapp, we highly recommend using Chrome or a chromium based web browser.</p>
      <div class="actions">
        <button mat-button (click)="closeShowChromeRecommendBlock()"><span translate>Close</span></button>
      </div>
    </div>
  </div>
</div>
