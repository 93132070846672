import { DriveFile } from 'src/app/components/resource-page/drive-window/drive-layout/drive-file';

export interface FolderDownloadData {
  resourceId: string;
  fileNames: string[];
  path: string;
}

export type FileRowSelectedEnum = 'selected' | 'indeterminate' | 'empty';

export interface FileRow {
  file?: DriveFile;
  name: string;
  path: string;
  size: number;
  parent?: FileRow;
  children?: FileRow[];
  selected: FileRowSelectedEnum;
  status: 'not-started' | 'loading' | 'done' | 'error-while-loading' | 'error';
  type: 'file' | 'folder';
  incrementSize?: Function;
  checkDone?: Function;
  changeFileSelection?: (
    selected: FileRowSelectedEnum | 'auto',
    fileRow: FileRow,
    onlyProcessChildren: boolean
  ) => void;
}

export enum QueuedCallbackStatus {
  queued,
  inprogress,
  done,
  error,
}

export interface QueuedCallback {
  callback: Function;
  status: QueuedCallbackStatus;
}
