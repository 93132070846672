import { DecryptionState, ID, OrderableItem } from './common-records';

export enum ResourceType {
  ROOM = 'room',
  DIALOGUE = 'dialogue',
}

export let UNGROUPPED_GROUP_ID = null;

// the ws raw/decrypted version resource type is not compatible with this
// so in the cache we need to convert it
export interface SidebarGroup extends OrderableItem, DecryptionState {
  closed: boolean;
  id: ID;
  resources: SidebarResource[];
  name: string;
}

export interface SidebarResource extends OrderableItem {
  id: ID;
  type: ResourceType;
  groupId: ID | null;
  hasNewMessages?: any; // used for sidebar news badge
}

export interface SidebarNews {
  news: number;
  resources: SidebarResource[];
}
