import { Component, OnInit } from '@angular/core';
import { SearchViewResultComponent } from '../search-view-result.component';

@Component({
  selector: 'app-search-view-result',
  templateUrl: './search-view-result-mobile.component.html',
  styleUrls: ['./search-view-result-mobile.component.scss'],
})
export class SearchViewResultMobileComponent extends SearchViewResultComponent implements OnInit {
  public fileNameFirstPart: String;
  public fileNameSecondPart: String;

  ngOnInit(): void {
    super.ngOnInit();
    let index = this.result.file.name.length - 3;
    this.fileNameFirstPart = this.result.file.name.substring(0, index);
    this.fileNameSecondPart = this.result.file.name.substring(index)
  }
}
