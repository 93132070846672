import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { AppStorage } from 'src/app/shared/app-storage';
import { NanoService } from 'src/app/shared/nano/nano.service';
import { PeekCache } from 'src/app/shared/nano/preview-cache';
import { ThumbnailCache } from 'src/app/shared/nano/thumbnail-cache';
import { sessionPushDataQuery } from 'src/app/shared/server-services/querys';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { DownloadManagerService } from 'src/app/shared/services/download-manager-service';
import { PushNotificationService } from 'src/app/shared/services/push-notification.service';
import { ServerRestApiService } from 'src/app/shared/services/server-rest-api.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { workerHandler } from 'src/app/shared/worker-handler/worker-handler';

export const enum AdvancedSettingsEnum {
  WORKERS = 'workers',
  FILE_STREAM_SIZE_LIMIT = 'file-stream-size-limit',
  HOLD_FILE_IN_CACHE = 'hold-file-in-cache',
  MAX_THUMBNAIL_CACHE = 'max-thumbnail-cache',
  EXP_IDENTITY_SIGNATURE = 'exp-identity-signature',
  MAX_PREVIEW_CACHE = 'max-preview-cache',
  PARALLEL_UPLOAD_WINDOW = 'parallel-upload-window',
  NETWORK_FILE_CHUNK_SIZE = 'network-file-chunk-size',
  EXP_BOOKMARKS = 'exp-bookmarks',
  LIVE_2P2 = 'live-2p2',
}

export let showWorkerGraph = new Subject<boolean>();
@Component({
  selector: 'app-advanced-settings',
  templateUrl: './advanced-settings.component.html',
  styleUrls: ['./advanced-settings.component.scss'],
})
export class AdvancedSettingsComponent {
  public maxWorker;
  public workerInput;
  public devOn = false;
  public fileStreamSizeLimit = NanoService.FILE_STREAM_MIN_SIZE / 1000000;
  public holdFileInCache = DownloadManagerService.STORE_TIME_DOWNLOADED_DATA_IN_CACHE / 1000;
  public maxCachedThumbnailElements = ThumbnailCache.MAX_CACHED_ELEMENTS;
  public maxCachedPreviewElements = PeekCache.MAX_CACHED_ELEMENTS;
  public parallelUploadWindow = NanoService.PARALLEL_UPLOAD_WINDOW;
  public networkFileChunkSize = NanoService.NETWORK_FILE_CHUNK_SIZE;
  public isIdentitySignatureOn =
    AppStorage.getItem(AdvancedSettingsEnum.EXP_IDENTITY_SIGNATURE) == '1';
  public isBookmarksOn = AppStorage.getItem(AdvancedSettingsEnum.EXP_BOOKMARKS) == '1';
  public isLive2P2On =
    // default true
    !AppStorage.getItem(AdvancedSettingsEnum.LIVE_2P2) ||
    AppStorage.getItem(AdvancedSettingsEnum.LIVE_2P2) == '1';

  constructor(
    private serverRestApiService: ServerRestApiService,
    private clipboardService: ClipboardService,
    private snackBarService: SnackBarService,
    private pushNotificationService: PushNotificationService
  ) {
    this.maxWorker = workerHandler.getMaxProcess();
  }

  changeMaxWorker(inputParam: string) {
    let max = parseInt(inputParam);
    workerHandler.setMaxProcess(max);
    AppStorage.setItem(AdvancedSettingsEnum.WORKERS, '' + max);
    this.maxWorker = workerHandler.getMaxProcess();
  }

  toggleWorkerGraph(show: boolean) {
    showWorkerGraph.next(show);
  }

  changeFileStreamSizeLimit(inputParam: string) {
    let limit = parseInt(inputParam);
    AppStorage.setItem(AdvancedSettingsEnum.FILE_STREAM_SIZE_LIMIT, '' + limit * 1000000);
    NanoService.FILE_STREAM_MIN_SIZE = limit * 1000000;
  }

  changeHoldFileInCache(inputParam: string) {
    let sec = parseInt(inputParam);
    AppStorage.setItem(AdvancedSettingsEnum.HOLD_FILE_IN_CACHE, '' + sec * 1000);
    DownloadManagerService.STORE_TIME_DOWNLOADED_DATA_IN_CACHE = sec * 1000;
  }

  changeMaxThumbnailCache(inputParam: string) {
    let amount = parseInt(inputParam);
    AppStorage.setItem(AdvancedSettingsEnum.MAX_THUMBNAIL_CACHE, '' + amount);
    ThumbnailCache.MAX_CACHED_ELEMENTS = amount;
  }

  changeMaxPreviewCache(inputParam: string) {
    let amount = parseInt(inputParam);
    AppStorage.setItem(AdvancedSettingsEnum.MAX_PREVIEW_CACHE, '' + amount);
    PeekCache.MAX_CACHED_ELEMENTS = amount;
  }

  changeParallelUploadWindow(value) {
    AppStorage.setItem(AdvancedSettingsEnum.PARALLEL_UPLOAD_WINDOW, '' + value);
    NanoService.PARALLEL_UPLOAD_WINDOW = parseInt(value);
  }

  changeNetworkFileChunkSize(value) {
    AppStorage.setItem(AdvancedSettingsEnum.NETWORK_FILE_CHUNK_SIZE, '' + value);
    NanoService.NETWORK_FILE_CHUNK_SIZE = parseInt(value);
  }

  changeIdentitySignature(value: boolean) {
    AppStorage.setItem(AdvancedSettingsEnum.EXP_IDENTITY_SIGNATURE, '' + (value ? 1 : 0));
  }

  changeBookmarks(value: boolean) {
    AppStorage.setItem(AdvancedSettingsEnum.EXP_BOOKMARKS, '' + (value ? 1 : 0));
  }

  changeLiveP2P(value: boolean) {
    AppStorage.setItem(AdvancedSettingsEnum.LIVE_2P2, '' + (value ? 1 : 0));
  }

  public pushToken?: string;
  public localPushToken?: string;
  getPushToken() {
    this.pushNotificationService
      .getToken()
      .then((token) => {
        if (token) {
          this.localPushToken = token;
        } else {
          this.localPushToken = 'No data';
        }
      })
      .catch((e) => {
        console.error('error', e);
        this.localPushToken = 'error; ' + e;
      });
    this.serverRestApiService
      .query({
        query: sessionPushDataQuery,
      })
      .then((token) => {
        if (token === null) {
          this.pushToken = 'No data';
        } else {
          this.pushToken = token;
        }
        console.log('token', token);
      })
      .catch((e) => {
        console.error('error', e);
        this.pushToken = 'error; ' + (e.message || e.error);
      });
  }

  copyPushToken() {
    if (this.pushToken) {
      this.clipboardService
        .copy('server: ' + this.pushToken + '; local: ' + this.localPushToken)
        .then(() => {
          this.snackBarService.showSnackbar('Data copied');
        })
        .catch((e) => {
          this.snackBarService.showSnackbar('Error: could not copy the token');
          console.error('error', e);
        });
    }
  }
}
