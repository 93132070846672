<mat-progress-bar mode="query" color="primary" class="query-loader" [ngClass]="{ active: isSearching }"></mat-progress-bar>

<div id="search-layout">
  <mat-expansion-panel class="search-select-panel">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span translate>Filter extensions...</span>
      </mat-panel-title>
      <mat-panel-description>
        <span class="selected-ext-container"
          ><span *ngFor="let ext of selectedExtensions">.{{ ext }}</span></span
        >
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="filter-by-ext-container" *ngIf="availableExtensionSet.size > 0">
      <mat-checkbox color="primary" *ngFor="let ext of availableExtensionSet" [checked]="isExtSelected(ext)" (change)="extSelected($event.checked, ext)">
        {{ ext }}
      </mat-checkbox>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="showFilterSearchPanel" (opened)="showFilterSearchPanel = true" class="search-select-panel">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span translate>Filter rooms...</span>
      </mat-panel-title>
      <mat-panel-description [ngSwitch]="searchMode">
        <span class="advanced-search-results-description" translate>Currently results from: </span>
        <span *ngSwitchCase="SearchMode.ROOM" translate>Own rooms</span>
        <span *ngSwitchCase="SearchMode.GLOBAL" translate>Everywhere</span>
        <span *ngSwitchCase="SearchMode.CUSTOM">
          <app-avatar *ngFor="let ownRoom of selectedRooms" class="search-room-avatar" [blob]="ownRoom['data']?.avatar"></app-avatar>
        </span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="switch-container">
      <button mat-raised-button (click)="selectAll()"><span translate>All</span></button>
      <button mat-raised-button (click)="selectOwnRooms()"><span translate>Own</span></button>
      <button mat-raised-button (click)="clearSelection()"><span translate>Clear</span></button>
    </div>

    <!-- group list -->
    <div class="inline-expansion-panel" *ngIf="allGroups?.length">
      <mat-expansion-panel class="search-select-panel" [expanded]="!groupsCollapsed" (opened)="groupsCollapsed = false" (closed)="groupsCollapsed = true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span translate>Filter by categories...</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul #searchBox class="group-list">
          <li matRipple [matRippleDisabled]="isSearching" *ngFor="let group of allGroups" [ngClass]="{ selected: isGroupSelected(group) }" class="room-card" (click)="groupChecked(group)">
            <span *ngIf="group?.resources?.length" class="room-info" [matTooltipShowDelay]="1000" matTooltipPosition="above" [matTooltip]="group?.name" [matTooltipClass]="['no-translate']">
              <span *ngIf="group?.name" class="room-name">{{ group?.name }}</span>
              <span *ngIf="!group?.name" class="room-name" [ngClass]="{ 'data-error': true }" translate>Decryption error</span>
            </span>
          </li>
        </ul>
      </mat-expansion-panel>
    </div>
    <!-- room list -->
    <ul #searchBox class="room-list" [style.height]="searchBoxHeight ? searchBoxHeight + 'px' : 'auto'">
      <li matRipple [matRippleDisabled]="isSearching" *ngFor="let room of allRooms" [ngClass]="{ selected: isRoomSelected(room) }" class="room-card" (click)="roomChecked(room)">
        <span class="room-info" [matTooltipShowDelay]="1000" matTooltipPosition="above" [matTooltip]="room['data']?.name" [matTooltipClass]="['no-translate']">
          <app-avatar class="room-avatar" [blob]="room['data']?.avatar"></app-avatar>
          <span class="room-name">{{ room['data']?.name }}</span>
          <span class="mdi mdi-crown info-flag room-icon" *ngIf="isMyRoom(room)"></span>
        </span>
      </li>
    </ul>
    <div id="dragger" (pointerdown)="draggerPointerDown($event)"><mat-icon>drag_handle</mat-icon></div>

    <button mat-raised-button color="primary" [disabled]="this.selectedRoomIds.length === 0" (click)="redoSearch()"><span translate>Search</span></button>
  </mat-expansion-panel>

  <h1 id="search-start-location" translate>Search results</h1>
  <div class="search-result-container">
    <app-search-view-result [result]="result" *ngFor="let result of searchResults"></app-search-view-result>
    <span *ngIf="searchResults.length === 0" translate>No results.</span>
  </div>
</div>
