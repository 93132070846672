import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateRoomPermissionEventSubscriptionHandler } from './ws-sub-r-perm-event-create-handler';
import { onDeleteRoomPermissionEventSubscriptionHandler } from './ws-sub-r-perm-event-delete-handler';
import { onModifyRoomPermissionEventSubscriptionHandler } from './ws-sub-r-perm-event-modify-handler';

export let workspaceSubscriptionRoomPermissionEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateRoomPermissionEventSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyRoomPermissionEventSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteRoomPermissionEventSubscriptionHandler,
};
