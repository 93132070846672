import {
  SidebarGroup,
  SidebarResource,
  UNGROUPPED_GROUP_ID,
} from '../../../../../../../../server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionDialogueEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getSidebarGroupQuery,
  getSidebarResourceQuery,
  getSidebarUngrouppedQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onDeleteDialogueEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionDialogueEventRecord
) => {
  let sidebarRes: SidebarResource = cache.getCacheDataByRequest({
    query: getSidebarResourceQuery,
    variables: event,
  });
  if (sidebarRes) {
    // remove from the container
    let container: SidebarResource[] | null = null;
    if (sidebarRes.groupId == UNGROUPPED_GROUP_ID) {
      container = cache.getCacheDataByRequest({ query: getSidebarUngrouppedQuery, variables: {} });
    } else {
      let group: SidebarGroup = cache.getCacheDataByRequest({
        query: getSidebarGroupQuery,
        variables: {
          id: sidebarRes.groupId,
        },
      });
      if (group) {
        container = group.resources;
      } else {
        console.warn('could not find the removable source in its group in the cache', event);
      }
    }

    if (container) {
      // it should be in the prev container
      let index = container.indexOf(sidebarRes);

      if (index > -1) {
        // remove the element from its old container
        container.splice(index, 1);
      } else {
        console.warn('could not find the removable element in its original container in the cache');
      }
    }

    cache.deleteCacheDataByRequest({ query: getSidebarResourceQuery, variables: event });
  } else {
    console.warn('could not find the removable resource in the cache');
  }

  delete cache.getCacheData('messages')[event.id];
  delete cache.getCacheData('seen')[event.id];
};
