<div>
  <div class="sub-page">
    <h1 translate>Nano Sale Codes</h1>
    <small translate>Check your sale codes here. You can also create or manage existing codes further down below.</small>

    <ng-template #loadingContent>
      <mat-icon><mat-spinner diameter="20"></mat-spinner></mat-icon>
    </ng-template>

    <ng-container *ngIf="saleCodes; else loadingContent">
      <ng-container *ngIf="saleCodes.length > 0">
        <table id="sale-code-table" #saleCodeTable mat-table [dataSource]="saleCodes" class="mat-elevation-z8">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef translate>Key</th>
            <td mat-cell *matCellDef="let saleCode" [ngClass]="{ invalid: !saleCode.descriptionDecoded.valid }">
              {{ saleCode.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef translate>Description</th>
            <td mat-cell *matCellDef="let saleCode" class="center" [ngClass]="{ invalid: !saleCode.descriptionDecoded.valid }">
              {{ saleCode.descriptionDecoded.text }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef translate>Edit / Delete</th>
            <td mat-cell *matCellDef="let saleCode" class="center action-buttons">
              <button mat-icon-button (click)="editSaleCode(saleCode)"><mat-icon>edit</mat-icon></button>
              <button mat-icon-button (click)="deleteSaleCode(saleCode)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="saleCodeColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: saleCodeColumns"></tr>
        </table>
      </ng-container>

      <button mat-raised-button color="primary" (click)="createSaleCode()"><span translate>Create new</span></button>
    </ng-container>
  </div>
</div>
