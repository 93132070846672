import { AbstractAsymmetricKeyGenerator } from '../interface';
import * as nacl from 'tweetnacl';

export class Ed25519KeyGenerator extends AbstractAsymmetricKeyGenerator {
  public static new_key(): nacl.SignKeyPair {
    let seed = crypto.getRandomValues(new Uint8Array(32));
    let kp = nacl.sign.keyPair.fromSeed(seed);
    kp.secretKey = seed;
    return kp;
  }

  public static describe(): string {
    return 'Ed25519-256';
  }

  public static regenerate_key_pair(secret_seed: Uint8Array): nacl.SignKeyPair {
    let kp = nacl.sign.keyPair.fromSeed(secret_seed);
    kp.secretKey = secret_seed;
    return kp;
  }
}

export class Curve25519KeyGenerator extends AbstractAsymmetricKeyGenerator {
  public static new_key(): nacl.SignKeyPair {
    return nacl.box.keyPair();
  }

  public static describe(): string {
    return 'Curve25519-256';
  }

  public static regenerate_key_pair(secret_key: Uint8Array): nacl.SignKeyPair {
    return nacl.box.keyPair.fromSecretKey(secret_key);
  }
}
