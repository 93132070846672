<h2 mat-dialog-title translate>Change email</h2>
<form id="email-form" [formGroup]="emailForm">
  <mat-dialog-content class="mat-typography">
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label translate>New email</mat-label>
        <input matInput name="newEmail" formControlName="email" type="email" />
        <mat-error *ngIf="emailForm.get('email').errors?.['required']"><span translate>Please enter your desired email address.</span></mat-error>
        <mat-error *ngIf="emailForm.get('email').errors?.['email']"><span translate>Please enter a valid email address.</span></mat-error>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label translate>New email again</mat-label>
        <input matInput name="newEmail2" formControlName="email2" type="email" />
        <mat-error *ngIf="emailForm.get('email2').errors?.['required']"><span translate>Please enter your desired email address.</span></mat-error>
        <mat-error *ngIf="emailForm.get('email2').errors?.['controlEquals']"><span translate>Emails must match.</span></mat-error>
        <mat-error *ngIf="emailForm.get('email2').errors?.['email']"><span translate>Please enter a valid email address.</span></mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-button color="primary" [disabled]="!emailForm.valid" (click)="changeEmail()"><span translate>Change</span></button>
  </mat-dialog-actions>
</form>
