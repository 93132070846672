<h1 mat-dialog-title translate>Moving files and folders</h1>
<div mat-dialog-content>
  <mat-list>
    <div class="mat-list-title"><span translate>Selected files: </span> {{ selectedFilesCount }}</div>
    <div class="mat-list-title"><span translate>Selected folders: </span> {{ selectedFoldersCount }}</div>
    <mat-divider></mat-divider>
    <mat-list-item matRipple [matRippleDisabled]="isRootFolder" [ngClass]="{ 'list-item-disabled': isRootFolder }" (click)="back()">
      <mat-icon matListItemIcon>arrow_upward</mat-icon>
      <div matListItemTitle>[ .. ]</div>
    </mat-list-item>
    <mat-list-item *ngFor="let folder of folders" matRipple (click)="openFolder(folder.name)">
      <mat-icon matListItemIcon>folder</mat-icon>
      <div matListItemTitle>{{ folder.name }}</div>
    </mat-list-item>
  </mat-list>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null"><span translate>Cancel</span></button>
  <button mat-button (click)="newFolderDialog()"><span translate>Create Directory</span></button>
  <button mat-button color="primary" [mat-dialog-close]="currentPath" cdkFocusInitial><span translate>Move here</span></button>
</div>
