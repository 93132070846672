import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateDialogueMessageEventSubscriptionHandler } from './ws-sub-dialogue-message-event-create-handler';
import { onDeleteDialogueMessageEventSubscriptionHandler } from './ws-sub-dialogue-message-event-delete-handler';
import { onModifyDialogueMessageEventSubscriptionHandler } from './ws-sub-dialogue-message-event-modify-handler';

export let workspaceSubscriptionDialogueMessageEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateDialogueMessageEventSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyDialogueMessageEventSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteDialogueMessageEventSubscriptionHandler,
};
