<span *ngIf="token" [class]="token.token">
  <ng-container *ngIf="token.token === 'link'">
    <a #link [href]="token.text" (click)="linkGuard($event)" [target]="isURLSameSite ? '_self' : '_blank'">{{ token.text }}</a>
  </ng-container>
  <br *ngIf="token.token === 'new-line'" />
  <ng-container *ngIf="isEmoji">
    <img src="assets/img/twemoji/72x72/{{ emojiSrc }}.png" [alt]="emojiAlt" />
  </ng-container>
  <ng-container *ngIf="isShowText">
    <span class="notranslate" *ngIf="token.token !== 'codeblock'">{{ token.text }}</span>
    <app-code-block-viewer *ngIf="token.token === 'codeblock'" [text]="token.text"></app-code-block-viewer>
  </ng-container>
  <ng-container *ngFor="let part of token.children">
    <app-markdown-viewer-fragment *ngIf="part.token !== 'token'" [token]="part"></app-markdown-viewer-fragment>
  </ng-container>
</span>
