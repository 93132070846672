export class RemainingTimeHumanizer {
  public static humanize(timeNeeded: number): string {
    function pad(n) {
      return n < 10 ? '0' + n : n;
    }

    var h = Math.floor(timeNeeded / 3600);
    var m = Math.floor(timeNeeded / 60) - h * 60;
    var s = Math.floor(timeNeeded - h * 3600 - m * 60);

    if (h > 0) {
      return pad(h) + ':' + pad(m) + ':' + pad(s);
    } else if (m > 0) {
      return m + ':' + pad(s);
    } else {
      return s + ' sec';
    }
  }
}
