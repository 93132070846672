import { ResponseManipulatorHandler } from '../../../cache-logic-interface';

export let licenseQueryManipulator: ResponseManipulatorHandler = (
  cache,
  cacheKey,
  request,
  serverResponse?
) => {
  let result = serverResponse ?? cache.getCacheData(cacheKey);
  return result;
};
