<h1 mat-dialog-title translate>Delete Account</h1>
<div class="dialog-content" mat-dialog-content>
  <div>
    <span translate>
      By deleting your account, you forfeit access to all of your purchased or activated and still valid Nano Licenses. All data exclusive to your account will be deleted, including but not limited to
      recurring purchases, saved billing information, contact list, rooms created by you.
    </span>
  </div>
  <div class="menacing-text">
    <span translate> After confirming the deletion the account can't be restored and the affected data will not be recoverable later. </span>
  </div>
</div>
<div class="confirmation-box">
  <mat-checkbox aria-required="true" color="primary" [checked]="confirmed" labelPosition="before" (change)="changeConfirmed($event.checked)"><span translate>I understand</span></mat-checkbox>
</div>
<div mat-dialog-actions align="end">
  <div>
    <button mat-stroked-button (click)="onCancel()"><span translate>Cancel</span></button>
    <button mat-flat-button color="warn" (click)="deleteAccount()" [disabled]="!confirmed"><span translate>Delete</span></button>
  </div>
</div>
