import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BlobService } from './blob.service';

@Injectable({
  providedIn: 'root',
})
export class FileDownloaderService {
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    private blobService: BlobService,
    @Inject(DOCUMENT) private document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public download(blob: Blob, fileName: string) {
    var a;
    a = this.renderer.createElement('a');
    let src = this.blobService.createObjectURL(blob);
    this.renderer.setProperty(a, 'href', src);
    this.renderer.setProperty(a, 'download', fileName);
    this.renderer.appendChild(this.document.body, a);
    this.renderer.setStyle(a, 'display', 'none');
    a.click();
    a.remove();

    setTimeout(() => {
      this.blobService.revokeObjectURL(src);
    }, 1000);
  }
}
