import { Component } from '@angular/core';
import {
  NanoActionType,
  NanoActionQueueService,
} from 'src/app/shared/services/nano-action-queue.service';

@Component({
  selector: 'app-action-queue',
  templateUrl: './action-queue.component.html',
  styleUrls: ['./action-queue.component.scss'],
})
export class ActionQueueComponent {
  actionList: NanoActionType[] = [];

  constructor(private nanoActionQueueService: NanoActionQueueService) {
    this.actionList = nanoActionQueueService.getActionList();
  }
}
