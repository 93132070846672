import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { MatNanoSnackbarComponent } from '../snackbars/mat-nano-snackbar/mat-nano-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

  public showSnackbar(message: string, action: string = null, primary: boolean = true) {
    if (action) {
      forkJoin([this.translate.get(message), this.translate.get(action)]).subscribe(
        ([translatedMessage, translatedAction]) => {
          this.snackBar.open(translatedMessage, translatedAction, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: [
              'mat-toolbar',
              'mat-primary',
              'nano-snackbar',
              primary ? 'primary-color-palette' : null,
            ],
          });
        }
      );
    } else {
      this.translate.get(message).subscribe((message) => {
        this.snackBar.openFromComponent(MatNanoSnackbarComponent, {
          data: message,
          duration: 3 * 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: [
            'mat-toolbar',
            'mat-primary',
            'nano-snackbar',
            primary ? 'primary-color-palette' : null,
          ],
        });
      });
    }
  }
}
