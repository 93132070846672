import { RoomDetail } from 'src/app/shared/server-services/query-records/room-records';
import { WorkspaceSubscriptionRoomMessageEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getRoomDetailQuery,
  roomMessagesQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onDeleteRoomMessageEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionRoomMessageEventRecord) => {
    let messages = cache.getCacheDataByRequest({
      query: roomMessagesQuery,
      variables: {
        roomId: event.id,
      },
    });

    if (!messages) {
      return;
    }

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id == event.messageId) {
        messages.splice(i, 1);
        break;
      }
    }

    if (cache.getCacheData('messages')[event.id]) {
      delete cache.getCacheData('messages')[event.id][event.messageId];

      let roomDetails: RoomDetail = cache.getCacheDataByRequest({
        query: getRoomDetailQuery,
        variables: {
          id: event.id,
        },
      });

      if (roomDetails) {
        let index = roomDetails.pinnedMessageIds.indexOf(event.messageId);
        if (index > -1) {
          roomDetails.pinnedMessageIds.splice(index, 1);
        }
      }
    }
  };
