<div class="title-bar" mat-dialog-title translate>
  <div class="file-image"><span class="mdi mdi-{{ data.file.icon }} file-{{ data.file.icon }}"></span></div>
  <!-- <div class="file-image" *ngIf="data?.file?.thumbnail">
    <app-blob-viewer [blob]="data?.file?.thumbnail" type="image"></app-blob-viewer>
  </div> -->
  <span class="title">{{ data?.file?.name }}</span>
</div>
<mat-dialog-content class="content-body mat-typography">
  <div *ngIf="data" class="properties-container">
    <div class="row">
      <div class="prop">Name:</div>
      <div class="value">{{ data.file.name }}</div>
    </div>
    <div class="row" *ngIf="!data.file.unresolved || data.file.symlink">
      <div class="prop">Extension:</div>
      <div class="value" *ngIf="!data.file.symlink">{{ data.file.ext }}</div>
      <div class="value" *ngIf="data.file.symlink"><i>symlink</i></div>
    </div>
    <div *ngIf="!data.file.unresolved && data.file.size >= 0" class="row">
      <div class="prop">Size:</div>
      <div class="value">{{ data.file.size | formatBytes }}</div>
    </div>
    <div class="row" *ngIf="!data.file.unresolved">
      <div class="prop">Last modified:</div>
      <div class="value">{{ data.file.createdAt | date: 'short' }}</div>
    </div>
    <!-- <div class="row">
      <div class="prop">Last modified:</div>
      <div class="value">{{ lastModified | date: 'short' }}</div>
    </div> -->
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container *ngIf="!data.file.unresolved">
    <ng-container *ngIf="!isDir">
      <button mat-raised-button color="primary" (click)="download()"><span translate>Download</span></button>
      <button mat-button color="primary" (click)="goToDrive()"><span translate>Open</span></button>
    </ng-container>
    <ng-container *ngIf="isDir">
      <button mat-raised-button color="primary" (click)="goToDrive()"><span translate>Open</span></button>
    </ng-container>
    <button mat-button color="primary" (click)="copyLink()"><span translate>Copy link</span></button>
  </ng-container>
  <button mat-button mat-dialog-close color="primary"><span translate>Close</span></button>
</mat-dialog-actions>
