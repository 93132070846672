import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Subscription, merge } from 'rxjs';
import {
  CollectionManagerType,
  DownloadManagerService,
  FileManagerType,
  collectionRemovedObserver,
  downloadFinishedObserver,
  downloadPausedObserver,
  downloadResumedObserver,
  downloadStartedObserver,
  fileRemovedObserver,
} from 'src/app/shared/services/download-manager-service';
import { sideMenuActiveObserver } from 'src/app/shared/shared-observers';

@Component({
  selector: 'app-download-manager',
  templateUrl: './download-manager.component.html',
  styleUrls: ['./download-manager.component.scss'],
})
export class DownloadManagerComponent implements OnInit, OnDestroy {
  fileList: FileManagerType[] = [];
  collectionList: CollectionManagerType[] = [];

  public canResumeDownload: boolean = true;

  private downloadObserversSub: Subscription;

  constructor(
    private downloadManagerService: DownloadManagerService,
    private location: Location,
    private bottomSheetRef: MatBottomSheetRef<DownloadManagerComponent>
  ) {
    this.fileList = this.downloadManagerService.getFileList();
    this.collectionList = this.downloadManagerService.getCollectionList();
  }
  ngOnDestroy(): void {
    this.downloadObserversSub.unsubscribe();
  }

  ngOnInit(): void {
    sideMenuActiveObserver.next(false);

    merge(collectionRemovedObserver, fileRemovedObserver).subscribe(() => {
      if (this.fileList.length === 0 && this.collectionList.length === 0)
        this.bottomSheetRef.dismiss();
    });

    this.canResumeDownload = this.downloadManagerService.canCreateDownloadTask();

    this.downloadObserversSub = merge(
      downloadStartedObserver,
      downloadFinishedObserver,
      downloadPausedObserver,
      downloadResumedObserver
    ).subscribe(() => {
      this.canResumeDownload = this.downloadManagerService.canCreateDownloadTask();
    });
  }

  public removeReady(): void {
    this.downloadManagerService.removeFinishedFromFileList();
  }
}
