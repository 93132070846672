import { Injectable } from '@angular/core';
import { getNanoVersionQuery } from '../server-services/querys';
import { ServerRestApiService } from './server-rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private currentVersion: VersionRecord;
  private versionRegex: RegExp = /^(\d+)\.(\d+)\.(\d+)/g;

  constructor(private serverRestApiService: ServerRestApiService) {
    this.serverRestApiService.query({ query: getNanoVersionQuery }).then((version) => {
      console.log('Current nano version', version);
      this.currentVersion = this.parseVersion(version);
    });
  }

  public parseVersion(versionString: string): VersionRecord {
    var relevantVersionString = versionString.match(this.versionRegex)[0];
    var versionParts = relevantVersionString.split('.');
    var major = parseInt(versionParts[0]);
    var minor = parseInt(versionParts[1]);
    var build = parseInt(versionParts[2]);

    return { versionString: versionString, major: major, minor: minor, build: build };
  }

  public isValidVersion(versionString: string): boolean {
    var version = this.parseVersion(versionString);

    if (version.major < this.currentVersion.major) return false;
    if (version.minor < this.currentVersion.minor && version.major <= this.currentVersion.major)
      return false;
    if (
      version.build < this.currentVersion.build &&
      version.minor <= this.currentVersion.minor &&
      version.major <= this.currentVersion.major
    )
      return false;

    return true;
  }
}

export interface VersionRecord {
  versionString: string;
  major: number;
  minor: number;
  build: number;
}
