import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Observable, Observer, Subject, firstValueFrom, from, merge, of, throwError } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  mergeMap,
  repeat,
  takeLast,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AdvancedSettingsEnum } from 'src/app/components/advanced-settings/advanced-settings.component';
import { AppStorage } from 'src/app/shared/app-storage';
import { DriveFile } from '../../components/resource-page/drive-window/drive-layout/drive-file';
import { ByteHumanizer } from '../humanizers/byte-humanizer';
import { RemainingTimeHumanizer } from '../humanizers/remaining-time-humanizer';
import {
  FileSystemWritableFileStreamCommonInterface,
  FileSystemWritableFileStreamMobile,
} from '../nano/nano-requests';
import { NanoService } from '../nano/nano.service';
import { ID } from '../server-services/query-records/common-records';
import { ServerErrorDetail } from '../server-services/server-errors';
import { BlobService } from './blob.service';
import { DialogService } from './dialog.service';
import { NativeAppService } from './native-app.service';

export enum FileManagerDirection {
  UP = 'up',
  DOWN = 'down',
}

export enum FileManagerStatus {
  PENDING,
  IN_PROGRESS,
  PAUSED,
  DONE,
  ERROR,
  VERIFYING,
}

export class FileManagerType {
  public static MAX_AVERAGE_TIMESTAMP_RANGE = 10;

  public direction: FileManagerDirection = FileManagerDirection.DOWN;
  public file: DriveFile;
  public resourceId: string;
  public path: string;
  public currPart: number = 0;
  public countPart: number = 0;
  public speed: string = '';
  public prevTimeNeededList: number[] = [];
  public prevAverageRemainingTime: number = 0;
  public timeNeeded: string = '';
  public started: Date = new Date();
  public progress: number = 0;
  public requestId: number = null;
  /**
   * Data could be stored in cache for a short time. It is still available for instant download
   */
  public objectURL?: string; // createObjectURL
  public rawObject?: any;
  public mobileLocalPath?: string;
  public streamWriter: any = null;
  public downloadObservable$?: Observable<FileManagerType> = new Subject<FileManagerType>();

  public fsDirectoryHandle: any = null;

  public errorDetail: ServerErrorDetail;

  private status: FileManagerStatus = FileManagerStatus.PENDING;

  public setStatus(newStatus: FileManagerStatus): void {
    this.status = newStatus;
  }

  public isPending(): boolean {
    return this.status === FileManagerStatus.PENDING;
  }

  public isInProgress(): boolean {
    return this.status === FileManagerStatus.IN_PROGRESS;
  }

  public isPaused(): boolean {
    return this.status === FileManagerStatus.PAUSED;
  }

  public isDone(): boolean {
    return this.status === FileManagerStatus.DONE;
  }

  public isError(): boolean {
    return this.status === FileManagerStatus.ERROR;
  }

  public isVerifying(): boolean {
    return this.status === FileManagerStatus.VERIFYING;
  }

  public updateProgress(): void {
    this.progress = Math.round((this.currPart / this.countPart) * 100);
    this.prevTimeNeededList.push(Date.now());

    if (this.prevTimeNeededList.length > FileManagerType.MAX_AVERAGE_TIMESTAMP_RANGE)
      this.prevTimeNeededList.shift(); //if we are above the chunkRange, then remove the oldest from the array to keep the array lean

    const speedInBytePerSec = this.speedInBytePerSec();
    const remainingTime = this.remainingTime(speedInBytePerSec);
    this.speed = ByteHumanizer.humanize(speedInBytePerSec) + '/sec';
    this.timeNeeded = RemainingTimeHumanizer.humanize(remainingTime);
  }

  private speedInBytePerSec(): number {
    let bytePerSec = 0;
    const chunkTimeAvgRange = this.prevTimeNeededList.length;
    //if there are two or more previous recorded times, then we can start calculating averages
    if (chunkTimeAvgRange > 1) {
      //avgMsPerChunk = ((latestTimestamp - timestamps[-chunkTimeAvgRange]) / chunkTimeAvgRange)
      let avgMsPerChunk =
        (this.prevTimeNeededList[this.prevTimeNeededList.length - 1] -
          this.prevTimeNeededList[this.prevTimeNeededList.length - chunkTimeAvgRange]) /
        chunkTimeAvgRange;
      let avgSecPerChunk = avgMsPerChunk / 1000;
      let chunkPerSec = 1 / avgSecPerChunk;
      bytePerSec = chunkPerSec * NanoService.NETWORK_FILE_CHUNK_SIZE;

      // console.table({
      //   'Average sec/chunk': avgSecPerChunk,
      //   'Chunk/sec': chunkPerSec,
      //   'Byte/sec': bytePerSec,
      //   Speed: this.humanizeSpeed(bytePerSec),
      // });
    }

    return bytePerSec;
  }

  private remainingTime(speedInBytePerSec: number): number {
    //To smoothen the countdown timer and reduce drastic jumps, we can use a technique called exponential moving average (EMA) to calculate the average remaining time over a period of time.

    if (speedInBytePerSec === 0) return 0;

    const estimatedRemainingTime = Math.round(
      ((this.countPart - this.currPart) * NanoService.NETWORK_FILE_CHUNK_SIZE) / speedInBytePerSec
    );

    const alpha = 0.4; //represents the smoothing factor between 0 and 1, where a smaller value will result in a smoother average but slower response to changes

    // Update average time using exponential moving average
    const averageTime = Math.round(
      alpha * estimatedRemainingTime + (1 - alpha) * this.prevAverageRemainingTime
    );

    // console.table({
    //   Raw: estimatedRemainingTime,
    //   Smooth: averageTime,
    // });

    this.prevAverageRemainingTime = averageTime;
    return averageTime;
  }
}

export class FileDownloadTask {
  public downloadCancel$: Subject<void> = new Subject<void>();
  public downloadFinished$: Subject<FileManagerType> = new Subject<FileManagerType>();
  public repeatingDownloadObservable$: Observable<FileDownloadTask>;
  public fileRef: FileManagerType;
  public taskIndex: number;
}

export type CollectionObject = {
  file: any; // FileRow
  writable: FileSystemWritableFileStreamCommonInterface;
  path: string;
  status: 'pending' | 'inprogress' | 'done' | 'error';
  requestId?: number;

  speed?: number;
  humanizedSpeed?: string;
  // rawTimeNeeded?: number;
  timeNeeded?: string;
  currPart?: number;
  countPart?: number;
  progress?: number;
};

/**
 * Used to manage collections (batched files like folder)
 */
export class CollectionManagerType {
  public direction: string; // up, down
  public file?: { fullName?: string }; // only for progress-bar component to handle the merged observers
  public path: string;
  public parts: CollectionObject[];
  public inProgressParts: CollectionObject[];
  public resourceId: string;
  public currPart: number;
  public countPart: number;
  public currSize: number;
  public maxSize: number;
  public speed: string;
  public timeNeeded: string;
  public started: Date;
  public progress: number;
  public errorFixStarted: boolean;
  public errorParts: CollectionObject[];
  public collectionId: string;
  public doneCallback?: Function;
  public progressCallback?: Function;
  public errorCallback?: Function;
  public dirHandler: any; // directory handler (important on error handling, to keep the handler reference of the directory we download in)
  public status: FileManagerStatus;

  public isPending(): boolean {
    return this.status === FileManagerStatus.PENDING;
  }

  public isInProgress(): boolean {
    return this.status === FileManagerStatus.IN_PROGRESS;
  }

  public isPaused(): boolean {
    return this.status === FileManagerStatus.PAUSED;
  }

  public isDone(): boolean {
    return this.status === FileManagerStatus.DONE;
  }

  public isError(): boolean {
    return this.status === FileManagerStatus.ERROR;
  }

  public isVerifying(): boolean {
    return this.status === FileManagerStatus.VERIFYING;
  }
}

export type DriveManagerUploadParam = {
  resourceId: ID;
  path: string | null;
  file: File;
  overwrite: boolean;
  alias?: string;
  freezedMTime?: number;
  autoName?: boolean;
  doneCallback?: (name, path) => void;
  errorCallback?: Function;
  progressCallback?: Function;
};

export let downloadProgressObserver = new Subject<FileManagerType>();
export let downloadStartedObserver = new Subject<FileManagerType>();
export let downloadPausedObserver = new Subject<FileManagerType>();
export let downloadResumedObserver = new Subject<FileManagerType>();
export let downloadFinishedObserver = new Subject<FileManagerType>();
export let downloadErrorObserver = new Subject<FileManagerType>();

export let repeatingTaskObserver = new Subject<null>();

export let uploadProgressObserver = new Subject<FileManagerType>();
export let uploadStartedObserver = new Subject<FileManagerType>();
export let uploadFinishedObserver = new Subject<FileManagerType>();
export let uploadErrorObserver = new Subject<FileManagerType>();

export let fileAddedObserver = new Subject<FileManagerType>();
export let fileRemovedObserver = new Subject<FileManagerType>();
export let collectionRemovedObserver = new Subject<CollectionManagerType>();

export let downloadCollectionProgressObserver = new Subject<CollectionManagerType>();
export let downloadCollectionStartedObserver = new Subject<CollectionManagerType>();
export let downloadCollectionFinishedObserver = new Subject<CollectionManagerType>();
export let downloadCollectionErrorObserver = new Subject<CollectionManagerType>();

@Injectable({
  providedIn: 'root',
})
export class DownloadManagerService {
  /**
   * number of parts processed at the same time in a collection
   */
  public MAX_PROCESSED_PARTS = 4;
  public MAX_CONCURRENT_DOWNLOAD_FILES = 8;

  private workingTasks: FileDownloadTask[] = [];

  private fileList: FileManagerType[] = [];
  private collectionList: CollectionManagerType[] = [];

  // store data in cache after download
  public static STORE_TIME_DOWNLOADED_DATA_IN_CACHE = 120 * 1000; // 2 min

  public getFileList(): FileManagerType[] {
    return this.fileList;
  }
  public getCollectionList(): CollectionManagerType[] {
    return this.collectionList;
  }

  private renderer: Renderer2;

  constructor(
    private nanoService: NanoService,
    private dialogService: DialogService,
    private nativeAppService: NativeAppService,
    private blobService: BlobService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public startCollection = async (
    collection: CollectionManagerType,
    batchSize: number = 1,
    progressCallback?: Function
  ) => {
    if (collection && batchSize) {
      const availableParts = collection.parts
        .filter((part) => part.status === 'pending')
        .slice(0, batchSize);

      for (let part of availableParts) {
        const { file, writable, path } = part;

        // pushes itself to inprogress parts
        collection.inProgressParts.push(part);
        const awaitedWritable = await writable;
        part.status = 'inprogress';

        let now = performance.now();
        let speedWindow = 1;

        part.requestId = this.nanoService.getFile(
          collection.resourceId,
          path,
          awaitedWritable,
          (response) => {
            part.status = 'done';

            const index = collection.inProgressParts.findIndex(
              (p) => p.requestId === part.requestId
            );
            if (index > -1) {
              collection.inProgressParts.splice(index, 1);
            }

            if (progressCallback) {
              progressCallback(0, 1, false); // we dont want to add the size, the progressCallback handles that
            }

            const statuses = collection.parts.map((part) => part.status);

            if (statuses.includes('pending')) {
              // still has pending parts
              // resolve them
              if (collection.status !== FileManagerStatus.PAUSED) {
                this.startCollection(collection, 1, progressCallback);
              }
            } else if (
              // !statuses.includes('pending') &&  // previous statement guarantees this
              !statuses.includes('inprogress')
            ) {
              // has no pending and inprogress parts
              // done or error
              if (statuses.includes('error')) {
                // send error
                if (!collection.errorFixStarted) {
                  console.log('error happened, fixing initiated');
                  collection.errorFixStarted = true;
                  this.resumeCollectionWithError(collection);
                } else {
                  // let it run i guess
                  console.log('Error happened but fixing is in progress');
                  return null;
                }

                // try to resolve errors
                console.log('has error');
              } else {
                collection.errorFixStarted = false;
                // everything is done
                console.log('everything is done');
                collection.doneCallback();
              }
            } else {
              // still in progress
            }
          },
          (err) => {
            console.log('Collection err', err, 'part', part);
            part.status = 'error';
            collection.status = FileManagerStatus.ERROR;

            const splicedPart = collection.inProgressParts.splice(
              collection.inProgressParts.findIndex((p) => p.requestId === part.requestId),
              1
            );
            if (splicedPart?.[0]) {
              collection.errorParts.push(splicedPart[0]); // ha hiba van fájl letöltése közben, akkor az adott sessiont pauseolja
            }

            this.startCollection(collection, 1, progressCallback);
          },
          (curr, max, speed) => {
            part.currPart = curr;
            part.countPart = max;
            part.progress = Math.round((curr / max) * 100);

            if (curr != 0) {
              let now2 = performance.now();
              if (now2 - now > 1000) {
                // show speed info in every sec
                let speedBytePerSec =
                  (NanoService.NETWORK_FILE_CHUNK_SIZE * speedWindow) / ((now2 - now) / 1000);
                now = now2;
                speedWindow = 1;

                part.speed = speedBytePerSec;
                part.humanizedSpeed = ByteHumanizer.humanize(speedBytePerSec) + '/sec';
                part.timeNeeded = RemainingTimeHumanizer.humanize(
                  Math.round(
                    ((part.countPart - part.currPart) * NanoService.NETWORK_FILE_CHUNK_SIZE) /
                      speedBytePerSec
                  )
                );
              } else {
                speedWindow++;
              }
            }

            let progressedSize: number;

            if (curr == 0 || curr == max) {
              progressedSize = 0;
            } else if (curr < max - 1) {
              progressedSize = NanoService.NETWORK_FILE_CHUNK_SIZE;
            } else {
              // last part
              progressedSize = file.file.size % NanoService.NETWORK_FILE_CHUNK_SIZE;
              if (progressedSize == 0) {
                progressedSize = NanoService.NETWORK_FILE_CHUNK_SIZE;
              }
            }

            // progressing one chunk
            if (progressCallback) {
              progressCallback(progressedSize, 0, false);
            }
            downloadCollectionProgressObserver.next(collection);
          }
        );
      }

      if (!availableParts.length && !collection.inProgressParts.length) {
        // edge case on folder copy without files
        collection.doneCallback();
      }
    } else {
      // throw error
      console.error('start collection called with invalid args: ', collection, batchSize);
    }
  };

  public async resumeCollection(collection: CollectionManagerType) {
    const isOnApp = this.nativeAppService.isOnApp();
    for (const part of collection.inProgressParts) {
      // app
      if (isOnApp) {
        this.nativeAppService.registerRunningProcess(part.requestId);
      }
      if (collection.direction === FileManagerDirection.UP) {
        this.nanoService.resumeUploadSession(part.requestId);
      } else {
        try {
          if (part.status === 'inprogress') {
            this.nanoService.resumeRequest(part.requestId);
          }
        } catch (err) {
          console.error('Error while resuming part', part, err);
          const splicedPart = collection.inProgressParts.splice(
            collection.inProgressParts.findIndex((p) => p.requestId === part.requestId),
            1
          );
          if (splicedPart?.[0]) {
            collection.errorParts.push(splicedPart[0]); // ha hiba van fájl letöltése közben, akkor az adott sessiont pauseolja
          }
        }
      }
    }
    this.startCollection(
      collection,
      this.MAX_PROCESSED_PARTS - collection.inProgressParts.length,
      collection.progressCallback
    );
  }

  public resumeCollectionWithError(
    collection: CollectionManagerType,
    targetedPart?: CollectionObject
  ) {
    console.log('resumeCollectionWithError started ', collection);
    // try to finish paused parts with errors
    collection.status = FileManagerStatus.IN_PROGRESS;
    for (const part of collection.errorParts) {
      if (targetedPart && part.requestId !== targetedPart.requestId) {
        return;
      }
      if (!part) {
        return;
      }
      try {
        if (part?.writable?.close) {
          // enclose with trycatch?
          // can throw error
          console.log('close writable stream');
          part.writable.close();
        }
        const path = part.path[0] === '/' ? part.path.slice(1) : part.path;
        const pathArray = path.split('/');
        const reDownloadFileCallback = (dirhandler) => {
          dirhandler.getFileHandle(part.file.name, { create: true }).then(
            (fileHandle) => {
              const collectionObject: CollectionObject = {
                writable: fileHandle.createWritable(),
                file: part.file,
                path: part.file.path,
                status: 'pending',
              };
              part.writable = collectionObject.writable;
              part.file = collectionObject.file;
              part.path = collectionObject.file.path;
              part.status = collectionObject.status;

              const splicableIndex = collection.errorParts.findIndex(
                (p) => p.requestId === part.requestId
              );

              if (splicableIndex >= 0) {
                const splicedPart = collection.errorParts.splice(
                  collection.errorParts.findIndex((p) => p.requestId === part.requestId),
                  1
                );
                /*if (splicedPart?.[0]) {
                  collection.inProgressParts.push(splicedPart[0]);
                }*/
              }

              if (
                collection.status === FileManagerStatus.DONE ||
                collection.status === FileManagerStatus.PAUSED
              ) {
                this.resumeCollection(collection);
              }
              this.startCollection(collection, 1, collection.progressCallback);
            },
            (err) => {
              console.error('error while fixing file', err);
              // throw err ?
            }
          );
        };

        const success = this.getFolderRecursively(
          collection.dirHandler,
          pathArray,
          0,
          reDownloadFileCallback
        );
        console.log('success?', success, collection.inProgressParts.length);
      } catch (err) {
        console.error('Error while resuming part', part);
        part.status = 'error';
        collection.status = FileManagerStatus.ERROR;
      }
    }
  }

  /**
   * Get the direhandler for the file
   * @param dirHandler
   * @param pathArray
   * @param index
   * @param cb
   * @returns dirhandler
   */
  private getFolderRecursively = (dirHandler, pathArray, index, cb) => {
    if (index + 1 >= pathArray.length) {
      return cb(dirHandler);
    }
    const path = pathArray[index];

    return dirHandler.getDirectoryHandle(path, { create: true }).then((subDirHandle) => {
      return this.getFolderRecursively(subDirHandle, pathArray, index + 1, cb);
    });
  };

  public async pauseCollection(collection: CollectionManagerType) {
    if (collection.status !== FileManagerStatus.PAUSED) {
      collection.status = FileManagerStatus.PAUSED;

      const isOnApp = this.nativeAppService.isOnApp();
      for (const part of collection.inProgressParts) {
        // app
        if (isOnApp) {
          this.nativeAppService.removeRunningProcess(part.requestId);
        }

        if (collection.direction === FileManagerDirection.UP) {
          this.nanoService.pauseUploadSession(part.requestId);
        } else {
          console.log('pausing: ', part);
          try {
            this.nanoService.pauseRequest(part.requestId);
            console.log('paused: ', part);
          } catch (err) {
            console.error('pause error on requestId: ', part.requestId, err);
          }
        }
      }
    }
  }

  public async downloadCollection(
    resourceId: string,
    path: string,
    collection: CollectionObject[],
    dirHandler: any
  ) {
    console.log('downloadCollection started', collection);

    const now = new Date();
    const collectionId = `collection_${resourceId}_${now.toISOString()}`;

    const collectionManagerType = new CollectionManagerType();
    collectionManagerType.direction = FileManagerDirection.DOWN;
    collectionManagerType.parts = collection;
    collectionManagerType.inProgressParts = [];
    collectionManagerType.errorParts = [];
    collectionManagerType.path = path;
    collectionManagerType.resourceId = resourceId;
    collectionManagerType.currPart = 0;
    collectionManagerType.countPart = collection?.length ?? 0;
    collectionManagerType.currSize = 0;
    collectionManagerType.maxSize = collection.reduce((sum, curr) => sum + curr.file.file.size, 0);
    collectionManagerType.speed = '';
    collectionManagerType.timeNeeded = '';
    collectionManagerType.started = now;
    collectionManagerType.progress = 0;
    collectionManagerType.errorFixStarted = false;
    collectionManagerType.collectionId = collectionId;
    collectionManagerType.status = FileManagerStatus.PENDING;
    collectionManagerType.doneCallback = (result) => {
      collectionManagerType.status = FileManagerStatus.DONE;
      console.log('collection done');

      downloadCollectionFinishedObserver.next(collectionManagerType);
    };
    collectionManagerType.progressCallback = (progressedSize, progressedPart, error) => {
      console.log('Collection progress callback', progressedSize, progressedPart, error);
      if (progressedSize) {
        const newSize = collectionManagerType.currSize + progressedSize;

        collectionManagerType.currSize = newSize;
        collectionManagerType.progress = (newSize / collectionManagerType.maxSize) * 100;
      }
      if (progressedPart) {
        collectionManagerType.currPart += progressedPart;
      }
      if (error) {
        collectionManagerType.status = FileManagerStatus.ERROR;
      }
    };
    collectionManagerType.errorCallback = (err) => {
      console.error('err on coll and part', err, collectionManagerType);
      collectionManagerType.status = FileManagerStatus.ERROR;
      this.pauseCollection(collectionManagerType);
      // downloadCollectionErrorObserver.next(collectionManagerType)
    };
    collectionManagerType.dirHandler = dirHandler;

    this.collectionList.push(collectionManagerType);
    downloadCollectionStartedObserver.next(collectionManagerType);

    console.log('START COLLECTION WITH ', collectionManagerType);
    this.startCollection(
      collectionManagerType,
      this.MAX_PROCESSED_PARTS,
      collectionManagerType.progressCallback
    );
  }

  public removeCollection(collection: CollectionManagerType) {
    const isOnApp = this.nativeAppService.isOnApp();
    let index = 0;
    for (const part of collection.parts) {
      try {
        if (part.status !== 'done' && part.requestId >= 0) {
          if (collection.direction === FileManagerDirection.UP) {
            this.nanoService.removeUploadSession(part.requestId);
          } else {
            console.log('delete requestId of ', part);
            this.nanoService.deleteRequestSession(part.requestId);
          }
          if (isOnApp) {
            this.nativeAppService.removeRunningProcess(part.requestId);
          }
        }
        index++;
      } catch (err) {
        console.error('err', err);
      }
    }

    let pos = this.collectionList.indexOf(collection);
    if (pos > -1) {
      this.collectionList.splice(pos, 1);
    }

    collectionRemovedObserver.next(collection);
  }

  /**
   * In Chrome there is a bug, with small chance the filewriter can not close the file, so it is not
   * flush the last part. In this case we can not use the corrupted filewriter, need to create a new one and
   * redownload the file
   * @param collection
   * @returns
   */
  verifyCollection = (collection: CollectionManagerType) => {
    collection.status = FileManagerStatus.VERIFYING;

    const dirHandlerMap = { [collection.path]: collection.dirHandler };

    let partCounter = 0;
    let verifyError = false;
    for (const part of collection.parts) {
      console.log('starting with part: ', partCounter, part);
      if (!part) {
        return;
      }
      if (verifyError) {
        // go out if error
        break;
      }
      partCounter += 1;
      if (part.file?.parent) {
        const path = part.path[0] === '/' ? part.path.slice(1) : part.path;
        const pathArray = path.split('/');
        if (part.file.parent.path in dirHandlerMap) {
          // folder's dirHandler is already used and stored in dirHandlerMap
          dirHandlerMap[part.file.parent.path]
            .getDirectoryHandle(pathArray[pathArray.length - 2], { create: true })
            .then((dirHandler) => {
              const lastIndex = path.lastIndexOf('/');
              const slicedFolderPath = path.slice(0, lastIndex < 0 ? path.length : lastIndex);
              dirHandlerMap[slicedFolderPath] = dirHandler;
              dirHandler.getFileHandle(part.file.name, { create: true }).then((fileHandle) => {
                verifyError = true;
                this.verifySingleFile(fileHandle, part, collection);
              });
            });
        } else {
          this.getFolderRecursively(collection.dirHandler, pathArray, 0, (foundDirHandler) => {
            const lastIndex = path.lastIndexOf('/');
            const slicedFolderPath = path.slice(0, lastIndex < 0 ? path.length : lastIndex);
            dirHandlerMap[slicedFolderPath] = foundDirHandler;
            foundDirHandler.getFileHandle(part.file.name, { create: true }).then((fileHandle) => {
              verifyError = true;
              this.verifySingleFile(fileHandle, part, collection);
            });
          });
        }
      } else {
        const path = part.path[0] === '/' ? part.path.slice(1) : part.path;
        collection.dirHandler.getDirectoryHandle(path, { create: true }).then((subDirHandler) => {
          console.log('subdirHandler in root', subDirHandler);
          // ha nincs parent, akkor gyökérben vagyunk, ez csak a főmappánál lehetséges
        });
      }
    }
  };

  private verifySingleFile = (fileHandle, part, collection: CollectionManagerType) => {
    fileHandle.getFile().then((file) => {
      if (file.size !== part.file.size) {
        console.error('Error while verifying file: ', file, part);
        // ezeket összegyűjteni promisokként és a végén lefuttatni. Ha mind megvan, akkor resumeCollectionWithError
        part.status = 'error';
        collection.status = FileManagerStatus.ERROR;
        collection.errorParts.push(part);
        this.resumeCollectionWithError(collection, part);
      } else if (collection.status === FileManagerStatus.VERIFYING) {
        collection.status = FileManagerStatus.DONE; //if verifying is finished and there was no error, then the collection is done
      }
    });
  };

  public download(resourceId, file: DriveFile): Promise<void> {
    return this.downloadMultiple(resourceId, [file]);
  }

  public async downloadMultiple(resourceId: string, files: DriveFile[]): Promise<void> {
    //create the filereference objects and add them to the global fileList
    await this.createFileReferences(resourceId, files);

    //create the repeating download tasks based on the available free download tasks.
    //If no tasks are available we don't do anything, the running tasks will process the files later
    if (this.canCreateDownloadTask()) {
      this.beginDownloadTasks(files.length);
    }
  }

  private beginDownloadTasks(fileCount: number) {
    const downloadTasks = this.createDownloadTaskForFiles(fileCount);
    this.workingTasks.push(...downloadTasks);

    console.log(
      downloadTasks.length,
      ' Tasks added, currently working tasks:',
      JSON.parse(JSON.stringify(this.workingTasks.length))
    );

    const taskObservables = downloadTasks.map((wt) => wt.repeatingDownloadObservable$);

    //begin working on the tasks
    merge(...taskObservables).subscribe({
      next: (task: FileDownloadTask) => {
        console.log(
          'TaskIndex: ',
          task.taskIndex,
          ' - finished with file: ',
          task.fileRef.file.name
        );
      },
      complete: () => {
        if (this.workingTasks.length > 0) {
          console.log('workingTask batch complete');
        } else {
          console.log('all workingTask complete');
          repeatingTaskObserver.next(null);
        }
      },
      error: (err) => console.error(err),
    });
  }

  private createDownloadTaskForFiles(fileCount: number): FileDownloadTask[] {
    const availableTasks =
      this.MAX_CONCURRENT_DOWNLOAD_FILES -
      this.workingTasks.filter((wt) => wt.fileRef.isInProgress()).length;
    if (availableTasks === 0) return []; //no download tasks are free, the files will be processed by the currently running tasks later

    const neededTasks = fileCount > availableTasks ? availableTasks : fileCount;
    const repeatingDownloadTasks: FileDownloadTask[] = [];

    console.log('Received tasks:', neededTasks);

    //start 'neededTasks' concurrent, repeating download tasks
    for (let i = 0; i < neededTasks; i++) {
      const taskIndex = i + this.workingTasks.length;
      const downloadTask = new FileDownloadTask();
      repeatingDownloadTasks.push(downloadTask);

      console.log(`Task ${taskIndex} created`);
      const repeatingDownloadTask$ = of(taskIndex).pipe(
        map((taskIndex: number) => {
          downloadTask.taskIndex = taskIndex;
          return this.fileList.find((f) => f.isPending());
        }),
        mergeMap((fileRef: FileManagerType) => {
          console.log('TaskIndex:', downloadTask.taskIndex, ' - found file: ', fileRef?.file.name);
          if (fileRef === undefined) {
            this.workingTasks = this.workingTasks.filter((wt) => wt !== downloadTask); //this task is no longer working, remove it from the list
            downloadTask.downloadFinished$.complete(); //complete downloadTask to break out of repeat
            return new Observable((observer: Observer<any>) => observer.complete()); //if there is no fileRef, then complete the download$
          } else {
            fileRef.setStatus(FileManagerStatus.IN_PROGRESS); //work begins on the fileRef
            downloadTask.fileRef = fileRef;
            let download$ = this.getFileDownloadObservable(fileRef).pipe(
              catchError((err) => {
                console.error('Error in download', err);
                return throwError(() => new Error(err));
              }),
              finalize(() => {
                setTimeout(() => downloadTask.downloadFinished$.next(fileRef), 1);
              }),
              takeUntil(downloadTask.downloadCancel$), //return faster when the user cancels this download,
              tap(downloadTask.fileRef.downloadObservable$) //as download$ progresses, call the fileRef's downloadObservable with the same data
            );

            //wire up progress handlers
            downloadTask.fileRef.downloadObservable$.subscribe({
              next: () => this.handleFileProgress(downloadTask.fileRef),
              error: (err) => this.handleDownloadError(err, downloadTask.fileRef),
              complete: () => this.fileDownloadDone(downloadTask.fileRef),
            });

            //send event that download started
            downloadStartedObserver.next(fileRef);

            return download$.pipe(takeLast(1)); //the repeatingDownloadTask$ is only interested in the finished download$. The download progress is unnecessary noise.
          }
        }),
        repeat({ delay: () => downloadTask.downloadFinished$ }),
        map(() => downloadTask)
      );

      downloadTask.repeatingDownloadObservable$ = repeatingDownloadTask$;
    }
    return repeatingDownloadTasks;
  }

  private getFileDownloadObservable(fileReference: FileManagerType): Observable<FileManagerType> {
    fileReference.setStatus(FileManagerStatus.IN_PROGRESS);
    const file = fileReference.file;
    const originalFilePath =
      fileReference.file.path.length == 0
        ? file.fullName
        : fileReference.file.path + '/' + file.fullName;

    const fileDownloadObservable: Observable<FileManagerType> = new Observable<FileManagerType>(
      (observer: Observer<FileManagerType>) => {
        this.getFileWriterForFileRef(fileReference).subscribe((streamWriter) => {
          let requestId = this.nanoService.getFile(
            fileReference.resourceId,
            originalFilePath,
            streamWriter,
            (response) => {
              fileReference.rawObject = response;
              fileReference.currPart = fileReference.countPart;
              fileReference.progress = 100;
              observer.complete();
            },
            (err) => {
              // observer.error(err);
              this.handleDownloadError(err, fileReference);
            },
            (curr, max) => {
              fileReference.currPart = curr;
              fileReference.countPart = max;
              observer.next(fileReference);
            }
          );
          fileReference.requestId = requestId;
          fileReference.streamWriter = streamWriter;
        });
      }
    );

    return fileDownloadObservable;
  }

  private async createFileReferences(resourceId: string, files: DriveFile[]): Promise<void> {
    const existingFileNames: Array<string> = [];
    let fsDirectoryHandle = null;

    if (files.length === 1 && files[0].size < NanoService.FILE_STREAM_MIN_SIZE) {
      // blob download, leaves fsDirectoryHandle null
    } else {
      fsDirectoryHandle = await firstValueFrom(this.getFileDirectoryHandle());

      //get info from the folder contents so we can append a running index to the file if it already exists
      if (fsDirectoryHandle) {
        for await (const handle of fsDirectoryHandle.values()) {
          existingFileNames.push(handle.name);
        }
      }
    }

    //create the files and push them into the filelist
    for (const file of files) {
      let newFileName = file.fullName;

      if (existingFileNames.some((existingFileName) => existingFileName == file.fullName))
        newFileName = this.createUnusedFileName(existingFileNames, file);

      const newFilePath = file.path.length == 0 ? newFileName : file.path + '/' + newFileName;
      const fileReference = this.createDefaultFileManagerType(file, resourceId, newFilePath);
      fileReference.fsDirectoryHandle = fsDirectoryHandle;

      this.addToFileList(fileReference);
    }
  }

  private fileDownloadDone(fileReference: FileManagerType): void {
    fileReference.setStatus(FileManagerStatus.DONE);
    downloadFinishedObserver.next(fileReference);

    if (this.nativeAppService.isOnApp()) {
      //if we are on mobile
      let mobileWriter = fileReference.streamWriter as FileSystemWritableFileStreamMobile;
      this.nativeAppService.removeRunningProcess(fileReference.requestId);
      fileReference.mobileLocalPath = mobileWriter.getPath();
    } else if (!fileReference.streamWriter) {
      if (fileReference.progress == 100) {
        //if there is no streamWriter, then we must prompt the file for download by hand
        var a;
        a = this.renderer.createElement('a');

        try {
          fileReference.objectURL = this.blobService.createObjectURL(fileReference.rawObject);
        } catch (e) {}

        this.renderer.setProperty(a, 'href', fileReference.objectURL);
        this.renderer.setProperty(a, 'download', fileReference.file.fullName);
        a.click();
      }

      // remove from cache
      setTimeout(() => {
        this.blobService.revokeObjectURL(fileReference.objectURL);
        fileReference.objectURL = null;
        fileReference.rawObject = null;
      }, DownloadManagerService.STORE_TIME_DOWNLOADED_DATA_IN_CACHE);
    } else {
      //there is a streamWriter, we don't have to store the raw file object
      fileReference.rawObject = null;
    }
  }

  private handleFileProgress(fileReference: FileManagerType): void {
    if (!fileReference.isInProgress() && !fileReference.isPaused())
      fileReference.setStatus(FileManagerStatus.IN_PROGRESS);
    fileReference.updateProgress();

    if (fileReference.direction === FileManagerDirection.DOWN) {
      if (fileReference.currPart == 0) {
        if (this.nativeAppService.isOnApp())
          this.nativeAppService.registerRunningProcess(fileReference.requestId);
      } else downloadProgressObserver.next(fileReference);
    } else if (fileReference.direction === FileManagerDirection.UP) {
      if (fileReference.currPart == 1) uploadStartedObserver.next(fileReference);
      else if (fileReference.currPart != 100) uploadProgressObserver.next(fileReference);
    }
  }

  private handleDownloadError(err, fileReference: FileManagerType): void {
    fileReference.setStatus(FileManagerStatus.ERROR);
    fileReference.errorDetail = new ServerErrorDetail(err.error);
    downloadErrorObserver.next(fileReference);
  }

  private createUnusedFileName(existingNames: string[], file: DriveFile): string {
    let existingFileName = existingNames.find((existingName) => existingName == file.fullName);
    let matches = existingFileName.match(/\((\d*)\)[^(]*$/);
    let newFileName: string = '';
    let index: number = 1;
    let toReplace: string = `.${file.ext}`;

    if (matches && matches.length > 1) {
      //filename ends with numbers between parentheses
      toReplace = matches[0]; //last part with parentheses. Eg.: (3).png
    }

    newFileName = file.fullName.replace(toReplace, ` (${index}).${file.ext}`);

    //check names until we find an unused one
    while (existingNames.some((existingName) => existingName == newFileName)) {
      newFileName = file.fullName.replace(toReplace, ` (${index}).${file.ext}`);
      index++;
    }

    return newFileName;
  }

  public createDefaultFileManagerType(
    file: DriveFile,
    resourceId: string,
    path: string
  ): FileManagerType {
    const fileRef = new FileManagerType();
    fileRef.file = file;
    fileRef.resourceId = resourceId;
    fileRef.path = path;
    return fileRef;
  }

  public upload(param: DriveManagerUploadParam) {
    let driveFile = new DriveFile(param.file);
    if (param.alias) {
      driveFile.updateName(param.alias);
    }

    let refForFile = this.createDefaultFileManagerType(driveFile, param.resourceId, param.path);
    refForFile.direction = FileManagerDirection.UP;

    let startUpload = () => {
      if (this.getNotFinnishedUpload() < 8) {
        this.addToFileList(refForFile);

        // let now = performance.now();
        // let speedWindow = 1; // how many chunks we downloaded to get speed information

        refForFile.requestId = this.nanoService.uploadFile(
          param.resourceId,
          param.file,
          param.path,
          param.overwrite,
          param.alias,
          param.autoName,
          (name, path) => {
            //this.listDirectory(); TODO
            refForFile.setStatus(FileManagerStatus.DONE);
            uploadFinishedObserver.next(refForFile);
            this.nativeAppService.isOnApp() &&
              this.nativeAppService.removeRunningProcess(refForFile.requestId);
            if (name && name.length > 0) {
              refForFile.file.fullName = name;
              refForFile.path = path;
              refForFile.resourceId = param.resourceId;
            }
            param.doneCallback(name, path); // norm_name
          },
          (err) => {
            console.log('error', err);
            refForFile.setStatus(FileManagerStatus.ERROR);
            refForFile.errorDetail = new ServerErrorDetail(err.error);
            uploadErrorObserver.next(refForFile);
            param.errorCallback && param.errorCallback(err);
            if (err.error === 4218) {
              this.removeFromFileList(refForFile);
            }
          },
          (curr, max, speed) => {
            refForFile.currPart = curr;
            refForFile.countPart = max;
            this.handleFileProgress(refForFile);

            param.progressCallback && param.progressCallback(curr, max);
          },
          param.freezedMTime
        );
      } else {
        console.log('too much upload, wait a little bit');
        setTimeout(startUpload, 1000);
      }
    };
    startUpload();
    this.nativeAppService.isOnApp() &&
      this.nativeAppService.registerRunningProcess(refForFile.requestId);

    return refForFile;
  }

  // set this limitation. We can remove this after mutation key handling refactor
  private getNotFinnishedUpload() {
    let notFinished = 0;
    for (let i = 0; i < this.fileList.length; i++) {
      if (this.fileList[i].isInProgress()) {
        notFinished++;
      }
    }
    return notFinished;
  }

  public removeFromFileList(file: FileManagerType): void {
    if (file.isInProgress() || file.isPaused()) {
      if (file.direction == FileManagerDirection.UP) {
        this.nanoService.removeUploadSession(file.requestId);
      } else {
        this.nanoService.deleteRequestSession(file.requestId);
        this.cancelDownloadObservable(file);
      }
      this.nativeAppService.isOnApp() && this.nativeAppService.removeRunningProcess(file.requestId);
    }

    let pos = this.fileList.indexOf(file);
    if (pos > -1) {
      this.fileList.splice(pos, 1);
    }

    fileRemovedObserver.next(file);
  }

  public removeFinishedFromFileList(): void {
    let doneFiles = this.fileList.filter((f) => f.isDone());
    doneFiles.map((f) => this.removeFromFileList(f));
  }

  public pauseFile(fileRef: FileManagerType): void {
    if (!fileRef.isPaused()) {
      fileRef.setStatus(FileManagerStatus.PAUSED);
      this.nativeAppService.isOnApp() &&
        this.nativeAppService.removeRunningProcess(fileRef.requestId);
      if (fileRef.direction == FileManagerDirection.UP) {
        this.nanoService.pauseUploadSession(fileRef.requestId);
      } else {
        this.nanoService.pauseRequest(fileRef.requestId);
        downloadPausedObserver.next(fileRef);
        this.checkForNewTask();
      }
    }
  }

  private checkForNewTask(): void {
    const pendingFile = this.fileList.find((f) => f.isPending());
    console.log('Checking for new task, pending file is', pendingFile);
    if (pendingFile) this.beginDownloadTasks(1);
  }

  public resumeFile(fileRef: FileManagerType): void {
    if (fileRef.isPaused()) {
      fileRef.setStatus(FileManagerStatus.IN_PROGRESS);
      fileRef.prevTimeNeededList = []; //reset prevTimeList so after pause we can still be precise
      this.nativeAppService.isOnApp() &&
        this.nativeAppService.registerRunningProcess(fileRef.requestId);
      if (fileRef.direction == FileManagerDirection.UP) {
        this.nanoService.resumeUploadSession(fileRef.requestId);
      } else {
        this.nanoService.resumeRequest(fileRef.requestId);
        downloadResumedObserver.next(fileRef);
      }
    }
  }

  public canCreateDownloadTask(): boolean {
    const workingTaskCount = this.workingTasks.filter((wt) => wt.fileRef.isInProgress()).length;
    return workingTaskCount < this.MAX_CONCURRENT_DOWNLOAD_FILES;
  }

  private cancelDownloadObservable(file: FileManagerType): void {
    let downloadTask = this.workingTasks.find((wt) => wt.fileRef === file);
    downloadTask.downloadCancel$.next();
  }

  private addToFileList(file: FileManagerType): void {
    this.fileList.push(file);
    fileAddedObserver.next(file);
  }

  public isUploadInProgress(): boolean {
    return (
      this.fileList.some((f) => f.isInProgress() && f.direction == FileManagerDirection.UP) ||
      this.collectionList.some(
        (c) => c.status !== FileManagerStatus.DONE && c.direction == FileManagerDirection.UP
      )
    );
  }
  public isDownloadInProgress(): boolean {
    return (
      this.fileList.some((f) => f.isInProgress() && f.direction == FileManagerDirection.DOWN) ||
      this.collectionList.some(
        (c) => c.status !== FileManagerStatus.DONE && c.direction == FileManagerDirection.DOWN
      )
    );
  }

  private getFileWriterForFileRef(fileReference: FileManagerType): Observable<any> {
    if (this.nativeAppService.isOnApp()) {
      //if we are in application
      return this.getMobileFileWriter(fileReference);
    } else if (window['showDirectoryPicker']) {
      //directoryPicker technology
      return this.getDirectoryPickerWriter(fileReference);
    } else {
      //legacy fallback, in memory
      return this.showInMemoryWarning();
    }
  }

  private getDirectoryPickerWriter(fileReference: FileManagerType): Observable<any> {
    if (fileReference.fsDirectoryHandle === null) {
      return of(null);
    } else {
      return of(fileReference.fsDirectoryHandle).pipe(
        mergeMap((fsDirectoryHandle: any) =>
          fsDirectoryHandle.getFileHandle(fileReference.file.fullName, { create: true })
        ),
        mergeMap((fileHandle: any) => fileHandle.createWritable())
      );
    }
  }

  private getFileDirectoryHandle(): Observable<any> {
    if (window['showDirectoryPicker']) {
      return of(window['showDirectoryPicker']()).pipe(
        mergeMap((fsDirectoryHandlePromise) => fsDirectoryHandlePromise)
      );
    } else return of(null);
  }

  private getMobileFileWriter(fileReference: FileManagerType): Observable<any> {
    return from(this.nativeAppService.getFileWriter(fileReference.file.fullName)).pipe(
      catchError((err) => {
        this.dialogService.openAlertDialog(
          marker('Permission required'),
          marker(
            'File Storage permission is required for this operation. Please make sure to give Nano the requested permission.'
          )
        );

        throw err;
      })
    );
  }

  private showInMemoryWarning(): Observable<null> {
    // return null, so without writablestream we will use blob
    if (AppStorage.getItem('big-file-download-warning') != '1') {
      // ask only the first time
      return this.dialogService
        .openConfirmDialog(
          marker('File download'),
          marker(
            "Downloading large files using this browser will use the computer's memory as temporary storage. If the file is too large to fit inside the available memory, the download may fail. To download large files optimally, use a browser with file-streaming support. (Chrome or Edge)"
          ),
          marker('Download large file without streaming support'),
          marker('Cancel')
        )
        .pipe(
          map((confirm: boolean) => {
            if (confirm) {
              AppStorage.setItem('big-file-download-warning', '1');
              return null;
            } else {
              throw new Error('cancel');
            }
          })
        );
    } else {
      return of(null);
    }
  }

  public clear(): void {
    while (this.fileList.length > 0) {
      this.removeFromFileList(this.fileList[0]);
    }

    while (this.collectionList.length > 0) {
      this.removeCollection(this.collectionList[0]);
    }
  }
}

let holdFileInCache = AppStorage.getItem(AdvancedSettingsEnum.HOLD_FILE_IN_CACHE);
if (holdFileInCache !== undefined) {
  let limit = parseInt(holdFileInCache);
  if (limit >= 10) {
    DownloadManagerService.STORE_TIME_DOWNLOADED_DATA_IN_CACHE = limit;
  }
}
