import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountService } from 'src/app/shared/server-services/account.service';
import {
  ERROR_NOT_AVAILABLE,
  RoomData,
  RoomRecord,
  RoomWithData,
} from 'src/app/shared/server-services/query-records/room-records';
import { SidebarResource } from 'src/app/shared/server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionRoomEventRecord } from 'src/app/shared/server-services/query-records/workspace-records';
import { RoomService } from 'src/app/shared/server-services/room.service';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from 'src/app/shared/server-services/subscription-event';
import { SubscriptionService } from 'src/app/shared/server-services/subscription.service';
import { PageTabService, PageTypes, SubPageTypes } from 'src/app/shared/services/page-tab.service';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { sideMenuActiveObserver } from 'src/app/shared/shared-observers';
import { moveIntoOtherGroupObserver, openSidebarRoomContextMenu } from '../sidebar.component';

@Component({
  selector: 'app-sidebar-room',
  templateUrl: './sidebar-room.component.html',
  styleUrls: ['./sidebar-room.component.scss'],
})
export class SidebarRoomComponent implements OnInit, OnDestroy {
  @Input() sidebarResourceData: SidebarResource;
  @Input() isReordering: boolean = false;

  public room: RoomRecord;
  public roomData: RoomData;

  public avatar: ArrayBuffer;
  public name: string;
  public error: boolean;
  public news: boolean;

  public selectedMenu = false;

  public isNanoConnected: boolean = false;
  public isNanoCompeting: boolean = false;
  public isMyRoom: boolean = false;

  public isVisible: boolean = false;

  private onRouterChange = (res) => {
    this.selectedMenu = this.sidebarResourceData.id == res?.params?.id;
  };

  constructor(
    private routerHandler: RouterHandler,
    private subscriptionService: SubscriptionService,
    private roomService: RoomService,
    private accountService: AccountService,
    private pageTabService: PageTabService
  ) {
    this.routerHandler.subscribeAll(this.onRouterChange);

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.updateData
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.updateData
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.updateData
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_PERMISSION_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.updateData
    );

    // enableRoomReorderObserver.subscribe((reordering: boolean) => {
    //   this.isReordering = reordering;
    // });
  }

  ngOnInit(): void {
    // RoomData is not necessary here
    // only after the component is visible
    this.loadRoomRecord().then(() => {
      this.refreshState();
      this.accountService.getMe().then((me) => {
        this.isMyRoom = this.room.ownerAccountId == me.id;

        this.onRouterChange(this.routerHandler.getRoute());
      });
    });
  }

  private updateData = (event: WorkspaceSubscriptionRoomEventRecord) => {
    if (this.sidebarResourceData.id == event.id) {
      if (event.data) {
        // data in the cache, refresh the data with that
        this.loadRoomData();
      } else {
        // not sure if data in the cache, return with the optimized query
        this.loadRoomRecord();
      }
      this.refreshState();
    }
  };

  public visibilityChange(visible: boolean) {
    if (!this.roomData && visible) {
      this.loadRoomData();
    }
    if (this.isVisible !== visible) {
      this.isVisible = visible;
    }
  }

  // load the data object on the component
  private loadRoomData(): Promise<void> {
    return this.roomService
      .getRoom(this.sidebarResourceData.id)
      .then((room) => {
        this.room = room;
        this.roomData = room.data;
        this.refreshState();
        return;
      })
      .catch((err) => {
        console.warn('can not find room', err);
        return;
      });
  }

  // load the record, we dont need the data, so we dont start a query for that
  // but there is a chance it is already in the cache
  private loadRoomRecord(): Promise<void> {
    return this.roomService.getRoomRecord(this.sidebarResourceData.id).then((room) => {
      //console.log('load room record', room);
      this.room = room;
      // the data is already in the cache, or it is generated a full server request
      if ((<RoomWithData>room).data) {
        this.roomData = (<RoomWithData>room).data;
      }
      this.refreshState();
      return;
    });
  }

  /**
   * Refresh all the view-based variables to trigger changes
   */
  private refreshState() {
    if (
      this.room.inaccessible ||
      (this.roomData &&
        this.roomData.decryptionError &&
        this.roomData.decryptionErrorMessage === ERROR_NOT_AVAILABLE)
    ) {
      this.leaveInaccessibleRoom();
      return;
    }

    if (this.room.nanoSessions) {
      this.isNanoConnected = Object.keys(this.room.nanoSessions).length > 0;
      this.isNanoCompeting = Object.keys(this.room.nanoSessions).length > 1;
    }
    if (this.roomData) {
      if (this.roomData.decryptionError) {
        this.error = true;
        this.name = this.roomData.decryptionErrorMessage;
      } else {
        this.error = false;
        this.name = this.roomData.name;
        this.avatar = this.roomData.avatar;
      }
    }
    this.news = this.sidebarResourceData?.hasNewMessages;
    // this.news =
    //   (this.room.seenMessageId == null && this.room.topMessageId !== null) ||
    //   this.room.seenMessageId < this.room.topMessageId;
  }

  private leaveInaccessibleRoom() {
    if (!this.isMyRoom) {
      this.roomService.leaveRoom(this.room.id);
    } else {
      this.roomService.deleteRoom(this.room.id);
    }
  }

  ngOnDestroy(): void {
    this.routerHandler.unsubscribeAll(this.onRouterChange);
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.updateData
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.updateData
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.updateData
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_PERMISSION_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.updateData
    );
  }

  hideSidebar(): void {
    this.loadRoomData();
    sideMenuActiveObserver.next(false);
  }

  public moveIntoOtherGroup(): void {
    moveIntoOtherGroupObserver.next(this.sidebarResourceData);
  }

  openContextMenu(e) {
    e.preventDefault();
    openSidebarRoomContextMenu.next({
      roomData: {
        id: this.room.id,
        isError: this.error,
        isMyRoom: this.isMyRoom,
        isNanoConnected: this.isNanoConnected,
      },
      pos: { x: e.clientX, y: e.clientY },
    });
  }

  selected(e: PointerEvent) {
    if (this.isReordering) return;
    if (e.pointerType == 'mouse' && e.button == 1) {
      // left click
      this.hideSidebar();
      this.pageTabService.openInNewTab({
        page: PageTypes.ROOM,
        subpage: SubPageTypes.CHAT,
        id: this.sidebarResourceData.id,
        fragment: {},
      });
    }
  }
}
