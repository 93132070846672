import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { downloadOverallProgressChanged } from 'src/app/components/header/progress-bar/progress-bar.component';
import {
  ExtendedSidebarResource,
  newsObserver,
} from 'src/app/components/news-list/news-list.component';
import { AccountService } from '../server-services/account.service';
import { ResourceType } from '../server-services/query-records/sidebar-records';
import { RoomService } from '../server-services/room.service';
import { SidebarService } from '../server-services/sidebar.service';

enum TitleMode {
  DEFAULT, // basic title
  EVENT, // new message, new invite...
  PROGRESS, // download/upload progress
}

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private titlePart: string = 'Clarabot Nano';
  public news: number = 0;

  private recentNewsTitle: { type: ResourceType; name: string } = null;
  private currentTabTitle: string = '';

  private mode: TitleMode = TitleMode.DEFAULT;

  public constructedTitle: string = 'Clarabot Nano';

  private rotationTimer = null;
  private rotateEnabled: boolean = false;

  constructor(
    private title: Title,
    private translate: TranslateService,
    private accountService: AccountService,
    private roomService: RoomService,
    private sidebarService: SidebarService
  ) {
    this.translate.onLangChange.subscribe(() => this.constructTitle());

    newsObserver.subscribe((news) => {
      this.setNewsChange(news);
    });

    downloadOverallProgressChanged.subscribe((overallProgress) => {
      this.setProgressTitle(overallProgress);
    });

    this.rotationTimer = setInterval(() => {
      if (this.rotateEnabled) this.rotateTitle();
    }, 4000);
  }

  private rotateTitle = () => {
    if (this.mode === TitleMode.DEFAULT) {
      if (this.news > 0) {
        this.mode = TitleMode.EVENT;
        this.constructTitle();
      }
    } else if (this.mode === TitleMode.EVENT) {
      this.mode = TitleMode.DEFAULT;
      this.constructTitle();
    } else if (this.mode === TitleMode.PROGRESS) {
      // do nothing, stay while it lasts
    }
  };

  private setNewsChange = (news: {
    count: number;
    newsCount: number;
    grantedRoomsCount: number;
    newsList: ExtendedSidebarResource[];
  }) => {
    this.news = news.count;
    const newsCount = news.newsCount;
    const grantedRoomsCount = news.grantedRoomsCount;

    if (newsCount) {
      const firstNew = news.newsList.find((res) => res.hasNewMessages);
      if (firstNew) {
        if (firstNew.type === ResourceType.DIALOGUE) {
          this.accountService.getAccount(firstNew.id).then((res) => {
            this.recentNewsTitle = { type: ResourceType.DIALOGUE, name: res.avatarName };
            this.constructTitleWithNewsChange();
          });
        }
        if (firstNew.type === ResourceType.ROOM) {
          this.roomService.getRoom(firstNew.id).then((res) => {
            this.recentNewsTitle = { type: ResourceType.ROOM, name: res.data.name };
            this.constructTitleWithNewsChange();
          });
        }
      } else {
        this.recentNewsTitle = null;
        this.constructTitleWithNewsChange();
      }
    } else if (grantedRoomsCount) {
      this.recentNewsTitle = null;
      this.constructTitleWithNewInvites(grantedRoomsCount);
    } else {
      this.recentNewsTitle = null;
      this.constructTitleWithOnPage();
    }
  };

  private setProgressTitle = (overallProgress: number) => {
    if (overallProgress === 100) {
      this.title.setTitle(this.constructedTitle);
      this.mode = TitleMode.DEFAULT;
    } else {
      this.title.setTitle(`(⇅${overallProgress}%) ${this.currentTabTitle} | ${this.titlePart}`);
      this.mode = TitleMode.PROGRESS;
    }
  };

  public setCurrentTabTitle = (tabTitle: string) => {
    this.translate.get(tabTitle).subscribe((translatedTabTitle) => {
      this.currentTabTitle = translatedTabTitle;
      this.constructTitle();
    });
  };

  private constructTitle = () => {
    if (this.mode === TitleMode.DEFAULT) {
      this.constructTitleWithOnPage();
      this.title.setTitle(this.constructedTitle);
    }

    if (this.mode === TitleMode.EVENT) {
      if (this.news) {
        this.constructTitleWithNews();
      } else {
        this.constructTitleWithOnPage();
        this.title.setTitle(this.constructedTitle);
      }
    }

    if (this.mode === TitleMode.PROGRESS) {
      this.constructTitleWithOnPage();
    }
  };

  // only on news change event
  private constructTitleWithNewsChange = () => {
    if (this.recentNewsTitle?.name !== undefined) {
      // has news and newsTitle is sent (name of the last message's user/room)
      if (this.recentNewsTitle.type === ResourceType.DIALOGUE) {
        this.constructedTitle = `(${this.news}) ${this.recentNewsTitle.name} ${marker(
          'has sent you a new message'
        )} | ${this.titlePart}`;
      }
      if (this.recentNewsTitle.type === ResourceType.ROOM) {
        this.constructedTitle = `(${this.news}) ${this.recentNewsTitle.name} ${marker(
          'has new messages'
        )} | ${this.titlePart}`;
      }
    } else {
      this.constructedTitle = `(${this.news}) ${marker('new messages')} | ${this.titlePart}`;
    }
  };

  // only on news change event
  private constructTitleWithNewInvites = (grantedNewRoomsCount?: number) => {
    this.constructedTitle = `(${grantedNewRoomsCount ?? this.news}) ${marker('New invite')} - ${
      this.currentTabTitle
    } | ${this.titlePart}`;
  };

  private constructTitleWithOnPage = () => {
    if (this.currentTabTitle) {
      this.constructedTitle = `${this.currentTabTitle} | ${this.titlePart}`;
    } else {
      this.constructedTitle = `${this.titlePart}`;
    }
  };

  private constructTitleWithNews = () => {
    if (this.recentNewsTitle?.name !== undefined) {
      // has news and newsTitle is sent (name of the last message's user/room)
      if (this.recentNewsTitle.type === ResourceType.DIALOGUE) {
        this.constructedTitle = `(${this.news}) ${this.recentNewsTitle.name} ${marker(
          'has sent you a new message'
        )} | ${this.titlePart}`;
      }
      if (this.recentNewsTitle.type === ResourceType.ROOM) {
        this.constructedTitle = `(${this.news}) ${this.recentNewsTitle.name} ${marker(
          'has new messages'
        )} | ${this.titlePart}`;
      }

      this.title.setTitle(this.constructedTitle);
    } else {
      return this.sidebarService.getGrantedRooms().then((rooms) => {
        const grantedRoomsCount = rooms.length;
        if (grantedRoomsCount) {
          this.constructTitleWithNewInvites(grantedRoomsCount);
          this.title.setTitle(this.constructedTitle);
        }
      });
    }
  };

  public stopTitleRotate = (): void => {
    this.news = 0; // reset news so when we re-enable the rotation by unfocusing the page, it won't start again automatically. It will start again when a new 'newsObserver' arrives
    this.rotateEnabled = false;

    this.mode = TitleMode.DEFAULT;
    this.constructTitle();
  };

  public startTitleRotate = (): void => {
    this.rotateEnabled = true;
  };
}
