import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import msgpack from 'msgpack-lite';
import { base64 } from 'rfc4648';
import { Subject } from 'rxjs';
import {
  MAX_CHAT_MESSAGE_LENGTH,
  MAX_SPLITTED_MESSAGE,
} from 'src/app/components/resource-page/chat-layout/chat-message-editor/message-consts';
import { DriveFile } from 'src/app/components/resource-page/drive-window/drive-layout/drive-file';
import { AppStorage } from '../app-storage';
import { ACCOUNT_CRYPTO_CONTEXT } from '../crypto/context/account/__init__';
import { AccountConfigDumpArgs, AccountConfigLoadArgs } from '../crypto/context/account/args';
import {
  NamingConventionEnum,
  RenameExistingFileDialogComponent,
} from '../dialogs/rename-existing-file-dialog/rename-existing-file-dialog.component';
import { NanoService } from '../nano/nano.service';
import { CacheService } from '../services/cache/cache.service';
import { DownloadManagerService, FileManagerType } from '../services/download-manager-service';
import { ServerRestApiService } from '../services/server-rest-api.service';
import { AuthService } from './auth.service';
import { chatInputStorageKey } from './chat-service-types';
import { PermissionService } from './permission.service';
import { ID } from './query-records/common-records';
import {
  DialogueDetail,
  LoadMessageDirection,
  MessageRecord,
  ReactionEnum,
  RoomDetail,
  RoomPermission,
} from './query-records/room-records';
import { messageQuery } from './querys';

export type UnsentMessageAttachmentLocation = { path: string; name: string; url: string };

export type UnsentMessageAttachment = {
  file: DriveFile;
  location: UnsentMessageAttachmentLocation;
  uploadProgress: number; // progress of all files (0-100)%
  uploadErrorState: boolean; // error state for every attachment
  uploadDoneState: boolean; // done state for every attachment
  peekDone: boolean;
};

export type EncryptableMessageObject = EncryptableMessageObjectV1 | EncryptableMessageObjectV2;

// [version, message, replyToId]
// has 47 byte length with 1 character message, when replied id attached
export type EncryptableMessageObjectV1 = [number, string, string?];

// has 53 byte length with 1 character message, when replied id attached
export type EncryptableMessageObjectV2 = {
  v: number; // version
  m: string; // string
  r?: string; // replyTo
};

export type ParsedMessageRecord = {
  message: string;
  replyTo?: ID;
};

export type UnsentMessage = {
  resourceId: ID;
  order: number;
  content: string;
  attachments: UnsentMessageAttachment[];

  uploadPath: string | null;

  // subscribe to live upload changes
  progressSubject: Subject<boolean>;

  sumUploadProgress: number; // overall progress 0-100

  messageSendDone: boolean;
  sendError: boolean; // message send error after upload

  remove: Function; // stop sending message (try to remove already uploaded files if can)

  sendWhenUploadDone: boolean; // flag, to send message after upload, you can unsend a message during upload

  replyTo?: string;
};

export type UnsentMessageStorageType = {
  content: string;
  order: number;
  replyTo?: string;
};

export type InitUnsentMessageParam = {
  resourceId: string;
  content: string;
  attachments?: DriveFile[];
  uploadPath?: string;
  order?: number;
  replyTo?: string;
};

export abstract class ResourceBase {
  protected authService: AuthService = this.injector.get(AuthService);
  protected nanoService: NanoService = this.injector.get(NanoService);
  protected downloadManagerService: DownloadManagerService =
    this.injector.get(DownloadManagerService);
  protected cacheService: CacheService = this.injector.get(CacheService);
  protected dialog: MatDialog = this.injector.get(MatDialog);
  protected serverRestApiService: ServerRestApiService = this.injector.get(ServerRestApiService);
  protected permissionService: PermissionService = this.injector.get(PermissionService);

  constructor(protected injector: Injector) {
    this.authService.onSelfAccountKeyringLoaded(() => {
      this.loadUnsentMessagesFromStorage().then((messages) => {
        for (const [resourceId, messageArray] of Object.entries(messages)) {
          if (!this.unsentMessages[resourceId]) {
            this.unsentMessages[resourceId] = [];
          }
          messageArray.forEach((msg) => {
            let initedUnsentMessage = this.initUnsentMessage({
              resourceId,
              content: msg.content,
              order: msg.order,
              ...(msg.replyTo ? { replyTo: msg.replyTo } : {}),
            });
            this.unsentMessages[resourceId].push(initedUnsentMessage);
          });
        }

        // send them to the server
        for (const [resourceId, messageArray] of Object.entries(this.unsentMessages)) {
          messageArray.forEach((msg) => {
            this.uploadAttachmentsThenPushUnsentMessageToServer(msg);
          });
        }
      });
    });
  }

  abstract getKeyring(resourceId);
  abstract getData(resourceId): Promise<Object>;
  abstract getLastMessages(resourceId): Promise<MessageRecord[]>;
  abstract getMoreMessages(
    resourceId: string,
    cursorMessageId: string,
    direction?: LoadMessageDirection,
    count?: number
  ): Promise<MessageRecord[]>;
  abstract sendTyping(resourceId): Promise<any>;
  abstract deleteMessage(resourceId, messageId): Promise<any>;
  abstract editMessage(resourceId, messageId, content, replyTo): Promise<any>;
  abstract seenMessage(resourceId, messageId): Promise<any>;
  abstract getDetail(resourceId): Promise<RoomDetail | DialogueDetail>;
  abstract getSeenIdsByMessage(resourceId, messageId): Promise<ID[]>;
  abstract reactMessage(resourceId, messageId, reaction: ReactionEnum | null): Promise<void>;
  abstract getChatPermissions(resourceId): Promise<RoomPermission>;

  public getPinnedMessages(resourceId): Promise<MessageRecord[]> {
    return this.getDetail(resourceId).then((detail) => {
      return this.getMessagesById(resourceId, detail.pinnedMessageIds);
    });
  }

  abstract pinMessage(resourceId, messageId): Promise<void>;
  abstract unpinMessage(resourceId, messageId): Promise<void>;

  /**
   * Decrypt messages in place, overriding the content of the references
   */
  protected decryptMessages(messages, variables): Promise<void[]> {
    let promises: Promise<void>[] = [];

    messages &&
      messages.forEach((msg) => {
        promises.push(
          this.decryptOneMessage(
            variables['roomId'] || variables['peerId'],
            msg['id'],
            msg['content']
          )
            .then((decryptedParsedMessageRecord) => {
              this.applyParsedMessageRecordOnMessageRef(msg, decryptedParsedMessageRecord);
              return;
            })
            .catch((e) => {
              msg['decryptionError'] = true;
              console.warn('message decryption error', e);
              msg['decryptionErrorMessage'] = e;
            })
        );
      });

    return Promise.all(promises).then(() => {
      return messages;
    });
  }

  /**
   * Apply all attribute from the parsedMessageRecord on the object
   */
  public applyParsedMessageRecordOnMessageRef(
    objectRef: { content; replyTo? } & any,
    parsedMessageRecord: ParsedMessageRecord
  ) {
    objectRef.content = parsedMessageRecord.message;
    if (parsedMessageRecord.replyTo) {
      objectRef.replyTo = parsedMessageRecord.replyTo;
    }
  }

  /**
   * We can not use the message ref, because live events only return with these parameters
   * All of these required for decryption (resourceId for keyring, messageId for timestamp, encryptedMessage for decryption)
   * After this we need to apply the returned values to the original ref: message, replyTo
   */
  public abstract decryptOneMessage(
    resourceId: ID,
    messageId: ID,
    encryptedMessage: Uint8Array
  ): Promise<ParsedMessageRecord>;

  protected abstract callSendMessageMutation(
    resourceId: ID,
    message: string,
    replyTo?: ID
  ): Promise<any>;

  protected abstract createAttachmentLocation(
    resourceId,
    name: string,
    path: string,
    previewSize?: number
  ): UnsentMessageAttachmentLocation;

  protected abstract getUnsentMessageStorageKeyPrefix(): string;

  public getMessagesById(resourceId, messageIds: ID[]): Promise<MessageRecord[]> {
    let resultMessages: (MessageRecord | null)[] = [];

    let notFoundPositions: number[] = [];
    let notFoundIds: ID[] = [];

    for (let i = 0; i < messageIds.length; i++) {
      resultMessages[i] = this.getOneMessageByIdFromCache(resourceId, messageIds[i]);
      if (!resultMessages[i]) {
        notFoundPositions.push(i);
        notFoundIds.push(messageIds[i]);
      }
    }

    if (notFoundIds.length == 0) {
      return Promise.resolve(resultMessages);
    }

    return this.queryMessagesById(resourceId, notFoundIds).then((messages: MessageRecord[]) => {
      for (let i = 0; i < notFoundIds.length; i++) {
        resultMessages[notFoundPositions[i]] = messages[i];
      }

      return resultMessages;
    });
  }

  protected abstract queryMessagesById(resourceId: ID, messageIds: ID[]): Promise<MessageRecord[]>;

  /**
   * Call getMessagesById if you need collection
   */
  public getOneMessageById(resourceId, messageId: ID): Promise<MessageRecord> {
    return this.getMessagesById(resourceId, [messageId]).then((allMessage) => {
      return allMessage[0];
    });
  }

  public saveMessageInStorage(
    resourceId: string,
    message: string,
    replyTo?: string
  ): Promise<void> {
    return this.encryptStoredMessage(message, replyTo).then((ecryptedMessage) => {
      AppStorage.setItem(this.makeMessageStorageKey(resourceId), ecryptedMessage);
      return;
    });
  }

  public removeMessageFromStorage(resourceId: string) {
    AppStorage.removeItem(this.makeMessageStorageKey(resourceId));
  }

  public loadMessageFromStorage(resourceId: string): Promise<ParsedMessageRecord> {
    let savedObject = AppStorage.getItem(this.makeMessageStorageKey(resourceId));
    if (!savedObject) return Promise.resolve({ message: '' });
    return this.decryptStoredMessage(savedObject);
  }

  protected unsentMessages: {
    [resourceId: string]: UnsentMessage[];
  } = {};

  protected lastUnsentMessageOrder: {
    [resourceId: string]: number;
  } = {};

  protected saveUnsentMessageInStorage(
    resourceId,
    order,
    content,
    replyTo?: string
  ): Promise<void> {
    return this.encryptStoredMessage(content, replyTo)
      .then((encryptedMessage) => {
        AppStorage.setItem(this.makeUnsentMessageStorageKey(resourceId, order), encryptedMessage);
        return;
      })
      .catch((e) => {
        console.warn('could not save the unsent message into storage', e);
        return;
      });
  }

  protected loadUnsentMessagesFromStorage(): Promise<{
    [resourceId: string]: UnsentMessageStorageType[];
  }> {
    let storageItems = AppStorage.getItemWithPrefix(this.getUnsentMessageStorageKeyPrefix());

    let decryptPromises: Promise<(UnsentMessageStorageType & { resourceId }) | null>[] = [];
    for (const [key, encryptedMessage] of Object.entries(storageItems)) {
      let resourceIdAndOrder = key.split(this.getUnsentMessageStorageKeyPrefix())[1];
      if (!resourceIdAndOrder) {
        continue;
      }

      let [resourceId, order] = resourceIdAndOrder.split(this.makeUnsentMessageStorageKeyDivider());
      if (order && order.length > 0) {
        decryptPromises.push(
          this.decryptStoredMessage(encryptedMessage)
            .then((decryptedMessage) => {
              return {
                content: decryptedMessage.message,
                order,
                resourceId,
                ...(decryptedMessage.replyTo ? { replyTo: decryptedMessage.replyTo } : {}),
              };
            })
            .catch((e) => {
              console.warn('could not decrypt unsent message', e);
              // could not decrypt the message, just skip, not a big problem
              return null;
            })
        );
      }
    }

    let result: {
      [resourceId: string]: UnsentMessageStorageType[];
    } = {};
    return Promise.all(decryptPromises).then((r) => {
      r.forEach((part) => {
        if (!result[part.resourceId]) {
          result[part.resourceId] = [];
        }
        result[part.resourceId].push({
          content: part.content,
          order: part.order,
          ...(part.replyTo ? { replyTo: part.replyTo } : {}),
        });
      });
      return result;
    });
  }

  protected removeUnsentMessageFromStorage(resourceId, order) {
    return AppStorage.removeItem(this.makeUnsentMessageStorageKey(resourceId, order));
  }

  protected makeMessageStorageKey(resourceId): string {
    return chatInputStorageKey + resourceId;
  }

  protected makeUnsentMessageStorageKey(resourceId: string, order: number): string {
    return (
      this.getUnsentMessageStorageKeyPrefix() +
      resourceId +
      this.makeUnsentMessageStorageKeyDivider() +
      order
    );
  }

  protected makeUnsentMessageStorageKeyDivider(): string {
    return '_';
  }

  protected encryptStoredMessage(message: string, replyTo?: string): Promise<string> {
    let messageObject = null;
    if (replyTo) {
      messageObject = this.convertMessageToEncrypedObject({ message, replyTo });
    } else {
      messageObject = this.convertMessageToEncrypedObject({ message });
    }

    return ACCOUNT_CRYPTO_CONTEXT.dump(
      new AccountConfigDumpArgs(messageObject, this.authService.getSelfAccountKeyring())
    ).then((dump) => {
      return base64.stringify(dump);
    });
  }

  protected decryptStoredMessage(encryptedMessage: string): Promise<ParsedMessageRecord> {
    let dump;
    try {
      dump = base64.parse(encryptedMessage);
    } catch (e) {
      console.warn('could not parse from string the encrypted stored message');
      return Promise.resolve({ message: '' });
    }

    return ACCOUNT_CRYPTO_CONTEXT.load(
      new AccountConfigLoadArgs(dump, this.authService.getSelfAccountKeyring())
    )
      .then((obj) => {
        return this.convertMessageFromEncryptableObject(obj);
      })
      .catch((e) => {
        console.warn('could not decrypt unsent message', e);
        return { message: '' };
      });
  }

  protected convertMessageToEncrypedObject(message: ParsedMessageRecord): Uint8Array {
    let msg: EncryptableMessageObjectV1 = [1, message.message];
    if (message.replyTo) {
      msg[2] = message.replyTo;
    }
    return msgpack.encode(msg);
  }

  /**
   * v2 message parsing
   */
  protected _convertMessageToEncrypedObject(message: ParsedMessageRecord): Uint8Array {
    let msg: EncryptableMessageObjectV2 = {
      v: 2,
      m: message.message,
    };
    if (message.replyTo) {
      msg.r = message.replyTo;
    }
    return msgpack.encode(msg);
  }

  protected convertMessageFromEncryptableObject(object: Uint8Array): ParsedMessageRecord {
    let decodedObject: EncryptableMessageObject = msgpack.decode(object);

    let version = decodedObject['v'] || decodedObject[0];

    if (version == 1) {
      let msg: EncryptableMessageObjectV1 = <EncryptableMessageObjectV1>decodedObject;
      if (msg[2]) {
        return {
          message: msg[1],
          replyTo: msg[2],
        };
      } else {
        return {
          message: msg[1],
        };
      }
    } else if (version == 2) {
      let msg: EncryptableMessageObjectV2 = <EncryptableMessageObjectV2>decodedObject;
      msg = <EncryptableMessageObjectV2>msg;
      if (msg.r) {
        return {
          message: msg.m,
          replyTo: msg.r,
        };
      } else {
        return {
          message: msg.m,
        };
      }
    } else {
      throw 'Not supported message version';
    }
  }

  public getUnsentMessages(resourceId: any): UnsentMessage[] {
    return this.unsentMessages[resourceId] || [];
  }

  protected saveUnsentMessageIntoQueue(unsentMessage: UnsentMessage) {
    this.unsentMessages[unsentMessage.resourceId]
      ? this.unsentMessages[unsentMessage.resourceId].push(unsentMessage)
      : (this.unsentMessages[unsentMessage.resourceId] = [unsentMessage]);
  }

  public sendMessage(
    resourceId,
    content: string,
    attachments: DriveFile[] = [],
    uploadPath: string | null = null,
    replyTo?: string
  ): Promise<UnsentMessage> {
    // do not send empty message
    if (content.length == 0 && attachments.length == 0) return;

    let preparedMessage = this.initUnsentMessage({
      resourceId,
      content,
      attachments,
      uploadPath,
      ...(replyTo ? { replyTo: replyTo } : {}),
    });
    // save this message into the unsent queue
    this.saveUnsentMessageIntoQueue(preparedMessage);
    this.removeMessageFromStorage(resourceId);

    return this.saveUnsentMessageInStorage(
      preparedMessage.resourceId,
      preparedMessage.order,
      preparedMessage.content,
      preparedMessage.replyTo
    ).then(() => {
      // upload all attachments before message send
      let uploadRequests = this.uploadAttachmentsThenPushUnsentMessageToServer(preparedMessage);

      // cleanup attachments on remove
      preparedMessage.remove = () => {
        preparedMessage.sendWhenUploadDone = false;
        if (uploadRequests) {
          uploadRequests.forEach((req) => {
            this.nanoService.removeUploadSession(req.requestId);
            this.downloadManagerService.removeFromFileList(req);
          });
        }

        this.clearUnsentMessageFromQueue(preparedMessage);
        this.removeUnsentMessageFromStorage(preparedMessage.resourceId, preparedMessage.order);
      };

      return preparedMessage;
    });
  }

  protected initUnsentMessage(initParam: InitUnsentMessageParam): UnsentMessage {
    if (!this.lastUnsentMessageOrder[initParam.resourceId]) {
      this.lastUnsentMessageOrder[initParam.resourceId] = 0;
    }

    if (initParam.order && this.lastUnsentMessageOrder[initParam.resourceId] < initParam.order) {
      this.lastUnsentMessageOrder[initParam.resourceId] = initParam.order;
    }

    initParam = Object.assign(
      {
        uploadPath: null,
        attachments: [],
        order: initParam.order || ++this.lastUnsentMessageOrder[initParam.resourceId],
      },
      initParam
    );

    let msg: UnsentMessage = {
      resourceId: initParam.resourceId,
      content: initParam.content,
      order: initParam.order,
      uploadPath: initParam.uploadPath,
      attachments: [],

      progressSubject: new Subject<boolean>(),

      sumUploadProgress: 0,

      sendError: false,
      messageSendDone: false,

      remove: () => {},

      sendWhenUploadDone: true,
      ...(initParam.replyTo ? { replyTo: initParam.replyTo } : {}),
    };

    initParam.attachments.forEach((f, index) => {
      msg.attachments[index] = {
        file: f,
        location: {
          name: undefined,
          path: undefined,
          url: undefined,
        },
        uploadDoneState: false,
        uploadErrorState: false,
        uploadProgress: 0,
        peekDone: false,
      };
    });

    // set default cleanup
    msg.remove = () => {
      this.clearUnsentMessageFromQueue(msg);
      this.removeUnsentMessageFromStorage(msg.resourceId, msg.order);
    };

    return msg;
  }

  protected clearUnsentMessageFromQueue(unsentMessage: UnsentMessage) {
    unsentMessage.sendWhenUploadDone = false;

    // remove successful message from the unsent list
    if (!this.unsentMessages[unsentMessage.resourceId]) {
      console.error('could not find prepared message: 1', unsentMessage);
      return;
    }
    let index = this.unsentMessages[unsentMessage.resourceId].indexOf(unsentMessage);
    if (index < 0) {
      console.error('could not find prepared message: 2', unsentMessage);
      return;
    }

    this.unsentMessages[unsentMessage.resourceId].splice(index, 1);
  }

  private startSendingRequest(unsentMessage: UnsentMessage) {
    return new Promise((resolve, reject) => {
      let tryToSendMessageToServer = (timeout = 1000) => {
        if (timeout > 32000) {
          timeout = 32000;
        }

        if (!unsentMessage.sendWhenUploadDone) {
          return;
        }

        this.callSendMessageMutation(
          unsentMessage.resourceId,
          unsentMessage.content,
          unsentMessage.replyTo
        )
          .then((res) => {
            unsentMessage.messageSendDone = true;
            unsentMessage.sendError = false;

            unsentMessage.remove();
            unsentMessage.progressSubject.next(true);

            return resolve(res);
          })
          .catch((err) => {
            console.warn('could not send message', err);
            unsentMessage.sendError = true;
            unsentMessage.progressSubject.next(false);
            setTimeout(() => {
              tryToSendMessageToServer(timeout * 2);
            }, timeout);
          });
      };
      tryToSendMessageToServer();
      return;
    });
  }

  /**
   * Add the attachment urls to the message. When the message's length is longer than the `maxLengthPerMessage`,
   * it will be splitted into multiple messages. Maximum `maxMessageSlices` will be returned.
   *
   * @param msg message
   * @param maxLengthPerMessage maximum length of one message which will be returned
   * @param maxMessageSlices maximum amount of message which will be retuned
   * @return Array of messages
   *
   * @throw Error when the message can not fit into the maxMessageSlices
   * @throw Error when one of the url is longer than the `maxLengthPerMessage`, so message slice can not be generated
   */
  private createConcatedMessageWithAttachmentURLs(
    originalMessage: UnsentMessage,
    maxLengthPerMessage: number,
    maxMessageSlices
  ): UnsentMessage[] {
    let originalContent = originalMessage.content;

    let messages = [];

    // split message into multiple message if needed
    while (originalContent.length > 0) {
      let stringChunk = originalContent.substring(0, MAX_CHAT_MESSAGE_LENGTH);
      originalContent = originalContent.substring(MAX_CHAT_MESSAGE_LENGTH);
      messages.push(stringChunk);
    }

    // add attachment urls to the message
    let currMessageIndex = Math.max(0, messages.length - 1); // starting msg to concat

    for (let i = 0; i < originalMessage.attachments.length; i++) {
      if (originalMessage.attachments[i].location.url.length > maxLengthPerMessage) {
        console.error('Attachment url is too long to fit in one message');
        /*throw this.translateService.instant(
          marker('Attachment url is too long to fit in one message')
        );*/
      }

      let concatedString;
      if (messages[currMessageIndex]) {
        concatedString =
          messages[currMessageIndex] + '\n' + originalMessage.attachments[i].location.url;
      } else {
        concatedString = originalMessage.attachments[i].location.url;
      }

      if (concatedString.length <= maxLengthPerMessage) {
        messages[currMessageIndex] = concatedString;
      } else {
        currMessageIndex++;
        if (currMessageIndex == maxMessageSlices) {
          console.error(
            'Message is too long with the attachments urls, splitting the message into acceptable chunks'
          );
          // currMessageIndex starts at 0, so this means, out of range
          /*throw this.translateService.instant(
            marker(
              'Message is too long with the attachments urls, splitting the message into acceptable chunks would create too many messages'
            )
          );*/
        }
        messages[currMessageIndex] = originalMessage.attachments[i].location.url;
      }
    }

    let result: UnsentMessage[] = [];

    messages.forEach((msgSlice) => {
      result.push(
        this.initUnsentMessage({
          content: msgSlice,
          resourceId: originalMessage.resourceId,
          ...(originalMessage.replyTo ? { replyTo: originalMessage.replyTo } : {}),
        })
      );
    });

    return result;
  }

  private isMessageReadyToPush(unsentMessage: UnsentMessage) {
    for (let i = 0; i < unsentMessage.attachments.length; i++) {
      if (!unsentMessage.attachments[i].uploadDoneState || !unsentMessage.attachments[i].peekDone)
        return false;
    }
    return unsentMessage.sendWhenUploadDone;
  }

  private calcOverallProgressOnUnsentMessageUpload(unsentMessage: UnsentMessage) {
    if (unsentMessage.attachments.length == 0) return 100;

    let sumProgress = 0;
    for (let i = 0; i < unsentMessage.attachments.length; i++) {
      sumProgress += unsentMessage.attachments[i].uploadProgress;
    }
    return Math.floor(sumProgress / unsentMessage.attachments.length);
  }

  private uploadAttachmentsThenPushUnsentMessageToServer(
    unsentMessage: UnsentMessage
  ): FileManagerType[] {
    let sendStarted: boolean = false;
    let uploadRequests: FileManagerType[] = [];

    let sendMessageWhenReady = async () => {
      if (!sendStarted && this.isMessageReadyToPush(unsentMessage)) {
        sendStarted = true;

        let messages = this.createConcatedMessageWithAttachmentURLs(
          unsentMessage,
          MAX_CHAT_MESSAGE_LENGTH,
          MAX_SPLITTED_MESSAGE
        );

        this.removeUnsentMessageFromStorage(unsentMessage.resourceId, unsentMessage.order);
        unsentMessage.remove();

        for (let i = 0; i < messages.length; i++) {
          this.saveUnsentMessageIntoQueue(messages[i]);
          await this.saveUnsentMessageInStorage(
            messages[i].resourceId,
            messages[i].order,
            messages[i].content,
            messages[i]?.replyTo
          );
        }
        for (let i = 0; i < messages.length; i++) {
          await this.startSendingRequest(messages[i]);
        }
      }
    };

    if (unsentMessage.attachments.length == 0) {
      sendMessageWhenReady();
      return uploadRequests;
    }

    unsentMessage.attachments.forEach((attachment) => {
      // upload progress(%) calculation

      if (attachment.file.resourceId) {
        // drive share url reference
        attachment.uploadProgress = 100;
        attachment.uploadErrorState = false;
        attachment.uploadDoneState = true;
        attachment.location = this.createAttachmentLocation(
          unsentMessage.resourceId,
          attachment.file.fullName,
          attachment.file.path
        );
        unsentMessage.sumUploadProgress =
          this.calcOverallProgressOnUnsentMessageUpload(unsentMessage);
        unsentMessage.progressSubject.next(false);

        this.peekUpdateAttachment(unsentMessage.resourceId, attachment).then(() => {
          attachment.peekDone = true;
          sendMessageWhenReady();
        });

        return;
      }

      // do the upload
      let uploadFile = (overwrite: boolean, alias?: string, autoName?: boolean) => {
        // path could be null, {{filename}}, or {{folder}}/{{filename}}
        let path = unsentMessage.uploadPath;
        if (path !== null) {
          if (path.length > 0) {
            path += '/';
          }

          path += alias || attachment.file.fullName;
        }

        uploadRequests.push(
          this.downloadManagerService.upload({
            resourceId: unsentMessage.resourceId,
            path,
            file: <File>attachment.file.rawData,
            overwrite,
            alias,
            autoName,
            doneCallback: (name, path) => {
              // calc progress
              if (path && attachment.file.path != path) {
                attachment.file.path = path;
              }
              if (name && attachment.file.name != name) {
                attachment.file.updateName(name);
              }

              attachment.uploadProgress = 100;
              attachment.uploadErrorState = false;
              attachment.uploadDoneState = true;
              attachment.location = this.createAttachmentLocation(
                unsentMessage.resourceId,
                name,
                path
              );
              unsentMessage.sumUploadProgress =
                this.calcOverallProgressOnUnsentMessageUpload(unsentMessage);
              unsentMessage.progressSubject.next(false);

              if (unsentMessage.sendWhenUploadDone) {
                // save message with the updated location into the locastorage
                let tmpSaveContent = unsentMessage.content;
                unsentMessage.attachments.forEach((currAttachment) => {
                  tmpSaveContent +=
                    '\n' +
                    (currAttachment.location.url ||
                      this.createAttachmentLocation(unsentMessage.resourceId, '', '').url);
                });
                this.saveUnsentMessageInStorage(
                  unsentMessage.resourceId,
                  unsentMessage.order,
                  tmpSaveContent,
                  unsentMessage.replyTo
                ).then(() => {
                  this.peekUpdateAttachment(unsentMessage.resourceId, attachment).then(() => {
                    attachment.peekDone = true;
                    sendMessageWhenReady();
                  });
                });
              }
            },
            errorCallback: (e) => {
              attachment.uploadErrorState = true;
              unsentMessage.progressSubject.next(false);

              if (e.error == 4218) {
                const dialogRef = this.dialog.open(RenameExistingFileDialogComponent, {
                  data: { name: attachment.file.fullName },
                });

                dialogRef.afterClosed().subscribe((result) => {
                  if (result && result.name && result.name.length > 0) {
                    attachment.uploadErrorState = false;
                    unsentMessage.progressSubject.next(false);
                    let autoName = result.namingConvention === NamingConventionEnum.AUTO_NAME;
                    uploadFile(result.name == attachment.file.name, result.name, autoName);
                  }
                });
              } else {
                console.error('instant file upload error', e, attachment);
              }
            },
            progressCallback: (curr, max) => {
              // calc progress
              attachment.uploadProgress = Math.floor((curr * 100) / max);
              attachment.uploadErrorState = false;

              unsentMessage.sumUploadProgress =
                this.calcOverallProgressOnUnsentMessageUpload(unsentMessage);
              unsentMessage.progressSubject.next(false);
            },
            freezedMTime: attachment.file.rawData['fromClipboard']
              ? attachment.file.mtime
              : undefined,
          })
        );
      };

      uploadFile(false);
    });

    return uploadRequests;
  }

  /**
   * Update the attachment location setting its resolution, by peeking the file
   * If the peek api is not available just resolve
   * If the nano is still creating the preview, set the resolution to a default square and retry
   * When the nano is done, set the correct preview resolution
   * Long preview generation can cause a timeout for this function
   * @param resourceId
   * @param attachment
   * @returns
   */
  protected abstract peekUpdateAttachment(
    resourceId: ID,
    attachment: UnsentMessageAttachment
  ): Promise<void>;

  protected getOneMessageByIdFromCache(resourceId, messageId): MessageRecord | null {
    return this.cacheService.getCacheDataByRequest({
      query: messageQuery,
      variables: { resourceId, messageId },
    });
  }
}
