import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateRoomEventSubscriptionHandler } from './ws-sub-room-event-create-handler';
import { onDeleteRoomEventSubscriptionHandler } from './ws-sub-room-event-delete-handler';
import { onModifyRoomEventSubscriptionHandler } from './ws-sub-room-event-modify-handler';

export let workspaceSubscriptionRoomEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateRoomEventSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyRoomEventSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteRoomEventSubscriptionHandler,
};
