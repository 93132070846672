import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SeenDialogComponent } from 'src/app/shared/dialogs/seen-dialog/seen-dialog.component';
import { AccountService } from 'src/app/shared/server-services/account.service';
import { AccountAvatarRecord } from 'src/app/shared/server-services/query-records/account-records';

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.scss'],
  animations: [
    trigger('growAnimation', [
      transition(':enter', [style({ height: 0 }), animate('0.1s ease-out', style({ height: 20 }))]),
      transition(':leave', [style({ height: 20 }), animate('0.1s ease-in', style({ height: 0 }))]),
    ]),
  ],
})
export class MessageDetailsComponent implements OnInit, OnChanges {
  public AVATAR_COUNT = 5;

  @Input() seenBy: string[];
  @Input() runAnimation: boolean = false;
  public accountAvatarList: AccountAvatarRecord[] = [];

  constructor(private accountService: AccountService, private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.seenBy) {
      if (this.seenBy?.length > 0) {
        const slicedSeenBy = this.seenBy.slice(0, this.AVATAR_COUNT);
        this.accountService.getAccounts(slicedSeenBy).then((res) => {
          this.accountAvatarList = Object.values(res);
        });
      } else {
        this.accountAvatarList = [];
      }
    }
  }

  public openSeenDialog = () => {
    let dialogRef = this.dialog.open(SeenDialogComponent, {
      data: { seenBy: this.seenBy },
      hasBackdrop: true,
      disableClose: false,

      width: '90%',
      maxWidth: '400px',
      height: '80%',
      maxHeight: '550px',
      autoFocus: false,
    });
  };

  ngOnInit(): void {}
}
