import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDoubleClick]',
})
export class DoubleClickDirective {
  @Output() readonly doubleClick: EventEmitter<any> = new EventEmitter<any>();

  private clicked = false;

  constructor() {}

  @HostListener('click', ['$event']) onClick($event) {
    if (this.clicked) {
      this.clicked = false;
      this.doubleClick.emit($event);
    } else {
      this.clicked = true;
      setTimeout(() => {
        this.clicked = false;
      }, 500);
    }
  }
}
