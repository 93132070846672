<div id="room-preview">
  <app-sidebar-room *ngIf="data.resource.type === 'room'" [sidebarResourceData]="data.resource"></app-sidebar-room>
  <app-sidebar-dialogue *ngIf="data.resource.type === 'dialogue'" [sidebarResourceData]="data.resource"></app-sidebar-dialogue>
</div>
<mat-divider></mat-divider>
<mat-action-list>
  <ng-container *ngIf="groups && groups.length > 0">
    <mat-list-item *ngFor="let g of groups" (click)="sheetActionClick(g)">
      <span matListItemIcon *ngIf="data.group === null || data.group.id !== g.id" class="mdi mdi-account-group"></span>
      <mat-icon matListItemIcon *ngIf="data.group && data.group.id === g.id">done</mat-icon>
      <h3 matListItemTitle>{{ g.name }}</h3>
    </mat-list-item>
    <mat-list-item (click)="sheetActionClick(null)">
      <span matListItemIcon *ngIf="data.group !== null" class="mdi mdi-account-group"></span>
      <mat-icon matListItemIcon *ngIf="data.group === null">done</mat-icon>
      <h3 matListItemTitle translate>Ungrouped</h3>
    </mat-list-item>
  </ng-container>
</mat-action-list>
