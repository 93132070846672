import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { showUserInfoObserver } from 'src/app/shared/components/user-info-menu/user-info-menu.component';
import { AccountService } from 'src/app/shared/server-services/account.service';
import { DialogueService } from 'src/app/shared/server-services/dialogue.service';
import { AccountAvatarRecord } from 'src/app/shared/server-services/query-records/account-records';
import { DialogueRecord } from 'src/app/shared/server-services/query-records/room-records';
import { SidebarResource } from 'src/app/shared/server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionDialogueEventRecord } from 'src/app/shared/server-services/query-records/workspace-records';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from 'src/app/shared/server-services/subscription-event';
import { SubscriptionService } from 'src/app/shared/server-services/subscription.service';
import { PageTabService, PageTypes, SubPageTypes } from 'src/app/shared/services/page-tab.service';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { sideMenuActiveObserver } from 'src/app/shared/shared-observers';
import { moveIntoOtherGroupObserver } from '../sidebar.component';

@Component({
  selector: 'app-sidebar-dialogue',
  templateUrl: './sidebar-dialogue.component.html',
  styleUrls: ['./sidebar-dialogue.component.scss'],
})
export class SidebarDialogueComponent implements OnInit, OnDestroy {
  @ViewChild('sidebarAvatar', { read: ElementRef })
  private sidebarAvatarRef: ElementRef;
  @Input() sidebarResourceData: SidebarResource;
  @Input() isReordering: boolean = false;
  public avatar: AccountAvatarRecord;
  public dialogueRecord: DialogueRecord;

  public name: string;
  public news: boolean;
  public error: boolean = false;

  public selectedMenu = false;

  public isVisible: boolean = false;

  private onRouterChange = (res) => {
    this.selectedMenu = this.sidebarResourceData.id == res?.params?.id;
  };

  constructor(
    private routerHandler: RouterHandler,
    private subscriptionService: SubscriptionService,
    private accountService: AccountService,
    private dialogueService: DialogueService,
    private translateService: TranslateService,
    private pageTabService: PageTabService
  ) {
    this.routerHandler.subscribeAll(this.onRouterChange);

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.updateData
    );
  }

  ngOnInit(): void {
    this.dialogueService.getData(this.sidebarResourceData.id).then((dialogue) => {
      this.dialogueRecord = dialogue;
      this.refreshState();
    });
  }

  private updateData = (event: WorkspaceSubscriptionDialogueEventRecord) => {
    if (this.sidebarResourceData.id == event.id) {
      this.refreshState();
    }
  };

  public visibilityChange(visible: boolean) {
    if (!this.avatar && visible) {
      this.loadUserData();
    }

    if (this.isVisible !== visible) {
      this.isVisible = visible;
    }
  }

  private loadUserData() {
    this.accountService
      .getAccount(this.sidebarResourceData.id)
      .then((account) => {
        this.avatar = account;

        if (account.deleted) {
          this.error = true;
          this.name = this.translateService.instant(marker('Deleted user'));
        } else {
          this.name = account.avatarName;
        }
      })
      .catch((e) => {
        console.error('could not load the user', e);
        this.name = marker('Error');
        this.error = true;
      });
  }

  /**
   * Refresh all the view-based variables to trigger changes
   */
  private refreshState() {
    this.news = this.sidebarResourceData?.hasNewMessages;
    // this.news =
    //   (this.dialogueRecord.seenMessageId == null && this.dialogueRecord.topMessageId !== null) ||
    //   this.dialogueRecord.seenMessageId < this.dialogueRecord.topMessageId;
  }

  ngOnDestroy(): void {
    this.routerHandler.unsubscribeAll(this.onRouterChange);
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.DIALOGUE_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.updateData
    );
  }

  hideSidebar(): void {
    sideMenuActiveObserver.next(false);
  }

  selected(e) {
    // room/{{ sidebarResourceData.id }}/chat/room

    if (this.isReordering) return;

    if (e.pointerType == 'mouse' && e.button == 1) {
      console.log('middle');
      e.preventDefault();

      this.hideSidebar();
      this.pageTabService.openInNewTab({
        page: PageTypes.PRIVATE_CHAT,
        subpage: SubPageTypes.CHAT,
        id: this.sidebarResourceData.id,
        fragment: {},
      });
    }
  }

  public moveIntoOtherGroup(): void {
    moveIntoOtherGroupObserver.next(this.sidebarResourceData);
  }

  public showUserInfo = (event) => {
    event.preventDefault();

    showUserInfoObserver.next({
      avatarInfo: this.avatar,
      originElementRef: this.sidebarAvatarRef,
    });
  };
}
