export enum SubscriptionServiceEvent {
  ACCOUNT_EVENT = 'accountEvent',
  ACCOUNT_CONTACT_EVENT = 'accountContactEvent',
  ACCOUNT_TRUST_EVENT = 'accountTrustEvent',
  DIALOGUE_EVENT = 'dialogueEvent',
  DIALOGUE_MESSAGE_EVENT = 'dialogueMessageEvent',
  RESOURCE_GROUP_EVENT = 'resourceGroupEvent',
  ROOM_EVENT = 'roomEvent',
  ROOM_MESSAGE_EVENT = 'roomMessageEvent',
  ROOM_PERMISSION_EVENT = 'roomPermissionsEvent',
  ROOM_ACCOUNT_PERMISSION_EVENT = 'roomAccountPermissionsEvent',
  NANO_WAITING_EVENT = 'nanoWaitingEvent',
  ACCOUNT_OFFER_EVENT = 'accountOfferEvent',
  DIALOGUE_TYPING_EVENT = 'dialogueTypingEvent',
  ROOM_TYPING_EVENT = 'roomTypingEvent',
}

/**
 * Currently all of the subscriptions implement the ChangeEvent graphql interface
 * so they can be divided by CMD (create, modify, delete). On subcribe we can separate them
 * or just subscribe all of them using ALL
 */
export enum SubscriptionServiceEventType {
  CREATE = 'C',
  MODIFY = 'M',
  DELETE = 'D',
  ALL = 'A',
}
