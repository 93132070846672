import { AbstractSelfAccountKeyring } from '../../keyring/account_base';
import { PeerAccountKeyring } from '../../keyring/account_peer';
import { AbstractRoomKeyring } from '../../keyring/room_base';
import { AbstractConfigDumpArgs, AbstractConfigLoadArgs } from '../base';

export class NanoConfigLoadArgs extends AbstractConfigLoadArgs {
  public self_kr;
  public peer_kr;
  public room_kr;
  public timestamp;

  constructor(
    cipher: Uint8Array,
    self_kr: AbstractSelfAccountKeyring,
    peer_kr: PeerAccountKeyring,
    room_kr: AbstractRoomKeyring,
    timestamp: number
  ) {
    super(cipher);
    this.self_kr = self_kr;
    this.peer_kr = peer_kr;
    this.room_kr = room_kr;
    this.timestamp = timestamp;
  }
}

export class NanoConfigDumpArgs extends AbstractConfigDumpArgs {
  public plain;
  public self_kr;
  public peer_kr;
  public room_kr;
  public timestamp;

  constructor(
    plain: Uint8Array,
    self_kr: AbstractSelfAccountKeyring,
    peer_kr: PeerAccountKeyring,
    room_kr: AbstractRoomKeyring,
    timestamp: number
  ) {
    super();
    this.plain = plain;
    this.self_kr = self_kr;
    this.peer_kr = peer_kr;
    this.room_kr = room_kr;
    this.timestamp = timestamp;
  }
}
