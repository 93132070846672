<div id="file-manager-container">
  <div id="title-row">
    <h1 id="title" translate>File Manager</h1>
    <button mat-icon-button (click)="removeReady()" [matTooltip]="'Clear finished files' | translate">
      <mat-icon>remove_done</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div id="file-manager-scroll">
    <!-- flex-direction: column reversed -->
    <div *ngFor="let file of fileList; let i = index">
      <app-download-manager-file [file]="file" [canResumeDownload]="canResumeDownload"></app-download-manager-file>
      <mat-divider *ngIf="i !== 0"></mat-divider>
    </div>
    <div *ngFor="let collection of collectionList; let i = index">
      <app-download-manager-collection [collection]="collection"></app-download-manager-collection>
      <mat-divider *ngIf="i !== 0 || fileList.length"></mat-divider>
    </div>
  </div>
</div>
