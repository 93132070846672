import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';
import { PaymentService } from 'src/app/shared/server-services/payment.service';
import { RecurringOrderRecord } from 'src/app/shared/server-services/query-records/nano-records';
import { PaymentShopDataRecord } from 'src/app/shared/server-services/query-records/payment-records';
import { cancelRecurringOrderQuery } from 'src/app/shared/server-services/querys';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ServerRestApiService } from 'src/app/shared/services/server-rest-api.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-recurring-order-detail-dialog',
  templateUrl: './recurring-order-detail-dialog.component.html',
  styleUrls: ['./recurring-order-detail-dialog.component.scss'],
})
export class RecurringOrderDetailDialogComponent extends NanoDialogBaseComponent<RecurringOrderDetailDialogComponent> {
  shopData: PaymentShopDataRecord;
  loaded: boolean = false;
  reveal: boolean = false;
  isHunCurrency: boolean = false;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    public recurringOrder: RecurringOrderRecord,
    private dialogService: DialogService,
    private serverRestApi: ServerRestApiService,
    private paymentService: PaymentService,
    private snackbarService: SnackBarService
  ) {
    super(injector);

    this.isHunCurrency = recurringOrder.currency == 'HUF';

    this.paymentService.getPaymentShopData().then((res) => {
      this.shopData = res.response;
      this.loaded = true;
    });
  }

  cancelRecurringOrder(): void {
    this.dialogService
      .openConfirmDialog(
        marker('Cancel recurring payment'),
        marker('Are you sure you want to cancel this recurring payment?')
      )
      .subscribe((confirm) => {
        if (!confirm) return;

        this.serverRestApi
          .mutate({
            query: cancelRecurringOrderQuery,
            variables: { orderId: this.recurringOrder.id },
          })
          .then((res) => {
            this.snackbarService.showSnackbar(marker('Recurring payment canceled!'));

            this.dialogRef.close({ canceled: true });
          })
          .catch((err) => console.error('Recurring payment cancel error', err));
      });
  }
}
