import { Component, INJECTOR, Inject, Injector } from '@angular/core';
import { NanoDialogBaseComponent } from '../nano-base-dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../two-factor-dialog/two-factor-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export enum DocumentType {
  WORD = '.odt',
  EXCEL = '.ods',
  PRESENTATION = '.odp',
}

@Component({
  selector: 'app-create-new-file-with-editor-dialog',
  templateUrl: './create-new-file-with-editor-dialog.component.html',
  styleUrls: ['./create-new-file-with-editor-dialog.component.scss'],
})
export class CreateNewFileWithEditorDialogComponent extends NanoDialogBaseComponent<CreateNewFileWithEditorDialogComponent> {
  public fileName: string = '';
  public documentType: DocumentType = DocumentType.WORD;
  public allType = {
    WORD: DocumentType.WORD,
    EXCEL: DocumentType.EXCEL,
    PRESENTATION: DocumentType.PRESENTATION,
  };
  public customExt: boolean = false;

  createFileFormGroup = new FormGroup({
    fileName: new FormControl<string | null>(
      this.translate.instant('New Document') + ' - ' + Date.now(),
      [Validators.required]
    ),
    customExt: new FormControl<boolean>(false),
  });

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public translate: TranslateService
  ) {
    super(injector);
  }

  public submit() {
    this.dialogRef.close(
      this.createFileFormGroup.get('fileName').value +
        (this.createFileFormGroup.get('customExt').value ? '' : this.documentType)
    );
  }

  public setExt(ext: DocumentType) {
    this.documentType = ext;
  }
}
