<h2 mat-dialog-title translate>Filtered filename</h2>
<form [formGroup]="filteredFileNameGroup" (submit)="submit()">
  <mat-dialog-content class="mat-typography">
    <div>
      <p class="dialog-desc" translate>This name can not be used on the selected operating system. Please use the suggested name below, or choose a different name.</p>
      <mat-form-field class="full-width">
        <mat-label><span translate>File name</span></mat-label>
        <input type="text" matInput name="file-name" formControlName="fileName" />
        <mat-error *ngIf="filteredFileNameGroup.get('fileName').hasError('required')" translate> Please enter a name </mat-error>
        <mat-error *ngIf="filteredFileNameGroup.get('fileName').hasError('maxlength')" translate> Name is too long </mat-error>
        <mat-error *ngIf="filteredFileNameGroup.get('fileName').hasError('windowsCharacterError')" translate> Invalid character in name </mat-error>
        <mat-error *ngIf="filteredFileNameGroup.get('fileName').hasError('filteredNameError')" translate> Invalid word in name </mat-error>
        <mat-error *ngIf="filteredFileNameGroup.get('fileName').hasError('trailingInvalidCharactersError')" translate> Invalid suffix in name </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-button color="primary" [disabled]="!filteredFileNameGroup.valid"><span translate>Create</span></button>
  </mat-dialog-actions>
</form>
