import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { RemainingTimeHumanizer } from 'src/app/shared/humanizers/remaining-time-humanizer';
import { NanoService } from 'src/app/shared/nano/nano.service';
import { RoomRecord } from 'src/app/shared/server-services/query-records/room-records';
import { RoomService } from 'src/app/shared/server-services/room.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import {
  CollectionManagerType,
  CollectionObject,
  DownloadManagerService,
  FileManagerStatus,
} from 'src/app/shared/services/download-manager-service';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { scrollToFileObserver } from '../../resource-page/drive-window/drive-layout/drive-layout.component';

@Component({
  selector: 'app-download-manager-collection',
  templateUrl: './download-manager-collection.component.html',
  styleUrls: [
    './download-manager-collection.component.scss',
    './download-manager-collection-mat.component.scss',
  ],
})
export class DownloadManagerCollectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() collection: CollectionManagerType;

  public roomData: RoomRecord;
  public isOpen = false;

  public sumRawTimeNeeded: string = '';
  public sumSpeed = 0;

  private timeUpdater = null;

  constructor(
    private dialogService: DialogService,
    private downloadManagerService: DownloadManagerService,
    private nanoService: NanoService,
    private roomService: RoomService,
    private routerHandler: RouterHandler,
    private bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.roomService.getRoom(this.collection.resourceId).then((data) => {
      this.roomData = data;
    });

    this.timeUpdater = setInterval(() => {
      if (
        this.collection &&
        this.collection.status !== FileManagerStatus.DONE &&
        this.collection.status !== FileManagerStatus.PAUSED
      ) {
        console.log('update on ', this.collection.collectionId);
        this.calcTimeAndSpeed();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timeUpdater);
  }

  ngOnChanges(changes: any): void {
    console.log('changes', changes);
  }

  public calcTimeAndSpeed = () => {
    if (this.collection && this.collection?.inProgressParts?.length > 0) {
      let speed = 0;
      for (const onePart of this.collection.inProgressParts) {
        if (onePart.status === 'inprogress') {
          if (onePart.speed) {
            speed += onePart.speed;
          }
        }
      }
      this.sumSpeed = speed;
      if (speed) {
        this.sumRawTimeNeeded = this.humanizeTime(
          (this.collection.maxSize * (100 - this.collection.progress)) / 100 / speed
        );
      }
    }
  };

  openDetails = () => {
    this.isOpen = true;
  };
  closeDetails = () => {
    this.isOpen = false;
  };

  private humanizeTime(timeNeeded: number): string {
    return RemainingTimeHumanizer.humanize(timeNeeded);
  }

  public pause() {
    this.downloadManagerService.pauseCollection(this.collection);
  }

  public resume() {
    if (this.collection.status === FileManagerStatus.PAUSED) {
      this.collection.status = FileManagerStatus.IN_PROGRESS;

      this.downloadManagerService.resumeCollection(this.collection);
    }
  }

  public resumeWithError() {
    this.downloadManagerService.resumeCollectionWithError(this.collection);
  }

  public remove() {
    this.dialogService
      .openConfirmDialog(
        marker('Remove downloading file'),
        marker('Are you sure you want to stop the file progress?') +
          '( ' +
          this.collection.path +
          ' )'
      )
      .subscribe((response) => {
        if (response) {
          this.downloadManagerService.removeCollection(this.collection);
        }
      });
  }

  public openPath(part?: CollectionObject) {
    if (this.isUploading) return;

    let fragment = this.routerHandler.getRoute().fragment;
    if (part) {
      fragment['path'] = part.path.split('/').slice(0, -1).join('/');
      fragment['file'] = part.file.name;
    } else {
      fragment['path'] = this.collection.path.split('/').slice(0, -1).join('/');
      fragment['file'] = this.collection.path.split('/').slice(-1)[0];
    }

    if (fragment?.['from']) {
      delete fragment['from'];
    }
    this.routerHandler.navigate(['/room/', this.collection.resourceId, 'drive'], {
      fragment: this.routerHandler.fragmentToRaw(fragment),
    });
    if (fragment['file']) {
      scrollToFileObserver.next(fragment['file']);
    }

    this.bottomSheet.dismiss();
  }

  get isUploading(): boolean {
    return this.collection.status !== FileManagerStatus.DONE && this.collection.direction == 'up';
  }
}
