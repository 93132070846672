import { AccountConfigDumpArgs, AccountConfigLoadArgs } from '../context/account/args';
import { ACCOUNT_CRYPTO_CONTEXT } from '../context/account/__init__';
import { SelfAccountKeyring } from '../keyring/account_self';

export class ResourceGroupCrypto {
  public static encrypt(data: string, self_kr: SelfAccountKeyring): Promise<Uint8Array> {
    return ACCOUNT_CRYPTO_CONTEXT.dump(
      new AccountConfigDumpArgs(new TextEncoder().encode(data), self_kr)
    ).then((encryptedData) => {
      return encryptedData;
    });
  }

  public static decrypt(data: Uint8Array, self_kr: SelfAccountKeyring): Promise<string | void> {
    return ACCOUNT_CRYPTO_CONTEXT.load(new AccountConfigLoadArgs(data, self_kr))
      .then((decryptedData) => {
        try {
          let decodedDecryptedData = new TextDecoder('utf-8', {
            fatal: true,
          }).decode(decryptedData);
          return decodedDecryptedData;
        } catch (err) {
          return Promise.reject({
            message: 'Invalid Character Error',
            err,
          });
        }
      })
      .catch((err) => {
        return Promise.reject({
          message: 'Decryption Error',
          err,
        });
      });
  }
}
