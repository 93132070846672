<div>
  <div class="granted-cont" [ngClass]="{ 'data-error': error === true }">
    <ng-container>
      <app-avatar [matTooltip]="name" [matTooltipClass]="['no-translate']" [matTooltipTouchGestures]="isMobile ? 'off' : 'on'" [blob]="avatar"></app-avatar>
      <div class="res-node-name">
        <span [matTooltip]="name" [matTooltipClass]="['no-translate']" class="notranslate" [matTooltipTouchGestures]="isMobile ? 'off' : 'on'">{{ name }}</span>
      </div>
      <button class="small-button" color="primary" mat-icon-button *ngIf="!rejected" [matTooltip]="'Join' | translate" (click)="acceptEvent.next(true)"><mat-icon>check</mat-icon></button>
      <button class="small-button" color="primary" mat-icon-button *ngIf="!rejected" [matTooltip]="'Decline' | translate" (click)="acceptEvent.next(false)"><mat-icon>close</mat-icon></button>
      <button class="small-button" color="primary" mat-icon-button *ngIf="rejected" [matTooltip]="'Re-join' | translate" (click)="acceptEvent.next(true)"><mat-icon>login</mat-icon></button>
    </ng-container>
  </div>
</div>
