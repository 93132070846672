import { AbstractDigitalSignature } from '../../base/interface';
import * as nacl from 'tweetnacl';
import { concatByteArray } from '../../utility';

/**
 * Asymmetric signature with the Ed25519 algorithm.
 */
export class Ed25519DSWorker extends AbstractDigitalSignature {
  /**
   *
   * @returns signature
   */
  public static sign(plain: Uint8Array, secret_key: Uint8Array): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      if (secret_key.length !== 64) {
        reject('Ed25519 signing key is invalid');
      } else {
        resolve(nacl.sign.detached(plain, secret_key));
      }
    });
  }

  /**
   *
   * @returns if OK return with the plain text without signature, throw error otherwise
   */
  public static verify(
    signature: Uint8Array,
    public_key: Uint8Array,
    plain: Uint8Array
  ): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const r = nacl.sign.open(concatByteArray(signature, plain), public_key);
      if (r === null) {
        reject('Signature was forged or corrupt');
      } else {
        resolve(r);
      }
    });
  }

  public static describe(): string {
    return 'Ed25519-DS-256';
  }
}
