import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {
  SidebarGroup,
  SidebarResource
} from 'src/app/shared/server-services/query-records/sidebar-records';
import { SidebarService } from 'src/app/shared/server-services/sidebar.service';

@Component({
  selector: 'app-bottom-sheet-group-selector',
  templateUrl: './bottom-sheet-group-selector.component.html',
  styleUrls: ['./bottom-sheet-group-selector.component.scss'],
})
export class BottomSheetGroupSelectorComponent implements OnInit {
  public allowAnonym: boolean = false;
  public groups: SidebarGroup[];

  constructor(
    private _bottomSheet: MatBottomSheetRef<BottomSheetGroupSelectorComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { resource: SidebarResource; group: SidebarGroup },
    private sidebarService: SidebarService
  ) {
    this.sidebarService.getAllSidebarGroup().then((groups: SidebarGroup[]) => {
      this.groups = groups;
    });
  }

  ngOnInit(): void {}

  sheetActionClick(group: SidebarGroup): void {
    this._bottomSheet.dismiss({ group });
  }
}
