<h1 mat-dialog-title translate>Account delete confirmation</h1>
<div class="content" mat-dialog-content>
  <div id="description"><span translate>By deleting your account, you forfeit access to all of your purchased or activated and still valid Nano Licenses.</span></div>
  <div><span translate>All data exclusive to your account will be deleted, including but not limited to recurring purchases, saved billing information, contact list, rooms created by you.</span></div>
  <div class="menacing-text">
    <span translate> After confirming the deletion the account can't be restored and the affected data will not be recoverable later. </span>
  </div>
  <div id="title">
    <span translate>Please enter the confirmation code that we sent to your configured email address to delete this account.</span>
  </div>
  <mat-form-field appearance="outline">
    <mat-label translate>Code</mat-label>
    <input matInput required [(ngModel)]="data.code" (keyup.enter)="onSubmit()" type="number" />
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()"><span translate>Cancel</span></button>
  <button mat-flat-button [mat-dialog-close]="data" [disabled]="data.code === null || data.code.length === 0" (click)="onSubmit()" color="warn"><span translate>DELETE My Account!</span></button>
</div>
