import { AbstractConfigDumpArgs, AbstractConfigLoadArgs } from '../base';
import { AbstractSelfAccountKeyring } from '../../keyring/account_base';

export class NanoOwnConfigLoadArgs extends AbstractConfigLoadArgs {
  public self_kr;
  public timestamp;

  constructor(cipher: Uint8Array, self_kr: AbstractSelfAccountKeyring, timestamp: number) {
    super(cipher), (this.self_kr = self_kr);
    this.timestamp = timestamp;
  }
}

export class NanoOwnConfigDumpArgs extends AbstractConfigDumpArgs {
  public plain;
  public self_kr;
  public timestamp;

  constructor(plain: Uint8Array, self_kr: AbstractSelfAccountKeyring, timestamp: number) {
    super();
    this.plain = plain;
    this.self_kr = self_kr;
    this.timestamp = timestamp;
  }
}
