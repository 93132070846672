export interface PostalCodeRecord {
  postal: number;
  city: string;
  county: string;
}

export const PostalCodes: PostalCodeRecord[] = [
  {
    postal: 7188,
    city: 'Szárász',
    county: 'Baranya',
  },
  {
    postal: 7300,
    city: 'Komló',
    county: 'Baranya',
  },
  {
    postal: 7304,
    city: 'Mánfa',
    county: 'Baranya',
  },
  {
    postal: 7305,
    city: 'Mecsekpölöske',
    county: 'Baranya',
  },
  {
    postal: 7331,
    city: 'Liget',
    county: 'Baranya',
  },
  {
    postal: 7332,
    city: 'Magyaregregy',
    county: 'Baranya',
  },
  {
    postal: 7333,
    city: 'Kárász',
    county: 'Baranya',
  },
  {
    postal: 7333,
    city: 'Vékény',
    county: 'Baranya',
  },
  {
    postal: 7334,
    city: 'Köblény',
    county: 'Baranya',
  },
  {
    postal: 7334,
    city: 'Szalatnak',
    county: 'Baranya',
  },
  {
    postal: 7342,
    city: 'Mágocs',
    county: 'Baranya',
  },
  {
    postal: 7343,
    city: 'Nagyhajmás',
    county: 'Baranya',
  },
  {
    postal: 7344,
    city: 'Mekényes',
    county: 'Baranya',
  },
  {
    postal: 7345,
    city: 'Alsómocsolád',
    county: 'Baranya',
  },
  {
    postal: 7346,
    city: 'Bikal',
    county: 'Baranya',
  },
  {
    postal: 7347,
    city: 'Egyházaskozár',
    county: 'Baranya',
  },
  {
    postal: 7348,
    city: 'Hegyhátmaróc',
    county: 'Baranya',
  },
  {
    postal: 7348,
    city: 'Tófű',
    county: 'Baranya',
  },
  {
    postal: 7349,
    city: 'Szászvár',
    county: 'Baranya',
  },
  {
    postal: 7351,
    city: 'Máza',
    county: 'Baranya',
  },
  {
    postal: 7370,
    city: 'Palé',
    county: 'Baranya',
  },
  {
    postal: 7370,
    city: 'Sásd',
    county: 'Baranya',
  },
  {
    postal: 7370,
    city: 'Varga',
    county: 'Baranya',
  },
  {
    postal: 7370,
    city: 'Meződ',
    county: 'Baranya',
  },
  {
    postal: 7370,
    city: 'Felsőegerszeg',
    county: 'Baranya',
  },
  {
    postal: 7370,
    city: 'Oroszló',
    county: 'Baranya',
  },
  {
    postal: 7370,
    city: 'Vázsnok',
    county: 'Baranya',
  },
  {
    postal: 7381,
    city: 'Tékes',
    county: 'Baranya',
  },
  {
    postal: 7381,
    city: 'Kisvaszar',
    county: 'Baranya',
  },
  {
    postal: 7381,
    city: 'Ág',
    county: 'Baranya',
  },
  {
    postal: 7383,
    city: 'Tormás',
    county: 'Baranya',
  },
  {
    postal: 7383,
    city: 'Szágy',
    county: 'Baranya',
  },
  {
    postal: 7383,
    city: 'Baranyaszentgyörgy',
    county: 'Baranya',
  },
  {
    postal: 7384,
    city: 'Baranyajenő',
    county: 'Baranya',
  },
  {
    postal: 7385,
    city: 'Gödre',
    county: 'Baranya',
  },
  {
    postal: 7386,
    city: 'Gödre',
    county: 'Baranya',
  },
  {
    postal: 7391,
    city: 'Kisbeszterce',
    county: 'Baranya',
  },
  {
    postal: 7391,
    city: 'Mindszentgodisa',
    county: 'Baranya',
  },
  {
    postal: 7391,
    city: 'Kishajmás',
    county: 'Baranya',
  },
  {
    postal: 7393,
    city: 'Bakóca',
    county: 'Baranya',
  },
  {
    postal: 7394,
    city: 'Magyarhertelend',
    county: 'Baranya',
  },
  {
    postal: 7394,
    city: 'Bodolyabér',
    county: 'Baranya',
  },
  {
    postal: 7396,
    city: 'Magyarszék',
    county: 'Baranya',
  },
  {
    postal: 7600,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7621,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7622,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7623,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7624,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7625,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7626,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7627,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7628,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7629,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7630,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7631,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7632,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7633,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7634,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7635,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7636,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7639,
    city: 'Kökény',
    county: 'Baranya',
  },
  {
    postal: 7639,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7661,
    city: 'Erzsébet',
    county: 'Baranya',
  },
  {
    postal: 7661,
    city: 'Kátoly',
    county: 'Baranya',
  },
  {
    postal: 7661,
    city: 'Kékesd',
    county: 'Baranya',
  },
  {
    postal: 7661,
    city: 'Szellő',
    county: 'Baranya',
  },
  {
    postal: 7663,
    city: 'Máriakéménd',
    county: 'Baranya',
  },
  {
    postal: 7664,
    city: 'Szilágy',
    county: 'Baranya',
  },
  {
    postal: 7664,
    city: 'Berkesd',
    county: 'Baranya',
  },
  {
    postal: 7664,
    city: 'Pereked',
    county: 'Baranya',
  },
  {
    postal: 7666,
    city: 'Pogány',
    county: 'Baranya',
  },
  {
    postal: 7668,
    city: 'Gyód',
    county: 'Baranya',
  },
  {
    postal: 7668,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7668,
    city: 'Keszü',
    county: 'Baranya',
  },
  {
    postal: 7671,
    city: 'Zók',
    county: 'Baranya',
  },
  {
    postal: 7671,
    city: 'Bicsérd',
    county: 'Baranya',
  },
  {
    postal: 7671,
    city: 'Aranyosgadány',
    county: 'Baranya',
  },
  {
    postal: 7672,
    city: 'Boda',
    county: 'Baranya',
  },
  {
    postal: 7673,
    city: 'Kővágószőlős',
    county: 'Baranya',
  },
  {
    postal: 7673,
    city: 'Cserkút',
    county: 'Baranya',
  },
  {
    postal: 7675,
    city: 'Bakonya',
    county: 'Baranya',
  },
  {
    postal: 7675,
    city: 'Kővágótöttös',
    county: 'Baranya',
  },
  {
    postal: 7677,
    city: 'Orfű',
    county: 'Baranya',
  },
  {
    postal: 7678,
    city: 'Kovácsszénája',
    county: 'Baranya',
  },
  {
    postal: 7678,
    city: 'Husztót',
    county: 'Baranya',
  },
  {
    postal: 7678,
    city: 'Abaliget',
    county: 'Baranya',
  },
  {
    postal: 7681,
    city: 'Hetvehely',
    county: 'Baranya',
  },
  {
    postal: 7681,
    city: 'Szentkatalin',
    county: 'Baranya',
  },
  {
    postal: 7681,
    city: 'Okorvölgy',
    county: 'Baranya',
  },
  {
    postal: 7682,
    city: 'Bükkösd',
    county: 'Baranya',
  },
  {
    postal: 7683,
    city: 'Dinnyeberki',
    county: 'Baranya',
  },
  {
    postal: 7683,
    city: 'Helesfa',
    county: 'Baranya',
  },
  {
    postal: 7683,
    city: 'Cserdi',
    county: 'Baranya',
  },
  {
    postal: 7691,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7693,
    city: 'Pécs',
    county: 'Baranya',
  },
  {
    postal: 7694,
    city: 'Hosszúhetény',
    county: 'Baranya',
  },
  {
    postal: 7695,
    city: 'Mecseknádasd',
    county: 'Baranya',
  },
  {
    postal: 7695,
    city: 'Óbánya',
    county: 'Baranya',
  },
  {
    postal: 7695,
    city: 'Ófalu',
    county: 'Baranya',
  },
  {
    postal: 7696,
    city: 'Hidas',
    county: 'Baranya',
  },
  {
    postal: 7700,
    city: 'Mohács',
    county: 'Baranya',
  },
  {
    postal: 7711,
    city: 'Bár',
    county: 'Baranya',
  },
  {
    postal: 7712,
    city: 'Dunaszekcső',
    county: 'Baranya',
  },
  {
    postal: 7714,
    city: 'Mohács',
    county: 'Baranya',
  },
  {
    postal: 7715,
    city: 'Mohács',
    county: 'Baranya',
  },
  {
    postal: 7716,
    city: 'Homorúd',
    county: 'Baranya',
  },
  {
    postal: 7717,
    city: 'Kölked',
    county: 'Baranya',
  },
  {
    postal: 7718,
    city: 'Udvar',
    county: 'Baranya',
  },
  {
    postal: 7720,
    city: 'Martonfa',
    county: 'Baranya',
  },
  {
    postal: 7720,
    city: 'Apátvarasd',
    county: 'Baranya',
  },
  {
    postal: 7720,
    city: 'Zengővárkony',
    county: 'Baranya',
  },
  {
    postal: 7720,
    city: 'Lovászhetény',
    county: 'Baranya',
  },
  {
    postal: 7720,
    city: 'Pécsvárad',
    county: 'Baranya',
  },
  {
    postal: 7723,
    city: 'Erdősmecske',
    county: 'Baranya',
  },
  {
    postal: 7724,
    city: 'Feked',
    county: 'Baranya',
  },
  {
    postal: 7725,
    city: 'Szebény',
    county: 'Baranya',
  },
  {
    postal: 7726,
    city: 'Véménd',
    county: 'Baranya',
  },
  {
    postal: 7727,
    city: 'Palotabozsok',
    county: 'Baranya',
  },
  {
    postal: 7728,
    city: 'Somberek',
    county: 'Baranya',
  },
  {
    postal: 7728,
    city: 'Görcsönydoboka',
    county: 'Baranya',
  },
  {
    postal: 7731,
    city: 'Nagypall',
    county: 'Baranya',
  },
  {
    postal: 7732,
    city: 'Fazekasboda',
    county: 'Baranya',
  },
  {
    postal: 7733,
    city: 'Geresdlak',
    county: 'Baranya',
  },
  {
    postal: 7733,
    city: 'Maráza',
    county: 'Baranya',
  },
  {
    postal: 7735,
    city: 'Erdősmárok',
    county: 'Baranya',
  },
  {
    postal: 7735,
    city: 'Szűr',
    county: 'Baranya',
  },
  {
    postal: 7735,
    city: 'Himesháza',
    county: 'Baranya',
  },
  {
    postal: 7737,
    city: 'Székelyszabar',
    county: 'Baranya',
  },
  {
    postal: 7741,
    city: 'Nagykozár',
    county: 'Baranya',
  },
  {
    postal: 7742,
    city: 'Bogád',
    county: 'Baranya',
  },
  {
    postal: 7743,
    city: 'Romonya',
    county: 'Baranya',
  },
  {
    postal: 7744,
    city: 'Ellend',
    county: 'Baranya',
  },
  {
    postal: 7745,
    city: 'Olasz',
    county: 'Baranya',
  },
  {
    postal: 7745,
    city: 'Hásságy',
    county: 'Baranya',
  },
  {
    postal: 7747,
    city: 'Birján',
    county: 'Baranya',
  },
  {
    postal: 7747,
    city: 'Belvárdgyula',
    county: 'Baranya',
  },
  {
    postal: 7751,
    city: 'Monyoród',
    county: 'Baranya',
  },
  {
    postal: 7751,
    city: 'Szederkény',
    county: 'Baranya',
  },
  {
    postal: 7752,
    city: 'Versend',
    county: 'Baranya',
  },
  {
    postal: 7753,
    city: 'Szajk',
    county: 'Baranya',
  },
  {
    postal: 7754,
    city: 'Bóly',
    county: 'Baranya',
  },
  {
    postal: 7755,
    city: 'Töttös',
    county: 'Baranya',
  },
  {
    postal: 7756,
    city: 'Nagybudmér',
    county: 'Baranya',
  },
  {
    postal: 7756,
    city: 'Pócsa',
    county: 'Baranya',
  },
  {
    postal: 7756,
    city: 'Borjád',
    county: 'Baranya',
  },
  {
    postal: 7756,
    city: 'Kisbudmér',
    county: 'Baranya',
  },
  {
    postal: 7757,
    city: 'Liptód',
    county: 'Baranya',
  },
  {
    postal: 7757,
    city: 'Babarc',
    county: 'Baranya',
  },
  {
    postal: 7759,
    city: 'Lánycsók',
    county: 'Baranya',
  },
  {
    postal: 7759,
    city: 'Kisnyárád',
    county: 'Baranya',
  },
  {
    postal: 7761,
    city: 'Magyarsarlós',
    county: 'Baranya',
  },
  {
    postal: 7761,
    city: 'Lothárd',
    county: 'Baranya',
  },
  {
    postal: 7761,
    city: 'Kozármisleny',
    county: 'Baranya',
  },
  {
    postal: 7762,
    city: 'Pécsudvard',
    county: 'Baranya',
  },
  {
    postal: 7763,
    city: 'Egerág',
    county: 'Baranya',
  },
  {
    postal: 7763,
    city: 'Szemely',
    county: 'Baranya',
  },
  {
    postal: 7763,
    city: 'Kisherend',
    county: 'Baranya',
  },
  {
    postal: 7763,
    city: 'Áta',
    county: 'Baranya',
  },
  {
    postal: 7763,
    city: 'Szőkéd',
    county: 'Baranya',
  },
  {
    postal: 7766,
    city: 'Újpetre',
    county: 'Baranya',
  },
  {
    postal: 7766,
    city: 'Kiskassa',
    county: 'Baranya',
  },
  {
    postal: 7766,
    city: 'Pécsdevecser',
    county: 'Baranya',
  },
  {
    postal: 7766,
    city: 'Peterd',
    county: 'Baranya',
  },
  {
    postal: 7768,
    city: 'Vokány',
    county: 'Baranya',
  },
  {
    postal: 7768,
    city: 'Kistótfalu',
    county: 'Baranya',
  },
  {
    postal: 7771,
    city: 'Palkonya',
    county: 'Baranya',
  },
  {
    postal: 7772,
    city: 'Ivánbattyán',
    county: 'Baranya',
  },
  {
    postal: 7772,
    city: 'Villánykövesd',
    county: 'Baranya',
  },
  {
    postal: 7773,
    city: 'Villány',
    county: 'Baranya',
  },
  {
    postal: 7773,
    city: 'Kisjakabfalva',
    county: 'Baranya',
  },
  {
    postal: 7774,
    city: 'Márok',
    county: 'Baranya',
  },
  {
    postal: 7775,
    city: 'Lapáncsa',
    county: 'Baranya',
  },
  {
    postal: 7775,
    city: 'Illocska',
    county: 'Baranya',
  },
  {
    postal: 7775,
    city: 'Magyarbóly',
    county: 'Baranya',
  },
  {
    postal: 7775,
    city: 'Kislippó',
    county: 'Baranya',
  },
  {
    postal: 7781,
    city: 'Sárok',
    county: 'Baranya',
  },
  {
    postal: 7781,
    city: 'Ivándárda',
    county: 'Baranya',
  },
  {
    postal: 7781,
    city: 'Lippó',
    county: 'Baranya',
  },
  {
    postal: 7782,
    city: 'Bezedek',
    county: 'Baranya',
  },
  {
    postal: 7783,
    city: 'Majs',
    county: 'Baranya',
  },
  {
    postal: 7784,
    city: 'Nagynyárád',
    county: 'Baranya',
  },
  {
    postal: 7785,
    city: 'Sátorhely',
    county: 'Baranya',
  },
  {
    postal: 7800,
    city: 'Kisharsány',
    county: 'Baranya',
  },
  {
    postal: 7800,
    city: 'Nagytótfalu',
    county: 'Baranya',
  },
  {
    postal: 7800,
    city: 'Siklós',
    county: 'Baranya',
  },
  {
    postal: 7811,
    city: 'Bosta',
    county: 'Baranya',
  },
  {
    postal: 7811,
    city: 'Csarnóta',
    county: 'Baranya',
  },
  {
    postal: 7811,
    city: 'Szalánta',
    county: 'Baranya',
  },
  {
    postal: 7811,
    city: 'Szilvás',
    county: 'Baranya',
  },
  {
    postal: 7811,
    city: 'Túrony',
    county: 'Baranya',
  },
  {
    postal: 7811,
    city: 'Bisse',
    county: 'Baranya',
  },
  {
    postal: 7812,
    city: 'Garé',
    county: 'Baranya',
  },
  {
    postal: 7813,
    city: 'Szava',
    county: 'Baranya',
  },
  {
    postal: 7814,
    city: 'Ócsárd',
    county: 'Baranya',
  },
  {
    postal: 7814,
    city: 'Babarcszőlős',
    county: 'Baranya',
  },
  {
    postal: 7814,
    city: 'Kisdér',
    county: 'Baranya',
  },
  {
    postal: 7814,
    city: 'Siklósbodony',
    county: 'Baranya',
  },
  {
    postal: 7815,
    city: 'Harkány',
    county: 'Baranya',
  },
  {
    postal: 7817,
    city: 'Rádfalva',
    county: 'Baranya',
  },
  {
    postal: 7817,
    city: 'Márfa',
    county: 'Baranya',
  },
  {
    postal: 7817,
    city: 'Diósviszló',
    county: 'Baranya',
  },
  {
    postal: 7822,
    city: 'Nagyharsány',
    county: 'Baranya',
  },
  {
    postal: 7823,
    city: 'Kistapolca',
    county: 'Baranya',
  },
  {
    postal: 7823,
    city: 'Siklósnagyfalu',
    county: 'Baranya',
  },
  {
    postal: 7824,
    city: 'Egyházasharaszti',
    county: 'Baranya',
  },
  {
    postal: 7825,
    city: 'Old',
    county: 'Baranya',
  },
  {
    postal: 7826,
    city: 'Alsószentmárton',
    county: 'Baranya',
  },
  {
    postal: 7827,
    city: 'Kásád',
    county: 'Baranya',
  },
  {
    postal: 7827,
    city: 'Beremend',
    county: 'Baranya',
  },
  {
    postal: 7831,
    city: 'Pellérd',
    county: 'Baranya',
  },
  {
    postal: 7833,
    city: 'Görcsöny',
    county: 'Baranya',
  },
  {
    postal: 7833,
    city: 'Szőke',
    county: 'Baranya',
  },
  {
    postal: 7833,
    city: 'Regenye',
    county: 'Baranya',
  },
  {
    postal: 7834,
    city: 'Baksa',
    county: 'Baranya',
  },
  {
    postal: 7834,
    city: 'Téseny',
    county: 'Baranya',
  },
  {
    postal: 7834,
    city: 'Tengeri',
    county: 'Baranya',
  },
  {
    postal: 7836,
    city: 'Ózdfalu',
    county: 'Baranya',
  },
  {
    postal: 7836,
    city: 'Bogádmindszent',
    county: 'Baranya',
  },
  {
    postal: 7837,
    city: 'Hegyszentmárton',
    county: 'Baranya',
  },
  {
    postal: 7838,
    city: 'Piskó',
    county: 'Baranya',
  },
  {
    postal: 7838,
    city: 'Nagycsány',
    county: 'Baranya',
  },
  {
    postal: 7838,
    city: 'Vejti',
    county: 'Baranya',
  },
  {
    postal: 7838,
    city: 'Vajszló',
    county: 'Baranya',
  },
  {
    postal: 7838,
    city: 'Hirics',
    county: 'Baranya',
  },
  {
    postal: 7838,
    city: 'Páprád',
    county: 'Baranya',
  },
  {
    postal: 7838,
    city: 'Lúzsok',
    county: 'Baranya',
  },
  {
    postal: 7838,
    city: 'Besence',
    county: 'Baranya',
  },
  {
    postal: 7839,
    city: 'Kemse',
    county: 'Baranya',
  },
  {
    postal: 7839,
    city: 'Zaláta',
    county: 'Baranya',
  },
  {
    postal: 7841,
    city: 'Sámod',
    county: 'Baranya',
  },
  {
    postal: 7841,
    city: 'Adorjás',
    county: 'Baranya',
  },
  {
    postal: 7841,
    city: 'Baranyahídvég',
    county: 'Baranya',
  },
  {
    postal: 7841,
    city: 'Kórós',
    county: 'Baranya',
  },
  {
    postal: 7841,
    city: 'Kisszentmárton',
    county: 'Baranya',
  },
  {
    postal: 7843,
    city: 'Cún',
    county: 'Baranya',
  },
  {
    postal: 7843,
    city: 'Kémes',
    county: 'Baranya',
  },
  {
    postal: 7843,
    city: 'Szaporca',
    county: 'Baranya',
  },
  {
    postal: 7843,
    city: 'Tésenfa',
    county: 'Baranya',
  },
  {
    postal: 7843,
    city: 'Drávapiski',
    county: 'Baranya',
  },
  {
    postal: 7846,
    city: 'Drávacsepely',
    county: 'Baranya',
  },
  {
    postal: 7847,
    city: 'Drávaszerdahely',
    county: 'Baranya',
  },
  {
    postal: 7847,
    city: 'Kovácshida',
    county: 'Baranya',
  },
  {
    postal: 7847,
    city: 'Ipacsfa',
    county: 'Baranya',
  },
  {
    postal: 7849,
    city: 'Drávacsehi',
    county: 'Baranya',
  },
  {
    postal: 7850,
    city: 'Drávapalkonya',
    county: 'Baranya',
  },
  {
    postal: 7851,
    city: 'Drávaszabolcs',
    county: 'Baranya',
  },
  {
    postal: 7853,
    city: 'Gordisa',
    county: 'Baranya',
  },
  {
    postal: 7854,
    city: 'Matty',
    county: 'Baranya',
  },
  {
    postal: 7900,
    city: 'Botykapeterd',
    county: 'Baranya',
  },
  {
    postal: 7900,
    city: 'Szigetvár',
    county: 'Baranya',
  },
  {
    postal: 7900,
    city: 'Csertő',
    county: 'Baranya',
  },
  {
    postal: 7912,
    city: 'Nyugotszenterzsébet',
    county: 'Baranya',
  },
  {
    postal: 7912,
    city: 'Nagyváty',
    county: 'Baranya',
  },
  {
    postal: 7912,
    city: 'Nagypeterd',
    county: 'Baranya',
  },
  {
    postal: 7913,
    city: 'Szentdénes',
    county: 'Baranya',
  },
  {
    postal: 7914,
    city: 'Katádfa',
    county: 'Baranya',
  },
  {
    postal: 7914,
    city: 'Bánfa',
    county: 'Baranya',
  },
  {
    postal: 7914,
    city: 'Rózsafa',
    county: 'Baranya',
  },
  {
    postal: 7915,
    city: 'Szentegát',
    county: 'Baranya',
  },
  {
    postal: 7915,
    city: 'Dencsháza',
    county: 'Baranya',
  },
  {
    postal: 7921,
    city: 'Somogyhatvan',
    county: 'Baranya',
  },
  {
    postal: 7922,
    city: 'Somogyapáti',
    county: 'Baranya',
  },
  {
    postal: 7923,
    city: 'Patapoklosi',
    county: 'Baranya',
  },
  {
    postal: 7923,
    city: 'Basal',
    county: 'Baranya',
  },
  {
    postal: 7924,
    city: 'Somogyviszló',
    county: 'Baranya',
  },
  {
    postal: 7925,
    city: 'Magyarlukafa',
    county: 'Baranya',
  },
  {
    postal: 7925,
    city: 'Somogyhárságy',
    county: 'Baranya',
  },
  {
    postal: 7926,
    city: 'Vásárosbéc',
    county: 'Baranya',
  },
  {
    postal: 7932,
    city: 'Mozsgó',
    county: 'Baranya',
  },
  {
    postal: 7932,
    city: 'Szulimán',
    county: 'Baranya',
  },
  {
    postal: 7932,
    city: 'Almáskeresztúr',
    county: 'Baranya',
  },
  {
    postal: 7934,
    city: 'Almamellék',
    county: 'Baranya',
  },
  {
    postal: 7935,
    city: 'Horváthertelend',
    county: 'Baranya',
  },
  {
    postal: 7935,
    city: 'Csebény',
    county: 'Baranya',
  },
  {
    postal: 7935,
    city: 'Ibafa',
    county: 'Baranya',
  },
  {
    postal: 7936,
    city: 'Szentlászló',
    county: 'Baranya',
  },
  {
    postal: 7937,
    city: 'Boldogasszonyfa',
    county: 'Baranya',
  },
  {
    postal: 7940,
    city: 'Csonkamindszent',
    county: 'Baranya',
  },
  {
    postal: 7940,
    city: 'Szentlőrinc',
    county: 'Baranya',
  },
  {
    postal: 7940,
    city: 'Kacsóta',
    county: 'Baranya',
  },
  {
    postal: 7951,
    city: 'Pécsbagota',
    county: 'Baranya',
  },
  {
    postal: 7951,
    city: 'Szabadszentkirály',
    county: 'Baranya',
  },
  {
    postal: 7951,
    city: 'Velény',
    county: 'Baranya',
  },
  {
    postal: 7951,
    city: 'Gerde',
    county: 'Baranya',
  },
  {
    postal: 7953,
    city: 'Királyegyháza',
    county: 'Baranya',
  },
  {
    postal: 7954,
    city: 'Kisasszonyfa',
    county: 'Baranya',
  },
  {
    postal: 7954,
    city: 'Gyöngyfa',
    county: 'Baranya',
  },
  {
    postal: 7954,
    city: 'Gilvánfa',
    county: 'Baranya',
  },
  {
    postal: 7954,
    city: 'Magyarmecske',
    county: 'Baranya',
  },
  {
    postal: 7954,
    city: 'Magyartelek',
    county: 'Baranya',
  },
  {
    postal: 7957,
    city: 'Okorág',
    county: 'Baranya',
  },
  {
    postal: 7958,
    city: 'Kákics',
    county: 'Baranya',
  },
  {
    postal: 7960,
    city: 'Drávasztára',
    county: 'Baranya',
  },
  {
    postal: 7960,
    city: 'Marócsa',
    county: 'Baranya',
  },
  {
    postal: 7960,
    city: 'Sumony',
    county: 'Baranya',
  },
  {
    postal: 7960,
    city: 'Sósvertike',
    county: 'Baranya',
  },
  {
    postal: 7960,
    city: 'Drávaiványi',
    county: 'Baranya',
  },
  {
    postal: 7960,
    city: 'Sellye',
    county: 'Baranya',
  },
  {
    postal: 7964,
    city: 'Csányoszró',
    county: 'Baranya',
  },
  {
    postal: 7966,
    city: 'Bogdása',
    county: 'Baranya',
  },
  {
    postal: 7967,
    city: 'Drávakeresztúr',
    county: 'Baranya',
  },
  {
    postal: 7967,
    city: 'Drávafok',
    county: 'Baranya',
  },
  {
    postal: 7967,
    city: 'Markóc',
    county: 'Baranya',
  },
  {
    postal: 7968,
    city: 'Felsőszentmárton',
    county: 'Baranya',
  },
  {
    postal: 7971,
    city: 'Hobol',
    county: 'Baranya',
  },
  {
    postal: 7972,
    city: 'Gyöngyösmellék',
    county: 'Baranya',
  },
  {
    postal: 7973,
    city: 'Bürüs',
    county: 'Baranya',
  },
  {
    postal: 7973,
    city: 'Endrőc',
    county: 'Baranya',
  },
  {
    postal: 7973,
    city: 'Várad',
    county: 'Baranya',
  },
  {
    postal: 7973,
    city: 'Teklafalu',
    county: 'Baranya',
  },
  {
    postal: 7975,
    city: 'Kétújfalu',
    county: 'Baranya',
  },
  {
    postal: 7976,
    city: 'Szörény',
    county: 'Baranya',
  },
  {
    postal: 7976,
    city: 'Zádor',
    county: 'Baranya',
  },
  {
    postal: 7980,
    city: 'Pettend',
    county: 'Baranya',
  },
  {
    postal: 7981,
    city: 'Kistamási',
    county: 'Baranya',
  },
  {
    postal: 7981,
    city: 'Molvány',
    county: 'Baranya',
  },
  {
    postal: 7981,
    city: 'Tótszentgyörgy',
    county: 'Baranya',
  },
  {
    postal: 7981,
    city: 'Merenye',
    county: 'Baranya',
  },
  {
    postal: 7981,
    city: 'Nemeske',
    county: 'Baranya',
  },
  {
    postal: 7985,
    city: 'Kisdobsza',
    county: 'Baranya',
  },
  {
    postal: 7985,
    city: 'Nagydobsza',
    county: 'Baranya',
  },
  {
    postal: 5500,
    city: 'Gyomaendrőd',
    county: 'Békés',
  },
  {
    postal: 5502,
    city: 'Gyomaendrőd',
    county: 'Békés',
  },
  {
    postal: 5510,
    city: 'Dévaványa',
    county: 'Békés',
  },
  {
    postal: 5515,
    city: 'Ecsegfalva',
    county: 'Békés',
  },
  {
    postal: 5516,
    city: 'Körösladány',
    county: 'Békés',
  },
  {
    postal: 5520,
    city: 'Szeghalom',
    county: 'Békés',
  },
  {
    postal: 5525,
    city: 'Füzesgyarmat',
    county: 'Békés',
  },
  {
    postal: 5526,
    city: 'Kertészsziget',
    county: 'Békés',
  },
  {
    postal: 5527,
    city: 'Bucsa',
    county: 'Békés',
  },
  {
    postal: 5530,
    city: 'Vésztő',
    county: 'Békés',
  },
  {
    postal: 5534,
    city: 'Okány',
    county: 'Békés',
  },
  {
    postal: 5536,
    city: 'Körösújfalu',
    county: 'Békés',
  },
  {
    postal: 5537,
    city: 'Zsadány',
    county: 'Békés',
  },
  {
    postal: 5538,
    city: 'Biharugra',
    county: 'Békés',
  },
  {
    postal: 5539,
    city: 'Körösnagyharsány',
    county: 'Békés',
  },
  {
    postal: 5540,
    city: 'Szarvas',
    county: 'Békés',
  },
  {
    postal: 5551,
    city: 'Csabacsűd',
    county: 'Békés',
  },
  {
    postal: 5552,
    city: 'Kardos',
    county: 'Békés',
  },
  {
    postal: 5553,
    city: 'Kondoros',
    county: 'Békés',
  },
  {
    postal: 5555,
    city: 'Hunya',
    county: 'Békés',
  },
  {
    postal: 5556,
    city: 'Örménykút',
    county: 'Békés',
  },
  {
    postal: 5561,
    city: 'Békésszentandrás',
    county: 'Békés',
  },
  {
    postal: 5600,
    city: 'Békéscsaba',
    county: 'Békés',
  },
  {
    postal: 5609,
    city: 'Csabaszabadi',
    county: 'Békés',
  },
  {
    postal: 5621,
    city: 'Csárdaszállás',
    county: 'Békés',
  },
  {
    postal: 5622,
    city: 'Köröstarcsa',
    county: 'Békés',
  },
  {
    postal: 5623,
    city: 'Békéscsaba',
    county: 'Békés',
  },
  {
    postal: 5624,
    city: 'Doboz',
    county: 'Békés',
  },
  {
    postal: 5630,
    city: 'Békés',
    county: 'Békés',
  },
  {
    postal: 5641,
    city: 'Tarhos',
    county: 'Békés',
  },
  {
    postal: 5643,
    city: 'Bélmegyer',
    county: 'Békés',
  },
  {
    postal: 5650,
    city: 'Mezőberény',
    county: 'Békés',
  },
  {
    postal: 5661,
    city: 'Újkígyós',
    county: 'Békés',
  },
  {
    postal: 5662,
    city: 'Csanádapáca',
    county: 'Békés',
  },
  {
    postal: 5663,
    city: 'Medgyesbodzás',
    county: 'Békés',
  },
  {
    postal: 5664,
    city: 'Medgyesbodzás',
    county: 'Békés',
  },
  {
    postal: 5665,
    city: 'Pusztaottlaka',
    county: 'Békés',
  },
  {
    postal: 5666,
    city: 'Medgyesegyháza',
    county: 'Békés',
  },
  {
    postal: 5667,
    city: 'Magyarbánhegyes',
    county: 'Békés',
  },
  {
    postal: 5668,
    city: 'Nagybánhegyes',
    county: 'Békés',
  },
  {
    postal: 5671,
    city: 'Békéscsaba',
    county: 'Békés',
  },
  {
    postal: 5672,
    city: 'Murony',
    county: 'Békés',
  },
  {
    postal: 5673,
    city: 'Kamut',
    county: 'Békés',
  },
  {
    postal: 5674,
    city: 'Kétsoprony',
    county: 'Békés',
  },
  {
    postal: 5675,
    city: 'Telekgerendás',
    county: 'Békés',
  },
  {
    postal: 5700,
    city: 'Gyula',
    county: 'Békés',
  },
  {
    postal: 5711,
    city: 'Gyula',
    county: 'Békés',
  },
  {
    postal: 5712,
    city: 'Szabadkígyós',
    county: 'Békés',
  },
  {
    postal: 5720,
    city: 'Sarkad',
    county: 'Békés',
  },
  {
    postal: 5725,
    city: 'Kötegyán',
    county: 'Békés',
  },
  {
    postal: 5726,
    city: 'Méhkerék',
    county: 'Békés',
  },
  {
    postal: 5727,
    city: 'Újszalonta',
    county: 'Békés',
  },
  {
    postal: 5731,
    city: 'Sarkadkeresztúr',
    county: 'Békés',
  },
  {
    postal: 5732,
    city: 'Mezőgyán',
    county: 'Békés',
  },
  {
    postal: 5734,
    city: 'Geszt',
    county: 'Békés',
  },
  {
    postal: 5741,
    city: 'Kétegyháza',
    county: 'Békés',
  },
  {
    postal: 5742,
    city: 'Elek',
    county: 'Békés',
  },
  {
    postal: 5743,
    city: 'Lőkösháza',
    county: 'Békés',
  },
  {
    postal: 5744,
    city: 'Kevermes',
    county: 'Békés',
  },
  {
    postal: 5745,
    city: 'Dombiratos',
    county: 'Békés',
  },
  {
    postal: 5746,
    city: 'Kunágota',
    county: 'Békés',
  },
  {
    postal: 5747,
    city: 'Almáskamarás',
    county: 'Békés',
  },
  {
    postal: 5751,
    city: 'Nagykamarás',
    county: 'Békés',
  },
  {
    postal: 5752,
    city: 'Medgyesegyháza',
    county: 'Békés',
  },
  {
    postal: 5800,
    city: 'Mezőkovácsháza',
    county: 'Békés',
  },
  {
    postal: 5811,
    city: 'Végegyháza',
    county: 'Békés',
  },
  {
    postal: 5820,
    city: 'Mezőhegyes',
    county: 'Békés',
  },
  {
    postal: 5830,
    city: 'Battonya',
    county: 'Békés',
  },
  {
    postal: 5836,
    city: 'Dombegyház',
    county: 'Békés',
  },
  {
    postal: 5837,
    city: 'Kisdombegyház',
    county: 'Békés',
  },
  {
    postal: 5838,
    city: 'Magyardombegyház',
    county: 'Békés',
  },
  {
    postal: 5900,
    city: 'Orosháza',
    county: 'Békés',
  },
  {
    postal: 5903,
    city: 'Orosháza',
    county: 'Békés',
  },
  {
    postal: 5904,
    city: 'Orosháza',
    county: 'Békés',
  },
  {
    postal: 5905,
    city: 'Orosháza',
    county: 'Békés',
  },
  {
    postal: 5919,
    city: 'Pusztaföldvár',
    county: 'Békés',
  },
  {
    postal: 5920,
    city: 'Csorvás',
    county: 'Békés',
  },
  {
    postal: 5925,
    city: 'Gerendás',
    county: 'Békés',
  },
  {
    postal: 5931,
    city: 'Nagyszénás',
    county: 'Békés',
  },
  {
    postal: 5932,
    city: 'Gádoros',
    county: 'Békés',
  },
  {
    postal: 5940,
    city: 'Tótkomlós',
    county: 'Békés',
  },
  {
    postal: 5945,
    city: 'Kardoskút',
    county: 'Békés',
  },
  {
    postal: 5946,
    city: 'Békéssámson',
    county: 'Békés',
  },
  {
    postal: 5948,
    city: 'Kaszaper',
    county: 'Békés',
  },
  {
    postal: 6000,
    city: 'Kecskemét',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6008,
    city: 'Kecskemét',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6031,
    city: 'Szentkirály',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6032,
    city: 'Nyárlőrinc',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6033,
    city: 'Városföld',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6034,
    city: 'Helvécia',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6035,
    city: 'Ballószög',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6041,
    city: 'Kerekegyháza',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6042,
    city: 'Fülöpháza',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6043,
    city: 'Kunbaracs',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6044,
    city: 'Kecskemét',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6045,
    city: 'Ladánybene',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6050,
    city: 'Lajosmizse',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6055,
    city: 'Felsőlajos',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6060,
    city: 'Tiszakécske',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6062,
    city: 'Tiszakécske',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6064,
    city: 'Tiszaug',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6065,
    city: 'Lakitelek',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6066,
    city: 'Tiszaalpár',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6067,
    city: 'Tiszaalpár',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6070,
    city: 'Izsák',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6075,
    city: 'Páhi',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6076,
    city: 'Ágasegyháza',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6077,
    city: 'Orgovány',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6078,
    city: 'Jakabszállás',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6080,
    city: 'Szabadszállás',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6085,
    city: 'Fülöpszállás',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6086,
    city: 'Szalkszentmárton',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6087,
    city: 'Dunavecse',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6088,
    city: 'Apostag',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6090,
    city: 'Kunszentmiklós',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6096,
    city: 'Kunpeszér',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6097,
    city: 'Kunadacs',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6098,
    city: 'Tass',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6100,
    city: 'Kiskunfélegyháza',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6111,
    city: 'Gátér',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6112,
    city: 'Pálmonostora',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6113,
    city: 'Petőfiszállás',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6114,
    city: 'Bugacpusztaháza',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6114,
    city: 'Bugac',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6115,
    city: 'Kunszállás',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6116,
    city: 'Fülöpjakab',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6120,
    city: 'Kiskunmajsa',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6131,
    city: 'Szank',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6132,
    city: 'Móricgát',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6133,
    city: 'Jászszentlászló',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6134,
    city: 'Kömpöc',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6135,
    city: 'Csólyospálos',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6136,
    city: 'Harkakötöny',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6200,
    city: 'Kiskőrös',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6211,
    city: 'Kaskantyú',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6221,
    city: 'Akasztó',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6222,
    city: 'Csengőd',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6223,
    city: 'Soltszentimre',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6224,
    city: 'Tabdi',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6230,
    city: 'Soltvadkert',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6235,
    city: 'Bócsa',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6236,
    city: 'Tázlár',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6237,
    city: 'Kecel',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6238,
    city: 'Imrehegy',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6239,
    city: 'Császártöltés',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6300,
    city: 'Kalocsa',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6311,
    city: 'Öregcsertő',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6320,
    city: 'Solt',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6321,
    city: 'Újsolt',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6323,
    city: 'Dunaegyháza',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6325,
    city: 'Dunatetétlen',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6326,
    city: 'Harta',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6327,
    city: 'Harta',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6328,
    city: 'Dunapataj',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6331,
    city: 'Foktő',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6332,
    city: 'Uszód',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6333,
    city: 'Dunaszentbenedek',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6334,
    city: 'Géderlak',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6335,
    city: 'Ordas',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6336,
    city: 'Szakmár',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6337,
    city: 'Újtelek',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6341,
    city: 'Homokmégy',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6342,
    city: 'Drágszél',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6343,
    city: 'Miske',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6344,
    city: 'Hajós',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6345,
    city: 'Nemesnádudvar',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6346,
    city: 'Sükösd',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6347,
    city: 'Érsekcsanád',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6348,
    city: 'Érsekhalma',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6351,
    city: 'Bátya',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6352,
    city: 'Fajsz',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6353,
    city: 'Dusnok',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6400,
    city: 'Kiskunhalas',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6411,
    city: 'Zsana',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6412,
    city: 'Balotaszállás',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6413,
    city: 'Kunfehértó',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6414,
    city: 'Pirtó',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6421,
    city: 'Kisszállás',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6422,
    city: 'Tompa',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6423,
    city: 'Kelebia',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6424,
    city: 'Csikéria',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6425,
    city: 'Bácsszőlős',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6430,
    city: 'Bácsalmás',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6435,
    city: 'Kunbaja',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6440,
    city: 'Jánoshalma',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6444,
    city: 'Kéleshalom',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6445,
    city: 'Borota',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6446,
    city: 'Rém',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6447,
    city: 'Felsőszentiván',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6448,
    city: 'Csávoly',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6449,
    city: 'Mélykút',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6451,
    city: 'Tataháza',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6452,
    city: 'Mátételke',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6453,
    city: 'Bácsbokod',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6454,
    city: 'Bácsborsód',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6455,
    city: 'Katymár',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6456,
    city: 'Madaras',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6500,
    city: 'Baja',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6503,
    city: 'Baja',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6511,
    city: 'Bácsszentgyörgy',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6512,
    city: 'Szeremle',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6513,
    city: 'Dunafalva',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6521,
    city: 'Vaskút',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6522,
    city: 'Gara',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6523,
    city: 'Csátalja',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6524,
    city: 'Dávod',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6525,
    city: 'Hercegszántó',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6527,
    city: 'Nagybaracska',
    county: 'Bács-Kiskun',
  },
  {
    postal: 6528,
    city: 'Bátmonostor',
    county: 'Bács-Kiskun',
  },
  {
    postal: 1007,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1011,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1012,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1013,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1014,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1015,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1016,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1021,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1022,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1023,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1024,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1025,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1026,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1027,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1028,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1029,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1031,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1032,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1033,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1034,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1035,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1036,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1037,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1038,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1039,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1041,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1042,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1043,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1044,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1045,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1046,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1047,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1048,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1051,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1052,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1053,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1054,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1055,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1056,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1061,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1062,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1063,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1064,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1065,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1066,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1067,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1068,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1071,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1072,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1073,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1074,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1075,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1076,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1077,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1078,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1081,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1082,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1083,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1084,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1085,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1086,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1087,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1088,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1089,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1091,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1092,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1093,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1094,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1095,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1096,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1097,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1098,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1101,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1102,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1103,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1104,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1105,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1106,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1107,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1108,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1111,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1112,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1113,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1114,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1115,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1116,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1117,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1118,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1119,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1121,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1122,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1123,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1124,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1125,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1126,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1131,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1132,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1133,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1134,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1135,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1136,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1137,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1138,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1139,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1141,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1142,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1143,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1144,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1145,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1146,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1147,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1148,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1149,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1151,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1152,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1153,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1154,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1155,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1156,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1157,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1158,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1161,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1162,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1163,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1164,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1165,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1171,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1172,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1173,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1174,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1181,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1182,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1183,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1184,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1185,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1186,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1188,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1191,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1192,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1193,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1194,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1195,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1196,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1201,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1202,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1203,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1204,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1205,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1211,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1212,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1213,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1214,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1215,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1221,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1222,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1223,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1224,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1225,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1237,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1238,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 1239,
    city: 'Budapest',
    county: 'Budapest',
  },
  {
    postal: 3400,
    city: 'Mezőkövesd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3411,
    city: 'Szomolya',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3412,
    city: 'Bogács',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3413,
    city: 'Cserépfalu',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3414,
    city: 'Bükkzsérc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3416,
    city: 'Tard',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3417,
    city: 'Cserépváralja',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3418,
    city: 'Szentistván',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3421,
    city: 'Mezőnyárád',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3422,
    city: 'Bükkábrány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3423,
    city: 'Tibolddaróc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3424,
    city: 'Kács',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3425,
    city: 'Sály',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3426,
    city: 'Borsodgeszt',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3431,
    city: 'Vatta',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3432,
    city: 'Emőd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3433,
    city: 'Nyékládháza',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3434,
    city: 'Mályi',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3441,
    city: 'Mezőkeresztes',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3442,
    city: 'Csincse',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3443,
    city: 'Mezőnagymihály',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3444,
    city: 'Gelej',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3450,
    city: 'Mezőcsát',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3458,
    city: 'Tiszakeszi',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3459,
    city: 'Igrici',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3461,
    city: 'Egerlövő',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3462,
    city: 'Borsodivánka',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3463,
    city: 'Négyes',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3464,
    city: 'Tiszavalk',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3465,
    city: 'Tiszabábolna',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3466,
    city: 'Tiszadorogma',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3467,
    city: 'Ároktő',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3500,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3501,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3508,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3515,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3516,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3517,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3518,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3519,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3521,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3524,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3525,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3526,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3527,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3528,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3529,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3530,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3531,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3532,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3533,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3534,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3535,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3551,
    city: 'Ónod',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3552,
    city: 'Muhi',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3553,
    city: 'Kistokaj',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3554,
    city: 'Bükkaranyos',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3555,
    city: 'Harsány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3556,
    city: 'Kisgyőr',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3557,
    city: 'Bükkszentkereszt',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3558,
    city: 'Miskolc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3559,
    city: 'Répáshuta',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3561,
    city: 'Felsőzsolca',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3562,
    city: 'Onga',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3563,
    city: 'Hernádkak',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3564,
    city: 'Hernádnémeti',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3565,
    city: 'Tiszalúc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3571,
    city: 'Alsózsolca',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3572,
    city: 'Sajólád',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3573,
    city: 'Sajópetri',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3574,
    city: 'Bőcs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3575,
    city: 'Berzék',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3576,
    city: 'Sajóhídvég',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3577,
    city: 'Köröm',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3578,
    city: 'Kiscsécs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3578,
    city: 'Girincs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3579,
    city: 'Kesznyéten',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3580,
    city: 'Tiszaújváros',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3586,
    city: 'Sajóörös',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3587,
    city: 'Tiszapalkonya',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3588,
    city: 'Hejőkürt',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3589,
    city: 'Tiszatarján',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3591,
    city: 'Oszlár',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3592,
    city: 'Nemesbikk',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3593,
    city: 'Hejőbába',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3594,
    city: 'Hejőpapi',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3595,
    city: 'Hejőszalonta',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3596,
    city: 'Szakáld',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3597,
    city: 'Hejőkeresztúr',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3598,
    city: 'Nagycsécs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3599,
    city: 'Sajószöged',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3600,
    city: 'Ózd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3603,
    city: 'Ózd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3604,
    city: 'Ózd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3608,
    city: 'Farkaslyuk',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3621,
    city: 'Ózd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3622,
    city: 'Uppony',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3623,
    city: 'Borsodszentgyörgy',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3625,
    city: 'Ózd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3626,
    city: 'Hangony',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3627,
    city: 'Domaháza',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3627,
    city: 'Kissikátor',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3630,
    city: 'Putnok',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3635,
    city: 'Dubicsány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3636,
    city: 'Sajógalgóc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3636,
    city: 'Vadna',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3641,
    city: 'Nagybarca',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3642,
    city: 'Bánhorváti',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3643,
    city: 'Dédestapolcsány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3644,
    city: 'Tardona',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3645,
    city: 'Mályinka',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3646,
    city: 'Nekézseny',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3647,
    city: 'Csokvaomány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3648,
    city: 'Lénárddaróc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3648,
    city: 'Bükkmogyorósd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3648,
    city: 'Csernely',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3651,
    city: 'Ózd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3652,
    city: 'Sajónémeti',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3653,
    city: 'Sajópüspöki',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3654,
    city: 'Bánréve',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3655,
    city: 'Hét',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3656,
    city: 'Sajómercse',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3656,
    city: 'Sajóvelezd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3657,
    city: 'Királd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3658,
    city: 'Borsodbóta',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3659,
    city: 'Sáta',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3661,
    city: 'Ózd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3662,
    city: 'Ózd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3663,
    city: 'Arló',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3664,
    city: 'Járdánháza',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3671,
    city: 'Borsodnádasd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3672,
    city: 'Borsodnádasd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3700,
    city: 'Kazincbarcika',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3704,
    city: 'Berente',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3711,
    city: 'Szirmabesenyő',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3712,
    city: 'Sajóvámos',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3712,
    city: 'Sajósenye',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3713,
    city: 'Arnót',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3714,
    city: 'Sajópálfala',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3715,
    city: 'Gesztely',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3716,
    city: 'Újcsanálos',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3716,
    city: 'Sóstófalva',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3717,
    city: 'Alsódobsza',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3718,
    city: 'Megyaszó',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3720,
    city: 'Sajóivánka',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3720,
    city: 'Sajókaza',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3721,
    city: 'Jákfalva',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3721,
    city: 'Dövény',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3721,
    city: 'Felsőnyárád',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3722,
    city: 'Felsőkelecsény',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3723,
    city: 'Zubogy',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3724,
    city: 'Trizs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3724,
    city: 'Ragály',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3725,
    city: 'Imola',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3726,
    city: 'Alsószuha',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3726,
    city: 'Szuhafő',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3726,
    city: 'Zádorfalva',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3728,
    city: 'Gömörszőlős',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3728,
    city: 'Kelemér',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3729,
    city: 'Serényfalva',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3731,
    city: 'Szuhakálló',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3732,
    city: 'Kurityán',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3733,
    city: 'Rudabánya',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3734,
    city: 'Szuhogy',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3735,
    city: 'Alsótelekes',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3735,
    city: 'Kánó',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3735,
    city: 'Felsőtelekes',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3741,
    city: 'Izsófalva',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3742,
    city: 'Rudolftelep',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3743,
    city: 'Ormosbánya',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3744,
    city: 'Múcsony',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3751,
    city: 'Szendrőlád',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3752,
    city: 'Galvács',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3752,
    city: 'Szendrő',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3753,
    city: 'Abod',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3754,
    city: 'Meszes',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3754,
    city: 'Szalonna',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3755,
    city: 'Martonyi',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3756,
    city: 'Perkupa',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3756,
    city: 'Varbóc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3757,
    city: 'Teresztenye',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3757,
    city: 'Égerszög',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3757,
    city: 'Szőlősardó',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3758,
    city: 'Jósvafő',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3759,
    city: 'Aggtelek',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3761,
    city: 'Tornakápolna',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3761,
    city: 'Szinpetri',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3761,
    city: 'Szin',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3762,
    city: 'Szögliget',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3763,
    city: 'Bódvaszilas',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3764,
    city: 'Bódvarákó',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3765,
    city: 'Tornabarakony',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3765,
    city: 'Komjáti',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3765,
    city: 'Tornaszentandrás',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3767,
    city: 'Tornanádaska',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3768,
    city: 'Becskeháza',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3768,
    city: 'Bódvalenke',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3768,
    city: 'Hidvégardó',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3769,
    city: 'Tornaszentjakab',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3770,
    city: 'Sajószentpéter',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3773,
    city: 'Sajólászlófalva',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3773,
    city: 'Sajókápolna',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3775,
    city: 'Kondó',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3776,
    city: 'Radostyán',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3777,
    city: 'Parasznya',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3778,
    city: 'Varbó',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3779,
    city: 'Alacska',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3780,
    city: 'Balajt',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3780,
    city: 'Ládbesenyő',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3780,
    city: 'Edelény',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3780,
    city: 'Damak',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3783,
    city: 'Edelény',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3786,
    city: 'Lak',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3786,
    city: 'Szakácsi',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3786,
    city: 'Hegymeg',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3786,
    city: 'Irota',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3787,
    city: 'Tomor',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3791,
    city: 'Sajókeresztúr',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3792,
    city: 'Sajóbábony',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3793,
    city: 'Sajóecseg',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3794,
    city: 'Boldva',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3794,
    city: 'Ziliz',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3795,
    city: 'Nyomár',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3795,
    city: 'Hangács',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3796,
    city: 'Borsodszirák',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3800,
    city: 'Szikszó',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3809,
    city: 'Nyésta',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3809,
    city: 'Selyeb',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3809,
    city: 'Abaújszolnok',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3811,
    city: 'Alsóvadász',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3812,
    city: 'Homrogd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3812,
    city: 'Monaj',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3813,
    city: 'Kupa',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3814,
    city: 'Felsővadász',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3815,
    city: 'Gadna',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3815,
    city: 'Abaújlak',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3816,
    city: 'Gagyvendégi',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3817,
    city: 'Gagybátor',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3821,
    city: 'Kány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3821,
    city: 'Szászfa',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3821,
    city: 'Keresztéte',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3821,
    city: 'Perecse',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3821,
    city: 'Pamlény',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3821,
    city: 'Krasznokvajda',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3821,
    city: 'Büttös',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3825,
    city: 'Rakaca',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3825,
    city: 'Viszló',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3825,
    city: 'Debréte',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3826,
    city: 'Rakacaszend',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3831,
    city: 'Kázsmárk',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3832,
    city: 'Léh',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3833,
    city: 'Rásonysápberencs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3834,
    city: 'Beret',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3834,
    city: 'Detek',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3836,
    city: 'Baktakék',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3837,
    city: 'Alsógagy',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3837,
    city: 'Felsőgagy',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3837,
    city: 'Gagyapáti',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3837,
    city: 'Csenyéte',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3841,
    city: 'Aszaló',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3842,
    city: 'Halmaj',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3843,
    city: 'Kiskinizs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3844,
    city: 'Nagykinizs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3844,
    city: 'Szentistvánbaksa',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3846,
    city: 'Hernádkércs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3847,
    city: 'Felsődobsza',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3848,
    city: 'Csobád',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3849,
    city: 'Forró',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3851,
    city: 'Ináncs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3852,
    city: 'Hernádszentandrás',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3853,
    city: 'Pere',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3853,
    city: 'Hernádbűd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3854,
    city: 'Gibárt',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3855,
    city: 'Fancsal',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3860,
    city: 'Encs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3863,
    city: 'Szalaszend',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3864,
    city: 'Fulókércs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3865,
    city: 'Fáj',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3866,
    city: 'Litka',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3866,
    city: 'Szemere',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3871,
    city: 'Méra',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3872,
    city: 'Novajidrány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3873,
    city: 'Garadna',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3874,
    city: 'Pusztaradvány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3874,
    city: 'Hernádvécse',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3874,
    city: 'Hernádpetri',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3875,
    city: 'Hernádszurdok',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3876,
    city: 'Hidasnémeti',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3877,
    city: 'Tornyosnémeti',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3881,
    city: 'Baskó',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3881,
    city: 'Abaújszántó',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3881,
    city: 'Sima',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3882,
    city: 'Abaújkér',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3882,
    city: 'Abaújalpár',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3884,
    city: 'Boldogkőújfalu',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3885,
    city: 'Arka',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3885,
    city: 'Boldogkőváralja',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3886,
    city: 'Korlát',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3887,
    city: 'Hernádcéce',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3888,
    city: 'Vizsoly',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3891,
    city: 'Vilmány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3892,
    city: 'Hejce',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3893,
    city: 'Regéc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3893,
    city: 'Mogyoróska',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3893,
    city: 'Fony',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3894,
    city: 'Göncruszka',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3895,
    city: 'Gönc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3896,
    city: 'Telkibánya',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3897,
    city: 'Zsujta',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3898,
    city: 'Pányok',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3898,
    city: 'Abaújvár',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3899,
    city: 'Kéked',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3900,
    city: 'Szerencs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3902,
    city: 'Szerencs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3903,
    city: 'Bekecs',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3904,
    city: 'Legyesbénye',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3905,
    city: 'Monok',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3906,
    city: 'Golop',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3907,
    city: 'Tállya',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3908,
    city: 'Rátka',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3909,
    city: 'Mád',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3910,
    city: 'Tokaj',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3915,
    city: 'Tarcal',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3916,
    city: 'Bodrogkeresztúr',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3917,
    city: 'Bodrogkisfalud',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3918,
    city: 'Szegi',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3918,
    city: 'Szegilong',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3921,
    city: 'Taktaszada',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3922,
    city: 'Taktaharkány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3923,
    city: 'Gesztely',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3924,
    city: 'Taktakenéz',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3925,
    city: 'Prügy',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3926,
    city: 'Taktabáj',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3927,
    city: 'Csobaj',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3928,
    city: 'Tiszatardos',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3929,
    city: 'Tiszaladány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3931,
    city: 'Mezőzombor',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3932,
    city: 'Erdőbénye',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3933,
    city: 'Olaszliszka',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3934,
    city: 'Tolcsva',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3935,
    city: 'Erdőhorváti',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3936,
    city: 'Háromhuta',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3937,
    city: 'Komlóska',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3941,
    city: 'Vámosújfalu',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3942,
    city: 'Sárazsadány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3943,
    city: 'Bodrogolaszi',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3944,
    city: 'Sátoraljaújhely',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3945,
    city: 'Sátoraljaújhely',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3950,
    city: 'Sárospatak',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3954,
    city: 'Györgytarló',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3955,
    city: 'Kenézlő',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3956,
    city: 'Viss',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3957,
    city: 'Zalkod',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3958,
    city: 'Hercegkút',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3959,
    city: 'Makkoshotyka',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3961,
    city: 'Vajdácska',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3962,
    city: 'Karos',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3963,
    city: 'Karcsa',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3964,
    city: 'Pácin',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3965,
    city: 'Kisrozvágy',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3965,
    city: 'Nagyrozvágy',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3967,
    city: 'Lácacséke',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3971,
    city: 'Tiszakarád',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3972,
    city: 'Tiszacsermely',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3973,
    city: 'Cigánd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3974,
    city: 'Ricse',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3974,
    city: 'Semjén',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3976,
    city: 'Révleányvár',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3977,
    city: 'Zemplénagárd',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3978,
    city: 'Dámóc',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3980,
    city: 'Sátoraljaújhely',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3985,
    city: 'Felsőberecki',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3985,
    city: 'Alsóberecki',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3987,
    city: 'Bodroghalom',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3988,
    city: 'Sátoraljaújhely',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3989,
    city: 'Mikóháza',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3989,
    city: 'Felsőregmec',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3989,
    city: 'Alsóregmec',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3991,
    city: 'Vilyvitány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3992,
    city: 'Vágáshuta',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3992,
    city: 'Kovácsvágás',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3993,
    city: 'Füzérradvány',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3994,
    city: 'Füzérkajata',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3994,
    city: 'Nagyhuta',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3994,
    city: 'Filkeháza',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3994,
    city: 'Bózsva',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3994,
    city: 'Pálháza',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3994,
    city: 'Kishuta',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3995,
    city: 'Pusztafalu',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3996,
    city: 'Füzér',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3997,
    city: 'Füzérkomlós',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3998,
    city: 'Nyíri',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 3999,
    city: 'Hollóháza',
    county: 'Borsod-Abaúj-Zemplén',
  },
  {
    postal: 6600,
    city: 'Szentes',
    county: 'Csongrád',
  },
  {
    postal: 6612,
    city: 'Nagytőke',
    county: 'Csongrád',
  },
  {
    postal: 6621,
    city: 'Derekegyház',
    county: 'Csongrád',
  },
  {
    postal: 6622,
    city: 'Nagymágocs',
    county: 'Csongrád',
  },
  {
    postal: 6623,
    city: 'Árpádhalom',
    county: 'Csongrád',
  },
  {
    postal: 6624,
    city: 'Eperjes',
    county: 'Csongrád',
  },
  {
    postal: 6625,
    city: 'Fábiánsebestyén',
    county: 'Csongrád',
  },
  {
    postal: 6630,
    city: 'Mindszent',
    county: 'Csongrád',
  },
  {
    postal: 6635,
    city: 'Szegvár',
    county: 'Csongrád',
  },
  {
    postal: 6636,
    city: 'Mártély',
    county: 'Csongrád',
  },
  {
    postal: 6640,
    city: 'Csongrád',
    county: 'Csongrád',
  },
  {
    postal: 6645,
    city: 'Felgyő',
    county: 'Csongrád',
  },
  {
    postal: 6646,
    city: 'Tömörkény',
    county: 'Csongrád',
  },
  {
    postal: 6647,
    city: 'Csanytelek',
    county: 'Csongrád',
  },
  {
    postal: 6648,
    city: 'Csongrád',
    county: 'Csongrád',
  },
  {
    postal: 6700,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6710,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6720,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6721,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6722,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6723,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6724,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6725,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6726,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6727,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6728,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6729,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6750,
    city: 'Algyő',
    county: 'Csongrád',
  },
  {
    postal: 6753,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6754,
    city: 'Újszentiván',
    county: 'Csongrád',
  },
  {
    postal: 6755,
    city: 'Kübekháza',
    county: 'Csongrád',
  },
  {
    postal: 6756,
    city: 'Tiszasziget',
    county: 'Csongrád',
  },
  {
    postal: 6757,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6758,
    city: 'Röszke',
    county: 'Csongrád',
  },
  {
    postal: 6760,
    city: 'Kistelek',
    county: 'Csongrád',
  },
  {
    postal: 6762,
    city: 'Sándorfalva',
    county: 'Csongrád',
  },
  {
    postal: 6763,
    city: 'Szatymaz',
    county: 'Csongrád',
  },
  {
    postal: 6764,
    city: 'Balástya',
    county: 'Csongrád',
  },
  {
    postal: 6765,
    city: 'Csengele',
    county: 'Csongrád',
  },
  {
    postal: 6766,
    city: 'Dóc',
    county: 'Csongrád',
  },
  {
    postal: 6767,
    city: 'Ópusztaszer',
    county: 'Csongrád',
  },
  {
    postal: 6768,
    city: 'Baks',
    county: 'Csongrád',
  },
  {
    postal: 6769,
    city: 'Pusztaszer',
    county: 'Csongrád',
  },
  {
    postal: 6771,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6772,
    city: 'Deszk',
    county: 'Csongrád',
  },
  {
    postal: 6773,
    city: 'Klárafalva',
    county: 'Csongrád',
  },
  {
    postal: 6774,
    city: 'Ferencszállás',
    county: 'Csongrád',
  },
  {
    postal: 6775,
    city: 'Kiszombor',
    county: 'Csongrád',
  },
  {
    postal: 6781,
    city: 'Domaszék',
    county: 'Csongrád',
  },
  {
    postal: 6782,
    city: 'Mórahalom',
    county: 'Csongrád',
  },
  {
    postal: 6783,
    city: 'Ásotthalom',
    county: 'Csongrád',
  },
  {
    postal: 6784,
    city: 'Öttömös',
    county: 'Csongrád',
  },
  {
    postal: 6785,
    city: 'Pusztamérges',
    county: 'Csongrád',
  },
  {
    postal: 6786,
    city: 'Ruzsa',
    county: 'Csongrád',
  },
  {
    postal: 6787,
    city: 'Zákányszék',
    county: 'Csongrád',
  },
  {
    postal: 6791,
    city: 'Szeged',
    county: 'Csongrád',
  },
  {
    postal: 6792,
    city: 'Zsombó',
    county: 'Csongrád',
  },
  {
    postal: 6793,
    city: 'Forráskút',
    county: 'Csongrád',
  },
  {
    postal: 6794,
    city: 'Üllés',
    county: 'Csongrád',
  },
  {
    postal: 6795,
    city: 'Bordány',
    county: 'Csongrád',
  },
  {
    postal: 6800,
    city: 'Hódmezővásárhely',
    county: 'Csongrád',
  },
  {
    postal: 6805,
    city: 'Hódmezővásárhely',
    county: 'Csongrád',
  },
  {
    postal: 6806,
    city: 'Hódmezővásárhely',
    county: 'Csongrád',
  },
  {
    postal: 6821,
    city: 'Székkutas',
    county: 'Csongrád',
  },
  {
    postal: 6900,
    city: 'Makó',
    county: 'Csongrád',
  },
  {
    postal: 6903,
    city: 'Makó',
    county: 'Csongrád',
  },
  {
    postal: 6911,
    city: 'Királyhegyes',
    county: 'Csongrád',
  },
  {
    postal: 6912,
    city: 'Kövegy',
    county: 'Csongrád',
  },
  {
    postal: 6913,
    city: 'Csanádpalota',
    county: 'Csongrád',
  },
  {
    postal: 6914,
    city: 'Pitvaros',
    county: 'Csongrád',
  },
  {
    postal: 6915,
    city: 'Csanádalberti',
    county: 'Csongrád',
  },
  {
    postal: 6916,
    city: 'Ambrózfalva',
    county: 'Csongrád',
  },
  {
    postal: 6917,
    city: 'Nagyér',
    county: 'Csongrád',
  },
  {
    postal: 6921,
    city: 'Maroslele',
    county: 'Csongrád',
  },
  {
    postal: 6922,
    city: 'Földeák',
    county: 'Csongrád',
  },
  {
    postal: 6923,
    city: 'Óföldeák',
    county: 'Csongrád',
  },
  {
    postal: 6931,
    city: 'Apátfalva',
    county: 'Csongrád',
  },
  {
    postal: 6932,
    city: 'Magyarcsanád',
    county: 'Csongrád',
  },
  {
    postal: 6933,
    city: 'Nagylak',
    county: 'Csongrád',
  },
  {
    postal: 2060,
    city: 'Bicske',
    county: 'Fejér',
  },
  {
    postal: 2063,
    city: 'Óbarok',
    county: 'Fejér',
  },
  {
    postal: 2064,
    city: 'Csabdi',
    county: 'Fejér',
  },
  {
    postal: 2065,
    city: 'Mány',
    county: 'Fejér',
  },
  {
    postal: 2066,
    city: 'Újbarok',
    county: 'Fejér',
  },
  {
    postal: 2066,
    city: 'Szár',
    county: 'Fejér',
  },
  {
    postal: 2067,
    city: 'Szárliget',
    county: 'Fejér',
  },
  {
    postal: 2091,
    city: 'Etyek',
    county: 'Fejér',
  },
  {
    postal: 2400,
    city: 'Dunaújváros',
    county: 'Fejér',
  },
  {
    postal: 2407,
    city: 'Dunaújváros',
    county: 'Fejér',
  },
  {
    postal: 2421,
    city: 'Nagyvenyim',
    county: 'Fejér',
  },
  {
    postal: 2422,
    city: 'Mezőfalva',
    county: 'Fejér',
  },
  {
    postal: 2423,
    city: 'Daruszentmiklós',
    county: 'Fejér',
  },
  {
    postal: 2424,
    city: 'Előszállás',
    county: 'Fejér',
  },
  {
    postal: 2425,
    city: 'Nagykarácsony',
    county: 'Fejér',
  },
  {
    postal: 2426,
    city: 'Baracs',
    county: 'Fejér',
  },
  {
    postal: 2427,
    city: 'Baracs',
    county: 'Fejér',
  },
  {
    postal: 2428,
    city: 'Kisapostag',
    county: 'Fejér',
  },
  {
    postal: 2431,
    city: 'Perkáta',
    county: 'Fejér',
  },
  {
    postal: 2432,
    city: 'Szabadegyháza',
    county: 'Fejér',
  },
  {
    postal: 2433,
    city: 'Sárosd',
    county: 'Fejér',
  },
  {
    postal: 2434,
    city: 'Hantos',
    county: 'Fejér',
  },
  {
    postal: 2435,
    city: 'Nagylók',
    county: 'Fejér',
  },
  {
    postal: 2451,
    city: 'Ercsi',
    county: 'Fejér',
  },
  {
    postal: 2453,
    city: 'Ercsi',
    county: 'Fejér',
  },
  {
    postal: 2454,
    city: 'Iváncsa',
    county: 'Fejér',
  },
  {
    postal: 2455,
    city: 'Beloiannisz',
    county: 'Fejér',
  },
  {
    postal: 2456,
    city: 'Besnyő',
    county: 'Fejér',
  },
  {
    postal: 2457,
    city: 'Adony',
    county: 'Fejér',
  },
  {
    postal: 2458,
    city: 'Kulcs',
    county: 'Fejér',
  },
  {
    postal: 2459,
    city: 'Rácalmás',
    county: 'Fejér',
  },
  {
    postal: 2462,
    city: 'Martonvásár',
    county: 'Fejér',
  },
  {
    postal: 2463,
    city: 'Tordas',
    county: 'Fejér',
  },
  {
    postal: 2464,
    city: 'Gyúró',
    county: 'Fejér',
  },
  {
    postal: 2465,
    city: 'Ráckeresztúr',
    county: 'Fejér',
  },
  {
    postal: 2471,
    city: 'Baracska',
    county: 'Fejér',
  },
  {
    postal: 2472,
    city: 'Kajászó',
    county: 'Fejér',
  },
  {
    postal: 2473,
    city: 'Vál',
    county: 'Fejér',
  },
  {
    postal: 2475,
    city: 'Kápolnásnyék',
    county: 'Fejér',
  },
  {
    postal: 2476,
    city: 'Pázmánd',
    county: 'Fejér',
  },
  {
    postal: 2477,
    city: 'Vereb',
    county: 'Fejér',
  },
  {
    postal: 2481,
    city: 'Velence',
    county: 'Fejér',
  },
  {
    postal: 2483,
    city: 'Gárdony',
    county: 'Fejér',
  },
  {
    postal: 2484,
    city: 'Gárdony',
    county: 'Fejér',
  },
  {
    postal: 2485,
    city: 'Gárdony',
    county: 'Fejér',
  },
  {
    postal: 2490,
    city: 'Pusztaszabolcs',
    county: 'Fejér',
  },
  {
    postal: 7000,
    city: 'Sárbogárd',
    county: 'Fejér',
  },
  {
    postal: 7003,
    city: 'Sárbogárd',
    county: 'Fejér',
  },
  {
    postal: 7011,
    city: 'Alap',
    county: 'Fejér',
  },
  {
    postal: 7012,
    city: 'Alsószentiván',
    county: 'Fejér',
  },
  {
    postal: 7013,
    city: 'Cece',
    county: 'Fejér',
  },
  {
    postal: 7014,
    city: 'Sáregres',
    county: 'Fejér',
  },
  {
    postal: 7015,
    city: 'Igar',
    county: 'Fejér',
  },
  {
    postal: 7017,
    city: 'Mezőszilas',
    county: 'Fejér',
  },
  {
    postal: 7018,
    city: 'Sárbogárd',
    county: 'Fejér',
  },
  {
    postal: 7019,
    city: 'Sárbogárd',
    county: 'Fejér',
  },
  {
    postal: 7041,
    city: 'Vajta',
    county: 'Fejér',
  },
  {
    postal: 8000,
    city: 'Székesfehérvár',
    county: 'Fejér',
  },
  {
    postal: 8019,
    city: 'Székesfehérvár',
    county: 'Fejér',
  },
  {
    postal: 8041,
    city: 'Csór',
    county: 'Fejér',
  },
  {
    postal: 8042,
    city: 'Moha',
    county: 'Fejér',
  },
  {
    postal: 8043,
    city: 'Iszkaszentgyörgy',
    county: 'Fejér',
  },
  {
    postal: 8044,
    city: 'Kincsesbánya',
    county: 'Fejér',
  },
  {
    postal: 8045,
    city: 'Isztimér',
    county: 'Fejér',
  },
  {
    postal: 8046,
    city: 'Bakonykúti',
    county: 'Fejér',
  },
  {
    postal: 8051,
    city: 'Sárkeresztes',
    county: 'Fejér',
  },
  {
    postal: 8052,
    city: 'Fehérvárcsurgó',
    county: 'Fejér',
  },
  {
    postal: 8053,
    city: 'Bodajk',
    county: 'Fejér',
  },
  {
    postal: 8054,
    city: 'Balinka',
    county: 'Fejér',
  },
  {
    postal: 8055,
    city: 'Balinka',
    county: 'Fejér',
  },
  {
    postal: 8056,
    city: 'Bakonycsernye',
    county: 'Fejér',
  },
  {
    postal: 8060,
    city: 'Mór',
    county: 'Fejér',
  },
  {
    postal: 8065,
    city: 'Nagyveleg',
    county: 'Fejér',
  },
  {
    postal: 8066,
    city: 'Pusztavám',
    county: 'Fejér',
  },
  {
    postal: 8071,
    city: 'Magyaralmás',
    county: 'Fejér',
  },
  {
    postal: 8072,
    city: 'Söréd',
    county: 'Fejér',
  },
  {
    postal: 8073,
    city: 'Csákberény',
    county: 'Fejér',
  },
  {
    postal: 8074,
    city: 'Csókakő',
    county: 'Fejér',
  },
  {
    postal: 8080,
    city: 'Bodmér',
    county: 'Fejér',
  },
  {
    postal: 8081,
    city: 'Zámoly',
    county: 'Fejér',
  },
  {
    postal: 8082,
    city: 'Gánt',
    county: 'Fejér',
  },
  {
    postal: 8083,
    city: 'Csákvár',
    county: 'Fejér',
  },
  {
    postal: 8085,
    city: 'Vértesboglár',
    county: 'Fejér',
  },
  {
    postal: 8086,
    city: 'Felcsút',
    county: 'Fejér',
  },
  {
    postal: 8087,
    city: 'Alcsútdoboz',
    county: 'Fejér',
  },
  {
    postal: 8088,
    city: 'Tabajd',
    county: 'Fejér',
  },
  {
    postal: 8089,
    city: 'Vértesacsa',
    county: 'Fejér',
  },
  {
    postal: 8092,
    city: 'Pátka',
    county: 'Fejér',
  },
  {
    postal: 8093,
    city: 'Lovasberény',
    county: 'Fejér',
  },
  {
    postal: 8095,
    city: 'Pákozd',
    county: 'Fejér',
  },
  {
    postal: 8096,
    city: 'Sukoró',
    county: 'Fejér',
  },
  {
    postal: 8097,
    city: 'Nadap',
    county: 'Fejér',
  },
  {
    postal: 8111,
    city: 'Seregélyes',
    county: 'Fejér',
  },
  {
    postal: 8112,
    city: 'Zichyújfalu',
    county: 'Fejér',
  },
  {
    postal: 8121,
    city: 'Tác',
    county: 'Fejér',
  },
  {
    postal: 8122,
    city: 'Csősz',
    county: 'Fejér',
  },
  {
    postal: 8123,
    city: 'Soponya',
    county: 'Fejér',
  },
  {
    postal: 8124,
    city: 'Káloz',
    county: 'Fejér',
  },
  {
    postal: 8125,
    city: 'Sárkeresztúr',
    county: 'Fejér',
  },
  {
    postal: 8126,
    city: 'Sárszentágota',
    county: 'Fejér',
  },
  {
    postal: 8127,
    city: 'Aba',
    county: 'Fejér',
  },
  {
    postal: 8130,
    city: 'Enying',
    county: 'Fejér',
  },
  {
    postal: 8131,
    city: 'Enying',
    county: 'Fejér',
  },
  {
    postal: 8132,
    city: 'Lepsény',
    county: 'Fejér',
  },
  {
    postal: 8133,
    city: 'Mezőszentgyörgy',
    county: 'Fejér',
  },
  {
    postal: 8134,
    city: 'Mátyásdomb',
    county: 'Fejér',
  },
  {
    postal: 8135,
    city: 'Dég',
    county: 'Fejér',
  },
  {
    postal: 8136,
    city: 'Lajoskomárom',
    county: 'Fejér',
  },
  {
    postal: 8137,
    city: 'Mezőkomárom',
    county: 'Fejér',
  },
  {
    postal: 8138,
    city: 'Szabadhídvég',
    county: 'Fejér',
  },
  {
    postal: 8142,
    city: 'Úrhida',
    county: 'Fejér',
  },
  {
    postal: 8143,
    city: 'Sárszentmihály',
    county: 'Fejér',
  },
  {
    postal: 8144,
    city: 'Sárkeszi',
    county: 'Fejér',
  },
  {
    postal: 8145,
    city: 'Nádasdladány',
    county: 'Fejér',
  },
  {
    postal: 8146,
    city: 'Jenő',
    county: 'Fejér',
  },
  {
    postal: 8151,
    city: 'Szabadbattyán',
    county: 'Fejér',
  },
  {
    postal: 8152,
    city: 'Kőszárhegy',
    county: 'Fejér',
  },
  {
    postal: 8154,
    city: 'Polgárdi',
    county: 'Fejér',
  },
  {
    postal: 8156,
    city: 'Kisláng',
    county: 'Fejér',
  },
  {
    postal: 8157,
    city: 'Füle',
    county: 'Fejér',
  },
  {
    postal: 9000,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9011,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9012,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9019,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9021,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9022,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9023,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9024,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9025,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9026,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9027,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9028,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9029,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9030,
    city: 'Győr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9061,
    city: 'Vámosszabadi',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9062,
    city: 'Vének',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9062,
    city: 'Kisbajcs',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9063,
    city: 'Nagybajcs',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9064,
    city: 'Vámosszabadi',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9071,
    city: 'Gönyű',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9072,
    city: 'Nagyszentjános',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9073,
    city: 'Bőny',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9074,
    city: 'Rétalap',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9081,
    city: 'Győrújbarát',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9082,
    city: 'Nyúl',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9083,
    city: 'Écs',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9084,
    city: 'Győrság',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9085,
    city: 'Pázmándfalu',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9086,
    city: 'Töltéstava',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9088,
    city: 'Bakonypéterd',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9089,
    city: 'Lázi',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9090,
    city: 'Pannonhalma',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9091,
    city: 'Ravazd',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9092,
    city: 'Tarjánpuszta',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9093,
    city: 'Győrasszonyfa',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9094,
    city: 'Tápszentmiklós',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9095,
    city: 'Táp',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9096,
    city: 'Nyalka',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9097,
    city: 'Mezőörs',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9098,
    city: 'Pér',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9099,
    city: 'Pér',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9100,
    city: 'Tét',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9111,
    city: 'Tényő',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9112,
    city: 'Sokorópátka',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9113,
    city: 'Koroncó',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9121,
    city: 'Győrszemere',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9122,
    city: 'Felpéc',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9123,
    city: 'Kajárpéc',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9124,
    city: 'Gyömöre',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9125,
    city: 'Szerecseny',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9126,
    city: 'Gyarmat',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9127,
    city: 'Csikvánd',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9131,
    city: 'Mórichida',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9132,
    city: 'Árpás',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9133,
    city: 'Kisbabot',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9133,
    city: 'Rábaszentmiklós',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9134,
    city: 'Bodonhely',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9135,
    city: 'Rábaszentmihály',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9136,
    city: 'Mérges',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9136,
    city: 'Rábacsécsény',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9141,
    city: 'Ikrény',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9142,
    city: 'Rábapatona',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9143,
    city: 'Enese',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9144,
    city: 'Kóny',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9145,
    city: 'Bágyogszovát',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9146,
    city: 'Rábapordány',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9147,
    city: 'Dör',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9151,
    city: 'Abda',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9152,
    city: 'Börcs',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9153,
    city: 'Öttevény',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9154,
    city: 'Mosonszentmiklós',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9155,
    city: 'Lébény',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9161,
    city: 'Győrsövényház',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9162,
    city: 'Bezi',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9163,
    city: 'Fehértó',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9164,
    city: 'Markotabödöge',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9165,
    city: 'Tárnokréti',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9165,
    city: 'Cakóháza',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9165,
    city: 'Rábcakapi',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9167,
    city: 'Bősárkány',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9167,
    city: 'Jánossomorja',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9168,
    city: 'Csorna',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9168,
    city: 'Acsalag',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9169,
    city: 'Barbacs',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9169,
    city: 'Maglóca',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9171,
    city: 'Győrújfalu',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9172,
    city: 'Győrzámoly',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9173,
    city: 'Győrladamér',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9174,
    city: 'Dunaszeg',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9175,
    city: 'Dunaszentpál',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9176,
    city: 'Mecsér',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9177,
    city: 'Ásványráró',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9178,
    city: 'Hédervár',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9181,
    city: 'Kimle',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9182,
    city: 'Károlyháza',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9183,
    city: 'Mosonszentmiklós',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9184,
    city: 'Kunsziget',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9200,
    city: 'Mosonmagyaróvár',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9211,
    city: 'Feketeerdő',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9221,
    city: 'Levél',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9222,
    city: 'Hegyeshalom',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9223,
    city: 'Bezenye',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9224,
    city: 'Rajka',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9225,
    city: 'Dunakiliti',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9226,
    city: 'Dunasziget',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9228,
    city: 'Halászi',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9231,
    city: 'Máriakálnok',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9232,
    city: 'Darnózseli',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9233,
    city: 'Lipót',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9234,
    city: 'Kisbodak',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9235,
    city: 'Dunaremete',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9235,
    city: 'Püski',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9241,
    city: 'Jánossomorja',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9242,
    city: 'Jánossomorja',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9243,
    city: 'Várbalog',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9243,
    city: 'Jánossomorja',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9244,
    city: 'Újrónafő',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9245,
    city: 'Mosonszolnok',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9246,
    city: 'Mosonudvar',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9300,
    city: 'Csorna',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9311,
    city: 'Pásztori',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9312,
    city: 'Szilsárkány',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9313,
    city: 'Rábacsanak',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9314,
    city: 'Egyed',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9315,
    city: 'Sobor',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9316,
    city: 'Rábaszentandrás',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9317,
    city: 'Szany',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9321,
    city: 'Farád',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9322,
    city: 'Rábatamási',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9323,
    city: 'Jobaháza',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9324,
    city: 'Potyond',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9324,
    city: 'Bogyoszló',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9325,
    city: 'Sopronnémeti',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9326,
    city: 'Szil',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9327,
    city: 'Rábasebes',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9327,
    city: 'Vág',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9330,
    city: 'Kapuvár',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9339,
    city: 'Kapuvár',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9341,
    city: 'Kisfalud',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9342,
    city: 'Mihályi',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9343,
    city: 'Edve',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9343,
    city: 'Beled',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9343,
    city: 'Vásárosfalu',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9344,
    city: 'Rábakecöl',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9345,
    city: 'Páli',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9346,
    city: 'Zsebeháza',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9346,
    city: 'Vadosfa',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9346,
    city: 'Magyarkeresztúr',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9351,
    city: 'Babót',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9352,
    city: 'Veszkény',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9353,
    city: 'Szárföld',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9354,
    city: 'Osli',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9361,
    city: 'Hövej',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9362,
    city: 'Himod',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9363,
    city: 'Gyóró',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9364,
    city: 'Cirák',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9365,
    city: 'Dénesfa',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9371,
    city: 'Vitnyéd',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9372,
    city: 'Csapod',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9373,
    city: 'Pusztacsalád',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9374,
    city: 'Iván',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9375,
    city: 'Csáfordjánosfa',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9375,
    city: 'Répceszemere',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9375,
    city: 'Csér',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9400,
    city: 'Sopron',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9407,
    city: 'Sopron',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9408,
    city: 'Sopron',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9421,
    city: 'Fertőrákos',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9422,
    city: 'Harka',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9423,
    city: 'Ágfalva',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9431,
    city: 'Fertőd',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9433,
    city: 'Fertőd',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9434,
    city: 'Sarród',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9435,
    city: 'Sarród',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9436,
    city: 'Fertőszéplak',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9437,
    city: 'Hegykő',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9438,
    city: 'Sarród',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9441,
    city: 'Agyagosszergény',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9442,
    city: 'Fertőendréd',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9443,
    city: 'Petőháza',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9444,
    city: 'Fertőszentmiklós',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9451,
    city: 'Ebergőc',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9451,
    city: 'Röjtökmuzsaj',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9461,
    city: 'Lövő',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9462,
    city: 'Völcsej',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9463,
    city: 'Sopronhorpács',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9464,
    city: 'Und',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9471,
    city: 'Nemeskér',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9472,
    city: 'Újkér',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9473,
    city: 'Egyházasfalu',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9474,
    city: 'Szakony',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9474,
    city: 'Gyalóka',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9475,
    city: 'Répcevis',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9476,
    city: 'Zsira',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9481,
    city: 'Pinnye',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9482,
    city: 'Nagylózs',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9483,
    city: 'Sopronkövesd',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9484,
    city: 'Pereszteg',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9485,
    city: 'Nagycenk',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9491,
    city: 'Hidegség',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9492,
    city: 'Fertőhomok',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9493,
    city: 'Fertőboz',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9494,
    city: 'Sopron',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 9495,
    city: 'Kópháza',
    county: 'Győr-Moson-Sopron',
  },
  {
    postal: 4000,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4002,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4024,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4025,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4026,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4027,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4028,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4029,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4030,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4031,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4032,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4033,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4034,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4042,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4060,
    city: 'Balmazújváros',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4063,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4064,
    city: 'Nagyhegyes',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4065,
    city: 'Újszentmargita',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4066,
    city: 'Tiszacsege',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4067,
    city: 'Egyek',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4069,
    city: 'Egyek',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4071,
    city: 'Hortobágy',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4074,
    city: 'Hajdúböszörmény',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4075,
    city: 'Görbeháza',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4078,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4079,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4080,
    city: 'Hajdúnánás',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4085,
    city: 'Hajdúnánás',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4086,
    city: 'Hajdúböszörmény',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4087,
    city: 'Hajdúdorog',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4090,
    city: 'Polgár',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4095,
    city: 'Folyás',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4096,
    city: 'Újtikos',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4097,
    city: 'Tiszagyulaháza',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4100,
    city: 'Berettyóújfalu',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4110,
    city: 'Biharkeresztes',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4114,
    city: 'Bojt',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4115,
    city: 'Ártánd',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4116,
    city: 'Berekböszörmény',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4117,
    city: 'Told',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4118,
    city: 'Mezőpeterd',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4119,
    city: 'Váncsod',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4121,
    city: 'Szentpéterszeg',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4122,
    city: 'Gáborján',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4123,
    city: 'Hencida',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4124,
    city: 'Esztár',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4125,
    city: 'Pocsaj',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4126,
    city: 'Kismarja',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4127,
    city: 'Nagykereki',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4128,
    city: 'Bedő',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4130,
    city: 'Derecske',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4132,
    city: 'Tépe',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4133,
    city: 'Konyár',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4134,
    city: 'Mezősas',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4135,
    city: 'Körösszegapáti',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4136,
    city: 'Körösszakál',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4137,
    city: 'Magyarhomorog',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4138,
    city: 'Komádi',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4141,
    city: 'Furta',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4142,
    city: 'Zsáka',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4143,
    city: 'Vekerd',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4144,
    city: 'Darvas',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4145,
    city: 'Csökmő',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4146,
    city: 'Újiráz',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4150,
    city: 'Püspökladány',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4161,
    city: 'Báránd',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4162,
    city: 'Szerep',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4163,
    city: 'Szerep',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4164,
    city: 'Bakonszeg',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4171,
    city: 'Sárrétudvari',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4172,
    city: 'Biharnagybajom',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4173,
    city: 'Nagyrábé',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4174,
    city: 'Bihartorda',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4175,
    city: 'Bihardancsháza',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4176,
    city: 'Sáp',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4177,
    city: 'Földes',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4181,
    city: 'Nádudvar',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4183,
    city: 'Kaba',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4184,
    city: 'Tetétlen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4200,
    city: 'Hajdúszoboszló',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4211,
    city: 'Ebes',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4212,
    city: 'Hajdúszovát',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4220,
    city: 'Hajdúböszörmény',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4224,
    city: 'Hajdúböszörmény',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4225,
    city: 'Debrecen',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4241,
    city: 'Bocskaikert',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4242,
    city: 'Hajdúhadház',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4243,
    city: 'Téglás',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4251,
    city: 'Hajdúsámson',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4252,
    city: 'Nyíradony',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4253,
    city: 'Nyíradony',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4254,
    city: 'Nyíradony',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4262,
    city: 'Nyíracsád',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4263,
    city: 'Nyírmártonfalva',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4264,
    city: 'Nyírábrány',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4266,
    city: 'Fülöp',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4271,
    city: 'Mikepércs',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4272,
    city: 'Sáránd',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4273,
    city: 'Hajdúbagos',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4274,
    city: 'Hosszúpályi',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4275,
    city: 'Monostorpályi',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4281,
    city: 'Létavértes',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4283,
    city: 'Létavértes',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4284,
    city: 'Kokad',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4285,
    city: 'Álmosd',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4286,
    city: 'Bagamér',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4287,
    city: 'Vámospércs',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 4288,
    city: 'Újléta',
    county: 'Hajdú-Bihar',
  },
  {
    postal: 3000,
    city: 'Hatvan',
    county: 'Heves',
  },
  {
    postal: 3009,
    city: 'Kerekharaszt',
    county: 'Heves',
  },
  {
    postal: 3011,
    city: 'Heréd',
    county: 'Heves',
  },
  {
    postal: 3012,
    city: 'Nagykökényes',
    county: 'Heves',
  },
  {
    postal: 3013,
    city: 'Ecséd',
    county: 'Heves',
  },
  {
    postal: 3014,
    city: 'Hort',
    county: 'Heves',
  },
  {
    postal: 3015,
    city: 'Csány',
    county: 'Heves',
  },
  {
    postal: 3016,
    city: 'Boldog',
    county: 'Heves',
  },
  {
    postal: 3021,
    city: 'Lőrinci',
    county: 'Heves',
  },
  {
    postal: 3022,
    city: 'Lőrinci',
    county: 'Heves',
  },
  {
    postal: 3023,
    city: 'Petőfibánya',
    county: 'Heves',
  },
  {
    postal: 3024,
    city: 'Lőrinci',
    county: 'Heves',
  },
  {
    postal: 3031,
    city: 'Zagyvaszántó',
    county: 'Heves',
  },
  {
    postal: 3032,
    city: 'Apc',
    county: 'Heves',
  },
  {
    postal: 3033,
    city: 'Rózsaszentmárton',
    county: 'Heves',
  },
  {
    postal: 3034,
    city: 'Szűcsi',
    county: 'Heves',
  },
  {
    postal: 3035,
    city: 'Gyöngyöspata',
    county: 'Heves',
  },
  {
    postal: 3036,
    city: 'Gyöngyöstarján',
    county: 'Heves',
  },
  {
    postal: 3200,
    city: 'Gyöngyös',
    county: 'Heves',
  },
  {
    postal: 3211,
    city: 'Gyöngyösoroszi',
    county: 'Heves',
  },
  {
    postal: 3212,
    city: 'Gyöngyöshalász',
    county: 'Heves',
  },
  {
    postal: 3213,
    city: 'Atkár',
    county: 'Heves',
  },
  {
    postal: 3214,
    city: 'Nagyréde',
    county: 'Heves',
  },
  {
    postal: 3221,
    city: 'Gyöngyös',
    county: 'Heves',
  },
  {
    postal: 3231,
    city: 'Gyöngyössolymos',
    county: 'Heves',
  },
  {
    postal: 3232,
    city: 'Gyöngyös',
    county: 'Heves',
  },
  {
    postal: 3233,
    city: 'Gyöngyös',
    county: 'Heves',
  },
  {
    postal: 3234,
    city: 'Mátraszentimre',
    county: 'Heves',
  },
  {
    postal: 3235,
    city: 'Mátraszentimre',
    county: 'Heves',
  },
  {
    postal: 3240,
    city: 'Parád',
    county: 'Heves',
  },
  {
    postal: 3242,
    city: 'Parádsasvár',
    county: 'Heves',
  },
  {
    postal: 3243,
    city: 'Bodony',
    county: 'Heves',
  },
  {
    postal: 3244,
    city: 'Parád',
    county: 'Heves',
  },
  {
    postal: 3245,
    city: 'Recsk',
    county: 'Heves',
  },
  {
    postal: 3246,
    city: 'Mátraderecske',
    county: 'Heves',
  },
  {
    postal: 3247,
    city: 'Mátraballa',
    county: 'Heves',
  },
  {
    postal: 3248,
    city: 'Ivád',
    county: 'Heves',
  },
  {
    postal: 3250,
    city: 'Pétervására',
    county: 'Heves',
  },
  {
    postal: 3252,
    city: 'Erdőkövesd',
    county: 'Heves',
  },
  {
    postal: 3253,
    city: 'Istenmezeje',
    county: 'Heves',
  },
  {
    postal: 3254,
    city: 'Váraszó',
    county: 'Heves',
  },
  {
    postal: 3255,
    city: 'Fedémes',
    county: 'Heves',
  },
  {
    postal: 3256,
    city: 'Kisfüzes',
    county: 'Heves',
  },
  {
    postal: 3257,
    city: 'Bükkszenterzsébet',
    county: 'Heves',
  },
  {
    postal: 3258,
    city: 'Tarnalelesz',
    county: 'Heves',
  },
  {
    postal: 3259,
    city: 'Szentdomonkos',
    county: 'Heves',
  },
  {
    postal: 3261,
    city: 'Abasár',
    county: 'Heves',
  },
  {
    postal: 3261,
    city: 'Pálosvörösmart',
    county: 'Heves',
  },
  {
    postal: 3262,
    city: 'Markaz',
    county: 'Heves',
  },
  {
    postal: 3263,
    city: 'Domoszló',
    county: 'Heves',
  },
  {
    postal: 3264,
    city: 'Kisnána',
    county: 'Heves',
  },
  {
    postal: 3265,
    city: 'Vécs',
    county: 'Heves',
  },
  {
    postal: 3271,
    city: 'Visonta',
    county: 'Heves',
  },
  {
    postal: 3272,
    city: 'Visonta',
    county: 'Heves',
  },
  {
    postal: 3273,
    city: 'Halmajugra',
    county: 'Heves',
  },
  {
    postal: 3274,
    city: 'Ludas',
    county: 'Heves',
  },
  {
    postal: 3275,
    city: 'Detk',
    county: 'Heves',
  },
  {
    postal: 3281,
    city: 'Karácsond',
    county: 'Heves',
  },
  {
    postal: 3282,
    city: 'Nagyfüged',
    county: 'Heves',
  },
  {
    postal: 3283,
    city: 'Tarnazsadány',
    county: 'Heves',
  },
  {
    postal: 3284,
    city: 'Tarnaméra',
    county: 'Heves',
  },
  {
    postal: 3291,
    city: 'Vámosgyörk',
    county: 'Heves',
  },
  {
    postal: 3292,
    city: 'Adács',
    county: 'Heves',
  },
  {
    postal: 3293,
    city: 'Visznek',
    county: 'Heves',
  },
  {
    postal: 3294,
    city: 'Tarnaörs',
    county: 'Heves',
  },
  {
    postal: 3295,
    city: 'Erk',
    county: 'Heves',
  },
  {
    postal: 3296,
    city: 'Zaránk',
    county: 'Heves',
  },
  {
    postal: 3300,
    city: 'Eger',
    county: 'Heves',
  },
  {
    postal: 3321,
    city: 'Egerbakta',
    county: 'Heves',
  },
  {
    postal: 3322,
    city: 'Hevesaranyos',
    county: 'Heves',
  },
  {
    postal: 3323,
    city: 'Szarvaskő',
    county: 'Heves',
  },
  {
    postal: 3324,
    city: 'Felsőtárkány',
    county: 'Heves',
  },
  {
    postal: 3325,
    city: 'Noszvaj',
    county: 'Heves',
  },
  {
    postal: 3326,
    city: 'Ostoros',
    county: 'Heves',
  },
  {
    postal: 3327,
    city: 'Novaj',
    county: 'Heves',
  },
  {
    postal: 3328,
    city: 'Egerszólát',
    county: 'Heves',
  },
  {
    postal: 3331,
    city: 'Tarnaszentmária',
    county: 'Heves',
  },
  {
    postal: 3332,
    city: 'Sirok',
    county: 'Heves',
  },
  {
    postal: 3333,
    city: 'Terpes',
    county: 'Heves',
  },
  {
    postal: 3334,
    city: 'Szajla',
    county: 'Heves',
  },
  {
    postal: 3335,
    city: 'Bükkszék',
    county: 'Heves',
  },
  {
    postal: 3336,
    city: 'Bátor',
    county: 'Heves',
  },
  {
    postal: 3337,
    city: 'Egerbocs',
    county: 'Heves',
  },
  {
    postal: 3341,
    city: 'Egercsehi',
    county: 'Heves',
  },
  {
    postal: 3341,
    city: 'Szúcs',
    county: 'Heves',
  },
  {
    postal: 3343,
    city: 'Bekölce',
    county: 'Heves',
  },
  {
    postal: 3344,
    city: 'Mikófalva',
    county: 'Heves',
  },
  {
    postal: 3345,
    city: 'Mónosbél',
    county: 'Heves',
  },
  {
    postal: 3346,
    city: 'Bükkszentmárton',
    county: 'Heves',
  },
  {
    postal: 3346,
    city: 'Bélapátfalva',
    county: 'Heves',
  },
  {
    postal: 3347,
    city: 'Balaton',
    county: 'Heves',
  },
  {
    postal: 3348,
    city: 'Szilvásvárad',
    county: 'Heves',
  },
  {
    postal: 3349,
    city: 'Nagyvisnyó',
    county: 'Heves',
  },
  {
    postal: 3350,
    city: 'Kál',
    county: 'Heves',
  },
  {
    postal: 3351,
    city: 'Verpelét',
    county: 'Heves',
  },
  {
    postal: 3352,
    city: 'Feldebrő',
    county: 'Heves',
  },
  {
    postal: 3353,
    city: 'Aldebrő',
    county: 'Heves',
  },
  {
    postal: 3354,
    city: 'Tófalu',
    county: 'Heves',
  },
  {
    postal: 3355,
    city: 'Kápolna',
    county: 'Heves',
  },
  {
    postal: 3356,
    city: 'Kompolt',
    county: 'Heves',
  },
  {
    postal: 3357,
    city: 'Nagyút',
    county: 'Heves',
  },
  {
    postal: 3358,
    city: 'Erdőtelek',
    county: 'Heves',
  },
  {
    postal: 3359,
    city: 'Tenk',
    county: 'Heves',
  },
  {
    postal: 3360,
    city: 'Heves',
    county: 'Heves',
  },
  {
    postal: 3368,
    city: 'Boconád',
    county: 'Heves',
  },
  {
    postal: 3369,
    city: 'Tarnabod',
    county: 'Heves',
  },
  {
    postal: 3371,
    city: 'Átány',
    county: 'Heves',
  },
  {
    postal: 3372,
    city: 'Kömlő',
    county: 'Heves',
  },
  {
    postal: 3373,
    city: 'Besenyőtelek',
    county: 'Heves',
  },
  {
    postal: 3374,
    city: 'Dormánd',
    county: 'Heves',
  },
  {
    postal: 3375,
    city: 'Mezőtárkány',
    county: 'Heves',
  },
  {
    postal: 3377,
    city: 'Szihalom',
    county: 'Heves',
  },
  {
    postal: 3378,
    city: 'Mezőszemere',
    county: 'Heves',
  },
  {
    postal: 3379,
    city: 'Egerfarmos',
    county: 'Heves',
  },
  {
    postal: 3381,
    city: 'Pély',
    county: 'Heves',
  },
  {
    postal: 3382,
    city: 'Tarnaszentmiklós',
    county: 'Heves',
  },
  {
    postal: 3383,
    city: 'Hevesvezekény',
    county: 'Heves',
  },
  {
    postal: 3384,
    city: 'Kisköre',
    county: 'Heves',
  },
  {
    postal: 3385,
    city: 'Tiszanána',
    county: 'Heves',
  },
  {
    postal: 3386,
    city: 'Sarud',
    county: 'Heves',
  },
  {
    postal: 3387,
    city: 'Újlőrincfalva',
    county: 'Heves',
  },
  {
    postal: 3388,
    city: 'Poroszló',
    county: 'Heves',
  },
  {
    postal: 3390,
    city: 'Füzesabony',
    county: 'Heves',
  },
  {
    postal: 3394,
    city: 'Egerszalók',
    county: 'Heves',
  },
  {
    postal: 3395,
    city: 'Demjén',
    county: 'Heves',
  },
  {
    postal: 3396,
    city: 'Kerecsend',
    county: 'Heves',
  },
  {
    postal: 3397,
    city: 'Maklár',
    county: 'Heves',
  },
  {
    postal: 3398,
    city: 'Nagytálya',
    county: 'Heves',
  },
  {
    postal: 3399,
    city: 'Andornaktálya',
    county: 'Heves',
  },
  {
    postal: 5000,
    city: 'Szolnok',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5008,
    city: 'Szolnok',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5051,
    city: 'Zagyvarékas',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5052,
    city: 'Újszász',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5053,
    city: 'Szászberek',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5054,
    city: 'Jászalsószentgyörgy',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5055,
    city: 'Jászladány',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5061,
    city: 'Tiszasüly',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5062,
    city: 'Kőtelek',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5063,
    city: 'Hunyadfalva',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5064,
    city: 'Csataszög',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5065,
    city: 'Nagykörű',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5071,
    city: 'Besenyszög',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5081,
    city: 'Szajol',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5082,
    city: 'Tiszatenyő',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5083,
    city: 'Kengyel',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5084,
    city: 'Rákócziújfalu',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5085,
    city: 'Rákóczifalva',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5091,
    city: 'Tószeg',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5092,
    city: 'Tiszavárkony',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5093,
    city: 'Vezseny',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5094,
    city: 'Tiszajenő',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5095,
    city: 'Tiszavárkony',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5100,
    city: 'Jászberény',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5111,
    city: 'Jászfelsőszentgyörgy',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5121,
    city: 'Jászjákóhalma',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5122,
    city: 'Jászdózsa',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5123,
    city: 'Jászárokszállás',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5124,
    city: 'Jászágó',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5125,
    city: 'Pusztamonostor',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5126,
    city: 'Jászfényszaru',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5130,
    city: 'Jászapáti',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5135,
    city: 'Jászivány',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5136,
    city: 'Jászszentandrás',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5137,
    city: 'Jászkisér',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5141,
    city: 'Jásztelek',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5142,
    city: 'Alattyán',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5143,
    city: 'Jánoshida',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5144,
    city: 'Jászboldogháza',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5152,
    city: 'Jászberény',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5200,
    city: 'Törökszentmiklós',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5211,
    city: 'Tiszapüspöki',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5212,
    city: 'Törökszentmiklós',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5213,
    city: 'Fegyvernek',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5222,
    city: 'Örményes',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5231,
    city: 'Fegyvernek',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5232,
    city: 'Tiszabő',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5233,
    city: 'Tiszagyenda',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5234,
    city: 'Tiszaroff',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5235,
    city: 'Tiszabura',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5241,
    city: 'Abádszalók',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5243,
    city: 'Tiszaderzs',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5244,
    city: 'Tiszaszőlős',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5300,
    city: 'Karcag',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5309,
    city: 'Berekfürdő',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5310,
    city: 'Kisújszállás',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5321,
    city: 'Kunmadaras',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5322,
    city: 'Tiszaszentimre',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5323,
    city: 'Tiszaszentimre',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5324,
    city: 'Tomajmonostora',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5331,
    city: 'Kenderes',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5340,
    city: 'Kunhegyes',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5349,
    city: 'Kenderes',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5350,
    city: 'Tiszafüred',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5358,
    city: 'Tiszafüred',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5359,
    city: 'Tiszafüred',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5361,
    city: 'Tiszaigar',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5362,
    city: 'Tiszaörs',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5363,
    city: 'Nagyiván',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5400,
    city: 'Mezőtúr',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5411,
    city: 'Kétpó',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5412,
    city: 'Kuncsorba',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5420,
    city: 'Túrkeve',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5430,
    city: 'Tiszaföldvár',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5435,
    city: 'Martfű',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5440,
    city: 'Kunszentmárton',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5449,
    city: 'Kunszentmárton',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5451,
    city: 'Öcsöd',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5452,
    city: 'Mesterszállás',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5453,
    city: 'Mezőhék',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5461,
    city: 'Tiszaföldvár',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5462,
    city: 'Cibakháza',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5463,
    city: 'Nagyrév',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5464,
    city: 'Tiszainoka',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5465,
    city: 'Cserkeszőlő',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5471,
    city: 'Tiszakürt',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5474,
    city: 'Tiszasas',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5475,
    city: 'Csépa',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 5476,
    city: 'Szelevény',
    county: 'Jász-Nagykun-Szolnok',
  },
  {
    postal: 2027,
    city: 'Dömös',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2028,
    city: 'Pilismarót',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2500,
    city: 'Esztergom',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2508,
    city: 'Esztergom',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2509,
    city: 'Esztergom',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2510,
    city: 'Dorog',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2517,
    city: 'Kesztölc',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2518,
    city: 'Leányvár',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2519,
    city: 'Piliscsév',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2521,
    city: 'Csolnok',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2522,
    city: 'Dág',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2523,
    city: 'Sárisáp',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2524,
    city: 'Nagysáp',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2525,
    city: 'Bajna',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2526,
    city: 'Epöl',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2527,
    city: 'Máriahalom',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2528,
    city: 'Úny',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2529,
    city: 'Annavölgy',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2531,
    city: 'Tokod',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2532,
    city: 'Tokodaltáró',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2533,
    city: 'Bajót',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2534,
    city: 'Tát',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2535,
    city: 'Mogyorósbánya',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2536,
    city: 'Nyergesújfalu',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2541,
    city: 'Lábatlan',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2543,
    city: 'Süttő',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2544,
    city: 'Neszmély',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2545,
    city: 'Dunaalmás',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2800,
    city: 'Tatabánya',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2821,
    city: 'Gyermely',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2822,
    city: 'Szomor',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2823,
    city: 'Vértessomló',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2824,
    city: 'Várgesztes',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2831,
    city: 'Tarján',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2832,
    city: 'Héreg',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2833,
    city: 'Vértestolna',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2834,
    city: 'Tardos',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2835,
    city: 'Tata',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2836,
    city: 'Baj',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2837,
    city: 'Vértesszőlős',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2840,
    city: 'Oroszlány',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2851,
    city: 'Környe',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2852,
    city: 'Kecskéd',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2853,
    city: 'Kömlőd',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2854,
    city: 'Dad',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2855,
    city: 'Bokod',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2856,
    city: 'Szákszend',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2858,
    city: 'Császár',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2859,
    city: 'Vérteskethely',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2861,
    city: 'Bakonysárkány',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2862,
    city: 'Aka',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2870,
    city: 'Kisbér',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2879,
    city: 'Kisbér',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2881,
    city: 'Ászár',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2882,
    city: 'Kerékteleki',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2883,
    city: 'Bársonyos',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2884,
    city: 'Bakonyszombathely',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2885,
    city: 'Bakonybánk',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2886,
    city: 'Réde',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2887,
    city: 'Ácsteszér',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2888,
    city: 'Csatka',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2889,
    city: 'Súr',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2890,
    city: 'Tata',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2896,
    city: 'Szomód',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2897,
    city: 'Dunaszentmiklós',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2898,
    city: 'Kocs',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2899,
    city: 'Naszály',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2900,
    city: 'Komárom',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2903,
    city: 'Komárom',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2911,
    city: 'Mocsa',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2921,
    city: 'Komárom',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2931,
    city: 'Almásfüzitő',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2941,
    city: 'Ács',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2942,
    city: 'Nagyigmánd',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2943,
    city: 'Bábolna',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2943,
    city: 'Tárkány',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2944,
    city: 'Bana',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2945,
    city: 'Tárkány',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2946,
    city: 'Csép',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2947,
    city: 'Ete',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2948,
    city: 'Kisigmánd',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2949,
    city: 'Csém',
    county: 'Komárom-Esztergom',
  },
  {
    postal: 2175,
    city: 'Kálló',
    county: 'Nógrád',
  },
  {
    postal: 2176,
    city: 'Erdőkürt',
    county: 'Nógrád',
  },
  {
    postal: 2177,
    city: 'Erdőtarcsa',
    county: 'Nógrád',
  },
  {
    postal: 2610,
    city: 'Ősagárd',
    county: 'Nógrád',
  },
  {
    postal: 2610,
    city: 'Nőtincs',
    county: 'Nógrád',
  },
  {
    postal: 2611,
    city: 'Felsőpetény',
    county: 'Nógrád',
  },
  {
    postal: 2616,
    city: 'Keszeg',
    county: 'Nógrád',
  },
  {
    postal: 2617,
    city: 'Alsópetény',
    county: 'Nógrád',
  },
  {
    postal: 2618,
    city: 'Nézsa',
    county: 'Nógrád',
  },
  {
    postal: 2619,
    city: 'Legénd',
    county: 'Nógrád',
  },
  {
    postal: 2640,
    city: 'Szendehely',
    county: 'Nógrád',
  },
  {
    postal: 2641,
    city: 'Berkenye',
    county: 'Nógrád',
  },
  {
    postal: 2642,
    city: 'Nógrád',
    county: 'Nógrád',
  },
  {
    postal: 2643,
    city: 'Diósjenő',
    county: 'Nógrád',
  },
  {
    postal: 2644,
    city: 'Borsosberény',
    county: 'Nógrád',
  },
  {
    postal: 2645,
    city: 'Nagyoroszi',
    county: 'Nógrád',
  },
  {
    postal: 2646,
    city: 'Drégelypalánk',
    county: 'Nógrád',
  },
  {
    postal: 2647,
    city: 'Hont',
    county: 'Nógrád',
  },
  {
    postal: 2648,
    city: 'Patak',
    county: 'Nógrád',
  },
  {
    postal: 2649,
    city: 'Dejtár',
    county: 'Nógrád',
  },
  {
    postal: 2651,
    city: 'Rétság',
    county: 'Nógrád',
  },
  {
    postal: 2652,
    city: 'Tereske',
    county: 'Nógrád',
  },
  {
    postal: 2653,
    city: 'Bánk',
    county: 'Nógrád',
  },
  {
    postal: 2654,
    city: 'Romhány',
    county: 'Nógrád',
  },
  {
    postal: 2655,
    city: 'Szente',
    county: 'Nógrád',
  },
  {
    postal: 2655,
    city: 'Kisecset',
    county: 'Nógrád',
  },
  {
    postal: 2655,
    city: 'Kétbodony',
    county: 'Nógrád',
  },
  {
    postal: 2656,
    city: 'Szátok',
    county: 'Nógrád',
  },
  {
    postal: 2657,
    city: 'Tolmács',
    county: 'Nógrád',
  },
  {
    postal: 2658,
    city: 'Pusztaberki',
    county: 'Nógrád',
  },
  {
    postal: 2658,
    city: 'Horpács',
    county: 'Nógrád',
  },
  {
    postal: 2659,
    city: 'Érsekvadkert',
    county: 'Nógrád',
  },
  {
    postal: 2660,
    city: 'Balassagyarmat',
    county: 'Nógrád',
  },
  {
    postal: 2660,
    city: 'Ipolyszög',
    county: 'Nógrád',
  },
  {
    postal: 2668,
    city: 'Patvarc',
    county: 'Nógrád',
  },
  {
    postal: 2669,
    city: 'Ipolyvece',
    county: 'Nógrád',
  },
  {
    postal: 2671,
    city: 'Őrhalom',
    county: 'Nógrád',
  },
  {
    postal: 2672,
    city: 'Hugyag',
    county: 'Nógrád',
  },
  {
    postal: 2673,
    city: 'Csitár',
    county: 'Nógrád',
  },
  {
    postal: 2674,
    city: 'Iliny',
    county: 'Nógrád',
  },
  {
    postal: 2675,
    city: 'Nógrádmarcal',
    county: 'Nógrád',
  },
  {
    postal: 2676,
    city: 'Cserhátsurány',
    county: 'Nógrád',
  },
  {
    postal: 2677,
    city: 'Herencsény',
    county: 'Nógrád',
  },
  {
    postal: 2678,
    city: 'Csesztve',
    county: 'Nógrád',
  },
  {
    postal: 2685,
    city: 'Nógrádsáp',
    county: 'Nógrád',
  },
  {
    postal: 2686,
    city: 'Galgaguta',
    county: 'Nógrád',
  },
  {
    postal: 2687,
    city: 'Bercel',
    county: 'Nógrád',
  },
  {
    postal: 2688,
    city: 'Vanyarc',
    county: 'Nógrád',
  },
  {
    postal: 2691,
    city: 'Nógrádkövesd',
    county: 'Nógrád',
  },
  {
    postal: 2692,
    city: 'Szécsénke',
    county: 'Nógrád',
  },
  {
    postal: 2693,
    city: 'Becske',
    county: 'Nógrád',
  },
  {
    postal: 2694,
    city: 'Cserháthaláp',
    county: 'Nógrád',
  },
  {
    postal: 2694,
    city: 'Debercsény',
    county: 'Nógrád',
  },
  {
    postal: 2694,
    city: 'Magyarnándor',
    county: 'Nógrád',
  },
  {
    postal: 2696,
    city: 'Terény',
    county: 'Nógrád',
  },
  {
    postal: 2697,
    city: 'Szanda',
    county: 'Nógrád',
  },
  {
    postal: 2698,
    city: 'Mohora',
    county: 'Nógrád',
  },
  {
    postal: 2699,
    city: 'Szügy',
    county: 'Nógrád',
  },
  {
    postal: 3041,
    city: 'Héhalom',
    county: 'Nógrád',
  },
  {
    postal: 3042,
    city: 'Palotás',
    county: 'Nógrád',
  },
  {
    postal: 3043,
    city: 'Egyházasdengeleg',
    county: 'Nógrád',
  },
  {
    postal: 3044,
    city: 'Szirák',
    county: 'Nógrád',
  },
  {
    postal: 3045,
    city: 'Bér',
    county: 'Nógrád',
  },
  {
    postal: 3046,
    city: 'Kisbágyon',
    county: 'Nógrád',
  },
  {
    postal: 3047,
    city: 'Buják',
    county: 'Nógrád',
  },
  {
    postal: 3051,
    city: 'Szarvasgede',
    county: 'Nógrád',
  },
  {
    postal: 3052,
    city: 'Csécse',
    county: 'Nógrád',
  },
  {
    postal: 3053,
    city: 'Kozárd',
    county: 'Nógrád',
  },
  {
    postal: 3053,
    city: 'Ecseg',
    county: 'Nógrád',
  },
  {
    postal: 3060,
    city: 'Pásztó',
    county: 'Nógrád',
  },
  {
    postal: 3063,
    city: 'Jobbágyi',
    county: 'Nógrád',
  },
  {
    postal: 3064,
    city: 'Szurdokpüspöki',
    county: 'Nógrád',
  },
  {
    postal: 3065,
    city: 'Pásztó',
    county: 'Nógrád',
  },
  {
    postal: 3066,
    city: 'Cserhátszentiván',
    county: 'Nógrád',
  },
  {
    postal: 3066,
    city: 'Bokor',
    county: 'Nógrád',
  },
  {
    postal: 3066,
    city: 'Kutasó',
    county: 'Nógrád',
  },
  {
    postal: 3067,
    city: 'Garáb',
    county: 'Nógrád',
  },
  {
    postal: 3067,
    city: 'Felsőtold',
    county: 'Nógrád',
  },
  {
    postal: 3068,
    city: 'Mátraszőlős',
    county: 'Nógrád',
  },
  {
    postal: 3069,
    city: 'Alsótold',
    county: 'Nógrád',
  },
  {
    postal: 3070,
    city: 'Bátonyterenye',
    county: 'Nógrád',
  },
  {
    postal: 3071,
    city: 'Bátonyterenye',
    county: 'Nógrád',
  },
  {
    postal: 3073,
    city: 'Tar',
    county: 'Nógrád',
  },
  {
    postal: 3074,
    city: 'Sámsonháza',
    county: 'Nógrád',
  },
  {
    postal: 3075,
    city: 'Kisbárkány',
    county: 'Nógrád',
  },
  {
    postal: 3075,
    city: 'Márkháza',
    county: 'Nógrád',
  },
  {
    postal: 3075,
    city: 'Nagybárkány',
    county: 'Nógrád',
  },
  {
    postal: 3077,
    city: 'Mátraverebély',
    county: 'Nógrád',
  },
  {
    postal: 3078,
    city: 'Bátonyterenye',
    county: 'Nógrád',
  },
  {
    postal: 3082,
    city: 'Pásztó',
    county: 'Nógrád',
  },
  {
    postal: 3100,
    city: 'Salgótarján',
    county: 'Nógrád',
  },
  {
    postal: 3102,
    city: 'Salgótarján',
    county: 'Nógrád',
  },
  {
    postal: 3104,
    city: 'Salgótarján',
    county: 'Nógrád',
  },
  {
    postal: 3109,
    city: 'Salgótarján',
    county: 'Nógrád',
  },
  {
    postal: 3121,
    city: 'Somoskőújfalu',
    county: 'Nógrád',
  },
  {
    postal: 3123,
    city: 'Cered',
    county: 'Nógrád',
  },
  {
    postal: 3124,
    city: 'Zabar',
    county: 'Nógrád',
  },
  {
    postal: 3125,
    city: 'Szilaspogony',
    county: 'Nógrád',
  },
  {
    postal: 3126,
    city: 'Bárna',
    county: 'Nógrád',
  },
  {
    postal: 3127,
    city: 'Kazár',
    county: 'Nógrád',
  },
  {
    postal: 3128,
    city: 'Vizslás',
    county: 'Nógrád',
  },
  {
    postal: 3129,
    city: 'Lucfalva',
    county: 'Nógrád',
  },
  {
    postal: 3129,
    city: 'Nagykeresztúr',
    county: 'Nógrád',
  },
  {
    postal: 3131,
    city: 'Sóshartyán',
    county: 'Nógrád',
  },
  {
    postal: 3132,
    city: 'Nógrádmegyer',
    county: 'Nógrád',
  },
  {
    postal: 3133,
    city: 'Magyargéc',
    county: 'Nógrád',
  },
  {
    postal: 3134,
    city: 'Piliny',
    county: 'Nógrád',
  },
  {
    postal: 3135,
    city: 'Szécsényfelfalu',
    county: 'Nógrád',
  },
  {
    postal: 3136,
    city: 'Etes',
    county: 'Nógrád',
  },
  {
    postal: 3137,
    city: 'Karancsberény',
    county: 'Nógrád',
  },
  {
    postal: 3138,
    city: 'Ipolytarnóc',
    county: 'Nógrád',
  },
  {
    postal: 3141,
    city: 'Salgótarján',
    county: 'Nógrád',
  },
  {
    postal: 3142,
    city: 'Mátraszele',
    county: 'Nógrád',
  },
  {
    postal: 3143,
    city: 'Mátranovák',
    county: 'Nógrád',
  },
  {
    postal: 3144,
    city: 'Mátranovák',
    county: 'Nógrád',
  },
  {
    postal: 3145,
    city: 'Mátraterenye',
    county: 'Nógrád',
  },
  {
    postal: 3146,
    city: 'Mátraterenye',
    county: 'Nógrád',
  },
  {
    postal: 3147,
    city: 'Kazár',
    county: 'Nógrád',
  },
  {
    postal: 3151,
    city: 'Rákóczibánya',
    county: 'Nógrád',
  },
  {
    postal: 3152,
    city: 'Nemti',
    county: 'Nógrád',
  },
  {
    postal: 3153,
    city: 'Dorogháza',
    county: 'Nógrád',
  },
  {
    postal: 3154,
    city: 'Szuha',
    county: 'Nógrád',
  },
  {
    postal: 3155,
    city: 'Mátramindszent',
    county: 'Nógrád',
  },
  {
    postal: 3161,
    city: 'Kishartyán',
    county: 'Nógrád',
  },
  {
    postal: 3162,
    city: 'Ságújfalu',
    county: 'Nógrád',
  },
  {
    postal: 3163,
    city: 'Szalmatercs',
    county: 'Nógrád',
  },
  {
    postal: 3163,
    city: 'Karancsság',
    county: 'Nógrád',
  },
  {
    postal: 3165,
    city: 'Endrefalva',
    county: 'Nógrád',
  },
  {
    postal: 3170,
    city: 'Szécsény',
    county: 'Nógrád',
  },
  {
    postal: 3175,
    city: 'Nagylóc',
    county: 'Nógrád',
  },
  {
    postal: 3176,
    city: 'Hollókő',
    county: 'Nógrád',
  },
  {
    postal: 3177,
    city: 'Rimóc',
    county: 'Nógrád',
  },
  {
    postal: 3178,
    city: 'Varsány',
    county: 'Nógrád',
  },
  {
    postal: 3179,
    city: 'Nógrádsipek',
    county: 'Nógrád',
  },
  {
    postal: 3181,
    city: 'Karancsalja',
    county: 'Nógrád',
  },
  {
    postal: 3182,
    city: 'Karancslapujtő',
    county: 'Nógrád',
  },
  {
    postal: 3183,
    city: 'Karancskeszi',
    county: 'Nógrád',
  },
  {
    postal: 3184,
    city: 'Mihálygerge',
    county: 'Nógrád',
  },
  {
    postal: 3185,
    city: 'Egyházasgerge',
    county: 'Nógrád',
  },
  {
    postal: 3186,
    city: 'Litke',
    county: 'Nógrád',
  },
  {
    postal: 3187,
    city: 'Nógrádszakál',
    county: 'Nógrád',
  },
  {
    postal: 3188,
    city: 'Ludányhalászi',
    county: 'Nógrád',
  },
  {
    postal: 2000,
    city: 'Szentendre',
    county: 'Pest',
  },
  {
    postal: 2009,
    city: 'Pilisszentlászló',
    county: 'Pest',
  },
  {
    postal: 2011,
    city: 'Budakalász',
    county: 'Pest',
  },
  {
    postal: 2013,
    city: 'Pomáz',
    county: 'Pest',
  },
  {
    postal: 2014,
    city: 'Csobánka',
    county: 'Pest',
  },
  {
    postal: 2015,
    city: 'Szigetmonostor',
    county: 'Pest',
  },
  {
    postal: 2016,
    city: 'Leányfalu',
    county: 'Pest',
  },
  {
    postal: 2017,
    city: 'Pócsmegyer',
    county: 'Pest',
  },
  {
    postal: 2021,
    city: 'Tahitótfalu',
    county: 'Pest',
  },
  {
    postal: 2022,
    city: 'Tahitótfalu',
    county: 'Pest',
  },
  {
    postal: 2023,
    city: 'Dunabogdány',
    county: 'Pest',
  },
  {
    postal: 2024,
    city: 'Kisoroszi',
    county: 'Pest',
  },
  {
    postal: 2025,
    city: 'Visegrád',
    county: 'Pest',
  },
  {
    postal: 2026,
    city: 'Visegrád',
    county: 'Pest',
  },
  {
    postal: 2030,
    city: 'Érd',
    county: 'Pest',
  },
  {
    postal: 2038,
    city: 'Sóskút',
    county: 'Pest',
  },
  {
    postal: 2039,
    city: 'Pusztazámor',
    county: 'Pest',
  },
  {
    postal: 2040,
    city: 'Budaörs',
    county: 'Pest',
  },
  {
    postal: 2045,
    city: 'Törökbálint',
    county: 'Pest',
  },
  {
    postal: 2049,
    city: 'Diósd',
    county: 'Pest',
  },
  {
    postal: 2051,
    city: 'Biatorbágy',
    county: 'Pest',
  },
  {
    postal: 2053,
    city: 'Herceghalom',
    county: 'Pest',
  },
  {
    postal: 2071,
    city: 'Páty',
    county: 'Pest',
  },
  {
    postal: 2072,
    city: 'Zsámbék',
    county: 'Pest',
  },
  {
    postal: 2073,
    city: 'Tök',
    county: 'Pest',
  },
  {
    postal: 2074,
    city: 'Perbál',
    county: 'Pest',
  },
  {
    postal: 2080,
    city: 'Pilisjászfalu',
    county: 'Pest',
  },
  {
    postal: 2081,
    city: 'Piliscsaba',
    county: 'Pest',
  },
  {
    postal: 2083,
    city: 'Solymár',
    county: 'Pest',
  },
  {
    postal: 2084,
    city: 'Pilisszentiván',
    county: 'Pest',
  },
  {
    postal: 2085,
    city: 'Pilisvörösvár',
    county: 'Pest',
  },
  {
    postal: 2086,
    city: 'Tinnye',
    county: 'Pest',
  },
  {
    postal: 2089,
    city: 'Telki',
    county: 'Pest',
  },
  {
    postal: 2090,
    city: 'Remeteszőlős',
    county: 'Pest',
  },
  {
    postal: 2092,
    city: 'Budakeszi',
    county: 'Pest',
  },
  {
    postal: 2093,
    city: 'Budajenő',
    county: 'Pest',
  },
  {
    postal: 2094,
    city: 'Nagykovácsi',
    county: 'Pest',
  },
  {
    postal: 2095,
    city: 'Pilisszántó',
    county: 'Pest',
  },
  {
    postal: 2096,
    city: 'Üröm',
    county: 'Pest',
  },
  {
    postal: 2097,
    city: 'Pilisborosjenő',
    county: 'Pest',
  },
  {
    postal: 2098,
    city: 'Pilisszentkereszt',
    county: 'Pest',
  },
  {
    postal: 2099,
    city: 'Pilisszentkereszt',
    county: 'Pest',
  },
  {
    postal: 2100,
    city: 'Gödöllő',
    county: 'Pest',
  },
  {
    postal: 2111,
    city: 'Szada',
    county: 'Pest',
  },
  {
    postal: 2112,
    city: 'Veresegyház',
    county: 'Pest',
  },
  {
    postal: 2113,
    city: 'Erdőkertes',
    county: 'Pest',
  },
  {
    postal: 2114,
    city: 'Valkó',
    county: 'Pest',
  },
  {
    postal: 2115,
    city: 'Vácszentlászló',
    county: 'Pest',
  },
  {
    postal: 2116,
    city: 'Zsámbok',
    county: 'Pest',
  },
  {
    postal: 2117,
    city: 'Isaszeg',
    county: 'Pest',
  },
  {
    postal: 2118,
    city: 'Dány',
    county: 'Pest',
  },
  {
    postal: 2119,
    city: 'Pécel',
    county: 'Pest',
  },
  {
    postal: 2120,
    city: 'Dunakeszi',
    county: 'Pest',
  },
  {
    postal: 2131,
    city: 'Göd',
    county: 'Pest',
  },
  {
    postal: 2132,
    city: 'Göd',
    county: 'Pest',
  },
  {
    postal: 2133,
    city: 'Sződliget',
    county: 'Pest',
  },
  {
    postal: 2134,
    city: 'Sződ',
    county: 'Pest',
  },
  {
    postal: 2135,
    city: 'Csörög',
    county: 'Pest',
  },
  {
    postal: 2141,
    city: 'Csömör',
    county: 'Pest',
  },
  {
    postal: 2142,
    city: 'Nagytarcsa',
    county: 'Pest',
  },
  {
    postal: 2143,
    city: 'Kistarcsa',
    county: 'Pest',
  },
  {
    postal: 2144,
    city: 'Kerepes',
    county: 'Pest',
  },
  {
    postal: 2145,
    city: 'Kerepes',
    county: 'Pest',
  },
  {
    postal: 2146,
    city: 'Mogyoród',
    county: 'Pest',
  },
  {
    postal: 2151,
    city: 'Fót',
    county: 'Pest',
  },
  {
    postal: 2161,
    city: 'Csomád',
    county: 'Pest',
  },
  {
    postal: 2162,
    city: 'Őrbottyán',
    county: 'Pest',
  },
  {
    postal: 2163,
    city: 'Vácrátót',
    county: 'Pest',
  },
  {
    postal: 2164,
    city: 'Váchartyán',
    county: 'Pest',
  },
  {
    postal: 2165,
    city: 'Kisnémedi',
    county: 'Pest',
  },
  {
    postal: 2166,
    city: 'Püspökszilágy',
    county: 'Pest',
  },
  {
    postal: 2167,
    city: 'Vácduka',
    county: 'Pest',
  },
  {
    postal: 2170,
    city: 'Aszód',
    county: 'Pest',
  },
  {
    postal: 2173,
    city: 'Kartal',
    county: 'Pest',
  },
  {
    postal: 2174,
    city: 'Verseg',
    county: 'Pest',
  },
  {
    postal: 2181,
    city: 'Iklad',
    county: 'Pest',
  },
  {
    postal: 2182,
    city: 'Domony',
    county: 'Pest',
  },
  {
    postal: 2183,
    city: 'Galgamácsa',
    county: 'Pest',
  },
  {
    postal: 2184,
    city: 'Vácegres',
    county: 'Pest',
  },
  {
    postal: 2185,
    city: 'Váckisújfalu',
    county: 'Pest',
  },
  {
    postal: 2191,
    city: 'Bag',
    county: 'Pest',
  },
  {
    postal: 2192,
    city: 'Hévízgyörk',
    county: 'Pest',
  },
  {
    postal: 2193,
    city: 'Galgahévíz',
    county: 'Pest',
  },
  {
    postal: 2194,
    city: 'Tura',
    county: 'Pest',
  },
  {
    postal: 2200,
    city: 'Monor',
    county: 'Pest',
  },
  {
    postal: 2209,
    city: 'Péteri',
    county: 'Pest',
  },
  {
    postal: 2211,
    city: 'Vasad',
    county: 'Pest',
  },
  {
    postal: 2212,
    city: 'Csévharaszt',
    county: 'Pest',
  },
  {
    postal: 2213,
    city: 'Monorierdő',
    county: 'Pest',
  },
  {
    postal: 2214,
    city: 'Pánd',
    county: 'Pest',
  },
  {
    postal: 2215,
    city: 'Káva',
    county: 'Pest',
  },
  {
    postal: 2216,
    city: 'Bénye',
    county: 'Pest',
  },
  {
    postal: 2217,
    city: 'Gomba',
    county: 'Pest',
  },
  {
    postal: 2220,
    city: 'Vecsés',
    county: 'Pest',
  },
  {
    postal: 2225,
    city: 'Üllő',
    county: 'Pest',
  },
  {
    postal: 2230,
    city: 'Gyömrő',
    county: 'Pest',
  },
  {
    postal: 2233,
    city: 'Ecser',
    county: 'Pest',
  },
  {
    postal: 2234,
    city: 'Maglód',
    county: 'Pest',
  },
  {
    postal: 2235,
    city: 'Mende',
    county: 'Pest',
  },
  {
    postal: 2241,
    city: 'Sülysáp',
    county: 'Pest',
  },
  {
    postal: 2242,
    city: 'Sülysáp',
    county: 'Pest',
  },
  {
    postal: 2243,
    city: 'Kóka',
    county: 'Pest',
  },
  {
    postal: 2244,
    city: 'Úri',
    county: 'Pest',
  },
  {
    postal: 2251,
    city: 'Tápiószecső',
    county: 'Pest',
  },
  {
    postal: 2252,
    city: 'Tóalmás',
    county: 'Pest',
  },
  {
    postal: 2253,
    city: 'Tápióság',
    county: 'Pest',
  },
  {
    postal: 2254,
    city: 'Szentmártonkáta',
    county: 'Pest',
  },
  {
    postal: 2255,
    city: 'Szentlőrinckáta',
    county: 'Pest',
  },
  {
    postal: 2300,
    city: 'Ráckeve',
    county: 'Pest',
  },
  {
    postal: 2309,
    city: 'Lórév',
    county: 'Pest',
  },
  {
    postal: 2310,
    city: 'Szigetszentmiklós',
    county: 'Pest',
  },
  {
    postal: 2314,
    city: 'Halásztelek',
    county: 'Pest',
  },
  {
    postal: 2315,
    city: 'Szigethalom',
    county: 'Pest',
  },
  {
    postal: 2316,
    city: 'Tököl',
    county: 'Pest',
  },
  {
    postal: 2317,
    city: 'Szigetcsép',
    county: 'Pest',
  },
  {
    postal: 2318,
    city: 'Szigetszentmárton',
    county: 'Pest',
  },
  {
    postal: 2319,
    city: 'Szigetújfalu',
    county: 'Pest',
  },
  {
    postal: 2321,
    city: 'Szigetbecse',
    county: 'Pest',
  },
  {
    postal: 2322,
    city: 'Makád',
    county: 'Pest',
  },
  {
    postal: 2330,
    city: 'Dunaharaszti',
    county: 'Pest',
  },
  {
    postal: 2335,
    city: 'Taksony',
    county: 'Pest',
  },
  {
    postal: 2336,
    city: 'Dunavarsány',
    county: 'Pest',
  },
  {
    postal: 2337,
    city: 'Délegyháza',
    county: 'Pest',
  },
  {
    postal: 2338,
    city: 'Áporka',
    county: 'Pest',
  },
  {
    postal: 2339,
    city: 'Majosháza',
    county: 'Pest',
  },
  {
    postal: 2340,
    city: 'Kiskunlacháza',
    county: 'Pest',
  },
  {
    postal: 2344,
    city: 'Dömsöd',
    county: 'Pest',
  },
  {
    postal: 2345,
    city: 'Apaj',
    county: 'Pest',
  },
  {
    postal: 2347,
    city: 'Bugyi',
    county: 'Pest',
  },
  {
    postal: 2351,
    city: 'Alsónémedi',
    county: 'Pest',
  },
  {
    postal: 2360,
    city: 'Gyál',
    county: 'Pest',
  },
  {
    postal: 2363,
    city: 'Felsőpakony',
    county: 'Pest',
  },
  {
    postal: 2364,
    city: 'Ócsa',
    county: 'Pest',
  },
  {
    postal: 2365,
    city: 'Inárcs',
    county: 'Pest',
  },
  {
    postal: 2366,
    city: 'Kakucs',
    county: 'Pest',
  },
  {
    postal: 2367,
    city: 'Újhartyán',
    county: 'Pest',
  },
  {
    postal: 2370,
    city: 'Dabas',
    county: 'Pest',
  },
  {
    postal: 2371,
    city: 'Dabas',
    county: 'Pest',
  },
  {
    postal: 2373,
    city: 'Dabas',
    county: 'Pest',
  },
  {
    postal: 2375,
    city: 'Tatárszentgyörgy',
    county: 'Pest',
  },
  {
    postal: 2376,
    city: 'Hernád',
    county: 'Pest',
  },
  {
    postal: 2377,
    city: 'Örkény',
    county: 'Pest',
  },
  {
    postal: 2378,
    city: 'Pusztavacs',
    county: 'Pest',
  },
  {
    postal: 2381,
    city: 'Táborfalva',
    county: 'Pest',
  },
  {
    postal: 2440,
    city: 'Százhalombatta',
    county: 'Pest',
  },
  {
    postal: 2461,
    city: 'Tárnok',
    county: 'Pest',
  },
  {
    postal: 2600,
    city: 'Vác',
    county: 'Pest',
  },
  {
    postal: 2612,
    city: 'Kosd',
    county: 'Pest',
  },
  {
    postal: 2613,
    city: 'Rád',
    county: 'Pest',
  },
  {
    postal: 2614,
    city: 'Penc',
    county: 'Pest',
  },
  {
    postal: 2615,
    city: 'Csővár',
    county: 'Pest',
  },
  {
    postal: 2621,
    city: 'Verőce',
    county: 'Pest',
  },
  {
    postal: 2623,
    city: 'Kismaros',
    county: 'Pest',
  },
  {
    postal: 2624,
    city: 'Szokolya',
    county: 'Pest',
  },
  {
    postal: 2625,
    city: 'Kóspallag',
    county: 'Pest',
  },
  {
    postal: 2626,
    city: 'Nagymaros',
    county: 'Pest',
  },
  {
    postal: 2627,
    city: 'Zebegény',
    county: 'Pest',
  },
  {
    postal: 2628,
    city: 'Szob',
    county: 'Pest',
  },
  {
    postal: 2629,
    city: 'Márianosztra',
    county: 'Pest',
  },
  {
    postal: 2631,
    city: 'Ipolydamásd',
    county: 'Pest',
  },
  {
    postal: 2632,
    city: 'Letkés',
    county: 'Pest',
  },
  {
    postal: 2633,
    city: 'Ipolytölgyes',
    county: 'Pest',
  },
  {
    postal: 2634,
    city: 'Nagybörzsöny',
    county: 'Pest',
  },
  {
    postal: 2635,
    city: 'Vámosmikola',
    county: 'Pest',
  },
  {
    postal: 2636,
    city: 'Tésa',
    county: 'Pest',
  },
  {
    postal: 2637,
    city: 'Perőcsény',
    county: 'Pest',
  },
  {
    postal: 2638,
    city: 'Kemence',
    county: 'Pest',
  },
  {
    postal: 2639,
    city: 'Bernecebaráti',
    county: 'Pest',
  },
  {
    postal: 2681,
    city: 'Galgagyörk',
    county: 'Pest',
  },
  {
    postal: 2682,
    city: 'Püspökhatvan',
    county: 'Pest',
  },
  {
    postal: 2683,
    city: 'Acsa',
    county: 'Pest',
  },
  {
    postal: 2700,
    city: 'Cegléd',
    county: 'Pest',
  },
  {
    postal: 2711,
    city: 'Tápiószentmárton',
    county: 'Pest',
  },
  {
    postal: 2712,
    city: 'Nyársapát',
    county: 'Pest',
  },
  {
    postal: 2713,
    city: 'Csemő',
    county: 'Pest',
  },
  {
    postal: 2721,
    city: 'Pilis',
    county: 'Pest',
  },
  {
    postal: 2723,
    city: 'Nyáregyháza',
    county: 'Pest',
  },
  {
    postal: 2724,
    city: 'Újlengyel',
    county: 'Pest',
  },
  {
    postal: 2730,
    city: 'Albertirsa',
    county: 'Pest',
  },
  {
    postal: 2735,
    city: 'Dánszentmiklós',
    county: 'Pest',
  },
  {
    postal: 2736,
    city: 'Mikebuda',
    county: 'Pest',
  },
  {
    postal: 2737,
    city: 'Ceglédbercel',
    county: 'Pest',
  },
  {
    postal: 2738,
    city: 'Cegléd',
    county: 'Pest',
  },
  {
    postal: 2740,
    city: 'Abony',
    county: 'Pest',
  },
  {
    postal: 2745,
    city: 'Kőröstetétlen',
    county: 'Pest',
  },
  {
    postal: 2746,
    city: 'Jászkarajenő',
    county: 'Pest',
  },
  {
    postal: 2747,
    city: 'Törtel',
    county: 'Pest',
  },
  {
    postal: 2750,
    city: 'Nagykőrös',
    county: 'Pest',
  },
  {
    postal: 2755,
    city: 'Kocsér',
    county: 'Pest',
  },
  {
    postal: 2760,
    city: 'Nagykáta',
    county: 'Pest',
  },
  {
    postal: 2764,
    city: 'Tápióbicske',
    county: 'Pest',
  },
  {
    postal: 2765,
    city: 'Farmos',
    county: 'Pest',
  },
  {
    postal: 2766,
    city: 'Tápiószele',
    county: 'Pest',
  },
  {
    postal: 2767,
    city: 'Tápiógyörgye',
    county: 'Pest',
  },
  {
    postal: 2768,
    city: 'Újszilvás',
    county: 'Pest',
  },
  {
    postal: 2769,
    city: 'Tápiószőlős',
    county: 'Pest',
  },
  {
    postal: 7253,
    city: 'Szabadi',
    county: 'Somogy',
  },
  {
    postal: 7253,
    city: 'Csoma',
    county: 'Somogy',
  },
  {
    postal: 7255,
    city: 'Nagyberki',
    county: 'Somogy',
  },
  {
    postal: 7256,
    city: 'Kercseliget',
    county: 'Somogy',
  },
  {
    postal: 7257,
    city: 'Mosdós',
    county: 'Somogy',
  },
  {
    postal: 7258,
    city: 'Baté',
    county: 'Somogy',
  },
  {
    postal: 7258,
    city: 'Kaposkeresztúr',
    county: 'Somogy',
  },
  {
    postal: 7261,
    city: 'Kaposhomok',
    county: 'Somogy',
  },
  {
    postal: 7261,
    city: 'Taszár',
    county: 'Somogy',
  },
  {
    postal: 7271,
    city: 'Fonó',
    county: 'Somogy',
  },
  {
    postal: 7272,
    city: 'Gölle',
    county: 'Somogy',
  },
  {
    postal: 7273,
    city: 'Büssü',
    county: 'Somogy',
  },
  {
    postal: 7274,
    city: 'Kazsok',
    county: 'Somogy',
  },
  {
    postal: 7275,
    city: 'Igal',
    county: 'Somogy',
  },
  {
    postal: 7276,
    city: 'Somogyszil',
    county: 'Somogy',
  },
  {
    postal: 7276,
    city: 'Gadács',
    county: 'Somogy',
  },
  {
    postal: 7279,
    city: 'Kisgyalán',
    county: 'Somogy',
  },
  {
    postal: 7281,
    city: 'Bonnya',
    county: 'Somogy',
  },
  {
    postal: 7282,
    city: 'Kisbárapáti',
    county: 'Somogy',
  },
  {
    postal: 7282,
    city: 'Fiad',
    county: 'Somogy',
  },
  {
    postal: 7283,
    city: 'Somogyacsa',
    county: 'Somogy',
  },
  {
    postal: 7284,
    city: 'Somogydöröcske',
    county: 'Somogy',
  },
  {
    postal: 7285,
    city: 'Szorosad',
    county: 'Somogy',
  },
  {
    postal: 7285,
    city: 'Törökkoppány',
    county: 'Somogy',
  },
  {
    postal: 7285,
    city: 'Kára',
    county: 'Somogy',
  },
  {
    postal: 7400,
    city: 'Kaposvár',
    county: 'Somogy',
  },
  {
    postal: 7400,
    city: 'Zselickislak',
    county: 'Somogy',
  },
  {
    postal: 7431,
    city: 'Juta',
    county: 'Somogy',
  },
  {
    postal: 7432,
    city: 'Csombárd',
    county: 'Somogy',
  },
  {
    postal: 7432,
    city: 'Hetes',
    county: 'Somogy',
  },
  {
    postal: 7434,
    city: 'Mezőcsokonya',
    county: 'Somogy',
  },
  {
    postal: 7435,
    city: 'Somogysárd',
    county: 'Somogy',
  },
  {
    postal: 7436,
    city: 'Újvárfalva',
    county: 'Somogy',
  },
  {
    postal: 7439,
    city: 'Bodrog',
    county: 'Somogy',
  },
  {
    postal: 7441,
    city: 'Magyaregres',
    county: 'Somogy',
  },
  {
    postal: 7442,
    city: 'Várda',
    county: 'Somogy',
  },
  {
    postal: 7443,
    city: 'Alsóbogát',
    county: 'Somogy',
  },
  {
    postal: 7443,
    city: 'Somogyjád',
    county: 'Somogy',
  },
  {
    postal: 7443,
    city: 'Edde',
    county: 'Somogy',
  },
  {
    postal: 7444,
    city: 'Osztopán',
    county: 'Somogy',
  },
  {
    postal: 7451,
    city: 'Kaposvár',
    county: 'Somogy',
  },
  {
    postal: 7452,
    city: 'Somogyaszaló',
    county: 'Somogy',
  },
  {
    postal: 7453,
    city: 'Mernye',
    county: 'Somogy',
  },
  {
    postal: 7454,
    city: 'Somodor',
    county: 'Somogy',
  },
  {
    postal: 7455,
    city: 'Somogygeszti',
    county: 'Somogy',
  },
  {
    postal: 7456,
    city: 'Felsőmocsolád',
    county: 'Somogy',
  },
  {
    postal: 7457,
    city: 'Ecseny',
    county: 'Somogy',
  },
  {
    postal: 7458,
    city: 'Polány',
    county: 'Somogy',
  },
  {
    postal: 7461,
    city: 'Orci',
    county: 'Somogy',
  },
  {
    postal: 7463,
    city: 'Patalom',
    county: 'Somogy',
  },
  {
    postal: 7463,
    city: 'Magyaratád',
    county: 'Somogy',
  },
  {
    postal: 7464,
    city: 'Ráksi',
    county: 'Somogy',
  },
  {
    postal: 7465,
    city: 'Szentgáloskér',
    county: 'Somogy',
  },
  {
    postal: 7471,
    city: 'Zimány',
    county: 'Somogy',
  },
  {
    postal: 7472,
    city: 'Cserénfa',
    county: 'Somogy',
  },
  {
    postal: 7472,
    city: 'Szentbalázs',
    county: 'Somogy',
  },
  {
    postal: 7473,
    city: 'Gálosfa',
    county: 'Somogy',
  },
  {
    postal: 7473,
    city: 'Kaposgyarmat',
    county: 'Somogy',
  },
  {
    postal: 7473,
    city: 'Hajmás',
    county: 'Somogy',
  },
  {
    postal: 7474,
    city: 'Zselicszentpál',
    county: 'Somogy',
  },
  {
    postal: 7474,
    city: 'Simonfa',
    county: 'Somogy',
  },
  {
    postal: 7475,
    city: 'Bőszénfa',
    county: 'Somogy',
  },
  {
    postal: 7476,
    city: 'Kaposszerdahely',
    county: 'Somogy',
  },
  {
    postal: 7477,
    city: 'Zselickisfalud',
    county: 'Somogy',
  },
  {
    postal: 7477,
    city: 'Patca',
    county: 'Somogy',
  },
  {
    postal: 7477,
    city: 'Szenna',
    county: 'Somogy',
  },
  {
    postal: 7477,
    city: 'Szilvásszentmárton',
    county: 'Somogy',
  },
  {
    postal: 7478,
    city: 'Bárdudvarnok',
    county: 'Somogy',
  },
  {
    postal: 7479,
    city: 'Sántos',
    county: 'Somogy',
  },
  {
    postal: 7500,
    city: 'Nagyatád',
    county: 'Somogy',
  },
  {
    postal: 7511,
    city: 'Ötvöskónyi',
    county: 'Somogy',
  },
  {
    postal: 7512,
    city: 'Mike',
    county: 'Somogy',
  },
  {
    postal: 7513,
    city: 'Rinyaszentkirály',
    county: 'Somogy',
  },
  {
    postal: 7514,
    city: 'Tarany',
    county: 'Somogy',
  },
  {
    postal: 7515,
    city: 'Somogyudvarhely',
    county: 'Somogy',
  },
  {
    postal: 7516,
    city: 'Berzence',
    county: 'Somogy',
  },
  {
    postal: 7517,
    city: 'Bolhás',
    county: 'Somogy',
  },
  {
    postal: 7521,
    city: 'Kaposmérő',
    county: 'Somogy',
  },
  {
    postal: 7522,
    city: 'Kaposújlak',
    county: 'Somogy',
  },
  {
    postal: 7523,
    city: 'Kisasszond',
    county: 'Somogy',
  },
  {
    postal: 7523,
    city: 'Kaposfő',
    county: 'Somogy',
  },
  {
    postal: 7524,
    city: 'Kiskorpád',
    county: 'Somogy',
  },
  {
    postal: 7525,
    city: 'Jákó',
    county: 'Somogy',
  },
  {
    postal: 7526,
    city: 'Csököly',
    county: 'Somogy',
  },
  {
    postal: 7527,
    city: 'Rinyakovácsi',
    county: 'Somogy',
  },
  {
    postal: 7527,
    city: 'Gige',
    county: 'Somogy',
  },
  {
    postal: 7530,
    city: 'Kőkút',
    county: 'Somogy',
  },
  {
    postal: 7530,
    city: 'Kadarkút',
    county: 'Somogy',
  },
  {
    postal: 7532,
    city: 'Hencse',
    county: 'Somogy',
  },
  {
    postal: 7533,
    city: 'Visnye',
    county: 'Somogy',
  },
  {
    postal: 7533,
    city: 'Hedrehely',
    county: 'Somogy',
  },
  {
    postal: 7535,
    city: 'Lad',
    county: 'Somogy',
  },
  {
    postal: 7536,
    city: 'Patosfa',
    county: 'Somogy',
  },
  {
    postal: 7537,
    city: 'Homokszentgyörgy',
    county: 'Somogy',
  },
  {
    postal: 7538,
    city: 'Kálmáncsa',
    county: 'Somogy',
  },
  {
    postal: 7539,
    city: 'Szulok',
    county: 'Somogy',
  },
  {
    postal: 7541,
    city: 'Kutas',
    county: 'Somogy',
  },
  {
    postal: 7542,
    city: 'Kisbajom',
    county: 'Somogy',
  },
  {
    postal: 7543,
    city: 'Beleg',
    county: 'Somogy',
  },
  {
    postal: 7544,
    city: 'Szabás',
    county: 'Somogy',
  },
  {
    postal: 7545,
    city: 'Nagykorpád',
    county: 'Somogy',
  },
  {
    postal: 7551,
    city: 'Lábod',
    county: 'Somogy',
  },
  {
    postal: 7552,
    city: 'Rinyabesenyő',
    county: 'Somogy',
  },
  {
    postal: 7553,
    city: 'Görgeteg',
    county: 'Somogy',
  },
  {
    postal: 7555,
    city: 'Csokonyavisonta',
    county: 'Somogy',
  },
  {
    postal: 7556,
    city: 'Rinyaújlak',
    county: 'Somogy',
  },
  {
    postal: 7557,
    city: 'Barcs',
    county: 'Somogy',
  },
  {
    postal: 7561,
    city: 'Pálmajor',
    county: 'Somogy',
  },
  {
    postal: 7561,
    city: 'Nagybajom',
    county: 'Somogy',
  },
  {
    postal: 7562,
    city: 'Segesd',
    county: 'Somogy',
  },
  {
    postal: 7563,
    city: 'Somogyszob',
    county: 'Somogy',
  },
  {
    postal: 7564,
    city: 'Kaszó',
    county: 'Somogy',
  },
  {
    postal: 7570,
    city: 'Barcs',
    county: 'Somogy',
  },
  {
    postal: 7582,
    city: 'Komlósd',
    county: 'Somogy',
  },
  {
    postal: 7582,
    city: 'Péterhida',
    county: 'Somogy',
  },
  {
    postal: 7584,
    city: 'Somogyaracs',
    county: 'Somogy',
  },
  {
    postal: 7584,
    city: 'Babócsa',
    county: 'Somogy',
  },
  {
    postal: 7584,
    city: 'Rinyaújnép',
    county: 'Somogy',
  },
  {
    postal: 7585,
    city: 'Háromfa',
    county: 'Somogy',
  },
  {
    postal: 7585,
    city: 'Bakháza',
    county: 'Somogy',
  },
  {
    postal: 7586,
    city: 'Bolhó',
    county: 'Somogy',
  },
  {
    postal: 7587,
    city: 'Heresznye',
    county: 'Somogy',
  },
  {
    postal: 7588,
    city: 'Vízvár',
    county: 'Somogy',
  },
  {
    postal: 7589,
    city: 'Bélavár',
    county: 'Somogy',
  },
  {
    postal: 7918,
    city: 'Tótújfalu',
    county: 'Somogy',
  },
  {
    postal: 7918,
    city: 'Lakócsa',
    county: 'Somogy',
  },
  {
    postal: 7918,
    city: 'Szentborbás',
    county: 'Somogy',
  },
  {
    postal: 7977,
    city: 'Kastélyosdombó',
    county: 'Somogy',
  },
  {
    postal: 7977,
    city: 'Potony',
    county: 'Somogy',
  },
  {
    postal: 7977,
    city: 'Drávagárdony',
    county: 'Somogy',
  },
  {
    postal: 7979,
    city: 'Drávatamási',
    county: 'Somogy',
  },
  {
    postal: 7987,
    city: 'Istvándi',
    county: 'Somogy',
  },
  {
    postal: 7988,
    city: 'Darány',
    county: 'Somogy',
  },
  {
    postal: 8600,
    city: 'Siófok',
    county: 'Somogy',
  },
  {
    postal: 8612,
    city: 'Nyim',
    county: 'Somogy',
  },
  {
    postal: 8613,
    city: 'Balatonendréd',
    county: 'Somogy',
  },
  {
    postal: 8614,
    city: 'Bálványos',
    county: 'Somogy',
  },
  {
    postal: 8617,
    city: 'Kőröshegy',
    county: 'Somogy',
  },
  {
    postal: 8618,
    city: 'Kereki',
    county: 'Somogy',
  },
  {
    postal: 8619,
    city: 'Pusztaszemes',
    county: 'Somogy',
  },
  {
    postal: 8621,
    city: 'Zamárdi',
    county: 'Somogy',
  },
  {
    postal: 8622,
    city: 'Szántód',
    county: 'Somogy',
  },
  {
    postal: 8623,
    city: 'Balatonföldvár',
    county: 'Somogy',
  },
  {
    postal: 8624,
    city: 'Balatonszárszó',
    county: 'Somogy',
  },
  {
    postal: 8625,
    city: 'Szólád',
    county: 'Somogy',
  },
  {
    postal: 8626,
    city: 'Teleki',
    county: 'Somogy',
  },
  {
    postal: 8627,
    city: 'Kötcse',
    county: 'Somogy',
  },
  {
    postal: 8628,
    city: 'Nagycsepely',
    county: 'Somogy',
  },
  {
    postal: 8630,
    city: 'Balatonboglár',
    county: 'Somogy',
  },
  {
    postal: 8635,
    city: 'Ordacsehi',
    county: 'Somogy',
  },
  {
    postal: 8636,
    city: 'Balatonszemes',
    county: 'Somogy',
  },
  {
    postal: 8637,
    city: 'Balatonőszöd',
    county: 'Somogy',
  },
  {
    postal: 8638,
    city: 'Balatonlelle',
    county: 'Somogy',
  },
  {
    postal: 8640,
    city: 'Fonyód',
    county: 'Somogy',
  },
  {
    postal: 8646,
    city: 'Balatonfenyves',
    county: 'Somogy',
  },
  {
    postal: 8647,
    city: 'Balatonmáriafürdő',
    county: 'Somogy',
  },
  {
    postal: 8648,
    city: 'Balatonkeresztúr',
    county: 'Somogy',
  },
  {
    postal: 8649,
    city: 'Balatonberény',
    county: 'Somogy',
  },
  {
    postal: 8651,
    city: 'Balatonszabadi',
    county: 'Somogy',
  },
  {
    postal: 8652,
    city: 'Siójut',
    county: 'Somogy',
  },
  {
    postal: 8653,
    city: 'Ádánd',
    county: 'Somogy',
  },
  {
    postal: 8654,
    city: 'Ságvár',
    county: 'Somogy',
  },
  {
    postal: 8655,
    city: 'Som',
    county: 'Somogy',
  },
  {
    postal: 8656,
    city: 'Nagyberény',
    county: 'Somogy',
  },
  {
    postal: 8658,
    city: 'Bábonymegyer',
    county: 'Somogy',
  },
  {
    postal: 8660,
    city: 'Lulla',
    county: 'Somogy',
  },
  {
    postal: 8660,
    city: 'Sérsekszőlős',
    county: 'Somogy',
  },
  {
    postal: 8660,
    city: 'Torvaj',
    county: 'Somogy',
  },
  {
    postal: 8660,
    city: 'Zala',
    county: 'Somogy',
  },
  {
    postal: 8660,
    city: 'Tab',
    county: 'Somogy',
  },
  {
    postal: 8666,
    city: 'Bedegkér',
    county: 'Somogy',
  },
  {
    postal: 8666,
    city: 'Somogyegres',
    county: 'Somogy',
  },
  {
    postal: 8667,
    city: 'Kánya',
    county: 'Somogy',
  },
  {
    postal: 8668,
    city: 'Tengőd',
    county: 'Somogy',
  },
  {
    postal: 8669,
    city: 'Miklósi',
    county: 'Somogy',
  },
  {
    postal: 8671,
    city: 'Kapoly',
    county: 'Somogy',
  },
  {
    postal: 8672,
    city: 'Zics',
    county: 'Somogy',
  },
  {
    postal: 8673,
    city: 'Somogymeggyes',
    county: 'Somogy',
  },
  {
    postal: 8674,
    city: 'Nágocs',
    county: 'Somogy',
  },
  {
    postal: 8675,
    city: 'Andocs',
    county: 'Somogy',
  },
  {
    postal: 8676,
    city: 'Karád',
    county: 'Somogy',
  },
  {
    postal: 8681,
    city: 'Látrány',
    county: 'Somogy',
  },
  {
    postal: 8681,
    city: 'Visz',
    county: 'Somogy',
  },
  {
    postal: 8683,
    city: 'Somogytúr',
    county: 'Somogy',
  },
  {
    postal: 8684,
    city: 'Somogybabod',
    county: 'Somogy',
  },
  {
    postal: 8685,
    city: 'Gamás',
    county: 'Somogy',
  },
  {
    postal: 8691,
    city: 'Balatonboglár',
    county: 'Somogy',
  },
  {
    postal: 8692,
    city: 'Gyugy',
    county: 'Somogy',
  },
  {
    postal: 8692,
    city: 'Szőlősgyörök',
    county: 'Somogy',
  },
  {
    postal: 8693,
    city: 'Lengyeltóti',
    county: 'Somogy',
  },
  {
    postal: 8693,
    city: 'Kisberény',
    county: 'Somogy',
  },
  {
    postal: 8694,
    city: 'Hács',
    county: 'Somogy',
  },
  {
    postal: 8695,
    city: 'Buzsák',
    county: 'Somogy',
  },
  {
    postal: 8696,
    city: 'Táska',
    county: 'Somogy',
  },
  {
    postal: 8697,
    city: 'Öreglak',
    county: 'Somogy',
  },
  {
    postal: 8698,
    city: 'Pamuk',
    county: 'Somogy',
  },
  {
    postal: 8698,
    city: 'Somogyvár',
    county: 'Somogy',
  },
  {
    postal: 8699,
    city: 'Somogyvámos',
    county: 'Somogy',
  },
  {
    postal: 8700,
    city: 'Marcali',
    county: 'Somogy',
  },
  {
    postal: 8700,
    city: 'Csömend',
    county: 'Somogy',
  },
  {
    postal: 8705,
    city: 'Somogyszentpál',
    county: 'Somogy',
  },
  {
    postal: 8706,
    city: 'Nikla',
    county: 'Somogy',
  },
  {
    postal: 8707,
    city: 'Libickozma',
    county: 'Somogy',
  },
  {
    postal: 8707,
    city: 'Pusztakovácsi',
    county: 'Somogy',
  },
  {
    postal: 8708,
    city: 'Somogyfajsz',
    county: 'Somogy',
  },
  {
    postal: 8709,
    city: 'Marcali',
    county: 'Somogy',
  },
  {
    postal: 8710,
    city: 'Balatonszentgyörgy',
    county: 'Somogy',
  },
  {
    postal: 8711,
    city: 'Vörs',
    county: 'Somogy',
  },
  {
    postal: 8712,
    city: 'Balatonújlak',
    county: 'Somogy',
  },
  {
    postal: 8713,
    city: 'Kéthely',
    county: 'Somogy',
  },
  {
    postal: 8714,
    city: 'Kelevíz',
    county: 'Somogy',
  },
  {
    postal: 8714,
    city: 'Marcali',
    county: 'Somogy',
  },
  {
    postal: 8716,
    city: 'Gadány',
    county: 'Somogy',
  },
  {
    postal: 8716,
    city: 'Hosszúvíz',
    county: 'Somogy',
  },
  {
    postal: 8716,
    city: 'Mesztegnyő',
    county: 'Somogy',
  },
  {
    postal: 8717,
    city: 'Nemeskisfalud',
    county: 'Somogy',
  },
  {
    postal: 8717,
    city: 'Szenyér',
    county: 'Somogy',
  },
  {
    postal: 8718,
    city: 'Tapsony',
    county: 'Somogy',
  },
  {
    postal: 8719,
    city: 'Böhönye',
    county: 'Somogy',
  },
  {
    postal: 8721,
    city: 'Vése',
    county: 'Somogy',
  },
  {
    postal: 8722,
    city: 'Nemesdéd',
    county: 'Somogy',
  },
  {
    postal: 8723,
    city: 'Varászló',
    county: 'Somogy',
  },
  {
    postal: 8724,
    city: 'Inke',
    county: 'Somogy',
  },
  {
    postal: 8725,
    city: 'Iharosberény',
    county: 'Somogy',
  },
  {
    postal: 8726,
    city: 'Somogycsicsó',
    county: 'Somogy',
  },
  {
    postal: 8726,
    city: 'Iharos',
    county: 'Somogy',
  },
  {
    postal: 8728,
    city: 'Pogányszentpéter',
    county: 'Somogy',
  },
  {
    postal: 8731,
    city: 'Tikos',
    county: 'Somogy',
  },
  {
    postal: 8731,
    city: 'Hollád',
    county: 'Somogy',
  },
  {
    postal: 8732,
    city: 'Főnyed',
    county: 'Somogy',
  },
  {
    postal: 8732,
    city: 'Szegerdő',
    county: 'Somogy',
  },
  {
    postal: 8732,
    city: 'Sávoly',
    county: 'Somogy',
  },
  {
    postal: 8733,
    city: 'Somogysámson',
    county: 'Somogy',
  },
  {
    postal: 8734,
    city: 'Somogyzsitfa',
    county: 'Somogy',
  },
  {
    postal: 8735,
    city: 'Csákány',
    county: 'Somogy',
  },
  {
    postal: 8736,
    city: 'Szőkedencs',
    county: 'Somogy',
  },
  {
    postal: 8737,
    city: 'Somogysimonyi',
    county: 'Somogy',
  },
  {
    postal: 8738,
    city: 'Nemesvid',
    county: 'Somogy',
  },
  {
    postal: 8739,
    city: 'Nagyszakácsi',
    county: 'Somogy',
  },
  {
    postal: 8840,
    city: 'Csurgó',
    county: 'Somogy',
  },
  {
    postal: 8840,
    city: 'Csurgónagymarton',
    county: 'Somogy',
  },
  {
    postal: 8849,
    city: 'Szenta',
    county: 'Somogy',
  },
  {
    postal: 8851,
    city: 'Gyékényes',
    county: 'Somogy',
  },
  {
    postal: 8852,
    city: 'Zákány',
    county: 'Somogy',
  },
  {
    postal: 8853,
    city: 'Zákányfalu',
    county: 'Somogy',
  },
  {
    postal: 8854,
    city: 'Őrtilos',
    county: 'Somogy',
  },
  {
    postal: 8858,
    city: 'Porrogszentpál',
    county: 'Somogy',
  },
  {
    postal: 8858,
    city: 'Somogybükkösd',
    county: 'Somogy',
  },
  {
    postal: 8858,
    city: 'Porrog',
    county: 'Somogy',
  },
  {
    postal: 8858,
    city: 'Porrogszentkirály',
    county: 'Somogy',
  },
  {
    postal: 4231,
    city: 'Bököny',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4232,
    city: 'Geszteréd',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4233,
    city: 'Balkány',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4234,
    city: 'Szakoly',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4235,
    city: 'Biri',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4244,
    city: 'Újfehértó',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4245,
    city: 'Érpatak',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4246,
    city: 'Nyíregyháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4267,
    city: 'Penészlek',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4300,
    city: 'Nyírbátor',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4311,
    city: 'Nyírgyulaj',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4320,
    city: 'Nagykálló',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4324,
    city: 'Kállósemjén',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4325,
    city: 'Kisléta',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4326,
    city: 'Máriapócs',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4327,
    city: 'Pócspetri',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4331,
    city: 'Nyírcsászári',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4332,
    city: 'Nyírderzs',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4333,
    city: 'Nyírkáta',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4334,
    city: 'Hodász',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4335,
    city: 'Kántorjánosi',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4336,
    city: 'Őr',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4337,
    city: 'Jármi',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4338,
    city: 'Papos',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4341,
    city: 'Nyírvasvári',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4342,
    city: 'Terem',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4343,
    city: 'Bátorliget',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4351,
    city: 'Vállaj',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4352,
    city: 'Mérk',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4353,
    city: 'Tiborszállás',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4354,
    city: 'Fábiánháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4355,
    city: 'Nagyecsed',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4356,
    city: 'Nyírcsaholy',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4361,
    city: 'Nyírbogát',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4362,
    city: 'Nyírgelse',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4363,
    city: 'Nyírmihálydi',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4371,
    city: 'Nyírlugos',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4372,
    city: 'Nyírbéltek',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4373,
    city: 'Ömböly',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4374,
    city: 'Encsencs',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4375,
    city: 'Piricse',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4376,
    city: 'Nyírpilis',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4400,
    city: 'Nyíregyháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4405,
    city: 'Nyíregyháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4431,
    city: 'Nyíregyháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4432,
    city: 'Nyíregyháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4433,
    city: 'Nyíregyháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4434,
    city: 'Kálmánháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4440,
    city: 'Tiszavasvári',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4441,
    city: 'Szorgalmatos',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4445,
    city: 'Nagycserkesz',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4446,
    city: 'Tiszaeszlár',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4447,
    city: 'Tiszalök',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4450,
    city: 'Tiszalök',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4455,
    city: 'Tiszadada',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4456,
    city: 'Tiszadob',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4461,
    city: 'Nyírtelek',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4463,
    city: 'Tiszanagyfalu',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4464,
    city: 'Tiszaeszlár',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4465,
    city: 'Rakamaz',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4466,
    city: 'Timár',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4467,
    city: 'Szabolcs',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4468,
    city: 'Balsa',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4471,
    city: 'Gávavencsellő',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4472,
    city: 'Gávavencsellő',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4474,
    city: 'Tiszabercel',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4475,
    city: 'Paszab',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4481,
    city: 'Nyíregyháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4482,
    city: 'Kótaj',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4483,
    city: 'Buj',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4484,
    city: 'Ibrány',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4485,
    city: 'Nagyhalász',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4486,
    city: 'Tiszatelek',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4487,
    city: 'Tiszatelek',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4488,
    city: 'Beszterec',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4491,
    city: 'Újdombrád',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4492,
    city: 'Dombrád',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4493,
    city: 'Tiszakanyár',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4494,
    city: 'Kékcse',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4495,
    city: 'Döge',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4496,
    city: 'Szabolcsveresmart',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4501,
    city: 'Kemecse',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4502,
    city: 'Vasmegyer',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4503,
    city: 'Tiszarád',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4511,
    city: 'Nyírbogdány',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4515,
    city: 'Kék',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4516,
    city: 'Demecser',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4517,
    city: 'Gégény',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4521,
    city: 'Berkesz',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4522,
    city: 'Nyírtass',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4523,
    city: 'Pátroha',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4524,
    city: 'Ajak',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4525,
    city: 'Rétközberencs',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4531,
    city: 'Nyírpazony',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4532,
    city: 'Nyírtura',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4533,
    city: 'Sényő',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4534,
    city: 'Székely',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4535,
    city: 'Nyíribrony',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4536,
    city: 'Ramocsaháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4537,
    city: 'Nyírkércs',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4541,
    city: 'Nyírjákó',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4542,
    city: 'Petneháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4543,
    city: 'Laskod',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4544,
    city: 'Nyírkarász',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4545,
    city: 'Gyulaháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4546,
    city: 'Anarcs',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4547,
    city: 'Szabolcsbáka',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4551,
    city: 'Nyíregyháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4552,
    city: 'Napkor',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4553,
    city: 'Apagy',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4554,
    city: 'Nyírtét',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4555,
    city: 'Levelek',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4556,
    city: 'Magy',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4557,
    city: 'Besenyőd',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4558,
    city: 'Ófehértó',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4561,
    city: 'Baktalórántháza',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4562,
    city: 'Vaja',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4563,
    city: 'Rohod',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4564,
    city: 'Nyírmada',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4565,
    city: 'Pusztadobos',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4566,
    city: 'Ilk',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4567,
    city: 'Gemzse',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4600,
    city: 'Kisvárda',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4611,
    city: 'Jéke',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4621,
    city: 'Fényeslitke',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4622,
    city: 'Komoró',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4623,
    city: 'Tuzsér',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4624,
    city: 'Tiszabezdéd',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4625,
    city: 'Záhony',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4625,
    city: 'Győröcske',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4627,
    city: 'Zsurk',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4628,
    city: 'Tiszaszentmárton',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4631,
    city: 'Pap',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4632,
    city: 'Nyírlövő',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4633,
    city: 'Lövőpetri',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4634,
    city: 'Aranyosapáti',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4635,
    city: 'Újkenéz',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4641,
    city: 'Mezőladány',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4642,
    city: 'Tornyospálca',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4643,
    city: 'Benk',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4644,
    city: 'Mándok',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4645,
    city: 'Tiszamogyorós',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4646,
    city: 'Eperjeske',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4700,
    city: 'Mátészalka',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4721,
    city: 'Szamoskér',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4722,
    city: 'Nyírmeggyes',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4731,
    city: 'Tunyogmatolcs',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4732,
    city: 'Cégénydányád',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4733,
    city: 'Gyügye',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4734,
    city: 'Szamosújlak',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4735,
    city: 'Hermánszeg',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4735,
    city: 'Szamossályi',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4737,
    city: 'Kisnamény',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4737,
    city: 'Darnó',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4741,
    city: 'Jánkmajtis',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4742,
    city: 'Csegöld',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4743,
    city: 'Csengersima',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4745,
    city: 'Szamosbecs',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4746,
    city: 'Szamostatárfalva',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4751,
    city: 'Kocsord',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4752,
    city: 'Győrtelek',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4754,
    city: 'Géberjén',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4754,
    city: 'Fülpösdaróc',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4755,
    city: 'Ököritófülpös',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4756,
    city: 'Rápolt',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4761,
    city: 'Porcsalma',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4762,
    city: 'Tyukod',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4763,
    city: 'Ura',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4764,
    city: 'Csengerújfalu',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4765,
    city: 'Komlódtótfalu',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4765,
    city: 'Csenger',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4766,
    city: 'Pátyod',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4767,
    city: 'Szamosangyalos',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4800,
    city: 'Vásárosnamény',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4803,
    city: 'Vásárosnamény',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4804,
    city: 'Vásárosnamény',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4811,
    city: 'Kisvarsány',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4812,
    city: 'Nagyvarsány',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4813,
    city: 'Gyüre',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4821,
    city: 'Ópályi',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4822,
    city: 'Nyírparasznya',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4823,
    city: 'Nagydobos',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4824,
    city: 'Szamosszeg',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4826,
    city: 'Olcsva',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4831,
    city: 'Tiszaszalka',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4832,
    city: 'Tiszavid',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4833,
    city: 'Tiszaadony',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4834,
    city: 'Tiszakerecseny',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4835,
    city: 'Mátyus',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4836,
    city: 'Lónya',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4841,
    city: 'Jánd',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4842,
    city: 'Gulács',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4843,
    city: 'Hetefejércse',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4844,
    city: 'Csaroda',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4845,
    city: 'Tákos',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4900,
    city: 'Fehérgyarmat',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4911,
    city: 'Nábrád',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4912,
    city: 'Kérsemjén',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4913,
    city: 'Panyola',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4914,
    city: 'Olcsvaapáti',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4921,
    city: 'Tivadar',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4921,
    city: 'Kisar',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4922,
    city: 'Nagyar',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4931,
    city: 'Tarpa',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4932,
    city: 'Márokpapi',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4933,
    city: 'Beregsurány',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4934,
    city: 'Beregdaróc',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4935,
    city: 'Gelénes',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4936,
    city: 'Vámosatya',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4937,
    city: 'Barabás',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4941,
    city: 'Penyige',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4942,
    city: 'Nemesborzova',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4942,
    city: 'Mánd',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4943,
    city: 'Kömörő',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4944,
    city: 'Túristvándi',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4945,
    city: 'Szatmárcseke',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4946,
    city: 'Tiszakóród',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4947,
    city: 'Tiszacsécse',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4948,
    city: 'Milota',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4951,
    city: 'Tiszabecs',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4952,
    city: 'Uszka',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4953,
    city: 'Magosliget',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4954,
    city: 'Sonkád',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4955,
    city: 'Botpalád',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4956,
    city: 'Kispalád',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4961,
    city: 'Zsarolyán',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4962,
    city: 'Nagyszekeres',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4963,
    city: 'Kisszekeres',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4964,
    city: 'Fülesd',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4965,
    city: 'Kölcse',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4966,
    city: 'Vámosoroszi',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4967,
    city: 'Csaholc',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4968,
    city: 'Túrricse',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4969,
    city: 'Tisztaberek',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4971,
    city: 'Rozsály',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4972,
    city: 'Gacsály',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4973,
    city: 'Császló',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4974,
    city: 'Zajta',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4975,
    city: 'Méhtelek',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4976,
    city: 'Garbolc',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4977,
    city: 'Nagyhódos',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 4977,
    city: 'Kishódos',
    county: 'Szabolcs-Szatmár-Bereg',
  },
  {
    postal: 7020,
    city: 'Dunaföldvár',
    county: 'Tolna',
  },
  {
    postal: 7025,
    city: 'Bölcske',
    county: 'Tolna',
  },
  {
    postal: 7026,
    city: 'Madocsa',
    county: 'Tolna',
  },
  {
    postal: 7027,
    city: 'Paks',
    county: 'Tolna',
  },
  {
    postal: 7030,
    city: 'Paks',
    county: 'Tolna',
  },
  {
    postal: 7038,
    city: 'Pusztahencse',
    county: 'Tolna',
  },
  {
    postal: 7039,
    city: 'Németkér',
    county: 'Tolna',
  },
  {
    postal: 7042,
    city: 'Pálfa',
    county: 'Tolna',
  },
  {
    postal: 7043,
    city: 'Bikács',
    county: 'Tolna',
  },
  {
    postal: 7044,
    city: 'Nagydorog',
    county: 'Tolna',
  },
  {
    postal: 7045,
    city: 'Györköny',
    county: 'Tolna',
  },
  {
    postal: 7047,
    city: 'Sárszentlőrinc',
    county: 'Tolna',
  },
  {
    postal: 7051,
    city: 'Kajdacs',
    county: 'Tolna',
  },
  {
    postal: 7052,
    city: 'Kölesd',
    county: 'Tolna',
  },
  {
    postal: 7054,
    city: 'Tengelic',
    county: 'Tolna',
  },
  {
    postal: 7056,
    city: 'Szedres',
    county: 'Tolna',
  },
  {
    postal: 7057,
    city: 'Medina',
    county: 'Tolna',
  },
  {
    postal: 7061,
    city: 'Belecska',
    county: 'Tolna',
  },
  {
    postal: 7062,
    city: 'Keszőhidegkút',
    county: 'Tolna',
  },
  {
    postal: 7063,
    city: 'Szárazd',
    county: 'Tolna',
  },
  {
    postal: 7064,
    city: 'Gyönk',
    county: 'Tolna',
  },
  {
    postal: 7065,
    city: 'Miszla',
    county: 'Tolna',
  },
  {
    postal: 7066,
    city: 'Udvari',
    county: 'Tolna',
  },
  {
    postal: 7067,
    city: 'Varsád',
    county: 'Tolna',
  },
  {
    postal: 7068,
    city: 'Kistormás',
    county: 'Tolna',
  },
  {
    postal: 7071,
    city: 'Szakadát',
    county: 'Tolna',
  },
  {
    postal: 7072,
    city: 'Diósberény',
    county: 'Tolna',
  },
  {
    postal: 7081,
    city: 'Simontornya',
    county: 'Tolna',
  },
  {
    postal: 7082,
    city: 'Kisszékely',
    county: 'Tolna',
  },
  {
    postal: 7083,
    city: 'Tolnanémedi',
    county: 'Tolna',
  },
  {
    postal: 7084,
    city: 'Pincehely',
    county: 'Tolna',
  },
  {
    postal: 7085,
    city: 'Nagyszékely',
    county: 'Tolna',
  },
  {
    postal: 7086,
    city: 'Ozora',
    county: 'Tolna',
  },
  {
    postal: 7087,
    city: 'Fürged',
    county: 'Tolna',
  },
  {
    postal: 7090,
    city: 'Tamási',
    county: 'Tolna',
  },
  {
    postal: 7091,
    city: 'Pári',
    county: 'Tolna',
  },
  {
    postal: 7092,
    city: 'Nagykónyi',
    county: 'Tolna',
  },
  {
    postal: 7093,
    city: 'Értény',
    county: 'Tolna',
  },
  {
    postal: 7094,
    city: 'Koppányszántó',
    county: 'Tolna',
  },
  {
    postal: 7095,
    city: 'Újireg',
    county: 'Tolna',
  },
  {
    postal: 7095,
    city: 'Iregszemcse',
    county: 'Tolna',
  },
  {
    postal: 7097,
    city: 'Nagyszokoly',
    county: 'Tolna',
  },
  {
    postal: 7098,
    city: 'Magyarkeszi',
    county: 'Tolna',
  },
  {
    postal: 7099,
    city: 'Felsőnyék',
    county: 'Tolna',
  },
  {
    postal: 7100,
    city: 'Szekszárd',
    county: 'Tolna',
  },
  {
    postal: 7121,
    city: 'Szálka',
    county: 'Tolna',
  },
  {
    postal: 7122,
    city: 'Kakasd',
    county: 'Tolna',
  },
  {
    postal: 7130,
    city: 'Tolna',
    county: 'Tolna',
  },
  {
    postal: 7131,
    city: 'Tolna',
    county: 'Tolna',
  },
  {
    postal: 7132,
    city: 'Bogyiszló',
    county: 'Tolna',
  },
  {
    postal: 7133,
    city: 'Fadd',
    county: 'Tolna',
  },
  {
    postal: 7134,
    city: 'Gerjen',
    county: 'Tolna',
  },
  {
    postal: 7135,
    city: 'Dunaszentgyörgy',
    county: 'Tolna',
  },
  {
    postal: 7136,
    city: 'Fácánkert',
    county: 'Tolna',
  },
  {
    postal: 7140,
    city: 'Bátaszék',
    county: 'Tolna',
  },
  {
    postal: 7142,
    city: 'Pörböly',
    county: 'Tolna',
  },
  {
    postal: 7143,
    city: 'Őcsény',
    county: 'Tolna',
  },
  {
    postal: 7144,
    city: 'Decs',
    county: 'Tolna',
  },
  {
    postal: 7145,
    city: 'Sárpilis',
    county: 'Tolna',
  },
  {
    postal: 7146,
    city: 'Várdomb',
    county: 'Tolna',
  },
  {
    postal: 7147,
    city: 'Alsónána',
    county: 'Tolna',
  },
  {
    postal: 7148,
    city: 'Alsónyék',
    county: 'Tolna',
  },
  {
    postal: 7149,
    city: 'Báta',
    county: 'Tolna',
  },
  {
    postal: 7150,
    city: 'Bonyhád',
    county: 'Tolna',
  },
  {
    postal: 7158,
    city: 'Bonyhádvarasd',
    county: 'Tolna',
  },
  {
    postal: 7159,
    city: 'Kisdorog',
    county: 'Tolna',
  },
  {
    postal: 7161,
    city: 'Cikó',
    county: 'Tolna',
  },
  {
    postal: 7162,
    city: 'Grábóc',
    county: 'Tolna',
  },
  {
    postal: 7163,
    city: 'Mőcsény',
    county: 'Tolna',
  },
  {
    postal: 7164,
    city: 'Bátaapáti',
    county: 'Tolna',
  },
  {
    postal: 7165,
    city: 'Mórágy',
    county: 'Tolna',
  },
  {
    postal: 7171,
    city: 'Sióagárd',
    county: 'Tolna',
  },
  {
    postal: 7172,
    city: 'Harc',
    county: 'Tolna',
  },
  {
    postal: 7173,
    city: 'Zomba',
    county: 'Tolna',
  },
  {
    postal: 7174,
    city: 'Kéty',
    county: 'Tolna',
  },
  {
    postal: 7175,
    city: 'Felsőnána',
    county: 'Tolna',
  },
  {
    postal: 7176,
    city: 'Murga',
    county: 'Tolna',
  },
  {
    postal: 7181,
    city: 'Tevel',
    county: 'Tolna',
  },
  {
    postal: 7182,
    city: 'Závod',
    county: 'Tolna',
  },
  {
    postal: 7183,
    city: 'Kisvejke',
    county: 'Tolna',
  },
  {
    postal: 7184,
    city: 'Lengyel',
    county: 'Tolna',
  },
  {
    postal: 7185,
    city: 'Mucsfa',
    county: 'Tolna',
  },
  {
    postal: 7186,
    city: 'Aparhant',
    county: 'Tolna',
  },
  {
    postal: 7186,
    city: 'Nagyvejke',
    county: 'Tolna',
  },
  {
    postal: 7187,
    city: 'Bonyhád',
    county: 'Tolna',
  },
  {
    postal: 7191,
    city: 'Hőgyész',
    county: 'Tolna',
  },
  {
    postal: 7192,
    city: 'Szakály',
    county: 'Tolna',
  },
  {
    postal: 7193,
    city: 'Regöly',
    county: 'Tolna',
  },
  {
    postal: 7194,
    city: 'Kalaznó',
    county: 'Tolna',
  },
  {
    postal: 7195,
    city: 'Mucsi',
    county: 'Tolna',
  },
  {
    postal: 7200,
    city: 'Dombóvár',
    county: 'Tolna',
  },
  {
    postal: 7211,
    city: 'Dalmand',
    county: 'Tolna',
  },
  {
    postal: 7212,
    city: 'Kocsola',
    county: 'Tolna',
  },
  {
    postal: 7213,
    city: 'Szakcs',
    county: 'Tolna',
  },
  {
    postal: 7214,
    city: 'Várong',
    county: 'Tolna',
  },
  {
    postal: 7214,
    city: 'Lápafő',
    county: 'Tolna',
  },
  {
    postal: 7215,
    city: 'Nak',
    county: 'Tolna',
  },
  {
    postal: 7224,
    city: 'Dúzs',
    county: 'Tolna',
  },
  {
    postal: 7225,
    city: 'Csibrák',
    county: 'Tolna',
  },
  {
    postal: 7226,
    city: 'Kurd',
    county: 'Tolna',
  },
  {
    postal: 7227,
    city: 'Gyulaj',
    county: 'Tolna',
  },
  {
    postal: 7228,
    city: 'Döbrököz',
    county: 'Tolna',
  },
  {
    postal: 7251,
    city: 'Kapospula',
    county: 'Tolna',
  },
  {
    postal: 7252,
    city: 'Attala',
    county: 'Tolna',
  },
  {
    postal: 7341,
    city: 'Csikóstőttős',
    county: 'Tolna',
  },
  {
    postal: 7352,
    city: 'Györe',
    county: 'Tolna',
  },
  {
    postal: 7353,
    city: 'Izmény',
    county: 'Tolna',
  },
  {
    postal: 7354,
    city: 'Váralja',
    county: 'Tolna',
  },
  {
    postal: 7355,
    city: 'Nagymányok',
    county: 'Tolna',
  },
  {
    postal: 7356,
    city: 'Kismányok',
    county: 'Tolna',
  },
  {
    postal: 7357,
    city: 'Jágónak',
    county: 'Tolna',
  },
  {
    postal: 7361,
    city: 'Kaposszekcső',
    county: 'Tolna',
  },
  {
    postal: 7362,
    city: 'Vásárosdombó',
    county: 'Tolna',
  },
  {
    postal: 7362,
    city: 'Tarrós',
    county: 'Tolna',
  },
  {
    postal: 7362,
    city: 'Gerényes',
    county: 'Tolna',
  },
  {
    postal: 9500,
    city: 'Celldömölk',
    county: 'Vas',
  },
  {
    postal: 9511,
    city: 'Kemenesmihályfa',
    county: 'Vas',
  },
  {
    postal: 9512,
    city: 'Ostffyasszonyfa',
    county: 'Vas',
  },
  {
    postal: 9513,
    city: 'Csönge',
    county: 'Vas',
  },
  {
    postal: 9514,
    city: 'Kenyeri',
    county: 'Vas',
  },
  {
    postal: 9515,
    city: 'Pápoc',
    county: 'Vas',
  },
  {
    postal: 9516,
    city: 'Vönöck',
    county: 'Vas',
  },
  {
    postal: 9517,
    city: 'Kemenessömjén',
    county: 'Vas',
  },
  {
    postal: 9521,
    city: 'Kemenesszentmárton',
    county: 'Vas',
  },
  {
    postal: 9522,
    city: 'Kemenesmagasi',
    county: 'Vas',
  },
  {
    postal: 9523,
    city: 'Szergény',
    county: 'Vas',
  },
  {
    postal: 9531,
    city: 'Mersevát',
    county: 'Vas',
  },
  {
    postal: 9541,
    city: 'Celldömölk',
    county: 'Vas',
  },
  {
    postal: 9542,
    city: 'Boba',
    county: 'Vas',
  },
  {
    postal: 9542,
    city: 'Nemeskocs',
    county: 'Vas',
  },
  {
    postal: 9544,
    city: 'Kemenespálfa',
    county: 'Vas',
  },
  {
    postal: 9545,
    city: 'Jánosháza',
    county: 'Vas',
  },
  {
    postal: 9547,
    city: 'Karakó',
    county: 'Vas',
  },
  {
    postal: 9548,
    city: 'Nemeskeresztúr',
    county: 'Vas',
  },
  {
    postal: 9549,
    city: 'Keléd',
    county: 'Vas',
  },
  {
    postal: 9551,
    city: 'Mesteri',
    county: 'Vas',
  },
  {
    postal: 9552,
    city: 'Vásárosmiske',
    county: 'Vas',
  },
  {
    postal: 9553,
    city: 'Kemeneskápolna',
    county: 'Vas',
  },
  {
    postal: 9553,
    city: 'Köcsk',
    county: 'Vas',
  },
  {
    postal: 9554,
    city: 'Egyházashetye',
    county: 'Vas',
  },
  {
    postal: 9554,
    city: 'Borgáta',
    county: 'Vas',
  },
  {
    postal: 9555,
    city: 'Kissomlyó',
    county: 'Vas',
  },
  {
    postal: 9556,
    city: 'Duka',
    county: 'Vas',
  },
  {
    postal: 9561,
    city: 'Tokorcs',
    county: 'Vas',
  },
  {
    postal: 9561,
    city: 'Nagysimonyi',
    county: 'Vas',
  },
  {
    postal: 9600,
    city: 'Sárvár',
    county: 'Vas',
  },
  {
    postal: 9608,
    city: 'Sárvár',
    county: 'Vas',
  },
  {
    postal: 9609,
    city: 'Sárvár',
    county: 'Vas',
  },
  {
    postal: 9611,
    city: 'Csénye',
    county: 'Vas',
  },
  {
    postal: 9612,
    city: 'Porpác',
    county: 'Vas',
  },
  {
    postal: 9612,
    city: 'Bögöt',
    county: 'Vas',
  },
  {
    postal: 9621,
    city: 'Ölbő',
    county: 'Vas',
  },
  {
    postal: 9622,
    city: 'Szeleste',
    county: 'Vas',
  },
  {
    postal: 9623,
    city: 'Répceszentgyörgy',
    county: 'Vas',
  },
  {
    postal: 9624,
    city: 'Chernelházadamonya',
    county: 'Vas',
  },
  {
    postal: 9625,
    city: 'Gór',
    county: 'Vas',
  },
  {
    postal: 9625,
    city: 'Bő',
    county: 'Vas',
  },
  {
    postal: 9631,
    city: 'Hegyfalu',
    county: 'Vas',
  },
  {
    postal: 9632,
    city: 'Sajtoskál',
    county: 'Vas',
  },
  {
    postal: 9633,
    city: 'Simaság',
    county: 'Vas',
  },
  {
    postal: 9634,
    city: 'Iklanberény',
    county: 'Vas',
  },
  {
    postal: 9634,
    city: 'Lócs',
    county: 'Vas',
  },
  {
    postal: 9635,
    city: 'Zsédeny',
    county: 'Vas',
  },
  {
    postal: 9636,
    city: 'Pósfa',
    county: 'Vas',
  },
  {
    postal: 9641,
    city: 'Rábapaty',
    county: 'Vas',
  },
  {
    postal: 9643,
    city: 'Jákfa',
    county: 'Vas',
  },
  {
    postal: 9651,
    city: 'Uraiújfalu',
    county: 'Vas',
  },
  {
    postal: 9652,
    city: 'Nick',
    county: 'Vas',
  },
  {
    postal: 9653,
    city: 'Répcelak',
    county: 'Vas',
  },
  {
    postal: 9654,
    city: 'Csánig',
    county: 'Vas',
  },
  {
    postal: 9661,
    city: 'Vasegerszeg',
    county: 'Vas',
  },
  {
    postal: 9662,
    city: 'Mesterháza',
    county: 'Vas',
  },
  {
    postal: 9662,
    city: 'Tompaládony',
    county: 'Vas',
  },
  {
    postal: 9663,
    city: 'Nemesládony',
    county: 'Vas',
  },
  {
    postal: 9664,
    city: 'Nagygeresd',
    county: 'Vas',
  },
  {
    postal: 9665,
    city: 'Vámoscsalád',
    county: 'Vas',
  },
  {
    postal: 9671,
    city: 'Sitke',
    county: 'Vas',
  },
  {
    postal: 9672,
    city: 'Gérce',
    county: 'Vas',
  },
  {
    postal: 9673,
    city: 'Káld',
    county: 'Vas',
  },
  {
    postal: 9674,
    city: 'Vashosszúfalu',
    county: 'Vas',
  },
  {
    postal: 9675,
    city: 'Bögöte',
    county: 'Vas',
  },
  {
    postal: 9676,
    city: 'Hosszúpereszteg',
    county: 'Vas',
  },
  {
    postal: 9681,
    city: 'Sótony',
    county: 'Vas',
  },
  {
    postal: 9682,
    city: 'Nyőgér',
    county: 'Vas',
  },
  {
    postal: 9683,
    city: 'Bejcgyertyános',
    county: 'Vas',
  },
  {
    postal: 9684,
    city: 'Egervölgy',
    county: 'Vas',
  },
  {
    postal: 9685,
    city: 'Szemenye',
    county: 'Vas',
  },
  {
    postal: 9700,
    city: 'Szombathely',
    county: 'Vas',
  },
  {
    postal: 9707,
    city: 'Szombathely',
    county: 'Vas',
  },
  {
    postal: 9721,
    city: 'Gencsapáti',
    county: 'Vas',
  },
  {
    postal: 9722,
    city: 'Perenye',
    county: 'Vas',
  },
  {
    postal: 9723,
    city: 'Gyöngyösfalu',
    county: 'Vas',
  },
  {
    postal: 9724,
    city: 'Lukácsháza',
    county: 'Vas',
  },
  {
    postal: 9725,
    city: 'Cák',
    county: 'Vas',
  },
  {
    postal: 9725,
    city: 'Kőszegszerdahely',
    county: 'Vas',
  },
  {
    postal: 9725,
    city: 'Kőszegdoroszló',
    county: 'Vas',
  },
  {
    postal: 9726,
    city: 'Velem',
    county: 'Vas',
  },
  {
    postal: 9727,
    city: 'Bozsok',
    county: 'Vas',
  },
  {
    postal: 9730,
    city: 'Kőszeg',
    county: 'Vas',
  },
  {
    postal: 9733,
    city: 'Kiszsidány',
    county: 'Vas',
  },
  {
    postal: 9733,
    city: 'Ólmod',
    county: 'Vas',
  },
  {
    postal: 9733,
    city: 'Horvátzsidány',
    county: 'Vas',
  },
  {
    postal: 9734,
    city: 'Peresznye',
    county: 'Vas',
  },
  {
    postal: 9735,
    city: 'Csepreg',
    county: 'Vas',
  },
  {
    postal: 9736,
    city: 'Tormásliget',
    county: 'Vas',
  },
  {
    postal: 9737,
    city: 'Bük',
    county: 'Vas',
  },
  {
    postal: 9738,
    city: 'Tömörd',
    county: 'Vas',
  },
  {
    postal: 9739,
    city: 'Pusztacsó',
    county: 'Vas',
  },
  {
    postal: 9739,
    city: 'Kőszegpaty',
    county: 'Vas',
  },
  {
    postal: 9739,
    city: 'Nemescsó',
    county: 'Vas',
  },
  {
    postal: 9740,
    city: 'Bük',
    county: 'Vas',
  },
  {
    postal: 9741,
    city: 'Vassurány',
    county: 'Vas',
  },
  {
    postal: 9742,
    city: 'Salköveskút',
    county: 'Vas',
  },
  {
    postal: 9743,
    city: 'Söpte',
    county: 'Vas',
  },
  {
    postal: 9744,
    city: 'Vasasszonyfa',
    county: 'Vas',
  },
  {
    postal: 9745,
    city: 'Meszlen',
    county: 'Vas',
  },
  {
    postal: 9746,
    city: 'Acsád',
    county: 'Vas',
  },
  {
    postal: 9747,
    city: 'Vasszilvágy',
    county: 'Vas',
  },
  {
    postal: 9748,
    city: 'Vát',
    county: 'Vas',
  },
  {
    postal: 9749,
    city: 'Nemesbőd',
    county: 'Vas',
  },
  {
    postal: 9751,
    city: 'Vép',
    county: 'Vas',
  },
  {
    postal: 9752,
    city: 'Bozzai',
    county: 'Vas',
  },
  {
    postal: 9752,
    city: 'Kenéz',
    county: 'Vas',
  },
  {
    postal: 9754,
    city: 'Megyehíd',
    county: 'Vas',
  },
  {
    postal: 9754,
    city: 'Pecöl',
    county: 'Vas',
  },
  {
    postal: 9756,
    city: 'Ikervár',
    county: 'Vas',
  },
  {
    postal: 9757,
    city: 'Meggyeskovácsi',
    county: 'Vas',
  },
  {
    postal: 9761,
    city: 'Táplánszentkereszt',
    county: 'Vas',
  },
  {
    postal: 9762,
    city: 'Tanakajd',
    county: 'Vas',
  },
  {
    postal: 9763,
    city: 'Vasszécseny',
    county: 'Vas',
  },
  {
    postal: 9764,
    city: 'Csempeszkopács',
    county: 'Vas',
  },
  {
    postal: 9764,
    city: 'Meggyeskovácsi',
    county: 'Vas',
  },
  {
    postal: 9766,
    city: 'Rábatöttös',
    county: 'Vas',
  },
  {
    postal: 9766,
    city: 'Zsennye',
    county: 'Vas',
  },
  {
    postal: 9766,
    city: 'Rum',
    county: 'Vas',
  },
  {
    postal: 9771,
    city: 'Balogunyom',
    county: 'Vas',
  },
  {
    postal: 9772,
    city: 'Kisunyom',
    county: 'Vas',
  },
  {
    postal: 9773,
    city: 'Sorokpolány',
    county: 'Vas',
  },
  {
    postal: 9774,
    city: 'Sorkifalud',
    county: 'Vas',
  },
  {
    postal: 9774,
    city: 'Gyanógeregye',
    county: 'Vas',
  },
  {
    postal: 9774,
    city: 'Sorkikápolna',
    county: 'Vas',
  },
  {
    postal: 9775,
    city: 'Nemeskolta',
    county: 'Vas',
  },
  {
    postal: 9776,
    city: 'Püspökmolnári',
    county: 'Vas',
  },
  {
    postal: 9777,
    city: 'Rábahídvég',
    county: 'Vas',
  },
  {
    postal: 9781,
    city: 'Egyházashollós',
    county: 'Vas',
  },
  {
    postal: 9782,
    city: 'Nemesrempehollós',
    county: 'Vas',
  },
  {
    postal: 9783,
    city: 'Egyházasrádóc',
    county: 'Vas',
  },
  {
    postal: 9784,
    city: 'Harasztifalu',
    county: 'Vas',
  },
  {
    postal: 9784,
    city: 'Rádóckölked',
    county: 'Vas',
  },
  {
    postal: 9784,
    city: 'Nagykölked',
    county: 'Vas',
  },
  {
    postal: 9789,
    city: 'Sé',
    county: 'Vas',
  },
  {
    postal: 9791,
    city: 'Dozmat',
    county: 'Vas',
  },
  {
    postal: 9791,
    city: 'Torony',
    county: 'Vas',
  },
  {
    postal: 9792,
    city: 'Bucsu',
    county: 'Vas',
  },
  {
    postal: 9793,
    city: 'Narda',
    county: 'Vas',
  },
  {
    postal: 9794,
    city: 'Felsőcsatár',
    county: 'Vas',
  },
  {
    postal: 9795,
    city: 'Vaskeresztes',
    county: 'Vas',
  },
  {
    postal: 9796,
    city: 'Pornóapáti',
    county: 'Vas',
  },
  {
    postal: 9796,
    city: 'Horvátlövő',
    county: 'Vas',
  },
  {
    postal: 9797,
    city: 'Nárai',
    county: 'Vas',
  },
  {
    postal: 9798,
    city: 'Ják',
    county: 'Vas',
  },
  {
    postal: 9799,
    city: 'Szentpéterfa',
    county: 'Vas',
  },
  {
    postal: 9800,
    city: 'Vasvár',
    county: 'Vas',
  },
  {
    postal: 9811,
    city: 'Andrásfa',
    county: 'Vas',
  },
  {
    postal: 9812,
    city: 'Telekes',
    county: 'Vas',
  },
  {
    postal: 9813,
    city: 'Gersekarát',
    county: 'Vas',
  },
  {
    postal: 9813,
    city: 'Sárfimizdó',
    county: 'Vas',
  },
  {
    postal: 9814,
    city: 'Halastó',
    county: 'Vas',
  },
  {
    postal: 9821,
    city: 'Hegyhátszentpéter',
    county: 'Vas',
  },
  {
    postal: 9821,
    city: 'Győrvár',
    county: 'Vas',
  },
  {
    postal: 9823,
    city: 'Pácsony',
    county: 'Vas',
  },
  {
    postal: 9824,
    city: 'Olaszfa',
    county: 'Vas',
  },
  {
    postal: 9825,
    city: 'Oszkó',
    county: 'Vas',
  },
  {
    postal: 9826,
    city: 'Petőmihályfa',
    county: 'Vas',
  },
  {
    postal: 9831,
    city: 'Bérbaltavár',
    county: 'Vas',
  },
  {
    postal: 9832,
    city: 'Nagytilaj',
    county: 'Vas',
  },
  {
    postal: 9833,
    city: 'Csehi',
    county: 'Vas',
  },
  {
    postal: 9834,
    city: 'Csehimindszent',
    county: 'Vas',
  },
  {
    postal: 9835,
    city: 'Mikosszéplak',
    county: 'Vas',
  },
  {
    postal: 9836,
    city: 'Csipkerek',
    county: 'Vas',
  },
  {
    postal: 9841,
    city: 'Kám',
    county: 'Vas',
  },
  {
    postal: 9842,
    city: 'Alsóújlak',
    county: 'Vas',
  },
  {
    postal: 9900,
    city: 'Körmend',
    county: 'Vas',
  },
  {
    postal: 9909,
    city: 'Magyarnádalja',
    county: 'Vas',
  },
  {
    postal: 9909,
    city: 'Körmend',
    county: 'Vas',
  },
  {
    postal: 9912,
    city: 'Magyarszecsőd',
    county: 'Vas',
  },
  {
    postal: 9912,
    city: 'Molnaszecsőd',
    county: 'Vas',
  },
  {
    postal: 9913,
    city: 'Szarvaskend',
    county: 'Vas',
  },
  {
    postal: 9913,
    city: 'Nagymizdó',
    county: 'Vas',
  },
  {
    postal: 9913,
    city: 'Döröske',
    county: 'Vas',
  },
  {
    postal: 9914,
    city: 'Döbörhegy',
    county: 'Vas',
  },
  {
    postal: 9915,
    city: 'Hegyhátsál',
    county: 'Vas',
  },
  {
    postal: 9915,
    city: 'Katafa',
    county: 'Vas',
  },
  {
    postal: 9915,
    city: 'Nádasd',
    county: 'Vas',
  },
  {
    postal: 9915,
    city: 'Hegyháthodász',
    county: 'Vas',
  },
  {
    postal: 9917,
    city: 'Daraboshegy',
    county: 'Vas',
  },
  {
    postal: 9917,
    city: 'Halogy',
    county: 'Vas',
  },
  {
    postal: 9918,
    city: 'Felsőmarác',
    county: 'Vas',
  },
  {
    postal: 9919,
    city: 'Csákánydoroszló',
    county: 'Vas',
  },
  {
    postal: 9921,
    city: 'Vasalja',
    county: 'Vas',
  },
  {
    postal: 9922,
    city: 'Pinkamindszent',
    county: 'Vas',
  },
  {
    postal: 9923,
    city: 'Kemestaródfa',
    county: 'Vas',
  },
  {
    postal: 9931,
    city: 'Hegyhátszentmárton',
    county: 'Vas',
  },
  {
    postal: 9931,
    city: 'Ivánc',
    county: 'Vas',
  },
  {
    postal: 9932,
    city: 'Viszák',
    county: 'Vas',
  },
  {
    postal: 9933,
    city: 'Őrimagyarósd',
    county: 'Vas',
  },
  {
    postal: 9934,
    city: 'Szaknyér',
    county: 'Vas',
  },
  {
    postal: 9934,
    city: 'Felsőjánosfa',
    county: 'Vas',
  },
  {
    postal: 9934,
    city: 'Hegyhátszentjakab',
    county: 'Vas',
  },
  {
    postal: 9935,
    city: 'Szőce',
    county: 'Vas',
  },
  {
    postal: 9936,
    city: 'Kisrákos',
    county: 'Vas',
  },
  {
    postal: 9937,
    city: 'Pankasz',
    county: 'Vas',
  },
  {
    postal: 9938,
    city: 'Szatta',
    county: 'Vas',
  },
  {
    postal: 9938,
    city: 'Nagyrákos',
    county: 'Vas',
  },
  {
    postal: 9941,
    city: 'Őriszentpéter',
    county: 'Vas',
  },
  {
    postal: 9941,
    city: 'Ispánk',
    county: 'Vas',
  },
  {
    postal: 9942,
    city: 'Szalafő',
    county: 'Vas',
  },
  {
    postal: 9943,
    city: 'Kondorfa',
    county: 'Vas',
  },
  {
    postal: 9944,
    city: 'Kerkáskápolna',
    county: 'Vas',
  },
  {
    postal: 9944,
    city: 'Bajánsenye',
    county: 'Vas',
  },
  {
    postal: 9945,
    city: 'Kercaszomor',
    county: 'Vas',
  },
  {
    postal: 9946,
    city: 'Velemér',
    county: 'Vas',
  },
  {
    postal: 9946,
    city: 'Magyarszombatfa',
    county: 'Vas',
  },
  {
    postal: 9951,
    city: 'Rátót',
    county: 'Vas',
  },
  {
    postal: 9952,
    city: 'Gasztony',
    county: 'Vas',
  },
  {
    postal: 9953,
    city: 'Nemesmedves',
    county: 'Vas',
  },
  {
    postal: 9953,
    city: 'Vasszentmihály',
    county: 'Vas',
  },
  {
    postal: 9954,
    city: 'Rönök',
    county: 'Vas',
  },
  {
    postal: 9955,
    city: 'Szentgotthárd',
    county: 'Vas',
  },
  {
    postal: 9961,
    city: 'Rábagyarmat',
    county: 'Vas',
  },
  {
    postal: 9962,
    city: 'Magyarlak',
    county: 'Vas',
  },
  {
    postal: 9962,
    city: 'Csörötnek',
    county: 'Vas',
  },
  {
    postal: 9970,
    city: 'Szentgotthárd',
    county: 'Vas',
  },
  {
    postal: 9981,
    city: 'Szentgotthárd',
    county: 'Vas',
  },
  {
    postal: 9982,
    city: 'Kétvölgy',
    county: 'Vas',
  },
  {
    postal: 9982,
    city: 'Orfalu',
    county: 'Vas',
  },
  {
    postal: 9982,
    city: 'Apátistvánfalva',
    county: 'Vas',
  },
  {
    postal: 9983,
    city: 'Szakonyfalu',
    county: 'Vas',
  },
  {
    postal: 9983,
    city: 'Alsószölnök',
    county: 'Vas',
  },
  {
    postal: 9985,
    city: 'Felsőszölnök',
    county: 'Vas',
  },
  {
    postal: 8100,
    city: 'Várpalota',
    county: 'Veszprém',
  },
  {
    postal: 8105,
    city: 'Pétfürdő',
    county: 'Veszprém',
  },
  {
    postal: 8109,
    city: 'Tés',
    county: 'Veszprém',
  },
  {
    postal: 8161,
    city: 'Ősi',
    county: 'Veszprém',
  },
  {
    postal: 8162,
    city: 'Küngös',
    county: 'Veszprém',
  },
  {
    postal: 8163,
    city: 'Csajág',
    county: 'Veszprém',
  },
  {
    postal: 8164,
    city: 'Balatonfőkajár',
    county: 'Veszprém',
  },
  {
    postal: 8171,
    city: 'Balatonvilágos',
    county: 'Veszprém',
  },
  {
    postal: 8172,
    city: 'Balatonakarattya',
    county: 'Veszprém',
  },
  {
    postal: 8174,
    city: 'Balatonkenese',
    county: 'Veszprém',
  },
  {
    postal: 8175,
    city: 'Balatonfűzfő',
    county: 'Veszprém',
  },
  {
    postal: 8181,
    city: 'Berhida',
    county: 'Veszprém',
  },
  {
    postal: 8182,
    city: 'Berhida',
    county: 'Veszprém',
  },
  {
    postal: 8183,
    city: 'Papkeszi',
    county: 'Veszprém',
  },
  {
    postal: 8184,
    city: 'Balatonfűzfő',
    county: 'Veszprém',
  },
  {
    postal: 8191,
    city: 'Öskü',
    county: 'Veszprém',
  },
  {
    postal: 8192,
    city: 'Hajmáskér',
    county: 'Veszprém',
  },
  {
    postal: 8193,
    city: 'Sóly',
    county: 'Veszprém',
  },
  {
    postal: 8194,
    city: 'Vilonya',
    county: 'Veszprém',
  },
  {
    postal: 8195,
    city: 'Királyszentistván',
    county: 'Veszprém',
  },
  {
    postal: 8196,
    city: 'Litér',
    county: 'Veszprém',
  },
  {
    postal: 8200,
    city: 'Veszprém',
    county: 'Veszprém',
  },
  {
    postal: 8220,
    city: 'Balatonalmádi',
    county: 'Veszprém',
  },
  {
    postal: 8225,
    city: 'Szentkirályszabadja',
    county: 'Veszprém',
  },
  {
    postal: 8226,
    city: 'Alsóörs',
    county: 'Veszprém',
  },
  {
    postal: 8227,
    city: 'Felsőörs',
    county: 'Veszprém',
  },
  {
    postal: 8228,
    city: 'Lovas',
    county: 'Veszprém',
  },
  {
    postal: 8229,
    city: 'Paloznak',
    county: 'Veszprém',
  },
  {
    postal: 8229,
    city: 'Csopak',
    county: 'Veszprém',
  },
  {
    postal: 8230,
    city: 'Balatonfüred',
    county: 'Veszprém',
  },
  {
    postal: 8233,
    city: 'Balatonszőlős',
    county: 'Veszprém',
  },
  {
    postal: 8237,
    city: 'Tihany',
    county: 'Veszprém',
  },
  {
    postal: 8241,
    city: 'Aszófő',
    county: 'Veszprém',
  },
  {
    postal: 8242,
    city: 'Balatonudvari',
    county: 'Veszprém',
  },
  {
    postal: 8242,
    city: 'Örvényes',
    county: 'Veszprém',
  },
  {
    postal: 8243,
    city: 'Balatonakali',
    county: 'Veszprém',
  },
  {
    postal: 8244,
    city: 'Dörgicse',
    county: 'Veszprém',
  },
  {
    postal: 8245,
    city: 'Vászoly',
    county: 'Veszprém',
  },
  {
    postal: 8245,
    city: 'Pécsely',
    county: 'Veszprém',
  },
  {
    postal: 8246,
    city: 'Tótvázsony',
    county: 'Veszprém',
  },
  {
    postal: 8247,
    city: 'Hidegkút',
    county: 'Veszprém',
  },
  {
    postal: 8248,
    city: 'Nemesvámos',
    county: 'Veszprém',
  },
  {
    postal: 8248,
    city: 'Veszprémfajsz',
    county: 'Veszprém',
  },
  {
    postal: 8251,
    city: 'Zánka',
    county: 'Veszprém',
  },
  {
    postal: 8252,
    city: 'Balatonszepezd',
    county: 'Veszprém',
  },
  {
    postal: 8253,
    city: 'Révfülöp',
    county: 'Veszprém',
  },
  {
    postal: 8254,
    city: 'Kékkút',
    county: 'Veszprém',
  },
  {
    postal: 8254,
    city: 'Kővágóörs',
    county: 'Veszprém',
  },
  {
    postal: 8255,
    city: 'Balatonrendes',
    county: 'Veszprém',
  },
  {
    postal: 8255,
    city: 'Kővágóörs',
    county: 'Veszprém',
  },
  {
    postal: 8256,
    city: 'Ábrahámhegy',
    county: 'Veszprém',
  },
  {
    postal: 8256,
    city: 'Salföld',
    county: 'Veszprém',
  },
  {
    postal: 8257,
    city: 'Badacsonytomaj',
    county: 'Veszprém',
  },
  {
    postal: 8258,
    city: 'Badacsonytomaj',
    county: 'Veszprém',
  },
  {
    postal: 8261,
    city: 'Badacsonytomaj',
    county: 'Veszprém',
  },
  {
    postal: 8263,
    city: 'Badacsonytördemic',
    county: 'Veszprém',
  },
  {
    postal: 8264,
    city: 'Szigliget',
    county: 'Veszprém',
  },
  {
    postal: 8265,
    city: 'Hegymagas',
    county: 'Veszprém',
  },
  {
    postal: 8271,
    city: 'Mencshely',
    county: 'Veszprém',
  },
  {
    postal: 8272,
    city: 'Szentjakabfa',
    county: 'Veszprém',
  },
  {
    postal: 8272,
    city: 'Tagyon',
    county: 'Veszprém',
  },
  {
    postal: 8272,
    city: 'Óbudavár',
    county: 'Veszprém',
  },
  {
    postal: 8272,
    city: 'Szentantalfa',
    county: 'Veszprém',
  },
  {
    postal: 8272,
    city: 'Balatoncsicsó',
    county: 'Veszprém',
  },
  {
    postal: 8273,
    city: 'Monoszló',
    county: 'Veszprém',
  },
  {
    postal: 8274,
    city: 'Köveskál',
    county: 'Veszprém',
  },
  {
    postal: 8275,
    city: 'Balatonhenye',
    county: 'Veszprém',
  },
  {
    postal: 8281,
    city: 'Szentbékkálla',
    county: 'Veszprém',
  },
  {
    postal: 8282,
    city: 'Mindszentkálla',
    county: 'Veszprém',
  },
  {
    postal: 8283,
    city: 'Káptalantóti',
    county: 'Veszprém',
  },
  {
    postal: 8284,
    city: 'Kisapáti',
    county: 'Veszprém',
  },
  {
    postal: 8284,
    city: 'Nemesgulács',
    county: 'Veszprém',
  },
  {
    postal: 8286,
    city: 'Gyulakeszi',
    county: 'Veszprém',
  },
  {
    postal: 8291,
    city: 'Barnag',
    county: 'Veszprém',
  },
  {
    postal: 8291,
    city: 'Nagyvázsony',
    county: 'Veszprém',
  },
  {
    postal: 8291,
    city: 'Vöröstó',
    county: 'Veszprém',
  },
  {
    postal: 8291,
    city: 'Pula',
    county: 'Veszprém',
  },
  {
    postal: 8292,
    city: 'Öcs',
    county: 'Veszprém',
  },
  {
    postal: 8294,
    city: 'Kapolcs',
    county: 'Veszprém',
  },
  {
    postal: 8294,
    city: 'Vigántpetend',
    county: 'Veszprém',
  },
  {
    postal: 8295,
    city: 'Taliándörögd',
    county: 'Veszprém',
  },
  {
    postal: 8296,
    city: 'Monostorapáti',
    county: 'Veszprém',
  },
  {
    postal: 8296,
    city: 'Hegyesd',
    county: 'Veszprém',
  },
  {
    postal: 8297,
    city: 'Tapolca',
    county: 'Veszprém',
  },
  {
    postal: 8300,
    city: 'Raposka',
    county: 'Veszprém',
  },
  {
    postal: 8300,
    city: 'Tapolca',
    county: 'Veszprém',
  },
  {
    postal: 8308,
    city: 'Zalahaláp',
    county: 'Veszprém',
  },
  {
    postal: 8308,
    city: 'Sáska',
    county: 'Veszprém',
  },
  {
    postal: 8311,
    city: 'Nemesvita',
    county: 'Veszprém',
  },
  {
    postal: 8312,
    city: 'Balatonederics',
    county: 'Veszprém',
  },
  {
    postal: 8317,
    city: 'Lesencefalu',
    county: 'Veszprém',
  },
  {
    postal: 8318,
    city: 'Lesencetomaj',
    county: 'Veszprém',
  },
  {
    postal: 8319,
    city: 'Lesenceistvánd',
    county: 'Veszprém',
  },
  {
    postal: 8321,
    city: 'Uzsa',
    county: 'Veszprém',
  },
  {
    postal: 8330,
    city: 'Sümeg',
    county: 'Veszprém',
  },
  {
    postal: 8344,
    city: 'Hetyefő',
    county: 'Veszprém',
  },
  {
    postal: 8344,
    city: 'Zalaerdőd',
    county: 'Veszprém',
  },
  {
    postal: 8345,
    city: 'Dabronc',
    county: 'Veszprém',
  },
  {
    postal: 8346,
    city: 'Gógánfa',
    county: 'Veszprém',
  },
  {
    postal: 8347,
    city: 'Ukk',
    county: 'Veszprém',
  },
  {
    postal: 8348,
    city: 'Zalameggyes',
    county: 'Veszprém',
  },
  {
    postal: 8348,
    city: 'Rigács',
    county: 'Veszprém',
  },
  {
    postal: 8348,
    city: 'Megyer',
    county: 'Veszprém',
  },
  {
    postal: 8349,
    city: 'Zalagyömörő',
    county: 'Veszprém',
  },
  {
    postal: 8351,
    city: 'Sümegprága',
    county: 'Veszprém',
  },
  {
    postal: 8352,
    city: 'Bazsi',
    county: 'Veszprém',
  },
  {
    postal: 8400,
    city: 'Ajka',
    county: 'Veszprém',
  },
  {
    postal: 8409,
    city: 'Úrkút',
    county: 'Veszprém',
  },
  {
    postal: 8411,
    city: 'Veszprém',
    county: 'Veszprém',
  },
  {
    postal: 8412,
    city: 'Veszprém',
    county: 'Veszprém',
  },
  {
    postal: 8413,
    city: 'Eplény',
    county: 'Veszprém',
  },
  {
    postal: 8414,
    city: 'Olaszfalu',
    county: 'Veszprém',
  },
  {
    postal: 8415,
    city: 'Nagyesztergár',
    county: 'Veszprém',
  },
  {
    postal: 8416,
    city: 'Dudar',
    county: 'Veszprém',
  },
  {
    postal: 8417,
    city: 'Csetény',
    county: 'Veszprém',
  },
  {
    postal: 8418,
    city: 'Bakonyoszlop',
    county: 'Veszprém',
  },
  {
    postal: 8419,
    city: 'Csesznek',
    county: 'Veszprém',
  },
  {
    postal: 8420,
    city: 'Zirc',
    county: 'Veszprém',
  },
  {
    postal: 8422,
    city: 'Bakonynána',
    county: 'Veszprém',
  },
  {
    postal: 8423,
    city: 'Szápár',
    county: 'Veszprém',
  },
  {
    postal: 8424,
    city: 'Jásd',
    county: 'Veszprém',
  },
  {
    postal: 8425,
    city: 'Lókút',
    county: 'Veszprém',
  },
  {
    postal: 8426,
    city: 'Pénzesgyőr',
    county: 'Veszprém',
  },
  {
    postal: 8427,
    city: 'Bakonybél',
    county: 'Veszprém',
  },
  {
    postal: 8428,
    city: 'Borzavár',
    county: 'Veszprém',
  },
  {
    postal: 8429,
    city: 'Porva',
    county: 'Veszprém',
  },
  {
    postal: 8430,
    city: 'Bakonyszentkirály',
    county: 'Veszprém',
  },
  {
    postal: 8431,
    city: 'Bakonyszentlászló',
    county: 'Veszprém',
  },
  {
    postal: 8432,
    city: 'Fenyőfő',
    county: 'Veszprém',
  },
  {
    postal: 8433,
    city: 'Bakonygyirót',
    county: 'Veszprém',
  },
  {
    postal: 8434,
    city: 'Románd',
    county: 'Veszprém',
  },
  {
    postal: 8435,
    city: 'Gic',
    county: 'Veszprém',
  },
  {
    postal: 8438,
    city: 'Veszprémvarsány',
    county: 'Veszprém',
  },
  {
    postal: 8439,
    city: 'Sikátor',
    county: 'Veszprém',
  },
  {
    postal: 8440,
    city: 'Herend',
    county: 'Veszprém',
  },
  {
    postal: 8441,
    city: 'Márkó',
    county: 'Veszprém',
  },
  {
    postal: 8442,
    city: 'Hárskút',
    county: 'Veszprém',
  },
  {
    postal: 8443,
    city: 'Bánd',
    county: 'Veszprém',
  },
  {
    postal: 8444,
    city: 'Szentgál',
    county: 'Veszprém',
  },
  {
    postal: 8445,
    city: 'Városlőd',
    county: 'Veszprém',
  },
  {
    postal: 8445,
    city: 'Csehbánya',
    county: 'Veszprém',
  },
  {
    postal: 8446,
    city: 'Kislőd',
    county: 'Veszprém',
  },
  {
    postal: 8447,
    city: 'Ajka',
    county: 'Veszprém',
  },
  {
    postal: 8448,
    city: 'Ajka',
    county: 'Veszprém',
  },
  {
    postal: 8449,
    city: 'Magyarpolány',
    county: 'Veszprém',
  },
  {
    postal: 8451,
    city: 'Ajka',
    county: 'Veszprém',
  },
  {
    postal: 8452,
    city: 'Szőc',
    county: 'Veszprém',
  },
  {
    postal: 8452,
    city: 'Halimba',
    county: 'Veszprém',
  },
  {
    postal: 8454,
    city: 'Nyirád',
    county: 'Veszprém',
  },
  {
    postal: 8455,
    city: 'Pusztamiske',
    county: 'Veszprém',
  },
  {
    postal: 8456,
    city: 'Noszlop',
    county: 'Veszprém',
  },
  {
    postal: 8457,
    city: 'Bakonypölöske',
    county: 'Veszprém',
  },
  {
    postal: 8458,
    city: 'Oroszi',
    county: 'Veszprém',
  },
  {
    postal: 8460,
    city: 'Devecser',
    county: 'Veszprém',
  },
  {
    postal: 8468,
    city: 'Kolontár',
    county: 'Veszprém',
  },
  {
    postal: 8469,
    city: 'Kamond',
    county: 'Veszprém',
  },
  {
    postal: 8471,
    city: 'Nemeshany',
    county: 'Veszprém',
  },
  {
    postal: 8471,
    city: 'Káptalanfa',
    county: 'Veszprém',
  },
  {
    postal: 8471,
    city: 'Bodorfa',
    county: 'Veszprém',
  },
  {
    postal: 8473,
    city: 'Gyepükaján',
    county: 'Veszprém',
  },
  {
    postal: 8474,
    city: 'Csabrendek',
    county: 'Veszprém',
  },
  {
    postal: 8475,
    city: 'Hosztót',
    county: 'Veszprém',
  },
  {
    postal: 8475,
    city: 'Veszprémgalsa',
    county: 'Veszprém',
  },
  {
    postal: 8475,
    city: 'Szentimrefalva',
    county: 'Veszprém',
  },
  {
    postal: 8476,
    city: 'Zalaszegvár',
    county: 'Veszprém',
  },
  {
    postal: 8477,
    city: 'Kisberzseny',
    county: 'Veszprém',
  },
  {
    postal: 8477,
    city: 'Tüskevár',
    county: 'Veszprém',
  },
  {
    postal: 8477,
    city: 'Apácatorna',
    county: 'Veszprém',
  },
  {
    postal: 8478,
    city: 'Somlójenő',
    county: 'Veszprém',
  },
  {
    postal: 8479,
    city: 'Borszörcsök',
    county: 'Veszprém',
  },
  {
    postal: 8481,
    city: 'Somlóvásárhely',
    county: 'Veszprém',
  },
  {
    postal: 8482,
    city: 'Doba',
    county: 'Veszprém',
  },
  {
    postal: 8483,
    city: 'Somlószőlős',
    county: 'Veszprém',
  },
  {
    postal: 8483,
    city: 'Kisszőlős',
    county: 'Veszprém',
  },
  {
    postal: 8484,
    city: 'Vid',
    county: 'Veszprém',
  },
  {
    postal: 8484,
    city: 'Nagyalásony',
    county: 'Veszprém',
  },
  {
    postal: 8484,
    city: 'Somlóvecse',
    county: 'Veszprém',
  },
  {
    postal: 8485,
    city: 'Dabrony',
    county: 'Veszprém',
  },
  {
    postal: 8491,
    city: 'Karakószörcsök',
    county: 'Veszprém',
  },
  {
    postal: 8492,
    city: 'Kerta',
    county: 'Veszprém',
  },
  {
    postal: 8493,
    city: 'Iszkáz',
    county: 'Veszprém',
  },
  {
    postal: 8494,
    city: 'Kiscsősz',
    county: 'Veszprém',
  },
  {
    postal: 8495,
    city: 'Csögle',
    county: 'Veszprém',
  },
  {
    postal: 8496,
    city: 'Kispirit',
    county: 'Veszprém',
  },
  {
    postal: 8496,
    city: 'Nagypirit',
    county: 'Veszprém',
  },
  {
    postal: 8497,
    city: 'Adorjánháza',
    county: 'Veszprém',
  },
  {
    postal: 8497,
    city: 'Egeralja',
    county: 'Veszprém',
  },
  {
    postal: 8500,
    city: 'Pápa',
    county: 'Veszprém',
  },
  {
    postal: 8511,
    city: 'Pápa',
    county: 'Veszprém',
  },
  {
    postal: 8512,
    city: 'Nyárád',
    county: 'Veszprém',
  },
  {
    postal: 8513,
    city: 'Mihályháza',
    county: 'Veszprém',
  },
  {
    postal: 8514,
    city: 'Mezőlak',
    county: 'Veszprém',
  },
  {
    postal: 8515,
    city: 'Békás',
    county: 'Veszprém',
  },
  {
    postal: 8516,
    city: 'Kemeneshőgyész',
    county: 'Veszprém',
  },
  {
    postal: 8517,
    city: 'Magyargencs',
    county: 'Veszprém',
  },
  {
    postal: 8518,
    city: 'Kemenesszentpéter',
    county: 'Veszprém',
  },
  {
    postal: 8521,
    city: 'Nagyacsád',
    county: 'Veszprém',
  },
  {
    postal: 8522,
    city: 'Nemesgörzsöny',
    county: 'Veszprém',
  },
  {
    postal: 8523,
    city: 'Várkesző',
    county: 'Veszprém',
  },
  {
    postal: 8523,
    city: 'Egyházaskesző',
    county: 'Veszprém',
  },
  {
    postal: 8531,
    city: 'Marcaltő',
    county: 'Veszprém',
  },
  {
    postal: 8532,
    city: 'Marcaltő',
    county: 'Veszprém',
  },
  {
    postal: 8533,
    city: 'Malomsok',
    county: 'Veszprém',
  },
  {
    postal: 8541,
    city: 'Takácsi',
    county: 'Veszprém',
  },
  {
    postal: 8542,
    city: 'Vaszar',
    county: 'Veszprém',
  },
  {
    postal: 8543,
    city: 'Gecse',
    county: 'Veszprém',
  },
  {
    postal: 8551,
    city: 'Nagygyimót',
    county: 'Veszprém',
  },
  {
    postal: 8552,
    city: 'Vanyola',
    county: 'Veszprém',
  },
  {
    postal: 8553,
    city: 'Lovászpatona',
    county: 'Veszprém',
  },
  {
    postal: 8554,
    city: 'Nagydém',
    county: 'Veszprém',
  },
  {
    postal: 8555,
    city: 'Bakonytamási',
    county: 'Veszprém',
  },
  {
    postal: 8556,
    city: 'Pápateszér',
    county: 'Veszprém',
  },
  {
    postal: 8557,
    city: 'Bakonyság',
    county: 'Veszprém',
  },
  {
    postal: 8557,
    city: 'Bakonyszentiván',
    county: 'Veszprém',
  },
  {
    postal: 8558,
    city: 'Csót',
    county: 'Veszprém',
  },
  {
    postal: 8561,
    city: 'Adásztevel',
    county: 'Veszprém',
  },
  {
    postal: 8562,
    city: 'Nagytevel',
    county: 'Veszprém',
  },
  {
    postal: 8563,
    city: 'Homokbödöge',
    county: 'Veszprém',
  },
  {
    postal: 8564,
    city: 'Ugod',
    county: 'Veszprém',
  },
  {
    postal: 8565,
    city: 'Béb',
    county: 'Veszprém',
  },
  {
    postal: 8571,
    city: 'Bakonykoppány',
    county: 'Veszprém',
  },
  {
    postal: 8572,
    city: 'Bakonyszücs',
    county: 'Veszprém',
  },
  {
    postal: 8581,
    city: 'Bakonyjákó',
    county: 'Veszprém',
  },
  {
    postal: 8581,
    city: 'Németbánya',
    county: 'Veszprém',
  },
  {
    postal: 8582,
    city: 'Farkasgyepű',
    county: 'Veszprém',
  },
  {
    postal: 8591,
    city: 'Pápa',
    county: 'Veszprém',
  },
  {
    postal: 8591,
    city: 'Nóráp',
    county: 'Veszprém',
  },
  {
    postal: 8592,
    city: 'Dáka',
    county: 'Veszprém',
  },
  {
    postal: 8593,
    city: 'Pápadereske',
    county: 'Veszprém',
  },
  {
    postal: 8594,
    city: 'Pápasalamon',
    county: 'Veszprém',
  },
  {
    postal: 8595,
    city: 'Kup',
    county: 'Veszprém',
  },
  {
    postal: 8596,
    city: 'Pápakovácsi',
    county: 'Veszprém',
  },
  {
    postal: 8597,
    city: 'Döbrönte',
    county: 'Veszprém',
  },
  {
    postal: 8597,
    city: 'Ganna',
    county: 'Veszprém',
  },
  {
    postal: 8598,
    city: 'Pápa',
    county: 'Veszprém',
  },
  {
    postal: 9532,
    city: 'Külsővat',
    county: 'Veszprém',
  },
  {
    postal: 9533,
    city: 'Nemesszalók',
    county: 'Veszprém',
  },
  {
    postal: 9534,
    city: 'Marcalgergelyi',
    county: 'Veszprém',
  },
  {
    postal: 9534,
    city: 'Vinár',
    county: 'Veszprém',
  },
  {
    postal: 8313,
    city: 'Balatongyörök',
    county: 'Zala',
  },
  {
    postal: 8314,
    city: 'Vonyarcvashegy',
    county: 'Zala',
  },
  {
    postal: 8315,
    city: 'Gyenesdiás',
    county: 'Zala',
  },
  {
    postal: 8316,
    city: 'Várvölgy',
    county: 'Zala',
  },
  {
    postal: 8316,
    city: 'Vállus',
    county: 'Zala',
  },
  {
    postal: 8341,
    city: 'Kisvásárhely',
    county: 'Zala',
  },
  {
    postal: 8341,
    city: 'Mihályfa',
    county: 'Zala',
  },
  {
    postal: 8341,
    city: 'Szalapa',
    county: 'Zala',
  },
  {
    postal: 8342,
    city: 'Óhíd',
    county: 'Zala',
  },
  {
    postal: 8353,
    city: 'Vindornyalak',
    county: 'Zala',
  },
  {
    postal: 8353,
    city: 'Zalaszántó',
    county: 'Zala',
  },
  {
    postal: 8354,
    city: 'Vindornyafok',
    county: 'Zala',
  },
  {
    postal: 8354,
    city: 'Zalaköveskút',
    county: 'Zala',
  },
  {
    postal: 8354,
    city: 'Karmacs',
    county: 'Zala',
  },
  {
    postal: 8355,
    city: 'Vindornyaszőlős',
    county: 'Zala',
  },
  {
    postal: 8356,
    city: 'Kisgörbő',
    county: 'Zala',
  },
  {
    postal: 8356,
    city: 'Nagygörbő',
    county: 'Zala',
  },
  {
    postal: 8357,
    city: 'Döbröce',
    county: 'Zala',
  },
  {
    postal: 8357,
    city: 'Sümegcsehi',
    county: 'Zala',
  },
  {
    postal: 8360,
    city: 'Keszthely',
    county: 'Zala',
  },
  {
    postal: 8371,
    city: 'Nemesbük',
    county: 'Zala',
  },
  {
    postal: 8372,
    city: 'Cserszegtomaj',
    county: 'Zala',
  },
  {
    postal: 8373,
    city: 'Rezi',
    county: 'Zala',
  },
  {
    postal: 8380,
    city: 'Hévíz',
    county: 'Zala',
  },
  {
    postal: 8391,
    city: 'Sármellék',
    county: 'Zala',
  },
  {
    postal: 8392,
    city: 'Zalavár',
    county: 'Zala',
  },
  {
    postal: 8393,
    city: 'Szentgyörgyvár',
    county: 'Zala',
  },
  {
    postal: 8394,
    city: 'Alsópáhok',
    county: 'Zala',
  },
  {
    postal: 8395,
    city: 'Felsőpáhok',
    county: 'Zala',
  },
  {
    postal: 8741,
    city: 'Bókaháza',
    county: 'Zala',
  },
  {
    postal: 8741,
    city: 'Zalaapáti',
    county: 'Zala',
  },
  {
    postal: 8742,
    city: 'Esztergályhorváti',
    county: 'Zala',
  },
  {
    postal: 8743,
    city: 'Zalaszabar',
    county: 'Zala',
  },
  {
    postal: 8744,
    city: 'Orosztony',
    county: 'Zala',
  },
  {
    postal: 8745,
    city: 'Kerecseny',
    county: 'Zala',
  },
  {
    postal: 8746,
    city: 'Nagyrada',
    county: 'Zala',
  },
  {
    postal: 8747,
    city: 'Garabonc',
    county: 'Zala',
  },
  {
    postal: 8747,
    city: 'Zalamerenye',
    county: 'Zala',
  },
  {
    postal: 8749,
    city: 'Zalakaros',
    county: 'Zala',
  },
  {
    postal: 8751,
    city: 'Zalakomár',
    county: 'Zala',
  },
  {
    postal: 8752,
    city: 'Zalakomár',
    county: 'Zala',
  },
  {
    postal: 8753,
    city: 'Balatonmagyaród',
    county: 'Zala',
  },
  {
    postal: 8754,
    city: 'Galambok',
    county: 'Zala',
  },
  {
    postal: 8756,
    city: 'Nagyrécse',
    county: 'Zala',
  },
  {
    postal: 8756,
    city: 'Csapi',
    county: 'Zala',
  },
  {
    postal: 8756,
    city: 'Zalasárszeg',
    county: 'Zala',
  },
  {
    postal: 8756,
    city: 'Kisrécse',
    county: 'Zala',
  },
  {
    postal: 8761,
    city: 'Pacsa',
    county: 'Zala',
  },
  {
    postal: 8761,
    city: 'Zalaigrice',
    county: 'Zala',
  },
  {
    postal: 8762,
    city: 'Szentpéterúr',
    county: 'Zala',
  },
  {
    postal: 8762,
    city: 'Gétye',
    county: 'Zala',
  },
  {
    postal: 8764,
    city: 'Zalaszentmárton',
    county: 'Zala',
  },
  {
    postal: 8764,
    city: 'Dióskál',
    county: 'Zala',
  },
  {
    postal: 8765,
    city: 'Egeraracsa',
    county: 'Zala',
  },
  {
    postal: 8767,
    city: 'Pötréte',
    county: 'Zala',
  },
  {
    postal: 8767,
    city: 'Felsőrajk',
    county: 'Zala',
  },
  {
    postal: 8767,
    city: 'Alsórajk',
    county: 'Zala',
  },
  {
    postal: 8771,
    city: 'Hahót',
    county: 'Zala',
  },
  {
    postal: 8772,
    city: 'Zalaszentbalázs',
    county: 'Zala',
  },
  {
    postal: 8772,
    city: 'Börzönce',
    county: 'Zala',
  },
  {
    postal: 8773,
    city: 'Pölöskefő',
    county: 'Zala',
  },
  {
    postal: 8773,
    city: 'Kacorlak',
    county: 'Zala',
  },
  {
    postal: 8774,
    city: 'Gelse',
    county: 'Zala',
  },
  {
    postal: 8774,
    city: 'Kilimán',
    county: 'Zala',
  },
  {
    postal: 8774,
    city: 'Gelsesziget',
    county: 'Zala',
  },
  {
    postal: 8776,
    city: 'Magyarszerdahely',
    county: 'Zala',
  },
  {
    postal: 8776,
    city: 'Magyarszentmiklós',
    county: 'Zala',
  },
  {
    postal: 8776,
    city: 'Bocska',
    county: 'Zala',
  },
  {
    postal: 8777,
    city: 'Fűzvölgy',
    county: 'Zala',
  },
  {
    postal: 8777,
    city: 'Hosszúvölgy',
    county: 'Zala',
  },
  {
    postal: 8777,
    city: 'Homokkomárom',
    county: 'Zala',
  },
  {
    postal: 8778,
    city: 'Újudvar',
    county: 'Zala',
  },
  {
    postal: 8782,
    city: 'Tilaj',
    county: 'Zala',
  },
  {
    postal: 8782,
    city: 'Zalacsány',
    county: 'Zala',
  },
  {
    postal: 8782,
    city: 'Ligetfalva',
    county: 'Zala',
  },
  {
    postal: 8784,
    city: 'Kehidakustány',
    county: 'Zala',
  },
  {
    postal: 8785,
    city: 'Zalaszentgrót',
    county: 'Zala',
  },
  {
    postal: 8785,
    city: 'Kallósd',
    county: 'Zala',
  },
  {
    postal: 8788,
    city: 'Zalaszentlászló',
    county: 'Zala',
  },
  {
    postal: 8788,
    city: 'Sénye',
    county: 'Zala',
  },
  {
    postal: 8789,
    city: 'Zalaszentgrót',
    county: 'Zala',
  },
  {
    postal: 8790,
    city: 'Zalaszentgrót',
    county: 'Zala',
  },
  {
    postal: 8792,
    city: 'Zalavég',
    county: 'Zala',
  },
  {
    postal: 8793,
    city: 'Tekenye',
    county: 'Zala',
  },
  {
    postal: 8795,
    city: 'Zalaszentgrót',
    county: 'Zala',
  },
  {
    postal: 8796,
    city: 'Türje',
    county: 'Zala',
  },
  {
    postal: 8797,
    city: 'Batyk',
    county: 'Zala',
  },
  {
    postal: 8798,
    city: 'Zalabér',
    county: 'Zala',
  },
  {
    postal: 8799,
    city: 'Dötk',
    county: 'Zala',
  },
  {
    postal: 8799,
    city: 'Pakod',
    county: 'Zala',
  },
  {
    postal: 8800,
    city: 'Nagykanizsa',
    county: 'Zala',
  },
  {
    postal: 8808,
    city: 'Nagykanizsa',
    county: 'Zala',
  },
  {
    postal: 8809,
    city: 'Nagykanizsa',
    county: 'Zala',
  },
  {
    postal: 8821,
    city: 'Nagybakónak',
    county: 'Zala',
  },
  {
    postal: 8822,
    city: 'Zalaújlak',
    county: 'Zala',
  },
  {
    postal: 8824,
    city: 'Sand',
    county: 'Zala',
  },
  {
    postal: 8825,
    city: 'Pat',
    county: 'Zala',
  },
  {
    postal: 8825,
    city: 'Miháld',
    county: 'Zala',
  },
  {
    postal: 8827,
    city: 'Zalaszentjakab',
    county: 'Zala',
  },
  {
    postal: 8831,
    city: 'Nagykanizsa',
    county: 'Zala',
  },
  {
    postal: 8832,
    city: 'Liszó',
    county: 'Zala',
  },
  {
    postal: 8834,
    city: 'Murakeresztúr',
    county: 'Zala',
  },
  {
    postal: 8835,
    city: 'Fityeház',
    county: 'Zala',
  },
  {
    postal: 8855,
    city: 'Belezna',
    county: 'Zala',
  },
  {
    postal: 8856,
    city: 'Surd',
    county: 'Zala',
  },
  {
    postal: 8857,
    city: 'Nemespátró',
    county: 'Zala',
  },
  {
    postal: 8861,
    city: 'Szepetnek',
    county: 'Zala',
  },
  {
    postal: 8862,
    city: 'Semjénháza',
    county: 'Zala',
  },
  {
    postal: 8863,
    city: 'Molnári',
    county: 'Zala',
  },
  {
    postal: 8864,
    city: 'Tótszerdahely',
    county: 'Zala',
  },
  {
    postal: 8865,
    city: 'Tótszentmárton',
    county: 'Zala',
  },
  {
    postal: 8866,
    city: 'Becsehely',
    county: 'Zala',
  },
  {
    postal: 8866,
    city: 'Petrivente',
    county: 'Zala',
  },
  {
    postal: 8868,
    city: 'Letenye',
    county: 'Zala',
  },
  {
    postal: 8868,
    city: 'Kistolmács',
    county: 'Zala',
  },
  {
    postal: 8868,
    city: 'Murarátka',
    county: 'Zala',
  },
  {
    postal: 8868,
    city: 'Zajk',
    county: 'Zala',
  },
  {
    postal: 8872,
    city: 'Szentmargitfalva',
    county: 'Zala',
  },
  {
    postal: 8872,
    city: 'Muraszemenye',
    county: 'Zala',
  },
  {
    postal: 8873,
    city: 'Csörnyeföld',
    county: 'Zala',
  },
  {
    postal: 8874,
    city: 'Kerkaszentkirály',
    county: 'Zala',
  },
  {
    postal: 8874,
    city: 'Dobri',
    county: 'Zala',
  },
  {
    postal: 8876,
    city: 'Tormafölde',
    county: 'Zala',
  },
  {
    postal: 8877,
    city: 'Tornyiszentmiklós',
    county: 'Zala',
  },
  {
    postal: 8878,
    city: 'Lovászi',
    county: 'Zala',
  },
  {
    postal: 8879,
    city: 'Szécsisziget',
    county: 'Zala',
  },
  {
    postal: 8879,
    city: 'Kerkateskánd',
    county: 'Zala',
  },
  {
    postal: 8881,
    city: 'Sormás',
    county: 'Zala',
  },
  {
    postal: 8882,
    city: 'Eszteregnye',
    county: 'Zala',
  },
  {
    postal: 8883,
    city: 'Rigyác',
    county: 'Zala',
  },
  {
    postal: 8885,
    city: 'Valkonya',
    county: 'Zala',
  },
  {
    postal: 8885,
    city: 'Borsfa',
    county: 'Zala',
  },
  {
    postal: 8886,
    city: 'Oltárc',
    county: 'Zala',
  },
  {
    postal: 8887,
    city: 'Lasztonya',
    county: 'Zala',
  },
  {
    postal: 8887,
    city: 'Bázakerettye',
    county: 'Zala',
  },
  {
    postal: 8888,
    city: 'Kiscsehi',
    county: 'Zala',
  },
  {
    postal: 8888,
    city: 'Lispeszentadorján',
    county: 'Zala',
  },
  {
    postal: 8888,
    city: 'Maróc',
    county: 'Zala',
  },
  {
    postal: 8891,
    city: 'Bánokszentgyörgy',
    county: 'Zala',
  },
  {
    postal: 8891,
    city: 'Várfölde',
    county: 'Zala',
  },
  {
    postal: 8893,
    city: 'Bucsuta',
    county: 'Zala',
  },
  {
    postal: 8893,
    city: 'Szentliszló',
    county: 'Zala',
  },
  {
    postal: 8895,
    city: 'Pusztamagyaród',
    county: 'Zala',
  },
  {
    postal: 8896,
    city: 'Pusztaszentlászló',
    county: 'Zala',
  },
  {
    postal: 8897,
    city: 'Söjtör',
    county: 'Zala',
  },
  {
    postal: 8900,
    city: 'Zalaegerszeg',
    county: 'Zala',
  },
  {
    postal: 8904,
    city: 'Zalaegerszeg',
    county: 'Zala',
  },
  {
    postal: 8911,
    city: 'Nagykutas',
    county: 'Zala',
  },
  {
    postal: 8911,
    city: 'Kiskutas',
    county: 'Zala',
  },
  {
    postal: 8912,
    city: 'Kispáli',
    county: 'Zala',
  },
  {
    postal: 8912,
    city: 'Nagypáli',
    county: 'Zala',
  },
  {
    postal: 8913,
    city: 'Egervár',
    county: 'Zala',
  },
  {
    postal: 8913,
    city: 'Gősfa',
    county: 'Zala',
  },
  {
    postal: 8913,
    city: 'Lakhegy',
    county: 'Zala',
  },
  {
    postal: 8914,
    city: 'Vasboldogasszony',
    county: 'Zala',
  },
  {
    postal: 8915,
    city: 'Nemesrádó',
    county: 'Zala',
  },
  {
    postal: 8917,
    city: 'Milejszeg',
    county: 'Zala',
  },
  {
    postal: 8918,
    city: 'Csonkahegyhát',
    county: 'Zala',
  },
  {
    postal: 8918,
    city: 'Németfalu',
    county: 'Zala',
  },
  {
    postal: 8919,
    city: 'Kustánszeg',
    county: 'Zala',
  },
  {
    postal: 8921,
    city: 'Alibánfa',
    county: 'Zala',
  },
  {
    postal: 8921,
    city: 'Zalaszentlőrinc',
    county: 'Zala',
  },
  {
    postal: 8921,
    city: 'Zalaszentiván',
    county: 'Zala',
  },
  {
    postal: 8921,
    city: 'Pethőhenye',
    county: 'Zala',
  },
  {
    postal: 8923,
    city: 'Nemesapáti',
    county: 'Zala',
  },
  {
    postal: 8924,
    city: 'Alsónemesapáti',
    county: 'Zala',
  },
  {
    postal: 8925,
    city: 'Nemessándorháza',
    county: 'Zala',
  },
  {
    postal: 8925,
    city: 'Nemesszentandrás',
    county: 'Zala',
  },
  {
    postal: 8925,
    city: 'Búcsúszentlászló',
    county: 'Zala',
  },
  {
    postal: 8926,
    city: 'Kisbucsa',
    county: 'Zala',
  },
  {
    postal: 8928,
    city: 'Nemeshetés',
    county: 'Zala',
  },
  {
    postal: 8929,
    city: 'Pölöske',
    county: 'Zala',
  },
  {
    postal: 8931,
    city: 'Kemendollár',
    county: 'Zala',
  },
  {
    postal: 8931,
    city: 'Vöckönd',
    county: 'Zala',
  },
  {
    postal: 8932,
    city: 'Zalaistvánd',
    county: 'Zala',
  },
  {
    postal: 8932,
    city: 'Pókaszepetk',
    county: 'Zala',
  },
  {
    postal: 8932,
    city: 'Gyűrűs',
    county: 'Zala',
  },
  {
    postal: 8934,
    city: 'Bezeréd',
    county: 'Zala',
  },
  {
    postal: 8935,
    city: 'Padár',
    county: 'Zala',
  },
  {
    postal: 8935,
    city: 'Nagykapornak',
    county: 'Zala',
  },
  {
    postal: 8935,
    city: 'Almásháza',
    county: 'Zala',
  },
  {
    postal: 8935,
    city: 'Misefa',
    county: 'Zala',
  },
  {
    postal: 8935,
    city: 'Orbányosfa',
    county: 'Zala',
  },
  {
    postal: 8936,
    city: 'Zalaszentmihály',
    county: 'Zala',
  },
  {
    postal: 8943,
    city: 'Bocfölde',
    county: 'Zala',
  },
  {
    postal: 8943,
    city: 'Csatár',
    county: 'Zala',
  },
  {
    postal: 8944,
    city: 'Sárhida',
    county: 'Zala',
  },
  {
    postal: 8945,
    city: 'Bak',
    county: 'Zala',
  },
  {
    postal: 8946,
    city: 'Tófej',
    county: 'Zala',
  },
  {
    postal: 8946,
    city: 'Baktüttös',
    county: 'Zala',
  },
  {
    postal: 8946,
    city: 'Pusztaederics',
    county: 'Zala',
  },
  {
    postal: 8947,
    city: 'Zalatárnok',
    county: 'Zala',
  },
  {
    postal: 8947,
    city: 'Szentkozmadombja',
    county: 'Zala',
  },
  {
    postal: 8948,
    city: 'Nova',
    county: 'Zala',
  },
  {
    postal: 8948,
    city: 'Barlahida',
    county: 'Zala',
  },
  {
    postal: 8949,
    city: 'Mikekarácsonyfa',
    county: 'Zala',
  },
  {
    postal: 8951,
    city: 'Gutorfölde',
    county: 'Zala',
  },
  {
    postal: 8951,
    city: 'Csertalakos',
    county: 'Zala',
  },
  {
    postal: 8953,
    city: 'Szentpéterfölde',
    county: 'Zala',
  },
  {
    postal: 8954,
    city: 'Ortaháza',
    county: 'Zala',
  },
  {
    postal: 8956,
    city: 'Kányavár',
    county: 'Zala',
  },
  {
    postal: 8956,
    city: 'Pördefölde',
    county: 'Zala',
  },
  {
    postal: 8956,
    city: 'Páka',
    county: 'Zala',
  },
  {
    postal: 8957,
    city: 'Csömödér',
    county: 'Zala',
  },
  {
    postal: 8957,
    city: 'Kissziget',
    county: 'Zala',
  },
  {
    postal: 8957,
    city: 'Zebecke',
    county: 'Zala',
  },
  {
    postal: 8957,
    city: 'Hernyék',
    county: 'Zala',
  },
  {
    postal: 8958,
    city: 'Iklódbördőce',
    county: 'Zala',
  },
  {
    postal: 8960,
    city: 'Lenti',
    county: 'Zala',
  },
  {
    postal: 8960,
    city: 'Gosztola',
    county: 'Zala',
  },
  {
    postal: 8966,
    city: 'Lenti',
    county: 'Zala',
  },
  {
    postal: 8969,
    city: 'Szijártóháza',
    county: 'Zala',
  },
  {
    postal: 8969,
    city: 'Bödeháza',
    county: 'Zala',
  },
  {
    postal: 8969,
    city: 'Gáborjánháza',
    county: 'Zala',
  },
  {
    postal: 8969,
    city: 'Zalaszombatfa',
    county: 'Zala',
  },
  {
    postal: 8971,
    city: 'Zalabaksa',
    county: 'Zala',
  },
  {
    postal: 8971,
    city: 'Kerkabarabás',
    county: 'Zala',
  },
  {
    postal: 8973,
    city: 'Magyarföld',
    county: 'Zala',
  },
  {
    postal: 8973,
    city: 'Ramocsa',
    county: 'Zala',
  },
  {
    postal: 8973,
    city: 'Alsószenterzsébet',
    county: 'Zala',
  },
  {
    postal: 8973,
    city: 'Felsőszenterzsébet',
    county: 'Zala',
  },
  {
    postal: 8973,
    city: 'Kerkakutas',
    county: 'Zala',
  },
  {
    postal: 8973,
    city: 'Kerkafalva',
    county: 'Zala',
  },
  {
    postal: 8973,
    city: 'Csesztreg',
    county: 'Zala',
  },
  {
    postal: 8975,
    city: 'Szentgyörgyvölgy',
    county: 'Zala',
  },
  {
    postal: 8976,
    city: 'Márokföld',
    county: 'Zala',
  },
  {
    postal: 8976,
    city: 'Nemesnép',
    county: 'Zala',
  },
  {
    postal: 8977,
    city: 'Lendvajakabfa',
    county: 'Zala',
  },
  {
    postal: 8977,
    city: 'Baglad',
    county: 'Zala',
  },
  {
    postal: 8977,
    city: 'Resznek',
    county: 'Zala',
  },
  {
    postal: 8978,
    city: 'Rédics',
    county: 'Zala',
  },
  {
    postal: 8978,
    city: 'Külsősárd',
    county: 'Zala',
  },
  {
    postal: 8978,
    city: 'Belsősárd',
    county: 'Zala',
  },
  {
    postal: 8978,
    city: 'Lendvadedes',
    county: 'Zala',
  },
  {
    postal: 8981,
    city: 'Lickóvadamos',
    county: 'Zala',
  },
  {
    postal: 8981,
    city: 'Gellénháza',
    county: 'Zala',
  },
  {
    postal: 8983,
    city: 'Nagylengyel',
    county: 'Zala',
  },
  {
    postal: 8983,
    city: 'Babosdöbréte',
    county: 'Zala',
  },
  {
    postal: 8983,
    city: 'Ormándlak',
    county: 'Zala',
  },
  {
    postal: 8984,
    city: 'Gombosszeg',
    county: 'Zala',
  },
  {
    postal: 8984,
    city: 'Petrikeresztúr',
    county: 'Zala',
  },
  {
    postal: 8984,
    city: 'Iborfia',
    county: 'Zala',
  },
  {
    postal: 8985,
    city: 'Becsvölgye',
    county: 'Zala',
  },
  {
    postal: 8986,
    city: 'Pusztaapáti',
    county: 'Zala',
  },
  {
    postal: 8986,
    city: 'Pórszombat',
    county: 'Zala',
  },
  {
    postal: 8986,
    city: 'Szilvágy',
    county: 'Zala',
  },
  {
    postal: 8988,
    city: 'Kozmadombja',
    county: 'Zala',
  },
  {
    postal: 8988,
    city: 'Kálócfa',
    county: 'Zala',
  },
  {
    postal: 8989,
    city: 'Dobronhegy',
    county: 'Zala',
  },
  {
    postal: 8990,
    city: 'Pálfiszeg',
    county: 'Zala',
  },
  {
    postal: 8991,
    city: 'Teskánd',
    county: 'Zala',
  },
  {
    postal: 8991,
    city: 'Böde',
    county: 'Zala',
  },
  {
    postal: 8991,
    city: 'Hottó',
    county: 'Zala',
  },
  {
    postal: 8992,
    city: 'Boncodfölde',
    county: 'Zala',
  },
  {
    postal: 8992,
    city: 'Bagod',
    county: 'Zala',
  },
  {
    postal: 8992,
    city: 'Hagyárosbörönd',
    county: 'Zala',
  },
  {
    postal: 8992,
    city: 'Zalaboldogfa',
    county: 'Zala',
  },
  {
    postal: 8994,
    city: 'Zalaszentgyörgy',
    county: 'Zala',
  },
  {
    postal: 8994,
    city: 'Kávás',
    county: 'Zala',
  },
  {
    postal: 8995,
    city: 'Keménfa',
    county: 'Zala',
  },
  {
    postal: 8995,
    city: 'Salomvár',
    county: 'Zala',
  },
  {
    postal: 8996,
    city: 'Zalacséb',
    county: 'Zala',
  },
  {
    postal: 8997,
    city: 'Zalaháshágy',
    county: 'Zala',
  },
  {
    postal: 8998,
    city: 'Ozmánbük',
    county: 'Zala',
  },
  {
    postal: 8998,
    city: 'Vaspör',
    county: 'Zala',
  },
  {
    postal: 8999,
    city: 'Zalalövő',
    county: 'Zala',
  },
  {
    postal: 8999,
    city: 'Csöde',
    county: 'Zala',
  },
];
