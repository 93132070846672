import { Component, Input } from '@angular/core';
import { NanoActionType } from 'src/app/shared/services/nano-action-queue.service';

@Component({
  selector: 'app-action-queue-element',
  templateUrl: './action-queue-element.component.html',
  styleUrls: ['./action-queue-element.component.scss'],
})
export class ActionQueueElementComponent {
  @Input() action: NanoActionType;

  constructor() {}
}
