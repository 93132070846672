import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-new-group-dialog',
  templateUrl: './new-group-dialog.component.html',
  styleUrls: ['./new-group-dialog.component.scss'],
})
export class NewGroupDialogComponent extends NanoDialogBaseComponent<NewGroupDialogComponent> {
  groupNameControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(35)]);

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super(injector);
    if (data) this.groupNameControl.setValue(data.name);
  }

  onSubmit() {
    this.dialogRef.close(this.groupNameControl.value);
  }
}
