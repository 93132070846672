import { AbstractAccountKeyring, AbstractSelfAccountKeyring } from '../../keyring/account_base';
import { AbstractConfigDumpArgs, AbstractConfigLoadArgs } from '../base';

export class AccountSignatureConfigLoadArgs extends AbstractConfigLoadArgs {
  public peer_kr;
  public plain;

  constructor(cipher: Uint8Array, peer_kr: AbstractAccountKeyring, plain: Uint8Array) {
    super(cipher);
    this.peer_kr = peer_kr;
    this.plain = plain;
  }
}

export class AccountSignatureConfigDumpArgs extends AbstractConfigDumpArgs {
  public plain;
  public self_kr;

  constructor(plain: Uint8Array, self_kr: AbstractSelfAccountKeyring) {
    super();
    this.plain = plain;
    this.self_kr = self_kr;
  }
}
