import { SidebarGroup } from '../../../../../../../../server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionResourceGroupEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getAllSidebarGroupQuery,
  getSidebarGroupQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onCreateResourceGroupEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionResourceGroupEventRecord) => {
    let sidebarGroup: SidebarGroup = {
      id: event.id,
      closed: <boolean>event.closed,
      ordering: <number>event.ordering,
      resources: [],
      name: <string>event.name,
      decryptionError: event.decryptionError,
      decryptionErrorMessage: event.decryptionErrorMessage,
    };
    cache.setCacheDataByRequest(
      { query: getSidebarGroupQuery, variables: sidebarGroup },
      sidebarGroup
    );
    cache
      .getCacheDataByRequest({ query: getAllSidebarGroupQuery, variables: {} })
      .push(sidebarGroup);
  };
