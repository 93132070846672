<h3 class="title-bar" mat-dialog-title translate>Seen ({{ data.seenBy.length }})</h3>
<mat-dialog-content class="content-body mat-typography">
  <div *ngIf="!isLoading">
    <div class="row" *ngFor="let account of accountAvatarList" appShowUserInfo [accountAvatarRecord]="account">
      <div>
        <app-avatar [accountAvatarRecord]="account"></app-avatar>
      </div>
      <div class="name notranslate">{{ account.avatarName }}</div>
    </div>
  </div>
  <div class="loading-container" *ngIf="isLoading">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="primary"><span translate>Cancel</span></button>
</mat-dialog-actions>
