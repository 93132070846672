import { MeRecord } from '../../../../../../../../server-services/query-records/account-records';
import { ID } from '../../../../../../../../server-services/query-records/common-records';
import {
  RoomRecord,
  RoomWithData,
} from '../../../../../../../../server-services/query-records/room-records';
import { SidebarResource } from '../../../../../../../../server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionRoomAccountPermissionEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getMeQuery,
  getRoomQuery,
  getSidebarGrantedRoomsQuery,
  getSidebarResourceQuery,
  roomMyPermissionsQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onCreateRoomAccountPermissionEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionRoomAccountPermissionEventRecord) => {
    if (
      (<MeRecord>cache.getCacheDataByRequest({ query: getMeQuery, variables: {} })).id ==
      event.accountId
    ) {
      let granted: ID[] = cache.getCacheDataByRequest({
        query: getSidebarGrantedRoomsQuery,
        variables: {},
      });
      let sidebarRes: SidebarResource = cache.getCacheDataByRequest({
        query: getSidebarResourceQuery,
        variables: event,
      });

      if (!granted.includes(event.id) && !sidebarRes) {
        granted.push(event.id);
      }

      let room: RoomRecord | RoomWithData = cache.getCacheDataByRequest({
        query: getRoomQuery,
        variables: {
          id: event.id,
        },
      });
      if (room) {
        // if you lose permission, have to reset the room's data
        // we have to force the client the refetch all the data
        room['__refetch'] = true;

        /*cache.delete(getRoomQuery, event);
          let allRoom: RoomRecord[] = cache.get(getAllRoomQuery);
          let roomIndex = allRoom.findIndex((el) => el.id == event.id);
          if (roomIndex > -1) {
            allRoom.splice(roomIndex);
          }*/

        cache.deleteCacheDataByRequest({ query: roomMyPermissionsQuery, variables: event });
      }
    }
  };
