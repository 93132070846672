import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateNanoWaitinEventSubscriptionHandler } from './ws-sub-nano-waiting-event-create-handler';
import { onDeleteNanoWaitinEventSubscriptionHandler } from './ws-sub-nano-waiting-event-delete-handler';
import { onModifyNanoWaitinEventSubscriptionHandler } from './ws-sub-nano-waiting-event-modify-handler';

export let workspaceSubscriptionNanoWaitingEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateNanoWaitinEventSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyNanoWaitinEventSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteNanoWaitinEventSubscriptionHandler,
};
