export class SafeRadix32 {
  private static SAFE_UNMAP: Array<number> = [
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, 0, 1, 2, -1, 3, 4, 5, 6, -1, -1, -1, -1, -1, -1, -1, -1, 7, 8, -1, -1, 9, 10, -1, -1,
    11, 12, 13, 14, -1, -1, 15, 16, -1, -1, -1, -1, 17, 18, -1, -1, 19, -1, -1, -1, -1, -1, -1, -1,
    20, 21, -1, -1, 22, 23, -1, -1, 24, 25, -1, 26, -1, -1, 27, 28, -1, -1, -1, -1, 29, 30, -1, -1,
    31, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  ];
  // const SAFE_BASE_BITS = 5

  static decodeSafeRadix32(v: string): number {
    let u = 0;
    let i = 0;
    while (true) {
      const x = this.SAFE_UNMAP[v.charCodeAt(i)];
      if (x === -1) {
        throw new Error('overflow error');
      }
      u += x;
      i += 1;
      if (i >= v.length) {
        break;
      }
      u *= 32;
    }
    return u;
  }

  static isSafeRadix(value: string): boolean {
    return /[2346789BCFGJKLMPQVWZbcfgjkmpqvwz]{13}/g.test(value);
  }
}
