import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({ selector: '[appEnterTheViewportNotifier]' })
export class EnterTheViewportNotifierDirective implements AfterViewInit, OnDestroy {
  @Output() readonly visibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() appEnterTheViewportNotifier?;
  @Input() viewportNotifierContainer?: Element = null;
  @Input() viewportNotifierThreshold?: number = 0.0;
  @Input() viewportNotifierMargin: string = '0px';
  private intersectionObserver: IntersectionObserver;

  constructor(@Host() private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const options = {
      root: this.viewportNotifierContainer,
      rootMargin: this.viewportNotifierMargin,
      threshold: this.viewportNotifierThreshold,
    };
    this.intersectionObserver = new IntersectionObserver(this._callback, options);
    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    if (this.intersectionObserver) this.intersectionObserver.disconnect();
  }

  private _callback = (entries, observer) => {
    entries.forEach((entry) => this.visibilityChange.emit(entry.isIntersecting));
  };
}
