import { AbstractKeyring, MissingKey } from './base';

/**
 * A room has secrets pre-shared with its members to enforce membership access. These secrets are shared with
    peer-to-peer encryption by the owner, so all members can only load their own version of the encrypted keys.
    Such secrets are primarily used by server-side group features. The Nano enforces the policy its owner creates
    by the group-config blockchain and the account trust-store, these keys are superfluous for Nano access.
 */
export abstract class AbstractRoomKeyring extends AbstractKeyring {
  public id;
  protected _keys;

  public static PINNED = '1';

  public static VALID_KEY_LENGTH_MAP = { [AbstractRoomKeyring.PINNED]: 32 };

  //public static abstract load: Function

  constructor(room_id: string, keys: Object) {
    super();
    const encoder = new TextEncoder();
    this.id = encoder.encode(room_id);
    AbstractRoomKeyring.check_key_lengths(keys, AbstractRoomKeyring.VALID_KEY_LENGTH_MAP);
    this._keys = keys;
  }

  public update(keys: Object) {
    if (keys) {
      AbstractRoomKeyring.check_key_lengths(keys, AbstractRoomKeyring.VALID_KEY_LENGTH_MAP);
      this._keys = Object.assign(this._keys, keys);
    }
  }

  public get_key(version: string) {
    let key = this._keys[version];
    if (!key) {
      throw new MissingKey(version + '');
    }
    return key;
  }
}
