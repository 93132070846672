import {
  MessageRecord,
  MessageRecordPlaceholder,
  MessageRecordType,
} from '../../../../../../server-services/query-records/room-records';
import { ResponseMergerHandler } from '../../../cache-logic-interface';

export let customRoomMessagesByIdQueryMerger: ResponseMergerHandler = function (
  cache,
  request,
  response: MessageRecord[]
) {
  if (response.length == 0) return;

  if (!cache.getCacheData('messages')[request.variables.roomId]) {
    cache.getCacheData('messages')[request.variables.roomId] = {};
  }

  response.forEach((msg) => {
    cache.getCacheData('messages')[request.variables.roomId][msg.id] = msg;

    const cacheKey = 'query.roomMessages.' + request.variables.roomId;
    let cachedMessages: (MessageRecordPlaceholder | MessageRecord)[] = cache.getCacheData(cacheKey);

    if (!cachedMessages?.length) {
      cachedMessages = [
        { type: MessageRecordType.END },
        { type: MessageRecordType.VOID, afterMsgId: msg.id },
        msg,
        { type: MessageRecordType.VOID, beforeMsgId: msg.id },
        { type: MessageRecordType.END },
      ];
      cache.setCacheData(cacheKey, cachedMessages);
    } else {
      for (let i = 0; i < cachedMessages.length; i++) {
        const cachedMsg = <MessageRecord>cachedMessages[i];

        if (cachedMsg?.id === msg.id) {
          break;
        }

        // megkeressük azt a voidot, ami helyére a [void, msg, void] kerülne
        if (
          // void
          (<MessageRecordPlaceholder>cachedMessages[i])?.type === MessageRecordType.VOID &&
          // void utáni elemnél már nagyobb a msg-ünk (vagy end)
          ((<MessageRecord>cachedMessages[i + 1])?.id < msg.id ||
            (<MessageRecordPlaceholder>cachedMessages[i + 1])?.type === MessageRecordType.END)
        ) {
          cachedMessages.splice(
            i,
            1,
            ...[
              {
                type: MessageRecordType.VOID,
                afterMsgId: msg.id,
                beforeMsgId: (<MessageRecord>cachedMessages[i - 1])?.id,
              },
              msg,
              {
                type: MessageRecordType.VOID,
                beforeMsgId: msg.id,
                afterMsgId: (<MessageRecord>cachedMessages[i + 1])?.id,
              },
            ]
          );
        }
      }
    }
  });
};
