import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateAccountEventSubscriptionHandler } from './ws-sub-account-event-create-handler';
import { onDeleteAccountEventSubscriptionHandler } from './ws-sub-account-event-delete-handler';
import { onModifyAccountEventSubscriptionHandler } from './ws-sub-account-event-modify-handler';

export let workspaceSubscriptionAccountEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateAccountEventSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyAccountEventSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteAccountEventSubscriptionHandler,
};
