import {
  LoadMessageDirection,
  MessageRecordType,
} from 'src/app/shared/server-services/query-records/room-records';
import { ConditionForFetchHandler } from '../../cache-logic-interface';

export let conditionFetchForRoomMessageQuery: ConditionForFetchHandler = function (
  cache,
  cacheKey,
  request
) {
  // querying required when
  // - there is no cache
  // - there is cache, but pivot is not in found in it
  //    or its the last element in the cached message list

  const cacheData = cache.getCacheData(cacheKey);
  if (!cacheData) {
    return true;
  }

  if (request.variables.pivot) {
    // ends with [... pivot, void, end]
    if (
      cacheData?.[cacheData.length - 2]?.type === MessageRecordType.VOID &&
      cacheData?.[cacheData.length - 3]?.id === request.variables.pivot
    ) {
      return true;
    }

    // is empty [end, void, end]
    if (
      cacheData?.[3]?.type === MessageRecordType.END &&
      cacheData?.[2]?.type === MessageRecordType.VOID
    ) {
      return true;
    }

    for (let i = 0; i < cacheData.length; i++) {
      if (cacheData[i].id === request.variables.pivot) {
        //if the element in the cache after/before the pivot is VOID, then we must query
        const dir: number = request.variables.direction === LoadMessageDirection.BEFORE ? -1 : 1;
        if (cacheData[i + dir]?.type === MessageRecordType.VOID) return true;
        else return false;
      }
    }
    // pivot is not found in the cache, query more
    return true;
  }

  // there
  return false;
};
