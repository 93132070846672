import { Component, Inject, INJECTOR, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';
import { SaleCodeRecord } from 'src/app/shared/server-services/query-records/nano-records';

@Component({
  selector: 'app-create-edit-sale-code-dialog',
  templateUrl: './create-edit-sale-code-dialog.component.html',
  styleUrls: ['./create-edit-sale-code-dialog.component.scss'],
})
export class CreateEditSaleCodeDialogComponent
  extends NanoDialogBaseComponent<CreateEditSaleCodeDialogComponent>
  implements OnInit
{
  saleCodeForm = new UntypedFormGroup({
    description: new UntypedFormControl('', Validators.maxLength(50)),
  });

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public saleCode: SaleCodeRecord
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.saleCodeForm.get('description').setValue(this.saleCode?.descriptionDecoded.text);
  }

  public createOrUpdate(): void {
    if (!this.saleCodeForm.valid) return;

    if (this.saleCode) {
      this.saleCode.descriptionDecoded.text = this.saleCodeForm.get('description').value;
      this.saleCode.descriptionDecoded.valid = true;
    } else {
      this.saleCode = {
        descriptionDecoded: { valid: true, text: this.saleCodeForm.get('description').value },
        id: null,
        description: null,
      };
    }
    this.dialogRef.close(this.saleCode);
  }
}
