import { Component, OnInit } from '@angular/core';
import { BaseElementComponent } from 'slate-angular';
import { Descendant } from 'slate';

export type SlateEmojiElement = { type: 'emoji'; src: string; alt: string; children: Descendant[] };

@Component({
  selector: 'app-slate-emoji',
  templateUrl: './slate-emoji.component.html',
  styleUrls: ['./slate-emoji.component.scss'],
})
export class SlateEmojiComponent extends BaseElementComponent<SlateEmojiElement> {
  public src;
}
