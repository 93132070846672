import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-mat-nano-snackbar',
  templateUrl: './mat-nano-snackbar.component.html',
  styleUrls: ['./mat-nano-snackbar.component.scss'],
})
export class MatNanoSnackbarComponent {
  public text: String;
  constructor(
    public snackBarRef: MatSnackBarRef<MatNanoSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string
  ) {
    this.text = data;
  }

  cancel(): void {
    this.snackBarRef.dismiss();
  }
}
