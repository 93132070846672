<h2 mat-dialog-title translate>Create file</h2>
<form [formGroup]="createFileFormGroup" (submit)="submit()">
  <mat-dialog-content class="mat-typography">
    <div>
      <mat-form-field>
        <mat-label><span translate>File name</span></mat-label>
        <input type="text" matInput name="file-name" formControlName="fileName" />
        <mat-error *ngIf="createFileFormGroup.get('fileName').hasError('required')" translate> Please enter file name </mat-error>
      </mat-form-field>
    </div>
    <div id="doc-type" *ngIf="!createFileFormGroup.get('customExt').value">
      <div matRipple [ngClass]="{ 'selected-type': documentType === allType.WORD }" (click)="setExt(allType.WORD)">
        <span class="mdi mdi-file-word file-file-word"></span><span translate>Word</span><span class="ext">(.odt)</span>
      </div>
      <div matRipple [ngClass]="{ 'selected-type': documentType === allType.EXCEL }" (click)="setExt(allType.EXCEL)">
        <span class="mdi mdi-file-excel file-file-excel"></span><span translate>Excel</span><span class="ext">(.ods)</span>
      </div>
      <div matRipple [ngClass]="{ 'selected-type': documentType === allType.PRESENTATION }" (click)="setExt(allType.PRESENTATION)">
        <span class="mdi mdi-file-powerpoint file-file-powerpoint"></span><span translate>Presentation</span><span class="ext">(.odp)</span>
      </div>
    </div>
    <div>
      <mat-checkbox color="primary" translate formControlName="customExt">Use custom extension instead</mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-button color="primary" [disabled]="!createFileFormGroup.valid"><span translate>Create</span></button>
  </mat-dialog-actions>
</form>
