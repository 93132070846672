import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

import { DriveFile } from '../../../components/resource-page/drive-window/drive-layout/drive-file';

@Component({
  selector: 'app-file-upload-confirm-dialog',
  templateUrl: './file-upload-confirm-dialog.component.html',
  styleUrls: ['./file-upload-confirm-dialog.component.scss'],
})
export class FileUploadConfirmDialogComponent extends NanoDialogBaseComponent<FileUploadConfirmDialogComponent> {
  public files = [];
  public autoName = false;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public fileUploadData: FileUploadData,
    public translate: TranslateService
  ) {
    super(injector);

    this.readFiles(fileUploadData.files);
  }

  closeWithOk() {
    this.dialogRef.close({ success: true, autoName: this.autoName });
  }

  readFiles(rawFiles) {
    for (let file of rawFiles) {
      const newFile = new DriveFile(file);

      if (newFile.icon === 'image') {
        this.files.push({ driveFile: newFile, blob: file });
      } else {
        this.files.push({ driveFile: newFile });
      }
    }
  }
}

interface FileUploadData {
  files: any[];
}
