<div class="input-container">
  <div>
    <img id="logo" [src]="isDarkTheme ? '/assets/img/ui/logo_nano-dark.svg' : '/assets/img/ui/logo_nano.svg'" />
    <form class="form-container" [formGroup]="registerConfirmForm" (submit)="startRegisterConfirm()">
      <h1 class="mat-caption" translate>Confirming registration</h1>
      <mat-form-field>
        <mat-label><span translate>Email address</span></mat-label>
        <input matInput formControlName="email" type="email" name="nano-webapp-email" autocomplete="email" />
        <mat-error *ngIf="registerConfirmForm.get('email').hasError('required')"><span translate>Please enter your email address.</span></mat-error>
        <mat-error *ngIf="registerConfirmForm.get('email').hasError('email')"><span translate>Please enter a valid email address.</span></mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label><span translate>Password</span></mat-label>
        <input matInput formControlName="password" type="password" name="nano-webapp-password" autocomplete="current-password" />
        <mat-error *ngIf="registerConfirmForm.get('password').hasError('required')"><span translate>Please enter your password.</span></mat-error>
      </mat-form-field>
      <button class="action-button" type="submit" mat-raised-button color="primary" [disabled]="isPending || !registerConfirmForm.valid">
        <span *ngIf="!isPending" translate>Confirm</span>
        <mat-icon *ngIf="isPending"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        <mat-icon *ngIf="isRegisterSuccess">done</mat-icon>
      </button>
    </form>

    <div class="nav-buttons">
      <a routerLink="/login"
        ><button mat-button color="primary"><span translate>Back to login</span></button></a
      >
    </div>
  </div>
</div>
