import { CacheDataPath, Request } from './services/cache/cache-logic/cache-logic-interface';
const stringify = require('json-stringify-deterministic');

export class RequestQueue {
  private queue = {};

  public createRequestQueryKey(request: Request) {
    return stringify(request);
  }

  /**
   * is the request already registered in this queue and resolvers are waiting its end
   * @param requestKey
   * @returns
   */
  public isRequestAlreadyFetched(requestKey): boolean {
    return requestKey in this.queue;
  }

  /**
   * Register a resolver callback for the request.
   * @param requestKey
   * @param callback
   */
  public registerToRequestQueue(requestKey, resolve, reject) {
    if (requestKey in this.queue) {
      this.queue[requestKey].push({ resolve, reject });
    } else {
      this.queue[requestKey] = [{ resolve, reject }];
    }
  }

  /**
   * return with the all registered resolver, what need to call at the end of the process
   * @param requestKey
   * @returns
   */
  public getRequestQueue(requestKey): { resolve: Function; reject: Function }[] {
    return this.queue[requestKey];
  }

  /**
   * clean the queue
   * @param requestKey
   */
  public emptyRequestQueue(requestKey) {
    delete this.queue[requestKey];
  }

  public reset() {
    this.queue = {};
  }

  public resolveAll(requestKey: CacheDataPath, requestResult: any): void {
    this.getRequestQueue(requestKey)?.forEach((cb) => {
      cb.resolve(requestResult);
    });

    this.emptyRequestQueue(requestKey);
  }

  public rejectAll(requestKey: CacheDataPath, err: any): void {
    this.getRequestQueue(requestKey)?.forEach((cb) => {
      cb.reject(err);
    });
    this.emptyRequestQueue(requestKey);
  }
}
