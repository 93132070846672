import { Component, ElementRef, Inject, INJECTOR, Injector, ViewChild } from '@angular/core';
import Cropper from 'cropperjs';
import { generateAvatar } from '../../avatar-generator';
import { NanoDialogBaseComponent } from '../nano-base-dialog';
import { NativeAppService } from '../../services/native-app.service';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
})
export class ImageCropperDialogComponent extends NanoDialogBaseComponent<ImageCropperDialogComponent> {
  cropper: Cropper;
  @ViewChild('image') imageRef: ElementRef;
  @ViewChild('previewImageContainer') previewImageContainerRef: ElementRef;
  isImageLoaded: boolean = false;
  generatedImages: Blob[] = [];
  generateSeed: string = 'seed';
  selectedIndex: number = -1;

  public isOnApp: boolean;

  constructor(@Inject(INJECTOR) injector: Injector, private nativeAppService: NativeAppService) {
    super(injector);
    this.isOnApp = this.nativeAppService.isOnApp();
  }

  imageSelected(e: Event) {
    this.generatedImages = [];
    var file = (<HTMLInputElement>e.target).files.item(0);
    var reader = new FileReader();
    reader.onload = (e) => this.imageLoaded(reader);
    reader.readAsDataURL(file);
  }

  imageLoaded(reader: FileReader) {
    if (this.cropper) this.cropper.destroy();

    this.imageRef.nativeElement.src = reader.result.toString();
    this.isImageLoaded = true;
    this.cropper = new Cropper(this.imageRef.nativeElement, {
      aspectRatio: 1,
      preview: this.previewImageContainerRef.nativeElement,
    });
  }

  changeAvatar(): void {
    this.cropper.getCroppedCanvas({ width: 128, height: 128 }).toBlob((blob) => {
      this.dialogRef.close(blob);
    });
  }

  generate() {
    let seedSource = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    this.generateSeed = '';
    for (let i = 0; i < 10; i++) {
      this.generateSeed += seedSource.charAt(Math.floor(Math.random() * seedSource.length));
    }

    this.generateAvatarFromSeed(this.generateSeed);
  }

  generateAvatarFromSeed(seed) {
    this.selectedIndex = -1;
    for (let i = 0; i < 6; i++) {
      ((i) => {
        generateAvatar('' + seed + (i > 0 ? i : '')).then((avatarBlob) => {
          this.generatedImages[i] = avatarBlob;
        });
      })(i);
    }
  }

  selectGeneratedAvatar(index: number): void {
    this.selectedIndex = index;
  }

  chooseGeneratedAvatar(): void {
    this.dialogRef.close(this.generatedImages[this.selectedIndex]);
  }

  public useCamera() {
    this.nativeAppService
      .useCamera()
      .then((webPath) => {
        let name = webPath.split('/').pop();
        return fetch(webPath)
          .then((r) => r.blob())
          .then((b) => new File([b], name))
          .then((f) => {
            var reader = new FileReader();
            reader.onload = (e) => this.imageLoaded(reader);
            reader.readAsDataURL(f);
          });
      })
      .catch((err) => {
        console.error('err', err);
      });
  }
}
