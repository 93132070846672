<!-- <div class="group" #group *ngIf="sidebarGroupData"> -->
<!-- <div class="group-header" (click)="toggle()">
  <span class="group-arrow">
    <mat-icon [ngClass]="{ 'flip-arrow': !closed }">keyboard_arrow_up</mat-icon>
  </span>
  <span class="group-title mat-subtitle-2" [ngClass]="{ 'data-error': error === true }">
    {{ name }}
  </span>
  <span class="group-options">
    <span [matMenuTriggerFor]="menu"></span>
    <mat-icon matRipple (click)="openMenu($event)">more_vert</mat-icon>
  </span>
</div> -->
<mat-expansion-panel [expanded]="!sidebarGroupData.closed" [disabled]="isGroupReordering || isRoomReordering" cdkDrag [cdkDragDisabled]="!isGroupReordering" class="group-expansion-panel">
  <mat-expansion-panel-header (click)="toggle()">
    <mat-panel-title>
      <span class="group-title mat-subtitle-2 notranslate" [ngClass]="{ 'data-error': error === true }">
        {{ name }}
      </span>
      <span class="group-options">
        <mat-icon *ngIf="isGroupReordering" class="group-grabber" cdkDragHandle>drag_handle</mat-icon>
        <mat-icon *ngIf="!isGroupReordering && !isRoomReordering" matRipple [matMenuTriggerFor]="menu" (click)="stopPropagation($event)">more_vert</mat-icon>
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="group-body" cdkDropList cdkDropListAutoScrollStep="15" [cdkDropListData]="showResourceList" (cdkDropListDropped)="roomDropped($event)">
    <div *ngFor="let r of showResourceList">
      <app-sidebar-room *ngIf="r.type === 'room'" [sidebarResourceData]="r" [isReordering]="isRoomReordering"></app-sidebar-room>
      <app-sidebar-dialogue *ngIf="r.type === 'dialogue'" [sidebarResourceData]="r" [isReordering]="isRoomReordering"></app-sidebar-dialogue>
    </div>
  </div>
</mat-expansion-panel>

<mat-menu #menu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item (click)="startGroupReorder()">
    <mat-icon>swap_vert</mat-icon>
    <span translate>Category reorder</span>
  </button>
  <button mat-menu-item (click)="startRoomReorder()">
    <mat-icon>swap_vert</mat-icon>
    <span translate>Room reorder</span>
  </button>
  <button mat-menu-item (click)="openNewRoomDialog()">
    <mat-icon>add_comment</mat-icon>
    <span translate>Create room</span>
  </button>
  <button mat-menu-item (click)="editName()">
    <mat-icon>edit</mat-icon>
    <span translate>Rename</span>
  </button>
  <button mat-menu-item class="primary" (click)="delete()">
    <mat-icon color="warn">delete</mat-icon>
    <span translate>Delete</span>
  </button>
</mat-menu>

<!-- </div> -->
