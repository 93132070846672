/**
 * Note: There is no static abstract function in TypeScript yet: https://github.com/microsoft/TypeScript/issues/34665
 */

/**
 * Generic base to express different cryptographic primitives and algorithms: symmetric, asymmetric, EC, etc.
 * These are so low level that versioning them separately does not seem very useful, because many times we will
 * use several of them in different specific combinations. Versioning those combinations in their context seems
 * generally more useful.
 */
export abstract class AbstractCryptoBase {
  public describe: Function;
}

export abstract class AbstractKeyGenerator extends AbstractCryptoBase {
  //public static abstract new_key: Function
  //public static abstract describe():string;
}

/**
 * Generate a key for a symmetric function.
 */
export abstract class AbstractSymmetricKeyGenerator extends AbstractKeyGenerator {
  //public static abstract new_key: Function;
  //public static abstract describe(): string;
}

/**
 * Generate a key-pair for an asymmetric function.
 */
export abstract class AbstractAsymmetricKeyGenerator extends AbstractKeyGenerator {
  //public static abstract new_key: Function;
  //public static abstract describe(): Function;
  //public static abstract regenerate_key_pair: Function;
}

export abstract class AbstractSymmetricCipher extends AbstractCryptoBase {
  //public static abstract encrypt: Function;
  //public static abstract decrypt: Function;
  //public static abstract describe(): string;
}

export abstract class AbstractAsymmetricCipher extends AbstractCryptoBase {
  //public static abstract encrypt: Function;
  //public static abstract decrypt: Function;
  //public static abstract describe(): string;
}

export abstract class AbstractDigitalSignature extends AbstractCryptoBase {
  //public static abstract sign: Function;
  //public static abstract verify: Function;
  //public static abstract describe(): string;
}
