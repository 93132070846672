import {
  DialogueRecord,
  RoomRecord,
} from 'src/app/shared/server-services/query-records/room-records';
import {
  getAllSidebarGroupQuery,
  getDialogueDataQuery,
  getRoomQuery,
  getSidebarNewsQuery,
  getSidebarUngrouppedQuery,
} from 'src/app/shared/server-services/querys';
import {
  ResourceType,
  SidebarGroup,
  SidebarNews,
  SidebarResource,
} from '../../../../../../../../server-services/query-records/sidebar-records';
import { ResponseMergerHandler } from '../../../../../cache-logic-interface';

export let onNewsHandlerInit: ResponseMergerHandler = (cache, request, response) => {
  // important!: this event called by client, it can be called during cache state reload
  let news: SidebarNews = {
    news: 0,
    resources: [],
  };

  let groups: SidebarGroup[] = cache.getCacheDataByRequest({
    query: getAllSidebarGroupQuery,
    variables: {},
  });

  groups.forEach((g) => {
    g.resources.forEach((res) => {
      news.resources.push(res);
    });
  });

  let ungroups: SidebarResource[] = cache.getCacheDataByRequest({
    query: getSidebarUngrouppedQuery,
    variables: {},
  });
  ungroups.forEach((res) => {
    news.resources.push(res);
  });

  news.resources.forEach((res) => {
    let currRes: RoomRecord | DialogueRecord;
    if (res.type == ResourceType.ROOM) {
      currRes = cache.getCacheDataByRequest({ query: getRoomQuery, variables: res });
    } else {
      currRes = cache.getCacheDataByRequest({ query: getDialogueDataQuery, variables: res });
    }

    if (
      currRes &&
      currRes.topMessageId &&
      (currRes.topMessageId > currRes.seenMessageId || !currRes.seenMessageId)
    ) {
      news.news++;
    }
  });

  // order news
  news.resources.sort((a, b) => {
    let aRes: RoomRecord | DialogueRecord =
      a.type == ResourceType.ROOM
        ? cache.getCacheDataByRequest({ query: getRoomQuery, variables: { id: a.id } })
        : cache.getCacheDataByRequest({ query: getDialogueDataQuery, variables: { id: a.id } });
    let bRes: RoomRecord | DialogueRecord =
      b.type == ResourceType.ROOM
        ? cache.getCacheDataByRequest({ query: getRoomQuery, variables: { id: b.id } })
        : cache.getCacheDataByRequest({ query: getDialogueDataQuery, variables: { id: b.id } });

    if (!aRes) {
      return 1;
    }
    if (!bRes) {
      return -1;
    }

    if (!bRes.topMessageId || aRes.topMessageId > bRes.topMessageId) {
      return -1;
    }
    if (!aRes.topMessageId || aRes.topMessageId < bRes.topMessageId) {
      return 1;
    }
    // else
    return 0;
  });

  cache.setCacheDataByRequest({ query: getSidebarNewsQuery, variables: {} }, news);
};
