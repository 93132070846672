import { AdvancedSettingsEnum } from 'src/app/components/advanced-settings/advanced-settings.component';
import { AppStorage } from '../app-storage';
import { ID } from '../server-services/query-records/common-records';
import { PeekResult } from './nano-requests';

export class PeekCache {
  /**
   * {room+path:
   *    {name+size+mtime+imgb: data}
   * }
   */
  private cachedPeeks: {
    [key: string]: PeekResult;
  } = {};

  /**
   * Store the keys inorder so you can clear the cache after a while
   */
  private cacheTopLevelKeys: string[] = [];

  public static MAX_CACHED_ELEMENTS: number =
    parseInt(AppStorage.getItem(AdvancedSettingsEnum.MAX_PREVIEW_CACHE)) || 40;

  private makePeekTopLevelCacheKey(roomId: ID, path: string) {
    return '' + roomId + path;
  }

  public getCachedPreview(roomId: ID, path: string): PeekResult | null {
    let topLevelKey = this.makePeekTopLevelCacheKey(roomId, path);

    return this.cachedPeeks[topLevelKey] || null;
  }

  public setCachedPreview(roomId: ID, path: string, peekResponse: PeekResult) {
    let topLevelKey = this.makePeekTopLevelCacheKey(roomId, path);

    this.cacheTopLevelKeys.push(topLevelKey);
    this.cachedPeeks[topLevelKey] = peekResponse;

    // check the cache limit
    while (
      this.cacheTopLevelKeys.length > PeekCache.MAX_CACHED_ELEMENTS &&
      this.cacheTopLevelKeys.length > 1
    ) {
      let topLevelKey = this.cacheTopLevelKeys.shift();
      if (this.cachedPeeks[topLevelKey]) {
        delete this.cachedPeeks[topLevelKey];
      }
    }
  }
}
