<div [id]="'room-' + sidebarResourceData.id" class="sidebar-item-container" cdkDrag [cdkDragDisabled]="!isReordering" *ngIf="sidebarResourceData && room">
  <button
    mat-button
    matRipple
    routerLink="room/{{ sidebarResourceData.id }}/chat/room"
    routerLinkActive="active"
    [ngClass]="{ 'active-node': selectedMenu, 'data-error': error === true }"
    (pointerup)="selected($event)"
    (contextmenu)="openContextMenu($event)"
    [appEnterTheViewportNotifier]
    (visibilityChange)="visibilityChange($event)"
    id="sidebar-room-cont"
    class="room-container"
    *ngIf="!isReordering"
  >
    <ng-container>
      <mat-icon class="notification-led" color="primary" *ngIf="sidebarResourceData?.hasNewMessages">circle</mat-icon>
      <app-avatar [blob]="avatar"></app-avatar>
      <div class="res-node-name">
        <span class="notranslate">{{ name }}</span>
        <div *ngIf="!roomData && isVisible" class="loading-bar"></div>
      </div>

      <mat-icon [matTooltip]="'Attached drive' | translate" class="info-flag" [ngClass]="{ warn: isNanoCompeting }" *ngIf="isNanoConnected">computer</mat-icon>
      <mat-icon class="info-flag" *ngIf="isNanoCompeting" color="warn">warning</mat-icon>
      <span [matTooltip]="'Owner' | translate" class="mdi mdi-crown info-flag" *ngIf="isMyRoom"></span>
    </ng-container>
  </button>
  <div *ngIf="isReordering" id="sidebar-room-cont" class="room-container room-container-reordering" [appEnterTheViewportNotifier] (visibilityChange)="visibilityChange($event)">
    <app-avatar [blob]="avatar"></app-avatar>
    <div class="res-node-name">
      <span class="notranslate">{{ name }}</span>
    </div>
    <button class="open-group-select-button" mat-icon-button (click)="moveIntoOtherGroup()"><mat-icon>north_east</mat-icon></button>
    <mat-icon class="room-grabber" cdkDragHandle>drag_handle</mat-icon>
  </div>
</div>
