import { DialogueRecord } from 'src/app/shared/server-services/query-records/room-records';
import { SidebarNews } from 'src/app/shared/server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionDialogueEventRecord } from 'src/app/shared/server-services/query-records/workspace-records';
import { getDialogueDataQuery, getSidebarNewsQuery } from 'src/app/shared/server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../../../workspace-change-subscription-merger-handlers/types';

export let onNewsHandlerDialogueDelete: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionDialogueEventRecord
) => {
  let dialogueData: DialogueRecord = cache.getCacheDataByRequest({
    query: getDialogueDataQuery,
    variables: event,
  });
  let news: SidebarNews = cache.getCacheDataByRequest({
    query: getSidebarNewsQuery,
    variables: {},
  });

  // remove from news
  let roomIndex = news.resources.findIndex((el) => el.id == event.id);
  if (roomIndex > -1) {
    news.resources.splice(roomIndex, 1);

    if (
      dialogueData &&
      dialogueData.topMessageId &&
      dialogueData.topMessageId < dialogueData.seenMessageId
    ) {
      news.news--;
      if (news.news < 0) {
        news.news = 0;
        console.warn('News number is negative', news);
      }
    }
  }
};
