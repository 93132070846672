import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OutboundLinkDialogComponent } from 'src/app/shared/dialogs/outbound-link-dialog/outbound-link-dialog.component';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { EnvironmentService } from 'src/environments/environment.service';
import { emojiShortcodeToUnicode } from '../../../../../../shared/emoji-codes';
import { MarkdownTokenTree, TOKEN_TYPE } from '../../../chat-message-editor/slate-editor/markdown';
declare var twemoji;

@Component({
  selector: 'app-markdown-viewer-fragment',
  templateUrl: './markdown-viewer-fragment.component.html',
  styleUrls: ['./markdown-viewer-fragment.component.scss'],
})
export class MarkdownViewerFragmentComponent implements OnInit {
  @Input() token: MarkdownTokenTree | null = null;

  @ViewChild('link', { read: ElementRef }) link: any;

  public emojiSrc: string = '';
  public emojiAlt: string = '';
  public isEmoji: boolean = false;

  public isURLSameSite: boolean = false;
  public isURLTrusted: boolean = false;

  public isShowText: boolean = true;

  constructor(
    private environmentService: EnvironmentService,
    private dialog: MatDialog,
    private routerHandler: RouterHandler
  ) {}

  ngOnInit(): void {
    if (this.token) {
      if (
        [TOKEN_TYPE.LINK, TOKEN_TYPE.EMOJI_SHORTCODE, TOKEN_TYPE.EMOJI].includes(this.token.token)
      ) {
        this.isShowText = false;
      }

      if (this.token.token == TOKEN_TYPE.EMOJI_SHORTCODE) {
        this.emojiAlt =
          emojiShortcodeToUnicode[this.token.text.slice(1, this.token.text.length - 1)];
        if (this.emojiAlt) {
          this.isEmoji = true;
          //this.emojiSrc = twemoji.convert.toCodePoint(this.emojiAlt);
          twemoji.parse(this.emojiAlt, {
            callback: (icon, options) => {
              if (icon) {
                this.emojiSrc = icon;
              } else {
                console.log('can not convert emoji', this.emojiAlt);
                this.isShowText = true;
                this.isEmoji = false;
              }
            },
          });
        } else {
          this.isShowText = true;
        }
      } else if (this.token.token == TOKEN_TYPE.EMOJI) {
        this.emojiAlt = this.token.text;
        if (this.emojiAlt) {
          this.isEmoji = true;
          //this.emojiSrc = twemoji.convert.toCodePoint(this.emojiAlt);
          twemoji.parse(this.emojiAlt, {
            callback: (icon, options) => {
              if (icon) {
                this.emojiSrc = icon;
              } else {
                console.log('can not convert emoji', this.emojiAlt);
                this.isShowText = true;
                this.isEmoji = false;
              }
            },
          });
        } else {
          this.isShowText = true;
        }
      } else if (this.token.token == TOKEN_TYPE.LINK) {
        this.isURLSameSite = this.token.text.startsWith(this.environmentService.site_base);
      }
    }
  }

  public linkGuard(e) {
    if (this.isURLSameSite) {
      e.preventDefault();
      let [base, url] = this.token.text.split(this.environmentService.site_base);
      if (url.startsWith('/')) {
        url = url.slice(1);
      }

      if (url) {
        let response = this.routerHandler.processUrl(url);

        this.routerHandler.navigate([response.routerResponse.url], {
          fragment: response.routerResponse.rawFragment,
        });
      }
    } else {
      if (!this.isURLTrusted) {
        e.preventDefault();
        var dialog = this.dialog.open(OutboundLinkDialogComponent, {
          data: { href: this.token.text, host: this.link.nativeElement.host },
          autoFocus: false,
        });
        dialog.afterClosed().subscribe((res) => {
          if (res) {
            this.isURLTrusted = true;
          }
        });
      }
      // else just let it navigate using the <a> tag natively
    }
  }
}
