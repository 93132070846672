import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GeneralAlertDialogComponent } from '../dialogs/general-alert-dialog/general-alert-dialog.component';
import { GeneralConfirmDialogComponent } from '../dialogs/general-confirm-dialog/general-confirm-dialog.component';
import { Observable } from 'rxjs';
import {
  GeneralInputDialogComponent,
  GeneralInputDialogData,
} from '../dialogs/general-input-dialog/general-input-dialog.component';
import { FileUploadConfirmDialogComponent } from '../dialogs/file-upload-confirm-dialog/file-upload-confirm-dialog.component';
import {
  WaitingDialogComponent,
  WaitingDialogData,
} from '../dialogs/waiting-dialog/waiting-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  public openAlertDialog(title: string, text: string, errorMessage: any = null): Observable<void> {
    let errorParam;

    if (errorMessage) {
      if (errorMessage instanceof Error) {
        errorParam = errorMessage.message;
      } else if (typeof errorMessage == 'string') {
        errorParam = errorMessage;
      } else {
        errorParam = JSON.stringify(errorMessage);
      }
    }

    var ref = this.dialog.open(GeneralAlertDialogComponent, {
      data: {
        title,
        text,
        errorMessage: errorParam,
      },
      autoFocus: false,
    });

    return ref.afterClosed();
  }

  public openConfirmDialog(
    title: string,
    text: string,
    okButtonText?: string,
    cancelButtonText?: string
  ): Observable<boolean> {
    var ref = this.dialog.open(GeneralConfirmDialogComponent, {
      data: { title, text, okButtonText, cancelButtonText },
      autoFocus: false,
    });

    return ref.afterClosed();
  }

  public openFileUploadConfirmDialog(
    files: any[]
  ): Observable<{ success: boolean; autoName: boolean }> {
    var ref = this.dialog.open(FileUploadConfirmDialogComponent, {
      data: { files },
      autoFocus: false,
    });

    return ref.afterClosed();
  }

  /**
   * Dialog for a simple string input field
   * @param {string} title Title of the dialog
   * @param {string} text Main text under the title
   * @param {string} input Default value for the input
   * @param {boolean} canBeEmpty if false, the dialog won't accept empty string
   * @returns
   */
  public openInputDialog(
    title: string,
    text: string,
    input: string = '',
    canBeEmpty: boolean = false
  ): Observable<string> {
    var ref = this.dialog.open(GeneralInputDialogComponent, {
      data: <GeneralInputDialogData>{ title, text, input, canBeEmpty },
    });

    return ref.afterClosed();
  }

  public openWaitingDialog(title: string, text: string): MatDialogRef<WaitingDialogComponent> {
    return this.dialog.open(WaitingDialogComponent, {
      data: <WaitingDialogData>{ title, text },
      autoFocus: false,
    });
  }
}
