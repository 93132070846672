import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, takeUntil, throttleTime } from 'rxjs';
import { RoomData } from 'src/app/shared/server-services/query-records/room-records';
import { RoomService } from 'src/app/shared/server-services/room.service';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from 'src/app/shared/server-services/subscription-event';
import { SubscriptionService } from 'src/app/shared/server-services/subscription.service';
import { PageTabService, PageTypes, SubPageTypes } from 'src/app/shared/services/page-tab.service';
@Component({
  selector: 'app-sidebar-granted-room',
  templateUrl: './sidebar-granted-room.component.html',
  styleUrls: ['./sidebar-granted-room.component.scss'],
})
export class SidebarGrantedRoomComponent implements OnDestroy, OnInit, OnChanges {
  @Input() roomId: string;
  @Input() rejected?: boolean = false;

  public roomData: RoomData;

  public avatar: ArrayBuffer;
  public name: string;
  public error: boolean;
  private loadError: boolean = false; // try to load the room for the first time
  public isMobile: boolean = false;

  protected acceptEvent = new Subject<boolean>();
  private destroyEvent = new Subject<void>();

  constructor(
    private roomService: RoomService,
    private subscriptionService: SubscriptionService,
    private deviceDetector: DeviceDetectorService,
    private pageTabService: PageTabService
  ) {
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.loadRoomData
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.loadRoomData
    );
  }
  ngOnInit(): void {
    this.isMobile = !this.deviceDetector.isDesktop();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadRoomData();

    this.acceptEvent
      .pipe(throttleTime(2000), takeUntil(this.destroyEvent))
      .subscribe((accepted: boolean) => {
        if (accepted) this.joinRoom();
        else this.leaveRoom();
      });
  }

  ngOnDestroy(): void {
    this.destroyEvent.next();

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.loadRoomData
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.loadRoomData
    );
  }

  private loadRoomData: () => Promise<void> = () => {
    if (!this.loadError) {
      return this.roomService
        .getRoom(this.roomId)
        .then((room) => {
          this.roomData = room.data;
          this.refreshState();
          return;
        })
        .catch((err) => {
          console.warn('granted room is not found', err);
          // something terrible happened with the room, so we can not load it
          // maybe the owner was deleted, and the room is left in the system
          // we can not let it in the granted section, because it will generate
          // a +1 news on the sidebar. So just call a leave
          this.roomService.leaveRoom(this.roomId).catch((err) => {
            console.warn('could not leave the room, maybe it is a deleted room', err, this.roomId);
          });
          this.loadError = true;
          return;
        });
    } else {
      return Promise.resolve(null);
    }
  };

  /**
   * Refresh all the view-based variables to trigger changes
   */
  private refreshState() {
    if (this.roomData) {
      if (this.roomData.decryptionError) {
        this.error = true;
        this.name = this.roomData.decryptionErrorMessage;
      } else {
        this.error = false;
        this.name = this.roomData.name;
        this.avatar = this.roomData.avatar;
      }
    }
  }

  public joinRoom() {
    this.roomService.joinRoom(this.roomId).then((res) => {
      console.log('done', res);
      // todo why do we need to wait before navigate
      // instant navigation cause permission error and the chat input does not load after navigation
      setTimeout(() => {
        this.pageTabService.openInCurrentTab({
          page: PageTypes.ROOM,
          subpage: SubPageTypes.CHAT,
          id: this.roomId,
          fragment: {},
        });
      }, 500);
    });
  }

  public leaveRoom() {
    this.roomService.leaveRoom(this.roomId).then((res) => {
      console.log('done', res);
    });
  }
}
