<h2 mat-dialog-title>
  <span *ngIf="!saleCode" translate>Create Sale Code</span>
  <span *ngIf="saleCode" translate>Edit Sale Code</span>
</h2>
<mat-dialog-content class="mat-typography">
  <div class="help-text" translate>Description can't be longer than 50 characters!</div>
  <form (submit)="createOrUpdate()" id="saleCodeForm" [formGroup]="saleCodeForm">
    <mat-form-field>
      <mat-label><span translate>Code description</span></mat-label>
      <input matInput formControlName="description" name="sale-code-description" type="text" />
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Close</span></button>
  <button mat-raised-button color="primary" type="submit" form="saleCodeForm" [disabled]="!saleCodeForm.valid">
    <span *ngIf="!saleCode" translate>Create</span>
    <span *ngIf="saleCode" translate>Update</span>
  </button>
</mat-dialog-actions>
