import { WorkspaceSubscriptionDialogueMessageEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import { dialogueMessagesQuery } from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onModifyDialogueMessageEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionDialogueMessageEventRecord) => {
    let messages = cache.getCacheDataByRequest({
      query: dialogueMessagesQuery,
      variables: {
        peerId: event.id,
      },
    });

    if (!messages) {
      messages = [];
      cache.setCacheDataByRequest(
        {
          query: dialogueMessagesQuery,
          variables: {
            peerId: event.id,
          },
        },
        messages
      );
    }

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id == event.messageId) {
        if (event.content) {
          messages[i].content = event.content;
        }
        if (event.edited) {
          messages[i].edited = event.edited;
        }
        if (event.replyTo) {
          messages[i].replyTo = event.replyTo;
        }
        if (event.decryptionError) {
          messages[i].decryptionError = event.decryptionError;
          messages[i].decryptionErrorMessage = event.decryptionErrorMessage;
        }
        if (event.reactionsAdded) {
          for (const [userId, emoji] of Object.entries(event.reactionsAdded)) {
            messages[i].reactions[userId] = emoji;
          }
        }
        if (event.reactionsRemoved) {
          event.reactionsRemoved.forEach((userId) => {
            delete messages[i].reactions[userId];
          });
        }
        break;
      }
    }
  };
