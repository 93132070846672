<h2 mat-dialog-title translate>License tokens</h2>
<mat-dialog-content class="mat-typography">
  <p id="sub-text" translate>Copy your Nano license token into the input field to redeem your new Nano license.</p>
  <form (submit)="redeem()" [formGroup]="redeemForm" id="redeemForm">
    <mat-form-field>
      <mat-label><span translate>License token</span></mat-label>
      <input matInput formControlName="redeemCode" name="nano-license-token" type="text" />
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Close</span></button>
  <button mat-raised-button color="primary" form="redeemForm" type="submit" [disabled]="!redeemForm.valid"><span translate>Redeem</span></button>
</mat-dialog-actions>
