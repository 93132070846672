<h2 mat-dialog-title [ngSwitch]="currentMode">
  <span translate *ngSwitchCase="dialogModes.PROVIDE">Enter Nano Client password</span>
  <span translate *ngSwitchCase="dialogModes.PROVIDE_FOR_SESSION">Enter Nano Client password</span>
  <span translate *ngSwitchCase="dialogModes.CHANGE">Change Nano Client password</span>
  <span translate *ngSwitchCase="dialogModes.FIRST">Set-up Nano Client password</span>
</h2>
<form id="admin-password-form" [formGroup]="passwordForm">
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="currentMode === dialogModes.PROVIDE || currentMode === dialogModes.PROVIDE_FOR_SESSION">
      <div class="dialog-text" translate>This is the password you've set up during your Nano Client configuration.</div>
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label><span translate>Password</span></mat-label>
          <input matInput required name="nano-webapp-admin-password" autocomplete="new-password" type="password" formControlName="password" />
          <mat-error *ngIf="passwordForm.get('password').errors?.['required']"><span translate>Password can not be empty.</span></mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="currentMode === dialogModes.CHANGE || currentMode === dialogModes.FIRST">
      <div class="dialog-text" translate>Please enter your new Nano Client password. Please note that this will be your Nano Client password, <b translate>not</b> your Webapp password.</div>
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label translate>Password</mat-label>
          <input matInput required name="nano-webapp-again-admin-password" autocomplete="new-password" type="password" formControlName="passwordAgain" />
          <mat-error *ngIf="passwordForm.get('passwordAgain').errors?.['required']"><span translate>Password can not be empty.</span></mat-error>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label><span translate>Password again</span></mat-label>
          <input matInput required name="nano-webapp-new-admin-password" autocomplete="new-password" type="password" formControlName="password" />
          <mat-error *ngIf="passwordForm.get('password').errors?.['required']"><span translate>Password confirm can not be empty.</span></mat-error>
          <mat-error *ngIf="passwordForm.get('password').errors?.['controlEquals'] && !passwordForm.get('password').errors?.['required']"><span translate>Passwords must match.</span></mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-raised-button color="primary" [disabled]="!passwordForm.valid" (click)="setPassword()"><span translate>OK</span></button>
  </mat-dialog-actions>
</form>
