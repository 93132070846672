import { Component, INJECTOR, Inject, Injector } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

@Component({
  selector: 'app-filename-filter-dialog',
  templateUrl: './filename-filter-dialog.component.html',
  styleUrls: ['./filename-filter-dialog.component.scss'],
})
export class FilenameFilterDialogComponent extends NanoDialogBaseComponent<FilenameFilterDialogComponent> {
  filteredFileNameGroup = new FormGroup({
    fileName: new FormControl<string | null>('', [
      Validators.required,
      Validators.maxLength(255),
      this.filterWindowsCharactersRegex,
    ]),
  });

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: { originalFileName: string },
    public translate: TranslateService
  ) {
    super(injector);
    this.filteredFileNameGroup
      .get('fileName')
      .setValue(this.filterWindowsFilename(data.originalFileName));
  }

  public filterWindowsFilename(name: string): string {
    let windowsCharactersRegex = /[<>:"\/\\|?*\x00-\x1F]/g;
    let reservedNamesRegex = /^(CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])$/i;
    let trailingInvalidCharactersRegex = /[.\s]+$/;
    return name
      .replace(windowsCharactersRegex, '')
      .replace(reservedNamesRegex, '')
      .replace(trailingInvalidCharactersRegex, '');
  }

  private filterWindowsCharactersRegex(control: AbstractControl): ValidationErrors | null {
    let windowsCharactersRegex = /[<>:"\/\\|?*\x00-\x1F]/g;
    let reservedNamesRegex = /^(CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])$/i;
    let trailingInvalidCharactersRegex = /[.\s]+$/;

    if (windowsCharactersRegex.test(control.value)) return { windowsCharacterError: true };

    if (reservedNamesRegex.test(control.value)) return { filteredNameError: true };

    if (trailingInvalidCharactersRegex.test(control.value))
      return { trailingInvalidCharactersError: true };
  }

  public submit(): void {
    const newFileName = this.filteredFileNameGroup.get('fileName').value;
    this.dialogRef.close(newFileName);
  }
}
