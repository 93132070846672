/**
 * For account-stored keys by using the private keyring.
Encryption of the master key of an account for example.
 */

import { CryptoContext } from '../base';
import { PrivateKeyConfigDumpArgs, PrivateKeyConfigLoadArgs } from './args';
import { PrivateKeyConfigV1 } from './v1';

export let PRIVATE_KEY_CRYPTO_CONTEXT = new CryptoContext<
  PrivateKeyConfigLoadArgs,
  PrivateKeyConfigDumpArgs
>([new PrivateKeyConfigV1()]);
