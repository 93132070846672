import {
  accountAvatarsQuery,
  dialogueMessagesByIdQuery,
  dialogueMessagesQuery,
  getAccountQuery,
  getDialogueDetailQuery,
  getRoomDatasQuery,
  getRoomDetailQuery,
  getRoomWithDataQuery,
  nanoRequestQuery,
  roomMessagesByIdQuery,
  roomMessagesQuery,
  workspaceQuery,
} from '../../../../../server-services/querys';
import { ResponseMergerType } from '../../cache-logic-interface';
import { customAccountAvatarsQueryMerger } from './sub-merger/account-avatars-query-merger';
import { customDialogueDetailQueryMerger } from './sub-merger/dialogue-detail-query-merger';
import { customDialogueMessageQueryMerger } from './sub-merger/dialogue-message-query-merger';
import { customDialogueMessagesByIdQueryMerger } from './sub-merger/dialogue-messages-by-id-query-merger';
import { customNanoRequestQueryMerger } from './sub-merger/nano-request-query-merger';
import { customRoomDatasQueryMerger } from './sub-merger/room-datas-query-merger';
import { customRoomDetailQueryMerger } from './sub-merger/room-detail-query-merger';
import { customRoomMessageQueryMerger } from './sub-merger/room-message-query-merger';
import { customRoomMessagesByIdQueryMerger } from './sub-merger/room-messages-by-id-query-merger';
import { customWorkspaceQueryMerger } from './sub-merger/workspace-query-merger';
import { customRoomWithDataQueryMerger } from './sub-merger/room-with-data-query-merger';
import { customGetAccountQueryMerger } from './sub-merger/get-account-query-merger';

export let customQueryMerger: ResponseMergerType = {
  default: function (cache, request, response) {
    cache.setCacheDataByRequest(request, response);
  },
  [roomMessagesQuery.endpoint]: customRoomMessageQueryMerger,
  [dialogueMessagesQuery.endpoint]: customDialogueMessageQueryMerger,
  [roomMessagesByIdQuery.endpoint]: customRoomMessagesByIdQueryMerger,
  [dialogueMessagesByIdQuery.endpoint]: customDialogueMessagesByIdQueryMerger,
  [getRoomDetailQuery.endpoint]: customRoomDetailQueryMerger,
  [getDialogueDetailQuery.endpoint]: customDialogueDetailQueryMerger,
  [accountAvatarsQuery.endpoint]: customAccountAvatarsQueryMerger,
  [getAccountQuery.endpoint]: customGetAccountQueryMerger,
  [workspaceQuery.endpoint]: customWorkspaceQueryMerger,
  [nanoRequestQuery.endpoint]: customNanoRequestQueryMerger,
  /**
   * get a specific room with all properties
   */
  [getRoomWithDataQuery.endpoint]: customRoomWithDataQueryMerger,
  /**
   * get the data property for a list of room
   */
  [getRoomDatasQuery.endpoint]: customRoomDatasQueryMerger,
};
