import { Component, Inject, Injector, INJECTOR } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AccountService } from '../../server-services/account.service';
import { DialogueService } from '../../server-services/dialogue.service';
import { AccountAvatarRecord } from '../../server-services/query-records/account-records';
import { createContactQuery, getContactListIdsQuery } from '../../server-services/querys';
import { DialogService } from '../../services/dialog.service';
import { RouterHandler } from '../../services/router-handler.service';
import { ServerRestApiService } from '../../services/server-rest-api.service';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent extends NanoDialogBaseComponent<ContactDialogComponent> {
  public user: AccountAvatarRecord;
  public addContactInProgress: boolean = false;
  public addContactDone: boolean = false;
  public isThisMe: boolean = false;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    public data: AccountAvatarRecord,
    private dialogueService: DialogueService,
    private routerHandler: RouterHandler,
    private dialogService: DialogService,
    private serverRestApiService: ServerRestApiService,
    private accountService: AccountService
  ) {
    super(injector);
    this.user = data;
    if (!this.user) {
      this.dialogRef.close();
    }

    this.accountService.getMe().then((me) => {
      this.isThisMe = me.id == this.user.id;
    });

    this.serverRestApiService
      .query({
        query: getContactListIdsQuery,
      })
      .then((contactList: string[]) => {
        this.addContactDone = contactList.includes(this.user.id);
      });
  }

  public close() {
    this.dialogRef.close();
  }

  public addContact() {
    if (!this.addContactDone && !this.addContactInProgress) {
      this.addContactInProgress = true;
      this.serverRestApiService
        .mutate({
          query: createContactQuery,
          variables: {
            peerId: this.user.id,
          },
        })
        .then((res) => {
          this.addContactDone = true;
        })
        .catch(() => {
          this.dialogService.openAlertDialog(
            marker('Error'),
            marker('Error happened during the operation.')
          );
        })
        .finally(() => {
          this.addContactInProgress = false;
        });
    }
  }

  public openChat() {
    this.dialogueService
      .getData(this.user.id)
      .then((dialogueData) => {
        // there is already a dialogue for this user
        this.routerHandler.navigate(['room', this.user.id, 'chat', 'private']);
        this.dialogRef.close();
      })
      .catch((err) => {
        this.dialogService.openAlertDialog(
          marker('Error'),
          marker('Error happened during the operation')
        );
      });
  }
}
