<div class="attached-file-url" [appEnterTheViewportNotifier] (visibilityChange)="visibilityChange($event)" (contextmenu)="onContextMenu($event)" appLongPress="" (longPress)="onContextMenu($event)">
  <div class="preview" *ngIf="img0 || preview || (fileUrl.width && fileUrl.height)">
    <div class="placeholder" [ngStyle]="dim" (click)="openFile()">
      <mat-icon *ngIf="!img0 && !preview">image</mat-icon>
      <div *ngIf="img0 || preview" class="result-thumbnail">
        <app-blob-viewer [blob]="preview || img0" type="image"></app-blob-viewer>
      </div>
    </div>
  </div>

  <div *ngIf="!img0 && !preview && !fileUrl.width && !fileUrl.height" class="file-box" (click)="openDetails()">
    <span class="big-mdi mdi mdi-{{ file.icon }} file-{{ file.icon }}"></span>
    <div class="file-info">
      <span class="file-name" [matTooltip]="file.fullName" [matTooltipClass]="['no-translate']">{{ file.name }}</span>
      <div class="file-details" mat-small>
        <span>
          <span class="size" *ngIf="isLoaded && isFileValid">{{ file.size | formatBytes }}</span>
          <span class="size" *ngIf="!isLoaded && isFileValid" translate>unknown</span>
          <span class="warn" *ngIf="isLoaded && !isFileValid && !fileCheckError" translate>File not found</span>
          <span
            [matTooltip]="'Could not check the file. It can be a request error or the current Nano version is not support the file peek operation.' | translate"
            *ngIf="isLoaded && fileCheckError"
            translate
            >unknown</span
          >
        </span>
        <span class="ext">{{ file.ext }}</span>
      </div>
    </div>
  </div>

  <div class="path-string">
    <a (click)="openPathString()">{{ pathString }}</a>
  </div>
</div>
