import { RoomWithData } from '../../../../../../../../server-services/query-records/room-records';
import { WorkspaceSubscriptionRoomPermissionEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import { getRoomQuery } from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onCreateRoomPermissionEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionRoomPermissionEventRecord) => {
    // if you have this room in the cache, you did not get
    // live events until the "create" room permission event
    // (or it was in "not available" state)
    // so now reset the data attribute, and the container component can refetch it
    let room: RoomWithData = cache.getCacheDataByRequest({ query: getRoomQuery, variables: {} });
    if (room) {
      if (room.pinnedKey) {
        room.data = undefined;
      }
    }
  };
