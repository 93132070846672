import { Component, Inject, INJECTOR, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';

@Component({
  selector: 'app-change-contact-policy-dialog',
  templateUrl: './change-contact-policy-dialog.component.html',
  styleUrls: ['./change-contact-policy-dialog.component.scss'],
})
export class ChangeContactPolicyDialogComponent
  extends NanoDialogBaseComponent<ChangeContactPolicyDialogComponent>
  implements OnInit
{
  policyForm: UntypedFormGroup = this.fb.group({
    policy: this.fb.control(0, [Validators.required]),
  });

  constructor(
    @Inject(INJECTOR) injector: Injector,
    public fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public currentPolicy: number
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.policyForm.get('policy').setValue(this.currentPolicy);
  }

  changePolicy(): void {
    this.dialogRef.close(this.policyForm.get('policy').value);
  }
}
