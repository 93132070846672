<textarea
  #editor
  [(ngModel)]="value"
  [disabled]="disabled"
  [placeholder]="placeholder"
  (change)="change($event.target['value'])"
  (focus)="focusChange(true)"
  (blur)="focusChange(false)"
  (input)="onInput()"
></textarea>
<div #invisibleBox class="invisible-box">{{ value }}</div>
