import { CryptoContext } from '../../base';
import { AccountRoomKeyConfigDumpArgs, AccountRoomKeyConfigLoadArgs } from './args';
import { AccountRoomKeyConfigV1 } from './v1';

/**
 * For account room keys by using the account keyring.
 */
export let ACCOUNT_ROOM_KEY_CRYPTO_CONTEXT = new CryptoContext<
  AccountRoomKeyConfigLoadArgs,
  AccountRoomKeyConfigDumpArgs
>([new AccountRoomKeyConfigV1()]);
