import {
  DialogueDetail,
  MessageRecord,
} from '../../../../../../server-services/query-records/room-records';
import { ResponseMergerHandler } from '../../../cache-logic-interface';
import { SeenCacheRecord } from '../../../custom-cache-reset-logic';

export let customDialogueDetailQueryMerger: ResponseMergerHandler = function (
  cache,
  request,
  response: DialogueDetail
) {
  let cacheKey = cache.getCacheLogic().makeCacheDataPath(request);
  cache.setCacheData(cacheKey, response);

  let cacheInsert: SeenCacheRecord = {
    messages: {},
    users: {},
  };

  if (response.peerSeenId) {
    cacheInsert.users[response.id] = response.peerSeenId; // save userId->messageId
    cacheInsert.messages[response.peerSeenId] = [response.id]; // save messageId->[userId]
  }

  cache.getCacheData('seen')[request.variables.id] = cacheInsert;
};
