import {
  accountAvatarsQuery,
  dialogueMessagesByIdQuery,
  dialogueMessagesQuery,
  getAllAccountIdQuery,
  getAllRoomQuery,
  getAllSidebarGroupQuery,
  getRoomDatasQuery,
  getSidebarGrantedRoomsQuery,
  getSidebarNewsQuery,
  getSidebarRejectedRoomsQuery,
  getSidebarUngrouppedQuery,
  messageQuery,
  roomConfigQuery,
  roomMessagesQuery,
} from '../../../server-services/querys';
import { CacheDataPath, CacheDataPathGeneratorType } from './cache-logic-interface';

let cacheKeyGenerators: {
  [query: string]: (variables?) => CacheDataPath;
} = {
  [roomMessagesQuery.endpoint]: (variables) => variables.roomId,
  [roomConfigQuery.endpoint]: (variables) => variables.id,
  [dialogueMessagesByIdQuery.endpoint]: (variables) => variables.peerId,
  [getAllRoomQuery.endpoint]: () => '',
  [getAllAccountIdQuery.endpoint]: () => '',
  [getAllSidebarGroupQuery.endpoint]: () => '',
  [getSidebarUngrouppedQuery.endpoint]: () => '',
  [getSidebarGrantedRoomsQuery.endpoint]: () => '',
  [getSidebarRejectedRoomsQuery.endpoint]: () => '',
  [getSidebarNewsQuery.endpoint]: () => '',
  [getRoomDatasQuery.endpoint]: (variables) => variables.ids.toString(), // make a unique id for the cacheKey to avoid response cb handler collision ,
  [accountAvatarsQuery.endpoint]: (variables) => variables.ids.toString(),
  [messageQuery.endpoint]: (variables) => ['messages', variables.resourceId, variables.messageId],
  [dialogueMessagesQuery.endpoint]: (variables) => variables.peerId,
  default: (variables) => (variables.id ? variables.id : ''),
};

export let customCacheKeyGeneratorLogic: CacheDataPathGeneratorType = function (request) {
  let key;
  if (request.query.endpoint in cacheKeyGenerators) {
    key = cacheKeyGenerators[request.query.endpoint](request.variables);
  } else {
    key = cacheKeyGenerators.default(request.variables);
  }

  if (typeof key == 'string') {
    return request.query.operation + '.' + request.query.endpoint + '.' + key;
  } else {
    return key;
  }
};
