import {
  LoadMessageDirection,
  MessageRecord,
  MessageRecordPlaceholder,
  MessageRecordType,
} from '../../../../../../server-services/query-records/room-records';
import { ResponseMergerHandler } from '../../../cache-logic-interface';

export let customRoomMessageQueryMerger: ResponseMergerHandler = function (
  cache,
  request,
  response: (MessageRecordPlaceholder | MessageRecord)[]
) {
  // quick access to message
  if (!cache.getCacheData('messages')[request.variables.roomId]) {
    cache.getCacheData('messages')[request.variables.roomId] = {};
  }

  /*if (variables.pivot == null) {
          cache.set(cacheKey, response);
        } else {
          cache.set(cacheKey, cache.get(cacheKey).concat(response));
        }*/
  let cacheKey = cache.getCacheLogic().makeCacheDataPath(request);

  let cachedMessages: any[] = cache.getCacheData(cacheKey);

  if (!cachedMessages?.length) {
    cachedMessages = [
      { type: MessageRecordType.END },
      { type: MessageRecordType.VOID },
      { type: MessageRecordType.END },
    ];
    cache.setCacheData(cacheKey, cachedMessages);
  }

  // init cachedMessages
  response.forEach((msg) => {
    if ((<MessageRecord>msg)?.id) {
      cache.getCacheData('messages')[request.variables.roomId][(<MessageRecord>msg)?.id] = msg;
    }
  });

  messageStreamMerger(cachedMessages, request, response);

  // sets beforeMsgId and afterMsgId on voids
  messageStreamVoidFixer(cachedMessages);
};

export const messageStreamVoidFixer = (cachedMessages: any[]) => {
  for (let i = 0; i < cachedMessages.length; i++) {
    if (cachedMessages[i]?.type === MessageRecordType.VOID) {
      cachedMessages[i].beforeMsgId = cachedMessages[i - 1]?.id;
      cachedMessages[i].afterMsgId = cachedMessages[i + 1]?.id;
    }
  }
};

export const messageStreamMerger = (cachedMessages: any[], request, response) => {
  const pivot = request.variables?.pivot;

  if (request.variables?.direction === LoadMessageDirection.BEFORE) {
    mergeMessageBefore(response, cachedMessages, pivot);
  } else {
    // request.variables?.direction === 'after' // as default
    mergeMessageAfter(response, cachedMessages, pivot);
  }
};

const mergeMessageBefore = (response, cachedMessages, pivot) => {
  response.forEach((res) => {
    let pivotIndex = cachedMessages.length - 1;
    if (pivot) {
      const foundIndex = cachedMessages.findIndex((msg) => msg.id === pivot);
      if (foundIndex >= 0 && foundIndex < cachedMessages.length - 1) {
        pivotIndex = foundIndex;
      }
    }
    if (pivotIndex === cachedMessages.length - 1) {
      for (let i = cachedMessages.length - 1; i >= 0; i--) {
        if (cachedMessages[i]?.type === MessageRecordType.VOID) {
          if (cachedMessages[i - 1]?.id === res.id) {
            cachedMessages.splice(i, 1);
          } else {
            cachedMessages.splice(i, 0, res);
          }
          break;
        }
        if (res.id === cachedMessages[i]?.id) {
          break;
        }
        if (res.id < cachedMessages[i]?.id) {
          cachedMessages.splice(i, 0, res);
          break;
        }
      }
    } else {
      for (let i = pivotIndex; i >= 0; i--) {
        // [end void msg3 msg2 msg1]

        if ((<MessageRecord>res)?.id === cachedMessages[i].id) {
          // tüntesse el voidot pivot és msg között
          if (pivotIndex > i && cachedMessages[i + 1]?.type === MessageRecordType.VOID) {
            cachedMessages.splice(i + 1, 1);
          }
          break;
        }

        if ((<MessageRecord>res)?.id < cachedMessages[i].id) {
          // insert message
          if (pivotIndex > i && cachedMessages[i + 1]?.type === MessageRecordType.VOID) {
            // insert after the void [...msg10 void >newmsg< msg8...]
            cachedMessages.splice(i + 2, 0, res);
          } else {
            // insert after the element
            // [...msg10 >newmsg< msg8]
            cachedMessages.splice(i + 1, 0, res);
          }
          break;
        }

        if (
          cachedMessages[i]?.type === MessageRecordType.END &&
          cachedMessages[i + 1]?.type === MessageRecordType.VOID
        ) {
          // insert to the start
          // [end void >newmsg< msg]
          cachedMessages.splice(i + 2, 0, res);
          break;
        }
      }
    }
  });
  if (!response?.length) {
    let pivotIndex = cachedMessages.length - 1;
    if (pivot !== pivotIndex) {
      const foundIndex = cachedMessages.findIndex((msg) => msg.id === pivot);
      if (foundIndex < cachedMessages.length - 1) {
        pivotIndex = foundIndex;
      }
    }
    if (cachedMessages?.[pivotIndex - 1]?.type === MessageRecordType.VOID) {
      cachedMessages.splice(pivotIndex - 1, 1);
    }
  }
};

const mergeMessageAfter = (response, cachedMessages, pivot) => {
  response.forEach((res) => {
    let pivotIndex = 0;
    if (pivot) {
      const foundIndex = cachedMessages.findIndex((msg) => msg.id === pivot);
      if (foundIndex > 0) {
        pivotIndex = foundIndex;
      }
    }

    if (!pivotIndex) {
      for (let i = 0; i < cachedMessages.length; i++) {
        if (cachedMessages[i]?.type === MessageRecordType.VOID) {
          if (cachedMessages[i + 1]?.id === res.id) {
            cachedMessages.splice(i, 1);
          } else {
            cachedMessages.splice(i, 0, res);
          }
          break;
        }
        if (res.id === cachedMessages[i]?.id) {
          break;
        }
        if (res.id > cachedMessages[i]?.id) {
          cachedMessages.splice(i, 0, res);
          break;
        }
      }
    } else {
      for (let i = pivotIndex; i < cachedMessages.length; i++) {
        if ((<MessageRecord>res)?.id === cachedMessages[i].id) {
          // tüntesse el voidot pivot és msg között
          if (pivotIndex < i && cachedMessages[i - 1]?.type === MessageRecordType.VOID) {
            cachedMessages.splice(i - 1, 1);
          }
          break;
        }
        if ((<MessageRecord>res)?.id > cachedMessages[i].id) {
          // tüntesse el voidot pivot és msg között
          // insert message
          if (pivotIndex < i && cachedMessages[i - 1]?.type === MessageRecordType.VOID) {
            cachedMessages.splice(i - 1, 0, res);
          } else {
            cachedMessages.splice(i, 0, res);
          }
          break;
        }

        if (
          cachedMessages[i]?.type === MessageRecordType.END &&
          cachedMessages[i - 1]?.type === MessageRecordType.VOID
        ) {
          // insert to the start
          // [end void >newmsg< msg]
          cachedMessages.splice(i - 1, 0, res);
          break;
        }
      }
    }
  });
  if (!response?.length) {
    let pivotIndex = 0;
    if (pivot) {
      const foundIndex = cachedMessages.findIndex((msg) => msg.id === pivot);
      if (foundIndex > 0) {
        pivotIndex = foundIndex;
      }
    }
    if (cachedMessages?.[pivotIndex + 1]?.type === MessageRecordType.VOID) {
      cachedMessages.splice(pivotIndex + 1, 1);
    }
  }
};
