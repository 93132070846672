import { MeRecord } from '../../../../../../../../server-services/query-records/account-records';
import { ID } from '../../../../../../../../server-services/query-records/common-records';
import {
  ERROR_NOT_AVAILABLE,
  RoomMyPermissionRecord,
  RoomRecord,
  RoomWithData,
} from '../../../../../../../../server-services/query-records/room-records';
import { WorkspaceSubscriptionRoomAccountPermissionEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getMeQuery,
  getRoomDetailQuery,
  getRoomQuery,
  getSidebarGrantedRoomsQuery,
  getSidebarRejectedRoomsQuery,
  roomMyPermissionsQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onDeleteRoomAccountPermissionEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionRoomAccountPermissionEventRecord) => {
    if (
      (<MeRecord>cache.getCacheDataByRequest({ query: getMeQuery, variables: {} })).id ==
      event.accountId
    ) {
      let room: RoomRecord | RoomWithData = cache.getCacheDataByRequest({
        query: getRoomQuery,
        variables: {
          id: event.id,
        },
      });
      if (room) {
        (<RoomWithData>room).data = {
          decryptionError: true,
          decryptionErrorMessage: ERROR_NOT_AVAILABLE, // TODO this can override the prev error
        };
        room['__refetch'] = true;

        let perm: RoomMyPermissionRecord = cache.getCacheDataByRequest({
          query: roomMyPermissionsQuery,
          variables: {
            id: event.id,
          },
        });

        if (perm) {
          perm.account = null;
        } // else just wait for the first query
      }
    }

    let room: RoomWithData = cache.getCacheDataByRequest({ query: getRoomQuery, variables: {} });

    if (room) {
      room.inaccessible = true;
      room.data = {
        decryptionError: true,
        decryptionErrorMessage: ERROR_NOT_AVAILABLE,
      };
    }

    // remove from rejected or granted
    let grantedRooms: ID[] = cache.getCacheDataByRequest({
      query: getSidebarGrantedRoomsQuery,
      variables: {},
    });
    let rejectedRooms: ID[] = cache.getCacheDataByRequest({
      query: getSidebarRejectedRoomsQuery,
      variables: {},
    });

    let roomIndex = grantedRooms.findIndex((el) => el == event.id);
    if (roomIndex > -1) {
      grantedRooms.splice(roomIndex, 1);
    }
    roomIndex = rejectedRooms.findIndex((el) => el == event.id);
    if (roomIndex > -1) {
      rejectedRooms.splice(roomIndex, 1);
    }

    if (cache.getCacheData('seen')[event.id]) {
      // remove so after rejoin we will refetch the recent seen data
      cache.deleteCacheDataByRequest({ query: getRoomDetailQuery, variables: { id: event.id } });
      // we don't need this anymore, clean up the cache
      delete cache.getCacheData('seen')[event.id];
    }
  };
