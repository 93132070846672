import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppStorage } from 'src/app/shared/app-storage';
import { AuthService } from 'src/app/shared/server-services/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NativeAppService } from 'src/app/shared/services/native-app.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { styleChangedObserver } from 'src/app/shared/shared-observers';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public isPending: boolean = false;
  public isLoginSuccess: boolean = false;
  public isOnApp = false;
  public isMobile = false;
  public isTablet = false;
  public isDarkTheme: boolean = true;

  loginForm = new FormGroup({
    email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
    password: new FormControl<string | null>(null, [Validators.required]),
    rememberMe: new FormControl<boolean>(false),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
    private titleService: TitleService,
    private nativeAppService: NativeAppService,
    private deviceDetector: DeviceDetectorService
  ) {
    this.isOnApp = this.nativeAppService.isOnApp();
    this.titleService.setCurrentTabTitle(marker('Login'));

    this.isMobile = this.deviceDetector.isMobile();
    this.isTablet = this.deviceDetector.isTablet();

    this.isDarkTheme = AppStorage.getItem('style_mode') == 'dark';
    styleChangedObserver.subscribe((style) => {
      this.isDarkTheme = style == 'dark';
    });
  }

  public startLogin() {
    if (this.isPending) {
      console.warn('operation is already in progress');
      return;
    }

    const email = this.loginForm.get('email').value;
    const password = this.loginForm.get('password').value;
    const rememberMe = this.isOnApp || this.loginForm.get('rememberMe').value;

    this.isPending = true;
    this.authService
      .login(email, password, undefined, undefined, undefined, rememberMe)
      .then(() => {
        console.log('login OK');
        this.isPending = false;
        this.isLoginSuccess = true;
        this.router.navigate(['/']);
      })
      .catch((err) => {
        console.log('login FAIL', err);
        this.isLoginSuccess = false;
        this.isPending = false;
        if (err.success === false) {
          this.dialogService.openAlertDialog(
            marker('Login Error'),
            marker('Invalid credentials. Please try again.'),
            err.err.message
          );
        } else if (err.serverError === true) {
          this.dialogService.openAlertDialog(
            marker('Login Error'),
            marker('Can not connect to the server. Please try again later.'),
            err.err.message
          );
        } else if (err.clientError === true) {
          this.dialogService.openAlertDialog(
            marker('Login Error'),
            marker(
              'Client can not setup the user workspace. Please try again. If you can not login please contact our support team.'
            ),
            err.err.message
          );
        }
      });
  }
}
