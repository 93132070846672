import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Observable } from 'rxjs';
import { AuthService } from '../server-services/auth.service';
import { DialogService } from '../services/dialog.service';
import { DownloadManagerService } from '../services/download-manager-service';

@Injectable({
  providedIn: 'root',
})
export class LogoutPromptGuard  {
  private confirmTitle: string = marker('Logging out');
  private confirmText: string = marker('Are you sure you want to log out?');

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private authService: AuthService,
    private downloadManagerService: DownloadManagerService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let mainPromise: Promise<boolean | UrlTree> = new Promise((resolve) => {
      if (this.authService.isAnonym()) {
        resolve(this.router.parseUrl('/login'));
      } else {
        this.dialogService
          .openConfirmDialog(this.confirmTitle, this.confirmText)
          .subscribe((confirmed) => {
            if (confirmed) {
              this.downloadManagerService.clear();
              this.authService.logout().then(() => {
                console.log('logout OK');
                resolve(true);
              });
            } else {
              resolve(false);
            }
          });
      }
    });

    return mainPromise;
  }
}
