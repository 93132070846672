import { Injectable } from '@angular/core';
import { environment } from './environment';
import { IEnvironment } from './environment-base';
@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements IEnvironment {
  get production() {
    return environment.production;
  }

  get site_base() {
    return environment.site_base;
  }

  get url_api() {
    return environment.url_api;
  }

  get url_ws() {
    return environment.url_ws;
  }

  get url_sws() {
    return environment.url_sws;
  }

  get url_landing() {
    return environment.url_landing;
  }

  get beta() {
    return environment.beta;
  }

  get url_office_editor() {
    return environment.url_office_editor;
  }

  get cookie_url() {
    return environment.cookie_url;
  }

  get ws_timeout() {
    return environment.ws_timeout;
  }

  constructor() {}

  get live_muc_version_url() {
    return this.live_muc_version_url;
  }

  public get_live_jitsi_lib_url(version) {
    return environment.get_live_jitsi_lib_url(version);
  }

  get live_jitsi_lib_fallback_url() {
    return environment.live_jitsi_lib_fallback_url;
  }
}
