import {
  dialogueMessagesQuery,
  getNanoLicensesQuery,
  getSeenIdsByMessageQuery,
  messageQuery,
  roomMessagesQuery,
} from '../../../../../server-services/querys';
import { ResponseManipulatorType } from '../../cache-logic-interface';
import { dialogueMessageQueryManipulator } from './sub-manipulator/dialogue-messages-query-manipulator';
import { getSeenIdsByMessageQueryManipulator } from './sub-manipulator/get-seen-ids-by-message-query-manipulator';
import { licenseQueryManipulator } from './sub-manipulator/license-query-manipulator';
import { messageQueryManipulator } from './sub-manipulator/message-query-manipulator';
import { roomMessagesQueryManipulator } from './sub-manipulator/room-messages-query-manipulator';

export let customQueryManipulator: ResponseManipulatorType = {
  default: (cache, cacheKey, request, serverResponse?) => {
    if (serverResponse !== undefined) return serverResponse;
    return cache.getCacheData(cacheKey);
  },
  [roomMessagesQuery.endpoint]: roomMessagesQueryManipulator,
  [dialogueMessagesQuery.endpoint]: dialogueMessageQueryManipulator,
  [getSeenIdsByMessageQuery.endpoint]: getSeenIdsByMessageQueryManipulator,
  [messageQuery.endpoint]: messageQueryManipulator,
  [getNanoLicensesQuery.endpoint]: licenseQueryManipulator,
};
