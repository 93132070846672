import { SafeRadix32 } from 'src/app/shared/safe-radix32';
import { ID } from 'src/app/shared/server-services/query-records/common-records';
import { AccountAvatarRecord } from '../../../../../../server-services/query-records/account-records';
import { getAccountQuery, getAllAccountIdQuery } from '../../../../../../server-services/querys';
import { ResponseMergerHandler } from '../../../cache-logic-interface';

let idToTime = (id: ID) => {
  return SafeRadix32.decodeSafeRadix32(id.slice(0, -5));
};

export let generateColor = (id: ID) => {
  // determining color number between 1-6
  return (idToTime(id) % 6) + 1;
};

export let customAccountAvatarsQueryMerger: ResponseMergerHandler = function (
  cache,
  request,
  response: AccountAvatarRecord[]
) {
  let allAccountIds = cache.getCacheDataByRequest({ query: getAllAccountIdQuery, variables: {} });

  response.forEach((accountAvatar) => {
    accountAvatar.color = generateColor(accountAvatar.id);

    let cacheKey = cache
      .getCacheLogic()
      .makeCacheDataPath({ query: getAccountQuery, variables: { id: accountAvatar.id } });
    cache.setCacheData(cacheKey, accountAvatar);
    if (!allAccountIds.includes(accountAvatar.id)) {
      cache
        .getCacheDataByRequest({ query: getAllAccountIdQuery, variables: {} })
        .push(accountAvatar.id);
    }
  });
};
