import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResizedEvent } from 'src/app/shared/directives/resized.directive';
import { PageInfo, PageTypes } from 'src/app/shared/services/page-tab.service';

@Component({
  selector: 'app-page-tab',
  templateUrl: './page-tab.component.html',
  styleUrls: ['./page-tab.component.scss'],
})
export class PageTabComponent implements OnInit {
  @Input() data?: PageInfo;
  @Input() active?: boolean;
  @Output() tabClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() pageInfoChange: EventEmitter<PageInfo> = new EventEmitter<PageInfo>();

  public isRoom: boolean = false;
  public isDialogue: boolean = false;
  public isEmpty: boolean = false;
  public isCompactView: boolean = false;
  public isLive: boolean = false;

  ngOnInit(): void {
    switch (this.data.page) {
      case PageTypes.ROOM:
        this.isRoom = true;
        break;
      case PageTypes.PRIVATE_CHAT:
        this.isDialogue = true;
        break;
      case PageTypes.LIVE:
        this.isLive = true;
        break;
      default:
        this.isEmpty = true;
        break;
    }
  }

  closeTab() {
    this.tabClose.emit();
  }

  changePageInfo() {
    this.pageInfoChange.emit();
  }

  onResized(event: ResizedEvent) {
    this.isCompactView = event.newRect.width < 600;
  }
}
