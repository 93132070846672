import { WorkspaceSubscriptionAccountContactEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import { getContactListIdsQuery } from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onDeleteAccountContactSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionAccountContactEventRecord
) => {
  let contactList: string[] = cache.getCacheDataByRequest({
    query: getContactListIdsQuery,
    variables: {},
  });

  for (let i = 0; i < contactList.length; i++) {
    if (contactList[i] == event.peerId) {
      contactList.splice(i, 1);
      break;
    }
  }
};
