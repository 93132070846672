import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { AppStorage } from '../app-storage';
import { AuthService } from '../server-services/auth.service';
import { editPushData } from '../server-services/querys';
import { NativeAppService } from './native-app.service';
import { ServerRestApiService } from './server-rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  public static PUSH_FCM_TOKEN_STORAGE_KEY = 'pushFCMToken';
  public static LAST_PUSH_FCM_TOKEN_UPDATE_TIME_STORAGE_KEY = 'lastPushFCMTokenUpdateTime';
  public static TOKEN_EXPIRE_TIME_DISTANCE = 1000 * 60 * 60 * 24 * 7 * 3; // 3 weeks

  constructor(
    private nativeAppService: NativeAppService,
    private serverRestApi: ServerRestApiService,
    private authService: AuthService,
    private restApiService: ServerRestApiService,
    @Inject(DOCUMENT) private document
  ) {
    if (this.nativeAppService.isOnApp()) {
      // Note: in the background ios can suspend the app and the connection
      // so we have to subscribe on the keyring loaded state
      // on android we have minutes before the suspend, so we can
      // check the cordova resume feature

      this.restApiService.subscribeOnWSConnected(() => {
        this.refreshToken();
      });

      // after resume
      this.document.addEventListener(
        'resume',
        () => {
          if (this.restApiService.isWebsocketConnected()) {
            //console.log('push noti token after resume cordova app');
            this.refreshToken();
          }
          PushNotifications.removeAllDeliveredNotifications();
        },
        false
      );

      this.authService.onLogout(() => {
        this.resetToken();
      });

      /**
       * Android needs time to save session cookies
       * You can close the app right after login and you will lose these cookies
       * so after every login we should reset the token
       */
      this.authService.onLogin(() => {
        this.resetToken();
        this.refreshToken();
      });

      PushNotifications.addListener('registration', (token) => {
        console.log('PUSHN Registration token: ', token.value);
        if (this.getTokenResolver.resolve) {
          this.getTokenResolver.resolve(token.value);
        } else {
          console.log('there is no resolver for push token');
        }
      });

      PushNotifications.addListener('registrationError', (err) => {
        console.error('PUSHN Registration error: ', err.error);
        if (this.getTokenResolver.reject) {
          this.getTokenResolver.reject(err);
        } else {
          console.log('there is no resolver for push token err');
        }
      });
    } else {
      console.warn('push noti is only supported on app');
    }
  }

  private getTokenResolver = { resolve: null, reject: null };

  public getToken(): Promise<string> {
    if (this.nativeAppService.isOnApp() && !this.authService.isAnonym()) {
      //return window['pushNotification'].getToken();
      console.log('PUSHN get token 1');
      return new Promise((resolve, reject) => {
        this.getTokenResolver = { resolve, reject };

        PushNotifications.checkPermissions().then((status) => {
          console.log('PUSHN get token 2', status);
          if (status.receive === 'prompt') {
            PushNotifications.requestPermissions().then((permission) => {
              console.log('PUSHN get token 3', permission);
              if (permission.receive !== 'granted') {
                throw new Error('User denied permissions!');
              }
              console.log('PUSHN get token 4 register');
              return PushNotifications.register();
            });
          } else if (status.receive !== 'granted') {
            throw new Error('PUSHN User denied permissions!');
          } else if (status.receive === 'granted') {
            console.log('PUSHN get token 5 register');
            return PushNotifications.register();
          }
        });
      });
    } else {
      return Promise.reject('PUSHN can not get token');
    }
  }

  public refreshToken() {
    console.log('PUSHN start get token call');
    return this.getToken()
      .then((token) => {
        console.log('PUSHN refresh token?', token);
        if (this.needToUpdateToken(token)) {
          console.log('PUSHN yes, refresh token');
          return this.serverRestApi
            .mutate({
              query: editPushData,
              variables: {
                type: 'fcm',
                token,
              },
            })
            .then((res) => {
              console.log('PUSHN push noti refresh resp', res);
              AppStorage.setItem(PushNotificationService.PUSH_FCM_TOKEN_STORAGE_KEY, token);
              AppStorage.setItem(
                PushNotificationService.LAST_PUSH_FCM_TOKEN_UPDATE_TIME_STORAGE_KEY,
                new Date().getTime() + ''
              );
              return true;
            })
            .catch((err) => {
              console.error('PUSHN could not send token to refresh', err);
            });
        }
      })
      .catch((err) => {
        console.log('PUSHN can not get push token', err);
      });
  }

  private needToUpdateToken(token): boolean {
    let savedToken = AppStorage.getItem(PushNotificationService.PUSH_FCM_TOKEN_STORAGE_KEY);
    let lastUpdateTimeString = AppStorage.getItem(
      PushNotificationService.LAST_PUSH_FCM_TOKEN_UPDATE_TIME_STORAGE_KEY
    );
    let lastUpdateTime;
    let timeDiff;
    if (lastUpdateTimeString) {
      lastUpdateTime = new Date(parseInt(lastUpdateTimeString));
      timeDiff = new Date().getTime() - lastUpdateTime.getTime();
    }

    return (
      token &&
      (token != savedToken ||
        !lastUpdateTime ||
        timeDiff > PushNotificationService.TOKEN_EXPIRE_TIME_DISTANCE)
    );
  }

  public resetToken() {
    console.log('PUSHN reset fcm token');
    AppStorage.removeItem(PushNotificationService.PUSH_FCM_TOKEN_STORAGE_KEY);
    AppStorage.removeItem(PushNotificationService.LAST_PUSH_FCM_TOKEN_UPDATE_TIME_STORAGE_KEY);
  }

  public subscribeNotification(cb) {
    //  window['pushNotification'].subscribeNotification(cb);
    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.log('PUSHN Push notification received: ', notification);
      cb(notification);
    });
  }

  public subscribeNotificationTap(cb) {
    //  window['pushNotification'].subscribeNotificationTap(cb);
    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      console.log(
        'PUSHN Push notification action performed',
        notification.actionId,
        notification.inputValue
      );
      cb(notification);
    });
  }

  public notificationPullStart() {
    /*
    if (window['pushNotification']) {
      window['pushNotification'].pullStart();
    } else {
      console.warn('there is no implemented pushNotification handler for this platform');
    }*/
  }
}
