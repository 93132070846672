import { AESCipherSIVNonce96 } from '../../base/cipher/aes';
import { AbstractCryptoConfig } from '../base';
import { NanoDataConfigDumpArgs, NanoDataConfigLoadArgs } from './args';

/**
 * Nano data encryption: AES-SIV
        KEY: 64B key (derived from Nano secrets and data-source state)
        NONCE: 12B random
 */
export class NanoDataConfigV1 extends AbstractCryptoConfig<
  NanoDataConfigLoadArgs,
  NanoDataConfigDumpArgs
> {
  constructor(never_dump: boolean = false) {
    super(1, never_dump);
  }

  public load(args: NanoDataConfigLoadArgs): Promise<Uint8Array> {
    return AESCipherSIVNonce96.decrypt(args.cipher.slice(this.version.byteLength), args.key);
  }

  public dump(args: NanoDataConfigDumpArgs): Promise<Uint8Array> {
    return AESCipherSIVNonce96.encrypt(args.plain, args.key, undefined, undefined, this.version);
  }
}
