<h2 mat-dialog-title translate>Saved billing templates</h2>
<mat-dialog-content class="mat-typography">
  <div translate>Select the templates you wish to delete.</div>
  <mat-selection-list #billingsList>
    <mat-list-option class="billing-row" *ngFor="let paymentBilling of paymentBillings" [value]="paymentBilling.billing_id" color="primary">
      <span>{{ paymentBilling.billing_id + 1 }}: {{ paymentBilling.postal }} {{ paymentBilling.city }}, {{ paymentBilling.address }}</span>
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Close</span></button>
  <button mat-raised-button color="primary" (click)="deleteBillings(billingsList.selectedOptions.selected)" [disabled]="billingsList.selectedOptions.selected.length === 0">
    <span>Delete selected</span>
  </button>
</mat-dialog-actions>
