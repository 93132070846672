import * as jdenticon from 'jdenticon';

export function generateAvatar(seed: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    try {
      var canvas = document.createElement('canvas');
      canvas.width = 128;
      canvas.height = 128;

      var ctx = canvas.getContext('2d');

      jdenticon.drawIcon(ctx, seed, 128, {
        lightness: {
          color: [0.33, 0.73],
          grayscale: [0.43, 0.75],
        },
        saturation: {
          color: 0.8,
          grayscale: 0.39,
        },
        backColor: '#000A12',
      });
      canvas.toBlob((avatarImageBlob) => {
        resolve(avatarImageBlob);
      });
    } catch (e) {
      reject(e);
    }
  });
}
