import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from 'src/app/shared/server-services/payment.service';
import { BackDataRecord } from 'src/app/shared/server-services/query-records/payment-records';

@Component({
  selector: 'app-simplepay-back',
  templateUrl: './simplepay-back.component.html',
  styleUrls: ['./simplepay-back.component.scss'],
})
export class SimplepayBackComponent {
  BackPayStatuses = BackPayStatus;
  backPayStatus: BackPayStatus;
  backData: BackDataRecord;

  constructor(private route: ActivatedRoute, private paymentService: PaymentService) {
    this.route.queryParams.subscribe((params) => {
      if (!params['r'] || !params['s']) {
        this.backPayStatus = BackPayStatus.INVALID;
        return;
      }

      this.paymentService
        .paymentParseBack(params['r'], params['s'])
        .then((backData) => {
          this.backData = (<any>backData).response.back_data;
          switch (this.backData.e.toLowerCase()) {
            case 'success':
              this.backPayStatus = BackPayStatus.VALID;
              break;
            case 'fail':
              this.backPayStatus = BackPayStatus.FAILED;
              break;
            case 'timeout':
              this.backPayStatus = BackPayStatus.TIMEOUT;
              break;
            case 'cancel':
              this.backPayStatus = BackPayStatus.CANCEL;
              break;
          }
        })
        .catch((err) => {
          console.error(err);
          this.backPayStatus = BackPayStatus.INVALID;
        });
    });
  }
}

enum BackPayStatus {
  INVALID,
  VALID,
  FAILED,
  TIMEOUT,
  CANCEL,
}
