<h2 mat-dialog-title translate>Recurring order details</h2>
<mat-dialog-content class="mat-typography">
  <p id="sub-text" translate>Information about the selected license's recurring payment.</p>

  <ng-template #loadingContent>
    <mat-icon><mat-spinner diameter="20"></mat-spinner></mat-icon>
  </ng-template>

  <ng-container *ngIf="loaded; else loadingContent">
    <ng-container *ngIf="!reveal; else orderDetailContent">
      <button mat-raised-button color="primary" (click)="reveal = true"><span translate>Reveal payment details</span></button>
    </ng-container>

    <ng-template #orderDetailContent>
      <mat-card appearance="outlined">
        <mat-card-title><span translate>Order details</span></mat-card-title>
        <mat-card-content>
          <div class="order-info-col">
            <label translate>Product</label>
            <span>
              {{ shopData.products[recurringOrder.product].packages[recurringOrder.package].months }}
              <span translate *ngIf="shopData.products[recurringOrder.product].packages[recurringOrder.package].months > 1">months license</span>
              <span translate *ngIf="shopData.products[recurringOrder.product].packages[recurringOrder.package].months === 1">month license</span>
            </span>
          </div>
          <div class="order-info-col">
            <label translate>License target</label>
            <span *ngIf="recurringOrder.target === 'own'" translate>Self</span>
            <span *ngIf="recurringOrder.target === 'bundle'" translate>Bundle</span>
          </div>
          <div class="order-info-col">
            <label translate>Slot amount</label>
            <span>{{ recurringOrder.number }} <span translate *ngIf="recurringOrder.number === 1">slot</span><span translate *ngIf="recurringOrder.number > 1">slots</span> </span>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined">
        <mat-card-title><span translate>Billing details</span></mat-card-title>
        <mat-card-content>
          <div class="order-info-col">
            <label translate>Purchaser entity</label>
            <span *ngIf="recurringOrder.entity === 'individual'" translate>Individual</span>
            <span *ngIf="recurringOrder.entity === 'company'" translate>Company</span>
          </div>
          <div class="order-info-col">
            <label translate>Email</label>
            <span>{{ recurringOrder.email }}</span>
          </div>
          <div class="order-info-col">
            <label translate *ngIf="recurringOrder.entity === 'individual'">Name</label>
            <label translate *ngIf="recurringOrder.entity === 'company'">Company name</label>
            <span>{{ recurringOrder.name }}</span>
          </div>
          <div class="order-info-col" *ngIf="recurringOrder.entity === 'company'">
            <label translate>Purchaser name</label>
            <span>{{ recurringOrder.name_of_purchaser }}</span>
          </div>
          <div class="order-info-col">
            <label translate>Country code</label>
            <span>{{ recurringOrder.country }}</span>
          </div>
          <div class="order-info-col">
            <label translate>State / Province</label>
            <span>{{ recurringOrder.state }}</span>
          </div>
          <div class="order-info-col">
            <label translate>ZIP code</label>
            <span>{{ recurringOrder.postal }}</span>
          </div>
          <div class="order-info-col">
            <label translate>City</label>
            <span>{{ recurringOrder.city }}</span>
          </div>
          <div class="order-info-col">
            <label translate>Address</label>
            <span>{{ recurringOrder.address }}</span>
          </div>
          <div class="order-info-col" *ngIf="recurringOrder.tax_number">
            <label translate>Tax number</label>
            <span>{{ recurringOrder.tax_number }}</span>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined">
        <mat-card-title><span translate>Payment details</span></mat-card-title>
        <mat-card-content>
          <div class="order-info-col">
            <label translate>Payment method</label>
            <span *ngIf="recurringOrder.method === 'card'" translate>Credit or debit card</span>
            <span *ngIf="recurringOrder.target === 'wire'" translate>Bank transfer</span>
          </div>
          <div class="order-info-col">
            <label translate>Recurring payments (total)</label>
            <span
              >{{ recurringOrder.recurring_number }} <span translate *ngIf="recurringOrder.recurring_number === 1">recurrence</span
              ><span translate *ngIf="recurringOrder.recurring_number > 1">recurrences</span></span
            >
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined">
        <mat-card-title><span translate>Recurrence detail</span></mat-card-title>
        <mat-card-content>
          <div class="order-info-col">
            <label translate>Detailed price</label>
            <span>
              {{ recurringOrder.price_net | currency: recurringOrder.currency:'symbol':(isHunCurrency ? '2.0-2' : '') }}
              + {{ recurringOrder.price_gross - recurringOrder.price_net | currency: recurringOrder.currency:'symbol':(isHunCurrency ? '2.0-2' : '') }}
              <span translate>VAT</span>
              = <b>{{ recurringOrder.price_gross | currency: recurringOrder.currency:'symbol':(isHunCurrency ? '2.0-2' : '') }}</b>
            </span>
            <small><span translate>Price includes the following discount:</span> {{ recurringOrder.net_discount_percent }}%</small>
          </div>
          <div class="order-info-col">
            <label translate>Last payment</label>
            <span>
              {{ recurringOrder.recurring_until * 1000 | date: 'medium' }}
            </span>
          </div>
          <div class="order-info-col">
            <span>
              <a (click)="cancelRecurringOrder()" translate>Cancel recurring payment</a>
            </span>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Close</span></button>
</mat-dialog-actions>
