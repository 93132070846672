<mat-action-list>
  <ng-container *ngIf="isExpanded === true">
    <div [@inOutAnimation] id="bottom-sheet-more-reactions">
      <span [ngClass]="{ selected: data.contextTargetMessage.data.myReaction === r.index }" (click)="sheetActionClick(SheetAction.REACT, r.index)" *ngFor="let r of reactions"
        ><img [src]="r.src"
      /></span>
    </div>
  </ng-container>
  <ng-container *ngIf="isExpanded === false">
    <ng-container *ngIf="data.contextTargetPermissions.reactionPermission">
      <div class="chat-bottom-sheet-emojibar">
        <span *ngFor="let emojiObj of topEmojies" [ngClass]="{ selected: data.contextTargetMessage.data.myReaction === emojiObj.index }">
          <img [src]="emojiObj.emoji" (click)="sheetActionClick(SheetAction.REACT, emojiObj.index)" />
        </span>
        <div (click)="expandEmojiPicker($event)" class="expand-icon"><mat-icon>expand_circle_down</mat-icon></div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>
    <!-- <mat-list-item (click)="sheetActionClick(SheetAction.SELECT)">
      <mat-icon matListItemIcon>check_circle</mat-icon>
      <h3 matListItemTitle>Select</h3>
      <p matListItemLine>
        <span>Select</span>
      </p>
    </mat-list-item> -->
    <mat-list-item *ngIf="data.myPermissions.canSendMessages" (click)="sheetActionClick(SheetAction.REPLY)">
      <mat-icon matListItemIcon>reply</mat-icon>
      <h3 matListItemTitle translate>Reply</h3>
      <!-- <p matListItemLine>
        <span translate>Edit message</span>
      </p> -->
    </mat-list-item>
    <mat-list-item *ngIf="data.contextTargetPermissions.modifyPermission" (click)="sheetActionClick(SheetAction.EDIT)">
      <mat-icon matListItemIcon>edit</mat-icon>
      <h3 matListItemTitle translate>Edit</h3>
      <!-- <p matListItemLine>
        <span translate>Edit message</span>
      </p> -->
    </mat-list-item>
    <mat-list-item (click)="sheetActionClick(SheetAction.COPY)">
      <mat-icon matListItemIcon>content_copy</mat-icon>
      <h3 matListItemTitle translate>Copy</h3>
      <!-- <p matListItemLine>
        <span translate>Copy message</span>
      </p> -->
    </mat-list-item>
    <mat-list-item *ngIf="data.contextTargetMessage.event['fileUrl']" (click)="sheetActionClick(SheetAction.COPY_FILE_URL)">
      <mat-icon matListItemIcon>file_copy</mat-icon>
      <h3 matListItemTitle translate>Copy File URL</h3>
      <!-- <p matListItemLine>
        <span translate>Copy message</span>
      </p> -->
    </mat-list-item>
    <mat-list-item *ngIf="data.contextTargetPermissions.deletePermission" (click)="sheetActionClick(SheetAction.DELETE)">
      <mat-icon matListItemIcon>delete</mat-icon>
      <h3 matListItemTitle translate>Delete</h3>
      <!-- <p matListItemLine>
        <span>Delete message for everyone</span>
      </p> -->
    </mat-list-item>
  </ng-container>
</mat-action-list>
