<div>
  <div class="sub-page">
    <h1 translate>Account settings</h1>
    <small translate>Personalize your account or change your security settings here!</small>
    <ng-template *ngIf="dataLoading; else dataLoaded">
      <mat-spinner id="nano-clients-spinner" diameter="40"></mat-spinner>
    </ng-template>
    <ng-template #dataLoaded>
      <div class="setting-row">
        <div id="avatar-container" (click)="openEditAvatarDialog()">
          <app-avatar [blob]="avatarBlob"></app-avatar>
          <mat-icon>add_circle</mat-icon>
        </div>
        <div class="setting-info-col">
          <label translate>Username</label>
          <span class="name-input notranslate">{{ me.avatarName }}</span>
        </div>
        <div class="setting-button-col">
          <button mat-raised-button (click)="openEditUserNameDialog()"><span translate>Edit</span></button>
        </div>
      </div>

      <div class="setting-row">
        <div class="setting-info-col">
          <label>
            <span class="setting-title" translate>My Contact ID</span>
            <mat-icon class="show-button" (click)="showID = true" *ngIf="!showID">visibility</mat-icon>
            <mat-icon class="show-button" (click)="showID = false" *ngIf="showID">visibility_off</mat-icon>
          </label>
          <div id="show-email-container">
            <div class="hidden-container">
              <span #hiddenId *ngIf="!showID; else id">{{ me.id | hide: 'all' }}</span>
              <ng-template #id
                ><span class="notranslate">{{ me.id }}</span></ng-template
              >
            </div>
          </div>
        </div>
        <div class="setting-button-col">
          <button mat-raised-button (click)="copyContactId()"><span translate>Copy</span></button>
        </div>
      </div>

      <div class="setting-row">
        <div class="setting-info-col">
          <label>
            <span class="setting-title" translate>My Contact URL</span>
            <mat-icon class="show-button" (click)="copyContactUrl()">link</mat-icon>
          </label>
          <div>
            <div>
              <span translate>Share your contact url with your friends so they can find you easily.</span>
            </div>
          </div>
        </div>
        <div class="setting-button-col">
          <button mat-raised-button (click)="copyContactUrl()"><span translate>Copy</span></button>
        </div>
      </div>

      <div class="setting-row" *ngIf="isIdentitySettingsOn">
        <div class="setting-info-col">
          <label>
            <span class="setting-title" translate>Identity signature</span>
            <mat-icon class="show-button">verified_user</mat-icon>
          </label>
          <span translate>Give this to people who wish to verify your identity on Nano. Send it on a secure channel outside of Nano.</span>
          <div id=""></div>
        </div>
        <div class="setting-button-col">
          <button mat-raised-button (click)="copyIdentitySignature()"><span translate>Copy</span></button>
        </div>
      </div>

      <div class="setting-row">
        <div class="setting-info-col">
          <label translate>Contact policy</label>
          <span translate>Contact policy determines how other users can reach you. Your current policy is set to:</span>
          <div [ngSwitch]="me.dialoguePolicy">
            <label *ngSwitchCase="dialoguePolicyEnum.OPEN"><small translate>Anyone can initiate a dialogue with you.</small></label>
            <label *ngSwitchCase="dialoguePolicyEnum.SHAREDROOM"
              ><small translate>Only users in rooms you are a member of can initiate a dialogue with you, or users in your contact list.</small></label
            >
            <label *ngSwitchCase="dialoguePolicyEnum.OWNEDROOM"
              ><small translate>Only users in rooms you are an owner of can initiate a dialogue with you, or users in your contact list.</small></label
            >
            <label *ngSwitchCase="dialoguePolicyEnum.CONTACTONLY"><small translate>Only users in your contact list can initiate a dialogue with you.</small></label>
          </div>
        </div>
        <div class="setting-button-col">
          <button mat-raised-button (click)="openEditMessagePolicyDialog()"><span translate>Edit</span></button>
        </div>
      </div>

      <div class="setting-row">
        <div class="setting-info-col">
          <label>
            <span translate>Email</span>
            <mat-icon class="show-button" (click)="showEmail = true" *ngIf="!showEmail">visibility</mat-icon>
            <mat-icon class="show-button" (click)="showEmail = false" *ngIf="showEmail">visibility_off</mat-icon>
          </label>
          <div id="show-email-container">
            <div class="hidden-container">
              <span #hiddenEmail *ngIf="!showEmail; else email">{{ me.email | hide }}</span>
              <ng-template #email
                ><span>{{ me.email }}</span></ng-template
              >
            </div>
          </div>
        </div>
        <div class="setting-button-col">
          <button mat-raised-button (click)="openEditEmailDialog()"><span translate>Edit</span></button>
        </div>
      </div>

      <div class="setting-row">
        <div class="setting-info-col">
          <label translate>Two-Factor Authentication</label>
          <span translate>Two-Factor authentication (2FA for short) is a good way to add an extra layer of security to your Nano account.</span>
        </div>
        <div class="setting-button-col">
          <button mat-raised-button (click)="setTwoFactor(true)" *ngIf="currentTwoFactorMode === 0"><span translate>Enable</span></button>
          <button mat-raised-button (click)="setTwoFactor(false)" *ngIf="currentTwoFactorMode === 10"><span translate>Disable</span></button>
        </div>
      </div>

      <div class="setting-row">
        <div class="setting-info-col">
          <label translate>Password</label>
          <span translate>Make sure to choose a strong password to protect your Nano account.</span>
        </div>
        <div class="setting-button-col">
          <button mat-raised-button (click)="openEditPasswordDialog()"><span translate>Change</span></button>
        </div>
      </div>

      <div class="setting-row">
        <div class="setting-info-col">
          <label translate>Login Sessions</label>
          <span translate>You can manually kick user login and Nano sessions.</span>
        </div>
        <div class="setting-button-col">
          <button routerLink="/session-handler" mat-raised-button><span translate>Open</span></button>
        </div>
      </div>

      <div class="setting-row">
        <div class="setting-info-col">
          <label translate>Save account recovery key</label>
          <span translate>The only way to recover your account if you lost your password.</span>
        </div>
        <div class="setting-button-col">
          <button mat-raised-button (click)="saveRecoveryKey()"><span translate>Download</span></button>
        </div>
      </div>
      <div class="setting-row panel">
        <div class="setting-info-col">
          <label>
            <span translate>Use ETALON token</span>
            <mat-icon class="show-button" (click)="showAddress = true" *ngIf="walletExists && !showAddress">visibility</mat-icon>
            <mat-icon class="show-button" (click)="showAddress = false" *ngIf="walletExists && showAddress">visibility_off</mat-icon>
          </label>
          <span *ngIf="walletExists && !metamaskConnectingInProgress" class="primary" translate>Wallet connected!</span>
          <p *ngIf="walletExists">
            <span>{{ 'Connected address: ' | translate }}</span>
            <code *ngIf="!showAddress">{{ metamaskAddressHint }}</code>
            <code *ngIf="showAddress">{{ metamaskFullAddress }}</code>
          </p>
          <span>
            <span
              >{{ 'Connect a crypto-wallet to your account. As long as it holds at least one ' | translate }}<a href="https://etalon.cash" target="_blank">Etalon token</a
              >{{ ' this account will receive one perpetual Nano slot.' | translate }}</span
            >
          </span>
        </div>
        <div class="setting-button-col">
          <div>
            <button *ngIf="isDesktop && !metamaskConnectingInProgress && ethereum" mat-raised-button (click)="authMetaMask()">
              <span translate>Use ETAL</span>
            </button>
            <button *ngIf="!isDesktop" mat-raised-button disabled>
              <span translate>Desktop only!</span>
            </button>
          </div>
          <mat-icon *ngIf="metamaskConnectingInProgress"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        </div>
      </div>

      <!-- <mat-divider style="margin-bottom: '15px'"></mat-divider> -->
      <div class="setting-row panel">
        <div class="setting-info-col">
          <label translate>Delete Your Account</label>
          <span translate>Permanently delete your user account. It CAN NOT be undone.</span>
        </div>
        <div class="setting-button-col">
          <button class="delete-button" mat-flat-button color="warn" (click)="deleteAccount()"><span translate>Delete</span></button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
