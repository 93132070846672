import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateDialogueTypingEventSubscriptionHandler } from './ws-sub-dialogue-typing-event-create-handler';
import { onDeleteDialogueTypingEventSubscriptionHandler } from './ws-sub-dialogue-typing-event-delete-handler';
import { onModifyDialogueTypingEventSubscriptionHandler } from './ws-sub-dialogue-typing-event-modify-handler';

export let workspaceSubscriptionDialogueTypingEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateDialogueTypingEventSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyDialogueTypingEventSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteDialogueTypingEventSubscriptionHandler,
};
