/**
 * Encryption of account keyring in local storage.
 */

import { CryptoContext } from '../base';
import { LocalStorageConfigDumpArgs, LocalStorageConfigLoadArgs } from './args';
import { LocalStorageConfigV1 } from './v1';
import { LocalStorageConfigV2 } from './v2';

export let LOCAL_STORAGE_CRYPTO_CONTEXT = new CryptoContext<
  LocalStorageConfigLoadArgs,
  LocalStorageConfigDumpArgs
>(
  [new LocalStorageConfigV1(), new LocalStorageConfigV2()],
  [new Uint8Array([1]), new Uint8Array([2])]
);
