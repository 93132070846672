import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BlobService } from 'src/app/shared/services/blob.service';

export type ImageDim = {
  w: number;
  h: number;
};

@Component({
  selector: 'app-blob-viewer',
  templateUrl: './blob-viewer.component.html',
  styleUrls: ['./blob-viewer.component.scss'],
})
export class BlobViewerComponent implements OnInit, OnDestroy {
  public TYPE_IMAGE = 'image';

  @Input() blob;
  @Input() type = this.TYPE_IMAGE;

  @Output() loadError: EventEmitter<void> = new EventEmitter<void>();
  @Output() load: EventEmitter<ImageDim> = new EventEmitter<ImageDim>();

  public imgURL;
  private blobUrl;

  constructor(private sanitizer: DomSanitizer, private blobService: BlobService) {}

  ngOnInit(): void {
    if (this.type == this.TYPE_IMAGE) {
      this.blobUrl = this.blobService.createObjectURL(this.blob);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(this.blobUrl);
    }
  }

  ngOnDestroy() {
    if (this.type == this.TYPE_IMAGE) {
      this.blobService.revokeObjectURL(this.blobUrl);
    }
  }

  onLoadError() {
    this.loadError.emit();
  }

  onImageLoad(e) {
    this.load.emit({
      w: e.target.naturalWidth,
      h: e.target.naturalHeight,
    });
  }
}
