import { DialogueRecord } from 'src/app/shared/server-services/query-records/room-records';
import { SidebarNews } from 'src/app/shared/server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionDialogueEventRecord } from 'src/app/shared/server-services/query-records/workspace-records';
import { getDialogueDataQuery, getSidebarNewsQuery } from 'src/app/shared/server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../../../workspace-change-subscription-merger-handlers/types';
import { pushNewsElementForward } from '../push-element-forward';

export let onNewsHandlerDialogueModify: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionDialogueEventRecord
) => {
  let dialogueData: DialogueRecord = cache.getCacheDataByRequest({
    query: getDialogueDataQuery,
    variables: event,
  });
  let news: SidebarNews = cache.getCacheDataByRequest({
    query: getSidebarNewsQuery,
    variables: {},
  });

  if (dialogueData) {
    if (event.seenMessageId) {
      if (
        ((!dialogueData.seenMessageId && dialogueData.topMessageId) ||
          dialogueData.seenMessageId < dialogueData.topMessageId) && // there is unseen message
        event.seenMessageId >= dialogueData.topMessageId // incoming seen message for top
      ) {
        news.news--;
      }
    }

    if (event.topMessageId) {
      pushNewsElementForward(cache, event.id);

      if (
        (!dialogueData.seenMessageId && !dialogueData.topMessageId) || // first message of the room
        (dialogueData.seenMessageId == dialogueData.topMessageId && // seen the last message but got a new one
          event.topMessageId >= dialogueData.topMessageId)
      ) {
        news.news++;
      }
    }
  }
};
