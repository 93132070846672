<input type="text" #hiddenClipboard id="hidden-clipboard" (paste)="onPaste($event)" />
<input type="file" multiple class="hidden-input" #hiddenFileInput (change)="openSelectFileDialog($event)" />
<div id="drive-cont" (dragenter)="onDragEnter($event)">
  <!-- <button mat-button (click)="downloadDrive()">Drive letöltése</button> -->
  <div id="drive-path" *ngIf="!loadError">
    <div id="full-path">
      <div id="path-root-cont">
        <mat-icon id="path-root" (click)="jumpInFolderStack(0)">computer</mat-icon>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div id="path-list-container">
        <ul>
          <li *ngFor="let folder of folderStack; let i = index">
            <span class="notranslate" class="mat-small" (click)="jumpInFolderStack(i + 1)">{{ folder }}</span>
            <mat-icon *ngIf="folderStack.length > i + 1">keyboard_arrow_right</mat-icon>
          </li>
        </ul>
      </div>
    </div>
    <div id="drive-menu">
      <ng-container *ngIf="initLoadDone && !loadError">
        <button mat-icon-button [disabled]="!this.lastInteracted" (click)="downloadFile(this.lastInteracted)" [matTooltip]="'Download selected file' | translate">
          <mat-icon>download</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="!isMobile && isDocumentEditorSupported"
          [disabled]="!this.lastInteracted"
          (click)="openFileInEditor(this.lastInteracted)"
          [matTooltip]="'Open file in editor' | translate"
        >
          <mat-icon>design_services</mat-icon>
        </button>
        <button mat-icon-button [disabled]="isReadOnly || (this.selectedFiles.length === 0 && !this.lastInteracted)" (click)="openDeleteDialog()" [matTooltip]="'Delete selected files' | translate">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button [disabled]="isReadOnly || (this.selectedFiles.length === 0 && !this.lastInteracted)" (click)="openEditDialog()" [matTooltip]="'Edit selected file name' | translate">
          <mat-icon>drive_file_rename_outline</mat-icon>
        </button>
        <button mat-icon-button [disabled]="isReadOnly || (this.selectedFiles.length === 0 && !this.lastInteracted)" (click)="openMoveDialog()" [matTooltip]="'Move selected files' | translate">
          <mat-icon>drive_file_move_outline</mat-icon>
        </button>
        <button mat-icon-button [disabled]="!this.lastInteracted" (click)="copyFileUrl()" [matTooltip]="'Copy file url' | translate">
          <mat-icon>link</mat-icon>
        </button>
        <div class="menu-divider"></div>
        <button mat-icon-button *ngIf="!isMobile && isDocumentEditorSupported" [disabled]="isReadOnly" (click)="openCreateFileWithEditorDialog()" [matTooltip]="'Create file' | translate">
          <mat-icon>note_add</mat-icon>
        </button>
        <button [disabled]="!canUpload" mat-icon-button (click)="hiddenFileInput.value = ''; hiddenFileInput.click()" [matTooltip]="'Upload file' | translate">
          <mat-icon>upload</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isBookmarksOn" (click)="saveIntoBookmarks()" [matTooltip]="'Save this folder as a bookmark' | translate">
          <mat-icon>bookmark_add</mat-icon>
        </button>
        <button [disabled]="!canUpload" mat-icon-button (click)="openCreateFolderDialog()" [matTooltip]="'Create folder' | translate">
          <mat-icon>create_new_folder</mat-icon>
        </button>
        <button mat-menu-item *ngIf="this.hasDirectoryPicker && folderStack.length" (click)="downloadThisFolderHandler()" [matTooltip]="'Download this folder' | translate">
          <span class="mdi mdi-folder-download mdi-icon"></span>
        </button>
        <button mat-menu-item *ngIf="this.hasDirectoryPicker && !folderStack.length" (click)="downloadDrive()" [matTooltip]="'Download Drive' | translate">
          <mat-icon>browser_updated</mat-icon>
        </button>
        <div class="menu-divider"></div>
        <button mat-icon-button (click)="switchViewMode(viewModeEnum.TILE)" [ngClass]="{ primary: viewMode === viewModeEnum.TILE }" [matTooltip]="'Icon view' | translate">
          <mat-icon>view_module</mat-icon>
        </button>
        <button mat-icon-button (click)="switchViewMode(viewModeEnum.LIST)" [ngClass]="{ primary: viewMode === viewModeEnum.LIST }" [matTooltip]="'List view' | translate">
          <mat-icon>view_list</mat-icon>
        </button>
      </ng-container>
      <ng-container>
        <button mat-icon-button *ngIf="!isContentLoading" (click)="refresh()" [matTooltip]="'Refresh' | translate">
          <mat-icon>refresh</mat-icon>
        </button>
        <mat-spinner class="drive-loading-spinner" *ngIf="isContentLoading" diameter="20"></mat-spinner>
      </ng-container>
    </div>
    <div id="drive-menu-mobile">
      <div id="current-folder" *ngIf="!isSelectingMode">
        <span (click)="backInFolderStack()">
          <mat-icon class="no-shrink" *ngIf="folderStack.length > 0">arrow_back</mat-icon>
          <span *ngIf="folderStack.length === 0">{{ nanoDetailsName }}</span>
          <span *ngIf="folderStack.length > 0">{{ folderStack?.[folderStack.length-1] }}</span>
        </span>
      </div>
      <div id="selected-files-container" *ngIf="isSelectingMode">
        <div id="selected-files-info">
          <button mat-icon-button (click)="stopSelectingMode()"><mat-icon>close</mat-icon></button>
          <div>{{ selectedFiles.length }}/{{ files.length }} <span translate>Selected</span></div>
        </div>
        <div>
          <button mat-button (click)="selectAll()">Select all</button>
        </div>
      </div>
      <ng-container *ngIf="!isSelectingMode">
        <button id="refresh-button" mat-icon-button [disabled]="isContentLoading" (click)="refresh()" [matTooltip]="'Refresh' | translate">
          <mat-icon>refresh</mat-icon>
        </button>
        <button id="toggle-view-button" mat-icon-button [disabled]="isContentLoading" (click)="toggleViewMode()" [matTooltip]="'Toggle view mode' | translate">
          <mat-icon *ngIf="viewMode === viewModeEnum.LIST">view_module</mat-icon>
          <mat-icon *ngIf="viewMode === viewModeEnum.TILE">view_list</mat-icon>
        </button>
        <button mat-icon-button [disabled]="isContentLoading" (click)="openMenuSheet()" [matTooltip]="'Open menu' | translate">
          <mat-icon>more_vert</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
  <div [ngStyle]="{ position: 'fixed', top: contextMenuPosition.y, left: contextMenuPosition.x }" [matMenuTriggerFor]="context_menu"></div>
  <mat-menu #context_menu="matMenu" [overlapTrigger]="true" [backdropClass]="'click-through'">
    <ng-template matMenuContent>
      <button mat-menu-item *ngIf="lastInteracted && !lastInteracted?.unresolved" (click)="downloadFile(this.lastInteracted)">
        <mat-icon>download</mat-icon>
        <span translate>Download</span>
      </button>
      <button mat-menu-item *ngIf="this.lastInteracted && !isMobile && isDocumentEditorSupported && !lastInteracted?.unresolved" (click)="openFileInEditor(this.lastInteracted)">
        <mat-icon>design_services</mat-icon>
        <div translate>Open in editor</div>
      </button>
      <button mat-menu-item *ngIf="!isReadOnly && !lastInteracted?.unresolved && (this.selectedFiles.length !== 0 || this.lastInteracted)" (click)="openEditDialog()">
        <mat-icon>drive_file_rename_outline</mat-icon>
        <span translate>Rename</span>
      </button>
      <button mat-menu-item *ngIf="!isReadOnly && (this.selectedFiles.length !== 0 || this.lastInteracted)" (click)="openMoveDialog()">
        <mat-icon>drive_file_move_outline</mat-icon>
        <span translate>Move</span>
      </button>
      <button mat-menu-item *ngIf="this.lastInteracted && !lastInteracted?.unresolved" (click)="copyFileUrl()">
        <mat-icon>link</mat-icon>
        <span translate>Copy url for members</span>
      </button>
      <button mat-menu-item *ngIf="this.lastInteracted && !lastInteracted?.unresolved && this.allowAnonym" (click)="copyPublicFileUrl()">
        <mat-icon>add_link</mat-icon>
        <span translate>Copy url for anyone</span>
      </button>
      <button mat-menu-item *ngIf="!isReadOnly && (this.selectedFiles.length !== 0 || this.lastInteracted)" (click)="openDeleteDialog()">
        <mat-icon>delete</mat-icon>
        <span translate>Delete</span>
      </button>
      <button mat-menu-item *ngIf="this.lastInteracted" (click)="openDescriptionDialog()">
        <mat-icon>description</mat-icon>
        <div translate>Properties</div>
      </button>
      <button mat-menu-item *ngIf="this.lastInteracted && this.lastInteracted.ext === 'dir' && isBookmarksOn" (click)="saveIntoBookmarks(this.lastInteracted)">
        <mat-icon>bookmark_add</mat-icon>
        <div translate>Add to Bookmarks</div>
      </button>
      <mat-divider *ngIf="lastInteracted"></mat-divider>
      <button mat-menu-item (click)="refresh()">
        <mat-icon>refresh</mat-icon>
        <span translate>Refresh</span>
      </button>
      <button mat-menu-item *ngIf="!isMobile && !isReadOnly && isDocumentEditorSupported" (click)="openCreateFileWithEditorDialog()">
        <mat-icon>note_add</mat-icon>
        <div translate>Create file</div>
      </button>
      <button mat-menu-item *ngIf="canUpload" (click)="openCreateFolderDialog()">
        <mat-icon>create_new_folder</mat-icon>
        <span translate>Create folder</span>
      </button>
      <button mat-menu-item *ngIf="canUpload && isOnApp" (click)="useCamera()">
        <mat-icon>photo_camera</mat-icon>
        <span translate>Camera</span>
      </button>
      <button mat-menu-item *ngIf="canUpload" (click)="hiddenFileInput.value = ''; hiddenFileInput.click()">
        <mat-icon>upload</mat-icon>
        <span translate>Upload</span>
      </button>
      <button mat-menu-item *ngIf="this.hasDirectoryPicker && folderStack.length" (click)="downloadThisFolderHandler()">
        <span class="mdi-mat-menu-element mdi mdi-folder-download mdi-icon"></span>
        <span translate>Download Folder</span>
      </button>
      <button mat-menu-item *ngIf="this.hasDirectoryPicker && !folderStack.length" (click)="downloadDrive()">
        <mat-icon>browser_updated</mat-icon>
        <span translate>Download Drive</span>
      </button>
    </ng-template>
  </mat-menu>
  <div *ngIf="!loadError" id="drive-submenu-container">
    <div class="paging-container" *ngIf="pagingMaxPage > 1">
      <div class="paging-data">
        <button mat-icon-button class="small-icon-button" (click)="nextPage(-1)" [disabled]="pagingCurrentPage === 1"><mat-icon>chevron_left</mat-icon></button>
        <mat-divider [vertical]="true"></mat-divider>
        <button mat-icon-button class="small-icon-button" (click)="nextPage(1)" [disabled]="pagingCurrentPage === pagingMaxPage"><mat-icon>chevron_right</mat-icon></button>
        <span class="paging-indicators">{{ pagingCurrentPage }} / {{ pagingMaxPage }}</span>
      </div>
    </div>
    <button mat-icon-button class="small-icon-button" (click)="switchSortDir()">
      <mat-icon *ngIf="currOrderDirection === 1">arrow_upward</mat-icon><mat-icon *ngIf="currOrderDirection === -1">arrow_downward</mat-icon>
    </button>
    <select id="drive-sort" class="nano-input" [(ngModel)]="currOrderBy" (ngModelChange)="setSortVal($event)">
      <option value="name">File name</option>
      <option value="ext">Extension</option>
      <option value="size">Size</option>
      <option value="mtime">Modify date</option>
    </select>
    <input [(ngModel)]="driveFilter" id="drive-search" name="drive-search" class="nano-input" [placeholder]="'Search in ' + (folderStack[0] ?? '(DriveRoot)')" (input)="filterFiles(driveFilter)" />
  </div>
  <div id="file-browser-container" #resourceContainer>
    <div id="file-browser-container-loading" *ngIf="isFileChunkLoadInProgress">
      <mat-spinner diameter="30" color="primary"></mat-spinner><span>{{ fileLoadProgress }} / {{ maxFileCount }}</span
      ><button mat-button color="primary" (click)="jumpInFolderStack(folderStack.length - 1)">Cancel</button>
    </div>
    <div *ngIf="!loadError" id="all-files" [ngClass]="{ opaque: isContentLoading }">
      <div *ngIf="viewMode === viewModeEnum.TILE" id="file-tile-table">
        <app-file-block-view
          id="f-{{ f.fullName }}"
          [selected]="selectedFiles.includes(f)"
          [isSelectingMode]="isSelectingMode"
          [focus]="f === lastInteracted"
          *ngFor="let f of files"
          [file]="f"
          appDoubleClick
          (doubleClick)="doubleClick(f)"
          (mousedown)="singleClick($event, f)"
          [appLongPress]=""
          (longPress)="onPress(f)"
        ></app-file-block-view>
      </div>
      <div *ngIf="viewMode === viewModeEnum.LIST" id="file-list-table">
        <table id="file-list-view-table">
          <tr id="file-list-view-header" *ngIf="!loadError">
            <th></th>
            <th id="file-list-view-header-name" class="mat-small" (click)="listHeaderClick('name')">
              <span class="column-title" translate>Name</span>
              <mat-icon *ngIf="currOrderBy === 'name' && currOrderDirection === -1">arrow_drop_down</mat-icon>
              <mat-icon *ngIf="currOrderBy === 'name' && currOrderDirection === 1">arrow_drop_up</mat-icon>
            </th>
            <th id="file-list-view-header-ext" class="mat-small" (click)="listHeaderClick('ext')">
              <span class="column-title" translate>Ext</span>
              <mat-icon *ngIf="currOrderBy === 'ext' && currOrderDirection === -1">arrow_drop_down</mat-icon>
              <mat-icon *ngIf="currOrderBy === 'ext' && currOrderDirection === 1">arrow_drop_up</mat-icon>
            </th>
            <th id="file-list-view-header-size" class="mat-small" (click)="listHeaderClick('size')">
              <span class="column-title" translate>Size</span>
              <mat-icon *ngIf="currOrderBy === 'size' && currOrderDirection === -1">arrow_drop_down</mat-icon>
              <mat-icon *ngIf="currOrderBy === 'size' && currOrderDirection === 1">arrow_drop_up</mat-icon>
            </th>
            <th id="file-list-view-header-date" class="mat-small" (click)="listHeaderClick('mtime')">
              <span class="column-title" translate>Date</span>
              <mat-icon *ngIf="currOrderBy === 'mtime' && currOrderDirection === -1">arrow_drop_down</mat-icon>
              <mat-icon *ngIf="currOrderBy === 'mtime' && currOrderDirection === 1">arrow_drop_up</mat-icon>
            </th>
          </tr>
          <tr id="back-in-folder" *ngIf="showBackArrow" appDoubleClick (doubleClick)="backInFolderStack()">
            <td><span class="mdi mdi-arrow-up-bold file-arrow-up-bold"></span></td>
            <td class="file-name" colspan="4">[..]</td>
          </tr>
          <app-file-list-view
            *ngFor="let file of files"
            id="f-{{ file.fullName }}"
            [ngClass]="{ subfolder: folderStack.length > 0 }"
            [selected]="selectedFiles.includes(file)"
            [isSelectingMode]="isSelectingMode"
            [focus]="file === lastInteracted"
            [file]="file"
            appDoubleClick
            (doubleClick)="doubleClick(file)"
            (mousedown)="singleClick($event, file)"
            [appLongPress]=""
            (longPress)="onPress(file)"
            (navigate)="navigateToSubFolder(file)"
          ></app-file-list-view>
        </table>
      </div>
    </div>
    <div class="drive-load-error" *ngIf="loadError" translate>
      <ng-container *ngIf="pathError">
        <h2 translate>Can not find this path.</h2>
        <div (click)="jumpInFolderStack(0)"><mat-icon color="primary">home</mat-icon></div>
      </ng-container>
      <ng-container *ngIf="peerNotTrustedError">
        <h2 translate>This drive requires explicit user verification for access. Contact the owner of the drive, so they may verify your account on Nano.</h2>
      </ng-container>
      <ng-container *ngIf="!pathError && !peerNotTrustedError">
        <ng-container *ngIf="competingError">
          <h2 translate>Multiple Nano clients are trying to serve a Drive for this room</h2>
          <div translate><span translate>Please stop all but one Drive from the following Nano clients: </span>{{ competingClientNames }}</div>
        </ng-container>
        <ng-container *ngIf="!competingError">
          <ng-container *ngIf="!pinnedKeyError">
            <h2 translate>Can not find active Nano attached to the room. Either the room's owner did not attach a Nano Drive, or the Nano's host machine is offline.</h2>
            <div *ngIf="ownRoom" translate>Attach a Nano Drive in the <a (click)="openNanoManagerDialog()" class="pointer" translate>Nano manager</a>.</div>
          </ng-container>
          <ng-container *ngIf="pinnedKeyError">
            <h2 translate>
              Wrong URL. You are trying to access the room anonymously but the url does not contain all of the information. Ask a member or the owner for an updated url, or login if you are a member
              of this room.
            </h2>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div id="drive-footer" *ngIf="!loadError">
    <div *ngIf="!isSelectingMode && nanoDetailsName" [matTooltip]="nanoDetailsName" [matTooltipClass]="['no-translate']" matTooltipPosition="above">
      <span><mat-icon>computer</mat-icon></span>
    </div>
    <div *ngIf="isSelectingMode">
      <button mat-raised-button (click)="stopSelectingMode()">Deselect</button>
    </div>
    <div id="drive-footer-info">
      <span *ngIf="!this.isContentLoading"
        ><span translate>Selected Files: </span><span>{{ selectedFiles.length }}</span></span
      >
      <span *ngIf="!this.isContentLoading"
        ><span translate>All Files: </span><span>{{ files.length }}</span></span
      >
    </div>
  </div>

  <div *ngIf="selectedFiles.length || lastInteracted" id="drive-footer-mobile" [@inOutAnimation] [ngClass]="{ active: selectedFiles.length > 0 }">
    <button class="mat-icon-text-button" mat-button (click)="downloadFile(this.lastInteracted)">
      <mat-icon>download</mat-icon>
      <span>Download</span>
    </button>

    <button class="mat-icon-text-button" mat-button (click)="openDeleteDialog()">
      <mat-icon>delete</mat-icon>
      <span>Delete</span>
    </button>

    <button class="mat-icon-text-button" mat-button (click)="openMenuSheet()">
      <mat-icon>more_vert</mat-icon>
      <span>More</span>
    </button>
  </div>

  <div id="floating-mobile-button" *ngIf="!loadError && canUpload" [ngClass]="{ active: selectedFiles.length > 0 || lastInteracted }">
    <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
      <mat-icon>add</mat-icon>
    </button>

    <mat-menu #menu="matMenu" xPosition="after" yPosition="above">
      <button mat-menu-item *ngIf="isOnApp" (click)="useCamera()">
        <mat-icon>photo_camera</mat-icon>
        <span translate>Camera</span>
      </button>
      <button mat-menu-item (click)="hiddenFileInput.value = ''; hiddenFileInput.click()">
        <mat-icon>upload</mat-icon>
        <span translate>Upload</span>
      </button>
      <button mat-menu-item (click)="openCreateFolderDialog()">
        <mat-icon>create_new_folder</mat-icon>
        <span translate>New folder</span>
      </button>
    </mat-menu>
  </div>
</div>
<div id="drop-area" [ngClass]="{ 'show-drop-area': showDropArea }">
  <div translate>Drop Files here</div>
  <div class="mdi mdi-file-plus"></div>
</div>
<div id="drop-zone" (drop)="onDragDrop($event)" (dragleave)="onDragLeave($event)" [ngClass]="{ 'show-drop-area': showDropArea }"></div>
