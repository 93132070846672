import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateAccountContactSubscriptionHandler } from './ws-sub-account-contact-event-create-handler';
import { onDeleteAccountContactSubscriptionHandler } from './ws-sub-account-contact-event-delete-handler';
import { onModifyAccountContactSubscriptionHandler } from './ws-sub-account-contact-event-modify-handler';

export let workspaceSubscriptionAccountContactEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateAccountContactSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyAccountContactSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteAccountContactSubscriptionHandler,
};
