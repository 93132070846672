import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Capacitor } from '@capacitor/core';
import { mobileRoutes, webRoutes } from './app/routes';
import { environment } from './environments/environment';

console.log('run');

if (environment.production) {
  enableProdMode();
}

// force view for testing
export let forceMobileView = false;
export let forceDestopView = false;

/**
 * Note!
 * Use dynamic module load, otherwise the module's static references will collide
 * In that case, the components with the same tag name will be loaded chaotically.
 * The first imported module will define the component and overwrite it in all other modules
 */

export let activeRoutes;

if (Capacitor.getPlatform() !== 'web') {
  if (forceDestopView) {
    console.log('force desktop view');
    activeRoutes = webRoutes;
    import('./app/modules/app.module').then((m) => {
      platformBrowserDynamic()
        .bootstrapModule(m.AppModule)
        .catch((err) => console.error(err));
    });
  } else {
    console.log('normal mobile view');
    activeRoutes = mobileRoutes;
    import('./app/modules/mobile-app.module').then((m) => {
      platformBrowserDynamic()
        .bootstrapModule(m.MobileAppModule)
        .catch((err) => console.error(err));
    });
  }
} else {
  if (forceMobileView) {
    console.log('force mobile view');
    activeRoutes = mobileRoutes;
    import('./app/modules/mobile-app.module').then((m) => {
      platformBrowserDynamic()
        .bootstrapModule(m.MobileAppModule)
        .catch((err) => console.error(err));
    });
  } else {
    console.log('normal desktop view');
    activeRoutes = webRoutes;
    import('./app/modules/app.module').then((m) => {
      platformBrowserDynamic()
        .bootstrapModule(m.AppModule)
        .catch((err) => console.error(err));
    });
  }
}
