import { AccountAvatarRecord } from 'src/app/shared/server-services/query-records/account-records';
import { ResponseMergerHandler } from '../../../cache-logic-interface';
import { getAccountQuery, getAllAccountIdQuery } from 'src/app/shared/server-services/querys';
import { generateColor } from './account-avatars-query-merger';

export let customGetAccountQueryMerger: ResponseMergerHandler = function (
  cache,
  request,
  response: AccountAvatarRecord
) {
  let accountAvatar = response;

  let allAccountIds = cache.getCacheDataByRequest({ query: getAllAccountIdQuery, variables: {} });

  accountAvatar.color = generateColor(accountAvatar.id);

  let cacheKey = cache
    .getCacheLogic()
    .makeCacheDataPath({ query: getAccountQuery, variables: { id: accountAvatar.id } });
  cache.setCacheData(cacheKey, accountAvatar);

  if (!allAccountIds.includes(accountAvatar.id)) {
    cache
      .getCacheDataByRequest({ query: getAllAccountIdQuery, variables: {} })
      .push(accountAvatar.id);
  }
};
