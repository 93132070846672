<h1 mat-dialog-title translate>Two Factor Authentication</h1>
<div mat-dialog-content>
  <div id="title" translate>Please enter the authorization code that we sent to your configured email address.</div>
  <mat-form-field appearance="outline">
    <mat-label translate>Code</mat-label>
    <input matInput required [(ngModel)]="data.code" (keyup.enter)="onSubmit()" type="text" inputmode="numeric" pattern="[0-9]*" />
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()"><span translate>Cancel</span></button>
  <button mat-button [mat-dialog-close]="data" [disabled]="data.code === null || data.code.length === 0" (click)="onSubmit()" color="primary"><span translate>Send</span></button>
</div>
