import {
  SidebarNews,
  SidebarResource,
} from 'src/app/shared/server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionDialogueEventRecord } from 'src/app/shared/server-services/query-records/workspace-records';
import {
  getSidebarNewsQuery,
  getSidebarResourceQuery,
} from 'src/app/shared/server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../../../workspace-change-subscription-merger-handlers/types';

export let onNewsHandlerDialogueCreate: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionDialogueEventRecord
) => {
  let res: SidebarResource = cache.getCacheDataByRequest({
    query: getSidebarResourceQuery,
    variables: event,
  });

  // it can be handy if we just put the element on the top of the
  // news list, even if it can represent a different state after refresh
  let news: SidebarNews = cache.getCacheDataByRequest({
    query: getSidebarNewsQuery,
    variables: {},
  });
  news.resources.unshift(res);
};
