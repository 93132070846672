import { Injectable } from '@angular/core';
import { NativeAppService } from './native-app.service';

export type NotificationParam = {
  id?: number;
  title: string;
  message: string;
  callback?: Function;
  callbackData?: Object;
};

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(private nativeAppService: NativeAppService) {}

  copy(text: string): Promise<void> {
    if (this.nativeAppService.isOnApp()) {
      return this.nativeAppService.copyClipboard(text);
    } else {
      return navigator.clipboard.writeText(text);
    }
  }
}
