import { AfterViewInit, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppStorage } from 'src/app/shared/app-storage';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { decodeWH } from 'src/app/shared/wh-coder';
import { EnvironmentService } from 'src/environments/environment.service';
import {
  MarkdownTokenTree,
  TOKEN_TYPE,
  parseLineToTree,
} from '../../chat-message-editor/slate-editor/markdown';
import { AttachedFileUrl } from './markdown-file-preview/markdown-file-preview.component';
declare let SimpleMarkdown;

@Component({
  selector: 'app-markdown-view',
  templateUrl: './markdown-view.component.html',
  styleUrls: ['./markdown-view.component.scss'],
})
export class MarkdownViewComponent implements AfterViewInit {
  @Input() hasLineBreak: boolean;
  @Input() set html(value: string) {
    this.refreshContent(value);
  }

  //@ViewChild('output', { read: ElementRef }) output: any;

  public attachedFileUrl: AttachedFileUrl[] = [];

  isCompactDisplay = AppStorage.getItem('messageDisplay') === 'compact';

  public syntaxTree: MarkdownTokenTree | null = null;

  public bigEmoji: boolean = false;

  constructor(
    private dialog: MatDialog,
    private environmentService: EnvironmentService,
    private routerHandler: RouterHandler
  ) {}

  ngAfterViewInit(): void {
    //this.output.nativeElement.querySelectorAll('pre code').forEach((el) => {
    //  hljs.highlightElement(el);
    //});
  }

  private refreshContent(content) {
    this.attachedFileUrl = [];
    this.syntaxTree = parseLineToTree(content);

    this.bigEmoji =
      this.syntaxTree.children.length == 1 &&
      (this.syntaxTree.children[0].token == TOKEN_TYPE.EMOJI ||
        this.syntaxTree.children[0].token == TOKEN_TYPE.EMOJI_SHORTCODE);

    this.extractDriveUrls(this.syntaxTree);
  }

  /**
   * Collect all link type from the markdownTree
   * @param tree
   * @returns list of urls
   */
  /*private collectURL(tree: MarkdownTokenTree): string[] {
    let linkList = [];

    if (tree.token == TOKEN_TYPE.LINK) {
      linkList.push(tree.text);
    }

    if (tree.children) {
      tree.children.forEach((child) => {
        linkList = linkList.concat(this.collectURL(child));
      });
    }

    return linkList;
  }*/

  private extractDriveUrls(tree: MarkdownTokenTree) {
    if (!tree.children) return;

    // check all drive attachment url starting from the end
    while (
      tree.children[tree.children.length - 1] &&
      tree.children[tree.children.length - 1].token == TOKEN_TYPE.LINK
    ) {
      // filter our domain
      let [base, url] = tree.children[tree.children.length - 1].text.split(
        this.environmentService.site_base + '/'
      );

      if (!url || !this.routerHandler.isUrlOnPage(url, 'room/?/drive')) return;

      // get the fragments from the url
      let response = this.routerHandler.processUrl(url);

      let file = response.routerResponse.fragment['file']; // name of the file (as path)
      let roomId = response.routerResponse.params['id'];
      let path = response.routerResponse.fragment['path']; // path before the file
      if (file && roomId) {
        let width, height;

        if (response.routerResponse.fragment['s']) {
          try {
            let previewSize = decodeWH(parseInt(response.routerResponse.fragment['s']));
            width = Math.max(previewSize.width, 100);
            height = Math.max(previewSize.height, 100);
          } catch (e) {
            width = 300;
            height = 300;
          }
        }

        this.attachedFileUrl.push({
          file,
          roomId,
          path,
          width: width,
          height: height,
          raw: tree.children[tree.children.length - 1].text,
        });
      } else {
        return;
      }

      // remove drive url from text
      tree.children.pop();

      // check the end line whitespace before the url
      if (
        tree.children[tree.children.length - 1] &&
        tree.children[tree.children.length - 1].token == TOKEN_TYPE.NEW_LINE
      ) {
        tree.children.pop();
      }
    }
  }
}
