import { DialogueRecord } from '../../../../../../../../server-services/query-records/room-records';
import {
  ResourceType,
  SidebarGroup,
  SidebarResource,
  UNGROUPPED_GROUP_ID,
} from '../../../../../../../../server-services/query-records/sidebar-records';
import { WorkspaceSubscriptionDialogueEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getDialogueDataQuery,
  getSidebarGroupQuery,
  getSidebarResourceQuery,
  getSidebarUngrouppedQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onCreateDialogueEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionDialogueEventRecord
) => {
  if (!cache.getCacheDataByRequest({ query: getDialogueDataQuery, variables: { id: event.id } })) {
    let dialogueData: DialogueRecord = {
      hidden: null,
      id: event.id,
      ignore: null,
      //ordering: -1,
      seenMessageId: null,
      topMessageId: null,
      //type: ResourceType.DIALOGUE
    };

    cache.setCacheDataByRequest(
      { query: getDialogueDataQuery, variables: { id: event.id } },
      dialogueData
    );
  }

  let sidebarRes: SidebarResource = {
    id: event.id,
    ordering: event.ordering || -1,
    type: ResourceType.DIALOGUE,
    groupId: UNGROUPPED_GROUP_ID,
  };
  cache.setCacheDataByRequest(
    { query: getSidebarResourceQuery, variables: sidebarRes },
    sidebarRes
  );

  // put on the top
  let ungroupped: SidebarResource[] = cache.getCacheDataByRequest({
    query: getSidebarUngrouppedQuery,
    variables: {},
  });
  if (!ungroupped.find((resource) => resource.id === event.id)) {
    ungroupped.unshift(sidebarRes);
  }
};
