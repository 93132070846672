<h2 mat-dialog-title>{{ 'File upload' | translate }}</h2>
<div class="file-upload-dialog-body">
  <span>{{ 'Are you sure you want to upload these files?' | translate }}</span>
  <mat-dialog-content class="mat-typography">
    <div class="upload-list-container">
      <ul>
        <li *ngFor="let file of files" class="file-desc-row">
          <div class="img-container">
            <app-blob-viewer *ngIf="file.blob" [blob]="file.blob" type="image"></app-blob-viewer>
            <span *ngIf="!file.blob" class="mdi mdi-{{ file.driveFile.icon }} file-{{ file.driveFile.icon }}"></span>
          </div>
          <div class="desc-container">
            <span class="overflow">{{ file.driveFile.name }}</span>
            <div class="lower-desc">
              <span class="ext">.{{ file.driveFile.ext }}</span>
              <span>{{ file.driveFile.size | formatBytes }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </mat-dialog-content>
  <mat-checkbox color="primary" [(ngModel)]="autoName"><span translate>Auto naming when needed</span></mat-checkbox>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    <span translate>Close</span>
  </button>
  <button mat-button color="primary" (click)="closeWithOk()">
    <span translate>OK</span>
  </button>
</mat-dialog-actions>
