<div class="news-list-outer-container">
  <div class="news-back-arrow" *ngIf="hasNews && !isNewsListOnStart">
    <div class="news-back-arrow-box" (click)="scrollToStart($event)">
      <mat-icon>arrow_back_ios</mat-icon>
    </div>
  </div>
  <div #newsContainer class="news-container" (wheel)="onMouseWheel($event)">
    <div class="news-list-visibility-placeholder" [appEnterTheViewportNotifier] (visibilityChange)="visibilityChange($event, 'placeholder')"></div>
    <div
      [ngClass]="{ 'active-res': resource.id === activeResourceId }"
      *ngFor="let resource of newsList"
      [matTooltipClass]="['no-translate']"
      [matTooltipDisabled]="isMobile"
      [matTooltip]="resource.type === ResourceType.DIALOGUE ? resource.avatar?.avatarName : resource.room?.data.name"
    >
      <div class="resource-cont" [appEnterTheViewportNotifier] (visibilityChange)="visibilityChange($event, resource)" (pointerdown)="selected($event, resource)">
        <app-avatar (click)="navigateTo(resource)" [accountAvatarRecord]="resource?.avatar" [blob]="resource?.room?.data?.avatar"> </app-avatar>
        <mat-icon class="notification-icon" color="primary" *ngIf="resource && resource?.hasNewMessages"> circle </mat-icon>
        <span class="mat-small notranslate" *ngIf="false">{{ resource.type === ResourceType.DIALOGUE ? resource.avatar?.avatarName : resource.room?.data.name }}</span>
      </div>
    </div>
  </div>
</div>
