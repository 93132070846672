import { Component, OnDestroy, OnInit } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TimedOfferRecord } from 'src/app/shared/server-services/query-records/timed-offer-records';
import { acceptOfferQuery, getTimedOffersQuery } from 'src/app/shared/server-services/querys';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from 'src/app/shared/server-services/subscription-event';
import { SubscriptionService } from 'src/app/shared/server-services/subscription.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ServerRestApiService } from 'src/app/shared/services/server-rest-api.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { sideMenuActiveObserver } from 'src/app/shared/shared-observers';

@Component({
  selector: 'app-timed-offers',
  templateUrl: './timed-offers.component.html',
  styleUrls: ['./timed-offers.component.scss'],
})
export class TimedOffersComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = ['offer', 'expiration', 'action'];
  public offers: TimedOfferRecord[];
  public selectedOffer: TimedOfferRecord = null;
  public isLoading = false;

  constructor(
    private serverRestApi: ServerRestApiService,
    private dialogService: DialogService,
    private subscriptionService: SubscriptionService,
    private titleService: TitleService,
    private snackbarService: SnackBarService
  ) {
    this.loadAllOffer();

    this.titleService.setCurrentTabTitle(marker('Offers'));

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ACCOUNT_OFFER_EVENT,
      SubscriptionServiceEventType.ALL,
      this.onOfferEvent.bind(this)
    );
  }

  public onOfferEvent() {
    this.loadAllOffer();
  }

  private loadAllOffer() {
    this.serverRestApi
      .query({
        query: getTimedOffersQuery,
      })
      .then((res: TimedOfferRecord[]) => {
        this.offers = res.slice();
      });
  }

  ngOnInit(): void {
    sideMenuActiveObserver.next(false);
  }

  ngOnDestroy() {
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ACCOUNT_OFFER_EVENT,
      SubscriptionServiceEventType.ALL,
      this.onOfferEvent.bind(this)
    );
  }

  public accept(offer: TimedOfferRecord) {
    this.isLoading = true;
    this.selectedOffer = offer;
    this.serverRestApi
      .mutate({
        query: acceptOfferQuery,
        variables: {
          offerId: offer.id,
        },
      })
      .then(() => {
        this.loadAllOffer();
        this.snackbarService.showSnackbar(marker('Offer is accepted.'));
      })
      .catch((err) => {
        this.snackbarService.showSnackbar(marker('Error happened during the operation!'));
        console.error('offer accept error', err);
      })
      .finally(() => {
        this.isLoading = false;
        this.selectedOffer = null;
      });
  }
}
