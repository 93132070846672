import { Component, OnInit } from '@angular/core';
import { BaseElementComponent } from 'slate-angular';
import { Descendant } from 'slate';

export type SlateCodeLineElement = { type: 'code-line'; children: Descendant[] };

@Component({
  selector: 'app-slate-code-line',
  templateUrl: './slate-code-line.component.html',
  styleUrls: ['./slate-code-line.component.scss'],
})
export class SlateCodeLineComponent extends BaseElementComponent<SlateCodeLineElement> {}
