import { Component, Inject, INJECTOR, Injector, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { reactionIndexSrcMap } from 'src/app/shared/server-services/query-records/room-records';
import { AccountService } from '../../server-services/account.service';
import { AccountAvatarRecord } from '../../server-services/query-records/account-records';
import { RouterHandler } from '../../services/router-handler.service';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

export interface DialogData {
  reactions: { [key: string]: number };
}

interface ReactionObject {
  userId: string;
  accountAvatar: AccountAvatarRecord;
  emojiCode: number;
  emojiIcon: string;
}

interface ReactionCategory {
  emoji: number;
  count: number;
}

@Component({
  selector: 'app-reaction-dialog',
  templateUrl: './reaction-dialog.component.html',
  styleUrls: ['./reaction-dialog.component.scss'],
})
export class ReactionDialogComponent
  extends NanoDialogBaseComponent<ReactionDialogComponent>
  implements OnInit, OnDestroy
{
  public accountAvatarList: AccountAvatarRecord[];

  public isLoading = true;
  public reactionObjects: ReactionObject[] = [];

  public selectedCategory: number | null = null;
  public reactionCategories: ReactionCategory[] = [];
  /**
   * First 3 reaction categories sorted by count
   */
  public topReactionCategories: ReactionCategory[] = [];

  public reactionCount: number = 0;

  constructor(
    private accountService: AccountService,
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private routerHandler: RouterHandler
  ) {
    super(injector);
    const userIds = Object.keys(this.data.reactions);

    for (let [userId, emoji] of Object.entries(this.data.reactions)) {
      this.reactionObjects.push({
        userId: userId,
        accountAvatar: null,
        emojiCode: emoji,
        emojiIcon: this.getEmojiByIndex(emoji),
      });

      const foundEmojiCategory = this.reactionCategories.find((e) => e.emoji === emoji);
      if (foundEmojiCategory) {
        foundEmojiCategory.count++;
      } else {
        this.reactionCategories.push({
          emoji: emoji,
          count: 1,
        });
      }

      this.reactionCount++;
      this.reactionCategories = this.reactionCategories
        .sort((c1, c2) => c1.count - c2.count)
        .reverse();
      this.topReactionCategories = this.reactionCategories.slice(0, 3);
    }

    this.accountService.getAccounts(userIds).then((res) => {
      for (let accountAvatar of Object.values(res)) {
        for (let reactionObj of this.reactionObjects) {
          if (reactionObj.userId === accountAvatar.id) {
            reactionObj.accountAvatar = accountAvatar;
          }
        }
      }
      this.isLoading = false;
    });

    this.routerHandler.subscribeAll(this.onCancel);
  }

  onCancel = () => {
    this.dialogRef.close();
  };

  ngOnDestroy(): void {
    this.routerHandler.unsubscribeAll(this.onCancel);
  }

  public getEmojiByIndex = (index) => {
    return reactionIndexSrcMap[index];
  };

  ngOnInit(): void {}
}
