<div>
  <div class="sub-page">
    <h1 translate>Rejected Rooms</h1>
    <small translate>Please note that rejected rooms stay in the system for only a certain amount of time.</small>
    <div *ngIf="rejectedRooms; else loadingBlock">
      <span *ngIf="rejectedRooms.length === 0" translate>No rejected rooms.</span>
      <app-sidebar-granted-room [roomId]="resource" [rejected]="true" *ngFor="let resource of rejectedRooms"></app-sidebar-granted-room>
    </div>
    <ng-template #loadingBlock>
      <div class="center"><mat-spinner diameter="20"></mat-spinner></div>
    </ng-template>
  </div>
</div>
