import { WorkspaceSubscriptionRoomPermissionEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  roomConfigQuery,
  roomMyPermissionsQuery,
  roomPermissionsQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onModifyRoomPermissionEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionRoomPermissionEventRecord) => {
    let permission = cache.getCacheDataByRequest({
      query: roomMyPermissionsQuery,
      variables: { id: event.id },
    });
    if (!permission) {
      cache.setCacheDataByRequest(
        { query: roomPermissionsQuery, variables: { id: event.id } },
        event
      );
    } else {
      if (event.msgBrdReadOnly != undefined) {
        permission.msgBrdReadOnly = !!event.msgBrdReadOnly;
      }
      if (event.allowAnonymous != undefined) {
        permission.allowAnonymous = !!event.allowAnonymous;
      }
      if (event.noReactions != undefined) {
        permission.noReactions = !!event.noReactions;
      }
    }

    //if cache data for room config exists, remove it
    cache.deleteCacheDataByRequest({ query: roomConfigQuery, variables: { id: event.id } });
  };
