import { Component, Inject, INJECTOR, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';
import { NanoValidators } from 'src/app/shared/nano-validators';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-change-email-dialog',
  templateUrl: './change-email-dialog.component.html',
  styleUrls: ['./change-email-dialog.component.scss'],
})
export class ChangeEmailDialogComponent
  extends NanoDialogBaseComponent<ChangeEmailDialogComponent>
  implements OnInit
{
  inputsValid: boolean = false;

  public emailForm = this.fb.group({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    email2: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    @Inject(INJECTOR) injector: Injector,
    public dialogService: DialogService,
    public fb: UntypedFormBuilder
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.emailForm
      .get('email2')
      .addValidators(NanoValidators.controlEquals(this.emailForm.get('email')));
  }

  changeEmail() {
    this.dialogRef.close(this.emailForm.get('email').value);
  }
}
