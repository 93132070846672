import { Component } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {
  clearUserLoginSessions,
  deleteLoginSession,
  getLoginSessions,
} from 'src/app/shared/server-services/querys';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ServerRestApiService } from 'src/app/shared/services/server-rest-api.service';

export enum LoginSessionScope {
  USER = 'user',
  NANO = 'nano',
}

export type LoginSession = {
  expiration: number;
  expiry_minutes: number;
  id: string;
  scopes: LoginSessionScope[];
  self?: true;
};

@Component({
  selector: 'app-session-handler',
  templateUrl: './session-handler.component.html',
  styleUrls: ['./session-handler.component.scss'],
})
export class SessionHandlerComponent {
  public dataLoading = false;
  public sessions: LoginSession[];
  public LoginSessionScope = LoginSessionScope;

  constructor(
    private serverRestApiService: ServerRestApiService,
    private dialogService: DialogService
  ) {
    this.loadData();
  }

  public loadData() {
    this.dataLoading = true;
    this.serverRestApiService
      .query({
        query: getLoginSessions,
      })
      .then((sessions: LoginSession[]) => {
        this.sessions = sessions.sort((s1, s2) => {
          if (
            s1.self ||
            (s1.scopes.includes(LoginSessionScope.USER) &&
              !s2.scopes.includes(LoginSessionScope.USER))
          ) {
            return -1;
          }
          if (
            s2.self ||
            (s2.scopes.includes(LoginSessionScope.USER) &&
              !s1.scopes.includes(LoginSessionScope.USER))
          ) {
            return 1;
          }

          return 0;
        });
      })
      .catch((err) => {
        console.error('login session err', err);
        this.dialogService.openAlertDialog(
          marker('Error'),
          marker('Error happened during the data loading')
        );
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  public kick(s: LoginSession) {
    this.serverRestApiService
      .mutate({
        query: deleteLoginSession,
        variables: {
          sessionId: s.id,
        },
      })
      .then(() => {
        let pos = this.sessions.indexOf(s);
        if (pos > -1) {
          this.sessions.splice(pos, 1);
        }
      })
      .catch((err) => {
        this.dialogService.openAlertDialog(
          marker('Error'),
          marker('Error happened during the operation')
        );
      });
  }

  public kickAllOtherUser() {
    this.dialogService
      .openConfirmDialog(
        marker('Remove other sessions'),
        marker('All other login sessions will be removed. Continue?')
      )
      .subscribe((confirm) => {
        if (!confirm) return;

        this.serverRestApiService
          .mutate({
            query: clearUserLoginSessions,
          })
          .then(() => {
            this.loadData();
          })
          .catch((err) => {
            this.dialogService.openAlertDialog(
              marker('Error'),
              marker('Error happened during the operation')
            );
          });
      });
  }
}
