/**
 * For account root keys by using the account keyring.
Secret keys of public-key cryptography for example.
 */

import { CryptoContext } from '../../base';
import { AccountRootKeyConfigDumpArgs, AccountRootKeyConfigLoadArgs } from './args';
import { AccountRootKeyConfigV1 } from './v1';

export let ACCOUNT_ROOT_KEY_CRYPTO_CONTEXT = new CryptoContext<
  AccountRootKeyConfigLoadArgs,
  AccountRootKeyConfigDumpArgs
>([new AccountRootKeyConfigV1()]);
