import { AbstractSymmetricKeyGenerator } from '../interface';
import * as nacl from 'tweetnacl';

/**
 * Create a shared secret based encryption key using the X25519 function.
 */
export class Curve25519SharedSecretGenerator extends AbstractSymmetricKeyGenerator {
  /**
     * Generating derived keys from ECDH shared secrets is easy, but using the secret as-is may be discouraged
        because different schemes require the keys to rotate. For example the same key should not be used with
        too many nonce values to avoid reuse with most AES modes.
        To derive safely usable keys HKDF (RFC5869) can be used used.
     */
  public static new_key(secret_key: Uint8Array, other_public_key: Uint8Array): Uint8Array {
    return nacl.box.before(other_public_key, secret_key);
  }

  public static describe(): string {
    return 'Curve25519-ECDH';
  }
}
