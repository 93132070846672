import { Component, Inject, INJECTOR, Injector, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from '../../server-services/account.service';
import { AccountAvatarRecord } from '../../server-services/query-records/account-records';
import { RouterHandler } from '../../services/router-handler.service';
import { NanoDialogBaseComponent } from '../nano-base-dialog';

export interface DialogData {
  seenBy: string[];
}

@Component({
  selector: 'app-seen-dialog',
  templateUrl: './seen-dialog.component.html',
  styleUrls: ['./seen-dialog.component.scss'],
})
export class SeenDialogComponent
  extends NanoDialogBaseComponent<SeenDialogComponent>
  implements OnInit, OnDestroy
{
  public accountAvatarList: AccountAvatarRecord[];

  public isLoading = true;

  constructor(
    private accountService: AccountService,
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,

    private routerHandler: RouterHandler
  ) {
    super(injector);
    this.accountService.getAccounts(this.data.seenBy).then((res) => {
      this.accountAvatarList = Object.values(res);
      this.isLoading = false;
    });

    this.routerHandler.subscribeAll(this.onCancel);
  }

  onCancel = () => {
    this.dialogRef.close();
  };

  ngOnDestroy(): void {
    this.routerHandler.unsubscribeAll(this.onCancel);
  }

  ngOnInit(): void {}
}
