import {
  MessageRecord,
  MessageRecordType,
} from '../../../../../../server-services/query-records/room-records';
import { ResponseMergerHandler } from '../../../cache-logic-interface';
import { messageStreamMerger, messageStreamVoidFixer } from './room-message-query-merger';

export let customDialogueMessageQueryMerger: ResponseMergerHandler = function (
  cache,
  request,
  response: MessageRecord[]
) {
  // quick access to message
  if (!cache.getCacheData('messages')[request.variables.peerId]) {
    cache.getCacheData('messages')[request.variables.peerId] = {};
  }

  let cacheKey = cache.getCacheLogic().makeCacheDataPath(request);

  let cachedMessages = cache.getCacheData(cacheKey);

  if (!cachedMessages?.length) {
    cachedMessages = [
      { type: MessageRecordType.END },
      { type: MessageRecordType.VOID },
      { type: MessageRecordType.END },
    ];
    cache.setCacheData(cacheKey, cachedMessages);
  }

  // init cachedMessages
  response.forEach((msg) => {
    if ((<MessageRecord>msg)?.id) {
      cache.getCacheData('messages')[request.variables.peerId][(<MessageRecord>msg)?.id] = msg;
    }
  });

  messageStreamMerger(cachedMessages, request, response);
  // sets beforeMsgId and afterMsgId on voids
  messageStreamVoidFixer(cachedMessages);
};
