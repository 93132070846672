import { WorkspaceSubscriptionAccountContactEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getAllAccountIdQuery,
  getContactListIdsQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onCreateAccountContactSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionAccountContactEventRecord
) => {
  let contactList: string[] = cache.getCacheDataByRequest({
    query: getContactListIdsQuery,
    variables: {},
  });

  if (!contactList.includes(event.peerId)) {
    contactList.push(event.peerId);
  }

  let allAcc: string[] = cache.getCacheDataByRequest({
    query: getAllAccountIdQuery,
    variables: {},
  });

  let found = false;
  for (let i = 0; i < allAcc.length; i++) {
    if (allAcc[i] == event.peerId) {
      found = true;
      break;
    }
  }
  if (!found) {
    allAcc.push(event.peerId);
  }
};
