<app-blocking-loader [loading]="loading"></app-blocking-loader>

<div class="button-box">
  <div>
    <button class="close-button" mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-tab-group mat-tab-group-headerless dynamicHeight [selectedIndex]="activeTab">
  <!-- 0 - Nano Client list -->
  <mat-tab>
    <div class="tab-content">
      <div class="title-bar">
        <span mat-dialog-title translate>Online Nano Clients</span>
      </div>
      <!-- No Nano client -->
      <div class="no-nano-container" *ngIf="nanoSlots.length === 0; else nanosListBlock">
        <img src="assets/img/ui/icon_nano.svg" />
        <div class="no-nano-text">
          <p translate>You need to have a Nano client installed and running on your PC.</p>
          <p translate>Download your Nano client from <a target="_blank" [href]="websiteDownloadPage" translate>here</a>.</p>
          <p translate>You also need an active Nano license for the Nano client to work properly. Check the status of your licenses at the <a translate routerLink="/licenses">licenses</a> page.</p>
          <p translate>For more help, see the <a target="_blank" [href]="'https://www.clarabot.info/nano/en/webapp-guide/client/' | translate" translate>online documentation</a>.</p>
        </div>
      </div>
      <ng-template #nanosListBlock>
        <div class="search-container">
          <mat-form-field appearance="outline">
            <mat-label><span translate class="notranslate">Filter by name</span></mat-label>
            <input matInput [placeholder]="'My Nano client\'s name' | translate" [formControl]="nanoFilterControl" />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>

          <button mat-icon-button (click)="refreshNanoSlots()" color="primary"><mat-icon>refresh</mat-icon></button>
        </div>
        <mat-dialog-content>
          <div class="nano-list-container">
            <div class="nano-item" *ngFor="let nanoSlot of filteredNanoSlots" [ngClass]="{ etalonSlot: nanoSlot.isEtalonSlot }">
              <ng-container *ngIf="nanoSlot.detail">
                <div class="filled-slot" (click)="selectNanoSlot(nanoSlot)" matRipple>
                  <div class="nano-icon">
                    <mat-icon>computer</mat-icon>
                    <img src="assets/img/ui/icon_nano.svg" *ngIf="!isNanoUpToDate(nanoSlot)" />
                    <mat-icon class="warn-icon" color="primary" [matTooltip]="'Nano Client version out of date' | translate" *ngIf="isNanoUpToDate(nanoSlot)">warning</mat-icon>
                  </div>
                  <div class="nano-data">
                    <span class="nano-client-name" class="notranslate">{{ nanoSlot.detail?.name }}</span>
                    <span class="valid-date-text">
                      <span class="primary" *ngIf="nanoSlot.isEtalonSlot; else validUntilBlock" translate>Forever with ETAL</span>
                      <ng-template #validUntilBlock>
                        <span>{{ nanoSlot.expiration * 1000 | date : 'longDate' }}</span>
                      </ng-template>
                    </span>
                  </div>
                  <img class="etalon-logo" *ngIf="nanoSlot.isEtalonSlot" src="assets/img/ui/Etalon_logo_small.png" [matTooltip]="'Powered by ETALON for owning 1 ETAL token.' | translate" />
                </div>
              </ng-container>
              <ng-container *ngIf="!nanoSlot.detail">
                <ng-container *ngIf="nanoSlot.occupiedSlot">
                  <span class="occupied-slot-container" [matTooltip]="getLastActiveDate(nanoSlot)" [matTooltipClass]="['no-translate']">
                    <div class="slot-icon">
                      <mat-icon>lock</mat-icon>
                    </div>
                    <span class="slot-text" translate>Occupied slot</span>
                    <img class="etalon-logo" *ngIf="nanoSlot.isEtalonSlot" src="assets/img/ui/Etalon_logo_small.png" [matTooltip]="'Powered by ETALON for owning 1 ETAL token.' | translate" />
                  </span>
                </ng-container>
                <ng-container *ngIf="!nanoSlot.occupiedSlot">
                  <span class="empty-slot-container">
                    <div class="slot-icon"></div>
                    <span class="slot-text" translate>Empty slot</span>
                    <img class="etalon-logo" *ngIf="nanoSlot.isEtalonSlot" src="assets/img/ui/Etalon_logo_small.png" [matTooltip]="'Powered by ETALON for owning 1 ETAL token.' | translate" />
                  </span>
                </ng-container>
              </ng-container>
            </div>

            <div class="nano-item" *ngIf="waitingNanos > 0">
              <span class="waiting-nano-container">
                <span class="mdi mdi-axis-z-arrow-lock"></span>
                <div class="waiting-text-container">
                  <span translate>Not enough free slots for connecting Nanos.</span>
                  <span translate>Waiting Nanos: {{ waitingNanos }}</span>
                </div>
              </span>
            </div>
          </div>
        </mat-dialog-content>
      </ng-template>

      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button mat-dialog-close><span translate>Close</span></button>
          <a routerLink="licenses"
            ><button color="primary" mat-raised-button mat-dialog-close><span translate>Licenses</span></button></a
          >
        </div>
      </mat-dialog-actions>
    </div>
  </mat-tab>

  <!-- 1 - Nano detail -->
  <mat-tab>
    <div class="tab-content" *ngIf="selectedNanoSlot">
      <div class="title-bar">
        <span mat-dialog-title translate
          >Nano Client Manager
          <mat-icon
            [matTooltip]="
              'Edit the settings for this Nano Client by clicking the gear icon, create a new Drive by clicking the \'New Drive\' button, or select an existing Drive to view its details.' | translate
            "
            [matTooltipEnableToggleOnMobile]="true"
            >info</mat-icon
          ></span
        >
      </div>

      <mat-divider></mat-divider>
      <div class="nano-client-data">
        <img src="assets/img/ui/icon_nano.svg" />
        <span class="nano-client-name">{{ selectedNanoSlot.detail?.name }}</span>
        <button mat-icon-button [matTooltip]="'Nano client settings' | translate" (click)="openClientSettings()">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
      <mat-divider class="closer"></mat-divider>
      <mat-dialog-content class="no-padding">
        <div class="no-drive" *ngIf="selectedNanoSlot.detail.remoteConfig.drives.length === 0; else driveListBlock">
          <span class="mdi mdi-network-off-outline"></span>
          <span translate>No drives found. Create a drive to access your files.</span>
        </div>
        <ng-template #driveListBlock>
          <div class="drive-list">
            <div class="drive-item" *ngFor="let drive of selectedNanoSlot.detail.remoteConfig.drives" matRipple (click)="selectDrive(drive)">
              <span class="mdi mdi-folder-network-outline"></span>
              <div class="drive-info">
                <span
                  class="folder-name overflowing-text-container-ellipsis notranslate"
                  [matTooltip]="getTopLevelFolderName(drive.path)"
                  [matTooltipClass]="['no-translate']"
                  [matTooltipShowDelay]="tooltipDelay"
                  >{{ getTopLevelFolderName(drive.path) }}</span
                >
                <span class="folder-path overflowing-text-container-ellipsis rtl">
                  <span class="trl-overwrite" [matTooltip]="getRestOfPath(drive.path)" [matTooltipClass]="['no-translate']">{{ getRestOfPath(drive.path) }}</span>
                </span>
              </div>
              <div class="room-avatars">
                <app-avatar *ngFor="let room of drive.roomDetails" [blob]="room.avatar" [matTooltip]="room.name" [matTooltipClass]="['no-translate']"></app-avatar>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button (click)="back()"><span translate>Back</span></button>
          <button color="primary" mat-raised-button (click)="openNewDriveTab()"><span translate>New Drive</span></button>
        </div>
      </mat-dialog-actions>
    </div>
  </mat-tab>

  <!-- 2 - Create drive -->
  <mat-tab>
    <div class="tab-content" *ngIf="selectedNanoSlot">
      <div class="title-bar">
        <span mat-dialog-title translate
          >New Drive
          <mat-icon [matTooltip]="'Choose an existing folder on your PC, or create a new folder to use as a Nano Drive.' | translate" [matTooltipEnableToggleOnMobile]="true">info</mat-icon>
        </span>
      </div>
      <mat-divider></mat-divider>
      <div class="nano-client-data drive-data">
        <img src="assets/img/ui/icon_nano.svg" />
        <div class="drive-data-text">
          <span class="overflowing-text-container-ellipsis" [matTooltip]="selectedNanoSlot.detail?.name" [matTooltipShowDelay]="tooltipDelay">{{ selectedNanoSlot.detail?.name }}</span>
          <span class="drive-current-path overflowing-text-container-ellipsis rtl">
            <span class="trl-overwrite" [matTooltip]="getPath()">{{ getPath() }}</span>
          </span>
        </div>
      </div>
      <mat-divider class="closer"></mat-divider>
      <mat-dialog-content class="no-padding">
        <div class="drive-folder-container">
          <div class="drive-folder-item" matRipple (click)="folderBack()" *ngIf="currentPath.length > 0">
            <span class="mdi mdi-chevron-left"></span>
            <span class="drive-folder-name">[ .. ]</span>
          </div>
          <div
            *ngFor="let folder of folders"
            class="drive-folder-item"
            [ngClass]="{ unresolved: folder.unresolved }"
            matRipple
            [matRippleDisabled]="folder.unresolved"
            (click)="!folder.unresolved && openFolder(folder.name)"
          >
            <span class="mdi mdi-harddisk" *ngIf="isRootFolder(folder.name); else notRootFolderBlock"></span>
            <ng-template #notRootFolderBlock>
              <span class="mdi mdi-folder"></span>
            </ng-template>
            <span
              *ngIf="!(isRootFolder(folder.name) && !folder.unresolved && folder.free && folder.total)"
              class="drive-folder-outer drive-folder-name overflowing-text-container-ellipsis notranslate"
            >
              {{ folder.name }}
            </span>
            <div *ngIf="isRootFolder(folder.name) && !folder.unresolved && folder.free && folder.total" class="storage-stats">
              <span class="drive-folder-name overflowing-text-container-ellipsis notranslate">
                {{ folder.name }}
              </span>
              <div>{{ (folder.total - folder.free) * 1073741824 | formatBytes }} / {{ folder.total * 1073741824 | formatBytes }}</div>
              <mat-progress-bar #storage_bar class="example-margin" [color]="storage_bar.value > 90 ? 'warn' : 'primary'" [value]="((folder.total - folder.free) / folder.total) * 100">
              </mat-progress-bar>
            </div>
            <span class="mdi mdi-chevron-right"></span>
          </div>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button (click)="backToDrives()"><span translate>Back</span></button>
          <button mat-button [disabled]="currentPath.length === 0" (click)="newFolderDialog()"><span translate>New Folder</span></button>
          <button color="primary" mat-raised-button [disabled]="currentPath.length === 0" (click)="createDrive()"><span translate>Create Drive</span></button>
        </div>
      </mat-dialog-actions>
    </div>
  </mat-tab>

  <!-- 3 - Drive detail -->
  <mat-tab>
    <div class="tab-content" *ngIf="selectedNanoDrive">
      <div class="title-bar">
        <span mat-dialog-title translate
          >Drive Detail
          <mat-icon [matTooltip]="'Here are all the rooms to which this Drive is attached to.' | translate" [matTooltipEnableToggleOnMobile]="true">info</mat-icon>
        </span>
      </div>

      <mat-divider></mat-divider>
      <div class="nano-client-data drive-data">
        <span class="mdi mdi-folder-network-outline"></span>
        <div class="drive-data-text">
          <span class="overflowing-text-container-ellipsis notranslate" [matTooltip]="getTopLevelFolderName(selectedNanoDrive.path)" [matTooltipShowDelay]="tooltipDelay">{{
            getTopLevelFolderName(selectedNanoDrive.path)
          }}</span>
          <span class="drive-current-path overflowing-text-container-ellipsis rtl">
            <span class="trl-overwrite notranslate" [matTooltip]="getRestOfPath(selectedNanoDrive.path)">{{ getRestOfPath(selectedNanoDrive.path) }}</span>
          </span>
        </div>
        <button mat-raised-button class="delete-button" (click)="deleteDrive()"><span translate>Delete</span></button>
      </div>
      <mat-divider class="closer"></mat-divider>
      <mat-dialog-content>
        <div class="no-room" *ngIf="selectedNanoDrive.roomDetails.length === 0; else roomListBlock">
          <span class="mdi mdi-network-off-outline"></span>
          <span translate>No attached rooms found. Attach a room to access your files.</span>
        </div>
        <ng-template #roomListBlock>
          <div class="room-list">
            <div class="room-item" *ngFor="let room of selectedNanoDrive.roomDetails">
              <div class="room-list-data">
                <app-avatar [blob]="room.avatar"></app-avatar>
                <span class="room-list-name overflowing-text-container-ellipsis notranslate">{{ room.name }}</span>
              </div>
              <button mat-raised-button mat-dialog-close [routerLink]="'/room/' + room.id + '/drive'"><span translate>Open</span></button>
              <button color="primary" mat-raised-button (click)="detachRoom(room)"><span translate>Detach</span></button>
            </div>
          </div>
        </ng-template>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button (click)="backToDrives()"><span translate>Back</span></button>
          <button color="primary" mat-raised-button (click)="openAttachRoom()"><span translate>Attach Room</span></button>
        </div>
      </mat-dialog-actions>
    </div>
  </mat-tab>

  <!-- 4 - Attach room -->
  <mat-tab>
    <div class="tab-content" *ngIf="selectedNanoSlot && selectedNanoDrive">
      <div class="title-bar">
        <span mat-dialog-title translate
          >Attach Room
          <mat-icon [matTooltip]="'Attach your Drive to one of your rooms by clicking on the room in the list below.' | translate" [matTooltipEnableToggleOnMobile]="true">info</mat-icon>
        </span>
      </div>

      <mat-divider></mat-divider>
      <div class="nano-client-data drive-data">
        <img src="assets/img/ui/icon_nano.svg" />
        <div class="drive-data-text">
          <span class="overflowing-text-container-ellipsis notranslate" [matTooltip]="selectedNanoSlot.detail?.name" [matTooltipClass]="['no-translate']" [matTooltipShowDelay]="tooltipDelay">{{
            selectedNanoSlot.detail?.name
          }}</span>
          <span class="drive-current-path overflowing-text-container-ellipsis rtl">
            <span class="trl-overwrite notranslate" [matTooltip]="selectedNanoDrive.path" [matTooltipClass]="['no-translate']">{{ selectedNanoDrive.path }}</span>
          </span>
        </div>
      </div>
      <mat-divider class="closer"></mat-divider>
      <mat-dialog-content>
        <div class="available-room-container">
          <div
            class="available-room-item"
            *ngFor="let room of availableRooms"
            [ngClass]="{ taken: !room.isValid }"
            [matTooltip]="'This room is already serving a Nano Drive.' | translate"
            [matTooltipDisabled]="room.isValid"
            matRipple
            [matRippleDisabled]="!room.isValid"
            (click)="selectRoom(room)"
          >
            <div class="available-room-avatar-container">
              <app-avatar [blob]="room.avatar"></app-avatar>
              <span class="mdi mdi-laptop" *ngIf="!room.isValid"></span>
            </div>
            <span class="available-room-name notranslate">{{ room.name }}</span>
          </div>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button (click)="back(3)"><span translate>Cancel</span></button>
        </div>
      </mat-dialog-actions>
    </div>
  </mat-tab>

  <!-- 5 - Drive preview -->
  <mat-tab>
    <div *ngIf="selectedRoomId">
      <app-drive-overview [roomId]="selectedRoomId" [previewSlot]="selectedNanoSlot" (backEvent)="back(3)" (roomChangeEvent)="backToAttachRoom()" (saveEvent)="attachRoom()"></app-drive-overview>
    </div>
  </mat-tab>

  <!-- 6 - Nano Client settings -->
  <mat-tab>
    <div class="tab-content" *ngIf="selectedNanoSlot">
      <div class="title-bar">
        <span mat-dialog-title translate
          >Nano Client settings
          <mat-icon [matTooltip]="'Edit the settings for this Nano Client here.' | translate" [matTooltipEnableToggleOnMobile]="true">info</mat-icon>
        </span>
      </div>
      <mat-dialog-content>
        <mat-divider></mat-divider>

        <form class="input-form" [formGroup]="nanoClientForm">
          <div class="input-row">
            <img class="logo" src="assets/img/ui/icon_nano.svg" />
            <mat-form-field appearance="outline">
              <mat-label><span translate>Nano Client name</span></mat-label>
              <input matInput formControlName="nanoClientName" />
              <mat-error *ngIf="nanoClientForm.get('nanoClientName').hasError('required')"> <span translate>Please enter a Nano client name</span></mat-error>
              <mat-error *ngIf="nanoClientForm.get('nanoClientName').hasError('maxlength')"> <span translate>Max length reached</span></mat-error>
            </mat-form-field>
          </div>

          <div class="input-row">
            <mat-icon>event_available</mat-icon>
            <div class="input-text" translate>
              <span class="input-text-title" translate>Valid until</span>
            </div>
            <span>{{ selectedNanoSlot.expiration * 1000 | date : 'longDate' }}</span>
          </div>

          <div class="input-row">
            <mat-icon>no_accounts</mat-icon>
            <div class="input-text" translate>
              <span class="input-text-title" translate>Deny anonymous</span>
              <span class="input-text-description" translate>This overrides the anonymous access setting of all connected rooms.</span>
            </div>
            <mat-slide-toggle color="primary" formControlName="denyAnonymous"></mat-slide-toggle>
          </div>

          <div class="input-row">
            <mat-icon>assignment_turned_in</mat-icon>
            <div class="input-text" translate>
              <span class="input-text-title" translate>Explicit peer trust</span>
              <span class="input-text-description" translate>Only peers that you have explicitly trusted can interact with the Nano from any room. This is not applicable to anonymous peers.</span>
            </div>
            <mat-slide-toggle color="primary" formControlName="explicitPeerTrust"></mat-slide-toggle>
          </div>

          <div class="button-row" *ngIf="isNanoLogFeatureAvailable">
            <mat-icon>article</mat-icon>
            <button mat-stroked-button (click)="openNanoLogs()"><span translate>Open Nano Logs</span></button>
          </div>

          <div class="button-row">
            <mat-icon>key</mat-icon>
            <button mat-stroked-button (click)="editNanoPassword()"><span translate>Set password</span></button>
          </div>
        </form>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <div class="action-container">
          <button mat-button (click)="discardEditSettings()"><span translate>Cancel</span></button>
          <button mat-raised-button color="primary" [disabled]="!(nanoClientForm.valid && nanoClientForm.dirty)" (click)="editSettings()"><span translate>Save</span></button>
        </div>
      </mat-dialog-actions>
    </div>
  </mat-tab>

  <!-- 7 - Nano Logs -->
  <mat-tab>
    <div class="tab-content wide-tab-content" *ngIf="selectedNanoSlot">
      <div class="title-bar">
        <span mat-dialog-title translate
          >Nano Logs
          <mat-icon [matTooltip]="'Edit the settings for this Nano Client here.' | translate" [matTooltipEnableToggleOnMobile]="true">info</mat-icon>
        </span>
      </div>
      <mat-dialog-content>
        <div id="nano-logs">
          <div id="nano-log-chooser">
            <div class="log-file" [ngClass]="{ selected: selectedLogFile === LOG_ACCESS }" (click)="selectLogFileToRead(LOG_ACCESS)">
              <mat-icon>description</mat-icon>
              <span>Access Log</span>
            </div>
            <div class="log-file" [ngClass]="{ selected: selectedLogFile === LOG_SERVICE }" (click)="selectLogFileToRead(LOG_SERVICE)">
              <mat-icon>description</mat-icon>
              <span>Service Log</span>
            </div>
          </div>

          <div id="nano-log-content" *ngIf="selectedLogFile">
            <div *ngFor="let line of logs[selectedLogFile].displayWindow">{{ line }}</div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <div class="action-container">
          <div id="log-navigator" *ngIf="selectedLogFile">
            <button mat-button (click)="showLogPage(-1)" [disabled]="showLogIsLoading">
              <mat-icon>keyboard_double_arrow_left</mat-icon>
            </button>
            <button mat-button (click)="showLogPage(logs[selectedLogFile].displayPage + 1)" [disabled]="showLogIsLoading">
              <mat-icon>arrow_back</mat-icon>
            </button>
            <div id="page-cont">
              <span translate>Page:</span
              ><input type="text" [attr.disabled]="showLogIsLoading ? '' : null" id="page-input" [value]="logs[selectedLogFile].displayPage" (input)="showLogPage($event.target['value'])" /><span
                >/{{ logs[selectedLogFile].log.length / LOG_LINE_PER_PAGE - 1 | number : '1.0-0' }}{{ logs[selectedLogFile].isReachedEnd ? '' : '+' }}</span
              >
            </div>
            <button mat-button [disabled]="showLogIsLoading || logs[selectedLogFile].displayPage <= 0" (click)="showLogPage(logs[selectedLogFile].displayPage - 1)">
              <mat-icon>arrow_forward</mat-icon>
            </button>
            <button mat-button [disabled]="showLogIsLoading || logs[selectedLogFile].displayPage <= 0" (click)="showLogPage(0)">
              <mat-icon>keyboard_double_arrow_right</mat-icon>
            </button>
          </div>

          <button *ngIf="selectedLogFile" mat-button (click)="mergeNewMessages(); showLogPage(0)" [disabled]="logs[selectedLogFile].stream.length === 0">
            <span translate>Refresh</span
            ><span *ngIf="logs[selectedLogFile].stream.length > 0">({{ logs[selectedLogFile].stream.length > 9999 ? '9999+' : logs[selectedLogFile].stream.length }})</span>
          </button>
          <button mat-button (click)="goBackFromNanoLog()"><span translate>Cancel</span></button>
        </div>
      </mat-dialog-actions>
    </div>
  </mat-tab>
</mat-tab-group>
