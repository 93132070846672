<h1 mat-dialog-title translate>Outgoing link</h1>
<div mat-dialog-content>
  <p translate>Warning! You have clicked on an outgoing link. Are you sure you trust this link? If yes, click accept to proceed.</p>
  <p translate>
    Link domain: <span>{{ linkDomain }}</span>
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="navigationAccepted(false)"><span translate>Back</span></button>
  <a (click)="navigationAccepted(true)" target="_blank" [href]="linkHref"
    ><button mat-button color="primary"><span translate>Accept</span></button></a
  >
</div>
