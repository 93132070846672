<div class="input-container">
  <div>
    <img id="logo" [src]="isDarkTheme ? '/assets/img/ui/logo_nano-dark.svg' : '/assets/img/ui/logo_nano.svg'" />
    <div class="flex-column" *ngIf="!isSendSuccess">
      <h1 class="mat-caption" translate>Account recovery</h1>
      <p translate>Confirm your email address so we can send an email with the account recovery information.</p>
      <form class="form-container" [formGroup]="emailForm" (submit)="start()">
        <mat-form-field>
          <mat-label translate>Email address</mat-label>
          <input type="email" matInput formControlName="email" placeholder="Ex. pat@example.com" />
          <mat-error *ngIf="emailForm.get('email').hasError('required')"> Please enter your <strong>email address</strong> </mat-error>
          <mat-error *ngIf="emailForm.get('email').hasError('email')"> Please enter a <strong>valid</strong> email address </mat-error>
        </mat-form-field>
        <button class="action-button" type="submit" [disabled]="isPending || !emailForm.valid" mat-raised-button color="primary">
          <span *ngIf="!isPending && !isSendSuccess" translate>Send</span>
          <mat-icon *ngIf="isPending"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          <mat-icon *ngIf="isSendSuccess">done</mat-icon>
        </button>
      </form>
    </div>
    <div class="flex-center" *ngIf="isSendSuccess">
      <div translate>We sent an email to your address, please follow the instructions to recover your account.</div>
      <div class="icon-center"><mat-icon>email</mat-icon></div>
    </div>
    <div class="nav-buttons">
      <a routerLink="/login"
        ><button mat-button color="primary"><span translate>Back to login</span></button></a
      >
    </div>
  </div>
</div>
