import { Component, Inject, INJECTOR, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';
import { NanoValidators } from 'src/app/shared/nano-validators';

@Component({
  selector: 'app-change-username-dialog',
  templateUrl: './change-username-dialog.component.html',
  styleUrls: ['./change-username-dialog.component.scss'],
})
export class ChangeUsernameDialogComponent
  extends NanoDialogBaseComponent<ChangeUsernameDialogComponent>
  implements OnInit
{
  originalName: string;

  usernameForm = new UntypedFormGroup({
    userName: new UntypedFormControl(''),
  });

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public changeUsernameData: ChangeUsernameData
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.originalName = this.changeUsernameData.userName;

    var userNameControl = this.usernameForm.get('userName');
    userNameControl.addValidators([, Validators.required, Validators.maxLength(31)]);

    if (this.originalName && this.originalName.length > 0) {
      userNameControl.setValue(this.originalName);
      userNameControl.addValidators([NanoValidators.stringNotEquals(this.originalName)]);
    }
  }

  changeUsername() {
    console.log(this.usernameForm.get('userName').value.length);
    this.dialogRef.close(this.usernameForm.get('userName').value);
  }
}

interface ChangeUsernameData {
  userName: string;
  firstSetup: boolean;
}
