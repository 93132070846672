<h1 mat-dialog-title translate>Create new folder</h1>
<form [formGroup]="filteredFolderNameGroup" (submit)="onSubmit()">
  <div mat-dialog-content>
    <mat-form-field class="folder-field">
      <mat-label translate>Name</mat-label>
      <input matInput required formControlName="folderName" />
      <mat-error *ngIf="filteredFolderNameGroup.get('folderName').hasError('required')" translate> Please enter a name </mat-error>
      <mat-error *ngIf="filteredFolderNameGroup.get('folderName').hasError('maxlength')" translate> Name is too long </mat-error>
      <mat-error *ngIf="filteredFolderNameGroup.get('folderName').hasError('noSlashes')" translate> Slashes are not allowed </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-button color="primary" [disabled]="!filteredFolderNameGroup.valid"><span translate>Save</span></button>
  </div>
</form>
