import { RoomKeyring } from '../../keyring/room';
import { AbstractConfigDumpArgs, AbstractConfigLoadArgs } from '../base';

export class RoomConfigLoadArgs extends AbstractConfigLoadArgs {
  public room_kr;
  public timestamp;

  constructor(cipher: Uint8Array, room_kr: RoomKeyring, timestamp: number) {
    super(cipher);
    this.room_kr = room_kr;
    this.timestamp = timestamp;
  }
}

export class RoomConfigDumpArgs extends AbstractConfigDumpArgs {
  public plain;
  public room_kr;
  public timestamp;

  constructor(plain: Uint8Array, room_kr: RoomKeyring, timestamp: number) {
    super();
    this.plain = plain;
    this.room_kr = room_kr;
    this.timestamp = timestamp;
  }
}
