<!-- todo translation -->
<h2 mat-dialog-title>{{ 'Download files' | translate }}</h2>
<mat-dialog-content class="mat-typography">
  <div class="refresh-box">
    <button mat-icon-button [ngClass]="{ pulse: pathError || pinnedKeyError || loadError }" [disabled]="refreshClicked" (click)="refresh()" [matTooltip]="'Refresh' | translate">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div>
    <button mat-button translate (click)="changeFileSelection('selected')">Select all</button>
    <button mat-button translate (click)="changeFileSelection('empty')">Deselect all</button>
  </div>
  <div id="file-box">
    <div *ngFor="let singleFile of rootFiles">
      <app-file-row *ngIf="singleFile.type === 'folder'" [data]="singleFile" [depth]="startingDepth"> </app-file-row>
    </div>
    <div *ngFor="let singleFile of rootFiles">
      <app-file-row *ngIf="singleFile.type === 'file'" [data]="singleFile" [depth]="startingDepth"> </app-file-row>
    </div>
  </div>
  <div class="summary-box">
    <div class="count-summary-box">
      <span>{{ 'Folders' | translate }}: {{ folderCount }}</span>
      <span>{{ 'Files' | translate }}: {{ fileCount }}</span>
    </div>
    <div class="size-summary-box">
      <span>{{ 'Size' | translate }}: {{ sumSize | formatBytes }}</span>
    </div>
  </div>
  <!-- <div id="progress-bar">{{ currentProgress }} / {{ maxProgress }}</div> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    <span translate>Close</span>
  </button>
  <button [disabled]="isContentLoading || pathError || pinnedKeyError || loadError" mat-button color="primary" (click)="closeWithOk()">
    <span translate>Download</span>
  </button>
</mat-dialog-actions>
