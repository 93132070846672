import { AbstractCryptoConfig } from '../base';
import { AccountSignatureConfigDumpArgs, AccountSignatureConfigLoadArgs } from './args';
import { Ed25519DS } from '../../base/ds/ec';
import { AbstractAccountKeyring } from '../../keyring/account_base';
import { concatByteArray } from '../../utility';

/**
 * Account signature: detached Ed25519
 */
export class AccountSignatureConfigV1 extends AbstractCryptoConfig<
  AccountSignatureConfigLoadArgs,
  AccountSignatureConfigDumpArgs
> {
  constructor(never_dump: boolean = false) {
    super(1, never_dump);
  }

  public load(args: AccountSignatureConfigLoadArgs): Promise<Uint8Array> {
    return Ed25519DS.verify(
      args.cipher.slice(this.version.byteLength),
      args.peer_kr.get_public_key(AbstractAccountKeyring.ED25519),
      args.plain
    );
  }

  public dump(args: AccountSignatureConfigDumpArgs): Promise<Uint8Array> {
    return Ed25519DS.sign(
      args.plain,
      concatByteArray(
        args.self_kr.get_secret_key(AbstractAccountKeyring.ED25519),
        args.self_kr.get_public_key(AbstractAccountKeyring.ED25519)
      )
    ).then((sign) => {
      return concatByteArray(this.version, sign);
    });
  }
}
