import {
  MessageRecord,
  MessageRecordPlaceholder,
  MessageRecordType,
} from '../../../../../../../../server-services/query-records/room-records';
import { WorkspaceSubscriptionRoomMessageEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import { roomMessagesQuery } from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onCreateRoomMessageEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionRoomMessageEventRecord) => {
    let msg: MessageRecord = {
      id: event.messageId,
      posterId: event.posterId,
      content: <string>event.content,
      edited: false,
      reactions: {},
    };

    if (event.replyTo) {
      msg.replyTo = event.replyTo;
    }

    if (event.decryptionError) {
      msg.decryptionError = event.decryptionError;
      msg.decryptionErrorMessage = event.decryptionErrorMessage;
    }

    let messages: (MessageRecordPlaceholder | MessageRecord)[] = cache.getCacheDataByRequest({
      query: roomMessagesQuery,
      variables: {
        roomId: event.id,
      },
    });

    if (!messages) {
      messages = [
        { type: MessageRecordType.END },
        { type: MessageRecordType.VOID },
        { type: MessageRecordType.END },
      ];
      cache.setCacheDataByRequest(
        {
          query: roomMessagesQuery,
          variables: {
            roomId: event.id,
          },
        },
        messages
      );
    }

    if (!messages.find((message) => (<MessageRecord>message)?.id === msg.id)) {
      const splice_index = 1;
      messages.splice(splice_index, 0, msg);
      if (messages[splice_index + 1]['type'] === MessageRecordType.VOID) {
        messages[splice_index + 1]['beforeMsgId'] = msg.id;
      }
    }

    if (!cache.getCacheData('messages')[event.id]) {
      cache.getCacheData('messages')[event.id] = {};
    }
    cache.getCacheData('messages')[event.id][msg.id] = msg;
  };
