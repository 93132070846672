import { Injectable, Injector } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DIALOGUE_CRYPTO_CONTEXT } from '../crypto/context/dialogue/__init__';
import { DialogueConfigDumpArgs, DialogueConfigLoadArgs } from '../crypto/context/dialogue/args';
import { getTimeFromId } from '../crypto/context/timestamp';
import { PeerAccountKeyring } from '../crypto/keyring/account_peer';
import {
  createDialogueMessageQuery,
  createDialogueQuery,
  deleteDialogueMessageQuery,
  dialogueMessagesByIdQuery,
  dialogueMessagesQuery,
  editDialogueMessageQuery,
  getDialogueDataQuery,
  getDialogueDetailQuery,
  getSeenIdsByMessageQuery,
  pinDialogueMessageQuery,
  reactDialogueMessageQuery,
  seenDialogueMessageQuery,
  sendDialogueTypingQuery,
  unpinDialogueMessageQuery,
} from '../server-services/querys';
import { SnackBarService } from '../services/snackbar.service';
import { AccountService } from './account.service';
import { AuthService } from './auth.service';
import { dialogueChatUnsentMessageStorageKey } from './chat-service-types';
import { ID } from './query-records/common-records';
import {
  DialogueDetail,
  DialogueRecord,
  LoadMessageDirection,
  MessageRecord,
  ReactionEnum,
  RoomPermission,
} from './query-records/room-records';
import { WorkspaceQueryDialogueRecord } from './query-records/workspace-records';
import {
  ParsedMessageRecord,
  ResourceBase,
  UnsentMessageAttachment,
  UnsentMessageAttachmentLocation,
} from './resource-base';

@Injectable({
  providedIn: 'root',
})
export class DialogueService extends ResourceBase {
  /**
   * Map<resourceId, event handlers>
   * This collection is wrap all of the subscription handler for a specific resource (key)
   * We separate the CMD+Typing callbacks
   * You can use the subscription to unsubscribe an apollo subscription
   */

  private peerKeyrings = {};

  constructor(
    protected accountService: AccountService,
    protected authService: AuthService,
    protected snackbarService: SnackBarService,
    protected injector: Injector
  ) {
    super(injector);
  }

  public getKeyring(resourceId): Promise<PeerAccountKeyring> {
    if (this.peerKeyrings[resourceId]) {
      return Promise.resolve(this.peerKeyrings[resourceId]);
    } else {
      return this.accountService.getPeerKeyring(resourceId).then((peer_kr) => {
        this.peerKeyrings[resourceId] = peer_kr;
        return peer_kr;
      });
    }
  }

  // direct api-calls --------------------------------------

  /**
   * @param id
   * @returns
   */
  public getData(id): Promise<DialogueRecord> {
    // check it from the cache
    return this.serverRestApiService
      .query({
        query: getDialogueDataQuery,
        variables: {
          id,
        },
      })
      .catch((err) => {
        console.warn('could not find the dialogue data, try to load it from the blocked list');
        // create or get if exists
        return this.createDialogue(id).then(() => {
          // retry to get it from cache after creation
          return this.serverRestApiService.query({
            query: getDialogueDataQuery,
            variables: {
              id,
            },
          });
        });
      });
  }

  /**
   * Note: Be sure, you have already called the getRoomData - need for creating the room_kr
   */
  public getLastMessages(resourceId): Promise<MessageRecord[]> {
    return this.serverRestApiService
      .query({
        query: dialogueMessagesQuery,
        variables: {
          peerId: resourceId,
          pivot: null,
          after: false,
          count: 30,
          prepareSub: true,
        },
        decrypt: this.decryptMessages.bind(this),
      })
      .then((data) => {
        return data;
      });
  }

  public getMoreMessages(
    resourceId,
    cursorMessageId,
    direction = LoadMessageDirection.AFTER,
    count = 20
  ): Promise<MessageRecord[]> {
    return this.serverRestApiService.query({
      query: dialogueMessagesQuery,
      variables: {
        peerId: resourceId,
        pivot: cursorMessageId,
        count: count,
        prepareSub: false,
        after: direction !== LoadMessageDirection.AFTER, //on server, 'after' is in perspective of date of messages. On client, 'after' is in perspective of the message array
        direction,
      },
      decrypt: this.decryptMessages.bind(this),
    });
  }

  public sendTyping(resourceId: string): Promise<boolean> {
    return this.serverRestApiService
      .mutate({
        query: sendDialogueTypingQuery,
        variables: {
          peerId: resourceId,
        },
      })
      .catch((err) => {
        if (err.message == 'Blocked by self!') {
          this.snackbarService.showSnackbar(marker('Can not send message to a blocked user.'));
        } else if (err.message == 'Blocked by peer!') {
          this.snackbarService.showSnackbar(
            marker('You can not start conversation with this user.')
          );
        } else {
          console.error('error during dialogue message send', err);
          throw err;
        }
      });
  }

  public deleteMessage(resourceId, messageId): Promise<any> {
    return this.serverRestApiService.mutate({
      query: deleteDialogueMessageQuery,
      variables: {
        peerId: resourceId,
        messageId,
      },
    });
  }

  public editMessage(resourceId, messageId, content, replyTo): Promise<any> {
    return this.serverRestApiService.mutate({
      query: editDialogueMessageQuery,
      variables: {
        peerId: resourceId,
        messageId,
      },
      extra: {
        message: content,
        replyTo,
      },
      encrypt: this.encryptMessage.bind(this),
    });
  }

  public seenMessage(resourceId, messageId): Promise<any> {
    return this.serverRestApiService.mutate({
      query: seenDialogueMessageQuery,
      variables: {
        peerId: resourceId,
        seenId: messageId,
      },
    });
  }

  public getDetail(userId): Promise<DialogueDetail> {
    return this.serverRestApiService.query({
      query: getDialogueDetailQuery,
      variables: {
        id: userId,
      },
    });
  }

  public pinMessage(resourceId, messageId): Promise<void> {
    return this.serverRestApiService.mutate({
      query: pinDialogueMessageQuery,
      variables: {
        peerId: resourceId,
        messageId,
        /**
         * Boolean to decide if the pin shall be private or shared.
         * If either account has blocked the dialogue, then only private pins may be created.
         * Error example: "May not be shared"
         */
        shared: true,
      },
    });
  }

  public unpinMessage(resourceId, messageId): Promise<void> {
    return this.serverRestApiService.mutate({
      query: unpinDialogueMessageQuery,
      variables: {
        peerId: resourceId,
        messageId,
      },
    });
  }

  public getSeenIdsByMessage(resourceId, messageId): Promise<ID[]> {
    return this.serverRestApiService.query({
      query: getSeenIdsByMessageQuery,
      variables: { resourceId, messageId },
    });
  }

  public reactMessage(
    resourceId: any,
    messageId: any,
    reaction: ReactionEnum | null
  ): Promise<void> {
    return this.serverRestApiService.mutate({
      query: reactDialogueMessageQuery,
      variables: {
        peerId: resourceId,
        messageId,
        reaction,
      },
    });
  }

  protected queryMessagesById(resourceId: ID, messageIds: ID[]): Promise<MessageRecord[]> {
    return this.serverRestApiService.query({
      query: dialogueMessagesByIdQuery,
      variables: {
        peerId: resourceId,
        messageIds: messageIds,
      },
      decrypt: this.decryptMessages.bind(this),
    });
  }

  public getChatPermissions(resourceId: any): Promise<RoomPermission> {
    let defaultRoomPerm = this.permissionService.getDefaultRoomPermissions();
    defaultRoomPerm.canAddReactions = true;
    defaultRoomPerm.canSendMessages = true;
    defaultRoomPerm.canPinMessages = true;
    return Promise.resolve(defaultRoomPerm);
  }

  public decryptOneMessage(
    resourceId: ID,
    messageId: ID,
    encryptedMessage: Uint8Array
  ): Promise<ParsedMessageRecord> {
    return this.getKeyring(resourceId).then((peer_kr) => {
      return DIALOGUE_CRYPTO_CONTEXT.load(
        new DialogueConfigLoadArgs(
          encryptedMessage,
          this.authService.getSelfAccountKeyring(),
          peer_kr,
          getTimeFromId(messageId)
        )
      ).then((loadedData) => {
        return this.convertMessageFromEncryptableObject(loadedData);
      });
    });
  }

  protected encryptMessage(variables, extra): Promise<Object> {
    let parsedMessage = this.convertMessageToEncrypedObject(extra);

    return this.getKeyring(variables['peerId']).then((peer_kr) => {
      return DIALOGUE_CRYPTO_CONTEXT.dump(
        new DialogueConfigDumpArgs(
          parsedMessage,
          this.authService.getSelfAccountKeyring(),
          peer_kr,
          variables.messageId
            ? getTimeFromId(variables.messageId)
            : this.serverRestApiService.makeTimestamp()
        )
      ).then((content) => {
        variables['content'] = content;
        return variables;
      });
    });
  }

  protected callSendMessageMutation(resourceId: ID, message: string, replyTo?: ID): Promise<any> {
    return this.serverRestApiService
      .mutate({
        query: createDialogueMessageQuery,
        variables: {
          peerId: resourceId,
        },
        extra: {
          replyTo,
          message: message,
        },
        encrypt: this.encryptMessage.bind(this),
      })
      .catch((err) => {
        if (err.message == 'Blocked by self!') {
          this.snackbarService.showSnackbar(marker('Can not send message to a blocked user.'));
        } else if (err.message == 'Blocked by peer!') {
          this.snackbarService.showSnackbar(
            marker('You can not start conversation with this user.')
          );
        } else {
          console.error('error during dialogue message send', err);
        }
        throw err;
      });
  }

  protected createAttachmentLocation(
    resourceId: any,
    name: string,
    path: string,
    previewSize?: number
  ): UnsentMessageAttachmentLocation {
    console.warn('attachment is not supported in dialogue');
    return null;
  }

  protected getUnsentMessageStorageKeyPrefix(): string {
    return dialogueChatUnsentMessageStorageKey;
  }

  public createDialogue(peerId: ID): Promise<WorkspaceQueryDialogueRecord> {
    return new Promise((resolve, reject) => {
      this.serverRestApiService
        .mutate({
          query: createDialogueQuery,
          variables: {
            peerId,
          },
        })
        .then((res) => {
          // this generate a dialogue, but it comes only via the subscription handler
          // we have to wait, while this merges into the cache, because the "getDialogue"
          // what will be called after this is an offline query
          let attempt = 0;

          let checkForDialogue = () => {
            this.getData(peerId)
              .then(() => {
                resolve(res);
              })
              .catch(() => {
                if (attempt < 30) {
                  attempt++;
                  setTimeout(checkForDialogue, 100);
                } else {
                  console.error('the dialogue is not loaded into the cache', peerId);
                  reject();
                }
              });
          };
          checkForDialogue();
        })
        .catch(reject);
    });
  }

  protected peekUpdateAttachment(
    resourceId: ID,
    attachment: UnsentMessageAttachment
  ): Promise<void> {
    return Promise.resolve();
  }
}
