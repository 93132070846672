import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { base64 } from 'rfc4648';
import { Subscription } from 'rxjs';
import { resourcePageMobileAnimations } from 'src/app/shared/animations/animations';
import { AccountService } from 'src/app/shared/server-services/account.service';
import { DialogueService } from 'src/app/shared/server-services/dialogue.service';
import { DialogueRecord } from 'src/app/shared/server-services/query-records/room-records';
import { RoomService } from 'src/app/shared/server-services/room.service';
import { NativeAppService } from 'src/app/shared/services/native-app.service';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
@Component({
  selector: 'app-resource-page-mobile',
  templateUrl: './resource-page-mobile.component.html',
  styleUrls: ['./resource-page-mobile.component.scss'],
  animations: resourcePageMobileAnimations,
})
export class ResourcePageMobileComponent implements OnInit, OnDestroy {
  public isOnMobile: boolean;
  public dataLoaded = false;
  public loadError = true;

  public animation: string = null;
  private routerSub: Subscription = null;

  public pinnedKey = '';

  constructor(
    private roomService: RoomService,
    private dialogueService: DialogueService,
    private accountService: AccountService,
    private routerHandler: RouterHandler,
    private router: Router,
    private nativeAppService: NativeAppService
  ) {
    this.isOnMobile = this.nativeAppService.isOnApp();

    if (this.isOnMobile) {
      this.routerSub = this.router.events.subscribe((evt) => {
        if (evt instanceof ActivationStart) {
          if (evt.snapshot.data.outlet === 'resource') {
            this.animation = evt.snapshot?.data?.animation;
          }
        }
      });
    }
  }
  ngOnDestroy(): void {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }

    this.routerHandler.unsubscribe('room/?/chat/?', this.onRouterChangeChat);
    this.routerHandler.unsubscribe('room/?/drive', this.onRouterChangeDrive);
    this.routerHandler.unsubscribe('room/?/details', this.onRouterChangeDetails);
  }
  ngOnInit(): void {
    this.initAnimation();

    if (
      this.routerHandler.isOnPage('room/?/chat/?') ||
      this.routerHandler.isOnPage('room/?/drive') ||
      this.routerHandler.isOnPage('room/?/details')
    ) {
      this.onRouterChangeChat(this.routerHandler.getRoute());
    }
    this.routerHandler.subscribe('room/?/chat/?', this.onRouterChangeChat);
    this.routerHandler.subscribe('room/?/drive', this.onRouterChangeDrive);
    this.routerHandler.subscribe('room/?/details', this.onRouterChangeDetails);
  }

  public initAnimation = () => {
    let url = this.routerHandler.getRoute();
    if (url.url.includes('drive')) {
      this.animation = 'resourcePage-chat-drive';
    } else if (url.url.includes('details')) {
      this.animation = 'resourcePage-chat-details';
    } else if (url.url.includes('chat')) {
      this.animation = 'resourcePage-chat';
    }
  };

  public onRouterChangeChat = (res) => {
    this.onChangeRoute(res, res.params.type !== 'private');
  };
  public onRouterChangeDrive = (res) => {
    this.onChangeRoute(res, res.params.type !== 'chat');
  };
  public onRouterChangeDetails = (res) => {
    this.onChangeRoute(res, res.params.type !== 'chat');
  };

  private onChangeRoute(res, isRoom = true) {
    this.dataLoaded = false;
    this.loadError = false;
    let resourceId = res.params.id;

    let outerPinnedKey;

    if (res.fragment?.roomkey) {
      // TODO paralell fragmentation
      outerPinnedKey = res.fragment.roomkey;
    }

    if (isRoom) {
      this.pinnedKey = outerPinnedKey;
      let parsedPinnedKey: Uint8Array;

      if (this.pinnedKey) {
        try {
          parsedPinnedKey = base64.parse(this.pinnedKey);
        } catch (e) {
          console.error('can not parse pinned key');
          // just try to load the room without pinned key
          // if it fails, we response with error message
          // there is a chance, we do not need the pinned key
          // when we are logged in and we are member of the room
        }
      }
      if (this.pinnedKey) {
        this.roomService.registerPinnedKey(resourceId, this.pinnedKey);
      }

      this.roomService
        .getRoom(resourceId)
        .then((res) => {
          if (res) {
            /*this.roomService.getMyRoomPermissions(resourceId).then((perm) => {
              // this.isMessageboard = perm.msgBrdReadOnly;
            });*/
            this.dataLoaded = true;
          } else {
            console.error('there is no response from get data');
            this.loadError = false;
          }
        })
        .catch((err) => {
          console.error('could not load room data', err);
          this.loadError = true;
        });
    } else {
      this.dialogueService
        .getData(resourceId)
        .then((dialogueRecord: DialogueRecord) => {
          this.accountService.getAccount(dialogueRecord.id).then((acc) => {
            this.dataLoaded = true;
            this.loadError = false;
          });
        })
        .catch((err) => {
          console.error('could not load dialogue data', err);
          this.loadError = true;
        });
    }
  }
}
