import { Component, Inject, Injector, INJECTOR, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';
import { AccountAvatarRecord } from 'src/app/shared/server-services/query-records/account-records';
import { RoomPermission } from 'src/app/shared/server-services/query-records/room-records';
import { DialogService } from 'src/app/shared/services/dialog.service';

export interface MemberDetailDialogComponentData {
  accountAvatar: AccountAvatarRecord;
  permission: RoomPermission;
  isInviting: boolean;
}

enum RoomPermissionEnum {
  ADMIN,
  MODERATOR,
  MEMBER,
}

enum DrivePermissionEnum {
  FULL_ACCESS,
  UPLOAD_ONLY,
  READ_ONLY,
}

@Component({
  selector: 'app-member-detail-dialog',
  templateUrl: './member-detail-dialog.component.html',
  styleUrls: ['./member-detail-dialog.component.scss'],
})
export class MemberDetailDialogComponent
  extends NanoDialogBaseComponent<MemberDetailDialogComponent>
  implements OnInit
{
  public RoomPermission = RoomPermissionEnum;
  public DrivePermission = DrivePermissionEnum;

  public roomPermissionControl = new FormControl<RoomPermissionEnum>(RoomPermissionEnum.MEMBER, {
    nonNullable: true,
  });
  public driveAccessControl = new FormControl<DrivePermissionEnum>(DrivePermissionEnum.READ_ONLY, {
    nonNullable: true,
  });

  constructor(
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) protected data: MemberDetailDialogComponentData,
    @Inject(INJECTOR) injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    let roomPerm: RoomPermissionEnum;
    let drivePerm: DrivePermissionEnum;

    if (this.data.permission.isAdmin) roomPerm = RoomPermissionEnum.ADMIN;
    else if (this.data.permission.isModerator) roomPerm = RoomPermissionEnum.MODERATOR;
    else roomPerm = RoomPermissionEnum.MEMBER;

    if (this.data.permission.driveCanWrite) drivePerm = DrivePermissionEnum.FULL_ACCESS;
    else if (this.data.permission.driveCanUpload) drivePerm = DrivePermissionEnum.UPLOAD_ONLY;
    else drivePerm = DrivePermissionEnum.READ_ONLY;

    this.roomPermissionControl = new FormControl<RoomPermissionEnum>(roomPerm, {
      nonNullable: true,
    });
    this.driveAccessControl = new FormControl<DrivePermissionEnum>(drivePerm, {
      nonNullable: true,
    });
  }

  public save(): void {
    let newPermission: RoomPermission = this.data.permission;
    newPermission.isMember = true;

    if (this.roomPermissionControl.dirty) {
      newPermission.isAdmin = false;
      newPermission.isModerator = false;
      switch (this.roomPermissionControl.value) {
        case RoomPermissionEnum.ADMIN:
          newPermission.isAdmin = true;
          newPermission.isModerator = true;
          break;
        case RoomPermissionEnum.MODERATOR:
          newPermission.isModerator = true;
          break;
        case RoomPermissionEnum.MEMBER:
          break;
      }
    }

    if (this.driveAccessControl.dirty) {
      newPermission.driveCanWrite = false;
      newPermission.driveCanUpload = false;
      switch (this.driveAccessControl.value) {
        case DrivePermissionEnum.FULL_ACCESS:
          newPermission.driveCanWrite = true;
          break;
        case DrivePermissionEnum.UPLOAD_ONLY:
          newPermission.driveCanUpload = true;
          break;
        case DrivePermissionEnum.READ_ONLY:
          break;
      }
    }

    this.dialogRef.close(newPermission);
  }

  public cancel(): void {
    if (this.roomPermissionControl.dirty || this.driveAccessControl.dirty) {
      this.dialogService
        .openConfirmDialog(
          marker('Unconfirmed change'),
          marker('There are unconfirmed changes on this tab. Are you sure you want to go back?')
        )
        .subscribe((confirm) => {
          if (confirm) this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }
}
