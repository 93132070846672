import { MESSAGE_INPUT_PLACEHOLDER } from './message-consts';

export abstract class MessageEditorBase {
  public abstract getContent(): string;
  public abstract setContent(content: string);

  public abstract emptyEditor();
  public abstract insertEmoji(emoji: string);
  public abstract focus();
  public abstract insertText(text: string);

  protected disabled: boolean = false;
  public disableEditor(disabled: boolean) {
    this.disabled = disabled;
  }

  protected placeholder: string = MESSAGE_INPUT_PLACEHOLDER;

  private onKeyDownHandlers: ((e: KeyboardEvent) => void)[] = [];
  onKeyDown(handler: (e: KeyboardEvent) => void) {
    this.onKeyDownHandlers.push(handler);
  }
  public keydown = (e: KeyboardEvent) => {
    this.onKeyDownHandlers.forEach((cb) => {
      cb(e);
    });
  };

  private onChangeHandlers: ((content: string) => void)[] = [];
  onChange(handler: (content: string) => void) {
    this.onChangeHandlers.push(handler);
  }
  public change(content: string) {
    this.onChangeHandlers.forEach((cb) => {
      cb(content);
    });
  }

  private onFocusChangeHandlers: ((active: boolean) => void)[] = [];
  onFocusChange(handler: (active: boolean) => void) {
    this.onFocusChangeHandlers.push(handler);
  }
  public focusChange(focus: boolean) {
    this.onFocusChangeHandlers.forEach((cb) => {
      cb(focus);
    });
  }
}
