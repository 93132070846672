import { Injectable } from '@angular/core';
import { RoomKeyring } from '../crypto/keyring/room';
import { CacheService } from '../services/cache/cache.service';
import { AccountService } from './account.service';
import { AuthService } from './auth.service';
import { RoomRecord } from './query-records/room-records';

@Injectable({
  providedIn: 'root',
})
export class RoomKeyringService {
  /**
   * Cache for keyrings
   */
  private roomKeyrings = {};
  private pinnedKeys: { [roomId: string]: Uint8Array } = {};

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private cacheService: CacheService
  ) {
    authService.onLogout(() => {
      this.roomKeyrings = {};
    });
  }

  public getKeyring(resourceId): Promise<RoomKeyring> {
    if (this.roomKeyrings.hasOwnProperty(resourceId)) {
      if (!this.roomKeyrings[resourceId]) {
        return Promise.reject('Could not generate keyring for this room: ' + resourceId);
      } else {
        return Promise.resolve(this.roomKeyrings[resourceId]);
      }
    }
    return this.cacheService.getRoomRecordFromCache(resourceId).then((room) => {
      return this.makeKeyring(room);
    });
  }

  public makeKeyring(roomData: RoomRecord): Promise<RoomKeyring> {
    if (!this.roomKeyrings.hasOwnProperty(roomData.ownerAccountId)) {
      if (!roomData.pinnedKey) {
        if (this.pinnedKeys[roomData.id]) {
          return RoomKeyring.loadRaw(roomData.id, this.pinnedKeys[roomData.id]).then((room_kr) => {
            this.roomKeyrings[roomData.id] = room_kr;
            return room_kr;
          });
        } else {
          console.error('did not get pinned key', roomData.pinnedKey);
          return Promise.reject('need pinned key for operation');
        }
      } else {
        return this.accountService.getPeerKeyring(roomData.ownerAccountId).then((peer_kr) => {
          return RoomKeyring.load(
            this.authService.getSelfAccountKeyring(),
            peer_kr,
            roomData.id,
            roomData.savedKey,
            roomData.pinnedKey
          )
            .then((room_kr) => {
              // save keyring for future request
              this.roomKeyrings[roomData.id] = room_kr;

              return room_kr;
            })
            .catch((err) => {
              this.roomKeyrings[roomData.id] = null;
              return Promise.reject('can not load roomkeyring');
            });
        });
      }
    } else {
      if (this.roomKeyrings[roomData.ownerAccountId]) {
        return Promise.resolve(this.getKeyring(roomData.id));
      } else {
        return Promise.reject('can not load roomkeyring');
      }
    }
  }

  public getPinnedKey(roomId: string): Uint8Array {
    return this.pinnedKeys[roomId];
  }

  public setPinnedKey(roomId: string, pinnedKey: Uint8Array) {
    this.pinnedKeys[roomId] = pinnedKey;
  }
}
