<div class="one-message" [ngClass]="{ 'own-message': data.isOwnMessage }" appResized (resized)="onResized($event)">
  <ng-container *ngIf="!data.isOwnMessage">
    <div *ngIf="!data.hasLineBreak" class="avatar-spaceholder">
      <!-- <span [matTooltip]="data.date | date: 'short'" class="ingroup-date date mat-small" [ngClass]="data.isinGroup || !isCompactDisplay ? 'ingroup-date' : ''">{{ data.date | date: 'shortTime' }}</span> -->
    </div>
    <div *ngIf="!data.isInGroup && isCompactDisplay" class="avatar-spaceholder">
      <!-- <span [matTooltip]="data.date | date: 'short'" class="date mat-small">{{ data.date | date: 'shortTime' }}</span> -->
    </div>
    <div *ngIf="data.hasLineBreak" class="avatar-container">
      <app-avatar verify *ngIf="!isCompactDisplay" [accountAvatarRecord]="data.account" appShowUserInfo></app-avatar>
    </div>
  </ng-container>
  <div class="one-message-content" (contextmenu)="handleContextMenu($event)" [appLongPress]="" (longPress)="handleLongPress($event)">
    <!-- <div id="dialog-fix-bg" *ngIf="showReactions" (click)="hideReactionDialog()"></div> -->
    <!-- <app-select-reaction
      *ngIf="showReactions"
      [ngClass]="{ 'expanded-reaction': isReactionBoxExpanded }"
      (expand)="expandReactionDialog($event)"
      (select)="addReaction($event)"
      [used]="data.myReaction"
    ></app-select-reaction> -->
    <div class="message-header" *ngIf="!isCompactDisplay && !data.isInGroup">
      <span class="mdi mdi-crown mat-small owner-icon" *ngIf="data.isOwnerOfRoom"></span>
      <span class="name mat-subtitle-2" appShowUserInfo [accountAvatarRecord]="data.account">
        <span *ngIf="!data.account.deleted" class="user-color-{{ data.account.color }} notranslate">{{ data.account.avatarName }}</span
        ><span class="data-error" *ngIf="data.account.deleted" translate>Deleted user</span>
      </span>
      <span class="date" matTooltip="{{ data.date | date: 'fullDate' }}" class="date mat-small">
        {{ data.date | date: 'HH:mm' }}
      </span>
    </div>
    <div class="messages">
      <span *ngIf="isCompactDisplay" class="name mat-subtitle-2" appShowUserInfo [accountAvatarRecord]="data.account">
        <span *ngIf="!data.account.deleted" class="user-color-{{ data.account.color }} notranslate">{{ data.account.avatarName }}: </span>
        <span class="data-error" *ngIf="data.account.deleted" translate>Deleted user</span>
      </span>
      <div *ngIf="data.replyToId" class="reply-outer-box">
        <div *ngIf="data.replyToMsg" class="reply-box reply-box-color-{{ data.replyToMsg?.account?.color }}" matRipple (click)="jumpToMessage(data.replyToId)">
          <span class="name user-color-{{ data.replyToMsg.account.color }} notranslate" appShowUserInfo [accountAvatarRecord]="data.replyToMsg?.account">{{ data.replyToMsg.account.avatarName }}</span>
          <div *ngIf="!data.replyToMsg?.error" class="reply-content">{{ data.replyToMsg.messageRef.content }}</div>
          <div *ngIf="data.replyToMsg?.error" class="reply-content message-error warn" [matTooltip]="data.replyToMsg.errorMessage" translate>Message load error</div>
        </div>
        <div *ngIf="data.replyToMsgError" class="reply-box reply-box-color-error">
          <div class="message-error warn"><span translate>Message deleted.</span></div>
        </div>
      </div>
      <app-markdown-view [ngClass]="{ hasReply: data?.replyToId, highlight: highlighted }" [hasLineBreak]="data?.hasLineBreak" *ngIf="!data.error" [html]="data.message"></app-markdown-view>
      <app-markdown-view
        class="message-error warn"
        [ngClass]="data?.replyToId ? 'hasReply' : ''"
        [hasLineBreak]="data?.hasLineBreak"
        *ngIf="data.error"
        [matTooltip]="data.errorMessage"
        [html]="'Message load error' | translate"
      ></app-markdown-view>
    </div>
  </div>
  <div class="message-menu-cont">
    <div class="reactions" (click)="openReactionDialog()">
      <img *ngFor="let r of data.someReaction" [src]="reactionSrcMap[r]" />
      <span *ngIf="data.sumReaction > 1">{{ data.sumReaction }}</span>
    </div>
  </div>
</div>
<app-message-details [runAnimation]="runSeenAnimation" [seenBy]="data.seenBy"></app-message-details>
