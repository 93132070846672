import { Component, ElementRef, Renderer2, ChangeDetectorRef, OnInit } from '@angular/core';
import { BaseLeafComponent } from 'slate-angular';

@Component({
  selector: 'span[SlateEditorLeaf]',
  templateUrl: './slate-editor-leaf.component.html',
  styleUrls: ['./slate-editor-leaf.component.scss'],
})
export class SlateEditorLeafComponent extends BaseLeafComponent {
  constructor(
    public elementRef: ElementRef,
    public cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    super(elementRef, cdr);
  }

  public isEmoji: boolean = false;
  public placeholder: string = '';

  onContextChange() {
    super.onContextChange();
    this.renderPlaceholder();
    this.isEmoji = this.leaf['token'] && this.leaf['token'].includes('emoji');
    this.elementRef.nativeElement.classList.forEach((className) => {
      if (className.startsWith('slate-')) {
        this.renderer.removeClass(this.elementRef.nativeElement, className);
      }
    });
    if (this.leaf['token']) {
      this.leaf['token'].forEach((token) => {
        this.renderer.addClass(this.elementRef.nativeElement, 'slate-' + token);
      });
    }
  }
}
