import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MessageEditorBase } from '../message-editor-base';

@Component({
  selector: 'app-native-editor',
  templateUrl: './native-editor.component.html',
  styleUrls: ['./native-editor.component.scss'],
})
export class NativeEditorComponent extends MessageEditorBase implements OnDestroy, AfterViewInit {
  @ViewChild('editor', { read: ElementRef }) editor: ElementRef;
  @ViewChild('invisibleBox', { read: ElementRef }) invisibleBox: ElementRef;

  public value: string = '';

  /**
   * For emoji insert, or style toggle we should know the last selection
   */
  public lastSelection: { start: number; end: number } = {
    start: 0,
    end: 0,
  };

  constructor(private changeRef: ChangeDetectorRef, @Inject(DOCUMENT) private document) {
    super();
  }

  public getContent(): string {
    return this.value;
  }

  setContent(content: string) {
    this.value = content;
    this.setInputHeight();
    this.change(this.value);
  }

  emptyEditor() {
    this.setContent('');
  }

  insertEmoji(emoji: string) {
    this.insertText(emoji);
  }

  public insertText(text: string) {
    let posStart = this.lastSelection.start || 0;
    let posEnd = this.lastSelection.end || 0;
    if (this.lastSelection) {
      this.value = this.value.substring(0, posStart) + text + this.value.substring(posStart);
    } else {
      this.value += text;
    }
    this.lastSelection = {
      start: posStart + text.length,
      end: posEnd + text.length,
    };
    this.setInputHeight();
  }

  public focus() {
    this.editor.nativeElement.focus();
    // jumps to the end of line
    this.editor.nativeElement.setSelectionRange(
      this.editor.nativeElement.value.length,
      this.editor.nativeElement.value.length
    );
  }

  ngAfterViewInit(): void {
    this.document.addEventListener('selectionchange', this.saveLastSelection);

    this.changeRef.detectChanges(); // resolve expression changed error
  }

  ngOnDestroy(): void {
    this.document.removeEventListener('selectionchange', this.saveLastSelection);
  }

  public saveLastSelection = (ev) => {
    const activeElement = this.document.activeElement;

    // Make sure this is your textarea
    if (activeElement == this.editor.nativeElement) {
      this.lastSelection = {
        start: (<HTMLTextAreaElement>activeElement).selectionStart,
        end: (<HTMLTextAreaElement>activeElement).selectionEnd,
      };
    }
  };

  public onInput() {
    this.setInputHeight();
    this.change(this.value);
  }

  private setInputHeight(): void {
    setTimeout(() => {
      this.editor.nativeElement.style.height = this.invisibleBox.nativeElement.scrollHeight + 'px';
    }, 10);
  }
}
