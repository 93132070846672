import { AbstractConfigDumpArgs, AbstractConfigLoadArgs } from '../base';
import { AbstractPeerAccountKeyring, AbstractSelfAccountKeyring } from '../../keyring/account_base';

/**
    This is only loaded by the client to solve the challenge of the server.
    The peer keyring will only contain the ephemeral public ECDH key of the server.
 */
export class RegisterChallengeConfigLoadArgs extends AbstractConfigLoadArgs {
  public self_kr;
  public peer_kr;

  constructor(
    cipher: Uint8Array,
    self_kr: AbstractSelfAccountKeyring,
    peer_kr: AbstractPeerAccountKeyring
  ) {
    super(cipher);
    this.self_kr = self_kr;
    this.peer_kr = peer_kr;
  }
}

/**
    This is only dumped by the server to pose a final challenge for the client to finish register.
    The peer keyring only need to contain the account's public ECDH key(s).
 */
export class RegisterChallengeConfigDumpArgs extends AbstractConfigDumpArgs {
  public plain;
  public self_kr;
  public peer_kr;

  constructor(
    plain: Uint8Array,
    self_kr: AbstractSelfAccountKeyring,
    peer_kr: AbstractPeerAccountKeyring
  ) {
    super();
    this.plain = plain;
    this.self_kr = self_kr;
    this.peer_kr = peer_kr;
  }
}
