<h2 mat-dialog-title translate>Change image</h2>
<mat-dialog-content class="mat-typography">
  <div id="preview-row" *ngIf="generatedImages.length === 0">
    <div>
      <button mat-raised-button color="primary" *ngIf="isOnApp" (click)="useCamera()"><mat-icon>photo_camera</mat-icon><span translate>Camera</span></button>
      <button id="upload-image-button" mat-raised-button color="primary" (click)="imageInput.click()"><mat-icon>upload_file</mat-icon><span translate>Upload image</span></button>
      <button *ngIf="!isImageLoaded" mat-raised-button color="primary" (click)="generate()"><mat-icon>shuffle</mat-icon><span translate>Generate Avatar</span></button>
    </div>

    <input id="image-input" type="file" accept="image/*" (change)="imageSelected($event)" #imageInput />
    <div id="preview-image-container" #previewImageContainer></div>
  </div>
  <div *ngIf="generatedImages.length > 0">
    <div id="generator-input">
      <label for="seed" translate>Random Seed: </label>
      <input id="seed" class="nano-input" [(ngModel)]="generateSeed" (input)="generateAvatarFromSeed(generateSeed)" />
    </div>
    <div class="generated-container">
      <app-blob-viewer
        *ngFor="let blob of generatedImages; let i = index"
        [blob]="blob"
        [ngClass]="{ selected: i === selectedIndex }"
        type="image"
        (click)="selectGeneratedAvatar(i)"
      ></app-blob-viewer>
    </div>
    <div id="regenerate">
      <span translate>Choose one of these avatar</span>
      <span translate>or</span>
      <span>
        <button mat-raised-button color="primary" (click)="generate()">
          <span translate>Randomize!</span>
        </button>
      </span>
    </div>
  </div>
  <div id="cropper-board" [ngClass]="{ imageLoaded: isImageLoaded }">
    <img #image />
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Cancel</span></button>
  <button mat-button color="primary" (click)="changeAvatar()" *ngIf="isImageLoaded"><span translate>Save crop</span></button>
  <button mat-button color="primary" (click)="chooseGeneratedAvatar()" *ngIf="generatedImages.length > 0" [disabled]="selectedIndex === -1"><span translate>Choose avatar</span></button>
</mat-dialog-actions>
