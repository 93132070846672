import { OperationType, Query } from '../services/cache/cache-logic/cache-logic-interface';

// common -----------------------------------------
export const pingQuery: Query = {
  endpoint: 'ping',
  operation: OperationType.QUERY,
};

export const sessionEncryptionKeyQuery: Query = {
  endpoint: 'sessionEncryptionKey',
  operation: OperationType.QUERY,
};

export const createNewIdQuery: Query = {
  endpoint: 'createNewId',
  operation: OperationType.MUTATION,
};

// account ------------------------------------
export const getMeQuery: Query = {
  endpoint: 'me',
  operation: OperationType.QUERY,
};

export const nanoMetricsQuery: Query = {
  endpoint: 'nanoMetrics',
  operation: OperationType.QUERY,
};

export const accountAvatarsQuery: Query = {
  endpoint: 'accountAvatars',
  operation: OperationType.QUERY,
};

// offline query
export const getContactListIdsQuery: Query = {
  endpoint: 'contacts',
  operation: OperationType.QUERY,
};

export const getNanoVersionQuery: Query = {
  endpoint: 'nanoVersion',
  operation: OperationType.QUERY,
};

export const getAndroidAppVersionQuery: Query = {
  endpoint: 'androidAppVersion',
  operation: OperationType.QUERY,
};

export const createContactQuery: Query = {
  endpoint: 'createContact',
  operation: OperationType.MUTATION,
};

export const deleteContactQuery: Query = {
  endpoint: 'deleteContact',
  operation: OperationType.MUTATION,
};

export const modifyAvatarQuery: Query = {
  endpoint: 'editAvatarImage',
  operation: OperationType.MUTATION,
};

export const modifyAvatarNameQuery: Query = {
  endpoint: 'editAvatarName',
  operation: OperationType.MUTATION,
};

export const getAccountQuery: Query = {
  endpoint: 'accountAvatar',
  operation: OperationType.QUERY,
};

export const editPasswordQuery: Query = {
  endpoint: 'editPassword',
  operation: OperationType.MUTATION,
};

export const editTwoFactorModeQuery: Query = {
  endpoint: 'editTwoFactorMode',
  operation: OperationType.MUTATION,
};

export const getTwoFactorModeQuery: Query = {
  endpoint: 'twoFactor',
  operation: OperationType.QUERY,
};

export const prepareEditEmailQuery: Query = {
  endpoint: 'prepareEditEmail',
  operation: OperationType.MUTATION,
};

export const editEmailQuery: Query = {
  endpoint: 'editEmail',
  operation: OperationType.MUTATION,
};

export const deleteAccountQuery: Query = {
  endpoint: 'deleteAccount',
  operation: OperationType.MUTATION,
};

export const deleteAccountPrepareQuery: Query = {
  endpoint: 'prepareDeleteAccount',
  operation: OperationType.MUTATION,
};

// cache query
export const getAllAccountIdQuery: Query = {
  endpoint: 'allAccountId',
  operation: OperationType.QUERY,
};

export const getAccountKeysQuery: Query = {
  endpoint: 'accountKeys',
  operation: OperationType.QUERY,
};

export const getLoginSessions: Query = {
  endpoint: 'loginSessions',
  operation: OperationType.QUERY,
};

export const deleteLoginSession: Query = {
  endpoint: 'deleteSession',
  operation: OperationType.MUTATION,
};

export const clearUserLoginSessions: Query = {
  endpoint: 'clearUserSessions',
  operation: OperationType.MUTATION,
};

export const editDialoguePolicy: Query = {
  endpoint: 'editDialoguePolicy',
  operation: OperationType.MUTATION,
};

export const editDialogueHidden: Query = {
  endpoint: 'editDialogueHidden',
  operation: OperationType.MUTATION,
};

// workspace ---------------------------------

export const resourceTreeQuery: Query = {
  endpoint: 'workspaceV2',
  operation: OperationType.QUERY,
};

export const workspaceQuery: Query = {
  endpoint: 'workspaceV2',
  operation: OperationType.QUERY,
};

export const workspaceSubscription: Query = {
  endpoint: 'workspaceChanges',
  operation: OperationType.SUBSCRIPTION,
};

// offline query
export const getSidebarGroupQuery: Query = {
  endpoint: 'sidebarGroup',
  operation: OperationType.QUERY,
};

// offline query
export const getAllSidebarGroupQuery: Query = {
  endpoint: 'allSidebarGroup',
  operation: OperationType.QUERY,
};

// offline query
export const getSidebarUngrouppedQuery: Query = {
  endpoint: 'sidebarUngroupped',
  operation: OperationType.QUERY,
};

// offline query
export const getSidebarGrantedRoomsQuery: Query = {
  endpoint: 'sidebarGrantedRooms',
  operation: OperationType.QUERY,
};

// offline query
export const getSidebarRejectedRoomsQuery: Query = {
  endpoint: 'sidebarRejectedRooms',
  operation: OperationType.QUERY,
};

// offline query
export const getSidebarResourceQuery: Query = {
  endpoint: 'sidebarResource',
  operation: OperationType.QUERY,
};

// offline query
export const getSidebarNewsQuery: Query = {
  endpoint: 'sidebarNews',
  operation: OperationType.QUERY,
};

export const createResourceGroupQuery: Query = {
  endpoint: 'createResourceGroup',
  operation: OperationType.MUTATION,
};

export const deleteResourceGroupQuery: Query = {
  endpoint: 'deleteResourceGroup',
  operation: OperationType.MUTATION,
};

export const editResourceGroupNameQuery: Query = {
  endpoint: 'editResourceGroupName',
  operation: OperationType.MUTATION,
};

export const editResourceGroupOrderingQuery: Query = {
  endpoint: 'editResourceGroupOrdering',
  operation: OperationType.MUTATION,
};

export const editResourceGroupClosedQuery: Query = {
  endpoint: 'editResourceGroupClosed',
  operation: OperationType.MUTATION,
};

export const editRoomPlacementQuery: Query = {
  endpoint: 'editRoomPlacement',
  operation: OperationType.MUTATION,
};

export const editDialoguePlacementQuery: Query = {
  endpoint: 'editDialoguePlacement',
  operation: OperationType.MUTATION,
};

// offline query
export const getTimedOffersQuery: Query = {
  endpoint: 'timedOffers',
  operation: OperationType.QUERY,
};

export const acceptOfferQuery: Query = {
  endpoint: 'acceptOffer',
  operation: OperationType.MUTATION,
};

// room  ------------------------------
/**
 * get a specific room with its "data"
 */
export const getRoomWithDataQuery: Query = {
  endpoint: 'roomData',
  operation: OperationType.QUERY,
};

/**
 * batch and get "data" for rooms
 */
export const getRoomDatasQuery: Query = {
  endpoint: 'roomDatas',
  operation: OperationType.QUERY,
};

export const joinRoomQuery: Query = {
  endpoint: 'joinRoom',
  operation: OperationType.MUTATION,
};

export const leaveRoomQuery: Query = {
  endpoint: 'leaveRoom',
  operation: OperationType.MUTATION,
};

// offline query
export const getAllRoomQuery: Query = {
  endpoint: 'allRoom',
  operation: OperationType.QUERY,
};

// offline query
export const getRoomQuery: Query = {
  endpoint: 'room',
  operation: OperationType.QUERY,
};

export const getRoomDetailQuery: Query = {
  endpoint: 'roomDetail',
  operation: OperationType.QUERY,
};

export const getDialogueDetailQuery: Query = {
  endpoint: 'dialogueDetail',
  operation: OperationType.QUERY,
};

export const pinRoomMessageQuery: Query = {
  endpoint: 'pinRoomMessage',
  operation: OperationType.MUTATION,
};

export const unpinRoomMessageQuery: Query = {
  endpoint: 'unpinRoomMessage',
  operation: OperationType.MUTATION,
};

export const pinDialogueMessageQuery: Query = {
  endpoint: 'pinDialogueMessage',
  operation: OperationType.MUTATION,
};

export const unpinDialogueMessageQuery: Query = {
  endpoint: 'unpinDialogueMessage',
  operation: OperationType.MUTATION,
};

// offline query
export const getSeenIdsByMessageQuery: Query = {
  endpoint: 'seenIdsByMessage',
  operation: OperationType.QUERY,
};

export const roomMessagesQuery: Query = {
  endpoint: 'roomMessages',
  operation: OperationType.QUERY,
};

export const roomMessagesByIdQuery: Query = {
  endpoint: 'roomMessagesById',
  operation: OperationType.QUERY,
};

export const dialogueMessagesByIdQuery: Query = {
  endpoint: 'dialogueMessagesById',
  operation: OperationType.QUERY,
};

// offline query
export const messageQuery: Query = {
  endpoint: 'message',
  operation: OperationType.QUERY,
};

export const reactRoomMessageQuery: Query = {
  endpoint: 'reactRoomMessage',
  operation: OperationType.MUTATION,
};

export const reactDialogueMessageQuery: Query = {
  endpoint: 'reactDialogueMessage',
  operation: OperationType.MUTATION,
};

export const createRoomMessageQuery: Query = {
  endpoint: 'createRoomMessage',
  operation: OperationType.MUTATION,
};

export const sendDialogueTypingQuery: Query = {
  endpoint: 'typingDialogue',
  operation: OperationType.MUTATION,
};

export const sendRoomTypingQuery: Query = {
  endpoint: 'typingRoom',
  operation: OperationType.MUTATION,
};

export const deleteRoomMessageQuery: Query = {
  endpoint: 'deleteRoomMessage',
  operation: OperationType.MUTATION,
};

export const editRoomMessageQuery: Query = {
  endpoint: 'editRoomMessage',
  operation: OperationType.MUTATION,
};

export const seenRoomMessageQuery: Query = {
  endpoint: 'seenRoomMessage',
  operation: OperationType.MUTATION,
};

export const roomConfigQuery: Query = {
  endpoint: 'roomConfig',
  operation: OperationType.QUERY,
};

export const roomPermissionsQuery: Query = {
  endpoint: 'roomPermissions',
  operation: OperationType.QUERY,
};

export const roomMemberQuery: Query = {
  endpoint: 'roomMembers',
  operation: OperationType.QUERY,
};

export const roomMyPermissionsQuery: Query = {
  endpoint: 'roomMyPermissions',
  operation: OperationType.QUERY,
};

// Note: only for the anonym session
// you should call this only after the lastMessagesQuery, or it will return with error
// because of the preparation
export const chatChangesSubscription: Query = {
  endpoint: 'roomChanges',
  operation: OperationType.SUBSCRIPTION,
};

export const createRoomConfigBlockQuery: Query = {
  endpoint: 'createRoomConfigBlock',
  operation: OperationType.MUTATION,
};

export const createRoomQuery: Query = {
  endpoint: 'createRoom',
  operation: OperationType.MUTATION,
};

export const deleteRoomQuery: Query = {
  endpoint: 'deleteRoom',
  operation: OperationType.MUTATION,
};

export const editRoomDataQuery: Query = {
  endpoint: 'editRoomData',
  operation: OperationType.MUTATION,
};

export const editRoomPermissionQuery: Query = {
  endpoint: 'editPermission',
  operation: OperationType.MUTATION,
};

export const editRoomAccountPermissionQuery: Query = {
  endpoint: 'editAccountPermission',
  operation: OperationType.MUTATION,
};

// diagloue -----------------------------------

// offline query
export const getDialogueDataQuery: Query = {
  endpoint: 'dialogueData',
  operation: OperationType.QUERY,
};

export const createDialogueQuery: Query = {
  endpoint: 'createDialogueV2',
  operation: OperationType.MUTATION,
};

export const dialogueMessagesQuery: Query = {
  endpoint: 'dialogueMessages',
  operation: OperationType.QUERY,
};

export const createDialogueMessageQuery: Query = {
  endpoint: 'createDialogueMessage',
  operation: OperationType.MUTATION,
};

export const deleteDialogueMessageQuery: Query = {
  endpoint: 'deleteDialogueMessage',
  operation: OperationType.MUTATION,
};

export const editDialogueMessageQuery: Query = {
  endpoint: 'editDialogueMessage',
  operation: OperationType.MUTATION,
};

export const seenDialogueMessageQuery: Query = {
  endpoint: 'seenDialogueMessage',
  operation: OperationType.MUTATION,
};

// nano  ------------------------------

export const nanoRequestQuery: Query = {
  endpoint: 'nanoRequest',
  operation: OperationType.SUBSCRIPTION,
};

/**
 * offline query
 * result of the query: {[key: string]: number}
 */
export const waitingNanosQuery: Query = {
  endpoint: 'waitingNanos',
  operation: OperationType.QUERY,
};

export const nanoDirectRequestQuery: Query = {
  endpoint: 'nanoDirectRequest',
  operation: OperationType.SUBSCRIPTION,
};

export const getNanoSlotsQuery: Query = {
  endpoint: 'nanoSlots',
  operation: OperationType.QUERY,
};

export const getNanoLicensesQuery: Query = {
  endpoint: 'nanoLicensesV2',
  operation: OperationType.QUERY,
};

export const getRecurringOrdersQuery: Query = {
  endpoint: 'paymentRecurringOrders',
  operation: OperationType.QUERY,
};

export const cancelRecurringOrderQuery: Query = {
  endpoint: 'paymentCancelRecurring',
  operation: OperationType.MUTATION,
};
// payment ------------------------------

export const getPaymentShopDataQuery: Query = {
  endpoint: 'paymentShopData',
  operation: OperationType.QUERY,
};

export const getPaymentBillingsQuery: Query = {
  endpoint: 'paymentBillings',
  operation: OperationType.QUERY,
};

export const savePaymentBillingQuery: Query = {
  endpoint: 'paymentSaveBilling',
  operation: OperationType.MUTATION,
};

export const deletePaymentBillingQuery: Query = {
  endpoint: 'paymentDeleteBilling',
  operation: OperationType.MUTATION,
};

export const preparePaymentOrderQuery: Query = {
  endpoint: 'paymentPrepareOrder',
  operation: OperationType.MUTATION,
};

export const editBuyCodeQuery: Query = {
  endpoint: 'editBuyCode',
  operation: OperationType.MUTATION,
};

export const getBuyCodeQuery: Query = {
  endpoint: 'buyCode',
  operation: OperationType.QUERY,
};

export const paymentOrderQuery: Query = {
  endpoint: 'paymentRecordOrder',
  operation: OperationType.QUERY,
};

export const getNanoBundleLicensesQuery: Query = {
  endpoint: 'nanoLicenseBundleTokens',
  operation: OperationType.QUERY,
};

export const redeemLicenseTokenQuery: Query = {
  endpoint: 'claimLicenseToken',
  operation: OperationType.MUTATION,
};

export const getSaleCodesQuery: Query = {
  endpoint: 'saleCodes',
  operation: OperationType.QUERY,
};

export const createSaleCodeQuery: Query = {
  endpoint: 'createSaleCode',
  operation: OperationType.MUTATION,
};

export const editSaleCodeDescriptionQuery: Query = {
  endpoint: 'editSaleCodeDescription',
  operation: OperationType.MUTATION,
};

export const deleteSaleCodeQuery: Query = {
  endpoint: 'deleteSaleCode',
  operation: OperationType.MUTATION,
};

export const paymentParseBackQuery: Query = {
  endpoint: 'paymentParseBack',
  operation: OperationType.QUERY,
};

// admin specific ------------------------
export const aggregateStats: Query = {
  endpoint: 'aggregateStats',
  operation: OperationType.QUERY,
};

// push noti
export const editPushData: Query = {
  endpoint: 'editPushData',
  operation: OperationType.MUTATION,
};

// verify signature
export const addTrustQuery: Query = {
  endpoint: 'addTrust',
  operation: OperationType.MUTATION,
};

export const prepareWalletProofQuery: Query = {
  endpoint: 'prepareWalletProof',
  operation: OperationType.MUTATION,
};

export const submitWalletProofQuery: Query = {
  endpoint: 'submitWalletProof',
  operation: OperationType.MUTATION,
};

// debug
export const sessionPushDataQuery: Query = {
  endpoint: 'sessionPushData',
  operation: OperationType.QUERY,
};

// live meet
export const joinDialogueMuc: Query = {
  endpoint: 'joinDialogueMuc',
  operation: OperationType.MUTATION,
};

export const joinRoomMuc: Query = {
  endpoint: 'joinRoomMuc',
  operation: OperationType.MUTATION,
};
