import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ChatLayoutSheetAction } from 'src/app/shared/consts/enums';
import {
  RoomPermission,
  reactionIndexSrcMap,
  reactionList,
} from 'src/app/shared/server-services/query-records/room-records';
// import { DriveFile } from '../drive-file';
import { ChatData, ChatMessagePermission, ChatPermissions } from '../chat-data';
import { ChatMessageContextMenuData } from '../chat-message/chat-message.component';

@Component({
  selector: 'app-chat-layout-bottom-sheet-menu',
  templateUrl: './chat-layout-bottom-sheet-menu.component.html',
  styleUrls: ['./chat-layout-bottom-sheet-menu.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0.5 }),
        animate('0.2s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ bottom: 0, opacity: 1 }),
        animate('0.2s ease-in', style({ opacity: 0.5 })),
        style({ display: 'none' }),
      ]),
    ]),
  ],
})
export class ChatLayoutBottomSheetMenuComponent implements OnInit {
  public SheetAction = ChatLayoutSheetAction;

  public isOnRoot: boolean = false;

  public isExpanded = false;
  public reactions = reactionList;
  public topEmojies = [
    { index: 1, emoji: reactionIndexSrcMap[1] }, // ❤️
    { index: 40, emoji: reactionIndexSrcMap[40] }, // 😆
    { index: 42, emoji: reactionIndexSrcMap[42] }, // 😮
    { index: 18, emoji: reactionIndexSrcMap[18] }, // 😢
    { index: 41, emoji: reactionIndexSrcMap[41] }, // 😠
    { index: 6, emoji: reactionIndexSrcMap[6] }, // 👍
  ];

  constructor(
    private _bottomSheet: MatBottomSheetRef<ChatLayoutBottomSheetMenuComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      contextTargetMessage: ChatMessageContextMenuData;
      contextTargetPermissions: ChatMessagePermission;
      myPermissions: RoomPermission;
    }
  ) {}

  public expandEmojiPicker = (event: MouseEvent) => {
    event.stopPropagation();
    this.isExpanded = true;
  };

  ngOnInit(): void {}

  sheetActionClick(action: ChatLayoutSheetAction, props: any = null): void {
    this._bottomSheet.dismiss({ action: action, props: props });
  }
}
