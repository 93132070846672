<div id="browser-container">
  <h2 id="title" *ngIf="data.title">
    {{ data.title }}
  </h2>
  <div id="header">
    <button mat-icon-button [disabled]="path.length === 0" (click)="goUp()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div id="path">
      <span *ngIf="path.length > 0">{{ path[path.length - 1].fullName }}</span>
    </div>
    <button mat-icon-button (click)="listDirectory()" *ngIf="!loadInprogress">
      <mat-icon>refresh</mat-icon>
    </button>
    <mat-spinner *ngIf="loadInprogress" [diameter]="20"></mat-spinner>
  </div>
  <div id="body">
    <ng-container *ngFor="let state of fileStates">
      <div class="file" [ngClass]="{ selected: state.selected }" (click)="select(state)" appDoubleClick (doubleClick)="doubleClick(state)">
        <mat-checkbox *ngIf="data.multiSelection" color="primary" [checked]="state.selected"></mat-checkbox>
        <span *ngIf="!state.file.thumbnail" class="mdi mdi-{{ state.file.icon }} file-{{ state.file.icon }}"></span>
        <app-blob-viewer *ngIf="state.file.thumbnail" [blob]="state.file.thumbnail" type="image" (error)="state.file.thumbnail = null"></app-blob-viewer>
        <span class="file-name">{{ state.file.name }}</span>
        <button mat-icon-button *ngIf="state.dir" (click)="goNext(state, $event)">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
  <div id="footer">
    <span id="select-all-cont" *ngIf="data.multiSelection && fileStates.length > 0">
      <mat-checkbox
        (click)="selectAll()"
        [checked]="fileStates.length === selectedFiles.length"
        [indeterminate]="selectedFiles.length > 0 && selectedFiles.length !== fileStates.length"
        [disabled]="loadInprogress"
        color="primary"
      ></mat-checkbox>
      <span> {{ selectedFiles.length }} / {{ fileStates.length }}</span>
    </span>
    <button mat-button [disabled]="loadInprogress" mat-dialog-close><span translate>Cancel</span></button>
    <button mat-button [disabled]="loadInprogress" (click)="openCreateFolderDialog()"><span translate>Create Folder</span></button>
    <button *ngIf="selectedFiles.length !== 0" mat-raised-button [disabled]="loadInprogress" color="primary" (click)="choose()">
      <span translate>Choose</span>
    </button>
    <button *ngIf="selectedFiles.length === 0" mat-raised-button [disabled]="loadInprogress" color="primary" (click)="chooseCurrentFolder()"><span translate>Choose current folder</span></button>
  </div>
</div>
