import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/shared/server-services/sidebar.service';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType
} from 'src/app/shared/server-services/subscription-event';
import { SubscriptionService } from 'src/app/shared/server-services/subscription.service';

@Component({
  selector: 'app-rejected-rooms',
  templateUrl: './rejected-rooms.component.html',
  styleUrls: ['./rejected-rooms.component.scss'],
})
export class RejectedRoomsComponent implements OnInit, OnDestroy {
  public rejectedRooms = null;

  constructor(
    private sidebarService: SidebarService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.subscribeToEvents();

    this.subscriptionService.subscribeWorkspaceLoaded(() => {
      this.refreshRejectedRooms();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeFromEvents();
  }

  private refreshRejectedRooms = () => {
    this.sidebarService.getRejectedRooms().then((rooms) => {
      console.log('RejectedRoomsComponent - Query rejected room result:', rooms);
      this.rejectedRooms = rooms;
    });
  };

  private subscribeToEvents() {
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshRejectedRooms
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.refreshRejectedRooms
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshRejectedRooms
    );

    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.refreshRejectedRooms
    );
  }

  private unsubscribeFromEvents() {
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshRejectedRooms
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.refreshRejectedRooms
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.refreshRejectedRooms
    );

    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_ACCOUNT_PERMISSION_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.refreshRejectedRooms
    );
  }
}
