import { TimedOfferRecord } from '../../../../../../../../server-services/query-records/timed-offer-records';
import { WorkspaceSubscriptionAccountOfferEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import {
  getNanoLicensesQuery,
  getTimedOffersQuery,
} from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onDeleteAccountOfferSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler = (
  cache,
  event: WorkspaceSubscriptionAccountOfferEventRecord
) => {
  let allAccountOffer: TimedOfferRecord[] = cache.getCacheDataByRequest({
    query: getTimedOffersQuery,
    variables: {},
  });

  for (let i = 0; i < allAccountOffer.length; i++) {
    if (allAccountOffer[i].id == event.id) {
      allAccountOffer.splice(i, 1);
      break;
    }
  }
  cache.deleteCacheDataByRequest({ query: getNanoLicensesQuery, variables: {} });
};
