import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Editor, Element, Node, Range, Scrubber, Selection, Transforms, createEditor } from 'slate';
import { AngularEditor, withAngular } from 'slate-angular';
import { withHistory } from 'slate-history';
import { MessageEditorBase } from '../message-editor-base';
import { TOKEN_TYPE, markdownTokens } from './markdown';
import { SlateCodeLineComponent } from './slate-components/slate-code-line/slate-code-line.component';
import { SlateEditorLeafComponent } from './slate-components/slate-editor-leaf/slate-editor-leaf.component';
import { SlateEmojiComponent } from './slate-components/slate-emoji/slate-emoji/slate-emoji.component';
import { CODE_LINE_NODE_TYPE, withMarkdown } from './with-markdown';
import { withUnit } from './with-unit';

@Component({
  selector: 'app-slate-editor',
  templateUrl: './slate-editor.component.html',
  styleUrls: ['./slate-editor.component.scss'],
})
export class SlateEditorComponent extends MessageEditorBase implements AfterViewInit {
  @ViewChild('slateEditorElement', { read: ElementRef }) slateEditorElement: ElementRef;
  @ViewChild('styleMenu', { read: ElementRef }) styleMenu: ElementRef;

  public toggleStyleMenu: boolean = false;
  public TOKEN_TYPE = TOKEN_TYPE;

  /**
   * The actual prefix, what you typed, it is passed to the
   * emoji shortcode picker component as Input
   */
  public shortcodePrefix: string = '';

  defaultValue = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ];
  public value = {
    type: 'paragraph',
    children: [{ text: '' }],
  };
  public valueAsString = '';

  /**
   * For emoji insert, or style toggle we should know the last selection
   */
  public lastSelection: Selection | null = null;

  @ViewChild('paragraph', { read: TemplateRef, static: true })
  paragraphTemplate!: TemplateRef<any>;
  editor = withSaveLastSelection(withMarkdown(withUnit(withAngular(withHistory(createEditor())))));

  constructor(private ref: ElementRef) {
    super();
    // check changes events for emojiPrefix
    const { onChange } = this.editor;
    this.editor.onChange = (d) => {
      if (this.disabled) return;

      onChange(d); // keep the prev behaviour
      // check the word under the cursor
      if (this.editor.selection) {
        if (Range.isCollapsed(this.editor.selection)) {
          let node = Node.get(this.editor, this.editor.selection.anchor.path);
          if (node['token'] && node['token'].includes(TOKEN_TYPE.EMOJI_SHORTCODE_STARTED)) {
            this.shortcodePrefix = Node.string(node).substring(1); // cut the ":" part
          } else {
            this.shortcodePrefix = '';
          }
          this.toggleStyleMenu = false;
        } else {
          this.toggleStyleMenu = true;
          this.shortcodePrefix = '';
        }
        //console.log('curr node', Node.get(this.editor, this.editor.selection.anchor.path));
      }
    };

    /*
    window['editor'] = this.editor;
    window['Transforms'] = Transforms;
    //window['Path'] = Path;
    window['Editor'] = Editor;
    window['node'] = Node;
    window['text'] = Text;
    window['range'] = Range;
    */
  }

  public getContent(): string {
    return this.valueAsString;
  }

  public setContent(content: string) {
    if (this.editor.children.length > 0 && this.valueAsString != content) {
      this.emptyEditor();
      this.insertText(content);
    }
  }

  public emptyEditor() {
    Transforms.insertText(this.editor, '.', {
      at: [
        this.editor.children.length - 1,
        this.editor.children[this.editor.children.length - 1].children.length - 1,
      ],
    });
    Transforms.select(this.editor, []);
    Transforms.select(this.editor, {
      anchor: Editor.start(this.editor, []),
      focus: Editor.end(this.editor, []),
    });
    Transforms.delete(this.editor);
  }

  public insertEmoji(emoji: string) {
    this.insertText(emoji);
  }

  /**
   * Insert text at the selection
   * We should call the editor's insertData because it triggers
   * the token parser
   * @param text
   */
  public insertText(text: string) {
    if (!this.editor.selection && this.editor['lastSelection']) {
      Transforms.select(this.editor, this.editor['lastSelection']);
    }

    if (this.editor.selection) {
      let dataTransfer = new DataTransfer();
      dataTransfer.setData('text/plain', text);
      this.editor.insertData(dataTransfer);

      //reset history so "undo" will not delete the editing original text
      this.editor.history = {
        redos: [],
        undos: [],
      };
    }
  }

  public focus() {
    AngularEditor.focus(this.editor);
    // jumps to the end of line
    setTimeout(() => {
      Transforms.select(this.editor, Editor.end(this.editor, []));
    }, 5);
  }

  ngAfterViewInit(): void {
    Transforms.insertNodes(this.editor, this.defaultValue);

    this.slateEditorElement.nativeElement.addEventListener('focus', () => {
      this.focusChange(true);
    });
    this.slateEditorElement.nativeElement.addEventListener('blur', () => {
      this.focusChange(false);
    });
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    var emojiShortcodePickerElement = this.ref.nativeElement.querySelector(
      'app-emoji-shortcode-picker'
    );

    if (
      !this.ref.nativeElement.contains(event.target) &&
      !emojiShortcodePickerElement.contains(event.target)
    ) {
      this.shortcodePrefix = '';
    }

    if (
      !this.ref.nativeElement.contains(event.target) &&
      (!this.styleMenu || !this.styleMenu.nativeElement.contains(event.target))
    ) {
      this.toggleStyleMenu = false;
    }
  }

  public handleEmojiAutocomplete(event) {
    // it should be, because we wrote some emoji prefix
    if (this.editor['lastSelection']) {
      Transforms.select(this.editor, this.editor['lastSelection'].anchor.path);
      Transforms.delete(this.editor); // clear the prev word
      // insert the new
      this.insertText(event.emoji);
    }
  }

  valueChange(value?: Element[]) {
    if (this.editor.children.length == 0) {
      Transforms.insertNodes(this.editor, this.defaultValue);
      if (this.valueAsString) {
        this.insertText(this.valueAsString);
      }
    }
    this.valueAsString = Node.string(Node.get(this.editor, [0]));
    for (let i = 1; i < this.editor.children.length; i++) {
      this.valueAsString += '\n' + Node.string(Node.get(this.editor, [i]));
    }

    this.change(this.valueAsString);
  }

  renderElement = (element: any) => {
    switch (element.type) {
      case 'emoji':
        return SlateEmojiComponent;
      case CODE_LINE_NODE_TYPE:
        return SlateCodeLineComponent;
      default:
        return this.paragraphTemplate;
    }
  };

  renderLeaf = (text: any) => {
    //console.log('leaf', text);
    return SlateEditorLeafComponent;
  };

  public setFormat(format: TOKEN_TYPE) {
    for (let i = 0; i < markdownTokens.length; i++) {
      if (format == markdownTokens[i].type) {
        let currSelection = this.editor['lastSelection'];
        let [start, end] = Range.edges(currSelection);
        //console.log('start end', start, end);
        // to the last first, if you do the start, it can change the whole structure
        // so you wont find the end position
        if (markdownTokens[i].end) {
          Transforms.select(this.editor, end);
          this.insertText(markdownTokens[i].end);
        }
        // to this last
        if (markdownTokens[i].start) {
          Transforms.select(this.editor, start);
          this.insertText((markdownTokens[i].lineStart ? '\n' : '') + markdownTokens[i].start);
        }

        // Chrome selection bug, after this operations, the selection API not triggering if you click at the end
        // of the editor. So you can not select the last character, it ignores it. It works anywhere else.
        // So repair the selection with the end position
        Transforms.select(this.editor, Editor.end(this.editor, [this.editor.children.length - 1]));
        return;
      }
    }
  }

  private deleteWordAtCursor() {
    let { path, offset } = this.editor.selection.anchor;
    let nodeString = Node.string(Node.get(this.editor, path));
    let findWordStartOffset = offset;

    while (findWordStartOffset > 0 && nodeString[findWordStartOffset - 1] != ' ') {
      findWordStartOffset--;
    }

    let findWordEndOffset = offset;

    while (findWordEndOffset < nodeString.length && nodeString[findWordEndOffset] != ' ') {
      findWordEndOffset++;
    }

    Transforms.select(this.editor, {
      anchor: {
        path,
        offset: findWordStartOffset,
      },
      focus: {
        path,
        offset: findWordEndOffset,
      },
    });
    Transforms.delete(this.editor);
  }
}

/**
 * It fails when adding editor to parameter
 */
Scrubber.stringify = (value) => {
  try {
    return JSON.stringify(value, Scrubber['_scrubber']);
  } catch (e) {
    console.error('scrubber fail at', Object.assign({}, value));
    return '';
  }
};

/**
 * For emoji short code insert we should know the last selection
 * because if you click out of the editor, it clears the selection
 * @param editor
 */
const withSaveLastSelection = (editor) => {
  const { onChange } = editor;
  editor.onChange = (d) => {
    if (editor.selection) {
      editor['lastSelection'] = editor.selection;
    }
    onChange(d);
  };
  return editor;
};
