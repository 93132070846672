<!-- <div class="group" #group>
  <div class="group-header" (click)="toggle()">
    <span class="group-arrow">
      <mat-icon [ngClass]="{ 'flip-arrow': !closed }">keyboard_arrow_up</mat-icon>
    </span>
    <span class="group-title mat-subtitle-2" translate> Ungrouped </span>
  </div>

  <div class="group-body" appDropList="sidebar-nodes" dropListId="group-{{ groupId }}" appPreviewComponentType="resource" #listContainer>
    <ng-container *ngFor="let r of showResourceList">
      <app-sidebar-room
        [id]="'room-' + r.id"
        *ngIf="r.type === 'room'"
        [sidebarResourceData]="r"
        appDrag="sidebar-nodes"
        [disableDrag]="closed"
        [previewData]="r"
        previewComponentType="room"
        (dragDropped)="resourceDragEnded($event, r)"
        appLongPress=""
        (longPress)="moveIntoOtherGroup(r)"
      ></app-sidebar-room>
      <app-sidebar-dialogue
        *ngIf="r.type === 'dialogue'"
        [sidebarResourceData]="r"
        appDrag="sidebar-nodes"
        [disableDrag]="closed"
        [previewData]="r"
        previewComponentType="dialogue"
        (dragDropped)="resourceDragEnded($event, r)"
        appLongPress=""
        (longPress)="moveIntoOtherGroup(r)"
      ></app-sidebar-dialogue>
    </ng-container>
  </div>
</div> -->
<mat-expansion-panel *ngIf="!simpleDisplay" [expanded]="!closed" [disabled]="isGroupReordering || isRoomReordering" class="group-expansion-panel">
  <mat-expansion-panel-header (click)="toggle()">
    <mat-panel-title>
      <span class="group-title mat-subtitle-2"> Ungrouped </span>
      <span class="group-options">
        <mat-icon *ngIf="!isGroupReordering && !isRoomReordering" matRipple [matMenuTriggerFor]="menu" (click)="stopPropagation($event)">more_vert</mat-icon>
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="group-body" cdkDropList cdkDropListAutoScrollStep="15" [cdkDropListData]="showResourceList" (cdkDropListDropped)="roomDropped($event)">
    <div *ngFor="let r of showResourceList">
      <app-sidebar-room *ngIf="r.type === 'room'" [sidebarResourceData]="r" [isReordering]="isRoomReordering"></app-sidebar-room>
      <app-sidebar-dialogue *ngIf="r.type === 'dialogue'" [sidebarResourceData]="r" [isReordering]="isRoomReordering"></app-sidebar-dialogue>
    </div>
  </div>
</mat-expansion-panel>

<ng-container *ngIf="simpleDisplay">
  <div class="group-options empty-row">
    <mat-icon *ngIf="!isGroupReordering && !isRoomReordering" matRipple [matMenuTriggerFor]="menu" (click)="stopPropagation($event)">more_vert</mat-icon>
  </div>
  <div class="group-body" cdkDropList cdkDropListAutoScrollStep="15" [cdkDropListData]="showResourceList" (cdkDropListDropped)="roomDropped($event)">
    <div *ngFor="let r of showResourceList">
      <app-sidebar-room *ngIf="r.type === 'room'" [sidebarResourceData]="r" [isReordering]="isRoomReordering"></app-sidebar-room>
      <app-sidebar-dialogue *ngIf="r.type === 'dialogue'" [sidebarResourceData]="r" [isReordering]="isRoomReordering"></app-sidebar-dialogue>
    </div>
  </div>
</ng-container>

<mat-menu #menu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item (click)="startGroupReorder()" *ngIf="!simpleDisplay">
    <mat-icon>swap_vert</mat-icon>
    <span translate>Category reorder</span>
  </button>
  <button mat-menu-item (click)="startRoomReorder()">
    <mat-icon>swap_vert</mat-icon>
    <span translate>Room reorder</span>
  </button>
</mat-menu>
