<div id="emoji-container" *ngIf="loadEmojiPicker">
  <app-emoji-picker (selectEmojiEvent)="handleEmojiInsert($event)" [style.display]="toggledEmojiPopup ? 'block' : 'none'"></app-emoji-picker>
</div>
<div id="attachments" *ngIf="attachments.length > 0">
  <div id="floating-attachment-inside-cont">
    <app-input-attachment *ngFor="let f of attachments" [file]="f" (removeEvent)="removeAttachment(f)"></app-input-attachment>
  </div>
</div>
<div id="editor-container" [ngClass]="{ messageLong: messageLengthTooLong }">
  <div class="actions" *ngIf="canUpload && isDriveAvailable" [ngStyle]="{ cursor: canUpload && isDriveAvailable ? 'auto' : 'not-allowed' }">
    <button [matMenuTriggerFor]="attachmentMenu" mat-icon-button [disabled]="messageSending || !isDriveAvailable || !canUpload">
      <mat-icon>attach_file</mat-icon>
    </button>
    <mat-menu #attachmentMenu="matMenu">
      <button mat-menu-item (click)="useCamera()">
        <mat-icon>photo_camera</mat-icon>
        <span translate>Camera</span>
      </button>
      <button mat-menu-item (click)="fileInput.click()">
        <mat-icon>upload</mat-icon>
        <span translate>Upload File</span>
      </button>
      <button mat-menu-item (click)="addDriveFiles()">
        <mat-icon>folder_open</mat-icon>
        <span translate>Browse Drive</span>
      </button>
    </mat-menu>
  </div>
  <div id="editor">
    <app-slate-editor *ngIf="isOnDesktop"></app-slate-editor>
    <app-native-editor *ngIf="!isOnDesktop"></app-native-editor>
  </div>
  <div class="actions">
    <button mat-icon-button [disabled]="messageSending" #emojiBtn id="insert-emoji-btn" (click)="onToggleEmojiPopup()">
      <mat-icon>insert_emoticon</mat-icon>
    </button>
    <button mat-icon-button *ngIf="!messageSending" (click)="submit()" [disabled]="messageLengthTooLong">
      <mat-icon>send</mat-icon>
    </button>
  </div>

  <div id="messageSendingLoader" *ngIf="messageSending">
    <mat-spinner diameter="20"></mat-spinner>
  </div>

  <span id="messageTooLongError" class="warn" *ngIf="messageLengthTooLong">Message too long! ({{ content.length }}/{{ messageMaxLength }})</span>
</div>
<input type="file" #fileInput multiple class="hidden-input" (change)="addAttachment($event)" />
