import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateAccountOfferSubscriptionHandler } from './ws-sub-account-offer-event-create-handler';
import { onDeleteAccountOfferSubscriptionHandler } from './ws-sub-account-offer-event-delete-handler';
import { onModifyAccountOfferSubscriptionHandler } from './ws-sub-account-offer-event-modify-handler';

export let workspaceSubscriptionAccountOfferEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateAccountOfferSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyAccountOfferSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteAccountOfferSubscriptionHandler,
};
