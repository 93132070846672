import { RoomDetail } from '../../../../../../server-services/query-records/room-records';
import { ResponseMergerHandler } from '../../../cache-logic-interface';
import { SeenCacheRecord } from '../../../custom-cache-reset-logic';

export let customRoomDetailQueryMerger: ResponseMergerHandler = function (
  cache,
  request,
  response: RoomDetail
) {
  let cacheKey = cache.getCacheLogic().makeCacheDataPath(request);
  cache.setCacheData(cacheKey, response);

  if (response.peerSeenIds) {
    // as an anonym we don't have this prop
    let cacheInsert: SeenCacheRecord = {
      messages: {},
      users: {},
    };

    let msgSeenMap: { [messageId: string]: string[] } = {}; // messageId - userId

    // make a reverse map from userId->messageId to messageId->userId[]
    for (const [userId, messageId] of Object.entries(response.peerSeenIds)) {
      if (msgSeenMap[messageId]) {
        msgSeenMap[messageId].push(userId);
      } else {
        msgSeenMap[messageId] = [userId];
      }

      cacheInsert.users[userId] = messageId; // save userId->messageId in cache
    }

    // save all in the cache
    for (const [messageId, users] of Object.entries(msgSeenMap)) {
      cacheInsert.messages[messageId] = users; // save messageId->userId in cache
    }

    cache.getCacheData('seen')[request.variables.id] = cacheInsert;
  }
};
