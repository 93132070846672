import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Subscription } from 'rxjs';
import { ResizedEvent } from 'src/app/shared/directives/resized.directive';
import { UnsentMessage } from 'src/app/shared/server-services/resource-base';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-chat-unsent-message',
  templateUrl: './chat-unsent-message.component.html',
  styleUrls: ['./chat-unsent-message.component.scss'],
})
export class ChatUnsentMessageComponent implements OnInit, OnDestroy {
  @Input() data: UnsentMessage;

  @Output() readonly resizedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() editEvent: EventEmitter<UnsentMessage> = new EventEmitter<UnsentMessage>();

  private progressSubscription: Subscription;

  public allAttachment: number = 0;
  public uploadedAttachment: number = 0;

  public uploadError: boolean = false;
  public sendError: boolean = false;

  public showMessage: boolean = false;
  public showTimer;

  constructor(private dialogService: DialogService) {}

  ngOnInit(): void {
    this.allAttachment = this.data.attachments.length;
    this.progressSubscription = this.data.progressSubject.subscribe((done) => {
      this.uploadError = false;
      this.uploadedAttachment = 0;
      this.data.attachments.forEach((att) => {
        if (att.uploadErrorState) {
          this.uploadError = true;
        }
        if (att.uploadDoneState) {
          this.uploadedAttachment++;
        }
      });
      this.sendError = this.data.sendError;
    });
    this.showTimer = setTimeout(() => {
      this.showMessage = true;
    }, 2000);
  }

  ngOnDestroy(): void {
    this.progressSubscription.unsubscribe();
    clearTimeout(this.showTimer);
  }

  public cancel() {
    this.dialogService
      .openConfirmDialog(
        marker('Cancel message'),
        marker('Are you sure you want to cancel this message?')
      )
      .subscribe((res) => {
        if (res) {
          this.data.remove();
        }
      });
  }

  public edit() {
    this.editEvent.emit(this.data);
  }

  public onResized(event: ResizedEvent) {
    this.resizedEvent.emit();
  }
}
