import {
  LoadMessageDirection,
  MessageRecord,
  MessageRecordPlaceholder,
  MessageRecordType,
} from 'src/app/shared/server-services/query-records/room-records';
import { ResponseManipulatorHandler } from '../../../cache-logic-interface';

export let roomMessagesQueryManipulator: ResponseManipulatorHandler = (
  cache,
  cacheKey,
  request
) => {
  const cacheData: any[] = cache.getCacheData(cacheKey);
  if (cacheData) {
    let pivotIndex = -1;
    const count = request.variables.count ?? 36;
    if (request.variables.pivot) {
      for (let i = 0; i < cacheData.length; i++) {
        if (cacheData[i].id < request.variables.pivot) {
          pivotIndex = i; // pivotIndex-th message is already in chat
          break;
        }
      }
    } else {
      pivotIndex = 0;
    }

    if (request.variables?.direction === LoadMessageDirection.BEFORE) {
      // notice: message array is reversed
      return lookBefore(cacheData, pivotIndex, count);
    } else {
      return lookAfter(cacheData, pivotIndex, count);
    }
  }

  return undefined;
};

const lookBefore = (cacheData, pivotIndex, count): (MessageRecordPlaceholder | MessageRecord)[] => {
  const sliceFrom = pivotIndex - count >= 0 ? pivotIndex - count : 0;
  const sliceTo = pivotIndex;

  const slicedCacheData = cacheData.slice(sliceFrom, sliceTo);

  for (let i = slicedCacheData.length; i >= 0; i--) {
    if (slicedCacheData[i]?.type === MessageRecordType.VOID) {
      return slicedCacheData.slice(i, slicedCacheData.length);
    }
  }

  if (slicedCacheData[0]?.type === MessageRecordType.END) {
    return slicedCacheData.slice(0, slicedCacheData.length); // @todo lehet h az endekkel itt probléma lesz
  }

  //there is no concrete way of determining if there is no VOID before or after the sliced messages, so we must insert a VOID at the start and end too

  const messagePlaceholderBeforeFirst: MessageRecordPlaceholder = {
    type: MessageRecordType.VOID,
    afterMsgId: slicedCacheData?.[0]?.id,
  };

  return [messagePlaceholderBeforeFirst, ...slicedCacheData];
};

const lookAfter = (cacheData, pivotIndex, count): (MessageRecordPlaceholder | MessageRecord)[] => {
  const slicedCacheData = cacheData.slice(pivotIndex, pivotIndex + count);

  for (let i = 0; i < slicedCacheData.length; i++) {
    if (slicedCacheData[i]?.type === MessageRecordType.VOID) {
      return slicedCacheData.slice(0, i + 1);
    }
  }

  if (slicedCacheData[slicedCacheData.length - 1]?.type === MessageRecordType.END) {
    return slicedCacheData.slice(0, slicedCacheData.length); // @todo lehet h az endekkel itt probléma lesz
  }

  //there is no concrete way of determining if there is no VOID before or after the sliced messages, so we must insert a VOID at the start and end too

  const messagePlaceholderAfterLast: MessageRecordPlaceholder = {
    type: MessageRecordType.VOID,
    beforeMsgId: slicedCacheData[slicedCacheData.length - 1]?.id,
  };

  return [...slicedCacheData, messagePlaceholderAfterLast];
};
