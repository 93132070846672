<mat-action-list>
  <ng-container *ngIf="interactingFiles.length > 0">
    <ng-container *ngIf="interactingFiles.length > 1">
      <p><span translate>Selected files: </span>{{ interactingFiles.length }}</p>
      <mat-divider></mat-divider>
      <mat-list-item (click)="sheetActionClick(SheetAction.DOWNLOAD)">
        <mat-icon matListItemIcon>download</mat-icon>
        <h3 matListItemTitle>Download files</h3>
        <p matListItemLine>
          <span>Start downloading the selected files. </span>
        </p>
      </mat-list-item>
      <mat-list-item (click)="sheetActionClick(SheetAction.MOVE)">
        <mat-icon matListItemIcon>drive_file_move_outline</mat-icon>
        <h3 matListItemTitle>Move file</h3>
        <p matListItemLine>
          <span>Move selected files and folders. </span>
        </p>
      </mat-list-item>
      <mat-list-item (click)="sheetActionClick(SheetAction.DELETE)">
        <mat-icon matListItemIcon>delete</mat-icon>
        <h3 matListItemTitle>Delete files</h3>
        <p matListItemLine>
          <span>Delete the selected files from the drive. </span>
        </p>
      </mat-list-item>
    </ng-container>

    <ng-container *ngIf="interactingFiles.length === 1">
      <table>
        <app-file-info [file]="interactingFiles[0]"></app-file-info>
      </table>
      <mat-divider></mat-divider>
      <mat-list-item (click)="sheetActionClick(SheetAction.DOWNLOAD)">
        <mat-icon matListItemIcon>download</mat-icon>
        <h3 matListItemTitle>Download file</h3>
        <p matListItemLine>
          <span>Start downloading the selected file. </span>
        </p>
      </mat-list-item>
      <mat-list-item (click)="sheetActionClick(SheetAction.MOVE)">
        <mat-icon matListItemIcon>drive_file_move_outline</mat-icon>
        <h3 matListItemTitle>Move file</h3>
        <p matListItemLine>
          <span>Move selected file or folder. </span>
        </p>
      </mat-list-item>
      <mat-list-item (click)="sheetActionClick(SheetAction.DELETE)">
        <mat-icon matListItemIcon>delete</mat-icon>
        <h3 matListItemTitle>Delete file</h3>
        <p matListItemLine>
          <span>Delete the selected file from the drive. </span>
        </p>
      </mat-list-item>
      <mat-list-item (click)="sheetActionClick(SheetAction.RENAME)">
        <mat-icon matListItemIcon>drive_file_rename_outline</mat-icon>
        <h3 matListItemTitle>Rename file</h3>
        <p matListItemLine>
          <span>Rename the selected file.</span>
        </p>
      </mat-list-item>
      <mat-list-item (click)="sheetActionClick(SheetAction.GETLINK)">
        <mat-icon matListItemIcon>link</mat-icon>
        <h3 matListItemTitle>Get link</h3>
        <p matListItemLine>
          <span>Get the link to the selected file.</span>
        </p>
      </mat-list-item>
      <mat-list-item *ngIf="allowAnonym" (click)="sheetActionClick(SheetAction.GETPUBLICLINK)">
        <mat-icon matListItemIcon>add_link</mat-icon>
        <h3 matListItemTitle>Get public link</h3>
        <p matListItemLine>
          <span>Get the public link to the selected file.</span>
        </p>
      </mat-list-item>
      <mat-list-item (click)="sheetActionClick(SheetAction.PROPERTIES)">
        <mat-icon matListItemIcon>description</mat-icon>
        <h3 matListItemTitle>Properties</h3>
        <p matListItemLine>
          <span>Show files properties</span>
        </p>
      </mat-list-item>
    </ng-container>
    <mat-divider></mat-divider>
  </ng-container>
  <!--<mat-list-item (click)="sheetActionClick(SheetAction.UPLOAD)">
    <mat-icon matListItemIcon>upload</mat-icon>
    <h3 matListItemTitle>Upload file</h3>
    <p matListItemLine>
      <span>Upload files to the current location.</span>
    </p>
  </mat-list-item>-->
  <mat-list-item (click)="sheetActionClick(SheetAction.NEWFOLDER)">
    <mat-icon matListItemIcon>create_new_folder</mat-icon>
    <h3 matListItemTitle>New folder</h3>
    <p matListItemLine>
      <span>Create a new folder in the current location.</span>
    </p>
  </mat-list-item>
  <mat-list-item (click)="sheetActionClick(SheetAction.TILEVIEW)">
    <mat-icon matListItemIcon>view_module</mat-icon>
    <h3 matListItemTitle>Tile view</h3>
    <p matListItemLine>
      <span>Change to tile view.</span>
    </p>
  </mat-list-item>
  <mat-list-item (click)="sheetActionClick(SheetAction.LISTVIEW)">
    <mat-icon matListItemIcon>view_list</mat-icon>
    <h3 matListItemTitle>List view</h3>
    <p matListItemLine>
      <span>Change to list view.</span>
    </p>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item *ngIf="hasDirectoryPicker && !isOnRoot" (click)="sheetActionClick(SheetAction.FOLDERDOWNLOAD)">
    <span matListItemIcon class="mdi mdi-folder-download"></span>
    <h3 matListItemTitle>Download Current Folder</h3>
    <p matListItemLine>
      <span>Download this current folder</span>
    </p>
  </mat-list-item>
  <mat-list-item *ngIf="hasDirectoryPicker && isOnRoot" (click)="sheetActionClick(SheetAction.DRIVEDOWNLOAD)">
    <mat-icon matListItemIcon>browser_updated</mat-icon>
    <h3 matListItemTitle>Download Drive</h3>
    <p matListItemLine>
      <span>Download this drive</span>
    </p>
  </mat-list-item>
</mat-action-list>
