import { Component, Inject, INJECTOR, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';
import { NanoValidators } from 'src/app/shared/nano-validators';

@Component({
  selector: 'app-admin-password-dialog',
  templateUrl: './admin-password-dialog.component.html',
  styleUrls: ['./admin-password-dialog.component.scss'],
})
export class AdminPasswordDialogComponent
  extends NanoDialogBaseComponent<AdminPasswordDialogComponent>
  implements OnInit
{
  passwordForm = new UntypedFormGroup({
    password: new UntypedFormControl(''),
    passwordAgain: new UntypedFormControl(''),
  });

  currentMode: AdminPasswordMode;
  dialogModes = AdminPasswordMode;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    @Inject(MAT_DIALOG_DATA) public adminPasswordDialogData: AdminPasswordData
  ) {
    super(injector);
    this.currentMode = adminPasswordDialogData.adminPasswordMode;
  }

  ngOnInit(): void {
    if (
      this.currentMode == AdminPasswordMode.FIRST ||
      this.currentMode == AdminPasswordMode.CHANGE
    ) {
      this.passwordForm.get('password').addValidators([Validators.required]);
      this.passwordForm
        .get('passwordAgain')
        .addValidators([
          Validators.required,
          NanoValidators.controlEquals(this.passwordForm.get('password')),
        ]);

      this.passwordForm.get('password').valueChanges.subscribe((e) => {
        if (this.passwordForm.get('passwordAgain').touched) {
          this.passwordForm.get('passwordAgain').updateValueAndValidity();
        }
      });
    } else {
      this.passwordForm.get('password').addValidators([Validators.required]);
    }
  }

  public setPassword() {
    this.dialogRef.close({
      password: this.passwordForm.get('password').value,
      passwordAgain: this.passwordForm.get('passwordAgain').value,
    });
  }
}

interface AdminPasswordData {
  adminPasswordMode: number;
}

export enum AdminPasswordMode {
  PROVIDE = 0,
  CHANGE = 1,
  FIRST = 2,
  PROVIDE_FOR_SESSION = 3,
}
