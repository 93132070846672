export type PaymentError = {
  error?: any;
};

/**
 * 
 {
    "round_currency": {"HUF": 0, "EUR": 2},
    "recurring_max": 12,
    "products": {
        "NL-1": {
            "price": {"EUR": 4.0, "HUF": 1417.32},
            "packages": {
                "30d-1": {
                    "active": True,
                    "target_limits": {"own": 100, "bundle": 10000},
                    "methods": ["card"],
                    "own_may_be_recurring": True,
                    "months": 1,
                },
                "90d-1": {
                    "active": True,
                    "target_limits": {"own": 100, "bundle": 10000},
                    "methods": ["card"],
                    "own_may_be_recurring": True,
                    "months": 3,
                },
                "360d-1": {
                    "active": True,
                    "target_limits": {"own": 100, "bundle": 10000},
                    "methods": ["card", "wire"],
                    "own_may_be_recurring": False,
                    "wire_minimum_price": {"EUR": 300.0, "HUF": 100000.0},
                    "months": 12,
                    "free_months": 1,
                },
            },
        }
    },
}
 */
export type PaymentShopData = {
  response: PaymentShopDataRecord & PaymentError;
  accountLicenseSlotLimit: number;
};

export type PaymentShopDataRecord = {
  round_currency: { [key in PaymentShopDataCurrencyType]: number };
  recurring_max: number;
  constant_tax_number_prefixes: any;
  products: { [key in PaymentShopDataProductsType]: PaymentShopDataProduct };
};

export enum PaymentShopDataProductsType {
  NL_1 = 'NL-1',
  NL_2 = 'NL-2',
}

export enum PaymentShopDataCurrencyType {
  HUF = 'HUF',
  EUR = 'EUR',
}

export type PaymentShopDataProduct = {
  price: { [key in PaymentShopDataCurrencyType]: number };
  packages: {
    [key in PaymentShopDataProductPackageType]: PaymentShopDataProductPackage;
  };
};

export enum PaymentShopDataProductPackageType {
  ONE_MONTH = '30d-1',
  THREE_MONTH = '90d-1',
  ONE_YEAR = '360d-1',
}

export type PaymentShopDataProductPackage = {
  active: boolean;
  target_limits: {
    [key in PaymentShopDataProductPackageTargetLimitType]: number;
  };
  methods: PaymentShopDataProductPackageMethodType[];
  own_may_be_recurring: boolean;
  wire_minimum_price?: { [key in PaymentShopDataCurrencyType]: number };
  months: number;
  free_months?: number;
};

export enum PaymentShopDataProductPackageTargetLimitType {
  OWN = 'own',
  BUNDLE = 'bundle',
}

export enum PaymentShopDataProductPackageMethodType {
  CARD = 'card',
  WIRE = 'wire',
}

/**
[
    {
        "billing_id": 0,
        "entity": "individual",
        "email": "test0@x.y",
        "name": "Teszt Béla",
        "name_of_purchaser": "",
        "country": "HU",
        "state": "Yes",
        "postal": "xyxy",
        "city": "Makó",
        "address": "Here and there",
        "tax_number": "",
    },
    {"billing_id": 1, ...},
    ...
]
*/

export type PaymentBillings = {
  response: PaymentBillingsRecord[] & PaymentError;
};

export type PaymentBillingsRecord = {
  /**
   * number: between 0 and 9, inclusive
   */
  billing_id: number;
} & PaymentBillingsData;

export type PaymentBillingsData = {
  entity: PaymentEntitiyType;
  email: string;
  name: string;
  name_of_purchaser: string;
  /**
   * short code for the country (uppercase) like "HU"
   */
  country: string;
  state: string;
  postal: string;
  city: string;
  address: string;
  tax_number: string;
};

export enum PaymentEntitiyType {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

export type PaymentSaveBillingResult = {
  response: { billing_id: number } & PaymentError;
};

export type PaymentPrepareData = {
  currency: PaymentShopDataCurrencyType;
  product: PaymentShopDataProductsType;
  package: PaymentShopDataProductPackageType;
  target: PaymentShopDataProductPackageTargetLimitType;
  number: number;
  entity: PaymentEntitiyType;
  email: string;
  name: string;
  name_of_purchaser: string;
  country: string;
  state: string;
  postal: string;
  city: string;
  address: string;
  tax_number: string;
  method: PaymentShopDataProductPackageMethodType;
  recurring_number: number;
};

export type PaymentPreapreResult = {
  response: PaymentPrepareResultRecord | PaymentError;
};

export type PaymentPrepareResultRecord = {
  order: {
    currency: PaymentShopDataCurrencyType;
    discount_reference: string;
    product: PaymentShopDataProductsType;
    package: PaymentShopDataProductPackageType;
    target: PaymentShopDataProductPackageTargetLimitType;
    number: number;
    entity: PaymentEntitiyType;
    email: string;
    name: string;
    name_of_purchaser: string;
    country: string;
    state: string;
    postal: string;
    city: string;
    address: string;
    tax_number: string;
    vat: number;
    net_discount_percent: number;
    price_net: number;
    price_gross: number;
    method: PaymentShopDataProductPackageMethodType;
    recurring_number: number;
  };
  order_hash: number;
};

export type PaymentBuyCodeRecord = {
  buyCode: string;
  valid: boolean;
};

export type PaymentRecordOrder = {
  order: PaymentPrepareData;
  orderHash: number;
  language: string;
};

export type PaymentRecordOrderResult = {
  payment_url: string;
};

export type BackDataRecord = {
  r: number;
  t: number;
  e: string;
  m: string;
  o: string;
};
