import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';

@Component({
  selector: 'app-redeem-token-dialog',
  templateUrl: './redeem-token-dialog.component.html',
  styleUrls: ['./redeem-token-dialog.component.scss'],
})
export class RedeemTokenDialogComponent extends NanoDialogBaseComponent<RedeemTokenDialogComponent> {
  redeemForm = new UntypedFormGroup({
    redeemCode: new UntypedFormControl('', [Validators.required]),
  });

  constructor(@Inject(INJECTOR) injector: Injector) {
    super(injector);
  }

  public redeem(): void {
    if (!this.redeemForm.valid) return;

    this.dialogRef.close(this.redeemForm.get('redeemCode').value);
  }
}
