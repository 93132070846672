import { AccountConfigDumpArgs, AccountConfigLoadArgs } from './args';
import { CryptoContext } from '../base';
import { AccountConfigV1 } from '../account/v1';

/**
 * For account-stored data by using the account keyring.
 */
export let ACCOUNT_CRYPTO_CONTEXT = new CryptoContext<AccountConfigLoadArgs, AccountConfigDumpArgs>(
  [new AccountConfigV1()]
);
