<div>
  <div class="sub-page">
    <h1 translate>Advanced Settings</h1>

    <div class="setting-row panel">
      <a routerLink="/settings" translate>Back to settings</a>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Max Worker</label>
        <span translate>All workers running on a different thread. The optimal performance depends on your device.</span>
      </div>
      <div class="setting-button-col flex">
        <input type="number" min="1" max="16" class="nano-input" [value]="maxWorker" #maxWorkerInput />
        <button mat-raised-button color="primary" (click)="changeMaxWorker(maxWorkerInput.value)"><span translate>Save</span></button>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>File streaming API limit (Megabytes)</label>
        <span translate>Download files directly on your computer. If the size of the file reaches this limit (megabyte), it will use the streaming technology instead of cache.</span>
        <span translate>Currently only the latest Chrome, Edge on desktop support this feature.</span>
        <span translate
          >See more about this api and support: <a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/API/FileSystemWritableFileStream">FileSystemWritableFileStream</a></span
        >
      </div>
      <div class="setting-button-col flex">
        <input type="number" min="0" max="500" class="nano-input" [value]="fileStreamSizeLimit" #fileStreamSizeLimitInput />
        <button mat-raised-button color="primary" (click)="changeFileStreamSizeLimit(fileStreamSizeLimitInput.value)"><span translate>Save</span></button>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Store downloaded file in cache (seconds)</label>
        <span translate
          >Downloaded files which use cache can be held for a short time and then be downloaded instantly again. Useful if you accidentally deny or cancel the file after the download process.</span
        >
      </div>
      <div class="setting-button-col flex">
        <input type="number" min="10" max="3600" class="nano-input" [value]="holdFileInCache" #holdFileInCacheInput />
        <button mat-raised-button color="primary" (click)="changeHoldFileInCache(holdFileInCacheInput.value)"><span translate>Save</span></button>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Thumbnail cache</label>
        <span translate>Set the limit for thumbnail elements in the cache. A thumbnail is around 2KB of data, so keeping 5000 in memory is only around 10MBs.</span>
      </div>
      <div class="setting-button-col flex">
        <input type="number" min="1" max="1000000" class="nano-input" [value]="maxCachedThumbnailElements" #maxCachedThumbnailElementsInput />
        <button mat-raised-button color="primary" (click)="changeMaxThumbnailCache(maxCachedThumbnailElementsInput.value)"><span translate>Save</span></button>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Preview cache</label>
        <span translate>Set the limit for preview elements in the cache. A preview is around 250KB of data, so keeping 40 in memory is only around 10MBs.</span>
      </div>
      <div class="setting-button-col flex">
        <input type="number" min="1" max="40000" class="nano-input" [value]="maxCachedPreviewElements" #maxCachedPreviewElementsInput />
        <button mat-raised-button color="primary" (click)="changeMaxPreviewCache(maxCachedPreviewElementsInput.value)"><span translate>Save</span></button>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Parallel upload window</label>
        <span translate>Maximum number of chunks when uploading a file</span>
      </div>
      <div class="setting-button-col flex">
        <input type="number" min="1" max="50" class="nano-input" [value]="parallelUploadWindow" #parallelUploadWindowInput />
        <button mat-raised-button color="primary" (click)="changeParallelUploadWindow(parallelUploadWindowInput.value)"><span translate>Save</span></button>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Network file chunk size</label>
        <span translate>Size of one payload when using download/upload request (bytes). Clear all of your running uploads/downloads before change this or reload/reopan the app.</span>
      </div>
      <div class="setting-button-col flex">
        <input type="number" min="1000" max="100000000" step="1" class="nano-input" [value]="networkFileChunkSize" #networkFileChunkSizeInput />
        <button mat-raised-button color="primary" (click)="changeNetworkFileChunkSize(networkFileChunkSizeInput.value)"><span translate>Save</span></button>
      </div>
    </div>

    <div class="setting-row" *ngIf="devOn">
      <div class="setting-info-col">
        <label translate>Show worker usages</label>
        <span translate>Show worker usages on floating graph</span>
      </div>
      <div class="setting-button-col">
        <mat-slide-toggle color="primary" #graphSlide (change)="toggleWorkerGraph(graphSlide.checked)"></mat-slide-toggle>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label><span translate>Enable Identity Signature Feature set</span> - <span class="mat-small" translate>Experimental</span><mat-icon>science</mat-icon></label>
        <span translate
          >With this option you can get your identity signature (in your profile settings), and verify others (by clicking on their avatar) identity signature. Moreover you can modify your drive so
          only verified users can use it.</span
        >
      </div>
      <div class="setting-button-col">
        <mat-slide-toggle color="primary" [(ngModel)]="isIdentitySignatureOn" (change)="changeIdentitySignature(isIdentitySignatureOn)"></mat-slide-toggle>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label><span translate>Enable Bookmarks</span> - <span class="mat-small" translate>Experimental</span><mat-icon>science</mat-icon></label>
        <span translate
          >With this option you can pin folder from any accessible drive. Currently all data will be saved on your machine. During the development phase you can lose these data. To use this, turn-on
          the feature and refresh your browser. This feature is only supported on desktop web browser.</span
        >
      </div>
      <div class="setting-button-col">
        <mat-slide-toggle color="primary" [(ngModel)]="isBookmarksOn" (change)="changeBookmarks(isBookmarksOn)"></mat-slide-toggle>
      </div>
    </div>

    <!--
    <div class="setting-row">
      <div class="setting-info-col">
        <label><span translate>Enable P2P for Live</span></label>
        <span translate>Enables or disable peer-to-peer connection, if disabled all media will be routed through videobridge.</span>
      </div>
      <div class="setting-button-col">
        <mat-slide-toggle color="primary" [(ngModel)]="isLive2P2On" (change)="changeLiveP2P(isLive2P2On)"></mat-slide-toggle>
      </div>
    </div>
    -->

    <div class="setting-row" *ngIf="devOn">
      <div class="setting-info-col">
        <label><span translate>Get Push Token</span> - <span class="mat-small" translate>Debug</span><mat-icon>bug_report</mat-icon></label>
        <span translate>Fetch notification token from the server for this account (debug purpose).</span>
      </div>
      <div class="setting-button-col">
        <button mat-raised-button color="primary" (click)="getPushToken()"><span translate>Fetch</span></button>
      </div>
    </div>

    <div class="setting-row" *ngIf="devOn && pushToken !== undefined">
      <div class="setting-info-col">
        <label><span translate>Push Token:</span> - <span class="mat-small" translate>Debug</span><mat-icon>bug_report</mat-icon></label>
        <span translate>Server token: {{ pushToken }}</span>
        <span translate>Local device token: {{ localPushToken }}</span>
      </div>
      <div class="setting-button-col">
        <button mat-raised-button color="primary" (click)="copyPushToken()"><span translate>Copy</span></button>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-info-col">
        <label translate>Enable developer settings</label>
      </div>
      <div class="setting-button-col">
        <mat-slide-toggle color="primary" [(ngModel)]="devOn"></mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
