<h2 mat-dialog-title translate>Change password</h2>
<form id="password-form" [formGroup]="passwordForm">
  <mat-dialog-content class="mat-typography">
    <div class="form-row">
      <div class="strength-container">
        <div #scoreContentMarker></div>
      </div>
      <mat-form-field appearance="outline">
        <mat-label><span translate>Password</span></mat-label>
        <input appZxcvbn [scoreContentMarker]="scoreContentMarker" (score)="passwordStrength($event)" matInput formControlName="password" type="password" name="nano-webapp-password" />
        <mat-error *ngIf="passwordForm.get('password').errors?.['required']"><span translate>Please enter a new password.</span></mat-error>
        <mat-error *ngIf="passwordForm.get('password').errors?.['passwordStrengthLow'] && !passwordForm.get('password').errors?.['required']"
          ><span translate>Please enter a stronger password.</span></mat-error
        >
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label><span translate>Confirm password</span></mat-label>
        <input matInput formControlName="passwordAgain" type="password" name="nano-webapp-password-again" />
        <mat-error *ngIf="passwordForm.get('passwordAgain').errors?.['required']"><span translate>Please enter a new password.</span></mat-error>
        <mat-error *ngIf="passwordForm.get('passwordAgain').errors?.['controlEquals']"><span translate>Passwords must match.</span></mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-button color="primary" (click)="changePassword()" [disabled]="!passwordForm.valid"><span translate>Change</span></button>
  </mat-dialog-actions>
</form>
