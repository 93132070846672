import { AbstractSelfAccountKeyring } from '../../../keyring/account_base';
import { AbstractRoomKeyring } from '../../../keyring/room_base';
import { AbstractConfigDumpArgs, AbstractConfigLoadArgs } from '../../base';

export class AccountRoomKeyConfigLoadArgs extends AbstractConfigLoadArgs {
  public self_kr;
  public key_version;
  public room_kr;

  constructor(
    cipher: Uint8Array,
    self_kr: AbstractSelfAccountKeyring,
    key_version: string,
    room_kr: AbstractRoomKeyring
  ) {
    super(cipher);
    this.self_kr = self_kr;
    this.key_version = key_version;
    this.room_kr = room_kr;
  }
}

export class AccountRoomKeyConfigDumpArgs extends AbstractConfigDumpArgs {
  public self_kr;
  public key_version;
  public room_kr;

  constructor(
    self_kr: AbstractSelfAccountKeyring,
    key_version: string,
    room_kr: AbstractRoomKeyring
  ) {
    super();
    this.self_kr = self_kr;
    this.key_version = key_version;
    this.room_kr = room_kr;
  }
}
