import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateDialogueEventSubscriptionHandler } from './ws-sub-dialogue-event-create-handler';
import { onDeleteDialogueEventSubscriptionHandler } from './ws-sub-dialogue-event-delete-handler';
import { onModifyDialogueEventSubscriptionHandler } from './ws-sub-dialogue-event-modify-handler';

export let workspaceSubscriptionDialogueEventHandler: WorkspaceChangeSubscriptionCMDType = {
  [SubscriptionServiceEventType.CREATE]: onCreateDialogueEventSubscriptionHandler,
  [SubscriptionServiceEventType.MODIFY]: onModifyDialogueEventSubscriptionHandler,
  [SubscriptionServiceEventType.DELETE]: onDeleteDialogueEventSubscriptionHandler,
};
