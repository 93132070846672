<h2 mat-dialog-title>{{ generalConfirmData.title | translate }}</h2>
<mat-dialog-content class="mat-typography">
  {{ generalConfirmData.text | translate }}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    <span *ngIf="!generalConfirmData.cancelButtonText" translate>Close</span>
    <span *ngIf="generalConfirmData.cancelButtonText">{{ generalConfirmData.cancelButtonText | translate }}</span>
  </button>
  <button mat-button color="primary" (click)="closeWithOk()">
    <span *ngIf="!generalConfirmData.okButtonText" translate>OK</span>
    <span *ngIf="generalConfirmData.okButtonText">{{ generalConfirmData.okButtonText | translate }}</span>
  </button>
</mat-dialog-actions>
