import { Component, Inject, INJECTOR, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NanoDialogBaseComponent } from 'src/app/shared/dialogs/nano-base-dialog';
import { PaymentService } from 'src/app/shared/server-services/payment.service';
import { PaymentBillingsRecord } from 'src/app/shared/server-services/query-records/payment-records';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-edit-billing-template-dialog',
  templateUrl: './edit-billing-template-dialog.component.html',
  styleUrls: ['./edit-billing-template-dialog.component.scss'],
})
export class EditBillingTemplateDialogComponent extends NanoDialogBaseComponent<EditBillingTemplateDialogComponent> {
  paymentBillings: PaymentBillingsRecord[];

  constructor(
    @Inject(INJECTOR) injector: Injector,
    public dialogService: DialogService,
    public paymentService: PaymentService,
    @Inject(MAT_DIALOG_DATA) paymentBillings: PaymentBillingsRecord[]
  ) {
    super(injector);
    this.paymentBillings = paymentBillings;
  }

  public deleteBillings(selectedBillings: any): void {
    var toDeleteBillingIds = selectedBillings.map((s) => s.value);

    this.dialogService
      .openConfirmDialog(
        marker('Delete billing templates'),
        marker('Are you sure you want to delete the selected billing templates?')
      )
      .subscribe((confirm) => {
        if (!confirm) return;

        this.dialogRef.close(toDeleteBillingIds);
      });
  }
}
