<div>
  <div class="sub-page">
    <h1 translate>License Purchase Status</h1>
    <small translate>You see this page because you got redirected from simple pay. Please see the message below for your simple pay purchase status.</small>

    <ng-container [ngSwitch]="backPayStatus">
      <mat-card appearance="outlined" *ngSwitchCase="BackPayStatuses.INVALID">
        <mat-card-title><span translate>Purchase invalid</span></mat-card-title>
        <mat-card-content>
          <span translate>The parameters are invalid.</span>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined" *ngSwitchCase="BackPayStatuses.VALID">
        <mat-card-title><span translate>Purchase valid</span></mat-card-title>
        <mat-card-content>
          <p translate>Thank you for purchasing your Nano License!</p>
          <p translate>Once the bank transaction processes, you will receive a notification email on the email address which you have provided.</p>
          <p translate>
            Afterwards, you will be able to see your new license at the <a routerLink="/licenses"><span translate>Licenses</span></a> page.
          </p>
        </mat-card-content>
        <mat-card-footer>
          <div class="footer"><span translate>SimplePay transaction id:</span> {{ backData.t }}</div>
        </mat-card-footer>
      </mat-card>
      <mat-card appearance="outlined" *ngSwitchCase="BackPayStatuses.FAILED">
        <mat-card-title><span translate>Purchase failed</span></mat-card-title>
        <mat-card-content>
          <p translate>Please check that the information provided during the transaction is correct.</p>
          <p translate>If you have entered all the information correctly, please contact the cause card issuing bank to investigate the purchase issue.</p>
        </mat-card-content>
        <mat-card-footer>
          <div class="footer"><span translate>SimplePay transaction id:</span> {{ backData.t }}</div>
        </mat-card-footer>
      </mat-card>
      <mat-card appearance="outlined" *ngSwitchCase="BackPayStatuses.TIMEOUT">
        <mat-card-title><span translate>Purchase timed out</span></mat-card-title>
        <mat-card-content>
          <span translate>You have exceeded the maximum possible time to start the transaction.</span>
        </mat-card-content>
        <mat-card-footer>
          <div class="footer"><span translate>SimplePay transaction id:</span> {{ backData.t }}</div>
        </mat-card-footer>
      </mat-card>
      <mat-card appearance="outlined" *ngSwitchCase="BackPayStatuses.CANCEL">
        <mat-card-title><span translate>Purchase canceled</span></mat-card-title>
        <mat-card-content>
          <span translate>You canceled the purchase process.</span>
        </mat-card-content>
        <mat-card-footer>
          <div class="footer"><span translate>SimplePay transaction id:</span> {{ backData.t }}</div>
        </mat-card-footer>
      </mat-card>
    </ng-container>
  </div>
</div>
