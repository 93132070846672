import { ConditionForFetchHandler } from '../../cache-logic-interface';

export let conditionFetchForRoomConfigQuery: ConditionForFetchHandler = function (
  cache,
  cacheKey,
  request
) {
  let roomConfig = cache.getCacheData(cacheKey);
  return !roomConfig; //if true = exists, do not fetch. Else fetch.
};
