import { Routes } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { AccountRecoveryConfirmComponent } from './components/account-recovery-confirm/account-recovery-confirm.component';
import { AccountRecoveryComponent } from './components/account-recovery/account-recovery.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { ActionQueueComponent } from './components/action-queue/action-queue.component';
import { AdvancedSettingsComponent } from './components/advanced-settings/advanced-settings.component';
import { AggregateStatsComponent } from './components/aggregate-stats/aggregate-stats.component';
import { ContactListComponent } from './components/contact-list/contact-list.component';
import { DownloadManagerComponent } from './components/download-manager/download-manager.component';
import { HomeBaseComponent } from './components/home/home-base.component';
import { HomeMobileComponent } from './components/home/mobile/home-mobile.component';
import { LoginComponent } from './components/login/login.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { NanoLicenseBuyComponent } from './components/nano-license-buy/nano-license-buy.component';
import { NanoLicenseViewComponent } from './components/nano-license-view/nano-license-view.component';
import { NanoLicenseComponent } from './components/nano-license/nano-license.component';
import { PageTabCollectionComponent } from './components/page-tab-collection/page-tab-collection.component';
import { RegisterConfirmComponent } from './components/register-confirm/register-confirm.component';
import { RegisterComponent } from './components/register/register.component';
import { RejectedRoomsComponent } from './components/rejected-rooms/rejected-rooms.component';
import { ChatLayoutComponent } from './components/resource-page/chat-layout/chat-layout.component';
import { DriveLayoutComponent } from './components/resource-page/drive-window/drive-layout/drive-layout.component';
import { ResourcePageMobileComponent } from './components/resource-page/mobile/resource-page-mobile/resource-page-mobile.component';
import { SaleCodeManagerComponent } from './components/sale-code-manager/sale-code-manager.component';
import { SearchViewMobileComponent } from './components/search-view/mobile/search-view-mobile.component';
import { SearchViewComponent } from './components/search-view/search-view.component';
import { SessionHandlerComponent } from './components/session-handler/session-handler.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SimplepayBackComponent } from './components/simplepay-back/simplepay-back.component';
import { TimedOffersComponent } from './components/timed-offers/timed-offers.component';
import { IsLoggedInGuard } from './shared/guards/is-logged-in.guard';
import { LogoutPromptGuard } from './shared/guards/logout-prompt.guard';
import { MainPageGuard } from './shared/guards/main-page.guard';
import { RegisterConfirmGuard } from './shared/guards/register-confirm.guard';

const sharedRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [IsLoggedInGuard],
    data: { animation: 'loginPage' },
  },
  { path: 'logout', children: [], canActivate: [LogoutPromptGuard] },
  { path: 'settings', component: SettingsComponent, data: { animation: 'mainMenu-settings' } },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [IsLoggedInGuard],
    data: { animation: 'rightFromLogin' },
  },
  {
    path: 'register-confirm',
    component: RegisterConfirmComponent,
    canActivate: [RegisterConfirmGuard],
    data: { animation: 'rightFromLogin' },
  },
  {
    path: 'advanced-settings',
    component: AdvancedSettingsComponent,
    data: { animation: 'mainMenu-settings-advancedSettings' },
  },
  {
    path: 'register-confirm',
    component: RegisterConfirmComponent,
    data: { animation: 'rightFromLogin' },
  },
  { path: 'download', component: DownloadManagerComponent },
  { path: 'action-queue', component: ActionQueueComponent },
  {
    path: 'licenses',
    component: NanoLicenseComponent,
    canActivate: [IsLoggedInGuard],
    data: { animation: 'mainMenu-licenses' },
  },
  {
    path: 'license-buy',
    component: NanoLicenseBuyComponent,
    canActivate: [IsLoggedInGuard],
    data: { animation: 'mainMenu-licenseBuy' },
  },
  {
    path: 'license-view',
    component: NanoLicenseViewComponent,
    data: { animation: 'rightFromMainMenu' },
  },
  { path: 'simplepay-back', component: SimplepayBackComponent },
  {
    path: 'sale-code-manager',
    component: SaleCodeManagerComponent,
    canActivate: [IsLoggedInGuard],
    data: { animation: 'mainMenu-saleCodeManager' },
  },
  {
    path: 'account',
    component: AccountSettingsComponent,
    canActivate: [IsLoggedInGuard],
    data: { animation: 'mainMenu-account' },
  },
  { path: 'about', component: AboutComponent, data: { animation: 'mainMenu-settings-about' } },
  {
    path: 'contact-list',
    component: ContactListComponent,
    canActivate: [IsLoggedInGuard],
    data: { animation: 'mainMenu-contactList' },
  },
  {
    path: 'offers',
    component: TimedOffersComponent,
    canActivate: [IsLoggedInGuard],
    data: { animation: 'mainMenu-offers' },
  },
  {
    path: 'account-recovery',
    component: AccountRecoveryComponent,
    data: { animation: 'rightFromLogin' },
  },
  {
    path: 'account-recovery-confirm',
    component: AccountRecoveryConfirmComponent,
    data: { animation: 'rightFromLogin' },
  },
  {
    path: 'session-handler',
    component: SessionHandlerComponent,
    canActivate: [IsLoggedInGuard],
    data: { animation: 'mainMenu-account-sessionHandler' },
  },
  { path: 'rejected-rooms', component: RejectedRoomsComponent, canActivate: [IsLoggedInGuard] },
  {
    path: 'nano-metrics',
    component: AggregateStatsComponent,
    canActivate: [IsLoggedInGuard],
    data: { animation: 'mainMenu-nanoMetrics' },
  },
];

export const webRoutes: Routes = [
  ...sharedRoutes,
  {
    path: '',
    component: HomeBaseComponent,
    canActivate: [MainPageGuard],
  },
  /*{
    path: 'room/:id',
    component: ResourcePageComponent,
    children: [
      { path: 'chat/:type', component: ChatLayoutComponent },
      { path: 'drive', component: DriveWindowComponent },
      {
        path: 'details',
        component: RoomDetailsComponent,
        canDeactivate: [PendingChangesGuard],
      },
    ],
  },*/
  { path: 'room/:id/chat/:type', component: PageTabCollectionComponent },
  { path: 'room/:id/drive', component: PageTabCollectionComponent },
  { path: 'search', component: SearchViewComponent },
  { path: 'search/:resourceId' /*#query*/, component: SearchViewComponent },
];

export const mobileRoutes: Routes = [
  ...sharedRoutes,
  {
    path: '',
    component: HomeMobileComponent,
    canActivate: [MainPageGuard],
    data: { animation: 'homePage' },
  },
  {
    path: 'main-menu',
    component: MainMenuComponent,
    data: { animation: 'mainMenu' },
  },
  {
    path: 'room/:id',
    component: ResourcePageMobileComponent,
    children: [
      {
        path: 'chat/:type',
        component: ChatLayoutComponent,
        data: { animation: 'resourcePage-chat', outlet: 'resource' },
      },
      {
        path: 'drive' /*#path*/,
        component: DriveLayoutComponent,
        data: { animation: 'resourcePage-chat-drive', outlet: 'resource' },
      },
    ],
    data: { animation: 'resourcePage' },
  },
  { path: 'search', component: SearchViewMobileComponent },
  { path: 'search/:resourceId' /*#query*/, component: SearchViewMobileComponent },
];
