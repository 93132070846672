<div>
  <div class="sub-page">
    <h1 translate>Timed Offers</h1>
    <small translate>Limited time offers available here</small>
    <ng-container *ngIf="offers && offers.length === 0">
      <div translate>There are currently no offers available.</div>
    </ng-container>
    <ng-container *ngIf="offers && offers.length > 0">
      <table mat-table [dataSource]="offers" class="mat-elevation-z8">
        <!-- <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef translate>Offer ID</th>
          <td mat-cell *matCellDef="let offers">{{ offers.id }}</td>
        </ng-container>
  
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef translate>Offer type</th>
          <td mat-cell *matCellDef="let offers">{{ offers.data.type }}</td>
        </ng-container>
  
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef translate>Amount</th>
          <td mat-cell *matCellDef="let offers">{{ offers.data.number }}</td>
        </ng-container> -->

        <ng-container matColumnDef="offer">
          <th mat-header-cell *matHeaderCellDef translate>Offer</th>
          <td mat-cell *matCellDef="let offers">
            {{ offers.data.number }}
            <span translate *ngIf="offers.data.number > 1">Nano slots for</span> <span translate *ngIf="offers.data.number === 1">Nano slot</span>
            {{ offers.data.months }}
            <span translate *ngIf="offers.data.months > 1">months</span> <span translate *ngIf="offers.data.months === 1"> month</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="expiration">
          <th mat-header-cell *matHeaderCellDef translate>Expires</th>
          <td mat-cell *matCellDef="let offers">{{ offers.expiration * 1000 | date: 'medium' }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef translate></th>
          <td mat-cell *matCellDef="let offers" class="action-cell">
            <button [disabled]="isLoading" *ngIf="selectedOffer !== offers" mat-raised-button color="primary" (click)="accept(offers)">
              <span translate>Accept</span>
            </button>
            <mat-spinner *ngIf="selectedOffer === offers" id="nano-clients-spinner" diameter="20"></mat-spinner>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ng-container>
  </div>
</div>
