import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hide',
})
export class HidePipe implements PipeTransform {
  transform(value: string, hideMode?: string): string {
    if (hideMode == 'all') return '*'.repeat(value.length);

    var fullEmail = value;
    var emailParts = fullEmail.split('@');
    var emailName = emailParts[0];
    var emailProviderParts = emailParts[1].split('.');
    var emailProvider = emailProviderParts[0];
    var emailDomain = emailProviderParts[1];

    var hiddenEmail = `${this.firstLastOnly(emailName)}@${this.lastOnly(
      emailProvider
    )}.${emailDomain}`;
    return hiddenEmail;
  }

  private firstLastOnly(value: string): string {
    if (value.length < 3) return '*'.repeat(value.length);

    return value[0] + '*'.repeat(value.length - 2) + value[value.length - 1];
  }

  private lastOnly(value: string): string {
    if (value.length < 2) return '*';

    return '*'.repeat(value.length - 1) + value[value.length - 1];
  }
}
