import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
} from '@angular/material/checkbox';
import {
  DownloadManagerService,
  downloadErrorObserver,
  downloadFinishedObserver,
  downloadProgressObserver,
  fileRemovedObserver,
} from 'src/app/shared/services/download-manager-service';
import { DriveFile } from '../drive-file';

@Component({
  selector: 'app-file-block-view',
  templateUrl: './file-block-view.component.html',
  styleUrls: ['./file-block-view.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
  ],
})
export class FileBlockViewComponent implements AfterViewInit {
  @Input() file: DriveFile;
  @Input() selected: boolean = false;
  @Input() focus: boolean = false;
  @Input() isSelectingMode: boolean = false;

  protected downloadProgressColor: string =
    'linear-gradient(90deg, rgba(249, 168, 37, .25) 100%, rgba(255, 255, 255, 0) 100%)';
  protected downloadErrorColor: string =
    'linear-gradient(90deg, rgba(244, 67, 54, .50) 100%, rgba(255, 255, 255, 0) 100%)';
  protected errorHappened: boolean = false;
  protected progress: number = 0;

  constructor(private el: ElementRef, private downloadManager: DownloadManagerService) {
    downloadProgressObserver.subscribe((fileManagerType) => {
      if (fileManagerType.file === this.file) {
        this.errorHappened = false;
        this.progress = fileManagerType.progress;
      }
    });

    downloadFinishedObserver.subscribe((fileManagerType) => {
      if (fileManagerType.file === this.file) {
        this.errorHappened = false;
        this.progress = 0;
      }
    });

    downloadErrorObserver.subscribe((error) => {
      if (error.file.rawData === this.file) {
        this.errorHappened = true;
      }
    });

    fileRemovedObserver.subscribe((fileManagerType) => {
      if (fileManagerType.file === this.file) this.progress = 0;
    });
  }

  private calcExistingProgress(): void {
    let fileManagerType = this.downloadManager
      .getFileList()
      .find((fileManagerType) => fileManagerType.file === this.file);
    if (fileManagerType && fileManagerType.progress < 100) {
      this.progress = fileManagerType.progress;
      this.errorHappened = fileManagerType.isError();
    }
  }

  ngAfterViewInit() {
    if (this.focus) {
      this.el.nativeElement.querySelectorAll('.file-name').forEach((el) => {
        el.scrollIntoView({
          // behavior: 'smooth',
          block: 'center',
        });
      });
    }

    this.calcExistingProgress();
  }
}
