<h1 mat-dialog-title translate>File already exists</h1>
<div mat-dialog-content>
  <div class="container">
    <!-- <mat-form-field appearance="outline">
      <mat-label translate>Name</mat-label>
      <input cdkFocusInitial matInput [formControl]="fileNameControl" />
      <span matTextSuffix>{{ extension }}</span>
      <mat-error *ngIf="fileNameControl.hasError('required')"><span translate>Please enter a file name.</span></mat-error>
    </mat-form-field> -->
    <p translate>One or more files already exist in this location. Do you want to keep both items or overwrite the files?</p>
    <mat-radio-group class="form-radio-group" color="primary" [formControl]="namingConventionControl">
      <mat-radio-button class="form-radio-button" [value]="NamingConventionEnum.AUTO_NAME">
        <div class="form-title" translate>Keep all files</div>
        <div class="form-description" translate>The new file will be appended by a random string.</div>
      </mat-radio-button>
      <mat-radio-button class="form-radio-button" [value]="NamingConventionEnum.OVERWRITE">
        <div class="form-title" translate>Overwrite existing files</div>
        <div class="form-description" translate>The original file will be overwritten.</div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null"><span translate>Cancel</span></button>
  <button mat-button color="primary" (click)="close()"><span translate>Upload</span></button>
</div>
