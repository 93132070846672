export type RequestQueueElement = {
  queue: Function[];
  partKey: Uint8Array;
};

export class PartKeyHandler {
  private requestQueue = new Map<number, RequestQueueElement>();

  public getPartKey(requestId): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const request = this.requestQueue.get(requestId);

      if (request && request.partKey !== null) {
        return resolve(request.partKey);
      } else if (request) {
        request.queue.push(resolve);
      } else {
        // getPartKey is faster than setPartKey sometimes on small files, because of async decryption
        this.requestQueue.set(requestId, {
          partKey: null,
          queue: [resolve],
        });
      }
    });
  }

  public setPartKey(requestId, partKey) {
    let reqQ = this.requestQueue.get(requestId);

    if (!reqQ) {
      reqQ = this.initPartKey(requestId);
    }

    reqQ.partKey = partKey;
    reqQ.queue.forEach((cb) => {
      cb(partKey);
    });
  }

  private initPartKey(requestId) {
    this.requestQueue.set(requestId, {
      partKey: null,
      queue: [],
    });
    return this.requestQueue.get(requestId);
  }
}
