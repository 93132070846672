<h1 mat-dialog-title translate>Delete file(s)</h1>
<div mat-dialog-content>
  <div translate>The following files will be deleted:</div>
  <div class="delete-file-container">
    <div class="delete-file-row" *ngFor="let file of data">
      <span *ngIf="file.icon && !file.thumbnail" class="mdi mdi-{{ file.icon }} file-{{ file.icon }}"></span>
      <app-blob-viewer *ngIf="file.thumbnail" class="delete-file-preview" [blob]="file.thumbnail" type="image"></app-blob-viewer>
      <span *ngIf="file.unresolved" class="unresolved-file-dash"> - </span>
      <div class="delete-file-name">{{ file.fullName ?? file.name ?? '{unresolved file}' }}</div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()"><span translate>Cancel</span></button>
  <button mat-button color="warn" [mat-dialog-close]="data"><span translate>Delete</span></button>
</div>
