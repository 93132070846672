import { base64 } from 'rfc4648';

export function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(<string>event.target.result); // desired file content
    reader.onerror = (error) => reject(error);
    reader.readAsText(file); // you could also read images and other binaries
  });
}

export function readFileAsBlob(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(new Blob([event.target.result])); // desired file content
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file); // you could also read images and other binaries
  });
}

export function searchRecoveryKeyFromFile(file: File): Promise<Uint8Array> {
  return readFile(file).then((content) => {
    let allLine = content.split('\n');
    for (let i = 0; i < allLine.length; i++) {
      let line = allLine[i].trim();
      if (line[0] == '#' && line.length > 0) continue;
      try {
        let result = base64.parse(line);
        if (result.length > 0) {
          return result;
        }
      } catch (e) {
        // just search more
      }
    }
    throw 'Can not find recovery key';
  });
}

/*
// Note: Angular recommend to use renderer instead of document.createElement/appendChild etc
// it is possible this won't work in some safari browser
export function selectLocalFile(allowMultipleSelection: boolean = false): Promise<FileList> {
  return new Promise((resolve, reject) => {
    let dialog = document.createElement('input');
    dialog.type = 'file';
    dialog.style.display = 'none';
    dialog.multiple = allowMultipleSelection;
    // on some safari sometimes the file input does not trigger
    // the event after the file-browser selection, if it is not attached to the dom
    // https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
    document.body.appendChild(dialog);
    dialog.addEventListener('change', (e) => {
      let files = dialog.files;
      resolve(files);
      document.body.removeChild(dialog);
    });

    dialog.click();
  });
}*/

export async function getFileFromClipboard(dataTransfer: DataTransfer): Promise<File[]> {
  let result: File[] = [];
  if (dataTransfer.items) {
    let files = await filterOutFoldersAsync(dataTransfer.items);
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      //console.log("item: " + item.kind + " (" + item.type + ")");

      // if you use clipboard, the name is always the same
      // if you want to paste multiple file, it is recommended to generate unique names
      if (file?.name.startsWith('image.')) {
        try {
          // make unique name by time and index
          // otherwise it will be "image" or something like that for all item
          let newName = '' + new Date().getTime() + '_' + i + '_' + file.name;
          file = new File([file], newName, { type: file.type });
        } catch (e) {
          // ok, then keep your name
        }
      }

      if (/^image\//.test(file.type) && file) {
        //console.log("img", file);
        result.push(file);
      } else if (file) {
        //console.log("binary", file);
        result.push(file);
      }
    }
  }

  return result;
}

function filterOutFoldersAsync(items: DataTransferItemList): Promise<File[]> {
  let fileCheckPromises: Promise<File>[] = [];

  for (let i = 0; i < items.length; i++) {
    let fileCheckPromise = new Promise<File>((resolve) => {
      let item = items[i];
      let file = item.getAsFile ? item.getAsFile() : null;
      isFolder(file).then((isFolder) => {
        if (isFolder) resolve(null);
        else resolve(file);
      });

      /**
       * NOTE!
       * Old version: with promise-await somewhy the chrome skip the whole process after the first result. 
       * We had to create a promise-then approach
       * Please test for multiple files if you are trying this:
      if (await isFolder(file)) {
        console.log('file is folder, skipping', file);
      } else {
        files.push(file);
      }
       */
    });
    fileCheckPromises.push(fileCheckPromise);
  }

  let onlyFilesPromise: Promise<File[]> = new Promise((resolve) => {
    Promise.all(fileCheckPromises).then((files) => {
      resolve(files.filter((f) => f !== null));
    });
  });

  return onlyFilesPromise;
}

// Reading a file with FileReader gives an error when the file is actually a folder.
// We abort the fileReader load after one second in case we are trying to check a large file.
function isFolder(file: File): Promise<boolean> {
  let reader = new FileReader();
  reader.readAsText(file);

  let checkIfFolderPromise: Promise<boolean> = new Promise((resolve, reject) => {
    reader.onload = (ev) => {
      resolve(false);
    };

    reader.onabort = (ev) => {
      resolve(false);
    };

    reader.onerror = (ev) => {
      resolve(true);
    };

    setTimeout(() => {
      if (reader.readyState === FileReader.LOADING) reader.abort();
    }, 1000);
  });

  return checkIfFolderPromise;
}
