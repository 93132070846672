import { SubscriptionServiceEventType } from '../../../../../../../../server-services/subscription-event';
import { WorkspaceChangeSubscriptionCMDType } from '../types';
import { onCreateRoomAccountPermissionEventSubscriptionHandler } from './ws-sub-rap-event-create-handler';
import { onDeleteRoomAccountPermissionEventSubscriptionHandler } from './ws-sub-rap-event-delete-handler';
import { onModifyRoomAccountPermissionEventSubscriptionHandler } from './ws-sub-rap-event-modify-handler';

export let workspaceSubscriptionRoomAccountPermissionEventHandler: WorkspaceChangeSubscriptionCMDType =
  {
    [SubscriptionServiceEventType.CREATE]: onCreateRoomAccountPermissionEventSubscriptionHandler,
    [SubscriptionServiceEventType.MODIFY]: onModifyRoomAccountPermissionEventSubscriptionHandler,
    [SubscriptionServiceEventType.DELETE]: onDeleteRoomAccountPermissionEventSubscriptionHandler,
  };
