import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BlobService {
  private static blobs = [];

  // NOTE: This is a work-in-progress, we need to make sure none of these would be opened in the origin of the application!
  public static SAFE_MIME_TYPES = [
    // libreoffice
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    // ms-office (XML)
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // ms-office (old)
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    // plain-er
    'application/rtf',
    'text/csv',
    // other
    'application/epub+zip',
    'application/pdf',
  ];

  constructor() {}

  /**
   * We don't allow to use any other type than binary.
   * if you open a file in a new tab, you can access the main
   * domain enviroment but without CSP, so we don't want to
   * give a chance to run script or script-embedded files
   * in a browser. The binary type always trigger an automatic download
   * instead of opening.
   * Read more: `"blob:" URL XSS (createObjectURL)` #261 issue
   */
  public new(array: any[]): Blob {
    return new Blob(array, { type: 'application/octet-stream' });
  }

  public createObjectURL(blob: Blob): string {
    let url = URL.createObjectURL(blob);
    BlobService.blobs.push(url);
    return url;
  }

  public revokeObjectURL(url) {
    let index = BlobService.blobs.indexOf(url);
    if (index > -1) {
      BlobService.blobs.splice(index, 1);
    } else {
      console.warn('could not find the removable blob in the cache', url, BlobService.blobs);
    }
    URL.revokeObjectURL(url);
  }

  /*
  public debug(draw?) {
    console.log("blob", BlobService.blobs);
    if (draw) {
      let cont = document.createElement("div");

      BlobService.blobs.forEach((b) => {
        let img = document.createElement("img");
        img.src = b;
        img.style.maxWidth = "200px";
        img.style.maxHeight = "200px";
        cont.appendChild(img);
      });

      document.body.appendChild(cont);
    }
  }
  */
}

// if you need to use the service outside the angular module
export let blobService = new BlobService();
