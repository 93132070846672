import {
  ERROR_NOT_AVAILABLE,
  RoomRecord,
  RoomWithData,
} from '../../../../../../../../server-services/query-records/room-records';
import { WorkspaceSubscriptionRoomPermissionEventRecord } from '../../../../../../../../server-services/query-records/workspace-records';
import { getRoomQuery, roomConfigQuery } from '../../../../../../../../server-services/querys';
import { WorkspaceChangeSubscriptionSpecificHandler } from '../types';

export let onDeleteRoomPermissionEventSubscriptionHandler: WorkspaceChangeSubscriptionSpecificHandler =
  (cache, event: WorkspaceSubscriptionRoomPermissionEventRecord) => {
    let room: RoomRecord | RoomWithData = cache.getCacheDataByRequest({
      query: getRoomQuery,
      variables: {
        id: event.id,
      },
    });
    // deleted room, we can let it like this
    (<RoomWithData>room).data = {
      decryptionError: true,
      decryptionErrorMessage: ERROR_NOT_AVAILABLE,
    };

    //if cache data for room config exists, remove it
    cache.deleteCacheDataByRequest({ query: roomConfigQuery, variables: { id: event.id } });
  };
